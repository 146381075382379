
import { IoPlayBackSharp } from "react-icons/io5";


import VisibleCurrCompo from '../TFCurrsHistogram_child_component/VisibleCurrCompo'


import {useState} from 'react'

 
const PlayerHeader = ({
    setPlayBackMSGstr,
    getPlayBACKdataFromDB,
    setIsViewChartBtnClicked,
    isPlayBackBtnClicked,
    setIsPlayBackBtnClicked,
    
    forexORcrypto,
    setIsAllCURRsSelected,
    setCurrArray,
    currArray,
    switchOnAndInsertCURRbyOrder,
    extra_curr,
    defaultCurrArray,
    
}) => {


    const [currIconBtnClicked, setCurrIconBtnClicked] = useState(false)



return (
<div //className='HistogramBarClass'
    style={{
    backgroundColor:`rgba(255,255,255,0.0)`,
    width: `90%`,
    //height: '6.6%',
    //border:'1px solid black',
    margin:'0px auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: '3'
    }}

    onClick={()=>{
    }}
>

    
    

    {
      <VisibleCurrCompo
        isPlayBackBtnClicked={isPlayBackBtnClicked}
        currIconBtnClicked={currIconBtnClicked}
        setCurrIconBtnClicked={setCurrIconBtnClicked}
        setCurrArray={setCurrArray}
        currArray={currArray}
        setIsAllCURRsSelected={setIsAllCURRsSelected}
        defaultCurrArray={defaultCurrArray}
        switchOnAndInsertCURRbyOrder={switchOnAndInsertCURRbyOrder}
        extra_curr={extra_curr}
        forexORcrypto={forexORcrypto}
      />
    }




    <div
    style={{
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor:'pointer',
      whiteSpace: 'nowrap',
      boxShadow:`${/*currIconBtnClicked === true ? `rgba(0, 0, 211, 0.9) 0px 7px 29px 0px` : */`rgba(0, 0, 211, 0.4) 0px 7px 29px 0px`}`,
      position: 'relative',
      zIndex: '11',
      borderRadius: '15px',
      height:'3vh',
      paddingLeft: '15px', paddingRight: '15px',
      marginRight:'0%'
    }}

    onClick={()=>{
      setIsPlayBackBtnClicked(!isPlayBackBtnClicked)
      setIsViewChartBtnClicked(false)

      setPlayBackMSGstr('Loading...')

      setTimeout(()=>{
        const chartNum = 1
        getPlayBACKdataFromDB(chartNum)
      }, 1)

      setCurrIconBtnClicked(false)
    }}
    >
      <IoPlayBackSharp
        style={{
          color:'rgba(0,0,255,0.5)',
          transform:'scale(1.5)'
        }}
      />
      <span style={{
        fontSize:'0.7em',
        color: 'gray',
        marginLeft:'11px'
      }}>play-back</span>
    </div>

</div>
)
}

export default PlayerHeader

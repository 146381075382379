
import { useState, useEffect } from 'react'

import { FcHighPriority } from "react-icons/fc";



const ServerDownAlertCompo = ({
    isShowServerDownAlertCompo,
    setIsShowServerDownAlertCompo,
}) => {


    const [isEmailCopied, setIsEmailCopied] = useState(false)


    useEffect(() => {

    },[])

return (
<div className={'homeBodyWidthResizer'}
//id='mt4ServerDownId'
id='serverDownId'
style={{
    //height:'200px',
    backgroundColor:'black',
    //border:'10px solid blue',
    display:'flex',
    //display:'none',
    //marginBottom:'20px',
    margin:'0px auto',
    
    position:'sticky',

    top:'0px',
    zIndex:'21'
}}
> 
    <div
    style={{
        width:'10%',
        //border:'1px solid blue',
        display: 'flex',
        justifyContent: 'center'
    }}
    >
        <FcHighPriority
        style={{
            //border:'1px solid blue',
            //width:'80%'
            transform:'scale(2)'
        }}
        />
    </div>

    <div
    style={{
        width:'90%',
        //border:'1px solid blue',
    }}
    >

        <div
        style={{
            //height:'50%',
            //border:'1px solid blue',
            color:'white',
            /*display:'flex',
            justifyContent: 'space-around',
            alignItems: 'center'*/
            //textAlign:'center'
        }}
        >
            
            Sorry, our <span style={{ color:'yellow'}}>SERVER REQUESTS are DOWN, </span>
            
            please send us an email at  <span id='emailAddress' style={{ color:'lightgreen'}}>support@currencystrengthzone.com </span>
            
            to get it resolved ASAP, or 
            stay tunned whiles we figure it out ASAP.
        </div>

        <div
        style={{
            height:'30px',
            //border:'1px solid blue',
            display:'flex',
            //justifyContent: 'space-around',
            alignItems: 'center',
            marginTop:'10px', marginBottom:'10px'
        }}
        >
            <button
            style={{
                height:'100%',
                //padding:'10px',
                fontWeight:'bold',
                //borderRadius:'10px',
                cursor:'pointer',
                backgroundColor:'lightgreen',
                border:'0px',
                borderRadius:'5px',
            }}
            >
            <a style={{color:'black'}} 
          href="mailto:support@currencystrengthzone.com">Send Us Email</a>
            </button>


            <button
            style={{
                height:'100%',
                //padding:'10px',
                fontWeight:'bold',
                //borderRadius:'10px',
                cursor:'pointer',
                marginLeft:'20px',
                backgroundColor: `${isEmailCopied ? 'black' : 'lightblue'}`,
                color: `${isEmailCopied ? 'white' : 'black'}`,
                border:'0px',
                borderRadius:'5px',
            }}

            onClick={()=>{

                const textToCopy = document.getElementById('emailAddress');

                // Create a range object to select the text
                const range = document.createRange();
                range.selectNode(textToCopy);

                // Select the text
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);

                // Copy the selected text to the clipboard
                document.execCommand('copy');

                // Clear the selection (optional)
                selection.removeAllRanges();





                setIsEmailCopied(true)
            }}
            >{isEmailCopied ? 'Email Copied' : 'Copy Email'}</button>


            <button
            style={{
                height:'100%',
                //padding:'10px',
                fontWeight:'bold',
                //borderRadius:'10px',
                cursor:'pointer',
                marginLeft:'20px',
                backgroundColor:'red',
                border:'0px',
                borderRadius:'5px',
                color:'white'
            }}

            onClick={(()=>{
                setIsShowServerDownAlertCompo(false)
            })}
            >Ignore</button>
        </div>

    </div>


    
</div>
)
}

export default ServerDownAlertCompo


import '../ChartSignals_style/ChartSignals.css'

import Chart from '../ChartSignals_child_component/ChartProject'
import Alert from '../ChartSignals_child_component/Alert'
import SelectTimeZoneCompo from '../ChartSignals_child_component/SelectTimeZoneCompo'

import { useEffect, useState } from 'react';



const CandlestickChart = ({ 
  
  liveUpdateSECcountdown,
  reloadALLchartGlobalFUNC,
  liveMT4datetimeSTR, liveMT4dayOfWeekNUM,
  pageScrollBarWidthSize, 
  globalDataStorageObj, setGlobalDataStorageObj,
  forexORcrypto, 
  isShowServerDownAlertCompo, setIsShowServerDownAlertCompo,
  reloadAllChartsUseEffectCounter, setReloadAllChartsUseEffectCounter,
  isDontTouchChart, setIsDontTouchChart,
  isTouchScreenDevice, 
  globalMaxWindowOuterHeight,
  globalMaxWindowOuterWidth, setGlobalMaxWindowOuterWidth,
  tfArray, 
  currIndexArray,
  currPairArray,
  defaultChartInstruTFsettingsObj,
  chartNumArrays,

  //----------------------------------------------------------------------------  
  chartInfoObj1,   setChartInfoObj1,
  chartInfoObj2,   setChartInfoObj2,
  chartInfoObj3,   setChartInfoObj3,


  //------------------------------------------------------------------------------
  globalInstruTFuseEffectReRunCounter1, 
  globalInstruTFuseEffectReRunCounter2, 
  globalInstruTFuseEffectReRunCounter3, 

  navToOnlyCHARTnum1instruTFuseEffectReRunCounter,

}) => {

  
  const zero_gmt_deviation = 3//const zero_gmt_deviation = 3//mql4 +3

  const dataShiftCount = 15//5
  const defaultNumOfCandlesToShowOnChart = 
    isTouchScreenDevice ? 18 + dataShiftCount : 35 + dataShiftCount//35 = window.width / (pixelSize we want for each candle either on PC or mobile)






  //all chart infos states separated to avoid re-running whole other charts when editing a giving one 
  //but all chartInfoObjs are put inside a single Obj for the storage in LStorage - {'chart_1':{}, 'chart_2':{},}
  //where: most LStorage done for a given chart_num, at ( onMouseUp, onTouchEnd, ) & after specific chart_num ( settings & other btn clicks)
  //which chart_num to show or minimize


 



  useEffect(() => {//useEffect runs once onPageLoad


    //---------------------------------------------------------------------------------------------
    window.localStorage.setItem("replayDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))  
    //---------------------------------------------------------------------------------------------


    //---------------------------------------------------------------------------------------------
    window.addEventListener('resize', function() {
      // Adjust canvas size or position here
      setGlobalMaxWindowOuterWidth(
          //500
          isTouchScreenDevice ? window.innerWidth : window.innerWidth-pageScrollBarWidthSize// -18 : is to takeout the px occupied by the scrollbar thumb of browser - whenever the page becomes length & scrollable
      )

      setReloadAllChartsUseEffectCounter(Date.now())

    });
    //---------------------------------------------------------------------------------------------


    //--------------------infinitely Listen Perform given Task onPageScroll------------------------
    window.addEventListener('scroll', function() {

      //console.log('pageScroll : ', window.scrollY)
      
    });
    //---------------------------------------------------------------------------------------------

  }, [])







  return(//NOTE: we had disable App - <React.StrictMode> to prevent useEffect Hook from running twice - in order to prevent the chart from printing twice
    <div
    style={{
      position:'relative'
      //border:'3px solid black',
      //width: '600px',
      //height: '450px',
      //margin: '0 auto',
      //border: '1px solid #000',
    }}
    >




      {
        (chartInfoObj1.isLoaded)//isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
        
        &&

        <Chart 

          //-----------------------------------------------------------------
          //unique Compo keys
          canvasCompoId={1}
          chartInRowName= {chartNumArrays[1-1]}//id='chart_1', 'chart_2' 
          chartInfoObj={chartInfoObj1}
          setChartInfoObj={setChartInfoObj1}
          globalInstruTFuseEffectReRunCounter={globalInstruTFuseEffectReRunCounter1}
          navToOnlyCHARTnum1instruTFuseEffectReRunCounter={navToOnlyCHARTnum1instruTFuseEffectReRunCounter}
          //-----------------------------------------------------------------

          //-----------------------------------------------------------------
          //Same keys shared with All Compos

          liveUpdateSECcountdown={liveUpdateSECcountdown}

          zero_gmt_deviation={zero_gmt_deviation}
          
          liveMT4datetimeSTR={liveMT4datetimeSTR}

          isShowServerDownAlertCompo={isShowServerDownAlertCompo}
          setIsShowServerDownAlertCompo={setIsShowServerDownAlertCompo}

          isDontTouchChart={isDontTouchChart}
          setIsDontTouchChart={setIsDontTouchChart}

          reloadAllChartsUseEffectCounter={reloadAllChartsUseEffectCounter}
          setReloadAllChartsUseEffectCounter={setReloadAllChartsUseEffectCounter}

          canvasHeight={globalMaxWindowOuterHeight}
          canvasWidth={globalMaxWindowOuterWidth}


          forexORcrypto={forexORcrypto}

          tfArray={tfArray}
          currIndexArray={currIndexArray}
          currPairArray={currPairArray}


          defaultChartInstruTFsettingsObj={defaultChartInstruTFsettingsObj}

          dataShiftCount={dataShiftCount}
          defaultNumOfCandlesToShowOnChart={defaultNumOfCandlesToShowOnChart}

          globalDataStorageObj   ={globalDataStorageObj}
          setGlobalDataStorageObj={setGlobalDataStorageObj}
          //-----------------------------------------------------------------------
        />
      }





      {//make 3 charts - 2currIndex & 1pair
      //false &&
        (chartInfoObj2.isLoaded)//isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded 
        
        &&

        <Chart 

          //-----------------------------------------------------------------
          //unique Compo keys
          canvasCompoId={2}
          chartInRowName= {chartNumArrays[2-1]}//id='chart_1', 'chart_2' 
          chartInfoObj={chartInfoObj2}
          setChartInfoObj={setChartInfoObj2}
          globalInstruTFuseEffectReRunCounter={globalInstruTFuseEffectReRunCounter2}
          navToOnlyCHARTnum1instruTFuseEffectReRunCounter={2}//static val=2 so that its useEffect wont reRun - but only chart1 will reRun its fetch data useEffect - to have only chart#1 been NAVed to from a Header click
          //-----------------------------------------------------------------


          //-----------------------------------------------------------------
          //Same keys shared with All Compos

          liveUpdateSECcountdown={liveUpdateSECcountdown}

          zero_gmt_deviation={zero_gmt_deviation}

          liveMT4datetimeSTR={liveMT4datetimeSTR}

          isShowServerDownAlertCompo={isShowServerDownAlertCompo}
          setIsShowServerDownAlertCompo={setIsShowServerDownAlertCompo}

          isDontTouchChart={isDontTouchChart}
          setIsDontTouchChart={setIsDontTouchChart}

          reloadAllChartsUseEffectCounter={reloadAllChartsUseEffectCounter}
          setReloadAllChartsUseEffectCounter={setReloadAllChartsUseEffectCounter}

          canvasHeight={globalMaxWindowOuterHeight}
          canvasWidth={globalMaxWindowOuterWidth}


          forexORcrypto={forexORcrypto}

          tfArray={tfArray}
          currIndexArray={currIndexArray}
          currPairArray={currPairArray}


          defaultChartInstruTFsettingsObj={defaultChartInstruTFsettingsObj}

          dataShiftCount={dataShiftCount}
          defaultNumOfCandlesToShowOnChart={defaultNumOfCandlesToShowOnChart}

          globalDataStorageObj   ={globalDataStorageObj}
          setGlobalDataStorageObj={setGlobalDataStorageObj}
          //------------------------------------------------------------------------
        />
      }



      

      {//make 3 charts - 2currIndex & 1pair
      //false &&
        (chartInfoObj3.isLoaded)//isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded 
        
        &&

        <Chart 

          //-----------------------------------------------------------------
          //unique Compo keys
          canvasCompoId={3}
          chartInRowName= {chartNumArrays[3-1]}//id='chart_1', 'chart_2' 
          chartInfoObj={chartInfoObj3}
          setChartInfoObj={setChartInfoObj3}
          globalInstruTFuseEffectReRunCounter={globalInstruTFuseEffectReRunCounter3}
          navToOnlyCHARTnum1instruTFuseEffectReRunCounter={3}//static val=2 so that its useEffect wont reRun - but only chart1 will reRun its fetch data useEffect - to have only chart#1 been NAVed to from a Header click
          //-----------------------------------------------------------------


          //-----------------------------------------------------------------
          //Same keys shared with All Compos
          
          liveUpdateSECcountdown={liveUpdateSECcountdown}

          zero_gmt_deviation={zero_gmt_deviation}

          liveMT4datetimeSTR={liveMT4datetimeSTR}

          isShowServerDownAlertCompo={isShowServerDownAlertCompo}
          setIsShowServerDownAlertCompo={setIsShowServerDownAlertCompo}

          isDontTouchChart={isDontTouchChart}
          setIsDontTouchChart={setIsDontTouchChart}

          reloadAllChartsUseEffectCounter={reloadAllChartsUseEffectCounter}
          setReloadAllChartsUseEffectCounter={setReloadAllChartsUseEffectCounter}

          canvasHeight={globalMaxWindowOuterHeight}
          canvasWidth={globalMaxWindowOuterWidth}

          forexORcrypto={forexORcrypto}

          tfArray={tfArray}
          currIndexArray={currIndexArray}
          currPairArray={currPairArray}


          defaultChartInstruTFsettingsObj={defaultChartInstruTFsettingsObj}

          dataShiftCount={dataShiftCount}
          defaultNumOfCandlesToShowOnChart={defaultNumOfCandlesToShowOnChart}

          globalDataStorageObj   ={globalDataStorageObj}
          setGlobalDataStorageObj={setGlobalDataStorageObj}
          //------------------------------------------------------------------------
        />
      }



      {//Alerts Compo1
        false &&
        <Alert
          alertID={1}
          alertHeight={globalMaxWindowOuterHeight*0.9}
          alertWidth={globalMaxWindowOuterWidth}

          isDontTouchChart={isDontTouchChart}
          setIsDontTouchChart={setIsDontTouchChart}
        />

      }





      {
        <SelectTimeZoneCompo
        reloadALLchartGlobalFUNC={reloadALLchartGlobalFUNC}
        liveMT4datetimeSTR={liveMT4datetimeSTR}
        day_of_week={liveMT4dayOfWeekNUM}
        zero_gmt_deviation={zero_gmt_deviation}
        />
      }







    </div>
  );
};

export default CandlestickChart;




import { GrClose } from "react-icons/gr";
import { SlRefresh } from "react-icons/sl";

import { useEffect, useState } from 'react';


const PsychoCompo = ({
    canvasWidth,
    isShowPsychoScreenCover,
    setIsShowPsychoScreenCover,
    tfArray,
    setUseEffectReRunCounter,
}) => {


    const psychoLEVELS = [10, 25, 50, 75, 100, 125, 150, 175, 250, 500, 750, 1000]

    const [psychoLevelObj, setPsychoLevelObj] = useState({
        selected : [/*10, 25, 50, 75,*/ 100, /*125, 150, 175, 250, 500, 750, 1000*/],
        selectedObj : {
            10 : ['m5', 'm15', /*'m30', 'h1', 'h4', 'd1', 'w1', 'mn'*/], 
            25 : ['m5', 'm15', 'm30', /*'h1', 'h4', 'd1', 'w1', 'mn'*/],  
            50 : ['m5', 'm15', 'm30', 'h1', /*'h4', 'd1', 'w1', 'mn'*/],  
            75 : [/*'m5',*/ 'm15', 'm30', 'h1', 'h4', /*'d1', 'w1', 'mn'*/],  
            100 : [/*'m5', 'm15',*/ 'm30', 'h1', 'h4', 'd1', /*'w1', 'mn'*/],  
            125 : [/*'m5', 'm15',*/ 'm30', 'h1', 'h4','d1', /*'w1', 'mn'*/],  
            150 : [/*'m5', 'm15',*/ 'm30', 'h1', 'h4', 'd1', /*'w1', 'mn'*/],  
            175 : [/*'m5', 'm15', 'm30',*/ 'h1', 'h4', 'd1', /*'w1', 'mn'*/],  
            250 : [/*'m5', 'm15', 'm30', 'h1',*/ 'h4', 'd1', 'w1',/* 'mn'*/],  
            500 : [/*'m5', 'm15', 'm30', 'h1', 'h4',*/ 'd1', 'w1', 'mn'],  
            750 : [/*'m5', 'm15', 'm30', 'h1', 'h4',*/ 'd1', 'w1', 'mn'],  
            1000 : [/*'m5', 'm15', 'm30', 'h1', 'h4',*/ 'd1', 'w1', 'mn']
        }
    })



    useEffect(() => {

        //Auth if psychoLevelObj - exist in localStorage, then use to update useState 
        //else - use the default 

        /*
        const dataObj = window.localStorage.getItem(`${chartNumStr}_Settings`  + forexORcrypto)
          const isDataExist = dataObj !== null

          if(isDataExist){
            //console.log('hey2')
            const data = JSON.parse(dataObj)
            //isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
            setChartInfoObj1(data && data.instruName!==undefined ? {...data, isLoaded: true} : {...defaultChartInfoObj, isLoaded: true})//set to false on LStorage
        */

            //window.localStorage.setItem(`psychoLevel`, JSON.stringify(newObj))  

        const dataObj = window.localStorage.getItem(`psychoLevel`)//make selected : [empty] - onDELETE
        const isDataExist = dataObj !== null

        if(isDataExist){
            const data = JSON.parse(dataObj)

            if(data.selected && data.selectedObj){
                setPsychoLevelObj(data)
            }
        }
        else{

        }

    }, [])


return (
<div
style={{
    //border:'3px solid red',
    backgroundColor:'rgba(0, 0, 0, 0.7)',
    //width:'100%',
    //height:'100%',
    position:'absolute',
    top:'0px',
    //right:`${'50%'}`,//canvasWidth
    bottom:`${true ? '5px' : ''}`,
    left:'0px',
    zIndex:'3',
    color:'white',
    padding:'5px',
    paddingLeft:`${canvasWidth>500 ? '20px' : '5px'}`
}}
>

    <div
    style={{
        //border:'1px solid white',
        height:'5%',
        //width:'50%',
        //display:'flex',
    }}
    >


        <div
        style={{
            marginBottom:'5px',
            //border:'1px solid white',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center'
        }}
        >

            <div
            style={{
                //fontSize:'12px',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                color:'gold',
                fontWeight:'bold',
                //border:'1px solid white',
            }}
            >
                SELECT Psychological Level Lines
            </div>
            
            <div
            style={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
            }}
            >

                <SlRefresh
                style={{
                    backgroundColor:'white',
                    borderRadius:'50%',
                    padding:'4px',
                    color:'black',
                    cursor:'pointer',
                    marginRight:'7px',
                    marginLeft:'7px'
                }}

                onClick={()=>{

                    
                    
                }}
                />

                <GrClose
                style={{
                    backgroundColor:'white',
                    borderRadius:'50%',
                    padding:'5px',
                    color:'black',
                    cursor:'pointer',
                    marginRight:'5px'
                }}

                onClick={()=>{
                    setIsShowPsychoScreenCover(!isShowPsychoScreenCover)
                }}
                />
            </div>
            
        </div>


    </div>



    <div
    style={{
        borderLeft:'1px solid white',
        borderRight:'1px solid white',
        height:'85%',
        //width:'50%',
        //display:'flex',
    }}
    >

        <div
        style={{
            //border:'1px solid gold',
            width:'100%',
            height:'100%',
            margin:'0 auto',
        }}
        >
            {
                psychoLEVELS.map((psychoEL, elIndex)=>{

                    return(
                        <div key={elIndex}
                        style={{
                            height:`${95 / psychoLEVELS.length}%`,
                            borderTop:'0.1px solid white',
                            display:'flex',
                        }}
                        >
                            <div
                            style={{
                                width:'30%',
                                //borderTop:'0.1px solid white',
                                display:'flex', //justifyContent: 'center', alignItems: 'center'
                            }}
                            >

                                <div
                                style={{
                                    width:'50%',
                                    display:'flex', justifyContent: 'center', alignItems: 'center'
                                }}
                                >
                                    {psychoEL}
                                </div>

                                <div
                                style={{
                                    //borderLeft:'0.1px solid white',
                                    width:'50%',
                                    display:'flex', justifyContent: 'center', alignItems: 'center',
                                    
                                }}
                                >

                                    <input type="checkbox" name="" id="" checked={psychoLevelObj.selected.includes(psychoEL)}

                                    style={{
                                        //backgroundColor:'gold',
                                        cursor:'pointer'
                                    }}

                                    onChange={()=>{
                                       
                                        //console.log(psychoLevelObj.selected)
                                       
                                        if(psychoLevelObj.selected.includes(psychoEL)){//filter

                                            setPsychoLevelObj(
                                                {
                                                    ...psychoLevelObj,
                                                    selected : psychoLevelObj.selected.filter((psyEL, psyELind)=>{
                                                        return(psyEL !== psychoEL)
                                                    })
                                                }
                                                
                                            )
                                        }
                                        else{//spread & add

                                            setPsychoLevelObj(
                                                {
                                                    ...psychoLevelObj,
                                                    selected : [...(psychoLevelObj.selected), psychoEL]
                                                }
                                            )
                                        }
                                        
                                         
                                        
                                    }}

                                    
                                    />

                                </div>

                            </div>



                            <div
                            style={{
                                width:'70%',
                                //borderTop:'0.1px solid white',
                                borderLeft:'0.1px solid white',
                            }}
                            >

                                <div
                                style={{
                                    height:'50%',
                                    //border:'0.1px solid white',
                                    display:'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}
                                >

                                    {
                                        (tfArray.slice(0,4)).map((tf, tfInd)=>{
                                            return(
                                                <div key={tfInd} 
                                                
                                                >
                                                    {tf}

                                                    <input type="checkbox" name="" id="" checked={psychoLevelObj.selectedObj[psychoEL].includes(tf)}

                                                    onChange={()=>{

                                                        /*
                                                        const [psychoLevelObj, setPsychoLevelObj] = useState({
                                                        selected : [ 100,],
                                                        selectedObj : {
                                                            10 : ['m5', 'm15', ], 
                                                        }
                                                        })
                                                        */

                                                        if(psychoLevelObj.selectedObj[psychoEL].includes(tf)){//filter

                                                            const newObj = psychoLevelObj.selectedObj
                                                            newObj[psychoEL] = psychoLevelObj.selectedObj[psychoEL].filter((tfEL, psyELind)=>{
                                                                return(tfEL !== tf)
                                                            })

                                                            setPsychoLevelObj(
                                                                {
                                                                    ...psychoLevelObj,
                                                                    selectedObj : newObj
                                                                }
                                                                
                                                            )
                                                        }
                                                        else{//spread & add

                                                            const newObj = psychoLevelObj.selectedObj
                                                            newObj[psychoEL] = [...(psychoLevelObj.selectedObj[psychoEL]), tf]

                                                            setPsychoLevelObj(
                                                                {
                                                                    ...psychoLevelObj,
                                                                    selectedObj : newObj
                                                                }
                                                                
                                                            )
                                                        }
                                                                                                            
                                                    }}

                                                    style={{
                                                        cursor:'pointer'
                                                    }}
                                    
                                                />
                                                </div>
                                            )
                                        })
                                    }

                                </div>


                                <div
                                style={{
                                    height:'50%',
                                    //border:'0.1px solid white',
                                    display:'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}
                                >

                                    {
                                        (tfArray.slice(4)).map((tf, tfInd)=>{
                                            return(
                                                <div key={tfInd} 
                                                
                                                >
                                                    {tf}

                                                    <input type="checkbox" name="" id="" checked={psychoLevelObj.selectedObj[psychoEL].includes(tf)}

                                                    onChange={()=>{

                                                        /*
                                                        const [psychoLevelObj, setPsychoLevelObj] = useState({
                                                        selected : [ 100,],
                                                        selectedObj : {
                                                            10 : ['m5', 'm15', ], 
                                                        }
                                                        })
                                                        */

                                                        if(psychoLevelObj.selectedObj[psychoEL].includes(tf)){//filter

                                                            const newObj = psychoLevelObj.selectedObj
                                                            newObj[psychoEL] = psychoLevelObj.selectedObj[psychoEL].filter((tfEL, psyELind)=>{
                                                                return(tfEL !== tf)
                                                            })

                                                            setPsychoLevelObj(
                                                                {
                                                                    ...psychoLevelObj,
                                                                    selectedObj : newObj
                                                                }
                                                                
                                                            )
                                                        }
                                                        else{//spread & add

                                                            const newObj = psychoLevelObj.selectedObj
                                                            newObj[psychoEL] = [...(psychoLevelObj.selectedObj[psychoEL]), tf]

                                                            setPsychoLevelObj(
                                                                {
                                                                    ...psychoLevelObj,
                                                                    selectedObj : newObj
                                                                }
                                                                
                                                            )
                                                        }
                                                                                                            
                                                    }}

                                                    style={{
                                                        cursor:'pointer'
                                                    }}
                                    
                                    />
                                                </div>
                                            )
                                        })
                                    }

                                </div>



                            </div>


                        </div>
                    )

                })
            }
        </div>

    </div>


    <div
    style={{
        border:'1px solid white',
        height:'10%',
        //width:'50%',
        display:'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    }}
    >

        <button
        style={{
            cursor:'pointer',
            width:'55%',
            height:'70%',
            backgroundColor:'lightgreen',
            border:'0px'
        }}
        onClick={()=>{
            //update localStorage by - psychoLevelObj
            window.localStorage.setItem(`psychoLevel`, JSON.stringify(psychoLevelObj))  

            setIsShowPsychoScreenCover(!isShowPsychoScreenCover)

            setUseEffectReRunCounter(Date.now())
        }}
        >Update</button>

        <button
        style={{
            cursor:'pointer',
            width:'30%',
            height:'70%',
            backgroundColor:'rgba(255, 0, 0, 0.85)',
            border:'0px',
            color:'white'
        }}
        onClick={()=>{
            //update localStorage by - psychoLevelObj
            const newObj = {
                ...psychoLevelObj,
                selected : []
            }

            window.localStorage.setItem(`psychoLevel`, JSON.stringify(newObj))  

            setIsShowPsychoScreenCover(!isShowPsychoScreenCover)

            setUseEffectReRunCounter(Date.now())

        }}
        >Delete</button>

    </div>
    
</div>
)
}

export default PsychoCompo

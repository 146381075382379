

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //NOTE: we had disable App - <React.StrictMode> to prevent useEffect Hook from running twice - in order to prevent the chart from printing twice
  /*
  <React.StrictMode>
    <App />
  </React.StrictMode>
  */
  <>
    <App />
  </>
);
  








import { GrStatusGoodSmall } from "react-icons/gr";

import TimeZoneSetterCompo from './../../ForexHome/ForexHome_child_component/TimeZoneSetterCompo'
//import TimeZoneSetterCompo from './../../'

//import {timeZoneDatetimeConverter} from "../../../../src/Helpers/timeZoneDatetimeConverter"
import {timeZoneDatetimeConverter} from "./../../../Helpers/timeZoneDatetimeConverter"



const SelectTimeZoneCompo = ({
        reloadALLchartGlobalFUNC,
        liveMT4datetimeSTR, day_of_week,
        zero_gmt_deviation
}) => {

    //const zero_gmt_deviation = 3//mql4 +3


return (
<div id='selectTimeZone-id'
style={{
    //border:'1px solid black',
    width:'100%',
    display:'flex', justifyContent: 'center', alignItems: 'center',
    //height: window.innerWidth <= 500 ? window.innerHeight *0.92 : window.innerWidth <= 742 ? window.innerHeight *0.90 :
    //742*0.9,//(document.getElementById('${pageSessionType}-pagesDropDownId').offsetHeight * 0.8) / (document.getElementById('${pageSessionType}-pagesDropDownId').offsetHeight/window.innerHeight),//0.5===50% of screen height
    borderRadius:'25px',
    boxShadow:`rgba(128, 128, 128, 0.75) 0px 7px 29px 0px`,
    marginTop:'50px', marginBottom:'50px'
}}
>
   
   <div
   style={{
    //border:'1px solid black',
    width: window.innerWidth <= 700 ? window.innerWidth *0.95 : 700 * 0.95,
    //height:'99%', 
    borderRadius:'25px',
    boxShadow:`rgba(0, 0, 128, 0.5) 0px 7px 29px 0px`,
    marginTop:'15px', marginBottom:'15px'
   }}
   >

        <div
        style={{
            //border:'1px solid black',
            //height:'13%', 
            display:'flex', justifyContent: 'center', alignItems: 'center',
            color:'blue',  width:'90%', 
            margin:'0 auto',
            position:'relative', //borderRadius:'25px',
            borderBottom:'0.1px solid gray',
            //boxShadow:`rgba(128, 128, 128, 0.3) 0px 7px 29px 0px`,
            //cursor:'pointer',
        }}
        >
            <div
            style={{}}
            >

                <h4 style={{//border:'1px solid black',
                //display:'flex', justifyContent: 'center', alignItems: 'center',
                fontWeight:'bold',//marginTop:'3px'
                }}>
                    Set your Chart  <span style={{
                        backgroundColor:'blue', color:'white', marginRight:'3px', marginLeft:'3px',
                        borderRadius:'25px', padding:'3px'
                    }}> TIME </span> to your current location :
                </h4>

            </div>
            
        </div>



        <div
        style={{
            //border:'1px solid black',
            //height:'70%',
        }}
        >



            <div
            style={{
                //border:'1px solid black',
                //height:'20%', 
                width:'90%', margin:'10px auto',
                display:'flex', justifyContent: 'center', alignItems: 'center', borderRadius:'25px',
            }}>
                <div
                style={{
                    //border:'1px solid black',
                    //height:'80%', 
                    width:'95%', //margin:'5px auto', //borderRadius:'25px',
                    fontSize:'14px'
                }}>
                    <b style={{color:'gray'}}>Chart Current Time </b> - <b style={{
                        color:`${ (
                            (
                            day_of_week >= 5 && 
                            Number(liveMT4datetimeSTR.split(' ')[1].split(':')[0]) === 23 &&
                            Number(liveMT4datetimeSTR.split(' ')[1].split(':')[1]) > 50)
                            || 
                            (
                                day_of_week === 6 
                            )
                        ) ? 'red' : ''}`, 
                        fontSize:'25px'
                    }}> 

                    { 
                        (
                            (
                            day_of_week >= 5 && 
                            Number(liveMT4datetimeSTR.split(' ')[1].split(':')[0]) === 23 &&
                            Number(liveMT4datetimeSTR.split(' ')[1].split(':')[1]) > 50)
                            || 
                            (
                                day_of_week === 6 
                            )
                        )

                        ? 
                        'MARKET CLOSED' : 
                        `${liveMT4datetimeSTR.includes('1970') ? liveMT4datetimeSTR.split(' ')[1].split(':')[0] : timeZoneDatetimeConverter(
                            liveMT4datetimeSTR, 
                            localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                            zero_gmt_deviation).split(' ')[1].split(':')[0]} : 
                        ${liveMT4datetimeSTR.includes('1970') ? liveMT4datetimeSTR.split(' ')[1].split(':')[1] : timeZoneDatetimeConverter(
                            liveMT4datetimeSTR, 
                            localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                            zero_gmt_deviation).split(' ')[1].split(':')[1]} ${Number(timeZoneDatetimeConverter(
                                liveMT4datetimeSTR, 
                                localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                                zero_gmt_deviation).split(' ')[1].split(':')[0]) >= 12 ? 'pm' : 'am'}`
                    }
                    
                    
                    </b> 
                    
                </div>


                

            </div>


            <div
            style={{
            //border:'1px solid black',
            //height:'20%', 
            width:'90%', margin:'10px auto',
            display:'flex', justifyContent: 'center', alignItems: 'center', borderRadius:'25px',
            }}
            >
                <div
                style={{
                    //border:'1px solid black',
                    //height:'80%', 
                    width:'95%', //margin:'5px auto', //borderRadius:'25px',
                    fontSize:'14px'
                }}
                >
                    <b style={{color:'gray'}}>Chart Current Date </b> -  {
                        liveMT4datetimeSTR.includes('1970') ? liveMT4datetimeSTR.split(' ')[0] :
                        timeZoneDatetimeConverter(
                            liveMT4datetimeSTR, 
                            localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                            zero_gmt_deviation).split(' ')[0]
                    }
                </div>
            </div>



            <div
            style={{
                //border:'1px solid black',
                //height:'20%', 
                width:'90%', margin:'10px auto',
                display:'flex', justifyContent: 'center', alignItems: 'center', borderRadius:'25px',
            }}>
                <div
                style={{
                    //border:'1px solid black',
                    //height:'80%', 
                    width:'95%', //margin:'5px auto', //borderRadius:'25px',
                    fontSize:'14px'
                }}>
                    <b style={{color:'green'}}>SELECT your current location time : </b>  
                    
                    <div
                    style={{
                        marginTop:'10px',
                    }}
                    >
                    {
                        <TimeZoneSetterCompo
                        id={'300'}
                        zero_gmt_deviation={zero_gmt_deviation}
                        datetime={liveMT4datetimeSTR}
                        reloadALLchartGlobalFUNC={reloadALLchartGlobalFUNC}
                        />
                    }
                    </div>
                    
                    
                </div>
            </div>






            <div
            style={{
                //border:'1px solid black',
                //height:'20%', 
                width:'90%', margin:'10px auto', marginBottom:'25px',
                display:'flex', justifyContent: 'center', alignItems: 'center', borderRadius:'25px',
            }}>
                <div
                style={{
                    //border:'1px solid black',
                    //height:'80%', 
                    width:'95%', //margin:'5px auto', //borderRadius:'25px',
                    fontSize:'14px'
                }}>
                    <b>NOTICE:</b> always modify your current Chart Time to match any current time of your choice 
              
                </div>
            </div>


        </div>

   </div>

</div>
)
}

export default SelectTimeZoneCompo






import {TFCurrsHistogram, CurrTFsHistogram} from './../../../Pages/export_Pages'

import {default_forex_db_data_obj} from './../../../Helpers/get_default_forex_db_data'
import { useState, useEffect } from 'react'

import ServerDownAlertCompo from './ServerDownAlertCompo'
import CSMheader from './CSMheader'
  


const ForexHomeBody = ({
    liveUpdateSECcountdown,
    currPairArray,
    globalDataStorageObj,
    globalInstruTFuseEffectReRunCounter,
    reloadALLchartGlobalFUNC,
    forexORcrypto,
    switchPairChartTOindexFUNC,
    setLiveMT4datetimeSTR, setLiveMT4dayOfWeekNUM
}) => {




//------------------------------------------------------------------------
const [tf_currs_forex_data, setTf_currs_forex_data] = useState(default_forex_db_data_obj)
const [curr_tfs_forex_data, setCurr_tfs_forex_data] = useState(default_forex_db_data_obj)
//------------------------------------------------------------------------

const [isStartDateTimeSet, setIsStartDateTimeSet] = useState(false)



const [isChartHeaderOptionsSwitchedAtleastOnce, setIsChartHeaderOptionsSwitchedAtleastOnce] = useState(false)
const [isChartDATAloadedAtleastOnce, setIsChartDATAloadedAtleastOnce] = useState(false)



//if: csmDATAtypeIDstr.includes('candle') - then disable btn & make btn color all white
const [csmDATAtypeIDstr1, setCsmDATAtypeIDstr1] = useState(// + forexORcrypto
    JSON.parse(window.localStorage.getItem(`csmDATAtypeIDstr-${100/*CSMchartID=100*/}` + forexORcrypto)) !== null ? 
    JSON.parse(window.localStorage.getItem(`csmDATAtypeIDstr-${100/*CSMchartID=100*/}` + forexORcrypto)) : 'time ago_current'

    //'time ago_current'//default= 'time ago_current'
    //LStorage Auths, if exists then access else default = 'time ago_current'
)

const [csmDATAtypeIDstr2, setCsmDATAtypeIDstr2] = useState(// + forexORcrypto
    JSON.parse(window.localStorage.getItem(`csmDATAtypeIDstr-${200/*CSMchartID=200*/}` + forexORcrypto)) !== null ? 
    JSON.parse(window.localStorage.getItem(`csmDATAtypeIDstr-${200/*CSMchartID=200*/}` + forexORcrypto)) : 'time ago_current'

    //'candle ago_current'//default= 'time ago_current'
    //LStorage Auths, if exists then access else default = 'time ago_current'
)

const csmDATAtypeIDstrObj = {//csmDATAtypeIDstrObj, csmDATAtypeIDstr
    'time ago_current'    : 0,
    'candle ago_current'  : 1,
    'candle ago_previous' : 2
}
//0 = csmDATAtypeIDstrObj[csmDATAtypeIDstr]
//'1970-01-01 00:00:00' = resData.all_datetime[csmDATAtypeIDstrObj[csmDATAtypeIDstr]]




//------------------------------------playBACK---FUNCs-------------------------------------------------

const [playBackINDEXval1, setPlayBackINDEXval1] = useState(0)
const [playBackINDEXval2, setPlayBackINDEXval2] = useState(0)//playBackMAXval

const [playBackMAXval1, setPlayBackMAXval1] = useState(10)
const [playBackMAXval2, setPlayBackMAXval2] = useState(10)

const [playBackMSGstr1, setPlayBackMSGstr1] = useState('')
const [playBackMSGstr2, setPlayBackMSGstr2] = useState('')//playBACKuseEffectNULLIFier

const [playBACKuseEffectNULLIFier1, setPlayBACKuseEffectNULLIFier1] = useState(Date.now())
const [playBACKuseEffectNULLIFier2, setPlayBACKuseEffectNULLIFier2] = useState(Date.now())

const [isPlayBackBtnClicked1, setIsPlayBackBtnClicked1] = useState(false)
const [isPlayBackBtnClicked2, setIsPlayBackBtnClicked2] = useState(false)

const [isOnPLAYmode1, setIsOnPLAYmode1] = useState(false)
const [isOnPLAYmode2, setIsOnPLAYmode2] = useState(false)

//set empty when playBACKmode closed
const [playbackDATAarrayChart1, setPlaybackDATAarrayChart1] = useState([])
const [playbackDATAarrayChart2, setPlaybackDATAarrayChart2] = useState([])






const onCLOSEplaybackDataArrayRESET = (chartNum)=>{

    //console.log('hey///')
    if(chartNum===1){
        
        setTf_currs_forex_data(
            typeof(playbackDATAarrayChart1)==='object' &&
            playbackDATAarrayChart1[playbackDATAarrayChart1.length-1] &&
            typeof(playbackDATAarrayChart1[playbackDATAarrayChart1.length-1])==='object' &&
            (playbackDATAarrayChart1[playbackDATAarrayChart1.length-1]).str_datetime &&
            (playbackDATAarrayChart1[playbackDATAarrayChart1.length-1]).str_datetime.length > '2024-01-01'.length
            ?
            playbackDATAarrayChart1[playbackDATAarrayChart1.length-1]
            :
            tf_currs_forex_data
        )

        setTimeout(()=>{
            setPlaybackDATAarrayChart1([])
        }, 1)

        //console.log('hey...')
        //alert('it worked...')
    }
    else if(chartNum===2){
        
        setCurr_tfs_forex_data(
            typeof(playbackDATAarrayChart2)==='object' &&
            playbackDATAarrayChart2[playbackDATAarrayChart2.length-1] &&
            typeof(playbackDATAarrayChart2[playbackDATAarrayChart2.length-1])==='object' &&
            (playbackDATAarrayChart2[playbackDATAarrayChart2.length-1]).str_datetime &&
            (playbackDATAarrayChart2[playbackDATAarrayChart2.length-1]).str_datetime.length > '2024-01-01'.length
            ?
            playbackDATAarrayChart2[playbackDATAarrayChart2.length-1]
            :
            curr_tfs_forex_data
        )
        
        setTimeout(()=>{
            setPlaybackDATAarrayChart2([])
        }, 1)

        //alert('it worked...')
    }
}



const isPlaybackDATAloadedAndVALID = (chartNum)=>{//before making - playback-icon, playforward-icon, playback-loop or rangeINPUT work

    if(chartNum===1 && typeof(playbackDATAarrayChart1)==='object' && playbackDATAarrayChart1.length > 0){
        return(true)
    }
    else if(chartNum===2 && typeof(playbackDATAarrayChart2)==='object' && playbackDATAarrayChart2.length > 0){
        return(true)
    }
    else{
        return(false)
    }
}



const isINDEXatEndsFUNC = (chartNum)=>{

    if(chartNum===1 && typeof(playbackDATAarrayChart1)==='object' && playbackDATAarrayChart1.length > 0 &&
        (playBackINDEXval1 === 0 || playBackINDEXval1 === playbackDATAarrayChart1.length - 1)
    ){
        return(true)
    }
    else if(chartNum===2 && typeof(playbackDATAarrayChart2)==='object' && playbackDATAarrayChart2.length > 0 &&
        (playBackINDEXval2 === 0 || playBackINDEXval2 === playbackDATAarrayChart2.length - 1)
    ){
        return(true)
    }
    else{
        return(false)
    }
}


const updateCSMchartByPLAYBACKid = (index=0, chartNum=1, step=1, isForward=true, isExceedBeforePLAY=false)=>{
    //adjust modulo - if len=20 & step=6 , then instead of 18(remaining=2) then id=20

    //playBackINDEXval,
    //setPlayBackINDEXval,

    
    
    if(chartNum===1 && typeof(playbackDATAarrayChart1)==='object' && playbackDATAarrayChart1.length > 0){
        
        //index=len-1 if index > len-1
        //if forward & if index===len-1 then index=0
        //if backward & if index===0 then index=len-1

        let indexAUTH = index + (isForward ? Number(step) : -(Number(step)))
        
        const isPlayRangeLESSER  = indexAUTH < 0 
        const isPlayRangeGREATER = indexAUTH > playbackDATAarrayChart1.length - 1
        indexAUTH = isPlayRangeLESSER ? 0 : isPlayRangeGREATER ? playbackDATAarrayChart1.length - 1 : indexAUTH

        indexAUTH = isOnPLAYmode1 && isPlayRangeLESSER ? 0 : 
        isOnPLAYmode1 && isPlayRangeGREATER ? playbackDATAarrayChart1.length - 1 : indexAUTH 

        indexAUTH = isExceedBeforePLAY && isPlayRangeLESSER ? playbackDATAarrayChart1.length - 1 : 
                    isExceedBeforePLAY && isPlayRangeGREATER ? 0 : indexAUTH 

        setTf_currs_forex_data(
            playbackDATAarrayChart1[Number(indexAUTH)] ?
            playbackDATAarrayChart1[Number(indexAUTH)]
            :
            tf_currs_forex_data
        )

        setPlayBackMSGstr1(`${indexAUTH+1} / ${playbackDATAarrayChart1.length}`)
        setPlayBackMAXval1(playbackDATAarrayChart1.length-1 >= 0 ? playbackDATAarrayChart1.length-1 : 0)

        setPlayBackINDEXval1(indexAUTH >= 0 ? indexAUTH : 0)
        //console.log(indexAUTH)

        if(
            (isPlayRangeLESSER || isPlayRangeGREATER) &&
            isExceedBeforePLAY === false
        ){
            setPlayBACKuseEffectNULLIFier1(Date.now())
        }
    }
    else if(chartNum===2 && typeof(playbackDATAarrayChart2)==='object' && playbackDATAarrayChart2.length > 0){
        

        /*const indexAUTH = playBackINDEXval2 

        setCurr_tfs_forex_data(
            playbackDATAarrayChart2[Number(indexAUTH)] ?
            playbackDATAarrayChart2[Number(indexAUTH)]
            :
            curr_tfs_forex_data
        )*/


        let indexAUTH = index + (isForward ? Number(step) : -(Number(step)))
        
        const isPlayRangeLESSER  = indexAUTH < 0 
        const isPlayRangeGREATER = indexAUTH > playbackDATAarrayChart2.length - 1
        indexAUTH = isPlayRangeLESSER ? 0 : isPlayRangeGREATER ? playbackDATAarrayChart2.length - 1 : indexAUTH

        indexAUTH = isOnPLAYmode2 && isPlayRangeLESSER ? 0 : 
        isOnPLAYmode2 && isPlayRangeGREATER ? playbackDATAarrayChart2.length - 1 : indexAUTH 

        indexAUTH = isExceedBeforePLAY && isPlayRangeLESSER ? playbackDATAarrayChart2.length - 1 : 
                    isExceedBeforePLAY && isPlayRangeGREATER ? 0 : indexAUTH 

        setCurr_tfs_forex_data(
            playbackDATAarrayChart2[Number(indexAUTH)] ?
            playbackDATAarrayChart2[Number(indexAUTH)]
            :
            curr_tfs_forex_data
        )

        setPlayBackMSGstr2(`${indexAUTH+1} / ${playbackDATAarrayChart2.length}`)
        setPlayBackMAXval2(playbackDATAarrayChart2.length-1 >= 0 ? playbackDATAarrayChart2.length-1 : 0)

        setPlayBackINDEXval2(indexAUTH >= 0 ? indexAUTH : 0)
        //console.log(indexAUTH)

        if(
            (isPlayRangeLESSER || isPlayRangeGREATER) &&
            isExceedBeforePLAY === false
        ){
            setPlayBACKuseEffectNULLIFier2(Date.now())
        }

    }

}






const getPlayBACKdataFromDB = (chartNum)=>{

    //NOTICE: loop & filter for a common minute & not return any same minute ahead in array
    //give a loading... indication while db_res waiting & parsing array to single minute data
    //req for 10 hours away data : 10 * 60 mins = 600 len - 12=720

    let instruTFsToUPDATEobj = {}
    instruTFsToUPDATEobj['hist_forex_csm'] = {datetimeToUpdateFrom : '', isUpdateForexData : false, chartNum : 100}//100 & 200

    //-----------------------------------------------------------------------------------------
    fetch(
        //window.location.hostname==='localhost' && false
        //?
        //`http://${ window.innerWidth < 400 ? '192.168.43.98' : 'localhost'}:5500/db`,//get_forex_data
        'https://serverapi.currencystrengthzone.com/db',
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                forexORcrypto,
                keyNAMES  : instruTFsToUPDATEobj,
                isPhone   : localStorage.getItem('isPhone') !== null ? true : false,
                phoneInfo : localStorage.getItem('isPhone') !== null ? localStorage.getItem('isPhone') : ''//'{"isPhone":true,"isAndroid":true,"appVersion":1.0.0,"key1":value1}'
            }),
        }
    )
    .then((res) => {
        return res.json()
    })
    .then((dbDATAres)=>{
        
        if(dbDATAres.error){//alert error, if: resObj.error_key exists in the response object, if image does not exist
            //alert('if error feed state with default data to display page & alert no internet or server under maintainance')
            

            //console.log(dbDATAres)

            const resDATAarray = []
        
            if(chartNum===1){
                setPlaybackDATAarrayChart1(resDATAarray)
                //console.log('playBACK data db success -- 1')
        
                //setPlayBackMSGstr1('try later) if any db res error
                //setPlayBackMSGstr1(`${playBackINDEXval1+1} / ${resDATAarray.length}`)
                //setPlayBackMSGstr1(`${resDATAarray.length} / ${resDATAarray.length}`)
                setPlayBackMSGstr1(`try later...`)
                setPlayBackMAXval1(0)
                setPlayBackINDEXval1(0)//0
            }
            else if(chartNum===2){
                setPlaybackDATAarrayChart2(resDATAarray)
                //console.log('playBACK data db success -- 2')

                //setPlayBackMSGstr2('try later) if any db res error
                //setPlayBackMSGstr2(`${playBackINDEXval2+1} / ${resDATAarray.length}`)
                //setPlayBackMSGstr2(`${resDATAarray.length} / ${resDATAarray.length}`)
                setPlayBackMSGstr2(`try later...`)
                setPlayBackMAXval2(0)
                setPlayBackINDEXval2(0)//0
            }
        }
        else{//without an resObj.error_key: return the name of the image deleted & an image deletion success msg
            

            //console.log(dbDATAres)

            if(
                //false &&
                dbDATAres['hist_forex_csm'] &&//if key exists
                dbDATAres['hist_forex_csm'].data &&//if its data exists
                typeof(dbDATAres['hist_forex_csm'].data)==='object' &&
                dbDATAres['hist_forex_csm'].data.length > 0 &&
                dbDATAres['hist_forex_csm'].data[0].str_datetime//atleast contain h1 data
            ){

                //console.log('hist_forex_csm EXISTs')
                const resDATAarray = dbDATAres['hist_forex_csm'].data
                //console.log(resDATAarray)
            
            
                if(chartNum===1){
                    setPlaybackDATAarrayChart1(resDATAarray)
                    //console.log('playBACK data db success -- 1')
            
                    //setPlayBackMSGstr1('try later) if any db res error
                    //setPlayBackMSGstr1(`${playBackINDEXval1+1} / ${resDATAarray.length}`)
                    setPlayBackMSGstr1(`${resDATAarray.length} / ${resDATAarray.length}`)
                    setPlayBackMAXval1(resDATAarray.length-1 >= 0 ? resDATAarray.length-1 : 0)
                    setPlayBackINDEXval1(resDATAarray.length-1 >= 0 ? resDATAarray.length-1 : 0)//0
                }
                else if(chartNum===2){
                    setPlaybackDATAarrayChart2(resDATAarray)
                    //console.log('playBACK data db success -- 2')

                    //setPlayBackMSGstr2('try later) if any db res error
                    //setPlayBackMSGstr2(`${playBackINDEXval2+1} / ${resDATAarray.length}`)
                    setPlayBackMSGstr2(`${resDATAarray.length} / ${resDATAarray.length}`)
                    setPlayBackMAXval2(resDATAarray.length-1 >= 0 ? resDATAarray.length-1 : 0)
                    setPlayBackINDEXval2(resDATAarray.length-1 >= 0 ? resDATAarray.length-1 : 0)//0
                }
                
            }
            else{
                //console.log(dbDATAres)

                const resDATAarray = []
            
                if(chartNum===1){
                    setPlaybackDATAarrayChart1(resDATAarray)
                    //console.log('playBACK data db success -- 1')
            
                    //setPlayBackMSGstr1('try later) if any db res error
                    //setPlayBackMSGstr1(`${playBackINDEXval1+1} / ${resDATAarray.length}`)
                    //setPlayBackMSGstr1(`${resDATAarray.length} / ${resDATAarray.length}`)
                    setPlayBackMSGstr1(`try later...`)
                    setPlayBackMAXval1(0)
                    setPlayBackINDEXval1(0)//0
                }
                else if(chartNum===2){
                    setPlaybackDATAarrayChart2(resDATAarray)
                    //console.log('playBACK data db success -- 2')

                    //setPlayBackMSGstr2('try later) if any db res error
                    //setPlayBackMSGstr2(`${playBackINDEXval2+1} / ${resDATAarray.length}`)
                    //setPlayBackMSGstr2(`${resDATAarray.length} / ${resDATAarray.length}`)
                    setPlayBackMSGstr2(`try later...`)
                    setPlayBackMAXval2(0)
                    setPlayBackINDEXval2(0)//0
                }
            }

        }

    })

    
    //alert('data loaded')

}




const db_to_PLAYBACKupdateFUNC = (resData)=>{

    //console.log('playBackACTIVATED...')

    //--------------------------------------replayBACK---MODE---------------------------------------------------------------------------------------
    //----------------Update table_1---------------------
    if(
        isPlayBackBtnClicked1 //&&
        //playbackDATAarrayChart1.length > 0 //meaning atleast 1 data range had been collected from db - set array to [] - after close of playback
    ){


        //----------------Update table_2---------------------
        if(isPlayBackBtnClicked2===false){
            setCurr_tfs_forex_data(resData)
        }


        //----------------Update table_1---------------------
        const arrayMAXsize = 240//240mins = 4hrs
        let array1         = [...playbackDATAarrayChart1]
        let newDATAarray   = []

        
        


        if(
            (array1.length === 0) 
            ||
            (
                array1.length === 1 
                && array1[0] 
                && array1[0].str_datetime 
                && typeof(array1[0].str_datetime)==='string' 
                && array1[0].str_datetime.length > '2024-01-01'.length
            )
        ){
            newDATAarray = [...array1, resData]


            
            

            if(//Auth newDATAarray array if valid
                newDATAarray &&
                typeof(newDATAarray) === 'object' &&
                newDATAarray.length >= array1.length
            ){
                //is_hist_forex_csm_valid = true
                setPlaybackDATAarrayChart1(newDATAarray)

                if(isOnPLAYmode1){
                    //do nothing
                }
                else{
                    setPlayBackMSGstr1(`${playBackINDEXval1 + 1} / ${newDATAarray.length}`)
                    setPlayBackMAXval1(newDATAarray.length-1 >= 0 ? newDATAarray.length-1 : 0)
                }
            }
        }
        else if(array1.length >= 2){
            
            const last_BUT1_obj_data = array1[array1.length - 2]
            const last_obj_data      = array1[array1.length - 1]
            const to_update_obj_data = resData
            

            if(
                last_obj_data &&
                last_BUT1_obj_data &&
                last_obj_data.str_datetime &&
                last_BUT1_obj_data.str_datetime &&
                typeof(last_obj_data.str_datetime)==='string' &&
                typeof(last_BUT1_obj_data.str_datetime)==='string' &&
                last_obj_data.str_datetime.length > '2024-01-01'.length &&
                last_BUT1_obj_data.str_datetime.length > '2024-01-01'.length 
            ){

                const last_BUT1_obj_data_EPOCH = datetimeToTimestamp(last_BUT1_obj_data.str_datetime)
                const last_obj_data_EPOCH      = datetimeToTimestamp(last_obj_data.str_datetime)
                const to_update_obj_data_EPOCH = datetimeToTimestamp(to_update_obj_data.str_datetime)

                

                if(//true
                    last_obj_data_EPOCH > last_BUT1_obj_data_EPOCH &&
                    to_update_obj_data_EPOCH > last_obj_data_EPOCH
                ){
                    const diff_epoch = 1000 * 60//1min - interval
                    //diff calc
                    const is_diff_valid = (last_obj_data_EPOCH - last_BUT1_obj_data_EPOCH) > diff_epoch

                    //-----------------------------------------before data Update----------------------------------------------
                    const isPlayBackIndexAndArrayLenEQUALbeforeUpdate = playBackINDEXval1+1 === array1.length
                    const ArrayLenEQUALbeforeUpdate = array1.length

                    if(is_diff_valid){
                        newDATAarray = [...array1, to_update_obj_data]
                        //console.log('now > 1min : ', to_update_obj_data.str_datetime)
                    }
                    else{
                        newDATAarray = [...(array1.slice(0, array1.length-1)), to_update_obj_data]//[].slice- removes the last element & replaced by newDATA
                        //console.log('still < 1min : ', to_update_obj_data.str_datetime)
                    }

                    //slice if len > 240mins = 4hrs
                    const isArrayLenGR = newDATAarray.length > arrayMAXsize
                    const len_diff     = isArrayLenGR && ((newDATAarray.length - arrayMAXsize) >= 0) ? 
                                            (newDATAarray.length - arrayMAXsize) : 0
                    newDATAarray         = isArrayLenGR ? newDATAarray.slice(len_diff, newDATAarray.length) : newDATAarray

                    //console.log('hist_forex_csm length : ', newDATAarray.length)


                    if(//Auth newDATAarray array if valid
                        newDATAarray &&
                        typeof(newDATAarray) === 'object' &&
                        newDATAarray.length >= array1.length
                    ){
                        //is_hist_forex_csm_valid = true
                        setPlaybackDATAarrayChart1(newDATAarray)

                        if(isOnPLAYmode1){
                            //do nothing
                        }
                        else{//if not onPLAY
                            setPlayBackMSGstr1(`${playBackINDEXval1 + 1} / ${newDATAarray.length}`)
                            setPlayBackMAXval1(newDATAarray.length-1 >= 0 ? newDATAarray.length-1 : 0)

                            //------------------------------------------after data Update--------------------------------------------------
                            const isPlayBackIndexAndArrayLenEQUALafterUpdate = playBackINDEXval1+1 === newDATAarray.length
                            const ArrayLenEQUALafterUpdate = newDATAarray.length

                            /*console.log({
                                isPlayBackIndexAndArrayLenEQUALbeforeUpdate,
                                isPlayBackIndexAndArrayLenEQUALafterUpdate,
                                ArrayLenEQUALbeforeUpdate,
                                ArrayLenEQUALafterUpdate
                            })*/

                            if(isPlayBackIndexAndArrayLenEQUALbeforeUpdate){
                                setTf_currs_forex_data(resData)
                                /*if(
                                    ArrayLenEQUALafterUpdate !== ArrayLenEQUALbeforeUpdate &&
                                    ArrayLenEQUALafterUpdate > ArrayLenEQUALbeforeUpdate
                                ){//arrayMAXsize
                                    const arrayLenDIFF = Math.abs( ArrayLenEQUALafterUpdate - ArrayLenEQUALbeforeUpdate )
                                    setPlayBackINDEXval1(playBackINDEXval1+1)
                                }*/
                            }
                            else{
                                //setPlayBackINDEXval1(playBackINDEXval1-1 < 0 ? playBackINDEXval1 : playBackINDEXval1-1)
                            }
                        }
                    }
                }
            }
        }

        
    }


    //----------------Update table_2---------------------
    if(
        isPlayBackBtnClicked2 //&&
        //playbackDATAarrayChart2.length > 0 //meaning atleast 1 data range had been collected from db - set array to [] - after close of playback
    ){

        //----------------Update table_1---------------------
        if(isPlayBackBtnClicked1===false){
            setTf_currs_forex_data(resData)
        }

        
        //----------------Update table_2---------------------
        const arrayMAXsize = 240//240mins = 4hrs
        let array2         = [...playbackDATAarrayChart2]
        let newDATAarray   = []


        if(
            (array2.length === 0) 
            ||
            (
                array2.length === 1 
                && array2[0] 
                && array2[0].str_datetime 
                && typeof(array2[0].str_datetime)==='string' 
                && array2[0].str_datetime.length > '2024-01-01'.length
            )
        ){
            newDATAarray = [...array2, resData]
            

            if(//Auth newDATAarray array if valid
                newDATAarray &&
                typeof(newDATAarray) === 'object' &&
                newDATAarray.length >= array2.length
            ){
                //is_hist_forex_csm_valid = true
                setPlaybackDATAarrayChart2(newDATAarray)

                if(isOnPLAYmode2){
                    //do nothing
                }
                else{
                    setPlayBackMSGstr2(`${playBackINDEXval2 + 1} / ${newDATAarray.length}`)
                    setPlayBackMAXval2(newDATAarray.length-1 >= 0 ? newDATAarray.length-1 : 0)
                }
            }
        }
        else if(array2.length >= 2){
            
            const last_BUT1_obj_data = array2[array2.length - 2]
            const last_obj_data      = array2[array2.length - 1]
            const to_update_obj_data = resData
            

            if(
                last_obj_data &&
                last_BUT1_obj_data &&
                last_obj_data.str_datetime &&
                last_BUT1_obj_data.str_datetime &&
                typeof(last_obj_data.str_datetime)==='string' &&
                typeof(last_BUT1_obj_data.str_datetime)==='string' &&
                last_obj_data.str_datetime.length > '2024-01-01'.length &&
                last_BUT1_obj_data.str_datetime.length > '2024-01-01'.length 
            ){

                const last_BUT1_obj_data_EPOCH = datetimeToTimestamp(last_BUT1_obj_data.str_datetime)
                const last_obj_data_EPOCH      = datetimeToTimestamp(last_obj_data.str_datetime)
                const to_update_obj_data_EPOCH = datetimeToTimestamp(to_update_obj_data.str_datetime)

                

                if(//true
                    to_update_obj_data_EPOCH > last_obj_data_EPOCH
                ){
                    const diff_epoch = 1000 * 60//1min - interval
                    //diff calc
                    const is_diff_valid = (to_update_obj_data_EPOCH - last_BUT1_obj_data_EPOCH) > (diff_epoch * 2)

                    //-----------------------------------------before data Update----------------------------------------------
                    const isPlayBackIndexAndArrayLenEQUALbeforeUpdate = playBackINDEXval2+1 === array2.length
                    const ArrayLenEQUALbeforeUpdate = array2.length

                    if(is_diff_valid){
                        newDATAarray = [...array2, to_update_obj_data]
                        //console.log('now > 1min : ', to_update_obj_data.str_datetime)
                    }
                    else{
                        newDATAarray = [...(array2.slice(0, array2.length-1)), to_update_obj_data]//[].slice- removes the last element & replaced by newDATA
                        //console.log('still < 1min : ', to_update_obj_data.str_datetime)
                    }

                    //slice if len > 240mins = 4hrs
                    const isArrayLenGR = newDATAarray.length > arrayMAXsize
                    const len_diff     = isArrayLenGR && ((newDATAarray.length - arrayMAXsize) >= 0) ? 
                                            (newDATAarray.length - arrayMAXsize) : 0
                    newDATAarray         = isArrayLenGR ? newDATAarray.slice(len_diff, newDATAarray.length) : newDATAarray

                    //console.log('hist_forex_csm length : ', newDATAarray.length)


                    if(//Auth newDATAarray array if valid
                        newDATAarray &&
                        typeof(newDATAarray) === 'object' &&
                        newDATAarray.length >= array2.length
                    ){
                        //is_hist_forex_csm_valid = true
                        setPlaybackDATAarrayChart2(newDATAarray)

                        if(isOnPLAYmode2){
                            //do nothing
                        }
                        else{
                            setPlayBackMSGstr2(`${playBackINDEXval2 + 1} / ${newDATAarray.length}`)
                            setPlayBackMAXval2(newDATAarray.length-1 >= 0 ? newDATAarray.length-1 : 0)

                            //------------------------------------------after data Update--------------------------------------------------
                            const isPlayBackIndexAndArrayLenEQUALafterUpdate = playBackINDEXval2+1 === newDATAarray.length
                            const ArrayLenEQUALafterUpdate = newDATAarray.length

                            /*console.log({
                                isPlayBackIndexAndArrayLenEQUALbeforeUpdate,
                                isPlayBackIndexAndArrayLenEQUALafterUpdate,
                                ArrayLenEQUALbeforeUpdate,
                                ArrayLenEQUALafterUpdate
                            })*/

                            if(isPlayBackIndexAndArrayLenEQUALbeforeUpdate){
                                setCurr_tfs_forex_data(resData)
                            }
                            else{

                            }
                        }
                    }
                }
            }
        }
    }
    //--------------------------------------replayBACK---MODE---------------------------------------------------------------------------------------

}
//-------------------------------------------------------------------------------------





useEffect(() => {
    //globalDataStorageObj['live_forex_csm']

    if(
        //false &&
        globalDataStorageObj['live_forex_csm'] &&//if key exists
        globalDataStorageObj['live_forex_csm'].data &&//if its data exists
        globalDataStorageObj['live_forex_csm'].data.str_datetime//atleast contain h1 data
    ){
        //console.log('live_forex_csm EXISTs')
        const resData = globalDataStorageObj['live_forex_csm'].data

        if(isStartDateTimeSet===false){
            setIsStartDateTimeSet(true)
        }

        setTimeout(() => {

            //datetime to be used on all candlestick charts
            setLiveMT4datetimeSTR(resData.str_datetime)
            setLiveMT4dayOfWeekNUM(resData.day_of_week)
            //console.log('day of week --- ', resData.day_of_week)


            //----------------------------------------
            if(//replayBACK---MODE---Update
                isPlayBackBtnClicked1 ||
                isPlayBackBtnClicked2
            ){
                db_to_PLAYBACKupdateFUNC(resData)
            }
            else if(
                isPlayBackBtnClicked1===false &&
                isPlayBackBtnClicked2===false 
            ){
                //setLiveForexTFdataArray2([resData])
                setTf_currs_forex_data(resData)
                setCurr_tfs_forex_data(resData)
            }
            //-----------------------------------------



            if(isChartDATAloadedAtleastOnce===false){
                setIsChartDATAloadedAtleastOnce(true)
            }

            //console.log('current_date : ', resData.str_datetime)

            //console.log(resData.str_datetime)
            /*setTimeout(()=>{
                if(document.getElementById('isLoadingId')){
                    document.getElementById('isLoadingId').style.display = 'none'
                }
            }, 500)*/

        }, localStorage.getItem('isPhone') !== null ? 500 : 0)
    }
    else{
        //console.log('live_forex_csm MISSING')
        //console.log(globalDataStorageObj)
    }


},[globalInstruTFuseEffectReRunCounter])













//-----------------------------------------------------------------------------
//timeframe vs. currencies:
//-------------------------
const [isAllCURRsSelected, setIsAllCURRsSelected] = useState(false)

const [tf, setTF] = useState(
    (localStorage.getItem('tf' + forexORcrypto) === null || localStorage.getItem('tf' + forexORcrypto) === undefined)
    ?
    "m5" : localStorage.getItem('tf' + forexORcrypto)
)


const [currArray, setCurrArray] = useState(
    //["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"]
    
    (localStorage.getItem('curr_array' + forexORcrypto) === null || localStorage.getItem('curr_array' + forexORcrypto) === undefined)
    ?
    ["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"]
    : 
    JSON.parse( 
        localStorage.getItem('curr_array' + forexORcrypto)
    )
    
)


const [defaultCurrArray, setDefaultCurrArray] = useState(["EUR","GBP","AUD","NZD"])
const extra_curr = ["USD","CAD","CHF","JPY"]


const switchOnAndInsertCURRbyOrder = (curr_to_insert)=>{
    const currIndexArray = ["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"]
    const insertedCURRarray = [...currArray, curr_to_insert]

    const orderedCURRarray = currIndexArray.filter((curr_str, curr_str_index)=>{
        return insertedCURRarray.includes(curr_str) === true
    })

    setCurrArray(orderedCURRarray)
}
//-----------------------------------------------------------------------------


//-----------------------------------------------------------------------------
//currency vs. timeframes:
//-------------------------
const [tfArray, setTfArray] = useState(
    (localStorage.getItem('tf_array' + forexORcrypto) === null || localStorage.getItem('tf_array' + forexORcrypto) === undefined)
    ?
    ["mn", "w1", "d1", "h12", "h8", "h6", "h4", "h2", "h1", "m45", "m30", "m15", "m5"]
    :
    JSON.parse(localStorage.getItem('tf_array' + forexORcrypto))
    //["mn", "w1", "d1", "h4", "h1", "m30", "m15", "m5"] 
)


const switchOnAndInsertTFbyOrder = (tf_to_insert)=>{
    const tfIndexArray = ["mn", "w1", "d1", "h12", "h8", "h6", "h4", "h2", "h1", "m45", "m30", "m15", "m5"]

    const insertedTFarray = [...tfArray, tf_to_insert]

    const orderedTFarray = tfIndexArray.filter((tf_str, tf_str_index)=>{
    return insertedTFarray.includes(tf_str) === true
    })

    setTfArray(orderedTFarray)

    localStorage.setItem('tf_array' + forexORcrypto, 
        JSON.stringify(
            orderedTFarray
        )
    )

}
//-----------------------------------------------------------------------------





return ( 
<div className={'homeBodyWidthResizer'}

style={{
    width:'100%',
    //width:`${window.innerWidth > CHROME_MIN_INNER_WIDTH ? '60%' : '100%'}`,
    margin:'0px auto',
    //marginTop: '20px',
    marginTop: '10px',
    marginBottom: '0px',
    //borderRadius : '15px',
    //border: '1px solid black',
    position: 'relative',
}}
>
    

    {true &&
        <CSMheader
        forexORcrypto={forexORcrypto}
        tf={tf}
        setTF={setTF}
        tableType={'TF vs. CURRs'}

        csmDATAtypeIDstr={csmDATAtypeIDstr1}
        setCsmDATAtypeIDstr={setCsmDATAtypeIDstr1}
        switchPairChartTOindexFUNC={switchPairChartTOindexFUNC}
        />
    }


    {
    true && 
    tf_currs_forex_data['m5'] !== undefined &&


    <div
    style={{
        position: 'relative',
    }}
    >

        <div id='tf_currs_id' //className={`chartBarScrollClass `} 
        style={{
            //border: '1px solid black',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            //marginTop:'30px'
        }}
        >
            <TFCurrsHistogram 

            liveUpdateSECcountdown={liveUpdateSECcountdown}

            //----------playBACK-----FUNCs-------------------------------
            isPlaybackDATAloadedAndVALID={isPlaybackDATAloadedAndVALID}
            isINDEXatEndsFUNC={isINDEXatEndsFUNC}
            playBACKuseEffectNULLIFier={playBACKuseEffectNULLIFier1}
            playBackMSGstr={playBackMSGstr1}
            setPlayBackMSGstr={setPlayBackMSGstr1}
            playBackINDEXval={playBackINDEXval1}
            setPlayBackINDEXval={setPlayBackINDEXval1}
            playBackMAXval={playBackMAXval1}
            isOnPLAYmode={isOnPLAYmode1} 
            setIsOnPLAYmode={setIsOnPLAYmode1}
            isPlayBackBtnClicked={isPlayBackBtnClicked1}
            setIsPlayBackBtnClicked={setIsPlayBackBtnClicked1}
            onCLOSEplaybackDataArrayRESET={onCLOSEplaybackDataArrayRESET}
            updateCSMchartByPLAYBACKid={updateCSMchartByPLAYBACKid}
            getPlayBACKdataFromDB={getPlayBACKdataFromDB}

            switchPairChartTOindexFUNC={switchPairChartTOindexFUNC}
            currPairArray={currPairArray}
            reloadALLchartGlobalFUNC={reloadALLchartGlobalFUNC}
            forexORcrypto={forexORcrypto}
            csmDATAtypeIDstrObj={csmDATAtypeIDstrObj}
            csmDATAtypeIDstr={csmDATAtypeIDstr1}

            isChartHeaderOptionsSwitchedAtleastOnce={isChartHeaderOptionsSwitchedAtleastOnce}
            setIsChartHeaderOptionsSwitchedAtleastOnce={setIsChartHeaderOptionsSwitchedAtleastOnce}

            forexTFdataArray={tf_currs_forex_data}

            isAllCURRsSelected={isAllCURRsSelected}
            setIsAllCURRsSelected={setIsAllCURRsSelected}
            currArray={currArray}
            setCurrArray={setCurrArray}
            defaultCurrArray={defaultCurrArray}
            setDefaultCurrArray={setDefaultCurrArray}
            extra_curr={extra_curr}
            switchOnAndInsertCURRbyOrder={switchOnAndInsertCURRbyOrder}

            headerName={'tf_currs'}
            tf={tf}
            setTF={setTF}
            />

            
        </div>

        
    </div>
    
    }




    {
    true &&  
    curr_tfs_forex_data['m5'] !== undefined 

    && 
    
    <div
    style={{
        position: 'relative',
    }}
    >


        {true &&
            <CSMheader
            forexORcrypto={forexORcrypto}
            tableType={'CURR vs. TFs'}

            csmDATAtypeIDstr={csmDATAtypeIDstr2}
            setCsmDATAtypeIDstr={setCsmDATAtypeIDstr2}
            switchPairChartTOindexFUNC={switchPairChartTOindexFUNC}
            />
        }


        <div id='curr_tfs_id'  //className='chartBarScrollClass' 
        style={{
            //border: '1px solid black',
            display: 'flex',
            //justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            marginTop:'10px'
        }}
        >
            <CurrTFsHistogram

            liveUpdateSECcountdown={liveUpdateSECcountdown}

            //----------playBACK-----FUNCs-------------------------------
            isPlaybackDATAloadedAndVALID={isPlaybackDATAloadedAndVALID}
            isINDEXatEndsFUNC={isINDEXatEndsFUNC}
            playBACKuseEffectNULLIFier={playBACKuseEffectNULLIFier2}
            playBackMSGstr={playBackMSGstr2}
            setPlayBackMSGstr={setPlayBackMSGstr2}
            playBackINDEXval={playBackINDEXval2}
            setPlayBackINDEXval={setPlayBackINDEXval2}
            playBackMAXval={playBackMAXval2}
            isOnPLAYmode={isOnPLAYmode2} 
            setIsOnPLAYmode={setIsOnPLAYmode2}
            isPlayBackBtnClicked={isPlayBackBtnClicked2}
            setIsPlayBackBtnClicked={setIsPlayBackBtnClicked2}
            onCLOSEplaybackDataArrayRESET={onCLOSEplaybackDataArrayRESET}
            updateCSMchartByPLAYBACKid={updateCSMchartByPLAYBACKid}
            getPlayBACKdataFromDB={getPlayBACKdataFromDB}

            reloadALLchartGlobalFUNC={reloadALLchartGlobalFUNC}
            forexORcrypto={forexORcrypto}
            csmDATAtypeIDstrObj={csmDATAtypeIDstrObj}
            csmDATAtypeIDstr={csmDATAtypeIDstr2}

            isChartHeaderOptionsSwitchedAtleastOnce={isChartHeaderOptionsSwitchedAtleastOnce}
            setIsChartHeaderOptionsSwitchedAtleastOnce={setIsChartHeaderOptionsSwitchedAtleastOnce}

            forexTFdataArray={curr_tfs_forex_data}

            tfArray={tfArray}
            setTfArray={setTfArray}
            switchOnAndInsertTFbyOrder={switchOnAndInsertTFbyOrder}
            />

            
            
        </div>



    </div>
    }



    















    
</div>
)
}




function datetimeToTimestamp(datetime){
    try {
        // Parse the datetime string into a Date object
        const dateObj = new Date(datetime);

        // Check if the date is valid
        if (isNaN(dateObj.getTime())) {
            //throw new Error('Invalid date format');
            return datetimeToTimestamp('1970-01-01 00:00:00')
        }

        // Get the timestamp (milliseconds since Unix epoch)
        const timestamp = dateObj.getTime();

        return timestamp;

    } catch (e) {
        //console.error(e.message);
        //return 'error';
        return datetimeToTimestamp('1970-01-01 00:00:00')
    }
}



export default ForexHomeBody

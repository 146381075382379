//NOTE: we had disable App - <React.StrictMode> to prevent useEffect Hook from running twice - in order to prevent the chart from printing twice

import { useEffect, useRef, useState } from 'react';

import { BsLock, BsUnlock } from "react-icons/bs";
import { MdZoomOutMap, MdZoomInMap   } from "react-icons/md";
import { TfiControlForward } from "react-icons/tfi";
import { MdOutlineArrowBackIosNew } from "react-icons/md"
import { MdOutlineDraw } from "react-icons/md" 
import { FiCheck } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { LiaHandPointer } from "react-icons/lia";
import { MdDoNotTouch } from "react-icons/md";
import { IoIosRefresh } from "react-icons/io";
import { SlRefresh } from "react-icons/sl";
import { CgBorderStyleDashed } from "react-icons/cg";
import { FaHandPointRight } from "react-icons/fa";


import ChartHeader from './ChartHeader'
import PsychoCompo from './PsychoCompo'
import HistStrengthMeterCompo from './HistStrengthMeterCompo'

import {timeZoneDatetimeConverter} from "../../../Helpers/timeZoneDatetimeConverter"

import imageFAKERwhite from './../../../Assets/Images/imageFAKERwhite.png'





const Chart = ({ ///*data,*/ width, height, 

    canvasCompoId,//=243
    chartInRowName,//chart_1
    isDontTouchChart,
    setIsDontTouchChart,
    navToOnlyCHARTnum1instruTFuseEffectReRunCounter,

    liveUpdateSECcountdown,
    
    liveMT4datetimeSTR, zero_gmt_deviation,

    isShowServerDownAlertCompo, setIsShowServerDownAlertCompo,

    reloadAllChartsUseEffectCounter, 
    setReloadAllChartsUseEffectCounter,

    canvasHeight,
    canvasWidth,

    forexORcrypto,

    tfArray,
    currIndexArray,
    currPairArray,

    chartInfoObj,
    setChartInfoObj,

    defaultChartInstruTFsettingsObj,

    dataShiftCount,
    defaultNumOfCandlesToShowOnChart,

    globalDataStorageObj,
    setGlobalDataStorageObj,

    //-----------------------------------------------------------
    globalInstruTFuseEffectReRunCounter, 
}) => {



    //const zero_gmt_deviation = 3//mql4 +3


    //-----------------------------------------------------------
    const canvasRef = useRef();

    const RSIperiodVAL = 14

    const [isDATAloaded, setIsDATAloaded] = useState(false)
    

    const [currsHighlightedArray, setCurrsHighlightedArray] = useState(['EUR', 'GBP', 'AUD', 'NZD', 'USD', 'CAD', 'CHF', 'JPY'])

    const [isInstruTFloaded, setIsInstruTFloaded] = useState(false)

    const [isRSIcorrListMaximized, setIsRSIcorrListMaximized] = useState(true)

    const [isLockedChartWARNING, setIsLockedChartWARNING] = useState(false)
    const [isLockIcon, setIsLockIcon] = useState(true)
    const [isShowStochMA, setIsShowStochMA] = useState(true)

    const [replayModeInfo, setReplayModeInfo] = useState('')//prevReplayDATETIME('')
    const [prevReplayModeInfo, setPrevReplayModeInfo] = useState('')
   
    const [isOnTouchMove, setIsOnTouchMove] = useState(false)
    //const [isDontTouchChart, setIsDontTouchChart] = useState(false)
    const [isShowLockedChartMovementsBtn, setIsShowLockedChartMovementsBtn] = useState(false)

    const [wasChartLockedBeforeOnClickSettingsDisplay, setWasChartLockedBeforeOnClickSettingsDisplay] = useState(false)

    const [isChartHeaderOptionsSwitchedAtleastOnce, setIsChartHeaderOptionsSwitchedAtleastOnce] = useState(false)
    const [isChartDATAloadedAtleastOnce, setIsChartDATAloadedAtleastOnce] = useState(false)
    const [isChartDATArefreshedAtleastOnce, setIsChartDATArefreshedAtleastOnce] = useState(false)

    const [isReplayLineDrawing, setIsReplayLineDrawing] = useState(false)

    const [mouseDownCount, setMouseDownCount] = useState(0)

    const [rsiDATETIMEnumCountObj, setRsiDATETIMEnumCountObj] = useState({
        datetimeStart :1,
        datetimeEnd   :1
    })

    const [twofingerTouchXcords, setTwofingerTouchXcords] = useState({})

    const [onlyOneClickDrawerInfoObj, setOnlyOneClickDrawerInfoObj] = useState({})

    const [beforeYmagCandleIndexInfoObj, setBeforeYmagCandleIndexInfoObj] = useState({})

    const [onTouchMoveCount, setOnTouchMoveCount] = useState(0)

    const [isShowCorrelationScreenCover, setIsShowCorrelationScreenCover] = useState(false)
    const [isShowPsychoScreenCover, setIsShowPsychoScreenCover] = useState(false)
    
    const [isDeleteAllChartDrawings, setIsDeleteAllChartDrawings] = useState(false)
    const [isMakeAllDrawingsVisible, setIsMakeAllDrawingsVisible] = useState(false)
    const [isMakeAllDrawingsInvisible, setIsMakeAllDrawingsInvisible] = useState(false)
    const [hoveredDrawingToolName, setHoveredDrawingToolName] = useState('')

    const [trendlineTypeToDraw2, setTrendlineTypeToDraw2] = useState('')
    
    const [isLockedVertiLineSelected, setIsLockedVertiLineSelected] = useState(false)
    const [isLockedVertiLineHovered, setIsLockedVertiLineHovered] = useState(false)
    const [lockedVertiLineSelectedDatetime, setLockedVertiLineSelectedDatetime] = useState('')

    const [moreORlessToolsVal, setMoreORlessToolsVal] = useState('more')
    


    const drawingToolsNamesArray = [//make state for it later
        'trendline1_1',//angularTL
        'trendline4',//HoriTL
        'trendline5',//VertiTL
        'trendline3',//centerToEndHoriTL
        'trendline2',//shortHoriTL
        'pip',//pipChecker
        'trendline1_2',//angularPointerTL
        'trendline6_1',//rect1
        'trendline6_2',//rect2-Color
        'trendline7_1',//rectSquareSELL
        'trendline7_2',//rectSquareBUY
        'trendline8',//circle
    ]

    const currColorObj = {
        'EUR' : 'rgb(0, 51, 255)',
        'GBP' : 'rgb(255, 0, 0)', 
        'AUD' : 'rgb(0, 204, 255)', 
        'NZD' : 'rgb(0, 204, 0)', 
        'USD' : 'rgb(255, 51, 204)', 
        'CAD' : 'rgb(153, 0, 255)', 
        'CHF' : 'rgb(153, 102, 0)', 
        'JPY' : 'rgb(255, 153, 0)',
        'ALL' : 'rgb(0, 0, 0)',
        'none' : 'rgb(128, 128, 128)',//gray
    }

    const currColorObj2 = {
        'EUR' : 'rgba(0, 51, 255, 0.99)',
        'GBP' : 'rgba(255, 0, 0, 0.99)', 
        'AUD' : 'rgba(0, 204, 255, 0.99)', 
        'NZD' : 'rgba(0, 204, 0, 0.99)', 
        'USD' : 'rgba(255, 51, 204, 0.99)', 
        'CAD' : 'rgba(153, 0, 255, 0.99)', 
        'CHF' : 'rgba(153, 102, 0, 0.99)', 
        'JPY' : 'rgba(255, 153, 0, 0.99)',
        'ALL' : 'rgba(0, 0, 0, 0.99)',
        'none' : 'rgba(255, 255, 255, 0.99)',//gray
    }



    const [currentPRICEdatetimeObj, setCurrentPRICEdatetimeObj] = useState({})

    const [rsiORIdata, setRsiORIdata] = useState([])
    const [rsiDATETIMEobjArrayIDs, setRsiDATETIMEobjArrayIDs] = useState({})
    const [corrCalcDATETIME, setCorrCalcDATETIME] = useState({
        datetimeStart:'', datetimeEnd:'' 
    })



    const [rsiCORRdata, setRsiCORRdata] = useState({ // corr:[], rsm:[]
        
        corr: [],
        rsm: []
          
    })

    

    const [data, setData] = useState([])
    

    const [dataCandleStartEndIndex, setDataCandleStartEndIndex] = useState({
        dataCandleStartIndex : 0,//data.length - defaultNumOfCandlesToShowOnChart,
        dataCandleEndIndex : 0,//data.length 
    })
    
    const [beforeYmagChartInfoTracker, setBeforeYmagChartInfoTracker] = useState({
        dataCandleStartIndex : 0,//data.length - defaultNumOfCandlesToShowOnChart,
        dataCandleEndIndex : 0,//data.length 
    })

    const [XchartShiftPX, setXchartShiftPX]   = useState(0)//25 - chart X-margin
    const [YchartShiftPX, setYchartShiftPX]   = useState(0)//25 - chart Y-margin

    const [chartXvelocity, setChartXvelocity] = useState(0)//-chart horizontal scroll 
    const [chartYvelocity, setChartYvelocity] = useState(0)//-chart vertical scroll
    const [chartXvelocityPrevVal, setChartXvelocityPrevVal] = useState(chartXvelocity)//-chart horizontal scroll 

    const [crossHairOnMoveCordVals, setCrossHairOnMoveCordVals] = useState({x:-50, y:-50})
    const [trendlineCordStart, setTrendlineCordStart] = useState({x:-1, y:-1})
    const [trendlineCordEnd, setTrendlineCordEnd] = useState({x:-1, y:-1})
    const [trendlineTypeToDraw, setTrendlineTypeToDraw] = useState('')

    const [isDrawingActivated, setIsDrawingActivated] = useState(false)

    const [isTouchScreenDevice, setIsTouchScreenDevice] = useState(
        'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    )

    const [pipCheckerCordStart, setPipCheckerCordStart]     = useState({x:-1, y:-1})
    const [pipCheckerCordEnd, setPipCheckerCordEnd]         = useState({x:-1, y:-1})
    const [isPipCheckerActivated, setIsPipCheckerActivated] = useState(false)

    const [isEditingDrawing, setIsEditingDrawing] = useState(false)

    const [isDisplayChartTextInput, setIsDisplayChartTextInput] = useState(false)
    const [isRectangleTextInput, setIsRectangleTextInput] = useState(false)
    const [chartTextInputStringTracker, setChartTextInputStringTracker] = useState('')

    const [isRectangleDrawingColored, setIsRectangleDrawingColored] = useState(false)
    const [isRectangleSquareDrawingSell, setIsRectangleSquareDrawingSell] = useState(false)
    const [defaultRectSquareDrawingCandleWidthCount, setDefaultRectSquareDrawingCandleWidthCount] = useState(10)
    const [defaultRectSquareDrawingAtChartHeightRatio, setDefaultRectSquareDrawingAtChartHeightRatio] = useState(0.1)

    
    ////chartCandleHeightMagnification
    //+0.01 = +1%, +0.1=+10%
    const [YmagnificationPercRatio, setYmagnificationPercRatio] = useState(0)//- [OHLC] * %incre/decre
    const [XmagnificationPercRatio, setXmagnificationPercRatio] = useState(0)//- candleWidthSize %incre/decre when no chart height scaling, when YmagnificationPercRatio > 0
    const [isBlinkLockChartIcon, setIsBlinkLockChartIcon] = useState(false)
    const [YChartHeightDeMagRatioLimitUptoAbsOfOne, setYChartHeightDeMagRatioLimitUptoAbsOfOne] = useState(-0.9)

    const [priceLabelMarginSize, setPriceLabelMarginSize] = useState(50)
    const [datetimeLabelMarginSize, setDatetimeLabelMarginSize] = useState(20)


    const nullifyAllChartDrawingsFUNC = ()=>{
        //-------------------------------------
        //nullify All drawing activity
        setSelectedDrawingForEditInfoIdObj({})
        setTrendlineTypeToDraw('')
        setIsDrawingActivated(false)
        setIsPipCheckerActivated(false)
        setIsReplayLineDrawing(false)
        //-------------------------------------*/
    }


    

    const [isScaleCandlesToCanvasHeight, setIsScaleCandlesToCanvasHeight] = useState(true)
    //-----------------------------------------------------------

    const [useEffectReRunCounter, setUseEffectReRunCounter] = useState(Date.now())
    const [localInstruTFuseEffectReRunCounter, setLocalInstruTFuseEffectReRunCounter] = useState(Date.now())

    //------------------------------------------------------------------------------
    //is
    const [isYmagnificationActivated, setIsYmagnificationActivated] = useState(false)
    const [isMouseDown, setIsMouseDown] = useState(false)
    const [isMouseOut, setIsMouseOut] = useState(false)
    const [isOverPriceLabelMargin, setIsOverPriceLabelMargin] = useState(false)
    const [isOutPriceLabelMargin, setIsOutPriceLabelMargin] = useState(false)
    const [onMouseDownVal, setOnMouseDownVal] = useState({})
    const [isOnTouchStart2fingers, setIsOnTouchStart2fingers] = useState(false)

    const [isUseEffectChartLoadStarted, setIsUseEffectChartLoadStarted] = useState(false)

    const [isTrendlineEndPointy, setIsTrendlineEndPointy] = useState(false)
    const [pointyTrendlineDefaultInfo, setPointyTrendlineDefaultInfo] = useState({defaultPointerLength:15})

    const [isChartReplayOn, setIsChartReplayOn] = useState(false)


    const [isHideAllDrawings, setIsHideAllDrawings] = useState(false)

    const [chartCandleBoxWidthStartPX, setChartCandleBoxWidthStartPX] = useState(0)

    const [crossHairMidStartXcordDrawerInfo, setCrossHairMidStartXcordDrawerInfo] = useState({xCord : 0, datetime : ''})

    const [drawingEditCursorOutlierLimit, setDrawingEditCursorOutlierLimit] = useState(
        10
    )//10px - make the px of mobile bigger
    const [cursorAndDrawingEditIdObj, setCursorAndDrawingEditIdObj] = useState({/*cursorType:"pointer"*/})//cursorType:"default"

    const [squareNavigatorMargin, setSquareNavigatorMargin] = useState(drawingEditCursorOutlierLimit*1.8 * 0.5)
    const [squareNavigatorWidthHeight, setSquareNavigatorWidthHeight] = useState(drawingEditCursorOutlierLimit*1.8)

    const [selectedDrawingForEditInfoIdObj, setSelectedDrawingForEditInfoIdObj] = useState({/*cursorType:"pointer"*/})
    const [onDrawingMoveInfoTrackerObj, setOnDrawingMoveInfoTrackerObj] = useState({})
    const [isDrawingDoneOnMouseUp, setIsDrawingDoneOnMouseUp] = useState(false)
  

    const [maxMagnificationCandleCountLimit, setMaxMagnificationCandleCountLimit] = useState(10-1)
    


    const nullifyAllDrawingActivitiesFUNC = () => {

      setIsPipCheckerActivated(false)

      //------------------------------------------------------------------------------------------------------------
      //nullify any drawing activity
      setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
      setIsDrawingActivated(false)
      setTrendlineTypeToDraw('')
      setIsRectangleDrawingColored(false)
      setIsRectangleTextInput(false)
      setIsReplayLineDrawing(false)

      setIsDeleteAllChartDrawings(false)
      setIsMakeAllDrawingsVisible(false)
      setIsMakeAllDrawingsInvisible(false)
      //------------------------------------------------------------------------------------------------------------

      //already defined in ChartHeader
      /*if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
        setIsHideAllDrawings(false)
      }*/
    }


    // Define the event listener function
    const preventScroll = (e) => {
        if (e.cancelable) {
            e.preventDefault();
        }
    };


    



    const fromYmagToDEFAULTchartStructureFUNC = ()=>{

        //console.log('i clashed')
        if(data.length>0){
    
            if(
                //false &&
                beforeYmagCandleIndexInfoObj &&
                beforeYmagCandleIndexInfoObj.startIndex===undefined
            ){
                setDataCandleStartEndIndex({
                    dataCandleStartIndex : data.length - Math.floor(canvasWidth / crossHairMidStartXcordDrawerInfo.candleWidthSize),//works when chartWidth===chartElWidth
                    dataCandleEndIndex : data.length 
                })
            }
            else if(
                //false &&
                beforeYmagCandleIndexInfoObj &&
                beforeYmagCandleIndexInfoObj.startIndex!==undefined &&
                beforeYmagCandleIndexInfoObj.startIndex
            ){
                
                //setBeforeYmagCandleIndexInfoObj({
                const startIndex            = beforeYmagCandleIndexInfoObj.startIndex
                const endIndex              = beforeYmagCandleIndexInfoObj.endIndex
                const candleWidthSizeBefore = beforeYmagCandleIndexInfoObj.candleWidthSize
                
                const chartXMagnificationRatio  = XmagnificationPercRatio
    
                const candleCountMoved = Math.floor(Math.abs(chartXvelocity) / ((candleWidthSizeBefore * (1 + chartXMagnificationRatio))))
                const candleInterval4candleWidthSize = Math.floor(canvasWidth / (candleWidthSizeBefore * (1 + chartXMagnificationRatio)))
    
                
    
                const startIndexRes = chartXvelocity >= 0 ? (startIndex - candleCountMoved) : (startIndex + candleCountMoved) 
                const endIndexRes   = startIndexRes + candleInterval4candleWidthSize//(endIndex - candleCountMoved)
    
                let dataCandleStartIndex_calc = data.length - Math.floor(canvasWidth / crossHairMidStartXcordDrawerInfo.candleWidthSize)
                let dataCandleEndIndex_calc   = data.length
    
                if(endIndexRes > data.length){
                    const start = startIndexRes - Math.abs(endIndexRes - data.length)
                    dataCandleStartIndex_calc = start < 0 ? 0 : start
                    dataCandleEndIndex_calc   = data.length
                }
                else if(startIndexRes < 0){
                    const end = endIndexRes + Math.abs(startIndexRes)
                    dataCandleStartIndex_calc = 0
                    dataCandleEndIndex_calc   = end > data.length ? data.length : end
                }
                else{
                    dataCandleStartIndex_calc = startIndexRes
                    dataCandleEndIndex_calc   = endIndexRes
                }
    
    
                setDataCandleStartEndIndex({
                    dataCandleStartIndex : dataCandleStartIndex_calc,//(startIndexRes < 0) ? 0 : (startIndexRes >= data.length) ? data.length-maxMagnificationCandleCountLimit : startIndexRes,
                    dataCandleEndIndex   : dataCandleEndIndex_calc//(endIndexRes > data.length) ? data.length : (endIndexRes <=0) ? 0+maxMagnificationCandleCountLimit : endIndexRes
                })
    
                setBeforeYmagCandleIndexInfoObj({})//*/
    
                /*console.log({
                    chartXvelocityBefore,
                    chartXvelocityAfter
                })*/
            }
            else{
                setDataCandleStartEndIndex({
                    dataCandleStartIndex : data.length - defaultNumOfCandlesToShowOnChart,
                    dataCandleEndIndex   : data.length 
                })
        
                setBeforeYmagChartInfoTracker({})//will be set during on Ymag click
            }
    
    
            setChartXvelocity(0)
            setChartYvelocity(0)
            setYmagnificationPercRatio(0)
            setXmagnificationPercRatio(0)
            setIsYmagnificationActivated(false)
            setIsDrawingActivated(false)
            setSelectedDrawingForEditInfoIdObj({})
            setCursorAndDrawingEditIdObj({})
    
    
            setUseEffectReRunCounter(Date.now())
        }
    
    }






    const chartDataPARSERfunc = (obj)=>{

      const timeframe     = obj.timeframe
      const instruNameSTR = obj.instruNameSTR
      const candleORrsiSTR= obj.candleORrsiSTR
      const indexORpairSTR= obj.indexORpairSTR

      const isCandleChart = obj.isCandleChart
      const isRSIchart    = obj.isRSIchart
      const isSTOCHchart  = obj.isSTOCHchart
      const isCORRchart   = obj.isCORRchart
      const isINDEXchart  = obj.isINDEXchart
      const isPAIRrsiORstochLineChart    = obj.isPAIRrsiORstochLineChart
      const isEitherRSIorSTOCHindexChart = obj.isEitherRSIorSTOCHindexChart
      const RSIperiod                    = obj.RSIperiod
      const isCurrIndex                  = obj.isCurrIndex

      let dbData        = obj.resDataObj.data//let keyword used as wouild be edited along the way
      const resDataObj  = obj.resDataObj

      if(dbData && dbData.length > 0){//does data ARRAY exists & is its length > 0

        setCurrentPRICEdatetimeObj({
          datetimeStart : dbData[0].datetime,
          datetimeEnd   : dbData[dbData.length-1].datetime
        })



        if(isCandleChart===false){
            //calcRSI(dataArray, currpair, periodNum)
            //isINDEXchart


            let calcRESarray = []
            //const calcRESarray = stochDATA

            if(isINDEXchart){

                /*if(isRSIchart){
                    const calcRES = calcRSI_all(dbData, RSIperiod)
                    calcRESarray  = typeof(calcRES)==='object' ? calcRES : []
                    //console.log({calcRESarray})
                }*/
                if(isRSIchart){

                    const minRsiPERIOD = 5
                    const data = dbData
                    let period = chartInfoObj.rsiPeriod===undefined ? 14 : Number(chartInfoObj.rsiPeriod)//14
                    period = dbData.length > period ? period : minRsiPERIOD

                    if(data.length > minRsiPERIOD){
                        const corrRES = calcRSI_all(dbData, period)
                        calcRESarray = typeof(corrRES)==='object' ? corrRES : [{
                            datetime: '1970-01-01 00:00:00',
                            EUR: [0,0], GBP: [0,0], AUD: [0,0], NZD: [0,0], USD: [0,0], CAD: [0,0], CHF: [0,0], JPY: [0,0]
                        }]
                    }
                    else{
                        calcRESarray = [{
                            datetime: '1970-01-01 00:00:00',
                            EUR: [0,0], GBP: [0,0], AUD: [0,0], NZD: [0,0], USD: [0,0], CAD: [0,0], CHF: [0,0], JPY: [0,0]
                        }]
                    }
                    //console.log({calcRESarray})
                    //console.log({corrRES})
                }
                else if(isSTOCHchart){
                   
                    const minStochPERIOD = 5
                    const data = dbData
                    let period = chartInfoObj.stochPeriod===undefined ? 10 : Number(chartInfoObj.stochPeriod)//5
                    period = dbData.length > period ? period : minStochPERIOD

                    if(data.length > minStochPERIOD){
                        const corrRES = calcSTOCH_all(dbData, period) 
                        calcRESarray = typeof(corrRES)==='object' ? corrRES : [{
                            datetime: '1970-01-01 00:00:00',
                            EUR: [0,0], GBP: [0,0], AUD: [0,0], NZD: [0,0], USD: [0,0], CAD: [0,0], CHF: [0,0], JPY: [0,0]
                        }]
                    }
                    else{
                        calcRESarray = [{
                            datetime: '1970-01-01 00:00:00',
                            EUR: [0,0], GBP: [0,0], AUD: [0,0], NZD: [0,0], USD: [0,0], CAD: [0,0], CHF: [0,0], JPY: [0,0]
                        }]
                    }
                    //console.log({calcRESarray})
                    //console.log({corrRES})
                }
                else if(isCORRchart){
                   
                    const minCorrPERIOD = 5
                    const data = dbData//.slice(0,50)//corrPeriod : 24,
                    const corrInstruName = chartInfoObj.corrInstruName===undefined ? 'USD' : chartInfoObj.corrInstruName
                    let period = chartInfoObj.corrPeriod===undefined ? 14 : Number(chartInfoObj.corrPeriod)//14
                    period = dbData.length > period ? period : minCorrPERIOD

                    if(data.length > minCorrPERIOD){
                        const corrRES = CORRcalcFUNC(data, currPairArray, period, corrInstruName)
                        calcRESarray = typeof(corrRES)==='object' ? corrRES : [{
                            datetime: '1970-01-01 00:00:00',
                            EUR: [0,0], GBP: [0,0], AUD: [0,0], NZD: [0,0], USD: [0,0], CAD: [0,0], CHF: [0,0], JPY: [0,0]
                        }]
                    }
                    else{
                        calcRESarray = [{
                            datetime: '1970-01-01 00:00:00',
                            EUR: [0,0], GBP: [0,0], AUD: [0,0], NZD: [0,0], USD: [0,0], CAD: [0,0], CHF: [0,0], JPY: [0,0]
                        }]
                    }
                    //console.log({calcRESarray})
                    //console.log({corrRES})
                }
            }
            /*else{//calcRSI(dataArray, currpair, periodNum)
                //calcSTOCH(dataArray, currpair)
                calcRESarray = isRSIchart ? calcRSI(dbData, instruNameSTR, RSIperiod) : calcSTOCH(dbData, instruNameSTR)
            }*/

            if(calcRESarray[0].datetime){
                //NOTICE:
                //never assign: resDataObj.data = calcRESarray, as will make rsi & stoch crash, as well leading to candle crash
                dbData = calcRESarray
                //console.log('calcRESarray : ', calcRESarray)
            }
        }


        


        const adjustedAndPreparedData = [
            ...(
                getNextOrPrevDatetimeByTF(
                    dbData[0].datetime,//datetime, 
                    timeframe, 
                    "prev",//nextORprevDatetimesToGet, 

                    //-------------------------------------------------------------------------------------------------------------------------------------
                    //find out why any data of length < 500 after adding shiftCount : crashes the chart or does not have candlesticks open the chart at all
                    //eg: d1 && mn - data length is < 500 therefore keep crashing or not showing on chart 
                    (isCandleChart===false ? RSIperiod : 0) +//add RSIperiod in order to catch up with same scroll
                    dataShiftCount + Math.abs( (500 - dbData.length) < 0 ? 0 : (500 - dbData.length) ),//dataShiftCount,//nextORprevDatetimeToGetCount, 
                    //-------------------------------------------------------------------------------------------------------------------------------------

                              //in order to have a 0 candle body or wick for all shifted candles - in order for them not to show on chart if they are being colored
                              //we set all OHLC data of shifted candles to the close_data of data[0].close
                              //set to data[0].close but not any other value/data, eg:0 - in order not to mess the canvas chart scaling - but have scaling in tune with the original data
                    dbData[0],//{...dbData[0], open: dbData[0].close, high:dbData[0].close, low:dbData[0].close},//prevDatetimeToGetPriceDataObj, 
                    dbData[dbData.length-1],//{...dbData[dbData.length-1], open: dbData[dbData.length-1].close, high:dbData[dbData.length-1].close, low:dbData[dbData.length-1].close}//nextDatetimeToGetPriceDataObj
                )
            ),
            ...dbData,
            ...(
                getNextOrPrevDatetimeByTF(
                    dbData[dbData.length-1].datetime,//datetime, 
                    timeframe, 
                    "next",//nextORprevDatetimesToGet, 
                    dataShiftCount,//nextORprevDatetimeToGetCount, 

                              //in order to have a 0 candle body or wick for all shifted candles - in order for them not to show on chart if they are being colored
                              //we set all OHLC data of shifted candles to the close_data of data[len-1].close
                              //set to data[len-1].close but not any other value/data, eg:0 - in order not to mess the canvas chart scaling - but have scaling in tune with the original data
                    dbData[0],//{...dbData[0], open: dbData[0].close, high:dbData[0].close, low:dbData[0].close},//prevDatetimeToGetPriceDataObj, 
                    dbData[dbData.length-1],//{...dbData[dbData.length-1], open: dbData[dbData.length-1].close, high:dbData[dbData.length-1].close, low:dbData[dbData.length-1].close}//nextDatetimeToGetPriceDataObj
                )
            )
        ]


        //regulate Highlighted or visible curr index lines
        //& then prevent all highligts on currs
        //unless being clicked to nav to index lines of the 2currs added to array & highligted
        if(isPAIRrsiORstochLineChart){
          const baseCURRname  = instruNameSTR.slice(0,3)
          const quoteCURRname = instruNameSTR.slice(3)
          setCurrsHighlightedArray([baseCURRname, quoteCURRname])
        }
        else if(isEitherRSIorSTOCHindexChart){
          //take from localStorage
          //console.log('...is working')
          

          const responseData = localStorage.getItem(`${candleORrsiSTR}_currsHighlightedArray` + forexORcrypto)
          const isDataExist = responseData !== null

          if(isDataExist){
              const parsedDATA = JSON.parse(responseData)
              const newData = [...parsedDATA]
              setCurrsHighlightedArray(newData)
          }
          else{//EUR-USD for stoch starting
            if(isSTOCHchart){
              setCurrsHighlightedArray(['USD']/*currsHighlightedArray*/)
            }
            else{//stoch
              setCurrsHighlightedArray([...currIndexArray])
            }
          }
        }
        

        





        //techiman
        //----------------------------------------------------------------------------------------------------------------------------------------
        const dataObject = window.localStorage.getItem(
          `${candleORrsiSTR}_${indexORpairSTR}_${
            instruNameSTR}_${/*isSTOCHchart ? chartInfoObj.tf : */timeframe}` + forexORcrypto
        )
        const isDataExists = dataObject !== null
        //console.log({isDataExists})

        let instruTFsettingsObj = {}

        if(isDataExists){
            const dataRes = JSON.parse(dataObject)
            //console.log(dataRes)
            instruTFsettingsObj = {...dataRes, isStateLoaded:true} 
        }
        else{
            instruTFsettingsObj = {...defaultChartInstruTFsettingsObj, isStateLoaded:true}
        }

        //Chart data Load/reLoad/reFresh/update useEffect reRun Auth 2
        if(true){
            //console.log('instruFTloaded...')




            //--------------------------------------------------------------------------------------------------------------------------------
            const usualStartEndIndexINFO = //instruTFsettingsObj.dataCandleStartEndIndex.dataCandleStartIndex!==0 && instruTFsettingsObj.dataCandleStartEndIndex.dataCandleEndIndex!==0
            Math.abs(instruTFsettingsObj.dataCandleStartEndIndex.dataCandleEndIndex - instruTFsettingsObj.dataCandleStartEndIndex.dataCandleStartIndex) > 0 
            ? instruTFsettingsObj.dataCandleStartEndIndex 
            : {
                dataCandleStartIndex : adjustedAndPreparedData.length - defaultNumOfCandlesToShowOnChart,
                dataCandleEndIndex   : adjustedAndPreparedData.length //+ (chartTotalShiftLength)
            }
               

            setDataCandleStartEndIndex(//also Auth if instruTFsettingsObj.dataCandleStartEndIndex is > adjustedAndPreparedData.length
            {
            dataCandleStartIndex : usualStartEndIndexINFO.dataCandleStartIndex < 0 ? 0 : usualStartEndIndexINFO.dataCandleStartIndex,
            dataCandleEndIndex   : usualStartEndIndexINFO.dataCandleEndIndex > adjustedAndPreparedData.length ? adjustedAndPreparedData.length : usualStartEndIndexINFO.dataCandleEndIndex
            }
            )
            //--------------------------------------------------------------------------------------------------------------------------------

              

            //--------------------------------------------------------------------------------------------------------------------------------
            const beforeYmagStartEndIndexINFO = //instruTFsettingsObj.beforeYmagChartInfoTracker.dataCandleStartIndex!==0 && instruTFsettingsObj.beforeYmagChartInfoTracker.dataCandleEndIndex!==0
            Math.abs(instruTFsettingsObj.beforeYmagChartInfoTracker.dataCandleEndIndex - instruTFsettingsObj.beforeYmagChartInfoTracker.dataCandleStartIndex) > 0
            ? instruTFsettingsObj.beforeYmagChartInfoTracker 
            : {
                dataCandleStartIndex : adjustedAndPreparedData.length - defaultNumOfCandlesToShowOnChart,
                dataCandleEndIndex   : adjustedAndPreparedData.length //+ (chartTotalShiftLength)
            }

            
            setBeforeYmagChartInfoTracker(
            {
                dataCandleStartIndex : beforeYmagStartEndIndexINFO.dataCandleStartIndex < 0 ? 0 : beforeYmagStartEndIndexINFO.dataCandleStartIndex,
                dataCandleEndIndex   : beforeYmagStartEndIndexINFO.dataCandleEndIndex > adjustedAndPreparedData.length ? adjustedAndPreparedData.length : beforeYmagStartEndIndexINFO.dataCandleEndIndex
            }
            )
            //--------------------------------------------------------------------------------------------------------------------------------
        

            //instruTF chart Positioning, structure & state
            setChartXvelocity(instruTFsettingsObj.chartXvelocity)
            setChartYvelocity(instruTFsettingsObj.chartYvelocity)
            setYmagnificationPercRatio(instruTFsettingsObj.YmagnificationPercRatio)
            setXmagnificationPercRatio(instruTFsettingsObj.XmagnificationPercRatio)
            setIsYmagnificationActivated(instruTFsettingsObj.isYmagnificationActivated)
            setBeforeYmagCandleIndexInfoObj(instruTFsettingsObj.beforeYmagCandleIndexInfoObj)
            //setDataCandleStartEndIndex(instruTFsettingsObj.dataCandleStartEndIndex)//*/

            
        }


        


        



        //chart data ready for plot
        //spreading Array data when updating is very essential to avoid code break
        //in order not to mimic old Array data length
        //where code will crash if the dataLength updated arent the same
        //setData([...adjustedAndPreparedData])
        setData(adjustedAndPreparedData)


        //setTimeout as its results is not all that needed quickly than candle prints does
        setTimeout(()=>{
          //--------------------------------------------------------------------------------------------------------------------------------
          //time taking state managed lastly
          if(
            //isCandleChart &&
            isCORRchart===false &&
            dbData.length > 0
          ){

              setRsiORIdata([
                  ...dbData
              ])

            

              const rsiDATETIMEobjArrayIDsObj = {}
              dbData.forEach((objEL, objELind)=>{
                  rsiDATETIMEobjArrayIDsObj[objEL.datetime] = objELind
                  rsiDATETIMEobjArrayIDsObj[objELind]       = objEL.datetime
              })

              setRsiDATETIMEobjArrayIDs({...rsiDATETIMEobjArrayIDsObj})


              //--------------------------------------------------------------

              if(
                corrCalcDATETIME &&  
                corrCalcDATETIME.datetimeStart!=='' &&
                corrCalcDATETIME.datetimeEnd!=='' &&
                rsiDATETIMEobjArrayIDsObj[corrCalcDATETIME.datetimeStart] &&
                rsiDATETIMEobjArrayIDsObj[corrCalcDATETIME.datetimeEnd]
              ){
                /*console.log('updating Corr chart...',{
                  datetimeStart : corrCalcDATETIME.datetimeStart,
                  datetimeEnd : corrCalcDATETIME.datetimeEnd,
                  start : Number(rsiDATETIMEobjArrayIDsObj[corrCalcDATETIME.datetimeStart]),
                  end: Number(rsiDATETIMEobjArrayIDsObj[corrCalcDATETIME.datetimeEnd]),
                  length :dbData.length
                })*/


                //check which 2 FUNCs coming 1st is the best
                calcRSIcorrFUNC(
                  dbData, 
                  dbData.length - Number(rsiDATETIMEobjArrayIDsObj[corrCalcDATETIME.datetimeStart]) ,//1, 
                  dbData.length - Number(rsiDATETIMEobjArrayIDsObj[corrCalcDATETIME.datetimeEnd]) //1
                )

                /*const [rsiDATETIMEnumCountObj, setRsiDATETIMEnumCountObj] = useState({
                  datetimeStart :1,
                  datetimeEnd   :1
                })*/

                setRsiDATETIMEnumCountObj({
                  datetimeStart : dbData.length - Number(rsiDATETIMEobjArrayIDsObj[corrCalcDATETIME.datetimeStart]),
                  datetimeEnd   : dbData.length - Number(rsiDATETIMEobjArrayIDsObj[corrCalcDATETIME.datetimeEnd])
                })


                /*console.log({
                  datetimeStart: corrCalcDATETIME.datetimeStart, 
                  datetimeEnd  : corrCalcDATETIME.datetimeEnd,
                  x1 : dbData.length - Number(rsiDATETIMEobjArrayIDsObj[corrCalcDATETIME.datetimeStart]),//1, 
                  x2 : dbData.length - Number(rsiDATETIMEobjArrayIDsObj[corrCalcDATETIME.datetimeEnd])//1  
                })*/

              }
              else{//mostly run once onPageLoad

                //check which 2 FUNCs coming 1st is the best
                setCorrCalcDATETIME({
                  datetimeStart: dbData[dbData.length - 1].datetime, 
                  datetimeEnd: dbData[dbData.length - 1].datetime 
                })
    
                //check which 2 FUNCs coming 1st is the best
                calcRSIcorrFUNC(dbData, 1, 1)

                
              }

              


              //on update by datetime else state('')empty
              //if obj.dt then id=1

              //console.log({rsiDATETIMEobjArrayIDsObj})
          }
          //--------------------------------------------------------------------------------------------------------------------------------
        },10)


        //reload chart after new data parse update
        setIsInstruTFloaded(true)//is all instruTF settings loaded, before load or satrt canvas chart Auth
        setUseEffectReRunCounter(Date.now())

        setIsDATAloaded(adjustedAndPreparedData.length > 0 ? true : false)
        //--------------------------------------------------------------------------------------------------------------------------------

      }

    }






    const switchPairChartTOindex = (e='index')=>{//make sure it has same update or code as the one in chartHeader
      //console.log('there is a change')
      if(
          true
      ){

          //'USD'/'BTC' remain static for LStorage naming sake for rsi/stoch tfs
          //as rsi/stoch comprise of multi currINDEX data but not specific curr
          //therefore: to avoid dynamic LStorage name/index to access sake, we choose a static CURRname for rsi/stoch index chart
          const multiCURRindexSTATICinstruName = forexORcrypto==='forex' ? 'USD' : 'BTC'

          const chartSettingsObjToSaveInLStorage = {
              ...chartInfoObj,
              indexORpair : e,

              instruName : chartInfoObj.candleORrsi !== 'candle' && e==='index' ? multiCURRindexSTATICinstruName 
              : e==='pair' ? chartInfoObj.prevCandlePairInstruAndTF.instruName :
              e==='index' ? chartInfoObj.prevCandleIndexInstruAndTF.instruName : chartInfoObj.instruName,

          }
          setChartInfoObj(chartSettingsObjToSaveInLStorage)

          window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

          //----------------------------------------------------------------------------------------------------------------------------------------
          setIsInstruTFloaded(false)
          setLocalInstruTFuseEffectReRunCounter(Date.now())
      }

    }


    const calcRSIcorrFUNC = (data, ARRAYstartIndex, ARRAYendIndex)=> {

        //console.log(data) 

        //------------------------------------------------------------------------------------
        //corr calc
        let   startIndex = data.length - ARRAYstartIndex//100//0
        let   endIndex   = data.length - ARRAYendIndex//1
        const isOnlyOneCandleDATA = endIndex - startIndex === 0
        //const candleOpenReducerCount = 0//
        startIndex       = isOnlyOneCandleDATA ? (startIndex-1)  : (startIndex-1) 
        startIndex = startIndex < 0 ? 0 : startIndex
        //console.log({startIndex, len:data.length - 1})
        const sliceIncludingLastIndexCount = 1
        const isStartIndex0AndDataLenDIFF0 = startIndex===0 && isOnlyOneCandleDATA
        endIndex = isStartIndex0AndDataLenDIFF0 ? endIndex+1 : endIndex
        const dataArray  = data.slice(startIndex, endIndex + sliceIncludingLastIndexCount)

        //console.log({startIndex, endIndex, dataArray:dataArray[0]})

        let calcCORRresultObj = {}
        if(data.length >= 2){//data must be of length 2
            /*console.log(calcCORR({
                data : dataArray,
                currPairArray,
                isOnlyOneCandleDATA
            }))*/
            calcCORRresultObj = calcCORR({
                data : dataArray,
                currPairArray,
                isOnlyOneCandleDATA
            })
            
        }
        if(calcCORRresultObj.error===undefined){
            //update rsm state()
            //console.log('result : ' , calcCORRresultObj)
            setRsiCORRdata({...calcCORRresultObj})
        }
    }


    const [currHighlightHovered, setCurrHighlightHovered] = useState('')


    const currHighlightHoveredFunc = (curr) => {
        setCurrHighlightHovered(curr)

        //update chart
        setUseEffectReRunCounter(Date.now())
    }



    const selectUnselectCurrHighlighterFunc = (curr) => {

        setCurrHighlightHovered('')

        if(curr==='ALL'){
            setCurrsHighlightedArray(currIndexArray)
            localStorage.setItem(`${chartInfoObj.candleORrsi}_currsHighlightedArray` + forexORcrypto, JSON.stringify(currIndexArray))
        }
        else if(curr==='none'){
            setCurrsHighlightedArray([])
            localStorage.setItem(`${chartInfoObj.candleORrsi}_currsHighlightedArray` + forexORcrypto, JSON.stringify([]))
        }
        else{
            if(currsHighlightedArray.includes(curr)){
                let newArray = currsHighlightedArray
                newArray = newArray.filter((elStr, elStrInd)=>{
                    return(elStr!==curr)
                })
                setCurrsHighlightedArray(newArray)
                localStorage.setItem(`${chartInfoObj.candleORrsi}_currsHighlightedArray` + forexORcrypto, JSON.stringify(newArray))
            }
            else{
                const newArray = [
                    ...currsHighlightedArray, curr
                ] 

                setCurrsHighlightedArray(newArray)
                localStorage.setItem(`${chartInfoObj.candleORrsi}_currsHighlightedArray` + forexORcrypto, JSON.stringify(newArray))
            }
        }

        //update chart
        setUseEffectReRunCounter(Date.now())
    }





    const minMaxValFinderFunc = (data/*=[]*/) => {

        //const data = [0, 100, 150, 200, 400, 500]
        let minVal = undefined 
        let maxVal = undefined 

        for(let i = 0; i < data.length; i++) {

            //const val = data[i]
            const obj = data[i]

            const open = obj.open
            const high = obj.high
            const low = obj.low
            const close = obj.close

            if(minVal===undefined){
                //minVal = val
                minVal = Math.min(open, high, low, close)
            }
            else if(maxVal===undefined){
                //maxVal = val
                maxVal = Math.max(open, high, low, close)
            }
            else{
                minVal = Math.min(open, high, low, close, minVal)
                maxVal = Math.max(open, high, low, close, maxVal)
            }

        }

        return {minVal, maxVal}
    }

    
    const handleTouchPadDoubleTouchZoomingScroll = (event) => {
        // Your scroll event logic here
        event.preventDefault(); // Prevents passive event listener warning
    };


    const [isScreenScrollLocked, setIsScreenScrollLocked] = useState(false)






    useEffect(()=>{//useEffect runs once onPageLoad

        //essential to listen to a specific element event by id
        //eg: document.getElementById('lockButton').addEventListener
        

        //---------------------------------------------------------------------------------------------
        // Add event listener to lock chart scroll
        document.getElementById(`lockButton-${canvasCompoId}`).addEventListener('click', () => {

            const targetDiv = document.getElementById(`canvasParentId-${canvasCompoId}`);
            targetDiv.scrollIntoView({
                behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
            })

            document.addEventListener('touchmove', preventScroll, { passive: false });

            setIsScreenScrollLocked(true)

            if(isLockedChartWARNING){
                setIsLockedChartWARNING(false)
            }
            
        });


        // Add event listener to unlock chart scroll
        document.getElementById(`unlockButton-${canvasCompoId}`).addEventListener('click', () => {

            const targetDiv = document.getElementById(`canvasTopBlankCompoParentId-${canvasCompoId}`);
            targetDiv.scrollIntoView({
                behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
            })
            
            document.removeEventListener('touchmove', preventScroll);

            setIsScreenScrollLocked(false)

            if(isLockedChartWARNING){
                setIsLockedChartWARNING(false)
            }
            
        });



        //---------------------------------------------------------------------------------------------
        //remove all replay verti lines of a given instru_tf from LStorage
        const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
        const isDataExist = drawingData !== null

        if(isDataExist){
            const data = JSON.parse(drawingData)

            let newData = [...data]
            
            newData = newData.filter((el, elIndx)=>{
                return(el.drawingType!=='trendline5' || (el.drawingType==='trendline5' && el.isReplayLineDrawing===false) )
            })

            window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))    

        }

    },[])






    //techiman
    useEffect(()=>{//useEffect that both Loads & Updates data


        if(
            //chartInfoObj.candleORrsi==='candle' &&
            //false &&
            //true || //-- later comment this truthy - to make way for isChartHeaderOptionsSwitchedAtleastOnce to take over local db data fetch request
            isChartHeaderOptionsSwitchedAtleastOnce//to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
                                                   //where its truthy state is served first before that of setLocalInstruTFuseEffectReRunCounter(Date.now()) to avoid missing the truthy val of isChartHeaderOptionsSwitchedAtleastOnce
            || isChartDATAloadedAtleastOnce

            || isChartDATArefreshedAtleastOnce
        ){

            //--------------------------------------------------------------------------------------------------------------------------------
            //Chart data Load/reLoad/reFresh/update useEffect reRun Auth 1
            //console.log('get db data')

            
            const timeframe     = chartInfoObj.tf 
            const isCandleChart = chartInfoObj.candleORrsi==='candle'
            const isRSIchart    = chartInfoObj.candleORrsi === 'rsi'
            const isSTOCHchart  = chartInfoObj.candleORrsi === 'stoch'
            const isCORRchart  = chartInfoObj.candleORrsi === 'corr'
            const isINDEXchart  = chartInfoObj.indexORpair === 'index'
            const isPAIRrsiORstochLineChart = isCandleChart===false && isINDEXchart===false
            const isEitherRSIorSTOCHindexChart = (isRSIchart || isSTOCHchart || isCORRchart) && isINDEXchart && isCandleChart===false

            const RSIperiod     = RSIperiodVAL//7//isRSIchart ? 14 : 6
            
            const isCurrIndex = currIndexArray.includes(chartInfoObj.instruName)//candleORrsi - if includes rsi or stoch split[0] b4 db req
            
            const instru_TF_str = `${
                isCandleChart ? chartInfoObj.instruName : isPAIRrsiORstochLineChart ? chartInfoObj.instruName : 'currs'
            }_${timeframe}`

            const indexToUPDATEdataAT = 1//later make it ind=1, after the trial of ind=4

            let isUpdateForexData = false
            let datetimeToUpdateFrom = ''

            if(
              //false &&//erase this later & fix error @line 4849 //.datetime access error
                globalDataStorageObj[instru_TF_str] &&
                (globalDataStorageObj[instru_TF_str]).data &&
                (globalDataStorageObj[instru_TF_str]).data.length > 0 
            ){//load data that had already been loaded & stored in a Global State memory Obj
                //as so data can still be loaded from f.e memory if db request ain't going through 
                //if last data id epoch late then trigger a useEffect/fetch to request updated data from db to update that already stored
                ///console.log('--------')
                ///console.log(`${instru_TF_str} already exists`)

                //dbDataResObj = globalDataStorageObj[instru_TF_str]
                //dbData       = dbDataResObj.data
                const dataArray = (globalDataStorageObj[instru_TF_str]).data
                const data      = dataArray[dataArray.length - indexToUPDATEdataAT/*dataArray.length-1*/]
                const dataDatetime       = data.datetime
                const isDatetimeStrValid = authDatetimeStrValidityBeforeDrawORedit(dataDatetime)

                /*console.log({
                    data,
                    dataDatetime,
                    isDatetimeStrValid
                })*/

                if(isDatetimeStrValid){//test datetime validity
                    datetimeToUpdateFrom = dataDatetime
                    isUpdateForexData = true
                }
            }


            

            let instruTFsToUPDATEobj = {
              //'EURUSD_h1' : {datetimeToUpdateFrom : ''/*'2023-11-03 00:00:00'*/, isUpdateForexData : false}, 
              /*'EUR_h1'    : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true}, 
              'USD_h1'    : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true},
              'currs_h1'  : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true}*/
            }
            instruTFsToUPDATEobj[instru_TF_str] = {datetimeToUpdateFrom /*: ''*//*'2023-11-03 00:00:00'*/, isUpdateForexData,  /*: false*/ chartNum : canvasCompoId}
            ///console.log({instruTFsToUPDATEobj})

            //-----------------------------------------------------------------------------
            //screenCover while Loading... data from SERVER, & gotten removed after Loading
            setIsDATAloaded(false)
            //-----------------------------------------------------------------------------

 

            //---------------------------------------REQ data from server-db--------------------------------------------------
            fetch(
                //window.location.hostname==='localhost' && false
                //?
                //`http://${ window.innerWidth < 400 ? '192.168.43.98' : 'localhost'}:5500/db`,//get_forex_data
                'https://serverapi.currencystrengthzone.com/db',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        forexORcrypto,
                        keyNAMES  : instruTFsToUPDATEobj,
                        isPhone   : localStorage.getItem('isPhone') !== null ? true : false,
                        phoneInfo : localStorage.getItem('isPhone') !== null ? localStorage.getItem('isPhone') : ''//'{"isPhone":true,"isAndroid":true,"appVersion":1.0.0,"key1":value1}'
                    }),
                }
            )
            .then((res) => {
                return res.json()
            })
            .then((dbDATAres)=>{
                
                if(dbDATAres.error){//alert error, if: resObj.error_key exists in the response object, if image does not exist
                    //alert('if error feed state with default data to display page & alert no internet or server under maintainance')
                    setIsShowServerDownAlertCompo(true)
                }
                else{//without an resObj.error_key: return the name of the image deleted & an image deletion success msg
                    
                    if(isShowServerDownAlertCompo){
                        setIsShowServerDownAlertCompo(false)
                    }

                    ///console.log(dbDATAres)

                    
                    let resDataObj = dbDATAres[instru_TF_str]//---mostly used below to access {.data, .minVal, .maxVal, .psycho}

            
                    if(
                        //isCandleChart
                        //&&
                        resDataObj.data.length > 0
                    ){


                        let dbData = []


                        if(isUpdateForexData){

                            //Auth data entries & lengths both globalData && dbData before Updating

                            const instruTFglobalDataObj = globalDataStorageObj[instru_TF_str]

                            if(
                                instruTFglobalDataObj.data.length > 0 &&
                                resDataObj.data.length > 0
                            ){

                                const dbFirstIndexDatetime  = resDataObj.data[0].datetime
                                const isDBfirstIndexDTvalid = authDatetimeStrValidityBeforeDrawORedit(dbFirstIndexDatetime)

                                if(isDBfirstIndexDTvalid){

                                    const feDataLastEntryDatetime = instruTFglobalDataObj.data[instruTFglobalDataObj.data.length-indexToUPDATEdataAT].datetime

                                    const isDTsameForUNION = dbFirstIndexDatetime === feDataLastEntryDatetime //=== datetimeToUpdateFrom

                                    if(isDTsameForUNION){

                                        //slice data but leave out the last index, spread the dbDATA that starts with that last index details/DT
                                        const numOfEntriesToSliceFromEnd = indexToUPDATEdataAT//1
                                        let newDATA = instruTFglobalDataObj.data.slice(0, instruTFglobalDataObj.data.length - numOfEntriesToSliceFromEnd)
                                        newDATA = [...newDATA, ...resDataObj.data]

                                        
                                        const newInstruTFobj = {
                                            data : newDATA
                                        }//instruTFglobalDataObj

                                      
                                        //updateGlobal data
                                        let newObj = {...globalDataStorageObj, isObjEMPTY:false}
                                        newObj[instru_TF_str] = newInstruTFobj//instruTFglobalDataObj
                                        setGlobalDataStorageObj(newObj)


                                        //update
                                        dbData     = newInstruTFobj.data//[...instruTFglobalDataObj.data, ]
                                        resDataObj = newInstruTFobj

                                        ///console.log(`${instru_TF_str} data loaded...`)
                                        ///console.log({resDataObj, dbData})
                                        
                                        /*console.log({
                                            dbFirstIndexDatetime,
                                            isDBfirstIndexDTvalid, 
                                            isDTsameForUNION,
                                            dbFirstIndexDatetime,
                                            feDataLastEntryDatetime , datetimeToUpdateFrom
                                        })*/
                                    }
                                    else{//if: Auth of a new db res data to be updated on f.e, happens to be falsy on various series of Auths
                                         //then: insert into the Arrays we were to Update, with the already existing instruTFglobalDataObj data we were to Update as it couldn't pass truthy Auths
                                         const oldExistingDataToUPDATEobj = globalDataStorageObj[instru_TF_str]
                                         dbData     = oldExistingDataToUPDATEobj.data
                                         resDataObj = oldExistingDataToUPDATEobj
                                         //no need to store in globalDataStorageObj as we used the original data from globalDataStorageObj with no new data to Update it as a result of falsy Auth from data to update
                                    }

                                }
                                else{//if: Auth of a new db res data to be updated on f.e, happens to be falsy on various series of Auths
                                     //then: insert into the Arrays we were to Update, with the already existing instruTFglobalDataObj data we were to Update as it couldn't pass truthy Auths
                                     const oldExistingDataToUPDATEobj = globalDataStorageObj[instru_TF_str]
                                     dbData     = oldExistingDataToUPDATEobj.data
                                     resDataObj = oldExistingDataToUPDATEobj
                                     //no need to store in globalDataStorageObj as we used the original data from globalDataStorageObj with no new data to Update it as a result of falsy Auth from data to update
                                }

                            }
                            else{//if: Auth of a new db res data to be updated on f.e, happens to be falsy on various series of Auths
                                 //then: insert into the Arrays we were to Update, with the already existing instruTFglobalDataObj data we were to Update as it couldn't pass truthy Auths
                                 const oldExistingDataToUPDATEobj = globalDataStorageObj[instru_TF_str]
                                 dbData     = oldExistingDataToUPDATEobj.data
                                 resDataObj = oldExistingDataToUPDATEobj
                                 //no need to store in globalDataStorageObj as we used the original data from globalDataStorageObj with no new data to Update it as a result of falsy Auth from data to update
                            }


                            
                        }
                        else{
                            dbData = resDataObj.data

                            ///console.log('--------')
                            ///console.log(`${instru_TF_str} don't exist`)

                            //Auth instru if don't exist b4 storage
                            //Auth if data has all the right ingredients b4 storage
                            if(
                                globalDataStorageObj[instru_TF_str]===undefined &&
                                resDataObj.data &&
                                resDataObj.data.length > 0 
                            ){
                                //console.log(`${instru_TF_str} data saved`)
                                let newObj = {...globalDataStorageObj, isObjEMPTY:false}
                                newObj[instru_TF_str] = resDataObj
                                setGlobalDataStorageObj(newObj)
                            }
                        }




                        //---------------------------------------------------------------------------------------------------------------------------
                        //with: dbData ARRAY - containing [data] & resDataObj OBJ - containing {.data, .minVal, .maxVal, .psycho}
                        //chartDataPARSERfunc takes all below code - with dbData & resDataObj & other rsi/candle vals as Params
                        const instruNameSTR = chartInfoObj.instruName
                        const candleORrsiSTR= chartInfoObj.candleORrsi
                        const indexORpairSTR= chartInfoObj.indexORpair

                        chartDataPARSERfunc({resDataObj, timeframe, instruNameSTR, candleORrsiSTR, isCandleChart, isRSIchart, 
                        isSTOCHchart, isCORRchart, isINDEXchart, isPAIRrsiORstochLineChart, isEitherRSIorSTOCHindexChart, RSIperiod, isCurrIndex,indexORpairSTR})
                        

            
                    //}
                    //---------------------------------------------------------------------------------------------


                    
                    }

                }
                
            })

            

        }


    //}
    //---------------------------------------------------------------------------------------------
    },[localInstruTFuseEffectReRunCounter, navToOnlyCHARTnum1instruTFuseEffectReRunCounter])




    //for global db update of chart reload useEffect reRunner
    useEffect(()=>{

      //get instruTF data of chart# from globalDataStorageObj
      //& apply chartDataPARSERfunc to prepare new data & load onto canvas chart on canvasUseEffectRerunner
      //before parsing data, Auth if chart# instruTF exists in globalDataStorageObj && has .data.length > 0 before parsing data to Update canvas chart

      

      const isCandleChart = chartInfoObj.candleORrsi==='candle'
      const isRSIchart    = chartInfoObj.candleORrsi === 'rsi'
      const isSTOCHchart  = chartInfoObj.candleORrsi === 'stoch'
      const isCORRchart  = chartInfoObj.candleORrsi === 'corr'
      const isINDEXchart  = chartInfoObj.indexORpair === 'index'
      const isPAIRrsiORstochLineChart = isCandleChart===false && isINDEXchart===false
      const isEitherRSIorSTOCHindexChart = (isRSIchart || isSTOCHchart) && isINDEXchart && isCandleChart===false

      const RSIperiod     = RSIperiodVAL//7//isRSIchart ? 14 : 6

      const isCurrIndex = currIndexArray.includes(chartInfoObj.instruName)//candleORrsi - if includes rsi or stoch split[0] b4 db req


      //---------------------------------------------------------------------------------------------------------------------------
      //with: dbData ARRAY - containing [data] & resDataObj OBJ - containing {.data, .minVal, .maxVal, .psycho}
      //chartDataPARSERfunc takes all below code - with dbData & resDataObj & other rsi/candle vals as Params
      const timeframe     = chartInfoObj.tf 
      const instruNameSTR = chartInfoObj.instruName
      const candleORrsiSTR= chartInfoObj.candleORrsi
      const indexORpairSTR= chartInfoObj.indexORpair

      const instru_TF_str = `${
        isCandleChart ? instruNameSTR : isPAIRrsiORstochLineChart ? instruNameSTR : 'currs'
      }_${timeframe}`

      //do some Auth on the ff vars, if they contain various ingredients to be used by the chartDataPARSERfunc before inserting them as Params
      if(
        globalDataStorageObj[instru_TF_str] &&
        globalDataStorageObj[instru_TF_str].data &&
        globalDataStorageObj[instru_TF_str].data.length > 0
      ){
        const globalObjDataToUPDATEchart = globalDataStorageObj[instru_TF_str]
        const resDataObj = globalObjDataToUPDATEchart

        //setIsInstruTFloaded(false)

        chartDataPARSERfunc({resDataObj, timeframe, instruNameSTR, candleORrsiSTR, isCandleChart, isRSIchart, 
          isSTOCHchart, isCORRchart, isINDEXchart, isPAIRrsiORstochLineChart, isEitherRSIorSTOCHindexChart, RSIperiod, isCurrIndex, indexORpairSTR})

        //console.log(`chart - ${canvasCompoId} db Update Loaded`)
      }


    },[globalInstruTFuseEffectReRunCounter])








    //techiman
    useEffect(() => {//NOTE: we had disable App - <React.StrictMode> to prevent useEffect Hook from running twice - in order to prevent the chart from printing twice

        //---------------------------------------------------------------------------------------------
        //Store line/candle_instru_tf settings into LStorage from all states: to start the next opened chart
        //by the courtesy of: setUseEffectReRunCounter(Date.now()) - all chart states updates are reloaded/updated onto chart
        //& therefore: all states are well updated for LStorage - as its being reloaded on the Chart by this reloaded useEffect func
        //HINT: always add - setUseEffectReRunCounter(Date.now()) - to the end of each Chart operations or Updates or btn click
        //to: trigger this reload useEffect func to trigger the storage of all updated Chart states into LStorage

        //---------------------------------------------------------------------------------------------
        if(
            //chartInfoObj.candleORrsi==='candle' &&
            data.length > 0 &&
            //&& chartXvelocity!==0
            //&& isYmagnificationActivated
            isInstruTFloaded
        ){
            const instruTFsettingsObj = {
                //instruTF chart Positioning, structure & state
                chartXvelocity,
                chartYvelocity,
                YmagnificationPercRatio,
                XmagnificationPercRatio,
                isYmagnificationActivated,
                beforeYmagCandleIndexInfoObj,
                dataCandleStartEndIndex,
                beforeYmagChartInfoTracker,

                //dataLength : data.length,

                //candleWidthSize : crossHairMidStartXcordDrawerInfo.candleWidthSize===undefined ? canvasWidth/defaultNumOfCandlesToShowOnChart : crossHairMidStartXcordDrawerInfo.candleWidthSize
            }//*/
            //LStorage name - '(line/candle)_(index/pair)_instruName_tf'
            //console.log(instruTFsettingsObj)
            window.localStorage.setItem(`${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_${
                chartInfoObj.candleORrsi==='candle' ? chartInfoObj.instruName : 'USD'//'USD' remain static for LStorage naming sake for rsi/stoch tfs
            }_${chartInfoObj.tf}` + forexORcrypto, 
            JSON.stringify(instruTFsettingsObj))
        }
        //---------------------------------------------------------------------------------------------
        



        //console.log("canvas Loaded")
        //console.log('canvasRef : ', canvasRef)
        //console.log("min_max_val : ", minMaxValFinderFunc(data), minMaxValFinderFunc(data).maxVal===undefined)
        //console.log("res : ", data)
        if(
            //chartInfoObj.candleORrsi==='candle' &&
            data.length > 0 &&
            isInstruTFloaded
            //true//context.clearRect saved us
            /*useEffectReRunCounter===0*///disable this to only have Canvas chart re-render multiple times onto itself making the whole Chart stuffed & blurr
                                     //always refresh browser page to have new Chart code edits run completely
        ){//Prevents Code re-rendering while working on it, HINT: refresh browser page always to have all the complete code worked on run at once
            //console.log('Canvas STARTED!!!')
            //console.log({isRectangleTextInput})
            //setCanvasWidth(isTouchScreenDevice ? window.innerWidth : window.innerWidth-18)

            const isSTOCHchart    = chartInfoObj.candleORrsi === 'stoch'
            const isCORRchart     = chartInfoObj.candleORrsi === 'corr'
            const isCandleChart   = chartInfoObj.candleORrsi === 'candle'
            const isRSIchart      = chartInfoObj.candleORrsi === 'rsi'
            const isINDEXchart    = chartInfoObj.indexORpair === 'index'
            const isPAIRchart     = chartInfoObj.indexORpair === 'pair'
            const isPAIRrsiORstochLineChart = isCandleChart===false && isINDEXchart===false

            const currentPriceDATETIME = currentPRICEdatetimeObj.datetimeEnd
            const lastPriceDATETIME    = currentPRICEdatetimeObj.datetimeStart

            
            if(isChartDATAloadedAtleastOnce===false){
              setIsChartDATAloadedAtleastOnce(true)
            }



            //---------------------------------------------------------------------------------------------------
            //canvas chart SCALING config:

            //access HTML canvas element 
            //& assign it to its unique chartId among all other charts
            const canvas = canvasRef.current;
            canvasRef.current.id = `canvasCompoId-${canvasCompoId}`;

            // Add the event listener with { passive: false } option
            // to prevent touchpad & phone double touch on canvas element from zooming
            document.getElementById(`canvasCompoId-${canvasCompoId}`).addEventListener('wheel', handleTouchPadDoubleTouchZoomingScroll, { passive: false });
            
            //get devicePixelRatio - to scale canvas drawing back to - 1 to 1 ratio if( devicePixelRatio > 1 )
            const devicePixelRatio = window.devicePixelRatio || 1
            const ratioTargetToScaleCanvasChartTo = 1
            const pixelOutlierRatioAwayFrom1to1 = devicePixelRatio - ratioTargetToScaleCanvasChartTo

            /*
            NOTE:

            canvas.width       = canvas HTML element size 
            canvas.style.width = canvas styling/drawing pixel size

            canvas.width & canvas.style.width : has different values
            but mostly use canvas.style.width in calculation
            */

            //get the width & height of canvas
            //strictly through its style PROP
            const canvasHEIGHTinStyle = Number(canvas.style.height.replace('px', ''))
            const canvasWIDTHinStyle  = Number(canvas.style.width.replace('px', ''))

            //scaled canvas size(w, h) style
            //scale & reset canvas chart size(w, h) by given devicePixelRatio
            const canvasChartWidthSCALED  = canvasWIDTHinStyle * devicePixelRatio
            const canvasChartHeightSCALED = canvasHEIGHTinStyle * devicePixelRatio

            //scale & reset canvas chart size(w, h) by given devicePixelRatio
            canvas.setAttribute('width', canvasChartWidthSCALED);
            canvas.setAttribute('height', canvasChartHeightSCALED);

            //get the canvas HTML element size(w, h)
            let canvasChartWidth  = canvas.width
            let canvasChartHeight = canvas.height

            //now scale canvas drawing style(w, h) to 1to1 - by taking out the Outlier/invincible drawing portion
            //to start the drawings from a 1to1 visible portion after the outlier had been subtracted
            const canvasChartWidthSCALEoutlierPX  = (canvasChartWidth / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1
            const canvasChartHeightSCALEoutlierPX = (canvasChartHeight / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1

            //do same in onMouseMove, scale to it & maybe subtract from the 
            const canvasChartWidthTRIMMEDto1to1ratioSIZE  = canvasChartWidth  - canvasChartWidthSCALEoutlierPX
            const canvasChartHeightTRIMMEDto1to1ratioSIZE = canvasChartHeight - canvasChartHeightSCALEoutlierPX

            canvasChartWidth  = canvasChartWidthTRIMMEDto1to1ratioSIZE
            canvasChartHeight = canvasChartHeightTRIMMEDto1to1ratioSIZE

            /*console.log({
              canvasChartWidthSCALED,canvasChartHeightSCALED,
              canvasChartWidth, canvasChartHeight
            })*/

            //techiman
            //--------------------------------------
            //create canvas drawing context Object
            const context = canvas.getContext('2d');

            //scale all canvas drawings to fit the devicePixelRatio
            context.scale(devicePixelRatio, devicePixelRatio)
            //---------------------------------------------------------------------------------------------------

           

            

            /*//------------------------------------------------------------------------------------------------------------------
            //avoid canvas blurrness
            // Get the device pixel ratio
            //https://medium.com/wdstack/fixing-html5-2d-canvas-blur-8ebe27db07da
            const devicePixelRatio = window.devicePixelRatio || 1;
            console.log("devicePixelRatio : ", devicePixelRatio)

            // Set the canvas size taking into account the pixel ratio
            canvas.width = canvas.clientWidth * devicePixelRatio;
            canvas.height = canvas.clientHeight * devicePixelRatio;
            canvas.style.width = canvasChartWidth + "px"
            canvas.style.height = canvasChartHeight + "px"
            canvas.getContext("2d").setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0)
            //

            // Scale the context by the pixel ratio
            context.scale(devicePixelRatio, devicePixelRatio);*/

            //avoid canvas blurrness
            //------------------------------------------------------------------------------------------------------------------
            //----------*/

            // Clear canvas
            context.clearRect(0, 0, canvasChartWidth, canvasChartHeight);

            
            //`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray`
            //----------------------------------------------------------------------------------------------------------------
            //store all datetimeEnd datetime into Obj to track their exact Xcord on candle's datetime loop
            const allDrawingsDatetimeXcordObj = {}
            const allCANDLESdatetimeDATAinfoObj = {}
            const allDATAdatetimeINDEXinArrayObj = {}
            const allArrayDataDatetimeIdTracker = {}
            let allDrawingsDatetimeEndArray = []
            let replayLineDrawingArray = []//_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}
            let localStorageResArray = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto) === null ? [] : JSON.parse(localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto))
            //console.log("localStorageResArray : ", localStorageResArray)
            
            /*const allDrawingsDatetimeEndArray = localStorageResArray.map((drawingDataObj, index)=>{
                return drawingDataObj.datetimeStart, drawingDataObj.datetimeEnd
            })*/
            //console.log('allDrawingsDatetimeEndArray : ', allDrawingsDatetimeEndArray)

            //console.log({isTouchScreenDevice})
            //console.log({isScreenScrollLocked})

            //to update screens with both touch & mouse
            //setIsTouchScreenDevice('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)

            
            //-------------------------------------------------------------------------------------------------------------------
            //locked replay lines
            let  replayLockedDatetimeArray = []
            const replayDrawingDataObj = localStorage.getItem("replayDatetimeLineLockedObj" + forexORcrypto)
            const isReplayDataExist = replayDrawingDataObj !== null

            if(isReplayDataExist){
                const dataObj = JSON.parse(replayDrawingDataObj)  
                //console.log("isReplay")
                
                for(let dtime in dataObj){

                    replayLockedDatetimeArray.push(dataObj[dtime])
                }


                if(replayLockedDatetimeArray.length > 0){

                  //to avoid multiple drawing ID insertion into localStorageResArray - which may cause crashes on selections & edits - on highlighting multiple drawing IDs
                  //we Loop & Auth if any Multiple ID & replace its obj by the lockedVertiLineDATAobj

                  //inserting: unique key (isReplayGlobalLockedVertiLine : true) - in obj very essential - for selection & editing & global display purposes
                  const lockedVertiLineDATAobj = {...(replayLockedDatetimeArray[replayLockedDatetimeArray.length-1]), isReplayGlobalLockedVertiLine : true}

                  const lockedVertiLineDATAid   = lockedVertiLineDATAobj.id
                  let repeatedIDSindexToREPLACE = 0
                  let isThereAnyREPEATEDid      = false
                  for(let i=0; i<localStorageResArray.length; i++){
                    const dataObj = localStorageResArray[i]
                    if(
                      dataObj &&
                      dataObj.id &&
                      lockedVertiLineDATAid &&
                      dataObj.id === lockedVertiLineDATAid
                    ){
                      repeatedIDSindexToREPLACE = i
                      isThereAnyREPEATEDid      = true
                    }
                  }

                  if(
                    isThereAnyREPEATEDid && 
                    localStorageResArray[repeatedIDSindexToREPLACE] && //if accessible before calling on its .id
                    (localStorageResArray[repeatedIDSindexToREPLACE]).id === lockedVertiLineDATAid
                  ){

                    //replace repeated id obj with the lockedVertiLineDATAobj
                    localStorageResArray[repeatedIDSindexToREPLACE] = lockedVertiLineDATAobj
                    //console.log({isThereAnyREPEATEDid, localStorageResArray})
                    

                    //if id repeated also delete/.filter it & store new data in the LStorage without it
                    let newData = localStorageResArray

                    newData = localStorageResArray.filter((drawingDATAobj, elIndx)=>{
                      return(drawingDATAobj.id !== lockedVertiLineDATAid)
                    })
                    
                    window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))

                  }
                  else{
                    //console.log({len:replayLockedDatetimeArray.length})
                    //put lockedVertiLineDATAobj 1st to be looped & drawn 1st
                    localStorageResArray = [lockedVertiLineDATAobj, ...localStorageResArray]
                    //console.log({localStorageResArray})//.id
                  }

                }
                /*else{
                    if(isChartReplayOn){
                        console.log('replay off')
                        setIsChartReplayOn(false)
                    }
                }*/

            }


            //locked vertical lines
            let lockedDatetimeArray = []
            const drawingDataObj = localStorage.getItem("verticalDatetimeLineLockedObj" + forexORcrypto)
            const isDataExist = drawingDataObj !== null
            

            if(isDataExist){
                const dataObj = JSON.parse(drawingDataObj)  

                
                for(let dtime in dataObj){

                    lockedDatetimeArray.push(dataObj[dtime])
                }

                if(lockedDatetimeArray.length > 0){

                    //to avoid multiple drawing ID insertion into localStorageResArray - which may cause crashes on selections & edits - on highlighting multiple drawing IDs
                    //we Loop & Auth if any Multiple ID & replace its obj by the lockedVertiLineDATAobj

                    //inserting: unique key (isGlobalLockedVertiLine : true) - in obj very essential - for selection & editing & global display purposes
                    const lockedVertiLineDATAobj = {...(lockedDatetimeArray[lockedDatetimeArray.length-1]), isGlobalLockedVertiLine : true}

                    const lockedVertiLineDATAid   = lockedVertiLineDATAobj.id
                    let repeatedIDSindexToREPLACE = 0
                    let isThereAnyREPEATEDid      = false
                    for(let i=0; i<localStorageResArray.length; i++){
                      const dataObj = localStorageResArray[i]
                      if(
                        dataObj &&
                        dataObj.id &&
                        lockedVertiLineDATAid &&
                        dataObj.id === lockedVertiLineDATAid
                      ){
                        repeatedIDSindexToREPLACE = i
                        isThereAnyREPEATEDid      = true
                      }
                    }

                    if(
                      isThereAnyREPEATEDid && 
                      localStorageResArray[repeatedIDSindexToREPLACE] && //if accessible before calling on its .id
                      (localStorageResArray[repeatedIDSindexToREPLACE]).id === lockedVertiLineDATAid
                    ){

                      //replace repeated id obj with the lockedVertiLineDATAobj
                      localStorageResArray[repeatedIDSindexToREPLACE] = lockedVertiLineDATAobj
                      //console.log({isThereAnyREPEATEDid, localStorageResArray})
                      

                      //if id repeated also delete/.filter it & store new data in the LStorage without it
                      let newData = localStorageResArray

                      newData = localStorageResArray.filter((drawingDATAobj, elIndx)=>{
                        return(drawingDATAobj.id !== lockedVertiLineDATAid)
                      })
                      
                      window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))

                    }
                    else{
                      //console.log({len:lockedDatetimeArray.length})
                      //put lockedVertiLineDATAobj 1st to be looped & drawn 1st
                      localStorageResArray = [lockedVertiLineDATAobj, ...localStorageResArray]
                      //console.log({localStorageResArray})//.id
                    }

                }
            }
            //----------------------------------------------------------------------------------------------------------------


            let replayLinesCount = 0
            let isHIGHLIGHTEDdrawingIDexists = false

            //localStorageResArray - contains all drawings data - including all Globally locked drawings
            localStorageResArray.forEach((drawingDataObj, index)=>{

                if(drawingDataObj.isReplayLineDrawing){
                    //console.log({isReplayLineDrawing:drawingDataObj.isReplayLineDrawing})
                    replayLineDrawingArray.push(drawingDataObj)

                    replayLinesCount += 1
                }

                allDrawingsDatetimeEndArray.push(drawingDataObj.datetimeStart, drawingDataObj.datetimeEnd)


                if(//Auth to be used to empty - selectedDrawingForEditInfoIdObj - when the selected drawing id happens to have been deleted globally - yet appears to be highlighted 
                  selectedDrawingForEditInfoIdObj &&
                  selectedDrawingForEditInfoIdObj.isSelected &&//if any drawing is selected for edit but already DELETED
                  isHIGHLIGHTEDdrawingIDexists === false 
                ){
                  isHIGHLIGHTEDdrawingIDexists = drawingDataObj.id===selectedDrawingForEditInfoIdObj.id//true
                }
            })


            //-----------------------------------------------------------------------------------------------------
            //nullify any drawing selection to edit by emptying the - selectedDrawingForEditInfoIdObj
            //when they happen to have been Globally affected or completely DELETED
            //console.log({selectedDrawingForEditInfoIdObj})
            if(
              selectedDrawingForEditInfoIdObj &&
              selectedDrawingForEditInfoIdObj.isSelected &&//if any drawing is selected for edit but already DELETED
              isHIGHLIGHTEDdrawingIDexists === false 
            ){
              //console.log('Globally DELETED but selection on other Charts exists')
              setSelectedDrawingForEditInfoIdObj({})
            }
            //-----------------------------------------------------------------------------------------------------


            //----------------------------------
            if(replayLinesCount > 0){
                if(isChartReplayOn===false){
                    //console.log('replay on')
                    setIsChartReplayOn(true)
                }
            }
            else{
                if(isChartReplayOn){
                    //console.log('replay off')
                    setIsChartReplayOn(false)
                }
            }
            //----------------------------------


            
            


            //let dataLength        = data.length
            //new data_length based on the candleStartIndex & candleEndIndex
            const dataLength = data.slice(dataCandleStartEndIndex.dataCandleStartIndex, dataCandleStartEndIndex.dataCandleEndIndex).length
            //console.log("dataLength : ", dataLength)


            /*const candleBoxWidthShiftCount = 0*/
            const candleBoxWidth = canvasChartWidth / (dataLength /*+ candleBoxWidthShiftCount*/)//w
            //console.log("candleBoxWidth : ", candleBoxWidth)


            const candleIntervalWidthPX  = candleBoxWidth > 40 ? 15 : candleBoxWidth > 10 ? 8 : 10*0.5 
            const candleCordPlusCenter   = candleIntervalWidthPX / 2
            const candleWidthMinusCenter = candleIntervalWidthPX / 2
            
            /*const candleWidthSize   = candleBoxWidth//cws
            const candleWidth       = (candleBoxWidth) - candleWidthMinusCenter//w/2*/
            

            const candleWidthSize   = candleBoxWidth * (1 + XmagnificationPercRatio )//cws
            const candleWidth       = (candleBoxWidth * (1 + XmagnificationPercRatio )) - candleWidthMinusCenter//w/2
            /*if(isMouseDown){
                console.log("candleWidthSize-1 : ", candleWidthSize)
            }*/

            if(isUseEffectChartLoadStarted===false){
                setChartCandleBoxWidthStartPX(candleWidthSize - candleIntervalWidthPX)
            }
            
            //Xmagnification : 
            //magnification    = incre in candle widthsize & decre in chart candles data
            //de-magnification = decre in candle widthsize & incre in chart candles data


            //console.log({dataCandleStartIndex : dataCandleStartEndIndex.dataCandleStartIndex, dataCandleEndIndex : dataCandleStartEndIndex.dataCandleEndIndex})


            const outlierLimit = 15
            let minMaxValRes       = {}
            let minPriceVal        = 0
            let maxPriceVal        = 1
            let minMaxPriceValDiff = 1  


            if(isCandleChart){
                //min_max val based on the candleStartIndex & candleEndIndex
                minMaxValRes = minMaxValFinderFunc(data.slice(dataCandleStartEndIndex.dataCandleStartIndex, dataCandleStartEndIndex.dataCandleEndIndex))
                minPriceVal = minMaxValRes.minVal===undefined ? 0 : minMaxValRes.minVal //0
                maxPriceVal = minMaxValRes.maxVal===undefined ? 0 : minMaxValRes.maxVal //500
                //console.log("minPriceVal : ", minPriceVal)
                //console.log("maxPriceVal : ", maxPriceVal)
                minMaxPriceValDiff = maxPriceVal - minPriceVal  
            }
            else{
                //min_max val based on the candleStartIndex & candleEndIndex
                //minMaxValRes = minMaxValFinderFunc(data.slice(dataCandleStartEndIndex.dataCandleStartIndex, dataCandleStartEndIndex.dataCandleEndIndex))
                
                minPriceVal        = 0   - (outlierLimit*0.5)
                maxPriceVal        = 100 + outlierLimit
                minMaxPriceValDiff = maxPriceVal - minPriceVal 
            }

            

            
            const drawingDatetimeInfo = {}

            let prevDATAinfoObj = {}

            const isChartOnREPLAYmode = replayLineDrawingArray.length > 0 && authDatetimeStrValidityBeforeDrawORedit((replayLineDrawingArray[replayLineDrawingArray.length-1]).datetimeStart)

            if(isChartOnREPLAYmode){
              const replayDT = (replayLineDrawingArray[replayLineDrawingArray.length-1]).datetimeStart
              //if > currentPriceDT then === currentPriceDT === currentPriceDATETIME
              //replayDT = datetimeToTimestamp(replayDT) > datetimeToTimestamp(currentPriceDATETIME) ? currentPriceDATETIME : replayDT
              

              if(
                //false&&
                authDatetimeStrValidityBeforeDrawORedit(replayDT)
                && (prevReplayModeInfo==='' || authDatetimeStrValidityBeforeDrawORedit(prevReplayModeInfo))
                && replayDT !== prevReplayModeInfo
              ){
                //-----------------------------------------------------------------------------------
                let value = replayDT
                let numVal = Math.abs((rsiORIdata.length + 1) - (Number(rsiDATETIMEobjArrayIDs[value]) + 1))
                numVal = numVal + 1
                numVal = numVal < 0 ? 0 : numVal

                setCorrCalcDATETIME({
                    //...corrCalcDATETIME,
                    datetimeStart : rsiDATETIMEobjArrayIDs[Math.abs((rsiORIdata.length - 1) - (numVal - 1))],//value,
                    datetimeEnd   : rsiDATETIMEobjArrayIDs[Math.abs((rsiORIdata.length - 1) - (numVal - 1))]//value
                })

                setRsiDATETIMEnumCountObj({
                    //...rsiDATETIMEnumCountObj,
                    datetimeStart : numVal,
                    datetimeEnd   : numVal
                })

                //------------------------------------------------------------------------------------
                //corr calc
                const ARRAYstartIndex = numVal//8//(Number(rsiDATETIMEobjArrayIDs[replayModeInfo]))//Number(numVal-1-1)
                const ARRAYendIndex   = numVal//8//(Number(rsiDATETIMEobjArrayIDs[replayModeInfo]))//Number(numVal-1-1)
                //console.log({ARRAYstartIndex, ARRAYendIndex, len:rsiORIdata.length})

                if(ARRAYstartIndex>=1 && ARRAYendIndex>=1){
                    calcRSIcorrFUNC(rsiORIdata, ARRAYstartIndex, ARRAYendIndex)
                }
                //-----------------------------------------------------------------------------------

                setPrevReplayModeInfo(replayModeInfo)
              }

              setReplayModeInfo(replayDT)
              
            }
            else{
              setReplayModeInfo('')
            }


            const canvasChartDATAarray = data.slice(
                isYmagnificationActivated ? 0 : dataCandleStartEndIndex.dataCandleStartIndex, 
                isYmagnificationActivated ? data.length : dataCandleStartEndIndex.dataCandleEndIndex 
            )


            canvasChartDATAarray.forEach((el, ind)=>{
            //[{}].forEach((el, ind)=>{
                //console.log('ind : ', ind)
                //context.fillRect(xCoordinate, yCoordinate, width, height)

                

                
                //OHLC
                let   open          = isCandleChart===false ? 30 : el.open //Yi,
                let   high          = isCandleChart===false ? 100 : el.high //Y1i,
                let   low           = isCandleChart===false ? 0 : el.low  //Y2i,
                let   close         = isCandleChart===false ? 70 : el.close//height
                const datetime      = el.datetime //"yy-mm-dd hh:mm:ss"
                //const prev_datetime = canvasChartDATAarray[ind===0 ? 0 : ind-1].datetime//to set REPLAY vertiLine DATETIME
                const base_csm      = isCandleChart===false ? 100 : el[`${chartInfoObj.instruName.slice(0,3)}_csm`]
                const quote_csm     = isCandleChart===false ? 100 : el[`${chartInfoObj.instruName.slice(3)}_csm`]
                //const id    = el.id   //1

                

                //to get replay prev_datetime - as current vertiLine highlighted is not visible
                //as prev_datetime shan't be saved right away as a given vertiLine datetime - needs to be parsed from a given tf it was drawn to thy given current chart tf
                //console.log({selectedDrawingForEditInfoIdObj})
                if(
                  isChartOnREPLAYmode &&
                  isMouseDown &&
                  selectedDrawingForEditInfoIdObj &&
                  selectedDrawingForEditInfoIdObj.isSelected &&
                  selectedDrawingForEditInfoIdObj.isReplayLineDrawing
                ){
                  allDATAdatetimeINDEXinArrayObj[datetime] = isYmagnificationActivated ? ind : dataCandleStartEndIndex.dataCandleStartIndex + ind
                }

               


                if(isScaleCandlesToCanvasHeight){
                    //magnify OHLC data only after had been scaled to the height of the Chart: (scale first) b4 * (1.0 + YmagnificationPercRatio))
                    open  = ((canvasChartHeight * (open  - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) // 1.0 + 0.2(=20%)
                    high  = ((canvasChartHeight * (high  - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) // 1.0 + 0.2(=20%)
                    low   = ((canvasChartHeight * (low   - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) // 1.0 + 0.2(=20%)
                    close = ((canvasChartHeight * (close - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) // 1.0 + 0.2(=20%)
                    //console.log([open, high, low, close])
                }
                else{

                }


                

                const candleOpen   = open
                const candleHeight = (close - open) 

                const drawCandleAtXcord     = (((ind - (isYmagnificationActivated ? dataCandleStartEndIndex.dataCandleStartIndex : 0)) * candleWidthSize ) + candleCordPlusCenter) + XchartShiftPX + chartXvelocity //Xi
                const allVerticalLinesXcord = (((ind - (isYmagnificationActivated ? dataCandleStartEndIndex.dataCandleStartIndex : 0)) * candleWidthSize) + ((candleWidthSize + candleCordPlusCenter)*0.5)) + XchartShiftPX + chartXvelocity
                
                const drawCandleAtYcord   = (((canvasChartHeight - candleOpen) - candleHeight) + YchartShiftPX) + chartYvelocity //Yi
                
                //candleWick = 
                const candleWickHighYcord = ((canvasChartHeight - high) + YchartShiftPX) + chartYvelocity //Y1i
                const candleWickLowYcord  = ((canvasChartHeight - low ) + YchartShiftPX) + chartYvelocity //Y2i
                //console.log(ind, ' : ', drawCandleAtYcord)

                

                //for drawing crossHair & datetime onHover & Click
                const candleBoxWidthIntervalXcoordStart = (ind * candleBoxWidth) + chartXvelocity
                const candleBoxWidthIntervalXcoordEnd   = ((ind * candleBoxWidth) + candleBoxWidth) + chartXvelocity
                //console.log(ind, ' : ', [candleBoxWidthIntervalXcoordStart, candleBoxWidthIntervalXcoordEnd])
                
                //current Price horizontal Line data
                const currentPriceDataIndex = (data.length - dataShiftCount)
                const currentPriceData = data[currentPriceDataIndex]
                //console.log('currentPriceData : ', currentPriceData.time)
                const currentClosePriceScaled = ((canvasChartHeight * (currentPriceData.close - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) // 1.0 + 0.2(=20%)
                const currentClosePriceYcord = ((canvasChartHeight - currentClosePriceScaled /*- (currentPriceData.close - currentPriceData.open) */) + YchartShiftPX) + chartYvelocity 


                //---------------------------------------------------DRAW All Psycho lines---------------------------------------------------------------
                if(//Draw Psycho lines
                    ind===0 && 
                    chartInfoObj.candleORrsi==='candle' && chartInfoObj.indexORpair === 'pair' &&
                    window.localStorage.getItem(`psychoLevel`) !== null
                ){

                    const dataObj = window.localStorage.getItem(`psychoLevel`)//make selected : [empty] - onDELETE
                    const isDataExist = dataObj !== null

                    if(isDataExist){
                        const data = JSON.parse(dataObj)

                        /*
                        {
                            selected : [10, 25, 50, 75, 100, 125, 150, 175, 250, 500, 750, 1000],
                            selectedObj : {
                                10 : ['m5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'], 
                                25 : ['m5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'],  
                                50 : ['m5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'],  
                                75 : ['m5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'],  
                                100 : ['m5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'],  
                                125 : ['m5', 'm15', 'm30', 'h1', 'h4','d1', 'w1', 'mn'],  
                                150 : ['m5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'],  
                                175 : ['m5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'],  
                                250 : ['m5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'],  
                                500 : ['m5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'],  
                                750 : ['m5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'],  
                                1000 : ['m5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn']
                            }
                        }
                        */


                        if(
                            data.selected && 
                            data.selectedObj &&
                            typeof(data.selected)==='object' &&
                            data.selected.length > 0//only print psycho lines when array > 0

                        ){

                            //tf Auth
                            let TFpsychoLinesAllowedARRAY = []
                            
                            for(let psyLEVEL of data.selected){
                                //console.log(psyLEVEL)
                                if(
                                    data.selectedObj[psyLEVEL] &&
                                    typeof(data.selectedObj[psyLEVEL])==='object' &&
                                    data.selectedObj[psyLEVEL].length > 0 &&
                                    data.selectedObj[psyLEVEL].includes(chartInfoObj.tf)
                                ){
                                    TFpsychoLinesAllowedARRAY.push(psyLEVEL)
                                    
                                }
                            }

                            


                            if(TFpsychoLinesAllowedARRAY.length > 0){



                                
                                const psychoLEVELs = TFpsychoLinesAllowedARRAY//[10, 25, 50, 75, 100, 125, 150, 175, 250, 500, 750, 1000]// + 10/1000
                                const instrNAME    = chartInfoObj.instruName//'EURJPY'
                                //const minPRICE     = 1.06005//nonJPY
                                //const maxPRICE     = 1.11999//nonJPY
                                const minPRICE     = minPriceVal//154.394//JPY
                                const maxPRICE     = maxPriceVal//174.202//JPY

                                const psychoLEVELcalcRESarray = psychoLEVELcalc({
                                    psychoLEVELs,
                                    instrNAME,
                                    minPRICE,
                                    maxPRICE,
                                })

                                /*console.log({
                                    TFpsychoLinesAllowedARRAY,

                                    psychoLEVELs,
                                    instrNAME,
                                    minPRICE,
                                    maxPRICE,
                                })*/

                                /*console.log({
                                    psychoLEVELcalcRESarray
                                })*/

                                if(
                                    psychoLEVELcalcRESarray.length > 0 
                                ){

                                    /*console.log({
                                        psychoTF:chartInfoObj.tf,
                                        TFpsychoLinesAllowedARRAY
                                    })*/
                                    

                                    for(let psyLEVELval of psychoLEVELcalcRESarray){

                                        const psychoLevelPriceScaled = ((canvasChartHeight * (psyLEVELval - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)
                                        const psychoLevelPriceYcord = ((canvasChartHeight - psychoLevelPriceScaled) + YchartShiftPX) + chartYvelocity 

                                        //console.log(psyLEVELval, ' : ', psychoLevelPriceYcord)

                                        
                                        //-----------------------------------------------------------------------------------------------
                                        context.setLineDash([5, 5]);
                                        // Draw horizontal Price Line
                                        context.beginPath(); // reset the context state
                                        context.strokeStyle = "rgba(0, 0, 255, 1)"; // color of the line
                                        context.lineWidth = 1; // thickness of the line
                                        context.moveTo(0, psychoLevelPriceYcord); // moveTo(x,y) -> starting point of the line
                                        context.lineTo(canvasChartWidth - priceLabelMarginSize, psychoLevelPriceYcord); // line(x,y) -> end point of the line
                                        context.stroke(); // draws the line
                                        // Reset line dash (draw solid line)
                                        context.setLineDash([]);


                                        let psychoLEVELpriceTEXT = String(psyLEVELval)
                                        psychoLEVELpriceTEXT = psychoLEVELpriceTEXT.includes('.') ? psychoLEVELpriceTEXT : psychoLEVELpriceTEXT + ".0"
                                        const psychoLEVELpriceTEXTlen = psychoLEVELpriceTEXT.length
                                        const psychoLEVELpriceTEXTdecIND = psychoLEVELpriceTEXT.indexOf('.')
                                        const psychoDECIMALlen = (psychoLEVELpriceTEXTlen-1) - psychoLEVELpriceTEXTdecIND 
                                        psychoLEVELpriceTEXT = psychoDECIMALlen===1 ? psychoLEVELpriceTEXT+'000' : psychoDECIMALlen===2 ? psychoLEVELpriceTEXT+'00' : 
                                        psychoDECIMALlen===3 ? psychoLEVELpriceTEXT+'0' : psychoDECIMALlen===4 ? psychoLEVELpriceTEXT+'' : psychoLEVELpriceTEXT

                                        //console.log({psychoLEVELpriceTEXT, psychoDECIMALlen})

                                        //Draw currentPriceText
                                        context.fillStyle = "blue";  
                                        context.font = "12px Verdana";
                                        // context.fillText(text, xCoord, yCoord, maxWidth);
                                        context.textAlign = "start";
                                        //context.textBaseline = "middle";
                                        context.fillText(
                                        psychoLEVELpriceTEXT, 
                                        canvasChartWidth - priceLabelMarginSize,
                                        psychoLevelPriceYcord + 3
                                        );
                                        //-----------------------------------------------------------------------------------------------
                                    }
                                    
                                }





                                
                            }

                           
                        }

                    }

                }
                //-------------------------------------------------------------------------------------------------------------------------------









                const isCurrentPriceDATETIME = authDatetimeStrValidityBeforeDrawORedit(
                  (isChartOnREPLAYmode) ? (replayLineDrawingArray[replayLineDrawingArray.length-1]).datetimeStart : 
                        currentPriceDATETIME
                ) && authDatetimeStrValidityBeforeDrawORedit(datetime)

                if(
                    ind>0 && 
                    isCandleChart &&
                    isCurrentPriceDATETIME 
                    &&
                    datetimeToTimestamp(datetime) === datetimeToTimestamp(
                    (isChartOnREPLAYmode) ? (replayLineDrawingArray[replayLineDrawingArray.length-1]).datetimeStart : 
                    currentPriceDATETIME
                    ) 
                ){
                    //console.log({base_csm, quote_csm})
                    //console.log(prevDATAinfoObj)

                    //const {prev_base_csm, prev_quote_csm, prevCurrentClosePriceYcord } = prevDATAinfoObj
                    //console.log({prev_base_csm, prev_quote_csm, prevCurrentClosePriceYcord })

                    //console.log({candleWidthSize})//23

                    //text with 2 attrs, bottom & centered
                    context.fillStyle = `${(isChartOnREPLAYmode ? prevDATAinfoObj.close >= prevDATAinfoObj.open : close >= open) ? "green" : "red"}`;  
                    context.font = "11px Verdana";
                    // context.fillText(text, xCoord, yCoord, maxWidth);
                    context.textAlign = "start";
                    //context.textBaseline = "center";
                    context.fillText(`${
                      currPairArray.includes(chartInfoObj.instruName)===false ? chartInfoObj.instruName.slice(0,3) : 
                      (isChartOnREPLAYmode ? prevDATAinfoObj.close >= prevDATAinfoObj.open : close >= open)
                      ? chartInfoObj.instruName.slice(0,3) : chartInfoObj.instruName.slice(3)
                    }(${
                      isChartOnREPLAYmode ?
                      (currPairArray.includes(chartInfoObj.instruName)===false && prevDATAinfoObj.close < prevDATAinfoObj.open ? '-' : '+') : 
                      (currPairArray.includes(chartInfoObj.instruName)===false && close < open ? '-' : '+')
                    }${
                      isChartOnREPLAYmode ?
                      (currPairArray.includes(chartInfoObj.instruName) ? Math.abs(prevDATAinfoObj.base_csm - prevDATAinfoObj.quote_csm) : (prevDATAinfoObj.close < prevDATAinfoObj.open ? Math.abs(100-prevDATAinfoObj.base_csm) : prevDATAinfoObj.base_csm)) :
                      (currPairArray.includes(chartInfoObj.instruName) ? Math.abs(base_csm - quote_csm) : (close < open ? Math.abs(100-base_csm) : base_csm))
                    })`, //.EUR
                      //- 1h : 45m - calc to take to take out weekends
                    allVerticalLinesXcord + ( (candleWidthSize * 20) / 20 ),//20,//( (candleWidthSize * 20) / 23 )
                    (isChartOnREPLAYmode ? prevDATAinfoObj.currentClosePriceYcord : currentClosePriceYcord) + 
                    ((isChartOnREPLAYmode ? prevDATAinfoObj.close >= prevDATAinfoObj.open : close >= open) ? 15 : -10)//200 + (10*0.4)
                    );
                    

                    if(isChartOnREPLAYmode){
                      // Set line dash pattern (5 pixels on, 5 pixels off)
                      //context.setLineDash([5, 5]);
                      // Draw horizontal Price Line
                      context.beginPath(); // reset the context state
                      context.strokeStyle = "black"; // color of the line
                      context.lineWidth = 0.5; // thickness of the line
                      context.moveTo(0, prevDATAinfoObj.currentClosePriceYcord); // moveTo(x,y) -> starting point of the line
                      context.lineTo(canvasChartWidth - priceLabelMarginSize, prevDATAinfoObj.currentClosePriceYcord); // line(x,y) -> end point of the line
                      context.stroke(); // draws the line
                      // Reset line dash (draw solid line)
                      //context.setLineDash([]);


                      //Draw currentPriceText
                      context.fillStyle = "green";  
                      context.font = "12px Verdana";
                      // context.fillText(text, xCoord, yCoord, maxWidth);
                      context.textAlign = "start";
                      //context.textBaseline = "middle";
                      context.fillText(String(prevDATAinfoObj.close), 
                      canvasChartWidth - priceLabelMarginSize,
                      prevDATAinfoObj.currentClosePriceYcord + 3
                      );
                    }
                }

                if(ind>0 && isChartOnREPLAYmode){
                  const newCurrentClosePriceScaled = ((canvasChartHeight * (el.close - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) // 1.0 + 0.2(=20%)
                  const newCurrentClosePriceYcord = ((canvasChartHeight - newCurrentClosePriceScaled /*- (close - open) */) + YchartShiftPX) + chartYvelocity 

                  prevDATAinfoObj = {base_csm, quote_csm, currentClosePriceYcord : newCurrentClosePriceYcord, close:el.close, open:el.open, datetime }
                }

                

                const CURRcirclePointYcord  = {}
                const CURRcirclePointYcord2 = {}
                const CURRrsiValsObj = {}
                const lineXcordStepCount=1
                let   isDrawRSIcirle = false
                if(//techiman
                    
                    (//ind===0 &&
                    isCandleChart===false &&
                    ((forexORcrypto==='forex' && el.EUR && el.GBP && el.AUD && el.NZD && el.USD && el.CAD && el.CHF && el.JPY) || (forexORcrypto==='crypto' && el.BTC /*&& USDT &&...*/))
                    &&
                    (ind < canvasChartDATAarray.length - lineXcordStepCount)//in order to read next data to current to draw a line
                    //(ind < data.length - dataShiftCount) 
                    )
                    && 
                    ( 
                      isChartOnREPLAYmode===false
                      ||
                    (
                      isChartOnREPLAYmode
                      &&
                      datetimeToTimestamp(datetime) 
                      < datetimeToTimestamp((replayLineDrawingArray[replayLineDrawingArray.length-1]).datetimeStart )
                      &&
                      datetimeToTimestamp(datetime) 
                      < datetimeToTimestamp(currentPriceDATETIME)
                    )
                    )
                ){
                    /*
                    {chartInfoObj.candleORrsi !== 'candle'
                        datetime: '2023-10-06 18:00:00',
                        EUR: [ 64.83118738093157, 56.3728225936063 ],
                        GBP: [ 65.36439638354484, -192.5066510642918 ],
                        AUD: [ 54.28615460085835, -14.227336618548087 ],
                        NZD: [ 68.57889068373203, 111.16757569401048 ],
                        USD: [ 41.534858875755205, 4.623402495816192 ],
                        CAD: [ 57.36655681847387, 49.925743770483614 ],
                        CHF: [ 71.34846942830522, 414.4255726399931 ],
                        JPY: [ 19.020690864842024, -429.7811295110698 ]
                    }
                    */

                    //const allVerticalLinesXcordForRSI = (((ind - (isYmagnificationActivated ? dataCandleStartEndIndex.dataCandleStartIndex : 0)) * candleWidthSize) + ((candleWidthSize + candleCordPlusCenter)*0.5)) + XchartShiftPX + chartXvelocity

                    //isYmagnificationActivated ? 0 : dataCandleStartEndIndex.dataCandleStartIndex + 0
                    //isYmagnificationActivated ? data.length - 1 : dataCandleStartEndIndex.dataCandleEndIndex - 1
                    //canvasChartDATAarray[ind]
                    //canvasChartDATAarray[ind+1]


                    if(ind===0){//draw once fixed lines (0, 30, 50, 70, 100)

                        (isRSIchart ? [0, 30, 50, 70, 100] : [0, 20, 50, 80, 100]).forEach((val, valIndex)=>{

                            let fixedLine = ((canvasChartHeight * (val - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)
                            fixedLine     = ((canvasChartHeight - fixedLine ) + YchartShiftPX) + chartYvelocity 

                            // Draw candle wick line
                            context.beginPath(); // reset the context state
                            context.strokeStyle = "rgba(0,0,0,0.9)"
                            context.lineWidth = 1; // thickness of the line
                            context.moveTo(0, fixedLine); // moveTo(x,y) -> starting point of the line
                            context.lineTo(canvasChartWidth, fixedLine); // line(x,y) -> end point of the line
                            context.stroke(); 

                        })

                    }


                    currIndexArray.forEach((currNameStr, currNameStrIndex)=>{//isCandleChart

                        const RSIobjPrev  = canvasChartDATAarray[ind===0 ? 0 : ind-1]
                        const RSIobjStart = canvasChartDATAarray[ind]
                        const RSIobjNext  = canvasChartDATAarray[ind+1]

                        const RSIvalPrevVal  = RSIobjPrev[currNameStr][0]
                        const RSIvalStartVal = RSIobjStart[currNameStr][0]
                        const RSIvalNextVal  = RSIobjNext[currNameStr][0]
                        const nextDATETIME   = RSIobjNext.datetime//(canvasChartDATAarray[ind+1]).datetime
                        //console.log({nextDATETIME})
                        const isnextDATETIMEreplayAuthTruthy = isChartOnREPLAYmode && (datetimeToTimestamp(nextDATETIME) 
                        < datetimeToTimestamp((replayLineDrawingArray[replayLineDrawingArray.length-1]).datetimeStart )) === false


                        let RSIvalStart = ((canvasChartHeight * (RSIvalStartVal - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)
                        let RSIvalNext  = ((canvasChartHeight * (RSIvalNextVal  - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)

                        RSIvalStart     = ((canvasChartHeight - RSIvalStart ) + YchartShiftPX) + chartYvelocity 
                        RSIvalNext      = ((canvasChartHeight - RSIvalNext ) + YchartShiftPX) + chartYvelocity
                        
                        
                        let RSIvalStartVal2 = 0
                        let RSIvalNextVal2  = 0

                        let RSIvalStart2    = 0
                        let RSIvalNext2     = 0


                        if(isShowStochMA && isRSIchart===false && isCORRchart===false){
                            RSIvalStartVal2 = RSIobjStart[currNameStr][1]
                            RSIvalNextVal2  = RSIobjNext[currNameStr][1]

                            RSIvalStart2    = ((canvasChartHeight * (RSIvalStartVal2 - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)
                            RSIvalNext2     = ((canvasChartHeight * (RSIvalNextVal2  - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)

                            RSIvalStart2    = ((canvasChartHeight - RSIvalStart2 ) + YchartShiftPX) + chartYvelocity 
                            RSIvalNext2     = ((canvasChartHeight - RSIvalNext2 ) + YchartShiftPX) + chartYvelocity

                            CURRcirclePointYcord2[currNameStr] = RSIvalStart2
                        }



                        const allVerticalLinesXcordForRSIstart = (
                            ((ind - (isYmagnificationActivated ? dataCandleStartEndIndex.dataCandleStartIndex : 0)) * candleWidthSize) + ((candleWidthSize + candleCordPlusCenter)*0.5)
                        ) + XchartShiftPX + chartXvelocity

                        const allVerticalLinesXcordForRSInext = (
                            (((ind+1) - (isYmagnificationActivated ? dataCandleStartEndIndex.dataCandleStartIndex : 0)) * candleWidthSize) + ((candleWidthSize + candleCordPlusCenter)*0.5)
                        ) + XchartShiftPX + chartXvelocity



                        const isPAIRrsiOrstoch = isPAIRrsiORstochLineChart && currNameStr !== chartInfoObj.instruName.slice(0,3)
                        const isPAIRrsiOrstochBASE_CURR = isPAIRrsiORstochLineChart && currNameStr === chartInfoObj.instruName.slice(0,3)


                        /*
                        setCurrentPRICEdatetimeObj({
                            datetimeStart : dbData[0].datetime,
                            datetimeEnd   : dbData[dbData.length-1].datetime
                        })  
                        */
                       //console.log({RSIobjStart})

                        const areDATETIMEtoAuthTruthy = authDatetimeStrValidityBeforeDrawORedit(currentPriceDATETIME) && authDatetimeStrValidityBeforeDrawORedit(RSIobjNext.datetime)
                        if(
                            areDATETIMEtoAuthTruthy &&
                            datetimeToTimestamp(RSIobjNext.datetime) <= datetimeToTimestamp(currentPriceDATETIME) 
                            //&& (dataCandleStartEndIndex.dataCandleStartIndex + ind) < ((data.length-1) - dataShiftCount)
                        ){
                            // Draw line
                            context.beginPath(); // reset the context state
                            context.strokeStyle = isPAIRrsiOrstoch ? 'rgba(0, 0, 0, 0.00)' : 
                            (currHighlightHovered===currNameStr && chartInfoObj.indexORpair==='index') && currsHighlightedArray.includes(currHighlightHovered) ? 'rgba(0, 0, 0, 0.07)' :
                            currsHighlightedArray.includes(currNameStr) || isPAIRrsiOrstochBASE_CURR || (currHighlightHovered===currNameStr && chartInfoObj.indexORpair==='index') 
                            ? currColorObj[isPAIRrsiOrstochBASE_CURR ? 'EUR' : currNameStr] :  'rgba(0, 0, 0, 0.07)'//"red"
                            context.lineWidth = 2; // thickness of the line
                            context.moveTo(allVerticalLinesXcordForRSIstart, RSIvalStart); // moveTo(x,y) -> starting point of the line
                            context.lineTo(isnextDATETIMEreplayAuthTruthy ? allVerticalLinesXcordForRSIstart : allVerticalLinesXcordForRSInext, 
                              isnextDATETIMEreplayAuthTruthy ? RSIvalStart : RSIvalNext); // line(x,y) -> end point of the line
                            context.stroke(); 


                            if(isShowStochMA && isRSIchart===false && isCORRchart===false){
                                // Draw line
                                context.setLineDash([5, 5]);
                                context.beginPath(); // reset the context state
                                context.strokeStyle = isPAIRrsiOrstoch ? 'rgba(0, 0, 0, 0.00)' : 
                                (currHighlightHovered===currNameStr && chartInfoObj.indexORpair==='index') && currsHighlightedArray.includes(currHighlightHovered) ? 'rgba(0, 0, 0, 0.07)' :
                                currsHighlightedArray.includes(currNameStr) || isPAIRrsiOrstochBASE_CURR || (currHighlightHovered===currNameStr && chartInfoObj.indexORpair==='index') ? 
                                currColorObj[isPAIRrsiOrstochBASE_CURR ? 'EUR' : currNameStr] :  'rgba(0, 0, 0, 0.07)'//"red"
                                context.lineWidth = 2; // thickness of the line
                                context.moveTo(allVerticalLinesXcordForRSIstart, RSIvalStart2); // moveTo(x,y) -> starting point of the line
                                context.lineTo(isnextDATETIMEreplayAuthTruthy ? allVerticalLinesXcordForRSIstart : allVerticalLinesXcordForRSInext, 
                                  isnextDATETIMEreplayAuthTruthy ? RSIvalStart2 : RSIvalNext2); // line(x,y) -> end point of the line
                                context.stroke(); 
                                context.setLineDash([]);
                            }


                            isDrawRSIcirle = true
                        }

                        CURRcirclePointYcord[currNameStr] = RSIvalStart
                        CURRrsiValsObj[currNameStr]       = RSIvalStartVal //- RSIvalPrevVal //>= 0 ? '+' + String(RSIvalStartVal - RSIvalPrevVal) : RSIvalStartVal - RSIvalPrevVal


                        if(//at last data draw a fixed circle 
                            isnextDATETIMEreplayAuthTruthy
                            ||
                            (areDATETIMEtoAuthTruthy &&
                            datetimeToTimestamp(RSIobjNext.datetime) === datetimeToTimestamp(currentPriceDATETIME) )
                            //(dataCandleStartEndIndex.dataCandleStartIndex + ind) === ((data.length-1-lineXcordStepCount) - dataShiftCount)
                        ){
                            

                            /*console.log({
                                currentPRICEdatetimeObj
                            })*/
                           

                            const radian = Math.PI / 180;

                            context.beginPath();
                            context.strokeStyle = isPAIRrsiOrstoch ? 'rgba(0, 0, 0, 0.00)' : 
                            (currHighlightHovered===currNameStr && chartInfoObj.indexORpair==='index') && currsHighlightedArray.includes(currHighlightHovered) ? 'rgba(0, 0, 0, 0.07)' :
                            currsHighlightedArray.includes(currNameStr) || isPAIRrsiOrstochBASE_CURR || (currHighlightHovered===currNameStr && chartInfoObj.indexORpair==='index') 
                            ? currColorObj[isPAIRrsiOrstochBASE_CURR ? 'EUR' : currNameStr] :  'rgba(0, 0, 0, 0.12)'//"blue";
                            context.lineWidth = 1;
                            context.fillStyle = isPAIRrsiOrstoch ? 'rgba(0, 0, 0, 0.00)' : 
                            (currHighlightHovered===currNameStr && chartInfoObj.indexORpair==='index') && currsHighlightedArray.includes(currHighlightHovered) ? 'rgba(0, 0, 0, 0.07)' :
                            currsHighlightedArray.includes(currNameStr) || isPAIRrsiOrstochBASE_CURR || (currHighlightHovered===currNameStr && chartInfoObj.indexORpair==='index') 
                            ? currColorObj[isPAIRrsiOrstochBASE_CURR ? 'EUR' : currNameStr] : 'rgba(0, 0, 0, 0.12)'//"rgba(255, 255, 255, 0.1)";
                            context.arc(
                                isnextDATETIMEreplayAuthTruthy ? allVerticalLinesXcordForRSIstart : allVerticalLinesXcordForRSInext, //x
                                isnextDATETIMEreplayAuthTruthy ? RSIvalStart : RSIvalNext, //y
                                (candleWidthSize * 0.20) > 6 ? 6 : (candleWidthSize * 0.20) ,//(window.innerWidth * 4)/626,//4, //drawingEditCursorOutlierLimit*1, 
                                0 * radian, 360 * radian, false
                            );
                            context.stroke();
                            context.fill();

                            

                            //Draw horizontal baseCurrStrengthValText & centered
                            //text with 2 attrs, bottom & centered
                            context.fillStyle = isPAIRrsiOrstoch ? 'rgba(0, 0, 0, 0.00)' : 
                            (currHighlightHovered===currNameStr && chartInfoObj.indexORpair==='index') && currsHighlightedArray.includes(currHighlightHovered) ? 'rgba(0, 0, 0, 0.07)' :
                            currsHighlightedArray.includes(currNameStr) || isPAIRrsiOrstochBASE_CURR || (currHighlightHovered===currNameStr && chartInfoObj.indexORpair==='index') 
                            ? currColorObj[isPAIRrsiOrstochBASE_CURR ? 'EUR' : currNameStr] :  'rgba(0, 0, 0, 0.4)'//"green";  
                            context.font = "11px Verdana";
                            // context.fillText(text, xCoord, yCoord, maxWidth);
                            context.textAlign = "start";
                            //context.textBaseline = "center";
                            context.fillText(`${
                                isCORRchart && currPairArray.includes((chartInfoObj.corrInstruName===undefined ? 'USD' : chartInfoObj.corrInstruName) + currNameStr) ? 
                                ((chartInfoObj.corrInstruName===undefined ? 'USD' : chartInfoObj.corrInstruName) + currNameStr) :
                                isCORRchart && currPairArray.includes(currNameStr + (chartInfoObj.corrInstruName===undefined ? 'USD' : chartInfoObj.corrInstruName)) ? 
                                (currNameStr + (chartInfoObj.corrInstruName===undefined ? 'USD' : chartInfoObj.corrInstruName)) :
                                isPAIRrsiOrstochBASE_CURR ? chartInfoObj.instruName : currNameStr} - ${Math.round(
                              isnextDATETIMEreplayAuthTruthy ? RSIobjStart[currNameStr][0] : RSIobjNext[currNameStr][0])}`, //.EUR
                              //allVerticalLinesXcord + ( (candleWidthSize * 20) / 20 ),//20,//( (candleWidthSize * 20) / 23 )
                                (isnextDATETIMEreplayAuthTruthy ? allVerticalLinesXcordForRSIstart : allVerticalLinesXcordForRSInext) + (isnextDATETIMEreplayAuthTruthy ?
                                ( (candleWidthSize * 30 * 0.7) / 20 ) : ( (candleWidthSize * 20 * 0.7) / 10 )),
                                (isnextDATETIMEreplayAuthTruthy ? RSIvalStart : RSIvalNext) + (10*0.4)
                            );
                            
    
                        }



                        //console.log(`${currNameStr} : `, RSIvalStart, ' , ', RSIvalNext)
                        /*console.log({
                            currNameStr,
                            RSIvalStart,
                            RSIvalNext,
                            allVerticalLinesXcordForRSIstart,
                            allVerticalLinesXcordForRSInext
                        })*/
                    })

                }
                


                //if Ymag
                //allow right chartXvelocity: as long as > -400
                //allow left chartXvelocity: as long as < candleWidthSize * datalength , candleCount
                if(isYmagnificationActivated){
                    //console.log("chartXvelocity : ", chartXvelocity, beforeYmagChartInfoTracker)//setYmagnificationCandleStartEndIndex

                    const rightScrollXvelocityLimit = (beforeYmagChartInfoTracker.rightXscrollCandleCountLimit * candleWidthSize) - dataShiftCount
                    const leftScrollXvelocityLimit  = (beforeYmagChartInfoTracker.leftXscrollCandleCountLimit * candleWidthSize * -1) + dataShiftCount
                    /*console.log({
                        rightScrollXvelocityLimit,
                        leftScrollXvelocityLimit,
                        chartXvelocity,
                        chartYvelocity
                    })*/


                    setBeforeYmagChartInfoTracker({
                        ...beforeYmagChartInfoTracker,
                        isYmagInfoTracked : true,

                        rightScrollXvelocityLimit,
                        leftScrollXvelocityLimit  
                    })
                }
                else{//beforeYmagChartInfoTracker

                    const candleCountOnChartBeforeYmag = Math.round(Math.abs(canvasChartWidth / candleWidthSize))//round important
                    const dataCandleIndexDiff = dataCandleStartEndIndex.dataCandleEndIndex - dataCandleStartEndIndex.dataCandleStartIndex

                    setBeforeYmagChartInfoTracker({
                            dataCandleStartIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                            dataCandleEndIndex :  dataCandleStartEndIndex.dataCandleEndIndex,
                            dataCandleIndexDiff : dataCandleIndexDiff,

                            rightXscrollCandleCountLimit : (dataCandleIndexDiff + (dataCandleStartEndIndex.dataCandleStartIndex + 1)),
                            leftXscrollCandleCountLimit  : (dataCandleIndexDiff + (data.length - dataCandleStartEndIndex.dataCandleEndIndex)),

                            candleBoxWidthCount : candleCountOnChartBeforeYmag,//round important
                            candleBoxWidthSize  : candleWidthSize,
                            tfStarter           : chartInfoObj.tf,
                            tfEditor            : chartInfoObj.tf,
                            id                  : Date.now()
                    })
                }


                //get & store candleWidthSize of all drawings datetimeEnd
                if(true/*allDrawingsDatetimeEndArray.includes(datetime)*/){
                    allDrawingsDatetimeXcordObj[datetime] = allVerticalLinesXcord
                }

                //for the purpose of editing all drawings by datetime id by candleWidthSizeMoved idDiff
                const arrayId = isYmagnificationActivated ? ind : dataCandleStartEndIndex.dataCandleStartIndex + ind
                allArrayDataDatetimeIdTracker[datetime] = arrayId

                


                //--------------------------------------------------------------------------------------------------------------
                //the is the most comeback to code to ever review 
                //or the code that needs review
                //As the (candlesBoxPointOfSeparationOutlier)
                //in order to: resolve the issue of:
                //trendline/crossHair disappearance & appearance, or formation of double trendlines/crossHair when drawing 
                const candlesBoxPointOfSeparationOutlier = (candleIntervalWidthPX / 2) * 0.5
                //--------------------------------------------------------------------------------------------------------------

                //const candleWidthMinusCenter = candleIntervalWidthPX / 2
                //const candleWidthSize   = candleBoxWidth * (1 + XmagnificationPercRatio )//cws
                //const candleWidth       = (candleBoxWidth * (1 + XmagnificationPercRatio )) - candleWidthMinusCenter//w/2


                if(
                    //ind===0
                    (isPipCheckerActivated && isMouseDown ? pipCheckerCordStart.x : crossHairOnMoveCordVals.x) > drawCandleAtXcord - candleWidthMinusCenter + (candlesBoxPointOfSeparationOutlier) 
                    && 
                    (isPipCheckerActivated && isMouseDown ? pipCheckerCordStart.x : crossHairOnMoveCordVals.x) < drawCandleAtXcord + candleWidth + candleCordPlusCenter - (candlesBoxPointOfSeparationOutlier)
                    && (allVerticalLinesXcord < canvasChartWidth - priceLabelMarginSize)
                    && (crossHairOnMoveCordVals.y > datetimeLabelMarginSize || isDrawingActivated)
                ){



                    //-----------------------------------------------------------------------------------------------------------------
                    //draw circles on rsi line hovers
                    //later after stoch/rsi put below crossHair to avoid its highlight over this circle
                    let RSIcrossHairStartVal = 100
                    if(//techiman
                        //ind===0 &&
                        isCandleChart===false &&
                        ((forexORcrypto==='forex' && el.EUR && el.GBP && el.AUD && el.NZD && el.USD && el.CAD && el.CHF && el.JPY) || (forexORcrypto==='crypto' && el.BTC))
                        &&
                        //(ind < canvasChartDATAarray.length-1)
                        CURRcirclePointYcord.USD &&

                        (isDrawRSIcirle//((dataCandleStartEndIndex.dataCandleStartIndex + ind) <= ((data.length-1) - dataShiftCount))
                            ||
                            (authDatetimeStrValidityBeforeDrawORedit(currentPriceDATETIME) && authDatetimeStrValidityBeforeDrawORedit(datetime) &&
                            datetimeToTimestamp(datetime) === datetimeToTimestamp(currentPriceDATETIME) )
                        )
                        
                    ){


                        RSIcrossHairStartVal = ((canvasChartHeight * (RSIcrossHairStartVal - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)
                        RSIcrossHairStartVal = ((canvasChartHeight - RSIcrossHairStartVal ) + YchartShiftPX) + chartYvelocity 

                        
                        //console.log('width : ', candleWidthSize * 0.33)
                        currIndexArray.forEach((currNameStr, currNameStrIndex)=>{

                            const isPAIRrsiOrstoch = isPAIRrsiORstochLineChart && currNameStr !== chartInfoObj.instruName.slice(0,3)
                            const isPAIRrsiOrstochBASE_CURR = isPAIRrsiORstochLineChart && currNameStr === chartInfoObj.instruName.slice(0,3)

                            const radian = Math.PI / 180;

                            context.beginPath();
                            context.strokeStyle = isPAIRrsiOrstoch ? 'rgba(0, 0, 0, 0.00)' : 
                            currsHighlightedArray.includes(currNameStr) || isPAIRrsiOrstochBASE_CURR
                            ? currColorObj[isPAIRrsiOrstochBASE_CURR ? 'EUR' : currNameStr] : 'rgba(0, 0, 0, 0.05)'//"blue";
                            context.lineWidth = 1;
                            context.fillStyle = isPAIRrsiOrstoch ? 'rgba(0, 0, 0, 0.00)' : 
                            currsHighlightedArray.includes(currNameStr) || isPAIRrsiOrstochBASE_CURR
                            ? currColorObj[isPAIRrsiOrstochBASE_CURR ? 'EUR' : currNameStr] : 'rgba(0, 0, 0, 0.05)'//"rgba(255, 255, 255, 0.1)";
                            context.arc(
                                allVerticalLinesXcord, //x
                                CURRcirclePointYcord[currNameStr], //y
                                (candleWidthSize * 0.33) > 8 ? 8 : (candleWidthSize * 0.33) ,//(window.innerWidth * 4)/626,//4, //drawingEditCursorOutlierLimit*1, 
                                0 * radian, 360 * radian, false
                            );
                            context.stroke();
                            context.fill();


                            if(isShowStochMA && isRSIchart===false && isCORRchart===false){
                                context.beginPath();
                                context.strokeStyle = isPAIRrsiOrstoch ? 'rgba(0, 0, 0, 0.00)' : 
                                currsHighlightedArray.includes(currNameStr) || isPAIRrsiOrstochBASE_CURR
                                ? currColorObj[isPAIRrsiOrstochBASE_CURR ? 'EUR' : currNameStr] : 'rgba(0, 0, 0, 0.05)'//"blue";
                                context.lineWidth = 1;
                                context.fillStyle = isPAIRrsiOrstoch ? 'rgba(0, 0, 0, 0.00)' : 
                                currsHighlightedArray.includes(currNameStr) || isPAIRrsiOrstochBASE_CURR
                                ? currColorObj[isPAIRrsiOrstochBASE_CURR ? 'EUR' : currNameStr] : 'rgba(0, 0, 0, 0.05)'//"rgba(255, 255, 255, 0.1)";
                                context.arc(
                                    allVerticalLinesXcord, //x
                                    CURRcirclePointYcord2[currNameStr], //y
                                    (candleWidthSize * 0.33) > 8 ? 8 : (candleWidthSize * 0.33) ,//(window.innerWidth * 4)/626,//4, //drawingEditCursorOutlierLimit*1, 
                                    0 * radian, 360 * radian, false
                                );
                                context.stroke();
                                context.fill();
                            }

                            //----------------------------------------------------------------------------------------------
                            const maxRSIval = 100
                            //const RSIoutLier = outlierLimit//15 

                            const row1Ycord = maxRSIval + (outlierLimit*0.42)
                            const row2Ycord = maxRSIval + (outlierLimit*0.13)

                            let currROW1 = ((canvasChartHeight * (row1Ycord - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)
                            let currROW2 = ((canvasChartHeight * (row2Ycord  - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)

                            currROW1     = ((canvasChartHeight - currROW1 ) + YchartShiftPX) + chartYvelocity 
                            currROW2     = ((canvasChartHeight - currROW2 ) + YchartShiftPX) + chartYvelocity 


                            if(isPAIRrsiOrstochBASE_CURR){
                              context.fillStyle = 'blue' 
                              context.font = "11px Verdana"; //"15px Verdana"; - for wider innerWidth
                              // context.fillText(text, xCoord, yCoord, maxWidth);
                              context.textAlign = "start";
                              //context.textBaseline = "center";
                              //context.fillText(`${currNameStr} - ${Math.round(CURRrsiValsObj[currNameStr])}`, //.EUR
                              context.fillText(`${chartInfoObj.instruName}(${
                                /*Number(Math.round(CURRrsiValsObj[currNameStr])) >= 0 ? '+' :*/ ''
                              }${isCORRchart && (chartInfoObj.corrInstruName===undefined ? 'USD' : chartInfoObj.corrInstruName) === currNameStr ? ' - ' : Math.round(CURRrsiValsObj[currNameStr])})`, //.EUR
                              ((60*(0 + 1 )) + (isShowCorrelationScreenCover&&canvasWidth>700 ? 700*0.5 : 0)) ,
                                  currROW2
                              );//*/
                            }
                            else if(isPAIRrsiORstochLineChart===false){
                              //Draw horizontal baseCurrStrengthValText & centered
                              //text with 2 attrs, bottom & centered
                              context.fillStyle = currsHighlightedArray.includes(currNameStr) ? currColorObj[currNameStr] : 'rgba(0, 0, 0, 0.3)'//currColorObj[currNameStr]//"green";  
                              context.font = "11px Verdana"; //"15px Verdana"; - for wider innerWidth
                              // context.fillText(text, xCoord, yCoord, maxWidth);
                              context.textAlign = "start";
                              //context.textBaseline = "center";
                              //context.fillText(`${currNameStr} - ${Math.round(CURRrsiValsObj[currNameStr])}`, //.EUR
                              context.fillText(`${currNameStr}(${
                                /*Number(Math.round(CURRrsiValsObj[currNameStr])) >= 0 ? '+' :*/ ''
                              }${isCORRchart && (chartInfoObj.corrInstruName===undefined ? 'USD' : chartInfoObj.corrInstruName) === currNameStr ? ' - ' : Math.round(CURRrsiValsObj[currNameStr])})`, //.EUR
                              ['EUR', 'GBP', 'AUD', 'NZD'].includes(currNameStr) ?
                              ((60*(currNameStrIndex + 1 )) + (isShowCorrelationScreenCover&&canvasWidth>700 ? 700*0.5 : 0)) 
                              : ((60 * (currNameStrIndex + 1 - 4) + (isShowCorrelationScreenCover&&canvasWidth>700 ? 700*0.5 : 0))),//divide width into 4
                                  ['EUR', 'GBP', 'AUD', 'NZD'].includes(currNameStr) ? currROW1 : currROW2
                              );//*/
                              //------------------------------------------------------------------------------------------------------
                            }

                        })


                        if(
                            (authDatetimeStrValidityBeforeDrawORedit(currentPriceDATETIME) && authDatetimeStrValidityBeforeDrawORedit(datetime) &&
                            datetimeToTimestamp(datetime) <= datetimeToTimestamp(currentPriceDATETIME) )
                        ){

                            const datetimeCountDiff = getDatetimeDiffCount({
                                datetimeStart : datetime,
                                datetimeEnd   : currentPriceDATETIME,
                                tf            : chartInfoObj.tf//chartInfoObj.tf
                            })

                            //console.log(datetimeCountDiff.count)//+1

                            if(
                                datetimeCountDiff.count >= 0
                            ){

                                let currROW1 = ((canvasChartHeight * (0+1 - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)
                                currROW1     = ((canvasChartHeight - currROW1 ) + YchartShiftPX) + chartYvelocity

                                //Draw horizontal baseCurrStrengthValText & centered
                                //text with 2 attrs, bottom & centered
                                context.fillStyle = 'black'//"green"
                                context.font = "11px Verdana"; //"15px Verdana"; - for wider innerWidth
                                // context.fillText(text, xCoord, yCoord, maxWidth);
                                context.textAlign = "center";
                                //context.textBaseline = "center";
                                context.fillText(`<<--- ${datetimeCountDiff.count+1}`, //.EUR
                                allVerticalLinesXcord,//divide width into 4
                                    currROW1 
                                );//*/

                            }

                        }


                        

                    }
                    //-----------------------------------------------------------------------------------------------------------------



                    //if(isChartOnREPLAYmode===false || (isChartOnREPLAYmode && isMouseDown===false)){
                    if(
                      isMouseDown &&
                      isChartOnREPLAYmode &&
                      selectedDrawingForEditInfoIdObj &&
                      selectedDrawingForEditInfoIdObj.isReplayLineDrawing
                    ){
                    
                    }
                    else{
                      // Set line dash pattern (5 pixels on, 5 pixels off)
                      context.setLineDash([5, 5]);

                      // Draw crossHiar & datetime vertical line steps
                      context.beginPath(); // reset the context state
                      context.strokeStyle =  "black"; // color of the line
                      context.lineWidth = 0.5; // thickness of the line
                      context.moveTo(allVerticalLinesXcord, isCandleChart ? 0 : RSIcrossHairStartVal); // moveTo(x,y) -> starting point of the line
                      context.lineTo(allVerticalLinesXcord, isDrawingActivated && crossHairOnMoveCordVals.y < datetimeLabelMarginSize ? canvasChartHeight : canvasChartHeight - 30); // line(x,y) -> end point of the line
                      context.stroke(); // draws the line

                      // Reset line dash (draw solid line)
                      context.setLineDash([]);
                    }
                    /*else{
                      console.log(selectedDrawingForEditInfoIdObj.isReplayLineDrawing)
                    }*/


                    if(
                        (isMouseDown===false || selectedDrawingForEditInfoIdObj.isSelected===undefined || selectedDrawingForEditInfoIdObj.isSelected===false) 
                        ||
                        (
                            selectedDrawingForEditInfoIdObj.isSelected && isMouseDown &&
                            (
                                selectedDrawingForEditInfoIdObj.drawingType!=="trendline5" 
                            )
                        )
                    ){
                        //timeZoneDatetimeConverter - NOTE: modify datetime TIMEzone only for view on screen purposes - but not modify/update any state/variable
                        const t_zone_TIME_convert = timeZoneDatetimeConverter(
                            datetime,//datetime
                            localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                            zero_gmt_deviation
                        )


                        //console.log({isSelected:selectedDrawingForEditInfoIdObj.isSelected, selectedDrawingForEditInfoIdObj})
                        //Draw vertical datetimeText & centered
                        //text with 2 attrs, bottom & centered
                        context.fillStyle = "black";  
                        context.font = "11px Verdana";
                        // context.fillText(text, xCoord, yCoord, maxWidth);
                        context.textAlign = "center";
                        //context.textBaseline = "center";
                        context.fillText(
                        t_zone_TIME_convert,
                        allVerticalLinesXcord < priceLabelMarginSize ? 80 : allVerticalLinesXcord,
                        canvasChartHeight - 23
                        );
                        //console.log("priceLabelMarginSize : ", priceLabelMarginSize)
                    }


                    if(isPAIRrsiORstochLineChart===false){

                      //Draw horizontal baseCurrStrengthValText & centered
                      //text with 2 attrs, bottom & centered
                      context.fillStyle = /*isSTOCHchart ? 'black' :*/ "green";  
                      context.font = "11px Verdana";
                      // context.fillText(text, xCoord, yCoord, maxWidth);
                      context.textAlign = "start";
                      //context.textBaseline = "center";
                      context.fillText(isRSIchart ? '' : isCORRchart ? `` : isSTOCHchart ? `` : `${
                          chartInfoObj.instruName.slice(0,3)
                          //"EUR"
                      } - ${Math.floor(base_csm)}${base_csm>=quote_csm ? '(+' + String(Math.floor(base_csm-quote_csm)) + ')' : ''}`, 
                      5 + (isShowCorrelationScreenCover&&canvasWidth>700 ? 700*0.5 : 0),
                      15*1.5
                      );

                      if(
                          //chartInfoObj.instruName.length > 3
                          currPairArray.includes(chartInfoObj.instruName)
                      ){
                          //Draw horizontal quoteCurrStrengthValText & centered
                          //text with 2 attrs, bottom & centered
                          context.fillStyle = "red";  
                          context.font = "11px Verdana";
                          // context.fillText(text, xCoord, yCoord, maxWidth);
                          context.textAlign = "start";
                          //context.textBaseline = "center";
                          context.fillText(`${
                              chartInfoObj.instruName.slice(3)
                              //"USD"
                          } - ${Math.floor(quote_csm)}${base_csm>=quote_csm ? '' : '(+' + String(Math.floor(quote_csm-base_csm)) + ')'}`, 
                          5 + (isShowCorrelationScreenCover&&canvasWidth>700 ? 700*0.5 : 0),
                          15*2.5
                          );
                      }

                    }



                    //techiman
                    //All Drawings Config
                    if(
                        //isDrawingActivated===false  &&
                        //isEditingDrawing &&
                        isTouchScreenDevice &&
                        isMouseDown && 
                        mouseDownCount===0
                    ){
                        //alert('hey1')
                        setMouseDownCount(1)
                        //crossHairMidStartXcordDrawerInfo.drawingOnDragEditCount
                        setCrossHairMidStartXcordDrawerInfo({
                            xCord : allVerticalLinesXcord, 
                            xCord2 : allVerticalLinesXcord, 
                            datetime, 
                            datetime2 : datetime, 
                            totalCandleCountOnPage : canvasChartWidth / candleWidthSize,
                            candleWidthSize,
                            isChartStartPageOutOfView : (
                                isYmagnificationActivated===false && 
                                (data.length - dataCandleStartEndIndex.dataCandleEndIndex) > (canvasChartWidth / candleWidthSize)
                            ),
                            crossHairStartInfos : crossHairOnMoveCordVals,
                            crossHairStartInfos2 : crossHairOnMoveCordVals,
                            drawingDatetimeMidId     : allArrayDataDatetimeIdTracker[datetime],
                            drawingDatetimeMidId2     : allArrayDataDatetimeIdTracker[datetime],
                            drawingOnDragEditCount : 0,
                            drawingEditDatetimeStart : datetime,
                            drawingDatetimeIdMovedCount : 0,
                            drawingOnFirstDragEditCount : 0,

                            id:Date.now(),
                            drawingType : trendlineTypeToDraw
                        })


                        if(isDrawingActivated){
                            setOnlyOneClickDrawerInfoObj({
                                xCord : allVerticalLinesXcord, 
                                xCord2 : allVerticalLinesXcord, 
                                datetime, 
                                datetime2 : datetime, 
                                totalCandleCountOnPage : canvasChartWidth / candleWidthSize,
                                
                                isChartStartPageOutOfView : (
                                    isYmagnificationActivated===false && 
                                    (data.length - dataCandleStartEndIndex.dataCandleEndIndex) > (canvasChartWidth / candleWidthSize)
                                ),
                                crossHairStartInfos : crossHairOnMoveCordVals,
                                crossHairStartInfos2 : crossHairOnMoveCordVals,
                                drawingDatetimeMidId     : allArrayDataDatetimeIdTracker[datetime],
                                drawingDatetimeMidId2     : allArrayDataDatetimeIdTracker[datetime],
                                drawingOnDragEditCount : 0,
                                drawingEditDatetimeStart : datetime,
                                drawingDatetimeIdMovedCount : 0,
                                drawingOnFirstDragEditCount : 0,

                                id:Date.now(),
                                drawingType : trendlineTypeToDraw,

                                candleWidthSize,
                                minMaxPriceValDiff,
                                minPriceVal,
                            })
                        }
                        /*
                            Line 11556:109:  'candleWidthSize' is not defined     no-undef
                            Line 11623:128:  'minMaxPriceValDiff' is not defined  no-undef
                            Line 11623:165:  'minPriceVal' is not defined         no-undef
                        */
                    }
                    else if(
                        (
                            isMouseDown ||

                            (//so that you need not drag but draw by just a click/mouseDown
                                //crossHairMidStartXcordDrawerInfo!=={} &&
                                isDrawingActivated && 
                                (
                                    trendlineTypeToDraw === 'trendline3' //horiFromCenterToEnd
                                    || trendlineTypeToDraw === 'trendline4' //fullEndToEndHoriLine
                                    || trendlineTypeToDraw === 'trendline5' //fullEndToEndVertiLine
                                    || trendlineTypeToDraw === 'trendline7' //RectSquare
                                    //|| trendlineTypeToDraw === 'trendline8' //Circle
                                )
                            )
                        )
                    ){
                        //console.log('yoooooooooooooooooo............')
                        

                        ////stretching drawing's datetime & price at given tf candle count
                        if(//Move all drawings by midSelections

                            isDrawingActivated===false
                            && selectedDrawingForEditInfoIdObj.isSelected
                            && 
                            (
                                selectedDrawingForEditInfoIdObj.isInTLmidRange ||                     //dt + price

                                selectedDrawingForEditInfoIdObj.isDrawingEndCordSelected ||           //dt + price
                                selectedDrawingForEditInfoIdObj.isDrawingStartCordSelected ||         //dt + price

                                //rect - cursorAndDrawingEditIdObj.
                                selectedDrawingForEditInfoIdObj.isCrossHair6WithinMidRightSquareRange ||    //dt 
                                selectedDrawingForEditInfoIdObj.isCrossHair6WithinMidLeftSquareRange ||     //dt 

                                selectedDrawingForEditInfoIdObj.isCrossHair6WithinMidTopSquareRange ||      //price
                                selectedDrawingForEditInfoIdObj.isCrossHair6WithinMidBottomSquareRange ||   //price

                                selectedDrawingForEditInfoIdObj.isCrossHair6WithinTopRightCircleRange ||    //dt + price
                                selectedDrawingForEditInfoIdObj.isCrossHair6WithinTopLeftCircleRange ||     //dt + price
                                selectedDrawingForEditInfoIdObj.isCrossHair6WithinBottomRightCircleRange || //dt + price
                                selectedDrawingForEditInfoIdObj.isCrossHair6WithinBottomLeftCircleRange ||  //dt + price

                                //riskRewardRectSquare - cursorAndDrawingEditIdObj.
                                selectedDrawingForEditInfoIdObj.isCrossHair7WithinMidRightSquareRange    || //dt 

                                selectedDrawingForEditInfoIdObj.isCrossHair7WithinMidLeftCircleRange     || //price

                                selectedDrawingForEditInfoIdObj.isCrossHair7WithinTopLeftSquareRange     || //price
                                selectedDrawingForEditInfoIdObj.isCrossHair7WithinBottomLeftSquareRange     //price
                            )
                            && (
                                   selectedDrawingForEditInfoIdObj.drawingType === 'trendline1' //normalAngularTrendline
                                || selectedDrawingForEditInfoIdObj.drawingType === 'trendline2'//normalButStraightTrendline
                                || selectedDrawingForEditInfoIdObj.drawingType === 'trendline3'//horiFromCenterToEnd
                                || selectedDrawingForEditInfoIdObj.drawingType === 'trendline4'//fullEndToEndHoriLine
                                || selectedDrawingForEditInfoIdObj.drawingType === 'trendline5'//fullEndToEndVertiLine

                                || selectedDrawingForEditInfoIdObj.drawingType === 'trendline6'//rect(x,y,w,h) === trendline1

                                || selectedDrawingForEditInfoIdObj.drawingType === 'trendline7'//rect(x,y,w,h) === trendline3

                                || selectedDrawingForEditInfoIdObj.drawingType === 'trendline8'//arc(x,y,w,h) === trendline10

                                //[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13
                                /*[
                                    'trendline1_1',//angularTL
                                    'trendline1_2',//angularPointerTL
                                    'trendline2',//shortHoriTL
                                    'trendline3',//centerToEndHoriTL
                                    'trendline4',//HoriTL
                                    'trendline5',//VertiTL
                                    'trendline6_1',//rect-color
                                    'trendline6_2',//rect
                                    'trendline7_1',//rectSquareSELL
                                    'trendline7_2',//rectSquareBUY
                                    'trendline8',//circle
                                ]*/
                            )
                        ){

                            //console.log('heloooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo')
                            /*console.log({
                                selectedDrawingForEditInfoIdObj
                            })*/

                            setIsEditingDrawing(true)

                            


                            const {
                                /*candleBoxWidthCount,        // :  6,
                                candleBoxWidthSize,         // :  10,
                                cordYpriceEnd,              // :  1.0888433761096152,
                                cordYpriceStart,            // :  1.0919684161096153,
                                datetimeEnd,                // :  "2023-08-25 12:00:00",
                                datetimeStart,              // :  "2023-08-24 12:00:00",
                                drawingType,                // :  "trendline1",*/
                                id,                         // :  1705990866150,
                                /*isDrawingForward,           // :  false,
                                isRectDrawingColored,       // :  false,
                                isRectSquareDrawingSell,    // :  false,
                                isTrendlineEndPointy,       // :  false,
                                rectSquareLowerHeightPrice, // :  0,
                                rectSquareUpperHeightPrice, // :  0,
                                tfEditor,                   // :  "h4",
                                tfStarter,                  // : "h4"*/

                                cursorType,                 // :  "default",-------------------
                                isDrawingEndCordSelected,   // :  true,-----------
                                isDrawingStartCordSelected, // :  false,----------
                                isDrawingMidCordSelected,   // :  false,------------
                                isInTLmidRange,             // :  false,----------------
                                isSelected,                 // :  true,----------------

                                //rect
                                isCrossHair6WithinMidRightSquareRange,
                                isCrossHair6WithinMidLeftSquareRange,

                                isCrossHair6WithinMidTopSquareRange,
                                isCrossHair6WithinMidBottomSquareRange,

                                isCrossHair6WithinTopRightCircleRange,
                                isCrossHair6WithinTopLeftCircleRange,
                                isCrossHair6WithinBottomRightCircleRange,
                                isCrossHair6WithinBottomLeftCircleRange,

                                //riskRewardRectSquare
                                isCrossHair7WithinMidRightSquareRange,

                                isCrossHair7WithinMidLeftCircleRange,

                                isCrossHair7WithinTopLeftSquareRange,
                                isCrossHair7WithinBottomLeftSquareRange,

                            } = selectedDrawingForEditInfoIdObj
                            /*console.log({
                                drawingType,
                                isInTLmidRange,
                                isDrawingEndCordSelected
                            })//read through understand whole concept first*/

                            /*isInTLmidRange===false
                            && isDrawingEndCordSelected*/


                            //console.log('wenchi...')
                            //-------------------------------------------------------------------------------
                            //isEditIdEqualLockedReplayId
                            //isEditIdEqualLockedVertiLineId
                            //use truthy to Auth final LStorage type at end stage of edit
                            let isEditIdEqualLockedReplayId = false
                            let lockedReplayInfoObj = {}
                            if(replayLockedDatetimeArray.length > 0){
                                const dataObj = replayLockedDatetimeArray[replayLockedDatetimeArray.length-1]
                                if(id===dataObj.id){
                                    isEditIdEqualLockedReplayId = true
                                    lockedReplayInfoObj = dataObj
                                }
                            }

                            let isEditIdEqualLockedVertiLineId = false
                            let lockedVertiLineInfoObj = {}
                            if(lockedDatetimeArray.length > 0){
                                const dataObj = lockedDatetimeArray[lockedDatetimeArray.length-1]
                                if(id===dataObj.id){
                                    isEditIdEqualLockedVertiLineId = true
                                    lockedVertiLineInfoObj = dataObj
                                }
                            }
                            //---------------------------------------------------------------------------------


                            //-------------------------------------------------------------------------------
                            const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                            const isDataExist = drawingData !== null
                            const parsedDataArray = JSON.parse(drawingData)
                            const selectDrawingObj = isEditIdEqualLockedReplayId ? lockedReplayInfoObj : 
                            isEditIdEqualLockedVertiLineId ? lockedVertiLineInfoObj : isDataExist ? (parsedDataArray.filter((el, elInd)=>{
                                return el.id===id
                            }))[0] : {}

                            const {
                                candleBoxWidthCount,        // :  6,
                                //candleBoxWidthSize,         // :  10,
                                cordYpriceEnd,              // :  1.0888433761096152,
                                cordYpriceStart,            // :  1.0919684161096153,
                                datetimeEnd,                // :  "2023-08-25 12:00:00",
                                datetimeStart,              // :  "2023-08-24 12:00:00",
                                drawingType,                // :  "trendline1",
                                //id,                         // :  1705990866150,
                                isDrawingForward,           // :  false,
                                isRectDrawingColored,       // :  false,
                                isRectSquareDrawingSell,    // :  false,
                                isTrendlineEndPointy,       // :  false,
                                rectSquareLowerHeightPrice, // :  0,
                                rectSquareUpperHeightPrice, // :  0,
                                tfEditor,                   // :  "h4",
                                tfStarter,                  // : "h4",

                                isRectangleTextInput,
                                text,
                                isReplayLineDrawing,
                                isGlobalLockedVertiLine,
                                isReplayGlobalLockedVertiLine
                            } = selectDrawingObj

                            //console.log({selectDrawingObj})
                            //------------------------------------------------------------------------------
                            



                            if(
                                authDatetimeStrValidityBeforeDrawORedit(datetimeStart) && 
                                authDatetimeStrValidityBeforeDrawORedit(datetimeEnd) && 
                                authDatetimeStrValidityBeforeDrawORedit(datetime) 
                            ){
                                
                                
                                let newCandleBoxWidthCount = candleBoxWidthCount //* tfCandleRatioCount
                               
                               



                                //any of the datetimeStart or datetimeEnd to edit and their ArrayId should be truthy
                                //before working with them to store or update in LS
                                //allDrawingsDatetimeXcordObj[datetime] = allVerticalLinesXcord
                                //allArrayDataDatetimeIdTracker[datetime] = arrayId
                                if(
                                    true /*&&//(authDatetimeStartTruthy || authDatetimeEndTruthy) //&& false

                                    (
                                        allDrawingsDatetimeXcordObj[datetimeEnd] ||
                                        allDrawingsDatetimeXcordObj[datetimeStart] ||
                                        allDrawingsDatetimeXcordObj[datetime]
                                    )*/
                                ){
                                    //work with either datetimeStart or datetimeEnd xCordMove that appears to be still within the range of the canvasChartWidth
                                    
                                    //let newDrawingDatetimeStartId = undefined
                                    //let newDrawingDatetimeEndId   = undefined

                                    //techiman
                                    let datetimeStartNewXcord     = allDrawingsDatetimeXcordObj[datetimeStart]
                                    let datetimeEndNewXcord       = allDrawingsDatetimeXcordObj[datetimeEnd]

                                    let newDrawingStartDatetime   = datetimeStart
                                    let newDrawingEndDatetime     = datetimeEnd

                                    let isDrawFromStartCount = false
                                    let isDrawFromEndCount   = false
                                    let isDrawFromMidCount   = false

                                    /*const isONLYdrawingDatetimeEndAwayBeyondChartShiftedCandlesReach = datetimeToTimestamp(datetimeEnd) > datetimeToTimestamp(
                                      authDatetimeStrValidityBeforeDrawORedit(data[data.length-1].datetime) ? data[data.length-1].datetime : datetimeEnd
                                    )
                                    console.log('is TL end > : ', isONLYdrawingDatetimeEndAwayBeyondChartShiftedCandlesReach)*/
                                    

                                    //--------------------------------------------------------------------------------------------------------------------------
                                    const prevDrawingEditDatetime = crossHairMidStartXcordDrawerInfo.drawingEditDatetimeStart
                                    const nextDrawingEditDatetime = datetime

                                    const isNextDrawingDatetimeGreater = datetimeToTimestamp(nextDrawingEditDatetime) > datetimeToTimestamp(prevDrawingEditDatetime)
                                    const isNextDrawingDatetimeLesser  = datetimeToTimestamp(nextDrawingEditDatetime) < datetimeToTimestamp(prevDrawingEditDatetime)
                                    const isNextDrawingDatetimeEqual   = datetimeToTimestamp(nextDrawingEditDatetime) === datetimeToTimestamp(prevDrawingEditDatetime)
                                    //--------------------------------------------------------------------------------------------------------------------------




                                    if(//where htf dtStart/dtEnd fails to appear in ltf, then we only consider the change in movement of Price but not datetime or xCord
                                        //isBothTLStartEndPointdatetimeExist===false
                                        //isAtLeastOneTLStartEndPointdatetimeExist===false
                                        //false &&
                                        //isDrawingStartToEndDatetimeAwayBeyondChartReach
                                        isNextDrawingDatetimeEqual
                                    ){//maintain the dtStart & dtEnd, with xCords of 0 && canvasChartWidth

                                        isDrawFromMidCount = true

                                        datetimeStartNewXcord   = allDrawingsDatetimeXcordObj[datetimeStart]//0
                                        datetimeEndNewXcord     = allDrawingsDatetimeXcordObj[datetimeEnd]//canvasChartWidth

                                        newDrawingStartDatetime = datetimeStart 
                                        newDrawingEndDatetime   = datetimeEnd

                                        //console.log('hello i dont exist')

                                    }
                                    else if(
                                        /*(isStartDatetimeExistInDataArray ||
                                        isEndDatetimeExistInDataArray ||
                                        isDrawingStartToEndDatetimeAwayBeyondChartReach)
                                        && drawingDatetimeIdMovedCount !== 0 // make datetime changes only when there is a move count*/
                                        isNextDrawingDatetimeGreater ||
                                        isNextDrawingDatetimeLesser
                                    ){//only change start/end datetime if at least starttime or endtime exist in dataArray
                                        //console.log('stretch started ----------------------------------------------------')
                                        

                                        if(isInTLmidRange){//check the the usefulness of: (candleBoxWidthCount * tfCandleRatioCount)
                                            //only change start/end datetime if at least starttime or endtime exist in dataArray

                                            isDrawFromMidCount = true

                                            const datetimeStartToGet = datetimeToTimestamp(datetimeStart) <= datetimeToTimestamp(datetimeEnd) ? datetimeStart : datetimeEnd
                                            const datetimeEndToGet   = datetimeToTimestamp(datetimeEnd)   >= datetimeToTimestamp(datetimeStart) ? datetimeEnd : datetimeStart

                                            //--------------------------------------------------------------------------------------------------------------------
                                            //calc if timeStamp diff of a given tf(eg: m1) is < a given time eg : a year
                                            const datetimeStartMovedToArray = getNextOrPrevDatetimeByTF(
                                                datetimeStartToGet, 
                                                chartInfoObj.tf, 
                                                isNextDrawingDatetimeGreater/*drawingDatetimeIdMovedCount > 0*/ ? "next" : "prev",//nextORprevDatetime, 
                                                1,//Math.abs(drawingDatetimeIdMovedCount),//nextORprevDatetimeToGetCount, 
                                                {},//prevDatetimeToGetPriceDataObjParam, 
                                                {},//nextDatetimeToGetPriceDataObjParam
                                                //datetime, 
                                                //true
                                            )
                                            //console.log({datetimeStartMovedToArray})
                                            //--------------------------------------------------------------------------------------------------------------------

                                            //--------------------------------------------------------------------------------------------------------------------
                                            //calc if timeStamp diff of a given tf(eg: m1) is < a given time eg : a year
                                            const datetimeEndMovedToArray = getNextOrPrevDatetimeByTF(
                                                datetimeEndToGet, 
                                                chartInfoObj.tf, 
                                                isNextDrawingDatetimeGreater/*drawingDatetimeIdMovedCount > 0*/ ? "next" : "prev",//nextORprevDatetime, 
                                                1,//Math.abs(drawingDatetimeIdMovedCount),//nextORprevDatetimeToGetCount, 
                                                {},//prevDatetimeToGetPriceDataObjParam, 
                                                {},//nextDatetimeToGetPriceDataObjParam
                                                //datetime, 
                                                //true
                                            )
                                            //console.log({datetimeEndMovedToArray})
                                            //--------------------------------------------------------------------------------------------------------------------

                                            //console.log({isNextDrawingDatetimeGreater})
                                            const datetimeStartMovedToArrayDatetime = datetimeStartMovedToArray[isNextDrawingDatetimeGreater > 0 ? 0 : datetimeStartMovedToArray.length - 1].datetime
                                            const datetimeEndMovedToArrayDatetime   = datetimeEndMovedToArray[isNextDrawingDatetimeGreater > 0 ? 0 : datetimeEndMovedToArray.length - 1].datetime


                                            if(
                                                datetimeStartMovedToArray.length > 0 &&
                                                datetimeEndMovedToArray.length   > 0 &&
                                                (
                                                    authDatetimeStrValidityBeforeDrawORedit(datetimeStartMovedToArrayDatetime) &&
                                                    authDatetimeStrValidityBeforeDrawORedit(datetimeEndMovedToArrayDatetime)
                                                )
                                            ){
                                                //--------------------------------------------------------------------------------------------------------------------
                                                //console.log('yooooooooooooooooooooooooo------------------', /*drawingDatetimeIdMovedCount*/)

                                                newDrawingStartDatetime = datetimeStartMovedToArrayDatetime
                                                newDrawingEndDatetime   = datetimeEndMovedToArrayDatetime


                                                datetimeStartNewXcord = allDrawingsDatetimeXcordObj[newDrawingStartDatetime]
                                                datetimeEndNewXcord   = allDrawingsDatetimeXcordObj[newDrawingEndDatetime]
                                                //--------------------------------------------------------------------------------------------------------------------
                                            }
                                            else if(
                                                datetimeStartMovedToArray.length === 0 &&
                                                datetimeEndMovedToArray.length   === 0 
                                            ){
                                                newDrawingStartDatetime = datetimeStart
                                                newDrawingEndDatetime   = datetimeEnd


                                                datetimeStartNewXcord = allDrawingsDatetimeXcordObj[datetimeStart]
                                                datetimeEndNewXcord   = allDrawingsDatetimeXcordObj[datetimeEnd]
                                            }

                                            /*
                                            selectedDrawingForEditInfoIdObj.isCrossHair7WithinMidRightSquareRange    || //dt 

                                            selectedDrawingForEditInfoIdObj.isCrossHair7WithinMidLeftCircleRange     || //price

                                            selectedDrawingForEditInfoIdObj.isCrossHair7WithinTopLeftSquareRange     || //price
                                            selectedDrawingForEditInfoIdObj.isCrossHair7WithinBottomLeftSquareRange     //price
                                            */


                                        }
                                        else if(//right-top-down-left-stretch
                                            (isDrawingEndCordSelected ||
                                            isCrossHair6WithinTopRightCircleRange ||
                                            isCrossHair6WithinBottomRightCircleRange ||
                                            isCrossHair6WithinMidRightSquareRange ||
                                            isCrossHair7WithinMidRightSquareRange) 
                                        ){//Get both datetime & xCord of both trendline start & end points
                                            //console.log('right-top-down-left-stretch : ', /*drawingDatetimeIdMovedCount*/)//

                                            isDrawFromEndCount = true

                                            //newCandleBoxWidthCount = newCandleBoxWidthCount + drawingDatetimeIdMovedCount

                                            //get the new xCordStart 
                                            //made undefined in order for it not to be accessible during drawing
                                            //but to have candleCount to calc from the point of stretching either from the start or end
                                            //newDrawingDatetimeStartId = undefined 
                                            datetimeStartNewXcord     =  allDrawingsDatetimeXcordObj[datetimeStart]//undefined
                                            
                                            
                                            //get the new xCordEnd
                                            /*newDrawingDatetimeEndId   = drawingDatetimeEndId + drawingDatetimeIdMovedCount
                                            const drawingMovementDiff2 = newDrawingDatetimeEndId - drawingDatetimeMovedToId*/
                                            datetimeEndNewXcord     = allVerticalLinesXcord// + (candleWidthSize * drawingDatetimeIdMovedCount)

                                            //get the newDrawingEndDatetime
                                            newDrawingEndDatetime = datetime//data[newDrawingDatetimeEndId].datetime

                                            newDrawingStartDatetime = datetimeStart 

                                        }
                                        else if(//left-top-down-right-stretch
                                            (isDrawingStartCordSelected ||
                                            isCrossHair6WithinTopLeftCircleRange ||
                                            isCrossHair6WithinBottomLeftCircleRange ||
                                            isCrossHair6WithinMidLeftSquareRange ||
                                            (isTouchScreenDevice && isCrossHair7WithinTopLeftSquareRange)||
                                            (isTouchScreenDevice && isCrossHair7WithinBottomLeftSquareRange) ||
                                            (isCrossHair7WithinMidLeftCircleRange)) 
                                        ){//Get both datetime & xCord of both trendline start & end points
                                            //console.log('left-top-down-right-stretch')

                                            isDrawFromStartCount = true
                                            
                                            //newCandleBoxWidthCount = newCandleBoxWidthCount - drawingDatetimeIdMovedCount

                                            //get the new xCordStart
                                            /*newDrawingDatetimeStartId = drawingDatetimeStartId + drawingDatetimeIdMovedCount
                                            const drawingMovementDiff = newDrawingDatetimeStartId - drawingDatetimeMovedToId*/
                                            datetimeStartNewXcord     = allVerticalLinesXcord //+ (candleWidthSize * drawingDatetimeIdMovedCount)

                                            //get the new xCordEnd
                                            //made undefined in order for it not to be accessible during drawing
                                            //but to have candleCount to calc from the point of stretching either from the start or end
                                            //newDrawingDatetimeEndId = undefined
                                            datetimeEndNewXcord     = allDrawingsDatetimeXcordObj[datetimeEnd]//undefined

                                            //get the newDrawingEndDatetime
                                            newDrawingStartDatetime = datetime//*/data[newDrawingDatetimeStartId].datetime

                                            newDrawingEndDatetime   = datetimeEnd 

                                        }
                                        else if(//top & down -stretch 
                                            isCrossHair6WithinMidTopSquareRange ||
                                            isCrossHair6WithinMidBottomSquareRange ||
                                            isCrossHair7WithinMidLeftCircleRange ||
                                            isCrossHair7WithinTopLeftSquareRange ||
                                            isCrossHair7WithinBottomLeftSquareRange
                                        ){//Get both datetime & xCord of both trendline start & end points

                                            isDrawFromMidCount = true

                                            //get the new xCordStart
                                            /*newDrawingDatetimeStartId = drawingDatetimeStartId + drawingDatetimeIdMovedCount
                                            const drawingMovementDiff = newDrawingDatetimeStartId - drawingDatetimeMovedToId*/
                                            datetimeStartNewXcord     = allDrawingsDatetimeXcordObj[datetimeStart] //+ (candleWidthSize * drawingDatetimeIdMovedCount)

                                            //get the new xCordEnd
                                            //made undefined in order for it not to be accessible during drawing
                                            //but to have candleCount to calc from the point of stretching either from the start or end
                                            //newDrawingDatetimeEndId = undefined
                                            datetimeEndNewXcord     = allDrawingsDatetimeXcordObj[datetimeEnd]

                                            //get the newDrawingEndDatetime
                                            newDrawingStartDatetime = datetimeStart//*/data[newDrawingDatetimeStartId].datetime

                                            newDrawingEndDatetime   = datetimeEnd 

                                        }




                                    }
                                    
                                    //console.log({newCandleBoxWidthCount})
                                    //console.log({isCrossHair6WithinMidTopSquareRange, isInTLmidRange})

                                    
                                    //NOTE:
                                    //where htf dtStart/dtEnd fails to appear in ltf, then we only consider the change in movement of Price but not datetime or xCord
                                    
                                    
                                    //convert Price to PX
                                    //Add YcrossHairMovedDiff to PX = newPX 
                                    //convert newPX to Price
                                    let YcordPriceStart = ((canvasChartHeight * (cordYpriceStart - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) 
                                    //YcordPriceStart     = ((canvasChartHeight - YcordPriceStart) + YchartShiftPX) + chartYvelocity 
                                    //YcordPriceStart     = YcordPriceStart + YchartShiftPX + chartYvelocity 

                                    let YcordPriceEnd = ((canvasChartHeight * (cordYpriceEnd - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) 
                                    //YcordPriceEnd     = ((canvasChartHeight - YcordPriceEnd) + YchartShiftPX) + chartYvelocity //*/
                                    //YcordPriceEnd     =  YcordPriceEnd + YchartShiftPX + chartYvelocity //*/

                                    const YcordOnEditStart = crossHairMidStartXcordDrawerInfo.crossHairStartInfos2.y
                                    const YcordOnEditEnd   = crossHairOnMoveCordVals.y
                                    const YcordOnEditStartEndDiff = YcordOnEditEnd - YcordOnEditStart

                                    const XcordOnEditStart = crossHairMidStartXcordDrawerInfo.crossHairStartInfos2.x
                                    const XcordOnEditEnd   = crossHairOnMoveCordVals.x
                                    const XcordOnEditStartEndDiff = XcordOnEditEnd - XcordOnEditStart

                                    /*const newYcordPriceStart = (//isCrossHair6WithinMidRightSquareRange
                                        isDrawingEndCordSelected ||
                                        isCrossHair6WithinTopRightCircleRange ||
                                        isCrossHair6WithinBottomRightCircleRange
                                    ) ? YcordPriceStart : YcordPriceStart + YcordOnEditStartEndDiff
                                    const newYcordPriceEnd   = (
                                        isDrawingStartCordSelected ||
                                        isCrossHair6WithinTopLeftCircleRange ||
                                        isCrossHair6WithinBottomLeftCircleRange
                                    ) ? YcordPriceEnd : YcordPriceEnd + YcordOnEditStartEndDiff*/

                                    const currentPriceByCrossHairYcordVal = crossHairOnMoveCordVals.y + YchartShiftPX + chartYvelocity 

                                    let newYcordPriceStart = (//isCrossHair6WithinMidRightSquareRange
                                        (isCrossHair6WithinMidRightSquareRange || isCrossHair6WithinMidLeftSquareRange) ||
                                        isDrawingEndCordSelected ||
                                        (
                                            (isCrossHair6WithinTopRightCircleRange || isCrossHair6WithinTopLeftCircleRange) && YcordPriceEnd >= YcordPriceStart
                                        ) ||
                                        (
                                            (isCrossHair6WithinBottomRightCircleRange || isCrossHair6WithinBottomLeftCircleRange) && YcordPriceStart >= YcordPriceEnd
                                        ) ||
                                        //(YcordPriceEnd < YcordPriceStart ? isCrossHair6WithinMidTopSquareRange : isCrossHair6WithinMidBottomSquareRange)
                                        (
                                            ((isCrossHair6WithinMidBottomSquareRange) && YcordPriceStart >= YcordPriceEnd) ||
                                            ((isCrossHair6WithinMidTopSquareRange) && YcordPriceEnd >= YcordPriceStart) /*||
                                            ((isCrossHair6WithinMidTopSquareRange || isCrossHair6WithinMidBottomSquareRange) && YcordPriceEnd === YcordPriceStart)*/
                                        )
                                        //|| isCrossHair7WithinMidRightSquareRange
                                    ) ? YcordPriceStart : /*drawingType === 'trendline1' &&*/ isInTLmidRange===false ? currentPriceByCrossHairYcordVal :  YcordPriceStart + YcordOnEditStartEndDiff

                                    let newYcordPriceEnd   = (
                                        (isCrossHair6WithinMidRightSquareRange || isCrossHair6WithinMidLeftSquareRange) ||
                                        isDrawingStartCordSelected ||
                                        (
                                            (isCrossHair6WithinTopLeftCircleRange || isCrossHair6WithinTopRightCircleRange) && YcordPriceStart >= YcordPriceEnd
                                        ) ||
                                        (
                                            (isCrossHair6WithinBottomLeftCircleRange || isCrossHair6WithinBottomRightCircleRange) && YcordPriceEnd >= YcordPriceStart
                                        ) ||
                                        (
                                            ((isCrossHair6WithinMidBottomSquareRange) && YcordPriceEnd >= YcordPriceStart) ||
                                            ((isCrossHair6WithinMidTopSquareRange) && YcordPriceStart >= YcordPriceEnd) /*||
                                            ((isCrossHair6WithinMidTopSquareRange || isCrossHair6WithinMidBottomSquareRange) && YcordPriceEnd === YcordPriceStart)*/
                                        )
                                        //|| isCrossHair7WithinMidRightSquareRange
                                    ) ? YcordPriceEnd : /*drawingType === 'trendline1' &&*/ isInTLmidRange===false ? currentPriceByCrossHairYcordVal : YcordPriceEnd + YcordOnEditStartEndDiff



                                    
                                    if(
                                        (drawingType === 'trendline2' && isInTLmidRange) ||
                                        drawingType === 'trendline3' ||
                                        drawingType === 'trendline4' 
                                    ){
                                        newYcordPriceStart = crossHairOnMoveCordVals.y + YchartShiftPX + chartYvelocity 
                                        newYcordPriceEnd   = crossHairOnMoveCordVals.y + YchartShiftPX + chartYvelocity 
                                    }
                                    else if(
                                        drawingType === 'trendline7'
                                    ){
                                        if(
                                            (isTouchScreenDevice===false && isCrossHair7WithinMidRightSquareRange) ||
                                            isCrossHair7WithinTopLeftSquareRange ||
                                            isCrossHair7WithinBottomLeftSquareRange
                                        ){
                                            newYcordPriceStart = YcordPriceStart
                                            newYcordPriceEnd   = YcordPriceEnd
                                        }
                                        else if(
                                            isCrossHair7WithinMidLeftCircleRange 
                                            || (isTouchScreenDevice && isCrossHair7WithinMidRightSquareRange)
                                        ){
                                            //console.log('runned')
                                            const currentDragToPrice = crossHairOnMoveCordVals.y + YchartShiftPX + chartYvelocity //(YcordPriceStart + YcordOnEditStartEndDiff)
                                            
                                            const upperHeightPriceYcord = ((canvasChartHeight * (rectSquareUpperHeightPrice - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)
                                            const lowerHeightPriceYcord = ((canvasChartHeight * (rectSquareLowerHeightPrice - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)

                                            const isBtnTopDownPrice = currentDragToPrice < upperHeightPriceYcord && currentDragToPrice > lowerHeightPriceYcord

                                            //console.log({currentDragToPrice})
                                            if(isBtnTopDownPrice){
                                                newYcordPriceStart = currentDragToPrice//YcordPriceStart + YcordOnEditStartEndDiff
                                                newYcordPriceEnd   = currentDragToPrice//YcordPriceEnd   + YcordOnEditStartEndDiff
                                            }
                                            else{
                                                newYcordPriceStart = YcordPriceStart 
                                                newYcordPriceEnd   = YcordPriceEnd   
                                            }
                                        }
                                    }
                                    else if(//in order to: avoid non-price movement anytime priceStart===priceEnd
                                        drawingType === 'trendline6'
                                        //&& false
                                    ){

                                        const isDragFromTop    = isCrossHair6WithinMidTopSquareRange    || isCrossHair6WithinTopRightCircleRange    || isCrossHair6WithinTopLeftCircleRange
                                        const isDragFromBottom = isCrossHair6WithinMidBottomSquareRange || isCrossHair6WithinBottomRightCircleRange || isCrossHair6WithinBottomLeftCircleRange

                                        newYcordPriceStart = (isDragFromTop    && newYcordPriceStart === newYcordPriceEnd) ? newYcordPriceStart - 10 : newYcordPriceStart
                                        newYcordPriceEnd   = (isDragFromBottom && newYcordPriceStart === newYcordPriceEnd) ? newYcordPriceEnd + 10   : newYcordPriceEnd
                                    }

                                    let newCordYpriceStart = ((newYcordPriceStart /*+ YchartShiftPX + chartYvelocity*/) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;
                                    let newCordYpriceEnd   = ((newYcordPriceEnd /*+ YchartShiftPX + chartYvelocity*/) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;

                                    //const oldCordYpriceStart = ((YcordPriceStart /*+ YchartShiftPX + chartYvelocity*/) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;
                                    //const oldCordYpriceEnd = ((YcordPriceEnd /*+ YchartShiftPX + chartYvelocity*/) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;



                                    let newRectSquareUpperHeightPrice = rectSquareUpperHeightPrice
                                    let newRectSquareLowerHeightPrice = rectSquareLowerHeightPrice
                                    if(
                                        drawingType === 'trendline7'
                                        &&
                                        (
                                            isInTLmidRange ||
                                            isCrossHair7WithinTopLeftSquareRange ||
                                            isCrossHair7WithinBottomLeftSquareRange
                                        )//&& false
                                    ){
                                        //')
                                        const upperHeightPriceYcord = ((canvasChartHeight * (rectSquareUpperHeightPrice - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)
                                        const lowerHeightPriceYcord = ((canvasChartHeight * (rectSquareLowerHeightPrice - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio)

                                        //const newUpperHeightPriceYcord = upperHeightPriceYcord + YcordOnEditStartEndDiff
                                        //const newLowerHeightPriceYcord = lowerHeightPriceYcord + YcordOnEditStartEndDiff

                                        if(isCrossHair7WithinTopLeftSquareRange){
                                            const newUpperHeightPriceYcord = crossHairOnMoveCordVals.y + YchartShiftPX + chartYvelocity 
                                            
                                            newRectSquareUpperHeightPrice = ((
                                                newUpperHeightPriceYcord > YcordPriceStart ? newUpperHeightPriceYcord : YcordPriceStart
                                                ) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;
                                        }
                                        else if(isCrossHair7WithinBottomLeftSquareRange){
                                            const newLowerHeightPriceYcord = crossHairOnMoveCordVals.y + YchartShiftPX + chartYvelocity 

                                            newRectSquareLowerHeightPrice = ((
                                                newLowerHeightPriceYcord < YcordPriceStart ? newLowerHeightPriceYcord : YcordPriceStart 
                                            ) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;
                                        }
                                        else if(isInTLmidRange){
                                            const newUpperHeightPriceYcord = upperHeightPriceYcord + YcordOnEditStartEndDiff
                                            const newLowerHeightPriceYcord = lowerHeightPriceYcord + YcordOnEditStartEndDiff

                                            newRectSquareUpperHeightPrice = ((newUpperHeightPriceYcord /*+ YchartShiftPX + chartYvelocity*/) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;
                                            newRectSquareLowerHeightPrice = ((newLowerHeightPriceYcord /*+ YchartShiftPX + chartYvelocity*/) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;
                                        }
                                    }

                                    //const cordYpriceDiff = ((YcordOnEditStartEndDiff + YchartShiftPX + chartYvelocity) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;

                                    /*console.log({
                                        //YcordPriceStart,
                                        //YcordPriceEnd,

                                        cordYpriceStart,
                                        cordYpriceEnd,

                                        newCordYpriceStart,
                                        newCordYpriceEnd,

                                        oldCordYpriceStart,
                                        oldCordYpriceEnd,

                                        //YcordOnEditStart,
                                        //YcordOnEditEnd,
                                        //YcordOnEditStartEndDiff,

                                        //cordYpriceDiff
                                    })*/

                                    
                                    
                                    
                                    

                                    /*console.log({
                                        dataStartIndexDatetime,
                                        dataEndIndexDatetime,
                                        dataStartDatetimeTimeStamp,
                                        dataEndDatetimeTimeStamp,
                                        newDrawingStartDatetimeTimeStamp,
                                        newDrawingEndDatetimeTimeStamp
                                    })*/


                                    //to avoid drawing selection from changing position onClicks
                                    const avgEditMoveDiffBeforeChange = (
                                        drawingType === 'trendline2' || drawingType === 'trendline6'
                                        /*
                                        drawingType === 'trendline3' ||
                                        drawingType === 'trendline4' ||
                                        drawingType === 'trendline5' ||
                                        drawingType === 'trendline7'*/// ||drawingType === 'trendline8'
                                    ) ? 0/*1*/ : 0
                                    


                                    if(
                                        (Math.abs(YcordOnEditStartEndDiff) > avgEditMoveDiffBeforeChange ||
                                        Math.abs(XcordOnEditStartEndDiff) > avgEditMoveDiffBeforeChange) 
                                        && 
                                        (newDrawingStartDatetime && newDrawingEndDatetime)
                                        /*&& 
                                        (
                                            (newDrawingStartDatetimeTimeStamp && newDrawingEndDatetimeTimeStamp) &&
                                            (newDrawingStartDatetimeTimeStamp <= dataEndDatetimeTimeStamp && newDrawingEndDatetimeTimeStamp >= dataStartDatetimeTimeStamp)
                                        )*/
                                        //&& (newDrawingStartDatetimeTimeStamp > dataStartDatetimeTimeStamp && newDrawingEndDatetimeTimeStamp < dataEndDatetimeTimeStamp)
                                    ){

                                        //store thr ff: to be tracked for drawing
                                        allDrawingsDatetimeXcordObj[//start
                                            /*((isDrawingEndCordSelected ||
                                            isCrossHair6WithinTopRightCircleRange ||
                                            isCrossHair6WithinBottomRightCircleRange))
                                            ? newDrawingStartDatetime : *///+ " datetimeStart made inaccessible to draw from"://to only make the end-point we had clicked(start/end) to edit xCord by datetime be accessible to calc candleCount from for drawing in a given htf/ltf & make the other datetime info inaccessible
                                            newDrawingStartDatetime
                                        ] = datetimeStartNewXcord

                                        
                                        allDrawingsDatetimeXcordObj[//end
                                            /*((isDrawingStartCordSelected ||
                                            isCrossHair6WithinTopLeftCircleRange ||
                                            isCrossHair6WithinBottomLeftCircleRange))
                                            ? newDrawingEndDatetime : *///+ " datetimeEnd made inaccessible to draw from" : //to only make the end-point we had clicked(start/end) to edit xCord by datetime be accessible to calc candleCount from for drawing in a given htf/ltf & make the other datetime info inaccessible
                                            (
                                                drawingType === 'trendline3' ||
                                                drawingType === 'trendline4' ||
                                                drawingType === 'trendline5' 
                                            ) ? newDrawingStartDatetime : newDrawingEndDatetime
                                        ] = (
                                            drawingType === 'trendline3' ||
                                            drawingType === 'trendline4' ||
                                            drawingType === 'trendline5' 
                                        ) ? datetimeStartNewXcord : datetimeEndNewXcord



                                        //remember to update the tfEditor of the timeframe inwhich we made the edit 
                                        //console.log({newCandleBoxWidthCount})
                                        const newSelectedDrawingForEditInfoIdObj = {
                                            drawingType,

                                            datetimeStart : newDrawingStartDatetime,

                                            datetimeEnd : (
                                                drawingType === 'trendline3' ||
                                                drawingType === 'trendline4' ||
                                                drawingType === 'trendline5' 
                                            ) ? newDrawingStartDatetime : newDrawingEndDatetime,

                                            cordYpriceStart : (
                                                drawingType === 'trendline2' 
                                                && isDrawingStartCordSelected
                                            ) ? newCordYpriceEnd : newCordYpriceStart,

                                            cordYpriceEnd : (
                                                drawingType === 'trendline3' ||
                                                drawingType === 'trendline4' ||
                                                drawingType === 'trendline5' 
                                            ) ? newCordYpriceStart : (
                                                drawingType === 'trendline2' 
                                                && isDrawingEndCordSelected
                                            ) ? newCordYpriceStart : newCordYpriceEnd,

                                            candleBoxWidthCount :  newCandleBoxWidthCount,// don't make it absolute when editing in order to catch reversal drawing
                                            candleBoxWidthSize : candleWidthSize,
                                            tfStarter : tfEditor,
                                            tfEditor  : chartInfoObj.tf,
                                            id,

                                            isRectDrawingColored,
                                            isRectSquareDrawingSell,

                                            rectSquareUpperHeightPrice : newRectSquareUpperHeightPrice, 
                                            rectSquareLowerHeightPrice : newRectSquareLowerHeightPrice, 

                                            isDrawingForward,// : newIsDrawingForward,
                                            isTrendlineEndPointy,
                                            
                                            isRectangleTextInput,
                                            text,
                                            isReplayLineDrawing,
                                            isGlobalLockedVertiLine : isEditIdEqualLockedVertiLineId ? true : isGlobalLockedVertiLine,
                                            isReplayGlobalLockedVertiLine
                                            
                                        } 
                                        //techiman

                                        /*console.log({
                                            //drawingType   : newSelectedDrawingForEditInfoIdObj.drawingType,

                                            a : newSelectedDrawingForEditInfoIdObj.datetimeStart,
                                            b   : newSelectedDrawingForEditInfoIdObj.datetimeEnd,

                                            c : newCandleBoxWidthCount,

                                            //cordYpriceStart : newSelectedDrawingForEditInfoIdObj.cordYpriceStart,
                                            //cordYpriceEnd : newSelectedDrawingForEditInfoIdObj.cordYpriceEnd,
                                        })*/

                                        
                                        
                                        //-------------------------------------------------------------------------
                                        //update locked vertical line moved 

                                        if(isEditIdEqualLockedReplayId){
                                            //console.log('hey algo...')

                                            const drawingDataObj = localStorage.getItem("replayDatetimeLineLockedObj" + forexORcrypto)
                                            const isDataExist = drawingDataObj !== null
                                            
                                            if(isDataExist){//must be atleast existing for update 

                                                /*const dataObj = JSON.parse(drawingDataObj)
                                                
                                                if(dataObj[datetimeStart]){
                                                    delete dataObj[datetimeStart]
                                                }*/
                                              
                                                const obj = {}
                                                obj[id] = newSelectedDrawingForEditInfoIdObj
                                                window.localStorage.setItem("replayDatetimeLineLockedObj" + forexORcrypto, JSON.stringify(obj))

                                                //Update All Charts:
                                                setTimeout(()=>{
                                                  setReloadAllChartsUseEffectCounter(Date.now())
                                                }, 10)
                                            }

                                        }
                                        else if(
                                            isEditIdEqualLockedVertiLineId
                                        ){
                                            //console.log('hey algo...')

                                            const drawingDataObj = localStorage.getItem("verticalDatetimeLineLockedObj" + forexORcrypto)
                                            const isDataExist = drawingDataObj !== null
                                            
                                            if(isDataExist){//must be atleast existing for update 

                                                /*const dataObj = JSON.parse(drawingDataObj)
                                                
                                                if(dataObj[datetimeStart]){
                                                    delete dataObj[datetimeStart]
                                                }*/
                                              
                                                const obj = {}
                                                obj[id] = newSelectedDrawingForEditInfoIdObj
                                                window.localStorage.setItem("verticalDatetimeLineLockedObj" + forexORcrypto, JSON.stringify(obj))

                                                //Update All Charts:
                                                setTimeout(()=>{
                                                  setReloadAllChartsUseEffectCounter(Date.now())
                                                }, 10)
                                            }
                                        }
                                        //-------------------------------------------------------------------------
                                        else{
                                            const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                                            const isDataExist = drawingData !== null

                                            //now as well map & store the newObj into localStorage
                                            if(isDataExist){
                                                const data = JSON.parse(drawingData)

                                                let newData = [...data]
                                                
                                                
                                                newData = newData.map((el, elInd)=>{
                                                    if(el.id===id){
                                                        return newSelectedDrawingForEditInfoIdObj
                                                    }
                                                    else{
                                                        return el
                                                    }
                                                })

                                                //alert(newData)
                                                window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))

                                            }
                                            else{
                                                window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify([newSelectedDrawingForEditInfoIdObj]))
                                            }
                                        }
                                        

                                        //map newObj into & to update localStorageArrayRes by id
                                        localStorageResArray = localStorageResArray.map((el, elInd)=>{
                                            if(el.id===id){
                                                return newSelectedDrawingForEditInfoIdObj
                                            }
                                            else{
                                                return el
                                            }
                                        })


                                        /*console.log({printMe : isDrawingEndCordSelected===false && isDrawingStartCordSelected && isAnyChangeInTLposition ? !isDrawingEndCordSelected : isDrawingEndCordSelected,
                                            isAnyChangeInTLposition})*/
                                        //spread & update the isSelectedObj - selectedDrawingForEditInfoIdObj.isSelected
                                        setSelectedDrawingForEditInfoIdObj({
                                            ...selectedDrawingForEditInfoIdObj, 

                                            datetimeStart : newDrawingStartDatetime,

                                            datetimeEnd : (
                                                drawingType === 'trendline3' ||
                                                drawingType === 'trendline4' ||
                                                drawingType === 'trendline5' 
                                            ) ? newDrawingStartDatetime : newDrawingEndDatetime,

                                            cordYpriceStart : (
                                                drawingType === 'trendline2' 
                                                && isDrawingStartCordSelected
                                            ) ? newCordYpriceEnd : newCordYpriceStart,

                                            cordYpriceEnd : (
                                                drawingType === 'trendline3' ||
                                                drawingType === 'trendline4' ||
                                                drawingType === 'trendline5' 
                                            ) ? newCordYpriceStart : (
                                                drawingType === 'trendline2' 
                                                && isDrawingEndCordSelected
                                            ) ? newCordYpriceStart : newCordYpriceEnd
                                        })

                                        
                                        //console.log("drawingOnDragEditCount : ", crossHairMidStartXcordDrawerInfo.drawingOnDragEditCount)
                                        //update drawing tracker vals
                                        setCrossHairMidStartXcordDrawerInfo({
                                            ...crossHairMidStartXcordDrawerInfo,
                                            
                                            xCord2 : allVerticalLinesXcord, 
                                            datetime2 : datetime, 
                                            crossHairStartInfos2   : crossHairOnMoveCordVals,
                                            drawingDatetimeMidId2  : allArrayDataDatetimeIdTracker[datetime],

                                            drawingOnDragEditCount : crossHairMidStartXcordDrawerInfo.drawingOnDragEditCount + 1,//drawingDatetimeIdMovedCount

                                            drawingEditDatetimeStart : datetime, 

                                            isDrawFromStartCount,
                                            isDrawFromEndCount,
                                            isDrawFromMidCount
                                        })

                                    }


                                }

                                
                            }




                        }//techiman
                        else if(//(isDrawingActivated || isDrawingActivated) 

                            (isDrawingActivated
                            && isMouseDown)
                            //strictly mouseDown
                            //&& allVerticalLinesXcord > crossHairMidStartXcordDrawerInfo.xCord//only draw forward in time
                        ){

                            //console.log('victoooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo')

                            let startLineXcord = 0
                            let startLineYcord = 0
                            let endLineXcord   = 0
                            let endLineYcord   = 0
                            let arcXcord       = 0
                            let arcYcord       = 0
                            let defaultRectSquareDrawingCandleWidthCountAuthCount = 0
                            let defaultRectSquareDrawingHeight = 0
                            let rectSquareUpperHeightPrice     = 0
                            let rectSquareLowerHeightPrice     = 0

                            if(trendlineTypeToDraw === 'trendline7'){
                                defaultRectSquareDrawingHeight = canvasChartHeight * defaultRectSquareDrawingAtChartHeightRatio

                                defaultRectSquareDrawingCandleWidthCountAuthCount = (allVerticalLinesXcord + (candleWidthSize * defaultRectSquareDrawingCandleWidthCount)) > canvasChartWidth ? 
                                    Math.floor((canvasChartWidth - allVerticalLinesXcord) / candleWidthSize) : 
                                    defaultRectSquareDrawingCandleWidthCount
                            }


                            if(crossHairMidStartXcordDrawerInfo.drawingOnFirstDragEditCount === 0){
                                //console.log('is one click only to be calc')
                                if(
                                    trendlineTypeToDraw === 'trendline1' ||
                                    trendlineTypeToDraw === 'trendline8' ||
                                    trendlineTypeToDraw === 'trendline2' ||
                                    trendlineTypeToDraw === 'trendline6'
                                ){
                                    startLineXcord = crossHairMidStartXcordDrawerInfo.xCord
                                    startLineYcord = canvasChartHeight - trendlineCordStart.y

                                    endLineXcord   = allVerticalLinesXcord
                                    endLineYcord   = trendlineTypeToDraw === 'trendline2' ? canvasChartHeight - trendlineCordStart.y : canvasChartHeight - trendlineCordEnd.y

                                    arcXcord       = crossHairMidStartXcordDrawerInfo.xCord
                                    arcYcord       = canvasChartHeight - trendlineCordStart.y
                                }
                                else if(
                                    trendlineTypeToDraw === 'trendline3' ||
                                    trendlineTypeToDraw === 'trendline4' ||
                                    trendlineTypeToDraw === 'trendline5' ||
                                    trendlineTypeToDraw === 'trendline7'
                                ){
                                    

                                    //console.log('wenchi---oooooooooooooooooooooooooooo')
                                    startLineXcord = trendlineTypeToDraw === 'trendline4' ? 0 : allVerticalLinesXcord//crossHairMidStartXcordDrawerInfo.xCord
                                    startLineYcord = trendlineTypeToDraw === 'trendline5' ? 0 : canvasChartHeight - trendlineCordStart.y

                                    endLineXcord   = trendlineTypeToDraw === 'trendline7' ? allVerticalLinesXcord + (candleWidthSize * defaultRectSquareDrawingCandleWidthCountAuthCount) : 
                                    (trendlineTypeToDraw === 'trendline3' || trendlineTypeToDraw === 'trendline4') ? canvasChartWidth : allVerticalLinesXcord
                                    endLineYcord   = trendlineTypeToDraw === 'trendline5' ? canvasChartHeight : canvasChartHeight - trendlineCordStart.y

                                    arcXcord       = trendlineTypeToDraw === 'trendline4' ? 0 : allVerticalLinesXcord//crossHairMidStartXcordDrawerInfo.xCord
                                    arcYcord       = trendlineTypeToDraw === 'trendline5' ? 0 : canvasChartHeight - trendlineCordStart.y
                                }
                            }
                            else if(crossHairMidStartXcordDrawerInfo.drawingOnFirstDragEditCount > 0){

                                if(
                                    trendlineTypeToDraw === 'trendline1' ||
                                    trendlineTypeToDraw === 'trendline8' ||
                                    trendlineTypeToDraw === 'trendline2' ||
                                    trendlineTypeToDraw === 'trendline6'
                                ){
                                    startLineXcord = crossHairMidStartXcordDrawerInfo.xCord
                                    startLineYcord = canvasChartHeight - trendlineCordStart.y

                                    endLineXcord   = allVerticalLinesXcord
                                    endLineYcord   = trendlineTypeToDraw === 'trendline2' ? canvasChartHeight - trendlineCordStart.y : canvasChartHeight - trendlineCordEnd.y

                                    arcXcord       = crossHairMidStartXcordDrawerInfo.xCord
                                    arcYcord       = canvasChartHeight - trendlineCordStart.y
                                }
                                else if(
                                    trendlineTypeToDraw === 'trendline3' ||
                                    trendlineTypeToDraw === 'trendline4' ||
                                    trendlineTypeToDraw === 'trendline5' ||
                                    trendlineTypeToDraw === 'trendline7'
                                ){
                                    //console.log('badu---oooooooooooooooooooooooooooo')
                                    startLineXcord = trendlineTypeToDraw === 'trendline4' ? 0 : allVerticalLinesXcord
                                    startLineYcord = trendlineTypeToDraw === 'trendline5' ? 0 : canvasChartHeight - trendlineCordEnd.y

                                    endLineXcord   = trendlineTypeToDraw === 'trendline7' ? allVerticalLinesXcord + (candleWidthSize * defaultRectSquareDrawingCandleWidthCountAuthCount) :
                                    (trendlineTypeToDraw === 'trendline3' || trendlineTypeToDraw === 'trendline4') ? canvasChartWidth : allVerticalLinesXcord
                                    endLineYcord   = trendlineTypeToDraw === 'trendline5' ? canvasChartHeight : canvasChartHeight - trendlineCordEnd.y

                                    arcXcord       = trendlineTypeToDraw === 'trendline4' ? 0 : allVerticalLinesXcord
                                    arcYcord       = trendlineTypeToDraw === 'trendline5' ? 0 : canvasChartHeight - trendlineCordEnd.y
                                }

                            }


                            

                            if(trendlineTypeToDraw === 'trendline8'){

                                const timestampStart = datetimeToTimestamp(crossHairMidStartXcordDrawerInfo.datetime);
                                const timestampEnd   = datetimeToTimestamp(datetime);
                                const isDrawingForward = timestampEnd >= timestampStart

                                const isXcordSame = crossHairMidStartXcordDrawerInfo.xCord === allVerticalLinesXcord
                                const YcordDiff   = Math.abs(endLineYcord - startLineYcord) 

                                let hypotenuse = 0
                                if(isXcordSame===false){
                                    const opposite = Math.abs(endLineYcord - startLineYcord)
                                    const adjacent = Math.abs(endLineXcord - startLineXcord)
                                    const angle      = pythagorasCalculator({calcType : 'tan', varToCalc : 'aa', opposite, adjacent})
                                    hypotenuse = pythagorasCalculator({calcType : 'sin', varToCalc : 'h', opposite, angle})
                                }

                                const radian = Math.PI / 180;

                                context.beginPath();
                                context.strokeStyle = "blue";
                                context.lineWidth = 1;
                                context.fillStyle = "rgba(255, 165, 0, 0.3)";
                                context.arc(
                                    //isDrawingForward ? startLineXcord : endLineXcord, 
                                    startLineXcord,
                                    startLineYcord , 
                                    isXcordSame ? YcordDiff : hypotenuse,//10, 
                                    0 * radian, 360 * radian, false);
                                context.stroke();
                                context.fill();
                                //*/

                            }
                            else if(trendlineTypeToDraw === 'trendline7'){

                                if(isRectangleSquareDrawingSell){

                                    //rect(x, y, w, h)
                                    //rect(x1, y1, w1, h1)
                                    //rect(x2, y2, w2, h2)
                                    const x1 = startLineXcord
                                    const y1 = startLineYcord - defaultRectSquareDrawingHeight
                                    const w1 = Math.abs(endLineXcord - startLineXcord)
                                    const h1 = defaultRectSquareDrawingHeight

                                    const x2 = startLineXcord
                                    const y2 = startLineYcord
                                    const w2 = Math.abs(endLineXcord - startLineXcord)
                                    const h2 = defaultRectSquareDrawingHeight

                                    context.fillStyle = "rgba(255, 0, 0, 0.4)";
                                    context.fillRect(
                                        x1, //x1
                                        y1, //y1
                                        w1, //w1
                                        h1//h1
                                    );

                                    context.fillStyle = "rgba(0, 255, 0, 0.4)";
                                    context.fillRect(
                                        x2, //x2
                                        y2 , //y2
                                        w2, //w2
                                        h2 //h2
                                    );

                                    //context.setLineDash([5, 5]);
                                    // Draw horizontal Price Line
                                    context.beginPath(); // reset the context state
                                    context.strokeStyle = "blue"; // color of the line
                                    context.lineWidth = 0.5; // thickness of the line
                                    context.moveTo(startLineXcord, startLineYcord); // moveTo(x,y) -> starting point of the line
                                    context.lineTo(endLineXcord, endLineYcord); // line(x,y) -> end point of the line
                                    context.stroke(); // draws the line
                                
                                    //context.setLineDash([]);
                                }
                                else{

                                    //rect(x, y, w, h)
                                    //rect(x1, y1, w1, h1)
                                    //rect(x2, y2, w2, h2)
                                    const x1 = startLineXcord
                                    const y1 = startLineYcord - defaultRectSquareDrawingHeight
                                    const w1 = Math.abs(endLineXcord - startLineXcord)
                                    const h1 = defaultRectSquareDrawingHeight

                                    const x2 = startLineXcord
                                    const y2 = startLineYcord
                                    const w2 = Math.abs(endLineXcord - startLineXcord)
                                    const h2 = defaultRectSquareDrawingHeight

                                    context.fillStyle = "rgba(0, 255, 0, 0.4)";
                                    context.fillRect(
                                        x1, //x1
                                        y1, //y1
                                        w1, //w1
                                        h1//h1
                                    );

                                    context.fillStyle = "rgba(255, 0, 0, 0.4)";
                                    context.fillRect(
                                        x2, //x2
                                        y2 , //y2
                                        w2, //w2
                                        h2 //h2
                                    );

                                    //context.setLineDash([5, 5]);
                                    // Draw horizontal Price Line
                                    context.beginPath(); // reset the context state
                                    context.strokeStyle = "blue"; // color of the line
                                    context.lineWidth = 0.5; // thickness of the line
                                    context.moveTo(startLineXcord, startLineYcord); // moveTo(x,y) -> starting point of the line
                                    context.lineTo(endLineXcord, endLineYcord); // line(x,y) -> end point of the line
                                    context.stroke(); // draws the line

                                    //context.setLineDash([]);
                                }
                            }
                            else if(trendlineTypeToDraw === 'trendline6'){

                                if(isRectangleDrawingColored){
                                    context.strokeStyle = "blue";
                                    context.lineWidth = 0.5;
                                    //rect(x,y,w,h)
                                    context.fillStyle = "rgba(235, 212, 239, 0.5)";
                                    context.rect(
                                        startLineXcord, //x
                                        startLineYcord, //y
                                        (endLineXcord - startLineXcord), //w
                                        (endLineYcord - startLineYcord)  //h
                                    );
                                    context.stroke();
                                    context.fill();

                                }
                                else{
                                    context.lineWidth = 1;
                                    context.strokeStyle = "blue";
                                    //rect(x,y,w,h)
                                    context.strokeRect(
                                        startLineXcord, //x
                                        startLineYcord, //y
                                        (endLineXcord - startLineXcord), //w
                                        (endLineYcord - startLineYcord)  //h
                                    );

                                }
                            }
                            else{
                                //context.setLineDash([5, 5]);
                                // Draw horizontal Price Line
                                context.beginPath(); // reset the context state
                                /*context.strokeStyle = "blue"; // color of the line
                                context.lineWidth = 0.8; // thickness of the line*/
                                context.strokeStyle = trendlineTypeToDraw === 'trendline5' && isReplayLineDrawing ? 'green' : "blue"; // color of the line
                                context.lineWidth = trendlineTypeToDraw === 'trendline5' && isReplayLineDrawing ?  2 : 0.8; // thickness of the line
                                context.moveTo(startLineXcord, startLineYcord); // moveTo(x,y) -> starting point of the line
                                context.lineTo(endLineXcord, endLineYcord); // line(x,y) -> end point of the line
                                context.stroke(); // draws the line


                                
                                if(
                                    trendlineTypeToDraw==='trendline1' &&
                                    isTrendlineEndPointy
                                ){

                                    //isTrendlineEndPointy
                                    //isDrawingForward
                                    //const [pointyTrendlineDefaultInfo, setPointyTrendlineDefaultInfo] = useState({defaultPointerLength:20, defaultPointerAngle:45})
                                    const {
                                        defaultPointerLength
                                    } = pointyTrendlineDefaultInfo
                                   

 
                                    //-------------------------------------------------------------------------------------------------------
                                    const timestampStart = datetimeToTimestamp(crossHairMidStartXcordDrawerInfo.datetime);
                                    const timestampEnd   = datetimeToTimestamp(datetime);
                                    const isEndTimeStampGreater = timestampEnd >= timestampStart
                                    /*console.log({
                                        timestampStart,
                                        timestampEnd,
                                        isEndTimeStampGreater
                                    })*/

                                    /*const drawingDatetimeStart = isEndTimeStampGreater ? crossHairMidStartXcordDrawerInfo.datetime : datetime
                                    const drawingDatetimeEnd   = isEndTimeStampGreater ? datetime : crossHairMidStartXcordDrawerInfo.datetime

                                    const drawingPriceStart = isEndTimeStampGreater ? cordYpriceStart : cordYpriceEnd
                                    const drawingPriceEnd   = isEndTimeStampGreater ? cordYpriceEnd : cordYpriceStart*/
                                    //--------------------------------------------------------------------------------------------------------

                                    //(obj.pointerYcordPriceEnd <= obj.nonPointerYcordPriceStart)

                                    const isDrawingForward = isEndTimeStampGreater

                                    /*const pointerXcordEnd         = isDrawingForward ? endLineXcord : startLineXcord
                                    const nonPointerXcordStart      = isDrawingForward ? startLineXcord : endLineXcord
                                    
                                    const nonPointerYcordPriceStart = isDrawingForward ? startLineYcord : endLineYcord  
                                    const pointerYcordPriceEnd      = isDrawingForward ? endLineYcord : startLineYcord   */

                                    const nonPointerXcordStart      = startLineXcord 
                                    const pointerXcordEnd           = endLineXcord

                                    const nonPointerYcordPriceStart = startLineYcord 
                                    const pointerYcordPriceEnd      = endLineYcord 

                                    //const is

                                    const opposite = Math.abs(endLineYcord - startLineYcord)
                                    const adjacent = Math.abs(endLineXcord - startLineXcord)
                                    const angle      = pythagorasCalculator({calcType : 'tan', varToCalc : 'aa', opposite, adjacent})
                                    const hypotenuse = pythagorasCalculator({calcType : 'sin', varToCalc : 'h', opposite, angle})

                                    //const newOpposite = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse, angle})
                                    //const newAdjacent = pythagorasCalculator({calcType : 'cos', varToCalc : 'a', hypotenuse, angle})

                                    const {
                                        pointerRightAngle45degreeDefaultXcordLeft,
                                        pointerRightAngle45degreeDefaultYcordLeft,
                                        pointerRightAngle45degreeDefaultXcordRight,
                                        pointerRightAngle45degreeDefaultYcordRight 
                                    } = calcLinePointerXYcord({
                                        isDrawingForward, 
                                        pointerXcordEnd, 
                                        pointerYcordPriceEnd, 
                                        nonPointerYcordPriceStart, 
                                        nonPointerXcordStart, 
                                        angle, 
                                        defaultPointerLength
                                    })

                                    


                                    // Draw Pointy Lines Line
                                    context.beginPath(); // reset the context state
                                    context.strokeStyle = "blue"; // color of the line
                                    context.lineWidth = 0.8; // thickness of the line
                                    context.moveTo(pointerXcordEnd, pointerYcordPriceEnd); // moveTo(x,y) -> starting point of the line
                                    context.lineTo(pointerRightAngle45degreeDefaultXcordLeft, pointerRightAngle45degreeDefaultYcordLeft); // line(x,y) -> end point of the line
                                    context.stroke(); // draws the line


                                    context.beginPath(); // reset the context state
                                    context.strokeStyle = "blue"; // color of the line
                                    context.lineWidth = 0.8; // thickness of the line
                                    context.moveTo(pointerXcordEnd, pointerYcordPriceEnd); // moveTo(x,y) -> starting point of the line
                                    context.lineTo(pointerRightAngle45degreeDefaultXcordRight, pointerRightAngle45degreeDefaultYcordRight); // line(x,y) -> end point of the line
                                    context.stroke(); // draws the line

                                    
                                }
                            }

                         

                            if(
                                (trendlineTypeToDraw !== 'trendline4' &&
                                trendlineTypeToDraw !== 'trendline5')
                            ){
                                const radian = Math.PI / 180;

                                context.beginPath();
                                context.strokeStyle = "blue";
                                context.lineWidth = 1;
                                context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                context.arc(
                                    arcXcord, //x
                                    arcYcord, //y
                                    drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false
                                );
                                context.stroke();
                                context.fill();
                            }
                        
                            //context.setLineDash([]);

                            /*console.log({
                                drawingType ,//: 'trendline1',
                                datetimeStart,//   : '2023-08-22 08:00:00',
                                datetimeEnd,//     : '2023-08-29 20:00:00',//where to start backCountDrawing from
                                cordYpriceStart,// : 1.09188,
                                cordYpriceEnd,//   : 1.07877,
                                candleBoxWidthCount,// : 32,
                                candleBoxWidthSize,//  : 10.9,
                                tfStarter,//       : 'h4',
                                tfEditor,//        : 'h4',
                                id,// : Date.now()
                            })*/

                            //price reversal from PX //drawingEditCursorOutlierLimit
                            let priceStarter = trendlineCordStart.y
                            let priceEnder   = trendlineCordEnd.y

                            
                            const isStartAndEndDatetimeSame = startLineXcord===endLineXcord
                            const YcordDiff = trendlineCordEnd.y - trendlineCordStart.y
                            const isYcordDiffPositiveVal = YcordDiff > 0

                            if((trendlineTypeToDraw === 'trendline1' || trendlineTypeToDraw === 'trendline8') && 
                            isStartAndEndDatetimeSame && Math.abs(YcordDiff) < candleWidthSize*1
                            ){
                                priceEnder   = trendlineCordEnd.y + (isYcordDiffPositiveVal ? candleWidthSize*2 : -(candleWidthSize*2))
                            }
                            /*console.log({
                                isStartAndEndDatetimeSame,
                                YcordDiff,
                                isYcordDiffPositiveVal
                            })*/


                            const cordYpriceStart = ((priceStarter + YchartShiftPX + chartYvelocity) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;
                            const cordYpriceEnd   = ((priceEnder   + YchartShiftPX + chartYvelocity) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;
                            //console.log(cordYpriceStart, " : ", cordYpriceEnd)

                            const timestampStart = datetimeToTimestamp(crossHairMidStartXcordDrawerInfo.datetime);
                            const timestampEnd   = datetimeToTimestamp(datetime);
                            const isEndTimeStampGreater = timestampEnd >= timestampStart
                            /*console.log({
                                timestampStart,
                                timestampEnd,
                                isEndTimeStampGreater
                            })*/

                            let drawingDatetimeStart = isEndTimeStampGreater ? crossHairMidStartXcordDrawerInfo.datetime : datetime
                            let drawingDatetimeEnd   = isEndTimeStampGreater ? datetime : crossHairMidStartXcordDrawerInfo.datetime

                            let drawingPriceStart = isEndTimeStampGreater ? cordYpriceStart : cordYpriceEnd
                            let drawingPriceEnd   = isEndTimeStampGreater ? cordYpriceEnd : cordYpriceStart


                            if(
                                trendlineTypeToDraw    === 'trendline4'
                                || trendlineTypeToDraw === 'trendline5'
                                || trendlineTypeToDraw === 'trendline3'
                            ){
                                drawingDatetimeStart = datetime
                                drawingDatetimeEnd   = datetime

                                drawingPriceStart    = cordYpriceEnd
                                drawingPriceEnd      = cordYpriceEnd
                            }



                            let trendline7DatetimeEnd = datetime
                            if(trendlineTypeToDraw === 'trendline7'){
                                const datetimeResArray = getNextOrPrevDatetimeByTF(
                                    datetime,//datetime, 
                                    chartInfoObj.tf, 
                                    "next",//nextORprevDatetimesToGet, 
                                    defaultRectSquareDrawingCandleWidthCountAuthCount,//nextORprevDatetimeToGetCount, 
                                    {},//{...dbData[0], open: dbData[0].close, high:dbData[0].close, low:dbData[0].close},//prevDatetimeToGetPriceDataObj, 
                                    {},//{...dbData[dbData.length-1], open: dbData[dbData.length-1].close, high:dbData[dbData.length-1].close, low:dbData[dbData.length-1].close}//nextDatetimeToGetPriceDataObj
                                )

                                trendline7DatetimeEnd = datetimeResArray[datetimeResArray.length - 1].datetime

                                const rectSquareUpperHeightPriceYcord = priceEnder + defaultRectSquareDrawingHeight  
                                const rectSquareLowerHeightPriceYcord = priceEnder - defaultRectSquareDrawingHeight

                                rectSquareUpperHeightPrice = ((rectSquareUpperHeightPriceYcord + YchartShiftPX + chartYvelocity) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;
                                rectSquareLowerHeightPrice = ((rectSquareLowerHeightPriceYcord + YchartShiftPX + chartYvelocity) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;

                            }



                            const trendlineInfoObj = {//store this in state until mouseUp for localStorage
                                drawingType         : trendlineTypeToDraw,//'trendline1',
                                datetimeStart       : (trendlineTypeToDraw === 'trendline3' || trendlineTypeToDraw === 'trendline7') ? datetime : drawingDatetimeStart,//'2023-08-22 08:00:00',//drawingDatetimeStart
                                datetimeEnd         : trendlineTypeToDraw === 'trendline7' ? trendline7DatetimeEnd : (
                                    //trendlineTypeToDraw === 'trendline3' ||
                                    //trendlineTypeToDraw === 'trendline4' ||
                                    trendlineTypeToDraw === 'trendline5'
                                ) ? drawingDatetimeStart : drawingDatetimeEnd, //'2023-08-29 20:00:00',//drawingDatetimeEnd
                                cordYpriceStart     : (trendlineTypeToDraw === 'trendline3' || trendlineTypeToDraw === 'trendline7') ? cordYpriceEnd : drawingPriceStart,//1.09188,
                                cordYpriceEnd       : (trendlineTypeToDraw === 'trendline3' || trendlineTypeToDraw === 'trendline7') ? cordYpriceEnd : 
                                trendlineTypeToDraw === 'trendline1' || trendlineTypeToDraw === 'trendline8' || trendlineTypeToDraw === 'trendline6' ? drawingPriceEnd : drawingPriceStart,//1.07877,
                                candleBoxWidthCount : trendlineTypeToDraw === 'trendline7' ? defaultRectSquareDrawingCandleWidthCountAuthCount :
                                Math.round(Math.abs((allVerticalLinesXcord - crossHairMidStartXcordDrawerInfo.xCord) / candleWidthSize)),//round important
                                candleBoxWidthSize  : candleWidthSize,
                                tfStarter           : chartInfoObj.tf,
                                tfEditor            : chartInfoObj.tf,
                                id                  : Date.now(),

                                isRectDrawingColored : isRectangleDrawingColored,//eSquareDrawingSell
                                isRectSquareDrawingSell : isRectangleSquareDrawingSell,

                                rectSquareUpperHeightPrice, 
                                rectSquareLowerHeightPrice, 

                                isDrawingForward : isEndTimeStampGreater,

                                isTrendlineEndPointy : trendlineTypeToDraw === 'trendline1' ? isTrendlineEndPointy : false,

                                //isRectangleTextInput
                                isRectangleTextInput : trendlineTypeToDraw === 'trendline6' && isRectangleTextInput ? true : false,
                                text : trendlineTypeToDraw === 'trendline6' && isRectangleTextInput ? "Text" : "",

                                //isReplayLineDrawing
                                isReplayLineDrawing : trendlineTypeToDraw === 'trendline5' && isReplayLineDrawing ? true : false,

                                isGlobalLockedVertiLine : false,
                                isReplayGlobalLockedVertiLine : false
                            }


                            if(trendlineTypeToDraw === 'trendline5' && isReplayLineDrawing){//delete any existing replay line before storing a new1

                                //store tracked drawing data in the localstorage
                                const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                                const isDataExist = drawingData !== null

                                if(isDataExist){
                                    const data = JSON.parse(drawingData)

                                    let newData = [...data]

                                    //if(TLtypeDrawnByOneClick === 'trendline5' && isReplayLineDrawing){//delete any existing replay line before storing a new1
                                        newData = newData.filter((el, elIndx)=>{
                                            return(el.drawingType!=='trendline5' || (el.drawingType==='trendline5' && el.isReplayLineDrawing===false) )
                                        })

                                        window.localStorage.setItem("replayDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))
                                    //}
                                    
                                    //newData = [...newData, oneClickTrendlineInfoObj]

                                    window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))


                                }

                            }



                            setCrossHairMidStartXcordDrawerInfo({
                                ...crossHairMidStartXcordDrawerInfo,
                                drawingOnFirstDragEditCount : crossHairMidStartXcordDrawerInfo.drawingOnFirstDragEditCount + 1
                            })

                            setOnDrawingMoveInfoTrackerObj(trendlineInfoObj)

                            /*setOnDrawingMoveInfoTrackerObj(
                                {
                                    ...onDrawingMoveInfoTrackerObj,
                                    ...trendlineInfoObj
                                }
                            )*/

                            

                            /*console.log({//abrant
                                candleWidthSize,
                                crossHairMidStartXcordDrawerInfo,
                                allVerticalLinesXcord,
                                res : Math.round(Math.abs((allVerticalLinesXcord - crossHairMidStartXcordDrawerInfo.xCord) / candleWidthSize))
                            })*/

                            

                            //store in localStorage
                        }
                        /*else{//editing a drawing
                            //THE BEST OF ALL CHART INFO TRACKER
                            setCrossHairMidStartXcordDrawerInfo({
                                ...crossHairMidStartXcordDrawerInfo,
                                xCordEnd : allVerticalLinesXcord
                            })
                            //console.log("datetime : ", datetime, " : ", allVerticalLinesXcord)
                        }*/
                    }//setIsTouchScreenDevice
                    else/* if(isTouchScreenDevice===false)*/{//only track the mid Start Xcord when not mouse for drawing
                        //THE BEST OF ALL CHART INFO TRACKER
                        //console.log('hey-------------------------------------------------')
                        setMouseDownCount(0)

                        setCrossHairMidStartXcordDrawerInfo({
                            xCord : allVerticalLinesXcord, 
                            xCord2 : allVerticalLinesXcord, 
                            datetime, 
                            datetime2 : datetime, 
                            totalCandleCountOnPage : canvasChartWidth / candleWidthSize,
                            candleWidthSize,
                            isChartStartPageOutOfView : (
                                isYmagnificationActivated===false && 
                                (data.length - dataCandleStartEndIndex.dataCandleEndIndex) > (canvasChartWidth / candleWidthSize)
                            ),
                            crossHairStartInfos : crossHairOnMoveCordVals,
                            crossHairStartInfos2 : crossHairOnMoveCordVals,
                            drawingDatetimeMidId     : allArrayDataDatetimeIdTracker[datetime],
                            drawingDatetimeMidId2     : allArrayDataDatetimeIdTracker[datetime],
                            drawingOnDragEditCount : 0,
                            drawingEditDatetimeStart : datetime,
                            drawingDatetimeIdMovedCount : 0,
                            drawingOnFirstDragEditCount : 0
                        })
                        //console.log("datetime : ", datetime, " : ", allVerticalLinesXcord)
                    }

                     
                }


               



                

                if(
                    ind===0//(isYmagnificationActivated ? ind : dataCandleStartEndIndex.dataCandleStartIndex + ind) === data.length-1 - dataShiftCount
                ){

                    if(isChartOnREPLAYmode===false){
                      // Set line dash pattern (5 pixels on, 5 pixels off)
                      //context.setLineDash([5, 5]);
                      // Draw horizontal Price Line
                      context.beginPath(); // reset the context state
                      context.strokeStyle = "black"; // color of the line
                      context.lineWidth = 0.5; // thickness of the line
                      context.moveTo(0, currentClosePriceYcord); // moveTo(x,y) -> starting point of the line
                      context.lineTo(canvasChartWidth - priceLabelMarginSize, currentClosePriceYcord); // line(x,y) -> end point of the line
                      context.stroke(); // draws the line
                      // Reset line dash (draw solid line)
                      //context.setLineDash([]);
                    }

                    if(isMouseDown && isPipCheckerActivated){
                        context.setLineDash([5, 5]);
                        // Draw horizontal Price Line
                        context.beginPath(); // reset the context state
                        context.strokeStyle = "rgba(0, 0, 255, 1)"; // color of the line
                        context.lineWidth = 0.5; // thickness of the line
                        context.moveTo(0, canvasChartHeight - pipCheckerCordEnd.y); // moveTo(x,y) -> starting point of the line
                        context.lineTo(canvasChartWidth - priceLabelMarginSize, canvasChartHeight - pipCheckerCordEnd.y); // line(x,y) -> end point of the line
                        context.stroke(); // draws the line
                        // Reset line dash (draw solid line)
                        context.setLineDash([]);
                    }

                     //Draw horizontal crossHairPriceText & centered
                     //const crossHairYprice = ((currentOpenPriceYcord - YchartShiftPX - chartYvelocity) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;

                     

                    // Draw vertical Price lable line
                    context.beginPath(); // reset the context state
                    context.strokeStyle = "rgba(0, 0, 0, 0.1)"; // color of the line
                    context.lineWidth = 0.5; // thickness of the line
                    context.moveTo(canvasChartWidth - priceLabelMarginSize, 0); // moveTo(x,y) -> starting point of the line
                    context.lineTo(canvasChartWidth - priceLabelMarginSize, canvasChartHeight); // line(x,y) -> end point of the line
                    context.stroke(); // draws the line

                    //Code & Draw Price text at right margin
                }

                if(//make it last index
                    ind===0
                ){
                    //Draw vertical Price lable Rectangle blur cover
                    //context.fillRect(xCoordinate(Xi), yCoordinate(Yi), width(candleWidth), height(candleHeight))
                    context.fillStyle = "rgba(0, 0, 0, 0.06)";
                    //context.fillRect(drawCandleAtXcord, drawCandleAtYcord, candleWidth, candleHeight)//chart inverted
                    context.fillRect(
                        canvasChartWidth - priceLabelMarginSize, 
                        0 ,
                        priceLabelMarginSize, 
                        canvasChartHeight
                    )//chart upright


                    // Draw horizontal datetime lable line
                    context.beginPath(); // reset the context state
                    context.strokeStyle = "rgba(0, 0, 0, 1)"; // color of the line
                    context.lineWidth = 0.5; // thickness of the line
                    context.moveTo(0, canvasChartHeight - datetimeLabelMarginSize); // moveTo(x,y) -> starting point of the line
                    context.lineTo(canvasChartWidth - priceLabelMarginSize, canvasChartHeight - datetimeLabelMarginSize); // line(x,y) -> end point of the line
                    context.stroke(); // draws the line


                    //Draw csm.com Text
                    context.fillStyle = "rgba(0, 0, 0, 0.5)";  
                     context.font = "12px Verdana";
                     // context.fillText(text, xCoord, yCoord, maxWidth);
                     context.textAlign = "center";
                     //context.textBaseline = "middle";
                     context.fillText("www.currencyStrengthZone.com", 
                     ((canvasChartWidth - priceLabelMarginSize)*0.5) + 14,
                     0+ 13
                     );



                     if(isCORRchart){
                        //Draw horizontal baseCurrStrengthValText & centered
                        //text with 2 attrs, bottom & centered
                        context.fillStyle = /*isSTOCHchart ? 'black' :*/ "green";  
                        context.font = "11px Verdana";
                        // context.fillText(text, xCoord, yCoord, maxWidth);
                        context.textAlign = "start";
                        //context.textBaseline = "center";
                        context.fillText(isCORRchart ? `100=(-1) , 0=(+1)` : ``, 
                        5 + (false ? 700*0.5 : 1),
                        15*1.5
                        );
                    }
                }







                if(//draw candle
                    isCandleChart 
                    &&
                    (
                        (
                            replayLineDrawingArray.length === 0 
                            ||
                            (//to prevent ltf page from been all blank/empty
                                isChartOnREPLAYmode && 
                                (replayLineDrawingArray[replayLineDrawingArray.length-1]).isReplayLineDrawing &&
                                datetimeToTimestamp(data[0].datetime) > datetimeToTimestamp((replayLineDrawingArray[replayLineDrawingArray.length-1]).datetimeStart)
                            )
                        )
                        ||
                        (//original code to hide all candles for replay
                        //(replayLineDrawingArray[replayLineDrawingArray.length-1]).datetimeStart
                        //refineAdjustPrevDatetimeStart({currentTF:tf, prevDatetimeStart:datetime})
                        //refineAdjustPrevDatetimeStart({currentTF:tf, prevDatetimeStart:(replayLineDrawingArray[replayLineDrawingArray.length-1]).datetimeStart})
                            isChartOnREPLAYmode && 
                            (replayLineDrawingArray[replayLineDrawingArray.length-1]).isReplayLineDrawing &&
                            datetimeToTimestamp(datetime) < datetimeToTimestamp((replayLineDrawingArray[replayLineDrawingArray.length-1]).datetimeStart)
                        )
                    )
                ){



                    // Draw candle wick line
                    context.beginPath(); // reset the context state
                    context.strokeStyle = 
                    (close >= open ? "green" : "red")
                    context.lineWidth = 0.8; // thickness of the line
                    context.moveTo(allVerticalLinesXcord, candleWickHighYcord); // moveTo(x,y) -> starting point of the line
                    context.lineTo(allVerticalLinesXcord, candleWickLowYcord); // line(x,y) -> end point of the line
                    context.stroke(); // draws the line*/


                    //context.fillRect(xCoordinate(Xi), yCoordinate(Yi), width(candleWidth), height(candleHeight))
                    context.fillStyle = 
                    (close >= open ? "green" : "red")
                    //context.fillRect(drawCandleAtXcord, drawCandleAtYcord, candleWidth, candleHeight)//chart inverted
                    context.fillRect(
                        drawCandleAtXcord, 
                        drawCandleAtYcord ,
                        candleWidth, 
                        candleHeight
                    )//chart upright

                    //get wick data - to prevent lockedVertiLine from being drawn through
                    allCANDLESdatetimeDATAinfoObj[datetime] = {candleWickHighYcord, candleWickLowYcord}
                }




                



                //kwasi
                //datetimeLabelMarginSize
                //draw datetime text of various intervals based on the tf
                //eg: h4 = interval of 3, & returning the day of date at times of "00:00:00"

                if(true){

                    //timeZoneDatetimeConverter - NOTE: modify datetime TIMEzone only for view on screen purposes - but not modify/update any state/variable
                    const t_zone_TIME_convert = timeZoneDatetimeConverter(
                        datetime,//datetime
                        localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                        zero_gmt_deviation
                    )

                    if(
                        //ind === 0 &&
                        
                        (//year
                            ["mn"].includes(chartInfoObj.tf) &&
                            t_zone_TIME_convert.split(" ")[0].split('-')[1]==="01" &&//month
                            (
                                t_zone_TIME_convert.split(" ")[0].split('-')[2]==="01" || //day
                                (t_zone_TIME_convert.split(" ")[0].split('-')[2]==="02" && isMonday(t_zone_TIME_convert)) || 
                                (t_zone_TIME_convert.split(" ")[0].split('-')[2]==="03" && isMonday(t_zone_TIME_convert)) 
                            ) &&
                            t_zone_TIME_convert.split(" ")[1]==="00:00:00" 
                        )
                        || 
                        (//year
                            ["w1"].includes(chartInfoObj.tf) &&
                            t_zone_TIME_convert.split(" ")[0].split('-')[1]==="01" &&//month
                            (
                                t_zone_TIME_convert.split(" ")[0].split('-')[2]==="01" || //day
                                (t_zone_TIME_convert.split(" ")[0].split('-')[2]==="02" && isMonday(t_zone_TIME_convert)) || 
                                (t_zone_TIME_convert.split(" ")[0].split('-')[2]==="03" && isMonday(t_zone_TIME_convert)) || 
                                (isWeekend(t_zone_TIME_convert) && Number(t_zone_TIME_convert.split(" ")[0].split('-')[2]) <= 7)
                            ) &&
                            t_zone_TIME_convert.split(" ")[1]==="00:00:00" 
                        )
                        || 
                        (//month
                            ["d1"].includes(chartInfoObj.tf) &&
                            //(t_zone_TIME_convert.split(" ")[0].split('-')[2]==="01") &&
                            (
                                t_zone_TIME_convert.split(" ")[0].split('-')[2]==="01" || 
                                (t_zone_TIME_convert.split(" ")[0].split('-')[2]==="02" && isMonday(t_zone_TIME_convert)) || 
                                (t_zone_TIME_convert.split(" ")[0].split('-')[2]==="03" && isMonday(t_zone_TIME_convert))
                            ) &&
                            t_zone_TIME_convert.split(" ")[1]==="00:00:00" 
                        )
                        || 
                        (//day
                            ["h4", "h1", "m30", "m15"].includes(chartInfoObj.tf) &&
                            t_zone_TIME_convert.split(" ")[1]==="00:00:00" 
                        )
                        || 
                        (//hour
                            ["m1", "m5"].includes(chartInfoObj.tf) &&
                            t_zone_TIME_convert.split(" ")[1].slice(3)==="00:00"
                        )
                    ){

                        const date_time_str = t_zone_TIME_convert//'2024-12-20 01:21:52'

                        const date_time_str_split = date_time_str.split(' ')
                        const date                = date_time_str_split[0]
                        const timee                = date_time_str_split[1]
                        const date_str_split      = date.split('-')
                        const time_str_split      = timee.split(':')
                        const year                = date_str_split[0]
                        const month               = date_str_split[1]
                        const day                 = date_str_split[2]
                        const hour                = time_str_split[0]
                        //const minute              = time_str_split[1]
                        //const second              = time_str_split[2]


                        const obj = {
                            mn : year,
                            w1 : year,
                            d1 : month,
                            h4 : day,
                            h1 : day,
                            m30: day,
                            m15: day,
                            m5 : hour,
                            m1 : hour
                        }
                        //console.log(obj)

                        //Draw vertical datetimeText & centered - at datetime Margin below
                        //text with 2 attrs, bottom & centered
                        context.fillStyle = "black";  
                        context.font = "11px Verdana";
                        // context.fillText(text, xCoord, yCoord, maxWidth);
                        context.textAlign = "center";
                        //context.textBaseline = "center";
                        context.fillText(
                        //t_zone_TIME_convert.split(" ")[0].split("-")[2], 
                        obj[chartInfoObj.tf],
                        allVerticalLinesXcord,
                        canvasChartHeight - 5//- datetimeLabelMarginSize
                        );
                    }
                }








                //setTrendlineCordStart(
                if(
                    isMouseDown &&
                    ind===0//(isYmagnificationActivated ? ind : dataCandleStartEndIndex.dataCandleStartIndex + ind) === data.length-1 - dataShiftCount
                ){
                    /*const currentClosePriceScaled = ((canvasChartHeight * (currentPriceData.close - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) // 1.0 + 0.2(=20%)
                const currentClosePriceYcord = ((canvasChartHeight - currentClosePriceScaled) + YchartShiftPX) + chartYvelocity 
                */
                    if(isPipCheckerActivated){
                        //context.setLineDash([5, 5]);
                        // Draw horizontal Price Line
                        context.beginPath(); // reset the context state
                        context.strokeStyle = "black"; // color of the line
                        context.lineWidth = 0.5; // thickness of the line
                        context.moveTo(pipCheckerCordStart.x, canvasChartHeight - pipCheckerCordStart.y); // moveTo(x,y) -> starting point of the line
                        context.lineTo(pipCheckerCordEnd.x, canvasChartHeight - pipCheckerCordEnd.y); // line(x,y) -> end point of the line
                        context.stroke(); // draws the line
                    
                        //context.setLineDash([]);
                    }
                }



                


                
                if(
                    //isChartOnREPLAYmode===false &&
                    //((isChartOnREPLAYmode && isMouseDown===false) || isChartOnREPLAYmode===false)&&
                    ind === 0
                    && 
                    (crossHairOnMoveCordVals.y > datetimeLabelMarginSize || isDrawingActivated)
                ){
                    //------------------------------------------------------------------------------

                    //if(isChartOnREPLAYmode===false || (isChartOnREPLAYmode && isMouseDown===false)){
                    if(
                      isMouseDown &&
                      isChartOnREPLAYmode &&
                      selectedDrawingForEditInfoIdObj &&
                      selectedDrawingForEditInfoIdObj.isReplayLineDrawing
                    ){
                      //do nothing for this Auth
                    }
                    else{
                      // Set line dash pattern (5 pixels on, 5 pixels off)
                      context.setLineDash([5, 5]);

                      //Draw horizontal crossHair line
                      context.beginPath(); // reset the context state
                      context.strokeStyle =  "black"; // color of the line
                      context.lineWidth = 0.5; // thickness of the line
                      context.moveTo(0, canvasChartHeight - (isPipCheckerActivated && isMouseDown ? pipCheckerCordStart.y : crossHairOnMoveCordVals.y)); // moveTo(x,y) -> starting point of the line
                      context.lineTo(canvasChartWidth - priceLabelMarginSize, canvasChartHeight - (isPipCheckerActivated && isMouseDown ? pipCheckerCordStart.y :crossHairOnMoveCordVals.y)); // line(x,y) -> end point of the line
                      context.stroke(); // draws the line

                      // Reset line dash (draw solid line)
                      context.setLineDash([]);
                    }
                }


                if(//at last Index draw & sit on all candles or chart
                    isYmagnificationActivated ? ind === data.length - 1 : 
                    ind === dataCandleStartEndIndex.dataCandleEndIndex-1 - dataCandleStartEndIndex.dataCandleStartIndex
                    
                ){
                    //console.log({open,high,low,close,datetime})
                    //console.log({open:el.open,high:el.high,low:el.low,close:el.close,datetime:el.datetime})

                    


                    if(
                        ((isChartOnREPLAYmode && isMouseDown===false) || isChartOnREPLAYmode===false)
                        &&
                        (
                          (isMouseDown===false || selectedDrawingForEditInfoIdObj.isSelected===undefined || selectedDrawingForEditInfoIdObj.isSelected===false) 
                          ||
                          (
                              selectedDrawingForEditInfoIdObj.isSelected && isMouseDown &&
                              (
                                  selectedDrawingForEditInfoIdObj.drawingType!=="trendline3" && 
                                  selectedDrawingForEditInfoIdObj.drawingType!=="trendline4"
                              )
                          )
                        )
                    ){
                        //console.log({isSelected:selectedDrawingForEditInfoIdObj.isSelected, selectedDrawingForEditInfoIdObj})
                        //Draw horizontal crossHairPriceText & centered //isPipCheckerActivated && isMouseDown ? pipCheckerCordStart.x :
                        //Change (crossHairOnMoveCordVals.y - YchartShiftPX - chartYvelocity) to (crossHairOnMoveCordVals.y + YchartShiftPX + chartYvelocity)
                        //const crossHairYprice = ((crossHairOnMoveCordVals.y + YchartShiftPX + chartYvelocity) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;
                        const crossHairYprice = (((isPipCheckerActivated && isMouseDown ? pipCheckerCordStart.y : crossHairOnMoveCordVals.y) + YchartShiftPX + chartYvelocity) / 
                        (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;

                        
                        context.fillStyle = "black";  
                        context.font = "12px Verdana";
                        // context.fillText(text, xCoord, yCoord, maxWidth);
                        context.textAlign = "start";
                        //context.textBaseline = "middle";
                        context.fillText(String(crossHairYprice), 
                        canvasChartWidth - priceLabelMarginSize,
                        canvasChartHeight - (isPipCheckerActivated && isMouseDown ? pipCheckerCordStart.y : crossHairOnMoveCordVals.y + 3)
                        );
                    }



                    


                    if(isPipCheckerActivated && isMouseDown){//the moving part

                        const pipStartYprice = ((pipCheckerCordStart.y  + YchartShiftPX + chartYvelocity) / 
                        (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;

                        const pipEndYprice = ((pipCheckerCordEnd.y  + YchartShiftPX + chartYvelocity) / 
                        (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;

                        context.fillStyle = "black";  
                        context.font = "12px Verdana";
                        // context.fillText(text, xCoord, yCoord, maxWidth);
                        context.textAlign = "start";
                        //context.textBaseline = "middle";
                        context.fillText(String(pipEndYprice), 
                        canvasChartWidth - priceLabelMarginSize,
                        canvasChartHeight - pipCheckerCordEnd.y
                        );

                        /*
                        const isCandleChart   = chartInfoObj.candleORrsi === 'candle'
                        const isRSIchart      = chartInfoObj.candleORrsi === 'rsi'
                        const isINDEXchart    = chartInfoObj.indexORpair === 'index'
                        const isPAIRchart     = chartInfoObj.indexORpair === 'pair'
                        */

                        context.fillStyle = "black";  
                        context.font = "12px Verdana";
                        // context.fillText(text, xCoord, yCoord, maxWidth);
                        context.textAlign = "start";
                        //context.textBaseline = "middle";
                        context.fillText("     " + String(Math.ceil(Math.abs(pipEndYprice - pipStartYprice) * (chartInfoObj.instruName.includes('JPY') ? 
                        (isCandleChart && isINDEXchart ? 10 : 100) : (isCandleChart && isINDEXchart ? 10*2 : 10000)))) + " pips  - " + 
                        `${Math.round(Math.abs(pipCheckerCordEnd.x - pipCheckerCordStart.x) / candleWidthSize)}` + " candles", 
                        isTouchScreenDevice ? pipCheckerCordStart.x : pipCheckerCordEnd.x,
                        isTouchScreenDevice ? canvasChartHeight - pipCheckerCordStart.y + 12 : canvasChartHeight - pipCheckerCordEnd.y + 12
                        );
                    }

                    if(isChartOnREPLAYmode===false){
                      //Draw currentPriceText
                      context.fillStyle = "green";  
                      context.font = "12px Verdana";
                      // context.fillText(text, xCoord, yCoord, maxWidth);
                      context.textAlign = "start";
                      //context.textBaseline = "middle";
                      context.fillText(String(currentPriceData.close), 
                      canvasChartWidth - priceLabelMarginSize,
                      currentClosePriceYcord + 3
                      );
                    }

                    //timeZoneDatetimeConverter - NOTE: modify datetime TIMEzone only for view on screen purposes - but not modify/update any state/variable
                    let t_zone_TIME_convert = timeZoneDatetimeConverter(
                        liveMT4datetimeSTR, 
                        localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                        zero_gmt_deviation
                    )
                    const lastIndexOfColon = t_zone_TIME_convert.lastIndexOf(':')
                    t_zone_TIME_convert = t_zone_TIME_convert.slice(0, lastIndexOfColon) + ":00"

                    //Draw currentPriceDatetimeText
                    context.fillStyle = "blue";  
                    context.font = "10px Verdana";
                    // context.fillText(text, xCoord, yCoord, maxWidth);
                    context.textAlign = "end";
                    //context.textBaseline = "middle";
                    context.fillText(//zero_gmt_deviation
                    //liveMT4datetimeSTR,//timestampToDatetime(Date.now()) (String(currentPriceData.datetime), 
                    t_zone_TIME_convert,
                    canvasChartWidth /*- priceLabelMarginSize*/,
                    0+13 
                    );


                    //insert drawing data into localStorage, if drawing is done 
                    if(isDrawingDoneOnMouseUp){//onDrawingMoveInfoTrackerObj

                        if(//store drawing if its candleBoxWidthCount is > a given number
                            onDrawingMoveInfoTrackerObj !== undefined 
                            && 
                            (//trendlineTypeToDraw==='trendline6'&& isDrawingActivated && isRectangleTextInput ?
                                /*onDrawingMoveInfoTrackerObj.drawingType==="trendline1" ||*/ 
                                onDrawingMoveInfoTrackerObj.drawingType==="trendline2" || 
                                (onDrawingMoveInfoTrackerObj.drawingType==="trendline6" && isRectangleTextInput===false)
                                ? onDrawingMoveInfoTrackerObj.candleBoxWidthCount >= 1 : true
                            )
                        ){
                            //store tracked drawing data in the localstorage
                            const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                            const isDataExist = drawingData !== null

                            if(isDataExist){
                                const data = JSON.parse(drawingData)

                                let newData = [...data]
                                
                                if(onDrawingMoveInfoTrackerObj.id){
                                    newData = [...newData, onDrawingMoveInfoTrackerObj]
                                }

                                //alert(newData)
                                window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))
                                //setSelectedDrawingForEditInfoIdObj({})

                                //console.log('data : ', data, " : ", newData)

                                //keep drawing still selected immediately after drawing to open for any Editing
                                setSelectedDrawingForEditInfoIdObj({...onDrawingMoveInfoTrackerObj, isSelected:true})
                                

                                setOnDrawingMoveInfoTrackerObj({})

                            }
                            else{
                                window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify([onDrawingMoveInfoTrackerObj]))
                                //keep drawing still selected immediately after drawing to open for any Editing
                                setSelectedDrawingForEditInfoIdObj({...onDrawingMoveInfoTrackerObj, isSelected:true})
                                setOnDrawingMoveInfoTrackerObj({})
                            }

                            setIsDrawingDoneOnMouseUp(false)
                            setUseEffectReRunCounter(Date.now())
                        }
                        else{
                            setSelectedDrawingForEditInfoIdObj({})
                            setIsDrawingDoneOnMouseUp(false)

                            setUseEffectReRunCounter(Date.now())
                        }
                    }
                }



                if(//at last Index draw & sit on all candles or chart
                (isYmagnificationActivated ? ind === data.length - 1 : 
                ind === dataCandleStartEndIndex.dataCandleEndIndex-1 - dataCandleStartEndIndex.dataCandleStartIndex)
                &&
                isUseEffectChartLoadStarted===false
                ){//to do something stuff when false & unloaded


                    setIsUseEffectChartLoadStarted(true)
                }



                //at last Index of loop, release from the localStorage & plot all Drawings
                if(
                    (isYmagnificationActivated ? ind === data.length - 1 : 
                    ind === dataCandleStartEndIndex.dataCandleEndIndex-1 - dataCandleStartEndIndex.dataCandleStartIndex)
                    && isHideAllDrawings===false
                ){
                    /*
                    const drawingsDataArray = {//trendlines, rects, vLines, hLines, circles, rrRects
                        [
                            {
                                drawingType : 'trendline1',
            
                                datetimeStart   : '2023-08-22 08:00:00',
                                datetimeEnd     : '2023-08-29 20:00:00',//where to start backCountDrawing from

                                cordYpriceStart : 1.09188,
                                cordYpriceEnd   : 1.07877,

                                candleBoxWidthCount : 32,
                                candleBoxWidthSize  : 10.9,

                                tfStarter       : 'h4',
                                tfEditor        : 'h4',

                                id : Date.now()//1703864044404
                            }
                        ],
                    */


                    let isAnyOfThePrevDrawingsIndexNotInUse = true

                    //in order not select or work with double drawings of same IDs
                    //which will cause crashes
                    let drawingIDsWorkedWithArray = []


                    //console.log({localStorageResArray})
                    //techiman
                    //if(isCandleChart){
                    localStorageResArray.forEach((drawingTypeObjDATA, index)=>{

                        const drawingTypeObj = {...drawingTypeObjDATA}
                        //console.log({drawingTypeObj})
                        //now refine both datetimeStart & datetimeEnd of a given trendline to fit or suite thy given current tf

                        //Auth if drawing ID already worked with - to avoid repeating it - to avoid selection & editing crashes of multiple drawings of same highlighted IDs
                        const isDrawingIDalreadyUSED = drawingIDsWorkedWithArray.includes(drawingTypeObj.id)//drawingIDsWorkedWithArray.push(drawingTypeObj.id)


                        //use thy Auth & operations to:
                        //modify, adjust & prepare drawing data to suite a given tf charts drawings
                        //thereby: observing the act of modifying data for drawing 
                        //BUT not modify what is in the LStorage - as suites ither TFs - yet we can modify it to suite other TFs without changing what's in the LStorage
                        if(//as refined now, clear out any refinings below this code
                          isDrawingIDalreadyUSED === false &&
                          data.length > 0 &&
                          (authDatetimeStrValidityBeforeDrawORedit(drawingTypeObj.datetimeStart) && 
                          authDatetimeStrValidityBeforeDrawORedit(drawingTypeObj.datetimeEnd))
                        ){
                          const refinedDATETIMEstart = refineAdjustPrevDatetimeStart({currentTF:chartInfoObj.tf, prevDatetimeStart:drawingTypeObj.datetimeStart})
                          const refinedDATETIMEend   = refineAdjustPrevDatetimeStart({currentTF:chartInfoObj.tf, prevDatetimeStart:drawingTypeObj.datetimeEnd})

                          drawingTypeObj.datetimeStart = refinedDATETIMEstart
                          drawingTypeObj.datetimeEnd   = refinedDATETIMEend

                          

                          /*console.log({
                            tf: chartInfoObj.tf,
                            refinedDATETIMEstart,
                            refinedDATETIMEend
                          })*/
                        }//*/



                        //console.log(allDrawingsDatetimeXcordObj)//wenchi
                        /*console.log('badu---ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo',
                        drawingTypeObj.drawingType==='trendline3', datetimeToTimestamp(data[
                            isYmagnificationActivated ? 0 : dataCandleStartEndIndex.dataCandleStartIndex + 0
                        ].datetime) > datetimeToTimestamp(drawingTypeObj.datetimeStart) 
                        )*/

                        if(
                            isDrawingIDalreadyUSED === false &&

                            data.length > 0 &&

                            //perform any drawings onto chart if the datetimes are valid by Auth
                            (authDatetimeStrValidityBeforeDrawORedit(drawingTypeObj.datetimeStart) && 
                            authDatetimeStrValidityBeforeDrawORedit(drawingTypeObj.datetimeEnd))
                        ){


                        //store drawing IDs - that had passed test & Auth & worked with
                        drawingIDsWorkedWithArray.push(drawingTypeObj.id)

                          

                        const startTimeToAuth = datetimeToTimestamp(drawingTypeObj.datetimeStart) <= datetimeToTimestamp(drawingTypeObj.datetimeEnd) ? drawingTypeObj.datetimeStart : drawingTypeObj.datetimeEnd
                        const endTimeToAuth   = datetimeToTimestamp(drawingTypeObj.datetimeEnd) >= datetimeToTimestamp(drawingTypeObj.datetimeStart) ? drawingTypeObj.datetimeEnd : drawingTypeObj.datetimeStart

                        //Auth, when: BOTH start & end datetime of Trendline/drawing is outsite/beyond the chart start&end-time
                        const isDrawingStartToEndDatetimeAwayBeyondChartReach = (

                            datetimeToTimestamp(startTimeToAuth)
                            < //BOTH - datetimeStart outside
                            datetimeToTimestamp(data[
                                isYmagnificationActivated ? 0 : dataCandleStartEndIndex.dataCandleStartIndex + 0
                            ].datetime) 
                            

                            &&

                            datetimeToTimestamp(endTimeToAuth)
                            >//AND - datetimeEnd outside
                            datetimeToTimestamp(data[
                                isYmagnificationActivated ? data.length - 1 : dataCandleStartEndIndex.dataCandleEndIndex - 1
                            ].datetime) 

                        )


                        //Auth, when: ONLY the end datetime of Trendline/drawing is outsite/beyond the chart end-time
                        //no need to Auth for ONLY the start datetime if beyond, since modifications of drawings ends of current price/data 
                        //are relevant/needed than the modifications of drawings start which are beyond & dead & less needed
                        const isONLYdrawingDatetimeEndAwayBeyondChartShiftedCandlesReach = (

                            datetimeToTimestamp(startTimeToAuth)
                            >= //datetimeStart inside
                            datetimeToTimestamp(data[
                                isYmagnificationActivated ? 0 : dataCandleStartEndIndex.dataCandleStartIndex + 0
                            ].datetime) 
                            

                            &&

                            datetimeToTimestamp(endTimeToAuth)
                            >//BUT - datetimeEnd outside
                            datetimeToTimestamp(data[
                                isYmagnificationActivated ? data.length - 1 : dataCandleStartEndIndex.dataCandleEndIndex - 1
                            ].datetime) 
                           
                        )
                        //console.log('is TL end > : ', isONLYdrawingDatetimeEndAwayBeyondChartShiftedCandlesReach)



                        const isStartDatetimeExistInDataArray = datetimeToTimestamp(startTimeToAuth) >= datetimeToTimestamp(data[
                            isYmagnificationActivated ? 0 : dataCandleStartEndIndex.dataCandleStartIndex + 0
                        ].datetime)

                        const isEndDatetimeExistInDataArray = datetimeToTimestamp(endTimeToAuth) <= datetimeToTimestamp(data[
                            isYmagnificationActivated ? data.length - 1 : dataCandleStartEndIndex.dataCandleEndIndex - 1
                        ].datetime)

                        //console.log({isNonArrayExist : isDrawingStartToEndDatetimeAwayBeyondChartReach, index})

                        //console.log({datetimeStart:drawingTypeObj.datetimeStart, allDrawingsDatetimeXcordObj}, '---1')






                        if(
                            (
                                drawingTypeObj.drawingType==='trendline1'  || 
                                drawingTypeObj.drawingType==='trendline8' || 
                                drawingTypeObj.drawingType==='trendline2' || 
                                drawingTypeObj.drawingType==='trendline3' || 
                                drawingTypeObj.drawingType==='trendline7' || 
                                drawingTypeObj.drawingType==='trendline4' || 
                                drawingTypeObj.drawingType==='trendline5' || 
                                drawingTypeObj.drawingType==='trendline6'//==='trendline1'
                            ) 
                            && (
                                drawingTypeObj.drawingType==='trendline4' ||

                                allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart] || 

                                allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd] || 

                                (
                                    drawingTypeObj.drawingType==='trendline3' && 
                                    datetimeToTimestamp(data[
                                        isYmagnificationActivated ? 0 : dataCandleStartEndIndex.dataCandleStartIndex + 0
                                    ].datetime) > datetimeToTimestamp(drawingTypeObj.datetimeStart) 
                                ) 

                                || 

                                (
                                    //drawingTypeObj.drawingType==='trendline7' && 
                                    isDrawingStartToEndDatetimeAwayBeyondChartReach
                                )
                            )//*/
                        ){
                            const {
                                drawingType,//: 'trendline1',
                                /*datetimeStart,//   : '2023-08-22 08:00:00',
                                datetimeEnd,//     : '2023-08-29 20:00:00',//where to start backCountDrawing from
                                cordYpriceStart,// : 1.09188,
                                cordYpriceEnd,//   : 1.07877,*/
                                //candleBoxWidthCount,// : 32,
                                candleBoxWidthSize,//  : 10.9,
                                tfStarter,//       : 'h4',
                                tfEditor,//        : 'h4',
                                id,// : Date.now()
                            } = drawingTypeObj
                            /*console.log({
                                drawingType ,//: 'trendline1',
                                datetimeStart,//   : '2023-08-22 08:00:00',
                                datetimeEnd,//     : '2023-08-29 20:00:00',//where to start backCountDrawing from
                                cordYpriceStart,// : 1.09188,
                                cordYpriceEnd,//   : 1.07877,
                                candleBoxWidthCount,// : 32,
                                candleBoxWidthSize,//  : 10.9,
                                tfStarter,//       : 'h4',
                                tfEditor,//        : 'h4',
                                id,// : Date.now()
                            })*/
                            /*
                            console.log({
                                    candleWidthSize,
                                    crossHairMidStartXcordDrawerInfo.xCord,
                                    allVerticalLinesXcord,
                                    res : Math.abs((allVerticalLinesXcord - crossHairMidStartXcordDrawerInfo.xCord) / candleWidthSize)
                                })
                            */

                            //console.log({datetimeStart:drawingTypeObj.datetimeStart}, '---2')


                            //[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13
                            /*[
                                'trendline1_1',//angularTL
                                'trendline1_2',//angularPointerTL
                                'trendline2',//shortHoriTL
                                'trendline3',//centerToEndHoriTL
                                'trendline4',//HoriTL
                                'trendline5',//VertiTL
                                'trendline6_1',//rect-color
                                'trendline6_2',//rect
                                'trendline7_1',//rectSquareSELL
                                'trendline7_2',//rectSquareBUY
                                'trendline8',//circle
                            ]*/

                            //-----------------------------------------------------------------------------
                            //Auths of All trendlineType:
                            //----------------------------
                            //drawingType==='trendline3/4' - midTLtoEnd:
                            const isDrawingTypeTrendline1 = drawingTypeObj.drawingType==='trendline1'
                            const isDrawingTypeTrendline2 = drawingTypeObj.drawingType==='trendline2'
                            const isDrawingTypeTrendline3 = drawingTypeObj.drawingType==='trendline3'
                            const isDrawingTypeTrendline4 = drawingTypeObj.drawingType==='trendline4'

                            const isDrawingTypeTrendline5 = drawingTypeObj.drawingType==='trendline5'
                            const isDrawingTypeTrendline6 = drawingTypeObj.drawingType==='trendline6'
                            const isDrawingTypeTrendline7 = drawingTypeObj.drawingType==='trendline7'
                            const isDrawingTypeTrendline8 = drawingTypeObj.drawingType==='trendline8'
                            //-------------------------------------------------------------------------------


                            let datetimeStart   = drawingTypeObj.datetimeStart//   : '2023-08-22 08:00:00',
                            let datetimeEnd     = drawingTypeObj.datetimeEnd//     : '2023-08-29 20:00:00',//where to start backCountDrawing from
                            let cordYpriceStart = drawingTypeObj.cordYpriceStart// : 1.09188,
                            let cordYpriceEnd   = drawingTypeObj.cordYpriceEnd//   : 1.07877,
                            let isDrawingForward = drawingTypeObj.isDrawingForward

                            /*if(isDrawingTypeTrendline4){
                                console.log({cordYpriceStart, num:1})
                            }*/

                            const isDTendGTdtStart  = datetimeToTimestamp(datetimeEnd) >= datetimeToTimestamp(datetimeStart)
                            //const isDTendLTdtStart  =  datetimeToTimestamp(datetimeEnd) < datetimeToTimestamp(datetimeStart)


                            const datetimeStartToGet = datetimeToTimestamp(datetimeStart) <= datetimeToTimestamp(datetimeEnd) ? datetimeStart : datetimeEnd
                            const datetimeEndToGet   = datetimeToTimestamp(datetimeEnd)   >= datetimeToTimestamp(datetimeStart) ? datetimeEnd : datetimeStart
                            //--------------------------------------------------------------------------------------------------------------------
                            //calc if timeStamp diff of a given tf(eg: m1) is < a given time eg : a year
                            let datetimeCandleDiffCount = 0
                            //--------------------------------------------------------------------------------------------------------------------


                            //-------------------------------------------------------------------------------------------------
                            //calc tf dtStart & dtEnd countDiff
                            const datetimeTFcandleDiffCountAndItsDatetimes = getDatetimeDiffCount({
                                datetimeStart : datetimeStartToGet,
                                datetimeEnd : datetimeEndToGet,
                                tf : chartInfoObj.tf
                            })

                            //console.log('res : ', datetimeTFcandleDiffCountAndItsDatetimes)
                            /*
                            {datetimeStart: '2023-08-28 16:00:00', datetimeEnd: '2023-08-31 04:00:00', count: 15}
                            */

                            let isAllDatetimeAndCandleDiffValid = false

                            if(datetimeTFcandleDiffCountAndItsDatetimes !== "error"){

                                const objResult = datetimeTFcandleDiffCountAndItsDatetimes

                                const isDatetimeStartValid   = authDatetimeStrValidityBeforeDrawORedit(objResult.datetimeStart)
                                const isDatetimeEndValid     = authDatetimeStrValidityBeforeDrawORedit(objResult.datetimeEnd)
                                const isCandleDiffCountValid = objResult.count >= 0

                                isAllDatetimeAndCandleDiffValid = (
                                    isDatetimeStartValid   &&
                                    isDatetimeEndValid     &&
                                    isCandleDiffCountValid 
                                )

                                if(isAllDatetimeAndCandleDiffValid){
                                    datetimeCandleDiffCount = objResult.count
                                    //console.log({count:objResult.count})
                                }

                            }

                            
                            //-------------------------------------------------------------------------------------------------



                            
                           
                            let candleBoxWidthCount = /*isMouseDown && selectedDrawingForEditInfoIdObj.id === id 
                            && selectedDrawingForEditInfoIdObj.isSelected && isEditingDrawing &&*/ //isDTendLTdtStart// 
                            isDTendGTdtStart===false ? 
                            -(datetimeCandleDiffCount) : Math.abs(datetimeCandleDiffCount)
                            //-(drawingTypeObj.candleBoxWidthCount) : Math.abs(drawingTypeObj.candleBoxWidthCount)

                            //------------------------------------------------------------------------------------------
                            //switch the dtStartStr & dtEndStr from the count func
                            //if: isDTendGTdtStart===false ?
                            if(isAllDatetimeAndCandleDiffValid){
                                if(isDTendGTdtStart===false){
                                    datetimeStart = datetimeTFcandleDiffCountAndItsDatetimes.datetimeEnd 
                                    datetimeEnd   = datetimeTFcandleDiffCountAndItsDatetimes.datetimeStart

                                    //console.log({datetimeStart, datetimeEnd})
                                }else{
                                    datetimeStart = datetimeTFcandleDiffCountAndItsDatetimes.datetimeStart
                                    datetimeEnd   = datetimeTFcandleDiffCountAndItsDatetimes.datetimeEnd

                                    //console.log({datetimeStart, datetimeEnd})
                                }
                            }
                            //------------------------------------------------------------------------------------------



                            //------------------------------------------------------------------------------------------
                            //if : isEditingDrawing
                            //get local storage storage & fix :
                            //if candleBoxWidthCount===neg val to pos val
                            //reverse datetime & price, if startTime > endTime
                            const isCandleBoxWidthCountNegative = /*drawingTypeObj.*/candleBoxWidthCount < 0

                            const isDrawingIdEditSelected = isMouseDown && selectedDrawingForEditInfoIdObj.id === id && selectedDrawingForEditInfoIdObj.isSelected && isEditingDrawing 
                            const isEditingDrawingForward = isDrawingIdEditSelected && isDTendGTdtStart===false ? false : true
                            //console.log({isNeg:isCandleBoxWidthCountNegative, isGT:isDTendGTdtStart})
                            //console.log({datetimeStart : drawingTypeObj.datetimeStart, datetimeEnd : drawingTypeObj.datetimeEnd})

                            isDrawingForward = isDrawingIdEditSelected && isEditingDrawingForward===false ? !isDrawingForward : isDrawingForward

                        

                            //techiman
                            if(//if count >= 0 && dtStartStr && dtEndStr Valid
                                //true
                                isAllDatetimeAndCandleDiffValid
                            ){
                            //replace all drawingTypeObj.isDrawingForward by let isDrawingForward = drawingTypeObj.isDrawingForward, isDrawingForward = newIsDrawingForward
                            if(
                                (
                                    isDrawingTypeTrendline3===false &&
                                    isDrawingTypeTrendline4===false &&
                                    isDrawingTypeTrendline5===false 
                                ) &&
                                (
                                    (isCandleBoxWidthCountNegative && isMouseDown===false) ||
                                    (isDTendGTdtStart===false && isMouseDown===false)
                                )
                                //&& false
                            ){

                                const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                                const isDataExist = drawingData !== null

                                //now as well map & store the newObj into localStorage
                                if(isDataExist){
                                    const data = JSON.parse(drawingData)

                                    let newData = [...data]
                                    
                                    if((isDTendGTdtStart===false && isMouseDown===false)){
                                        newData = newData.map((el, elInd)=>{
                                            if(el.id===id){
                                                return({
                                                    ...el,
                                                    candleBoxWidthCount : Math.abs(el.candleBoxWidthCount),
                                                    datetimeStart   : el.datetimeEnd,//     : '2023-08-29 20:00:00',//where to start backCountDrawing from
                                                    datetimeEnd     : el.datetimeStart,//   : '2023-08-22 08:00:00', 
                                                    cordYpriceStart : el.cordYpriceEnd,//   : 1.07877,
                                                    cordYpriceEnd   : el.cordYpriceStart,// : 1.09188, 
                                                    isDrawingForward: !el.isDrawingForward
                                                })
                                            }
                                            else{
                                                return el
                                            }
                                        })
                                    }


                                    //alert(newData)
                                    window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))

                                }

                            }
                            //------------------------------------------------------------------------------------------

                            
                            //--------------------------------------------------------------------------------------------
                            const isTrendline3DatetimeAncient = (isDrawingTypeTrendline3 && isStartDatetimeExistInDataArray===false
                            /*datetimeToTimestamp(data[
                                isYmagnificationActivated ? 0 : dataCandleStartEndIndex.dataCandleStartIndex + 0
                            ].datetime) > datetimeToTimestamp(drawingTypeObj.datetimeStart) */
                            )

                            const isTrendline7DatetimeAncientAndFuture = (
                                drawingTypeObj.drawingType==='trendline7' && isDrawingStartToEndDatetimeAwayBeyondChartReach/*
                                datetimeToTimestamp(data[
                                    isYmagnificationActivated ? 0 : dataCandleStartEndIndex.dataCandleStartIndex + 0
                                ].datetime) > datetimeToTimestamp(drawingTypeObj.datetimeStart) &&
                                datetimeToTimestamp(data[
                                    isYmagnificationActivated ? data.length - 1 : dataCandleStartEndIndex.dataCandleEndIndex - 1
                                ].datetime) < datetimeToTimestamp(drawingTypeObj.datetimeEnd)*/ 
                            )

                            //drawingType==='trendline4' - fullTLstartToChartEnd:
                            //-----------------------------------------------------------------------------------

                            /*if(isDrawingTypeTrendline4){
                                console.log({cordYpriceStart, num:2})
                            }*/



                            let YcordPriceStart = ((canvasChartHeight * (cordYpriceStart - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) 
                            YcordPriceStart = ((canvasChartHeight - YcordPriceStart) + YchartShiftPX) + chartYvelocity 
                            YcordPriceStart = isDrawingTypeTrendline5 ? 0 : YcordPriceStart

                            let YcordPriceEnd = ((canvasChartHeight * (cordYpriceEnd - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) 
                            YcordPriceEnd = ((canvasChartHeight - YcordPriceEnd) + YchartShiftPX) + chartYvelocity 
                            YcordPriceEnd = isDrawingTypeTrendline5 ? canvasChartHeight : YcordPriceEnd


                            //XcordStart + ((candleBoxWidthCount * tfCandleRatioCount) * candleWidthSize)
                            //candleBoxWidthCount :  is only calculated onDraw & onEdit using prevNextDatetimeFunc
                            //we dont calc it when drawing, we only divide or multiply by w.r.t the datetime we are drawing on 
                            //const tfCandleRatioCount = /*tf===tfEditor ? 1 :*/ tfsMinsCountObj[tfEditor] / tfsMinsCountObj[tf]

                            /*if(isDrawingTypeTrendline4){
                                console.log({YcordPriceStart, num:3})
                            }*/

                            //techiman
                            //----------------------------------------------------------------------------------------------------------------------------------
                            if(
                                (
                                    isDrawingTypeTrendline6==false &&
                                    isDrawingTypeTrendline7==false //&&
                                    //isDrawingTypeTrendline4==false
                                )
                                &&
                                //isYmagnificationActivated===false &&
                                (isDrawingStartToEndDatetimeAwayBeyondChartReach 
                                  || isONLYdrawingDatetimeEndAwayBeyondChartShiftedCandlesReach
                                ) 
                                && 
                                (YcordPriceStart !== YcordPriceEnd)
                            ){

                                /*
                                //DEFINE: this CODE also in drawing editing CODE - where we edit dragging data & modify drawing data - if necessary
                                //reset drawing datetimeStart & datetimeEnd, 
                                //if: the datetimeStart of drawing in LStorage is < the data[0].datetime 
                                //& if: the datetimeEnd of drawing in LStorage is > the data[data.length-1].datetime
                                //calc by datetimeToTimestamp() integer func 
                                //NOTE: for Ymag===false - take prevDATETIME steps equal to the width of priceLABELwidth - done based on candleWidthSIZE
                                //in order for the tip of the line to be accessible for modifications - when pulled away from under the priceLABELwidth
                                */

                                
                                //NOTE: for Ymag===false - take prevDATETIME steps equal to the width of priceLABELwidth - done based on candleWidthSIZE
                                //in order for the tip of the line to be accessible for modifications - when pulled away from under the priceLABELwidth
                                //const numOfCandleWidthSizeThatMakesPriceLabelMarginSize = Math.ceil(priceLabelMarginSize/candleWidthSize)//+ 1
                                //const priceLABELwidthMarginVal = isYmagnificationActivated ? 0 : numOfCandleWidthSizeThatMakesPriceLabelMarginSize
                                //console.log('num : ', numOfCandleWidthSizeThatMakesPriceLabelMarginSize)

                                //TL-invincibleBEYOND start&end
                                const fullDatetimeStartToGet = datetimeToTimestamp(datetimeStart) <= datetimeToTimestamp(datetimeEnd) ? datetimeStart : datetimeEnd
                                const fullDatetimeEndToGet   = datetimeToTimestamp(datetimeEnd)   >= datetimeToTimestamp(datetimeStart) ? datetimeEnd : datetimeStart


                                const partialDatetimeStartToGet = isONLYdrawingDatetimeEndAwayBeyondChartShiftedCandlesReach ? fullDatetimeStartToGet :
                                data[//TL-visibleStart
                                    isYmagnificationActivated ? 0 : dataCandleStartEndIndex.dataCandleStartIndex + 0
                                ].datetime

                                const partialDatetimeEndToGet = data[//TL-visibleEnd
                                    isYmagnificationActivated ? data.length - 1 : (dataCandleStartEndIndex.dataCandleEndIndex - 1) //- priceLABELwidthMarginVal
                                ].datetime

                                /*
                                //to test if would return us 0 for same datetime
                                console.log('res : ', getDatetimeDiffCount({
                                  datetimeStart : fullDatetimeStartToGet,
                                  datetimeEnd   : fullDatetimeStartToGet,
                                  tf : chartInfoObj.tf
                                }), isONLYdrawingDatetimeEndAwayBeyondChartShiftedCandlesReach)*/
                                
                                //-------------------------------------------------------------------------------------------------
                                //calc tf dtStart & dtEnd countDiff
                                let fullDatetimeCountDiff = getDatetimeDiffCount({
                                    datetimeStart : fullDatetimeStartToGet,
                                    datetimeEnd   : fullDatetimeEndToGet,
                                    tf : chartInfoObj.tf
                                })
                                let partialStartDatetimeCountDiff = isONLYdrawingDatetimeEndAwayBeyondChartShiftedCandlesReach ? {count:0} : getDatetimeDiffCount({
                                    datetimeStart : fullDatetimeStartToGet,
                                    datetimeEnd   : partialDatetimeStartToGet,
                                    tf : chartInfoObj.tf
                                })
                                let partialEndDatetimeCountDiff = getDatetimeDiffCount({
                                    datetimeStart : partialDatetimeEndToGet,
                                    datetimeEnd   : fullDatetimeEndToGet,
                                    tf : chartInfoObj.tf
                                })


                                if(
                                    fullDatetimeCountDiff !== "error" &&
                                    partialStartDatetimeCountDiff !== "error" &&
                                    partialEndDatetimeCountDiff !== "error" 
                                ){
                                    fullDatetimeCountDiff         = fullDatetimeCountDiff.count
                                    partialStartDatetimeCountDiff = partialStartDatetimeCountDiff.count
                                    partialEndDatetimeCountDiff   = partialEndDatetimeCountDiff.count
                                
                                    /*console.log({
                                        //start : start.count,
                                        //mid : mid.count,
                                        //end : end.count,
                                        fullDatetimeCountDiff,
                                        partialStartDatetimeCountDiff,
                                        partialEndDatetimeCountDiff
                                    })*/

                                    const x = fullDatetimeCountDiff * candleWidthSize// * tfCandleRatioCount 
                                    const y = Math.abs(YcordPriceEnd - YcordPriceStart)

                                    const isYcordStartGreater  = (YcordPriceStart > YcordPriceEnd) === false//base on chart inverse
                                    //const isYcordStartEqualEnd = YcordPriceStart === YcordPriceEnd

                                    const opposite = Math.abs(y)
                                    const adjacent = Math.abs(x)
                                    const angle      = pythagorasCalculator({calcType : 'tan', varToCalc : 'aa', opposite, adjacent})
                                    //const hypotenuse = pythagorasCalculator({calcType : 'sin', varToCalc : 'h', opposite, angle})

                                    
                                    const x1 = partialStartDatetimeCountDiff * candleWidthSize// * tfCandleRatioCount 
                                    const x2 = partialEndDatetimeCountDiff   * candleWidthSize// * tfCandleRatioCount 

                                    const hypotenuse1 = isONLYdrawingDatetimeEndAwayBeyondChartShiftedCandlesReach ? 0 : 
                                                        pythagorasCalculator({calcType : 'cos', varToCalc : 'h', adjacent:x1, angle})
                                    const opposite1   = isONLYdrawingDatetimeEndAwayBeyondChartShiftedCandlesReach ? 0 : 
                                                        pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse:hypotenuse1, angle})

                                    const hypotenuse2 = pythagorasCalculator({calcType : 'cos', varToCalc : 'h', adjacent:x2, angle})
                                    const opposite2   = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse:hypotenuse2, angle})

                                    if(isYcordStartGreater===false){
                                        YcordPriceStart = YcordPriceStart - opposite1
                                        YcordPriceEnd   = YcordPriceEnd   + opposite2
                                    }
                                    else if(isYcordStartGreater){
                                        YcordPriceStart = YcordPriceStart + opposite1
                                        YcordPriceEnd   = YcordPriceEnd   - opposite2
                                    }

                                    
                                    /*console.log({
                                        x , y, 
                                        angle, //isGT: isYcordStartGreater
                                        //h1 : hypotenuse,
                                        h2 : hypotenuse2,
                                        o2 : opposite2,

                                        a : partialStartDatetimeCountDiff,
                                        b : fullDatetimeCountDiff,
                                        c : partialEndDatetimeCountDiff
                                    })*/

                                }

                            }
                            //----------------------------------------------------------------------------------------------------------------------------------



                            /*if(isDrawingTypeTrendline4){
                                console.log({YcordPriceStart, num:4})
                            }*/


                            let XcordEnd   = 0 - (drawingEditCursorOutlierLimit * 5)
                            let XcordStart = 0 - (drawingEditCursorOutlierLimit * 5)


                            if(isDrawingStartToEndDatetimeAwayBeyondChartReach){
                                //console.log('Hello am beyond... : ', drawingTypeObj.drawingType)
                                XcordStart = 0                //- (drawingEditCursorOutlierLimit * 2)
                                XcordEnd   = canvasChartWidth //+ (drawingEditCursorOutlierLimit * 2)

                                if(
                                  isYmagnificationActivated &&
                                  allDrawingsDatetimeXcordObj[data[0].datetime] &&
                                  allDrawingsDatetimeXcordObj[data[data.length - 1].datetime]
                                ){
                                  XcordStart = allDrawingsDatetimeXcordObj[data[0].datetime]
                                  XcordEnd   = allDrawingsDatetimeXcordObj[data[data.length - 1].datetime]
                                }
                            }
                            else if(isONLYdrawingDatetimeEndAwayBeyondChartShiftedCandlesReach){
                                XcordStart = 0                //- (drawingEditCursorOutlierLimit * 2)
                                XcordEnd   = canvasChartWidth //+ (drawingEditCursorOutlierLimit * 2)

                                if(
                                  isYmagnificationActivated===false &&
                                  allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart]//datetimeStart is suppose to be inside the chart datetime cord
                                ){
                                  XcordStart = allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart]
                                  XcordEnd   = canvasChartWidth//as datetimeEnd outside data[len-1].datetime - we cord to canvasChartWidth
                                }
                                else if(
                                  isYmagnificationActivated &&
                                  allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart] &&
                                  allDrawingsDatetimeXcordObj[data[data.length - 1].datetime]
                                ){
                                  XcordStart = allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart]
                                  XcordEnd   = allDrawingsDatetimeXcordObj[data[data.length - 1].datetime]
                                }
                                
                            }
                            else if(isDrawingTypeTrendline5){
                                XcordStart = allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart]
                                XcordEnd   = allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart]
                            }
                            else if(isDrawingTypeTrendline4){
                                XcordStart = 0                //- (drawingEditCursorOutlierLimit * 5)
                                XcordEnd   = canvasChartWidth //+ (drawingEditCursorOutlierLimit * 5)
                            }
                            else if(isDrawingTypeTrendline3 && isTrendline3DatetimeAncient===false){
                                XcordStart = allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart]
                                XcordEnd   = canvasChartWidth 
                            }
                            else if(isTrendline3DatetimeAncient || isTrendline7DatetimeAncientAndFuture){
                                XcordStart = 0                //- (drawingEditCursorOutlierLimit * 2)
                                XcordEnd   = canvasChartWidth //+ (drawingEditCursorOutlierLimit * 2)
                            }
                            else if(//startCord1 - Auth to make sure we draw from the start or end point of click we are dragging & editing from
                                (
                                    (
                                    isMouseDown &&
                                    isEditingDrawing &&
                                    //crossHairMidStartXcordDrawerInfo.drawingOnDragEditCount>=0 &&
                                    (crossHairMidStartXcordDrawerInfo.isDrawFromStartCount || crossHairMidStartXcordDrawerInfo.isDrawFromMidCount ))
                                )
                                &&
                                allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart]
                            ){//essential to Auth the datetimeStart first before datetimeEnd due to the candles used as white margins at the end of chart
                                XcordStart = allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart]
                                XcordEnd   = XcordStart + (candleBoxWidthCount /* tfCandleRatioCount*/ * candleWidthSize)
                                //console.log("candleWidthSize-2 : ", candleWidthSize)
                                //console.log('drawing from startXcord--1')
                            }
                            else if(//endCord1 - Auth to make sure we draw from the start or end point of click we are dragging & editing from
                                (
                                    (
                                    isMouseDown &&
                                    isEditingDrawing &&
                                    //crossHairMidStartXcordDrawerInfo.drawingOnDragEditCount>=0 &&
                                    (crossHairMidStartXcordDrawerInfo.isDrawFromEndCount || crossHairMidStartXcordDrawerInfo.isDrawFromMidCount ))
                                )
                                &&
                                allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd]
                            ){
                                XcordEnd   = allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd]
                                XcordStart = XcordEnd - (candleBoxWidthCount /* tfCandleRatioCount*/ * candleWidthSize)
                                //console.log("candleWidthSize-3 : ", candleWidthSize)
                                //console.log('drawing from endXcord--1')
                            }
                            else if(//startCord2
                                allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart]
                            ){//essential to Auth the datetimeStart first before datetimeEnd due to the candles used as white margins at the end of chart
                                XcordStart = allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart]
                                XcordEnd   = XcordStart + (candleBoxWidthCount /* tfCandleRatioCount*/ * candleWidthSize)
                                //console.log("candleWidthSize-2 : ", candleWidthSize)
                                //console.log('drawing from startXcord--2')
                            }
                            else if(//endCord2
                                allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd]
                            ){
                                XcordEnd   = allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd]
                                XcordStart = XcordEnd - (candleBoxWidthCount /* tfCandleRatioCount*/ * candleWidthSize)
                                //console.log("candleWidthSize-3 : ", candleWidthSize)
                                //console.log('drawing from endXcord--2')
                            }
                            //techiman
                            
                            /*console.log({
                                XcordStart,
                                XcordEnd,
                                YcordPriceStart,
                                YcordPriceEnd,
                                tfCandleRatioCount
                            })*/


                            let sellRiskRewardXcord   = 0
                            let sellRiskRewardYcord   = 0
                            let buyRiskRewardXcord    = 0
                            let buyRiskRewardYcord    = 0
                            let riskRewardUpperHeight = 0
                            let riskRewardLowerHeight = 0

                            
                            if(isDrawingTypeTrendline7){

                                if(drawingTypeObj.isRectSquareDrawingSell){

                                    const rectSquareUpperHeightPrice =  drawingTypeObj.rectSquareUpperHeightPrice
                                    const rectSquareLowerHeightPrice =  drawingTypeObj.rectSquareLowerHeightPrice

                                    //const defaultRectSquareDrawingHeight = canvasChartHeight * defaultRectSquareDrawingAtChartHeightRatio

                                    let rectSquareUpperHeightPriceYcord = ((canvasChartHeight * (rectSquareUpperHeightPrice - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) 
                                    rectSquareUpperHeightPriceYcord = ((canvasChartHeight - rectSquareUpperHeightPriceYcord) + YchartShiftPX) + chartYvelocity 

                                    let rectSquareLowerHeightPriceYcord = ((canvasChartHeight * (rectSquareLowerHeightPrice - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) 
                                    rectSquareLowerHeightPriceYcord = ((canvasChartHeight - rectSquareLowerHeightPriceYcord) + YchartShiftPX) + chartYvelocity 

                                    /*console.log({
                                        rectSquareUpperHeightPrice,
                                        rectSquareLowerHeightPrice,
                                        rectSquareUpperHeightPriceYcord,
                                        rectSquareLowerHeightPriceYcord,
                                        YcordPriceStart,
                                    })*/


                                    //rect(x, y, w, h)
                                    //rect(x1, y1, w1, h1)
                                    //rect(x2, y2, w2, h2)
                                    const x1 = isDTendGTdtStart===false ? crossHairOnMoveCordVals.x : XcordStart
                                    const y1 = rectSquareUpperHeightPriceYcord
                                    const w1 = Math.abs(XcordEnd - XcordStart)
                                    const h1 = Math.abs(rectSquareUpperHeightPriceYcord - YcordPriceStart)

                                    const x2 = isDTendGTdtStart===false ? crossHairOnMoveCordVals.x : XcordStart
                                    const y2 = YcordPriceStart
                                    const w2 = Math.abs(XcordEnd - XcordStart)
                                    const h2 = Math.abs(rectSquareLowerHeightPriceYcord - YcordPriceStart)
                                    //ddgd

                                    context.fillStyle = "rgba(255, 0, 0, 0.4)";
                                    context.fillRect(
                                        x1, //x1
                                        y1, //y1
                                        w1, //w1
                                        h1//h1
                                    );

                                    context.fillStyle = "rgba(0, 255, 0, 0.4)";
                                    context.fillRect(
                                        x2, //x2
                                        y2 , //y2
                                        w2, //w2
                                        h2 //h2
                                    );


                                    sellRiskRewardXcord = x2 + (w2*0.5)
                                    sellRiskRewardYcord = y2 + (h2*0.5)

                                    riskRewardUpperHeight = h1
                                    riskRewardLowerHeight = h2


                                    //context.setLineDash([5, 5]);
                                    // Draw horizontal Price Line
                                    context.beginPath(); // reset the context state
                                    context.strokeStyle = "blue"; // color of the line
                                    context.lineWidth = 0.5; // thickness of the line
                                    context.moveTo(isDTendGTdtStart===false ? crossHairOnMoveCordVals.x : XcordStart, YcordPriceStart); // moveTo(x,y) -> starting point of the line
                                    context.lineTo(isDTendGTdtStart===false ? crossHairOnMoveCordVals.x + w1 : XcordEnd, YcordPriceEnd); // line(x,y) -> end point of the line
                                    context.stroke(); // draws the line
                                
                                    //context.setLineDash([]);

                                    if(//make a vertical line of width 2px - when Trendline7 is invincible - due timeframe datetime diminishing difference
                                        x1 === x1+w1
                                    ){
                                        context.beginPath(); // reset the context state
                                        context.strokeStyle = "blue"; // color of the line
                                        context.lineWidth = 2; // thickness of the line
                                        context.moveTo(x1, y1); // moveTo(x,y) -> starting point of the line
                                        context.lineTo(x1, y2+h2); // line(x,y) -> end point of the line
                                        context.stroke(); // draws the line
                                    }
                                }
                                else{
                                    const rectSquareUpperHeightPrice =  drawingTypeObj.rectSquareUpperHeightPrice
                                    const rectSquareLowerHeightPrice =  drawingTypeObj.rectSquareLowerHeightPrice

                                    //const defaultRectSquareDrawingHeight = canvasChartHeight * defaultRectSquareDrawingAtChartHeightRatio

                                    let rectSquareUpperHeightPriceYcord = ((canvasChartHeight * (rectSquareUpperHeightPrice - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) 
                                    rectSquareUpperHeightPriceYcord = ((canvasChartHeight - rectSquareUpperHeightPriceYcord) + YchartShiftPX) + chartYvelocity 

                                    let rectSquareLowerHeightPriceYcord = ((canvasChartHeight * (rectSquareLowerHeightPrice - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) 
                                    rectSquareLowerHeightPriceYcord = ((canvasChartHeight - rectSquareLowerHeightPriceYcord) + YchartShiftPX) + chartYvelocity 

                                    /*console.log({
                                        rectSquareUpperHeightPrice,
                                        rectSquareLowerHeightPrice,
                                        rectSquareUpperHeightPriceYcord,
                                        rectSquareLowerHeightPriceYcord,
                                        YcordPriceStart,
                                    })*/

                                    //rect(x, y, w, h)
                                    //rect(x1, y1, w1, h1)
                                    //rect(x2, y2, w2, h2)
                                    const x1 = isDTendGTdtStart===false ? crossHairOnMoveCordVals.x : XcordStart
                                    const y1 = rectSquareUpperHeightPriceYcord
                                    const w1 = Math.abs(XcordEnd - XcordStart)
                                    const h1 = Math.abs(rectSquareUpperHeightPriceYcord - YcordPriceStart)

                                    const x2 = isDTendGTdtStart===false ? crossHairOnMoveCordVals.x : XcordStart
                                    const y2 = YcordPriceStart
                                    const w2 = Math.abs(XcordEnd - XcordStart)
                                    const h2 = Math.abs(rectSquareLowerHeightPriceYcord - YcordPriceStart)

                                    context.fillStyle = "rgba(0, 255, 0, 0.4)";
                                    context.fillRect(
                                        x1, //x1
                                        y1, //y1
                                        w1, //w1
                                        h1  //h1
                                    );

                                    context.fillStyle = "rgba(255, 0, 0, 0.4)";
                                    context.fillRect(
                                        x2, //x2
                                        y2, //y2
                                        w2, //w2
                                        h2  //h2
                                    );

                                    buyRiskRewardXcord = x1 + (w1*0.5)
                                    buyRiskRewardYcord = y1 + (h1*0.5)

                                    riskRewardUpperHeight = h1
                                    riskRewardLowerHeight = h2


                                    //context.setLineDash([5, 5]);
                                    // Draw horizontal Price Line
                                    context.beginPath(); // reset the context state
                                    context.strokeStyle = "blue"; // color of the line
                                    context.lineWidth = 0.5; // thickness of the line
                                    context.moveTo(isDTendGTdtStart===false ? crossHairOnMoveCordVals.x : XcordStart, YcordPriceStart); // moveTo(x,y) -> starting point of the line
                                    context.lineTo(isDTendGTdtStart===false ? crossHairOnMoveCordVals.x + w1 : XcordEnd, YcordPriceEnd); // line(x,y) -> end point of the line
                                    context.stroke(); // draws the line

                                    //context.setLineDash([]);


                                    if(//make a vertical line of width 2px - when Trendline7 is invincible - due timeframe datetime diminishing difference
                                        x1 === x1+w1
                                    ){
                                        context.beginPath(); // reset the context state
                                        context.strokeStyle = "blue"; // color of the line
                                        context.lineWidth = 2; // thickness of the line
                                        context.moveTo(x1, y1); // moveTo(x,y) -> starting point of the line
                                        context.lineTo(x1, y2+h2); // line(x,y) -> end point of the line
                                        context.stroke(); // draws the line
                                    }
                                }
                            }
                            else if(isDrawingTypeTrendline6){

                                //if(isDrawingTypeTrendline6 /*&& drawingTypeObj.isRectangleTextInput*/){//isRectangleTextInput
                                if(drawingTypeObj.isRectDrawingColored){
                                    
                                    context.lineWidth =  XcordStart === (XcordStart+(XcordEnd - XcordStart)) ? 5 : 0.5;
                                    //rect(x,y,w,h)
                                    context.strokeStyle = "blue";
                                    context.fillStyle = "rgba(235, 212, 239, 0.5)";
                                    context.rect(
                                        XcordStart, //x
                                        YcordPriceStart, //y
                                        (XcordEnd - XcordStart), //w
                                        (YcordPriceEnd - YcordPriceStart)  //h
                                    );
                                    context.stroke();
                                    context.fill();

                                }
                                else if(
                                    (
                                        selectedDrawingForEditInfoIdObj.id===id ||
                                        cursorAndDrawingEditIdObj.id === id 
                                    ) 
                                    ||
                                    drawingTypeObj.isRectangleTextInput===false 
                                ){
                                    //x1 === x1+w1
                                    context.lineWidth = XcordStart === (XcordStart+(XcordEnd - XcordStart)) ? 5 : 1;
                                    context.strokeStyle = "blue";   
                                    //rect(x,y,w,h)
                                    context.strokeRect(
                                        XcordStart, //x
                                        YcordPriceStart, //y
                                        (XcordEnd - XcordStart), //w
                                        (YcordPriceEnd - YcordPriceStart)  //h
                                    );

                                }

                                //console.log({cursorAndDrawingEditIdObj})


                              
                                const x = XcordStart //x
                                const y = YcordPriceStart //y
                                const w = Math.abs(XcordEnd - XcordStart) //w
                                const h = Math.abs(YcordPriceEnd - YcordPriceStart)  //h
                                //console.log('rect-text')

                                let XcordNew = XcordStart + (Math.abs(XcordEnd - XcordStart) * 0.5)
                                XcordNew = XcordNew <= 0 ? 40 : XcordNew 

                                const textSize = 11

                                context.fillStyle = "blue";  
                                context.font = `${textSize}px Verdana`;
                                // context.fillText(text, xCoord, yCoord, maxWidth);
                                context.textAlign = "center";
                                //context.textBaseline = "center";
                                context.fillText(
                                drawingTypeObj.text, 
                                isDrawingStartToEndDatetimeAwayBeyondChartReach ? 0 + (Math.abs(canvasChartWidth - 0 /*- priceLabelMarginSize*/) * 0.5) :
                                isDTendGTdtStart ? XcordNew : 
                                XcordEnd + (Math.abs(XcordEnd - XcordStart) * 0.5),
                                (YcordPriceEnd > YcordPriceStart) ? YcordPriceStart + (h * 0.5) + (textSize*0.2) : YcordPriceStart - (h * 0.5) + (textSize*0.2)
                                );

                            }
                            else if(isDrawingTypeTrendline8){

                                const isXcordSame = allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart] !== undefined && allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd] !== undefined
                                && (allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart] === allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd])
                                const YcordDiff = Math.abs(YcordPriceStart - YcordPriceEnd)

                                let hypotenuse = 0
                                if(isXcordSame===false){
                                    const opposite = Math.abs(YcordPriceEnd - YcordPriceStart)
                                    const adjacent = Math.abs(XcordEnd - XcordStart)
                                    const angle      = pythagorasCalculator({calcType : 'tan', varToCalc : 'aa', opposite, adjacent})
                                    hypotenuse = pythagorasCalculator({calcType : 'sin', varToCalc : 'h', opposite, angle})
                                }

                                //--------------------------------------------------------------------------------------------------------------------------------
                                let pointerXcordEnd           = XcordEnd
                                let pointerYcordPriceEnd      = YcordPriceEnd 

                                let nonPointerXcordStart      = XcordStart
                                let nonPointerYcordPriceStart = YcordPriceStart  

                                if(isMouseDown && isDrawingIdEditSelected && isEditingDrawingForward===false){
                                    pointerXcordEnd           = isDrawingForward ? XcordEnd : XcordStart      
                                    pointerYcordPriceEnd      = isDrawingForward ? YcordPriceEnd : YcordPriceStart  

                                    nonPointerXcordStart      = isDrawingForward ? XcordStart : XcordEnd      
                                    nonPointerYcordPriceStart = isDrawingForward ? YcordPriceStart : YcordPriceEnd   
                                }
                                else{
                                    pointerXcordEnd           = isDrawingForward ? XcordStart : XcordEnd      
                                    pointerYcordPriceEnd      = isDrawingForward ? YcordPriceStart : YcordPriceEnd 

                                    nonPointerXcordStart      = isDrawingForward ? XcordEnd : XcordStart
                                    nonPointerYcordPriceStart = isDrawingForward ? YcordPriceEnd : YcordPriceStart 
                                }
                                //--------------------------------------------------------------------------------------------------------------------------------

                                const radian = Math.PI / 180;

                                context.beginPath();
                                context.strokeStyle = "blue";
                                context.lineWidth = 1;
                                context.fillStyle = "rgba(255, 165, 0, 0.3)";
                                context.arc(
                                    pointerXcordEnd,//isDrawingForward ? XcordStart : XcordEnd, 
                                    pointerYcordPriceEnd,//isDrawingForward ? YcordPriceStart : YcordPriceEnd, 
                                    isXcordSame ? YcordDiff : hypotenuse,//10, 
                                    0 * radian, 360 * radian, false);
                                context.stroke();
                                context.fill();


                                context.fillStyle = "blue";  
                                context.font = "12px Verdana";
                                // context.fillText(text, xCoord, yCoord, maxWidth);
                                context.textAlign = "center";
                                //context.textBaseline = "middle";
                                context.fillText(drawingTypeObj.text,
                                //isDrawingTypeTrendline4 ? 0 + (Math.abs(canvasChartWidth - 0 /*- priceLabelMarginSize*/) * 0.5) :
                                XcordStart ,//+ drawingEditCursorOutlierLimit,
                                YcordPriceStart + (drawingEditCursorOutlierLimit*0.5)
                                );


                            }
                            else{

                               /*const YcordDiff = Math.abs(YcordPriceStart - YcordPriceEnd)
                                const isDiffOfYcordInsignificant = YcordDiff //< 
                                console.log('isDiffOfYcordInsignificant : ', isDiffOfYcordInsignificant)*/

                                if(
                                    true
                                ){
                                    if(
                                      isDrawingTypeTrendline5 && 
                                      drawingTypeObj.isGlobalLockedVertiLine && 
                                      allCANDLESdatetimeDATAinfoObj[drawingTypeObj.datetimeStart]
                                    ){
                                      //get wick data - to prevent lockedVertiLine from being drawn through
                                      //allCANDLESdatetimeDATAinfoObj[datetime] = {candleWickHighYcord, candleWickLowYcord}
                                      const {candleWickHighYcord, candleWickLowYcord} = allCANDLESdatetimeDATAinfoObj[drawingTypeObj.datetimeStart] ? 
                                      allCANDLESdatetimeDATAinfoObj[drawingTypeObj.datetimeStart] : {candleWickHighYcord:undefined, candleWickLowYcord:undefined}
                                      const wickMarginerPX = 5

                                      //context.setLineDash([5, 5]);
                                      context.beginPath(); // reset the context state
                                      context.strokeStyle = 'red'; // color of the line
                                      context.lineWidth = 2; // thickness of the line
                                      context.moveTo(XcordStart, YcordPriceStart); // moveTo(x,y) -> starting point of the line
                                      context.lineTo(XcordEnd, candleWickHighYcord ? candleWickHighYcord - wickMarginerPX : YcordPriceEnd); // line(x,y) -> end point of the line
                                      context.stroke(); // draws the line
                                      //context.setLineDash([]);

                                      //context.setLineDash([5, 5]);
                                      context.beginPath(); // reset the context state
                                      context.strokeStyle = 'red'; // color of the line
                                      context.lineWidth = 2; // thickness of the line
                                      context.moveTo(XcordStart, candleWickLowYcord ? candleWickLowYcord + wickMarginerPX : YcordPriceStart); // moveTo(x,y) -> starting point of the line
                                      context.lineTo(XcordEnd, YcordPriceEnd); // line(x,y) -> end point of the line
                                      context.stroke(); // draws the line
                                      //context.setLineDash([]);
                                      
                                    }
                                    else if(
                                      isDrawingTypeTrendline5 &&
                                      drawingTypeObj.isReplayLineDrawing===false &&
                                      drawingTypeObj.isGlobalLockedVertiLine===false
                                    ){
                                      //get wick data - to prevent lockedVertiLine from being drawn through
                                      //allCANDLESdatetimeDATAinfoObj[datetime] = {candleWickHighYcord, candleWickLowYcord}
                                      const {candleWickHighYcord, candleWickLowYcord} =  allCANDLESdatetimeDATAinfoObj[drawingTypeObj.datetimeStart] ?
                                       allCANDLESdatetimeDATAinfoObj[drawingTypeObj.datetimeStart] : {candleWickHighYcord:undefined, candleWickLowYcord:undefined}
                                      const wickMarginerPX = 5

                                      //context.setLineDash([7, 7]);
                                      context.beginPath(); // reset the context state
                                      context.strokeStyle = 'blue'; // color of the line
                                      context.lineWidth = 0.8; // thickness of the line
                                      context.moveTo(XcordStart, YcordPriceStart); // moveTo(x,y) -> starting point of the line
                                      context.lineTo(XcordEnd, candleWickHighYcord ? candleWickHighYcord - wickMarginerPX : YcordPriceEnd); // line(x,y) -> end point of the line
                                      context.stroke(); // draws the line
                                      //context.setLineDash([]);

                                      //context.setLineDash([10, 10]);
                                      context.beginPath(); // reset the context state
                                      context.strokeStyle = 'blue'; // color of the line
                                      context.lineWidth = 0.8; //thickness of the line
                                      context.moveTo(XcordStart, candleWickLowYcord ? candleWickLowYcord + wickMarginerPX : YcordPriceStart); // moveTo(x,y) -> starting point of the line
                                      context.lineTo(XcordEnd, YcordPriceEnd); // line(x,y) -> end point of the line
                                      context.stroke(); // draws the line
                                      //context.setLineDash([]);
                                    }
                                    else{//if an above Auth error pass this else code
                                      if(false && isDrawingTypeTrendline5 && drawingTypeObj.isGlobalLockedVertiLine){
                                        context.setLineDash([7, 7]);
                                      }
                                      // Draw horizontal Price Line
                                      context.beginPath(); // reset the context state
                                      context.strokeStyle = isDrawingTypeTrendline5 && drawingTypeObj.isReplayLineDrawing ? 'green' : isDrawingTypeTrendline5 && drawingTypeObj.isGlobalLockedVertiLine ? 'red' : "blue"; // color of the line
                                      context.lineWidth = //isMouseDown && selectedDrawingForEditInfoIdObj.id===id ? 0 : 
                                      isDrawingTypeTrendline5 && (drawingTypeObj.isReplayLineDrawing || drawingTypeObj.isGlobalLockedVertiLine) ? 2 : 0.8; // thickness of the line
                                      context.moveTo(isDrawingTypeTrendline5 && drawingTypeObj.isReplayLineDrawing && isMouseDown && selectedDrawingForEditInfoIdObj.id===id ? -500 : XcordStart, YcordPriceStart); // moveTo(x,y) -> starting point of the line
                                      context.lineTo(isDrawingTypeTrendline5 && drawingTypeObj.isReplayLineDrawing && isMouseDown && selectedDrawingForEditInfoIdObj.id===id ? -500 : XcordEnd, YcordPriceEnd); // line(x,y) -> end point of the line
                                      context.stroke(); // draws the line
                                      //context.setLineDash([]);
                                      if(false && isDrawingTypeTrendline5 && drawingTypeObj.isGlobalLockedVertiLine){
                                        context.setLineDash([]);
                                      }
                                    }
                                }


                                if(isMouseDown && selectedDrawingForEditInfoIdObj.isReplayLineDrawing){
                                    //do nothing
                                }
                                else if(isDrawingTypeTrendline5 && drawingTypeObj.isReplayLineDrawing ){
                                    const trendlineXstartCord = XcordStart
                                    //context.setLineDash([5, 5]);
                                    // Draw horizontal Price Line
                                    context.beginPath(); // reset the context state
                                    context.strokeStyle =  'green' 
                                    context.lineWidth =  2 
                                    context.moveTo(trendlineXstartCord + drawingEditCursorOutlierLimit, (canvasChartHeight*0.5) - (canvasChartHeight*0.25));
                                    context.lineTo(trendlineXstartCord + drawingEditCursorOutlierLimit, ((canvasChartHeight*0.5) - (canvasChartHeight*0.25))+(drawingEditCursorOutlierLimit*2)); 
                                    context.lineTo(trendlineXstartCord + (drawingEditCursorOutlierLimit*2.5), ((canvasChartHeight*0.5) - (canvasChartHeight*0.25))+(drawingEditCursorOutlierLimit*2*0.5));
                                    context.lineTo(trendlineXstartCord + drawingEditCursorOutlierLimit, (canvasChartHeight*0.5) - (canvasChartHeight*0.25)); 
                                    context.stroke(); // draws the line
                                    //context.setLineDash([]);
                                }
                                

                                //drawingTypeObj.text!==""
                                if(isDrawingTypeTrendline2){

                                    let XcordNew = XcordStart + (Math.abs(XcordEnd - XcordStart) * 0.5)
                                    XcordNew = XcordNew <= 0 ? 40 : XcordNew 

                                    context.fillStyle = "blue";  
                                    context.font = "11px Verdana";
                                    // context.fillText(text, xCoord, yCoord, maxWidth);
                                    context.textAlign = "center";
                                    //context.textBaseline = "center";
                                    context.fillText(
                                    drawingTypeObj.text, 
                                    isDrawingStartToEndDatetimeAwayBeyondChartReach ? 0 + (Math.abs(canvasChartWidth - 0 /*- priceLabelMarginSize*/) * 0.5) :
                                    isDTendGTdtStart ? XcordNew : 
                                    XcordEnd + (Math.abs(XcordEnd - XcordStart) * 0.5),
                                    YcordPriceStart - 8
                                    );
                                }
                                else if(
                                    isDrawingTypeTrendline5
                                ){

                                    let replayMODE_onMouseDOWN_replay_current_DATETIME = ''
                                    let isReplayOnMouseDownDrag = false
                                    if(
                                      drawingTypeObj.isReplayLineDrawing &&
                                      isMouseDown &&
                                      selectedDrawingForEditInfoIdObj &&
                                      selectedDrawingForEditInfoIdObj.isSelected &&
                                      selectedDrawingForEditInfoIdObj.isReplayLineDrawing &&
                                      selectedDrawingForEditInfoIdObj.id === drawingTypeObj.id &&
                                      allDATAdatetimeINDEXinArrayObj[datetimeStart] &&
                                      allDATAdatetimeINDEXinArrayObj[datetimeStart] > 0 
                                    ){
                                      //console.log('yeah... we on REPLAY mode')
                                      const currentDATETIMEid  = allDATAdatetimeINDEXinArrayObj[datetimeStart]
                                      const prevDATETIMEstring = data[currentDATETIMEid - 1].datetime ? data[currentDATETIMEid - 1].datetime : datetimeStart
                                      replayMODE_onMouseDOWN_replay_current_DATETIME = prevDATETIMEstring
                                      //console.log({prevDATETIMEstring})
                                      isReplayOnMouseDownDrag = true
                                    }

                                    //timeZoneDatetimeConverter - NOTE: modify datetime TIMEzone only for view on screen purposes - but not modify/update any state/variable
                                    const t_zone_TIME_convert = timeZoneDatetimeConverter(
                                        //datetime,//datetime
                                        isReplayOnMouseDownDrag ? replayMODE_onMouseDOWN_replay_current_DATETIME : datetimeStart,
                                        localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                                        zero_gmt_deviation
                                    )

                                    //Draw vertical datetimeText & centered
                                    //text with 2 attrs, bottom & centered
                                    context.fillStyle = drawingTypeObj.isReplayLineDrawing ? 'green' : drawingTypeObj.isGlobalLockedVertiLine ? 'red' : "blue";  
                                    context.font = `${(drawingTypeObj.isReplayLineDrawing || drawingTypeObj.isGlobalLockedVertiLine) ? 13 : 11}px Verdana`;
                                    // context.fillText(text, xCoord, yCoord, maxWidth);
                                    context.textAlign = "center";
                                    //context.textBaseline = "center";
                                    context.fillText(
                                    //isReplayOnMouseDownDrag ? replayMODE_onMouseDOWN_replay_current_DATETIME : datetimeStart, 
                                    t_zone_TIME_convert,
                                    XcordStart < priceLabelMarginSize ? 80 : XcordStart,
                                    canvasChartHeight - 23-
                                    (isMouseDown && selectedDrawingForEditInfoIdObj.isSelected && selectedDrawingForEditInfoIdObj.id===id ? 0 : 13)//
                                    );
                                    //console.log({isSelected:selectedDrawingForEditInfoIdObj.isSelected, selectedDrawingForEditInfoIdObj})
                                    //console.log("priceLabelMarginSize : ", priceLabelMarginSize)



                                    const str = drawingTypeObj.text

                                    str.split(' ').map((string, stringIndex)=>{

                                        context.fillStyle = isDrawingTypeTrendline5 && drawingTypeObj.isReplayLineDrawing ? 'green' : isDrawingTypeTrendline5 && drawingTypeObj.isGlobalLockedVertiLine ? 'red' : "blue";  
                                        context.font = "11px Verdana";
                                        // context.fillText(text, xCoord, yCoord, maxWidth);
                                        context.textAlign = "start";//"center"
                                        //context.textBaseline = "center";
                                        context.fillText(
                                        string, 
                                        XcordStart < priceLabelMarginSize ? 80 : XcordStart+2,
                                        60 + (stringIndex*20)
                                        );

                                    })



                                }
                                else if(
                                    (isDrawingTypeTrendline3 || isDrawingTypeTrendline4)
                                ){

                                    /*if(isDrawingTypeTrendline4){
                                        console.log({YcordPriceStart, num:5})
                                    }*/

                                    const horiLineYprice = (((canvasChartHeight - YcordPriceStart) + YchartShiftPX + chartYvelocity) / 
                                    (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasChartHeight) + minPriceVal;

                                    /*if(isDrawingTypeTrendline4){
                                        console.log({horiLineYprice, num:6})
                                    }*/

                                    context.fillStyle = "blue";  
                                    context.font = "12px Verdana";
                                    context.textAlign = "start";
                                    //context.textBaseline = "middle";
                                    context.fillText(String(horiLineYprice), 
                                    canvasChartWidth - priceLabelMarginSize,
                                    YcordPriceStart-3//canvasChartHeight - (isPipCheckerActivated && isMouseDown ? pipCheckerCordStart.y : crossHairOnMoveCordVals.y + 3)
                                    );


                                    //Text -Print
                                    context.fillStyle = "blue";  
                                    context.font = "12px Verdana";
                                    context.textAlign = "center";
                                    //context.textBaseline = "middle";
                                    context.fillText(drawingTypeObj.text,
                                    isDrawingTypeTrendline4 ? 0 + (Math.abs(canvasChartWidth - 0 /*- priceLabelMarginSize*/) * 0.5) :
                                    XcordStart + (Math.abs(canvasChartWidth - XcordStart /*- priceLabelMarginSize*/) * 0.5),
                                    isDrawingTypeTrendline4 ? YcordPriceStart-13 : YcordPriceStart-8//canvasChartHeight - (isPipCheckerActivated && isMouseDown ? pipCheckerCordStart.y : crossHairOnMoveCordVals.y + 3)
                                    );

                                }
                                else if(
                                    drawingTypeObj.drawingType==='trendline1' &&
                                    drawingTypeObj.isTrendlineEndPointy
                                ){//techiman

                                    //isTrendlineEndPointy
                                    //isDrawingForward
                                    //const [pointyTrendlineDefaultInfo, setPointyTrendlineDefaultInfo] = useState({defaultPointerLength:20, defaultPointerAngle:45})
                                    const {
                                        defaultPointerLength
                                    } = pointyTrendlineDefaultInfo

                                    //const isDrawingForward = /*drawingTypeObj.*/isDrawingForward
                                    //isEditingDrawingForward && isDrawingIdEditSelected
                                    //const newIsDrawingForward = isDrawingIdEditSelected && isEditingDrawingForward===false ? !isDrawingForward : isDrawingForward
                                    //console.log({newIsDrawingForward})

                                    let pointerXcordEnd           = XcordEnd
                                    let pointerYcordPriceEnd      = YcordPriceEnd 

                                    let nonPointerXcordStart      = XcordStart
                                    let nonPointerYcordPriceStart = YcordPriceStart  

                                    if(isMouseDown && isDrawingIdEditSelected && isEditingDrawingForward===false){
                                        pointerXcordEnd           = isDrawingForward ? XcordStart      : XcordEnd
                                        pointerYcordPriceEnd      = isDrawingForward ? YcordPriceStart : YcordPriceEnd 

                                        nonPointerXcordStart      = isDrawingForward ? XcordEnd      : XcordStart
                                        nonPointerYcordPriceStart = isDrawingForward ? YcordPriceEnd : YcordPriceStart  
                                    }
                                    else{
                                        pointerXcordEnd           = isDrawingForward ? XcordEnd      : XcordStart
                                        pointerYcordPriceEnd      = isDrawingForward ? YcordPriceEnd : YcordPriceStart 

                                        nonPointerXcordStart      = isDrawingForward ? XcordStart      : XcordEnd
                                        nonPointerYcordPriceStart = isDrawingForward ? YcordPriceStart : YcordPriceEnd
                                    }

                                    //const is

                                    const opposite = Math.abs(YcordPriceEnd - YcordPriceStart)
                                    const adjacent = Math.abs(XcordEnd - XcordStart)
                                    const angle      = pythagorasCalculator({calcType : 'tan', varToCalc : 'aa', opposite, adjacent})
                                    const hypotenuse = pythagorasCalculator({calcType : 'sin', varToCalc : 'h', opposite, angle})

                                    //const newOpposite = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse, angle})
                                    //const newAdjacent = pythagorasCalculator({calcType : 'cos', varToCalc : 'a', hypotenuse, angle})

                                    const {
                                        pointerRightAngle45degreeDefaultXcordLeft,
                                        pointerRightAngle45degreeDefaultYcordLeft,
                                        pointerRightAngle45degreeDefaultXcordRight,
                                        pointerRightAngle45degreeDefaultYcordRight 
                                    } = calcLinePointerXYcord({
                                        isDrawingForward,// : isDrawingForward, 
                                        pointerXcordEnd, 
                                        pointerYcordPriceEnd, 
                                        nonPointerYcordPriceStart, 
                                        nonPointerXcordStart, 
                                        angle, 
                                        defaultPointerLength
                                    })

                                    


                                    // Draw Pointy Lines Line
                                    context.beginPath(); // reset the context state
                                    context.strokeStyle = "blue"; // color of the line
                                    context.lineWidth = 0.8; // thickness of the line
                                    context.moveTo(pointerXcordEnd, pointerYcordPriceEnd); // moveTo(x,y) -> starting point of the line
                                    context.lineTo(pointerRightAngle45degreeDefaultXcordLeft, pointerRightAngle45degreeDefaultYcordLeft); // line(x,y) -> end point of the line
                                    context.stroke(); // draws the line


                                    context.beginPath(); // reset the context state
                                    context.strokeStyle = "blue"; // color of the line
                                    context.lineWidth = 0.8; // thickness of the line
                                    context.moveTo(pointerXcordEnd, pointerYcordPriceEnd); // moveTo(x,y) -> starting point of the line
                                    context.lineTo(pointerRightAngle45degreeDefaultXcordRight, pointerRightAngle45degreeDefaultYcordRight); // line(x,y) -> end point of the line
                                    context.stroke(); // draws the line

                                    
                                }

                            
                                
                            }


                            //console.log({drawingTypeObj})

                            //Calc for drawingEditCursorOutlierLimit by pythogoras angle
                            //if truthy draw circle or rectangle alerts at ends or mid for edit
                            //if truthy store & edit infos of drawing including its id
                            //start editing the Xcord(XcordStart, XcordEnd) from where dragging starts while keeping the other constant
                            //onMouseDown: update drawing data into local storage by loop by id
                            //keep on updating a given drawing by id we started the mouseDown with, until mouseUp

                            /*
                            GLOSSARY:
                            aa - angle
                            o  - opposite
                            a  - adjacent
                            h  - hypotenuse
                            */

                            //const paramObj = {calcType : 'sin', varToCalc : 'aa', adjacent : 5, opposite : 5, angle : 45, hypotenuse : 7.071067812}
                            //console.log("pythagorasCalculator res : ", pythagorasCalculator(paramObj))
                        
                            //opposite(y2 - y1)
                            //adjacent(x2 - x1)
                            const opposite = Math.abs(YcordPriceEnd - YcordPriceStart)
                            const adjacent = Math.abs(XcordEnd - XcordStart)

                            const angle      = pythagorasCalculator({calcType : 'tan', varToCalc : 'aa', opposite, adjacent})
                            const hypotenuse = pythagorasCalculator({calcType : 'sin', varToCalc : 'h', opposite, angle})

                            const trendlineXstartCord = XcordStart
                            const trendlineYstartCord = isDrawingTypeTrendline5 ? 0 /*- (drawingEditCursorOutlierLimit * 5)*/ : canvasChartHeight - YcordPriceStart

                            const trendlineXendCord = isDrawingTypeTrendline5 ? XcordStart : drawingTypeObj.drawingType === 'trendline3' ? canvasChartWidth : XcordEnd
                            const trendlineYendCord = isDrawingTypeTrendline5 ? canvasChartHeight /*+ (drawingEditCursorOutlierLimit * 5)*/ : canvasChartHeight - YcordPriceEnd

                            const crossHairXcord = crossHairOnMoveCordVals.x
                            const crossHairYcord = crossHairOnMoveCordVals.y
                            //console.log({victor: crossHairYcord})

                            /*console.log({
                                datetimeStart : drawingTypeObj.datetimeStart,
                                datetimeEnd : drawingTypeObj.datetimeEnd,
                                xCordStart : allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart],
                                xCordEnd : allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd]
                            })*/

                            const trendline5XstartCordDiff = isDrawingTypeTrendline5 ? Math.abs(trendlineXstartCord - crossHairXcord) : drawingEditCursorOutlierLimit * 2
                            const isTrendline5startInSelectionRange = (trendline5XstartCordDiff <= drawingEditCursorOutlierLimit) 

                            const trendline8XstartCordDiff = isDrawingTypeTrendline8 ? Math.abs(trendlineXstartCord - crossHairXcord) : drawingEditCursorOutlierLimit * 2
                            const isTrendline8startInSelectionRange = (trendline8XstartCordDiff <= drawingEditCursorOutlierLimit) &&
                            ((crossHairYcord >= trendlineYstartCord && crossHairYcord <= trendlineYendCord) || (crossHairYcord <= trendlineYstartCord && crossHairYcord >= trendlineYendCord))
                            /*console.log({
                                trendline8XstartCordDiff,
                                isTrendline8startInSelectionRange
                            })*/

                            const trendline1XstartCordDiff = isDrawingTypeTrendline1 ? Math.abs(trendlineXstartCord - crossHairXcord) : drawingEditCursorOutlierLimit * 2
                            const isTrendline1startInSelectionRange = (trendline1XstartCordDiff <= drawingEditCursorOutlierLimit) &&
                            ((crossHairYcord >= trendlineYstartCord && crossHairYcord <= trendlineYendCord) || crossHairYcord <= trendlineYstartCord && crossHairYcord >= trendlineYendCord)


                            //--------------------------------------------------------------------------------------------------------------------
                            //trendline1/2/3/4 midRangeSelection Auth vars
                            let trendlineXstartCordDiff = 0
                            let trendlineYstartCordDiff = 0

                            let trendlineXendCordDiff   = 0
                            let trendlineYendCordDiff   = 0

                            let isTrendlineStartInSelectionRange = false 
                            let isTrendlineEndInSelectionRange   = false

                            let isXcrossHairWithinX1X2 = false
                            let isYcrossHairWithinY1Y2 = false
                            let isCrossHairWithinDrawingSquareRange = false

                            //if trendline price opposite height difference less than the drawingEditCursorOutlierLimit
                            let isDrawingHeightLessThanCursorOutlierLimit = false
                            let isCrossHairBtnStartAndEndCordOfDrawing = false
                            let isShorterTLnotHighlightedAtStartAndEnd = false
                            let isShorterTLinMidRangeForHightlightEdit = false

                            let isShorterTLinMidRangeAuthTruthy = false
                            
                            let trendlineMidRangePX = isShorterTLinMidRangeAuthTruthy ? drawingEditCursorOutlierLimit : 100//100= imaginary number
                            //---------------------------------------------------------------------------------------------------------------------


                            if(isDrawingTypeTrendline5===false){
                                trendlineXstartCordDiff = Math.abs(trendlineXstartCord - crossHairXcord)//yesss
                                trendlineYstartCordDiff = Math.abs(trendlineYstartCord - crossHairYcord)

                                trendlineXendCordDiff   = Math.abs(trendlineXendCord - crossHairXcord)
                                trendlineYendCordDiff   = Math.abs(trendlineYendCord - crossHairYcord)

                                isTrendlineStartInSelectionRange = (trendlineXstartCordDiff <= drawingEditCursorOutlierLimit) && (trendlineYstartCordDiff <= drawingEditCursorOutlierLimit) 
                                isTrendlineEndInSelectionRange   = (trendlineXendCordDiff <= drawingEditCursorOutlierLimit) && (trendlineYendCordDiff <= drawingEditCursorOutlierLimit) 

                                isXcrossHairWithinX1X2 = (crossHairXcord >= trendlineXstartCord && crossHairXcord <= trendlineXendCord) //|| (crossHairXcord >= trendlineXendCord && crossHairXcord <= trendlineXstartCord)
                                isYcrossHairWithinY1Y2 = (crossHairYcord >= trendlineYstartCord && crossHairYcord <= trendlineYendCord) || (crossHairYcord >= trendlineYendCord && crossHairYcord <= trendlineYstartCord)
                                isCrossHairWithinDrawingSquareRange = isXcrossHairWithinX1X2 && isYcrossHairWithinY1Y2

                                //if trendline price opposite height difference less than the drawingEditCursorOutlierLimit
                                isDrawingHeightLessThanCursorOutlierLimit = opposite <= drawingEditCursorOutlierLimit
                                isCrossHairBtnStartAndEndCordOfDrawing = (crossHairXcord >= trendlineXstartCord) && (crossHairXcord <= trendlineXendCord)
                                isShorterTLnotHighlightedAtStartAndEnd = isTrendlineStartInSelectionRange===false && isTrendlineEndInSelectionRange===false
                                isShorterTLinMidRangeForHightlightEdit = (crossHairYcord <= (trendlineYstartCord + drawingEditCursorOutlierLimit)) && (crossHairYcord >= (trendlineYstartCord - drawingEditCursorOutlierLimit))

                                isShorterTLinMidRangeAuthTruthy = /*isDrawingHeightLessThanCursorOutlierLimit &&*/ isCrossHairBtnStartAndEndCordOfDrawing && isShorterTLinMidRangeForHightlightEdit && isShorterTLnotHighlightedAtStartAndEnd 
                                /*console.log({
                                    isDrawingHeightLessThanCursorOutlierLimit,
                                    isShorterTLnotHighlightedAtStartAndEnd, 
                                    isShorterTLinMidRangeForHightlightEdit,
                                    crossHairYcord,
                                    a : trendlineYstartCord + drawingEditCursorOutlierLimit,
                                    b : trendlineYstartCord - drawingEditCursorOutlierLimit
                                    isCrossHairBtnStartAndEndCordOfDrawing
                                })*/

                                /*
                                (isDrawingHeightLessThanCursorOutlierLimit===false &&
                                            isCrossHairWithinDrawingSquareRange &&
                                        trendlineMidRangePX <= drawingEditCursorOutlierLimit)
                                */

                                trendlineMidRangePX = isShorterTLinMidRangeAuthTruthy ? drawingEditCursorOutlierLimit : 100//100= imaginary number
                            }

                            

                            if(isDrawingTypeTrendline1 && allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart] !== undefined && allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd] !== undefined
                                && (allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart] === allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd])
                            ){
                                //console.log('hello badu')
                                trendlineMidRangePX = trendline1XstartCordDiff
                            }
                            else if(isDrawingTypeTrendline8 && allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart] !== undefined && allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd] !== undefined
                                && (allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeStart] === allDrawingsDatetimeXcordObj[drawingTypeObj.datetimeEnd])
                            ){
                                //console.log(true)
                                trendlineMidRangePX = trendline8XstartCordDiff
                            }
                            else if(isDrawingTypeTrendline5){
                                trendlineMidRangePX = trendline5XstartCordDiff
                            }
                            else if(
                                isDrawingHeightLessThanCursorOutlierLimit===false &&
                                isCrossHairWithinDrawingSquareRange
                            ){

                                if(
                                    trendlineYstartCord < trendlineYendCord//y1 < y2
                                ){
                                    //console.log('y1 < y2')

                                    const mini_adjacent = Math.abs(crossHairXcord - trendlineXstartCord)
                                    const mini_opposite = Math.abs(crossHairYcord - trendlineYstartCord)

                                    const mini_angle      = pythagorasCalculator({calcType : 'tan', varToCalc : 'aa', opposite : mini_opposite, adjacent : mini_adjacent})
                                    const mini_hypotenuse = pythagorasCalculator({calcType : 'sin', varToCalc : 'h', opposite : mini_opposite, angle : mini_angle})

                                    const angleDiff = Math.abs(angle - mini_angle)

                                    if(mini_angle > angle){
                                        trendlineMidRangePX   = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse : mini_hypotenuse, angle : angleDiff})
                                    }
                                    else if(mini_angle <= angle){
                                        const mini_hypotenuse2 = pythagorasCalculator({calcType : 'cos', varToCalc : 'h', adjacent : mini_hypotenuse, angle : angleDiff})
                                        trendlineMidRangePX   = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse : mini_hypotenuse2, angle : angleDiff})
                                    }

                                    /*console.log('y1 > y2 : ', {
                                        //mini_adjacent, mini_opposite,
                                        //mini_hypotenuse,
                                        mini_angle,
                                        angle,
                                        angleDiff,
                                        trendlineMidRangePX
                                    })//truthy*/
                                }
                                else if(
                                    trendlineYstartCord > trendlineYendCord//y1 > y2
                                ){
                                    const mini_adjacent = Math.abs(crossHairXcord - trendlineXendCord)
                                    const mini_opposite = Math.abs(crossHairYcord - trendlineYendCord)

                                    const mini_angle      = pythagorasCalculator({calcType : 'tan', varToCalc : 'aa', opposite : mini_opposite, adjacent : mini_adjacent})
                                    const mini_hypotenuse = pythagorasCalculator({calcType : 'sin', varToCalc : 'h', opposite : mini_opposite, angle : mini_angle})

                                    const angleDiff = Math.abs(angle - mini_angle)

                                    if(mini_angle > angle){
                                        trendlineMidRangePX   = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse : mini_hypotenuse, angle : angleDiff})
                                    }
                                    else if(mini_angle <= angle){
                                        const mini_hypotenuse2 = pythagorasCalculator({calcType : 'cos', varToCalc : 'h', adjacent : mini_hypotenuse, angle : angleDiff})
                                        trendlineMidRangePX   = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse : mini_hypotenuse2, angle : angleDiff})
                                    }

                                    /*console.log('y1 > y2 : ', {
                                        //mini_adjacent, mini_opposite,
                                        //mini_hypotenuse,
                                        mini_angle,
                                        angle,
                                        angleDiff,
                                        trendlineMidRangePX
                                    })//truthy*/
                                }
                            }
                            else if(
                                isTrendlineStartInSelectionRange ||
                                isTrendlineEndInSelectionRange
                            ){
                                trendlineMidRangePX = 0
                            }

                            /*console.log({//drawingEditCursorOutlierLimit
                                //opposite,
                                //adjacent,
                                //angle,
                                //hypotenuse,
                                x1y1 : `${trendlineXstartCord}, ${trendlineYstartCord}`,
                                x2y2 : `${trendlineXendCord}, ${trendlineYendCord}`,
                                xy : `${crossHairXcord}, ${crossHairYcord}`,
                                //trendlineXendCordDiff,
                                //trendlineYendCordDiff,
                                //isTrendlineEndInSelectionRange
                                isCrossHairWithinDrawingSquareRange
                            })*/


                            /*
                            isXcrossHairWithinX1X2 = (crossHairXcord >= trendlineXstartCord && crossHairXcord <= trendlineXendCord) //|| (crossHairXcord >= trendlineXendCord && crossHairXcord <= trendlineXstartCord)
                            isYcrossHairWithinY1Y2 = (crossHairYcord >= trendlineYstartCord && crossHairYcord <= trendlineYendCord) || (crossHairYcord >= trendlineYendCord && crossHairYcord <= trendlineYstartCord)
                            isCrossHairWithinDrawingSquareRange = isXcrossHairWithinX1X2 && isYcrossHairWithinY1Y2
                            */

                            //const squareNavigatorMargin =  (drawingEditCursorOutlierLimit*1.8 * 0.5)
                            //const squareNavigatorWidthHeight = drawingEditCursorOutlierLimit*1.8

                            const drawing678EditCursorOutlierLimit = drawingEditCursorOutlierLimit * 1

                            let isCrossHairWithinCircleRange = false
                            let isCrossHair8InRoundEdgeRange = false
                            let isCrossHairWithinRectSquareRange = false

                            let isRectSquare7DrawingSell = false


                            //trendline6
                            let trendline6MidTopXcord    = 0
                            let trendline6MidTopYcord    = 0
                            let trendline6MidRightXcord  = 0
                            let trendline6MidRightYcord  = 0
                            let trendline6MidBottomXcord = 0
                            let trendline6MidBottomYcord = 0
                            let trendline6MidLeftXcord   = 0
                            let trendline6MidLeftYcord   = 0

                            let trendline6TopRightXcord      = 0
                            let trendline6TopRightYcord      = 0
                            let trendline6BottomRightXcord   = 0
                            let trendline6BottomRightYcord   = 0
                            let trendline6TopLeftXcord       = 0
                            let trendline6TopLeftYcord       = 0
                            let trendline6BottomLeftXcord    = 0
                            let trendline6BottomLeftYcord    = 0

                            let isCrossHair6WithinMidTopSquareRange       = false
                            let isCrossHair6WithinMidRightSquareRange     = false
                            let isCrossHair6WithinMidBottomSquareRange    = false
                            let isCrossHair6WithinMidLeftSquareRange      = false
                            let isCrossHair6WithinTopRightCircleRange     = false
                            let isCrossHair6WithinTopLeftCircleRange      = false
                            let isCrossHair6WithinBottomRightCircleRange  = false
                            let isCrossHair6WithinBottomLeftCircleRange   = false

                            let isCrossHair6InTopEdgeRange      = false
                            let isCrossHair6InTopEdgeRangeType2 = false
                            let isCrossHair6InRightEdgeRange    = false
                            let isCrossHair6InRightEdgeRangeType2 = false
                            let isCrossHair6InBottomEdgeRange = false
                            let isCrossHair6InBottomEdgeRangeType2 = false
                            let isCrossHair6InleftEdgeRange   = false
                            let isCrossHair6InLeftEdgeRangeType2   = false


                            //trendline7
                            let trendline7MidRightXcord  = 0
                            let trendline7MidRightYcord  = 0
                            let trendline7MidLeftXcord   = 0
                            let trendline7MidLeftYcord   = 0

                            let trendline7TopLeftXcord       = 0
                            let trendline7TopLeftYcord       = 0
                            let trendline7BottomLeftXcord    = 0
                            let trendline7BottomLeftYcord    = 0

                            let isCrossHair7WithinMidRightSquareRange     = false
                            let isCrossHair7WithinMidLeftCircleRange      = false
                            let isCrossHair7WithinTopLeftSquareRange      = false
                            let isCrossHair7WithinBottomLeftSquareRange   = false

                            let isCrossHair7InTopEdgeRange    = false
                            let isCrossHair7InRightEdgeRange  = false
                            let isCrossHair7InBottomEdgeRange = false
                            let isCrossHair7InleftEdgeRange   = false

                            let isCrossHair7InMidEdgeRange    = false

                            let isCrossHair7InTopEdgeRangeType2    = false
                            let isCrossHair7InRightEdgeRangeType2  = false
                            let isCrossHair7InBottomEdgeRangeType2 = false
                            let isCrossHair7InleftEdgeRangeType2   = false


                            if(isDrawingTypeTrendline6){

                                const isYcordPriceStartGreater = YcordPriceStart <= YcordPriceEnd

                                const x = XcordStart
                                const y = isYcordPriceStartGreater ? YcordPriceStart : YcordPriceEnd
                                const w = Math.abs(XcordEnd - XcordStart)
                                const h = Math.abs(YcordPriceEnd - YcordPriceStart)

                                trendline6MidTopXcord    = x + (w * 0.5) - squareNavigatorMargin
                                trendline6MidTopYcord    = y - squareNavigatorMargin
                                trendline6MidRightXcord  = x + (w) - squareNavigatorMargin
                                trendline6MidRightYcord  = y + (h * 0.5) - squareNavigatorMargin
                                trendline6MidBottomXcord = x + (w * 0.5) - squareNavigatorMargin
                                trendline6MidBottomYcord = y + (h) - squareNavigatorMargin
                                trendline6MidLeftXcord   = x - squareNavigatorMargin
                                trendline6MidLeftYcord   = y + (h * 0.5) - squareNavigatorMargin

                                trendline6TopRightXcord      = x + (w) //- (squareNavigatorMargin)
                                trendline6TopRightYcord      = y //- squareNavigatorMargin
                                trendline6BottomRightXcord   = x + (w) //- squareNavigatorMargin
                                trendline6BottomRightYcord   = y + (h) //- squareNavigatorMargin
                                trendline6TopLeftXcord       = x //- squareNavigatorMargin
                                trendline6TopLeftYcord       = y //- squareNavigatorMargin
                                trendline6BottomLeftXcord    = x //- squareNavigatorMargin
                                trendline6BottomLeftYcord    = y + (h) //- squareNavigatorMargin

                                //squareNavigatorWidthHeight - w/h

                                /*
                                isXcrossHairWithinX1X2 = (crossHairXcord >= trendlineXstartCord && crossHairXcord <= trendlineXendCord) //|| (crossHairXcord >= trendlineXendCord && crossHairXcord <= trendlineXstartCord)
                                isYcrossHairWithinY1Y2 = (crossHairYcord >= trendlineYstartCord && crossHairYcord <= trendlineYendCord) || (crossHairYcord >= trendlineYendCord && crossHairYcord <= trendlineYstartCord)
                                isCrossHairWithinDrawingSquareRange = isXcrossHairWithinX1X2 && isYcrossHairWithinY1Y2
                                */ 

                                isCrossHair6WithinMidTopSquareRange       = (
                                    crossHairXcord >= (trendline6MidTopXcord) && crossHairXcord <= (trendline6MidTopXcord + squareNavigatorWidthHeight)
                                ) && (
                                    (crossHairYcord >= ((canvasChartHeight - trendline6MidTopYcord)) && crossHairYcord <= ((canvasChartHeight - trendline6MidTopYcord) - squareNavigatorWidthHeight)) ||
                                    (crossHairYcord <= ((canvasChartHeight - trendline6MidTopYcord)) && crossHairYcord >= ((canvasChartHeight - trendline6MidTopYcord) - squareNavigatorWidthHeight))
                                )
                                isCrossHair6WithinMidRightSquareRange     = (
                                    crossHairXcord >= (trendline6MidRightXcord) && crossHairXcord <= (trendline6MidRightXcord + squareNavigatorWidthHeight)
                                ) && (
                                    (crossHairYcord >= ((canvasChartHeight - trendline6MidRightYcord)) && crossHairYcord <= ((canvasChartHeight - trendline6MidRightYcord) - squareNavigatorWidthHeight)) ||
                                    (crossHairYcord <= ((canvasChartHeight - trendline6MidRightYcord)) && crossHairYcord >= ((canvasChartHeight - trendline6MidRightYcord) - squareNavigatorWidthHeight))
                                )
                                isCrossHair6WithinMidBottomSquareRange    = (
                                    crossHairXcord >= (trendline6MidBottomXcord) && crossHairXcord <= (trendline6MidBottomXcord + squareNavigatorWidthHeight)
                                ) && (
                                    (crossHairYcord >= ((canvasChartHeight - trendline6MidBottomYcord)) && crossHairYcord <= ((canvasChartHeight - trendline6MidBottomYcord) - squareNavigatorWidthHeight)) ||
                                    (crossHairYcord <= ((canvasChartHeight - trendline6MidBottomYcord)) && crossHairYcord >= ((canvasChartHeight - trendline6MidBottomYcord) - squareNavigatorWidthHeight))
                                )
                                isCrossHair6WithinMidLeftSquareRange      = (
                                    crossHairXcord >= (trendline6MidLeftXcord) && crossHairXcord <= (trendline6MidLeftXcord + squareNavigatorWidthHeight)
                                ) && (
                                    (crossHairYcord >= ((canvasChartHeight - trendline6MidLeftYcord)) && crossHairYcord <= ((canvasChartHeight - trendline6MidLeftYcord) - squareNavigatorWidthHeight)) ||
                                    (crossHairYcord <= ((canvasChartHeight - trendline6MidLeftYcord)) && crossHairYcord >= ((canvasChartHeight - trendline6MidLeftYcord) - squareNavigatorWidthHeight))
                                )
                                isCrossHair6WithinTopRightCircleRange     = (
                                    crossHairXcord >= (trendline6MidRightXcord) && crossHairXcord <= (trendline6MidRightXcord + squareNavigatorWidthHeight)
                                ) && (
                                    (crossHairYcord >= ((canvasChartHeight - trendline6MidTopYcord)) && crossHairYcord <= ((canvasChartHeight - trendline6MidTopYcord) - squareNavigatorWidthHeight)) ||
                                    (crossHairYcord <= ((canvasChartHeight - trendline6MidTopYcord)) && crossHairYcord >= ((canvasChartHeight - trendline6MidTopYcord) - squareNavigatorWidthHeight))
                                )
                                isCrossHair6WithinTopLeftCircleRange      = (
                                    crossHairXcord >= (trendline6MidLeftXcord) && crossHairXcord <= (trendline6MidLeftXcord + squareNavigatorWidthHeight)
                                ) && (
                                    (crossHairYcord >= ((canvasChartHeight - trendline6MidTopYcord)) && crossHairYcord <= ((canvasChartHeight - trendline6MidTopYcord) - squareNavigatorWidthHeight)) ||
                                    (crossHairYcord <= ((canvasChartHeight - trendline6MidTopYcord)) && crossHairYcord >= ((canvasChartHeight - trendline6MidTopYcord) - squareNavigatorWidthHeight))
                                )
                                isCrossHair6WithinBottomRightCircleRange  = (
                                    crossHairXcord >= (trendline6MidRightXcord) && crossHairXcord <= (trendline6MidRightXcord + squareNavigatorWidthHeight)
                                ) && (
                                    (crossHairYcord >= ((canvasChartHeight - trendline6MidBottomYcord)) && crossHairYcord <= ((canvasChartHeight - trendline6MidBottomYcord) - squareNavigatorWidthHeight)) ||
                                    (crossHairYcord <= ((canvasChartHeight - trendline6MidBottomYcord)) && crossHairYcord >= ((canvasChartHeight - trendline6MidBottomYcord) - squareNavigatorWidthHeight))
                                )
                                isCrossHair6WithinBottomLeftCircleRange   = (
                                    crossHairXcord >= (trendline6MidLeftXcord) && crossHairXcord <= (trendline6MidLeftXcord + squareNavigatorWidthHeight)
                                ) && (
                                    (crossHairYcord >= ((canvasChartHeight - trendline6MidBottomYcord)) && crossHairYcord <= ((canvasChartHeight - trendline6MidBottomYcord) - squareNavigatorWidthHeight)) ||
                                    (crossHairYcord <= ((canvasChartHeight - trendline6MidBottomYcord)) && crossHairYcord >= ((canvasChartHeight - trendline6MidBottomYcord) - squareNavigatorWidthHeight))
                                )

                                
                                
                                //isCrossHair6InTopEdgeRange = 
                                isCrossHair6InTopEdgeRange = (crossHairXcord >= (x - drawing678EditCursorOutlierLimit) && crossHairXcord <= (x+w+drawing678EditCursorOutlierLimit)) && (
                                    (crossHairYcord >= ((canvasChartHeight - y) - drawing678EditCursorOutlierLimit) && crossHairYcord <= ((canvasChartHeight - y) + drawing678EditCursorOutlierLimit)) ||
                                    (crossHairYcord <= ((canvasChartHeight - y) - drawing678EditCursorOutlierLimit) && crossHairYcord >= ((canvasChartHeight - y) + drawing678EditCursorOutlierLimit))
                                )


                                
                                //isCrossHair6InRightEdgeRange = 
                                isCrossHair6InRightEdgeRange = (crossHairXcord >= (x+w-drawing678EditCursorOutlierLimit) && crossHairXcord <= (x+w+drawing678EditCursorOutlierLimit)) && (
                                    (crossHairYcord >= ((canvasChartHeight - y) - drawing678EditCursorOutlierLimit) && crossHairYcord <= ((canvasChartHeight - y - h) + drawing678EditCursorOutlierLimit)) ||
                                    (crossHairYcord <= ((canvasChartHeight - y) - drawing678EditCursorOutlierLimit) && crossHairYcord >= ((canvasChartHeight - y - h) + drawing678EditCursorOutlierLimit))
                                )

                                
                                //------------------------------------------------------------
                                isCrossHair6InRightEdgeRangeType2 = (crossHairXcord >= (0) && crossHairXcord <= (canvasChartWidth)) 
                                isCrossHair6InLeftEdgeRangeType2  = (crossHairXcord >= (0) && crossHairXcord <= (canvasChartWidth))

                                isCrossHair6InTopEdgeRangeType2     = (crossHairYcord >= (0) && crossHairYcord <= (canvasChartHeight))
                                isCrossHair6InBottomEdgeRangeType2  = (crossHairYcord >= (0) && crossHairYcord <= (canvasChartHeight))
                                //------------------------------------------------------------

                                //isCrossHair6InBottomEdgeRange = 
                                isCrossHair6InBottomEdgeRange = (crossHairXcord >= (x - drawing678EditCursorOutlierLimit) && crossHairXcord <= (x+w+drawing678EditCursorOutlierLimit)) && (
                                    (crossHairYcord >= ((canvasChartHeight - y - h) - drawing678EditCursorOutlierLimit) && crossHairYcord <= ((canvasChartHeight - y - h) + drawing678EditCursorOutlierLimit)) ||
                                    (crossHairYcord <= ((canvasChartHeight - y - h) - drawing678EditCursorOutlierLimit) && crossHairYcord >= ((canvasChartHeight - y - h) + drawing678EditCursorOutlierLimit))
                                )

                                //isCrossHair6InleftEdgeRange = 
                                isCrossHair6InleftEdgeRange = (crossHairXcord >= (x - drawing678EditCursorOutlierLimit) && crossHairXcord <= (x + drawing678EditCursorOutlierLimit)) && (
                                    (crossHairYcord >= ((canvasChartHeight - y) - drawing678EditCursorOutlierLimit) && crossHairYcord <= ((canvasChartHeight - y - h) + drawing678EditCursorOutlierLimit)) ||
                                    (crossHairYcord <= ((canvasChartHeight - y) - drawing678EditCursorOutlierLimit) && crossHairYcord >= ((canvasChartHeight - y - h) + drawing678EditCursorOutlierLimit))
                                )

                                /*if(isCrossHair6InleftEdgeRange){
                                    console.log('baduooooooooooooooooooooooooooo')
                                }*/

                            }
                            else if(isDrawingTypeTrendline7){

                                    isRectSquare7DrawingSell = drawingTypeObj.isRectSquareDrawingSell

                                    const rectSquareUpperHeightPrice =  drawingTypeObj.rectSquareUpperHeightPrice
                                    const rectSquareLowerHeightPrice =  drawingTypeObj.rectSquareLowerHeightPrice

                                    let rectSquareUpperHeightPriceYcord = ((canvasChartHeight * (rectSquareUpperHeightPrice - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) 
                                    rectSquareUpperHeightPriceYcord = ((canvasChartHeight - rectSquareUpperHeightPriceYcord) + YchartShiftPX) + chartYvelocity 

                                    let rectSquareLowerHeightPriceYcord = ((canvasChartHeight * (rectSquareLowerHeightPrice - minPriceVal)) / minMaxPriceValDiff) * (1.0 + YmagnificationPercRatio) 
                                    rectSquareLowerHeightPriceYcord = ((canvasChartHeight - rectSquareLowerHeightPriceYcord) + YchartShiftPX) + chartYvelocity 

                                    //console.log({rectSquareUpperHeightPrice,rectSquareLowerHeightPrice,rectSquareUpperHeightPriceYcord,rectSquareLowerHeightPriceYcord,YcordPriceStart,})

                                    //rect(x, y, w, h)
                                    //rect(x1, y1, w1, h1)
                                    //rect(x2, y2, w2, h2)
                                    const x1 = /*isDTendGTdtStart===false ? crossHairOnMoveCordVals.x : */XcordStart
                                    const y1 = rectSquareUpperHeightPriceYcord
                                    const w1 = Math.abs(XcordEnd - XcordStart)
                                    const h1 = Math.abs(rectSquareUpperHeightPriceYcord - YcordPriceStart)

                                    const x2 = /*isDTendGTdtStart===false ? crossHairOnMoveCordVals.x : */XcordStart
                                    const y2 = YcordPriceStart
                                    const w2 = Math.abs(XcordEnd - XcordStart)
                                    const h2 = Math.abs(rectSquareLowerHeightPriceYcord - YcordPriceStart)

                                    isCrossHairWithinRectSquareRange = ((crossHairXcord >= x1) && (crossHairXcord <= x1+w1) &&
                                    (
                                        ((crossHairYcord >= (canvasChartHeight - y1) ) && (crossHairYcord <= (canvasChartHeight - (y1 +h1+h2) ))) || 
                                        ((crossHairYcord <= (canvasChartHeight - y1) ) && (crossHairYcord >= (canvasChartHeight - (y1 +h1+h2) )))
                                    )
                                    )

                                    /*console.log({
                                        isCrossHairWithinRectSquareRange
                                    })*/

                                    trendline7MidRightXcord  = x1 + (w1) - squareNavigatorMargin
                                    trendline7MidRightYcord  = y2 - squareNavigatorMargin//y1 + (h1) - squareNavigatorMargin
                                    trendline7MidLeftXcord   = x2 //- squareNavigatorMargin
                                    trendline7MidLeftYcord   = y2 //+ (h * 0.5) - squareNavigatorMargin

                                    trendline7TopLeftXcord    = x1 - squareNavigatorMargin
                                    trendline7TopLeftYcord    = y1 - squareNavigatorMargin
                                    trendline7BottomLeftXcord = x2 - squareNavigatorMargin
                                    trendline7BottomLeftYcord = y2 + (h2) - squareNavigatorMargin

                                    isCrossHair7WithinMidRightSquareRange     = (
                                        crossHairXcord >= (trendline7MidRightXcord) && crossHairXcord <= (trendline7MidRightXcord + squareNavigatorWidthHeight)
                                    ) && (
                                        (crossHairYcord >= ((canvasChartHeight - trendline7MidRightYcord)) && crossHairYcord <= ((canvasChartHeight - trendline7MidRightYcord) - squareNavigatorWidthHeight)) ||
                                        (crossHairYcord <= ((canvasChartHeight - trendline7MidRightYcord)) && crossHairYcord >= ((canvasChartHeight - trendline7MidRightYcord) - squareNavigatorWidthHeight))
                                    )

                                    isCrossHair7WithinMidLeftCircleRange      = (
                                        crossHairXcord >= (trendline7MidLeftXcord - squareNavigatorMargin) && crossHairXcord <= (trendline7MidLeftXcord + squareNavigatorMargin)
                                    ) && (
                                        (crossHairYcord >= ((canvasChartHeight - trendline7MidLeftYcord - squareNavigatorMargin)) && crossHairYcord <= ((canvasChartHeight - trendline7MidLeftYcord) + squareNavigatorMargin)) ||
                                        (crossHairYcord <= ((canvasChartHeight - trendline7MidLeftYcord - squareNavigatorMargin)) && crossHairYcord >= ((canvasChartHeight - trendline7MidLeftYcord) + squareNavigatorMargin))
                                    )

                                    isCrossHair7WithinTopLeftSquareRange      = (
                                        crossHairXcord >= (trendline7TopLeftXcord) && crossHairXcord <= (trendline7TopLeftXcord + squareNavigatorWidthHeight)
                                    ) && (
                                        (crossHairYcord >= ((canvasChartHeight - trendline7TopLeftYcord)) && crossHairYcord <= ((canvasChartHeight - trendline7TopLeftYcord) - squareNavigatorWidthHeight)) ||
                                        (crossHairYcord <= ((canvasChartHeight - trendline7TopLeftYcord)) && crossHairYcord >= ((canvasChartHeight - trendline7TopLeftYcord) - squareNavigatorWidthHeight))
                                    )

                                    isCrossHair7WithinBottomLeftSquareRange   = (
                                        crossHairXcord >= (trendline7BottomLeftXcord) && crossHairXcord <= (trendline7BottomLeftXcord + squareNavigatorWidthHeight)
                                    ) && (
                                        (crossHairYcord >= ((canvasChartHeight - trendline7BottomLeftYcord)) && crossHairYcord <= ((canvasChartHeight - trendline7BottomLeftYcord) - squareNavigatorWidthHeight)) ||
                                        (crossHairYcord <= ((canvasChartHeight - trendline7BottomLeftYcord)) && crossHairYcord >= ((canvasChartHeight - trendline7BottomLeftYcord) - squareNavigatorWidthHeight))
                                    )


                                    //isCrossHair7InMidEdgeRange
                                    isCrossHair7InMidEdgeRange = (crossHairXcord >= (x1 - drawing678EditCursorOutlierLimit) && crossHairXcord <= (x1+w1+drawing678EditCursorOutlierLimit)) && (
                                        (crossHairYcord >= ((canvasChartHeight - y2) - drawing678EditCursorOutlierLimit) && crossHairYcord <= ((canvasChartHeight - y2) + drawing678EditCursorOutlierLimit)) ||
                                        (crossHairYcord <= ((canvasChartHeight - y2) - drawing678EditCursorOutlierLimit) && crossHairYcord >= ((canvasChartHeight - y2) + drawing678EditCursorOutlierLimit))
                                    )

                                    //isCrossHair7InTopEdgeRange = 
                                    isCrossHair7InTopEdgeRange = (crossHairXcord >= (x1 - drawing678EditCursorOutlierLimit) && crossHairXcord <= (x1+w1+drawing678EditCursorOutlierLimit)) && (
                                        (crossHairYcord >= ((canvasChartHeight - y1) - drawing678EditCursorOutlierLimit) && crossHairYcord <= ((canvasChartHeight - y1) + drawing678EditCursorOutlierLimit)) ||
                                        (crossHairYcord <= ((canvasChartHeight - y1) - drawing678EditCursorOutlierLimit) && crossHairYcord >= ((canvasChartHeight - y1) + drawing678EditCursorOutlierLimit))
                                    )

                                    //isCrossHair7InRightEdgeRange = 
                                    isCrossHair7InRightEdgeRange = (crossHairXcord >= (x2+w2-drawing678EditCursorOutlierLimit) && crossHairXcord <= (x2+w2+drawing678EditCursorOutlierLimit)) && (
                                        (crossHairYcord >= ((canvasChartHeight - y1) - drawing678EditCursorOutlierLimit) && crossHairYcord <= ((canvasChartHeight - y1 - h1 - h2) + drawing678EditCursorOutlierLimit)) ||
                                        (crossHairYcord <= ((canvasChartHeight - y1) - drawing678EditCursorOutlierLimit) && crossHairYcord >= ((canvasChartHeight - y1 - h1 - h2) + drawing678EditCursorOutlierLimit))
                                    )

                                    //isCrossHair7InBottomEdgeRange = 
                                    isCrossHair7InBottomEdgeRange = (crossHairXcord >= (x2 - drawing678EditCursorOutlierLimit) && crossHairXcord <= (x2+w2+drawing678EditCursorOutlierLimit)) && (
                                        (crossHairYcord >= ((canvasChartHeight - y2 - h2) - drawing678EditCursorOutlierLimit) && crossHairYcord <= ((canvasChartHeight - y2 - h2) + drawing678EditCursorOutlierLimit)) ||
                                        (crossHairYcord <= ((canvasChartHeight - y2 - h2) - drawing678EditCursorOutlierLimit) && crossHairYcord >= ((canvasChartHeight - y2 - h2) + drawing678EditCursorOutlierLimit))
                                    )

                                    //isCrossHair7InleftEdgeRange = 
                                    isCrossHair7InleftEdgeRange = (crossHairXcord >= (x1 - drawing678EditCursorOutlierLimit) && crossHairXcord <= (x1 + drawing678EditCursorOutlierLimit)) && (
                                        (crossHairYcord >= ((canvasChartHeight - y1) - drawing678EditCursorOutlierLimit) && crossHairYcord <= ((canvasChartHeight - y1 - h1 - h2) + drawing678EditCursorOutlierLimit)) ||
                                        (crossHairYcord <= ((canvasChartHeight - y1) - drawing678EditCursorOutlierLimit) && crossHairYcord >= ((canvasChartHeight - y1 - h1 - h2) + drawing678EditCursorOutlierLimit))
                                    )


                                    //------------------------------------------------------------------------------------------
                                    isCrossHair7InRightEdgeRangeType2  = (crossHairXcord >= (0) && crossHairXcord <= (canvasChartWidth))
                                    isCrossHair7InleftEdgeRangeType2   = (crossHairXcord >= (0) && crossHairXcord <= (canvasChartWidth))

                                    isCrossHair7InTopEdgeRangeType2    = (crossHairYcord >= (0) && crossHairYcord <= (canvasChartHeight))
                                    isCrossHair7InBottomEdgeRangeType2 = (crossHairYcord >= (0) && crossHairYcord <= (canvasChartHeight))
                                    //--------------------------------------------------------------------------------------


                                    /*if(isCrossHair7WithinBottomLeftSquareRange){
                                        console.log('baduooooooooooooooooooooooooooo')
                                    }*/

                                    


                            }
                            else if(isDrawingTypeTrendline8){


                                let hypotenuse = 0
                                let crossHairHypotenuse = 0
                                if(true/*isXcordSame===false*/){
                                    const opposite = Math.abs(YcordPriceEnd - YcordPriceStart)
                                    const adjacent = Math.abs(XcordEnd - XcordStart)
                                    const angle    = pythagorasCalculator({calcType : 'tan', varToCalc : 'aa', opposite, adjacent})
                                    hypotenuse     = pythagorasCalculator({calcType : 'sin', varToCalc : 'h', opposite, angle})
                                }
                                //if(true/*isXcordSame===false*/){
                                    const chartCrossHairYcord = Math.abs(canvasChartHeight - crossHairOnMoveCordVals.y)
                                    const opposite      = Math.abs(chartCrossHairYcord - (/*drawingTypeObj.*/isDrawingForward ? YcordPriceStart : YcordPriceEnd))
                                    const adjacent      = Math.abs(crossHairXcord - (/*drawingTypeObj.*/isDrawingForward ? XcordStart : XcordEnd))
                                    const angle         = pythagorasCalculator({calcType : 'tan', varToCalc : 'aa', opposite, adjacent})
                                    crossHairHypotenuse = pythagorasCalculator({calcType : 'sin', varToCalc : 'h', opposite, angle})
                                //}
                                

                                isCrossHairWithinCircleRange = crossHairHypotenuse <= hypotenuse

                                //drawingEditCursorOutlierLimit
                                isCrossHair8InRoundEdgeRange = crossHairHypotenuse <= Math.abs(hypotenuse + drawingEditCursorOutlierLimit) && 
                                crossHairHypotenuse >= Math.abs(hypotenuse - drawingEditCursorOutlierLimit)

                                
                                /*console.log({
                                    old:hypotenuse,
                                    new:crossHairHypotenuse,
                                    opposite, adjacent, angle, adjacent,
                                })*/

                                /*if(isCrossHair8InRoundEdgeRange){
                                    console.log('yoooooooooooooooo')
                                }*/
                            }




                            if(//isDrawingIdEditSelected && isEditingDrawingForward
                                //true ||
                                (
                                    (isDrawingTypeTrendline6 &&
                                    selectedDrawingForEditInfoIdObj.isSelected &&
                                    selectedDrawingForEditInfoIdObj.id === id 
                                    && isDrawingActivated === false 
                                    //&& isTouchScreenDevice===false
                                    )
                                )
                                ||
                                (
                                    (
                                        isDrawingTypeTrendline6 && (isMouseDown===false || isTouchScreenDevice)// || isTouchScreenDevice: could help move it right from click
                                        && isDrawingActivated === false //&& isEditingDrawing===false
                                    ) &&
                                    (
                                        (isTouchScreenDevice && false
                                        && isCrossHairWithinDrawingSquareRange) //good for onTouchScreenDevice
                                        ||
                                        /*(isTrendlineStartInSelectionRange===false &&
                                        isTrendlineEndInSelectionRange===false &&
                                        trendlineMidRangePX > drawingEditCursorOutlierLimit) &&*/
                                        
                                        (
                                            (isTouchScreenDevice===false || true) &&
                                            (
                                                isCrossHair6WithinMidTopSquareRange      ||
                                                isCrossHair6WithinMidRightSquareRange    ||
                                                isCrossHair6WithinMidBottomSquareRange   ||
                                                isCrossHair6WithinMidLeftSquareRange     ||
                                                isCrossHair6WithinTopRightCircleRange    ||
                                                isCrossHair6WithinTopLeftCircleRange     ||
                                                isCrossHair6WithinBottomRightCircleRange ||
                                                isCrossHair6WithinBottomLeftCircleRange  ||

                                                isCrossHair6InTopEdgeRange               ||
                                                isCrossHair6InRightEdgeRange             ||
                                                isCrossHair6InBottomEdgeRange            ||
                                                isCrossHair6InleftEdgeRange
                                            )
                                        )
                                    )
                                )
                            ){//rect(6)

                                //console.log({isMouseDown})
                                //console.log('moveo+++++++++++++++ooooooooooooooooooooooooooooooohelooooooooooooooo')

                                const cursorObj = {
                                    isCrossHair6WithinMidTopSquareRange      : 'ns-resize',
                                    isCrossHair6WithinMidRightSquareRange    : 'ew-resize',
                                    isCrossHair6WithinMidBottomSquareRange   : 'ns-resize',
                                    isCrossHair6WithinMidLeftSquareRange     : 'ew-resize',
                                    isCrossHair6WithinTopRightCircleRange    : 'nesw-resize',
                                    isCrossHair6WithinTopLeftCircleRange     : 'nwse-resize',
                                    isCrossHair6WithinBottomRightCircleRange : 'nwse-resize',
                                    isCrossHair6WithinBottomLeftCircleRange  : 'nesw-resize',

                                    /*isCrossHair6InTopEdgeRange               : '',
                                    isCrossHair6InRightEdgeRange             : '',
                                    isCrossHair6InBottomEdgeRange            : '',
                                    isCrossHair6InleftEdgeRange              : ''*/
                                }
                                


                                //techiman
                                //consider the arrangement & selection by specificity by Auth layers
                                if(
                                    //(isCrossHair6WithinBottomRightCircleRange /*&& isMouseDown===false*/) || 
                                    (isCrossHair6WithinBottomRightCircleRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair6WithinBottomRightCircleRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType:cursorObj.isCrossHair6WithinBottomRightCircleRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair6WithinBottomRightCircleRange,
                                        ...drawingTypeObj
                                    })
                                }
                                else if(
                                    (isCrossHair6WithinTopRightCircleRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair6WithinTopRightCircleRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType:cursorObj.isCrossHair6WithinTopRightCircleRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair6WithinTopRightCircleRange,
                                        ...drawingTypeObj
                                    })
                                    
                                }
                                else if(
                                    (isCrossHair6WithinTopLeftCircleRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair6WithinTopLeftCircleRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType:cursorObj.isCrossHair6WithinTopLeftCircleRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair6WithinTopLeftCircleRange,
                                        ...drawingTypeObj
                                    })
                                }
                                else if(
                                    (isCrossHair6WithinBottomLeftCircleRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair6WithinBottomLeftCircleRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType:cursorObj.isCrossHair6WithinBottomLeftCircleRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair6WithinBottomLeftCircleRange,
                                        ...drawingTypeObj
                                    })
                                }
                                else if(
                                    (isCrossHair6WithinMidRightSquareRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair6WithinMidRightSquareRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType: cursorObj.isCrossHair6WithinMidRightSquareRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair6WithinMidRightSquareRange :  isCrossHair6InRightEdgeRangeType2 ? isCrossHair6InRightEdgeRangeType2 : isCrossHair6WithinMidRightSquareRange,
                                        ...drawingTypeObj
                                    })

                                    
                                    setSelectedDrawingForEditInfoIdObj({
                                        ...selectedDrawingForEditInfoIdObj,
                                        isCrossHair6InRightEdgeRangeType2
                                    })
                                    
                                }
                                else if(
                                    (isCrossHair6WithinMidLeftSquareRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair6WithinMidLeftSquareRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType:cursorObj.isCrossHair6WithinMidLeftSquareRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair6WithinMidLeftSquareRange : isCrossHair6InLeftEdgeRangeType2 ? isCrossHair6InLeftEdgeRangeType2 : isCrossHair6WithinMidLeftSquareRange,
                                        ...drawingTypeObj
                                    })

                                    setSelectedDrawingForEditInfoIdObj({
                                        ...selectedDrawingForEditInfoIdObj,
                                        isCrossHair6InLeftEdgeRangeType2
                                    })
                                }
                                else if(
                                    (isCrossHair6WithinMidBottomSquareRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair6WithinMidBottomSquareRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType:cursorObj.isCrossHair6WithinMidBottomSquareRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair6WithinMidBottomSquareRange : isCrossHair6InBottomEdgeRangeType2 ? isCrossHair6InBottomEdgeRangeType2 : isCrossHair6WithinMidBottomSquareRange,
                                        ...drawingTypeObj
                                    })

                                    setSelectedDrawingForEditInfoIdObj({
                                        ...selectedDrawingForEditInfoIdObj,
                                        isCrossHair6InBottomEdgeRangeType2
                                    })
                                }
                                else if(
                                    (isCrossHair6WithinMidTopSquareRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair6WithinMidTopSquareRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType:cursorObj.isCrossHair6WithinMidTopSquareRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair6WithinMidTopSquareRange : isCrossHair6InTopEdgeRangeType2 ? isCrossHair6InTopEdgeRangeType2 : isCrossHair6WithinMidTopSquareRange,
                                        ...drawingTypeObj
                                    })

                                    setSelectedDrawingForEditInfoIdObj({
                                        ...selectedDrawingForEditInfoIdObj,
                                        isCrossHair6InTopEdgeRangeType2
                                    })
                                }
                                else if(//this auth: should always come last, as the absolute range passes through various specific range
                                    //isMouseDown===false &&
                                    //
                                    isCrossHair6WithinMidTopSquareRange === false      &&
                                    isCrossHair6WithinMidRightSquareRange === false    &&
                                    isCrossHair6WithinMidBottomSquareRange === false   &&
                                    isCrossHair6WithinMidLeftSquareRange === false     &&
                                    isCrossHair6WithinTopRightCircleRange === false    &&
                                    isCrossHair6WithinTopLeftCircleRange === false     &&
                                    isCrossHair6WithinBottomRightCircleRange === false &&
                                    isCrossHair6WithinBottomLeftCircleRange === false  &&
                                    (
                                        isCrossHair6InTopEdgeRange ||
                                        isCrossHair6InRightEdgeRange ||
                                        isCrossHair6InBottomEdgeRange ||
                                        isCrossHair6InleftEdgeRange
                                    ) 
                                ){
                                    //take note of this variable
                                    //in order for drawing state & cursorType to change
                                    isAnyOfThePrevDrawingsIndexNotInUse = false

                                    setCursorAndDrawingEditIdObj({
                                        cursorType:"pointer",
                                        isInTLmidRange : true,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,
                                        ...drawingTypeObj
                                    })
                                    //console.log('moveoooooooooooooooooooooooooooooooohelooooooooooooooo')
                                }
                                
                                
                                //console.log({YcordPriceStart, YcordPriceEnd})

                                const radian = Math.PI / 180;
                                // context.arc(centerX, centerY, radius, startAngle, endAngle, antiClockwiseDirection);



                                if(
                                    //isDTendGTdtStart
                                    isMouseDown===false
                                ){

                                    //trendline6MidTopXcord, 
                                    context.lineWidth = 0.5;
                                    context.strokeStyle = "blue";
                                    context.strokeRect(
                                        trendline6MidTopXcord, //x
                                        trendline6MidTopYcord, //y
                                        squareNavigatorWidthHeight, //w
                                        squareNavigatorWidthHeight  //h
                                    );

                                    //trendline6MidRightXcord
                                    context.lineWidth = 0.5;
                                    context.strokeStyle = "blue";
                                    context.strokeRect(
                                        trendline6MidRightXcord, //x
                                        trendline6MidRightYcord, //y
                                        squareNavigatorWidthHeight, //w
                                        squareNavigatorWidthHeight  //h
                                    );

                                    //trendline6MidBottomXcord
                                    context.lineWidth = 0.5;
                                    context.strokeStyle = "blue";
                                    context.strokeRect(
                                        trendline6MidBottomXcord, //x
                                        trendline6MidBottomYcord, //y
                                        squareNavigatorWidthHeight, //w
                                        squareNavigatorWidthHeight  //h
                                    );

                                    //trendline6MidLeftXcord
                                    context.lineWidth = 0.5;
                                    context.strokeStyle = "blue";
                                    context.strokeRect(
                                        trendline6MidLeftXcord, //x
                                        trendline6MidLeftYcord, //y
                                        squareNavigatorWidthHeight, //w
                                        squareNavigatorWidthHeight  //h
                                    );


                                    //trendline6TopRightXcord
                                    context.beginPath();
                                    context.strokeStyle = "blue";
                                    context.lineWidth = 0.7;
                                    context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                    context.arc(
                                        trendline6TopRightXcord, 
                                        trendline6TopRightYcord, 
                                        drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                    context.stroke();
                                    context.fill();

                                    //trendline6BottomRightXcord
                                    context.beginPath();
                                    context.strokeStyle = "blue";
                                    context.lineWidth = 0.7;
                                    context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                    context.arc(
                                        trendline6BottomRightXcord, 
                                        trendline6BottomRightYcord, 
                                        drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                    context.stroke();
                                    context.fill();

                                    //trendline6TopLeftXcord
                                    context.beginPath();
                                    context.strokeStyle = "blue";
                                    context.lineWidth = 0.7;
                                    context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                    context.arc(
                                        trendline6TopLeftXcord, 
                                        trendline6TopLeftYcord, 
                                        drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                    context.stroke();
                                    context.fill();

                                    //trendline6BottomLeftXcord
                                    context.beginPath();
                                    context.strokeStyle = "blue";
                                    context.lineWidth = 0.7;
                                    context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                    context.arc(
                                        trendline6BottomLeftXcord, 
                                        trendline6BottomLeftYcord, 
                                        drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                    context.stroke();
                                    context.fill();

                                }

                            }
                            else if(
                                (
                                    isDrawingTypeTrendline8 &&
                                    selectedDrawingForEditInfoIdObj.isSelected &&
                                    selectedDrawingForEditInfoIdObj.id === id
                                    && isDrawingActivated === false
                                )
                                ||
                                (
                                    (
                                        isDrawingTypeTrendline8 && isMouseDown===false 
                                        && isDrawingActivated === false //&& isEditingDrawing===false
                                    ) &&
                                    (
                                        (isTouchScreenDevice && false
                                        && isCrossHairWithinCircleRange) //good for onTouchScreenDevice
                                        || 
                                        /*(isTrendlineStartInSelectionRange===false &&
                                        isTrendlineEndInSelectionRange===false &&
                                        trendlineMidRangePX > drawingEditCursorOutlierLimit) &&*/
                                        
                                        (
                                            (isTouchScreenDevice===false || true) &&
                                            (
                                                isCrossHair8InRoundEdgeRange ||  
                                                isTrendlineEndInSelectionRange ||
                                                isTrendlineStartInSelectionRange
                                            )
                                        )
                                    )
                                )
                            ){//circle(8) - isCrossHair8InRoundEdgeRange



                                //consider the arrangement & selection by specificity by Auth layers
                                if(/*isMouseDown===false && */isTrendlineEndInSelectionRange){//end 
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType: /*drawingTypeObj.*/isDrawingForward ? "default" : "pointer",
                                        isInTLmidRange : /*drawingTypeObj.*/isDrawingForward ? false : true,
                                        isDrawingEndCordSelected : /*drawingTypeObj.*/isDrawingForward ? true : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,
                                        ...drawingTypeObj
                                    })

                                    //console.log(/*drawingTypeObj.*/isDrawingForward ? 'trendline end' : 'trendline Circle mid mover', drawingTypeObj
                                }
                                else if(/*isMouseDown===false && */isTrendlineStartInSelectionRange){//start
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType: /*drawingTypeObj.*/isDrawingForward ? "pointer" : "default",
                                        isInTLmidRange : /*drawingTypeObj.*/isDrawingForward ? true : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : /*drawingTypeObj.*/isDrawingForward ? false : true,
                                        isDrawingMidCordSelected : false,
                                        ...drawingTypeObj
                                    })

                                    //console.log(/*drawingTypeObj.*/isDrawingForward ? 'trendline Circle mid mover' : "trendline start", drawingTypeObj)
                                }
                                else if(/*isMouseDown===false && */isCrossHair8InRoundEdgeRange)
                                {
                                    //take note of this variable
                                    //in order for drawing state & cursorType to change
                                    isAnyOfThePrevDrawingsIndexNotInUse = false

                                    setCursorAndDrawingEditIdObj({
                                        cursorType:"pointer",
                                        isInTLmidRange : true,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,
                                        ...drawingTypeObj
                                    })
                                    //console.log('moveoooooooooooooooooooooooooooooooo')
                                    //console.log('trendline Circle @Edge mover', drawingTypeObj)
                                }


                                if(
                                    //isDTendGTdtStart
                                    isMouseDown===false
                                ){

                                    const radian = Math.PI / 180;
                                    // context.arc(centerX, centerY, radius, startAngle, endAngle, antiClockwiseDirection);

                                    context.beginPath();
                                    context.strokeStyle = "blue";
                                    context.lineWidth = 0.7;
                                    context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                    context.arc(
                                        trendlineXstartCord, 
                                        (canvasChartHeight - trendlineYstartCord), 
                                        drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false
                                    );
                                    context.stroke();
                                    context.fill();

                                    
                                    context.beginPath();
                                    context.strokeStyle = "blue";
                                    context.lineWidth = 0.7;
                                    context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                    context.arc(
                                        trendlineXendCord, 
                                        (canvasChartHeight - trendlineYendCord), 
                                        drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false
                                    );
                                    context.stroke();
                                    context.fill();
                                }

                            }
                            else if(
                                (
                                    isDrawingTypeTrendline7 &&
                                    selectedDrawingForEditInfoIdObj.isSelected &&
                                    selectedDrawingForEditInfoIdObj.id === id 
                                    && isDrawingActivated === false
                                    //&& isMouseDown===false
                                )
                                ||
                                (
                                    (
                                        isDrawingTypeTrendline7 && (isMouseDown===false || isTouchScreenDevice)// || isTouchScreenDevice: could help move it right from click
                                        && isDrawingActivated === false //&& isEditingDrawing===false
                                    ) &&
                                    (
                                        (isTouchScreenDevice && false
                                        && isCrossHairWithinRectSquareRange) //good for onTouchScreenDevice
                                        ||
                                        /*(isTrendlineStartInSelectionRange===false &&
                                        isTrendlineEndInSelectionRange===false &&
                                        trendlineMidRangePX > drawingEditCursorOutlierLimit) &&*/
                                        
                                        (
                                            (isTouchScreenDevice===false || true) &&
                                            (
                                                isCrossHair7WithinMidRightSquareRange    ||
                                                isCrossHair7WithinMidLeftCircleRange     ||
                                                isCrossHair7WithinTopLeftSquareRange     ||
                                                isCrossHair7WithinBottomLeftSquareRange  ||

                                                isCrossHair7InTopEdgeRange               ||
                                                isCrossHair7InRightEdgeRange             ||
                                                isCrossHair7InBottomEdgeRange            ||
                                                isCrossHair7InleftEdgeRange              ||
                                                isCrossHair7InMidEdgeRange
                                            )
                                        )
                                    )
                                )
                            ){//rectSquare(7)

                                if(isCrossHairWithinRectSquareRange){
                                    //console.log('wenchioooooooooooooooooooo')
                                }


                                const cursorObj = {

                                    isCrossHair7WithinMidRightSquareRange    : 'ew-resize',
                                    isCrossHair7WithinMidLeftCircleRange     : 'default',
                                    isCrossHair7WithinTopLeftSquareRange     : 'ns-resize',
                                    isCrossHair7WithinBottomLeftSquareRange  : 'ns-resize',

                                    /*isCrossHair7InTopEdgeRange               : '',
                                    isCrossHair7InRightEdgeRange             : '',
                                    isCrossHair7InBottomEdgeRange            : '',
                                    isCrossHair7InleftEdgeRange              : ''
                                    isCrossHair7InMidEdgeRange               : ''*/
                                }



                                
                                //techiman
                                //consider the arrangement & selection by specificity by Auth layers
                                if(
                                    //isCrossHair7WithinTopLeftSquareRange
                                    (isCrossHair7WithinTopLeftSquareRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair7WithinTopLeftSquareRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType:cursorObj.isCrossHair7WithinTopLeftSquareRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair7WithinTopLeftSquareRange :  isCrossHair7InTopEdgeRangeType2 ? isCrossHair7InTopEdgeRangeType2 : isCrossHair7WithinTopLeftSquareRange,
                                        ...drawingTypeObj
                                    })

                                    setSelectedDrawingForEditInfoIdObj({
                                        ...selectedDrawingForEditInfoIdObj,
                                        isCrossHair7InTopEdgeRangeType2
                                    })
                                }
                                else if(
                                    //isCrossHair7WithinBottomLeftSquareRange
                                    (isCrossHair7WithinBottomLeftSquareRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair7WithinBottomLeftSquareRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType:cursorObj.isCrossHair7WithinBottomLeftSquareRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair7WithinBottomLeftSquareRange  :  isCrossHair7InBottomEdgeRangeType2 ? isCrossHair7InBottomEdgeRangeType2 : isCrossHair7WithinBottomLeftSquareRange,
                                        ...drawingTypeObj
                                    })

                                    setSelectedDrawingForEditInfoIdObj({
                                        ...selectedDrawingForEditInfoIdObj,
                                        isCrossHair7InBottomEdgeRangeType2
                                    })
                                }
                                else if(
                                    //isCrossHair7WithinMidRightSquareRange
                                    (isCrossHair7WithinMidRightSquareRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair7WithinMidRightSquareRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType:cursorObj.isCrossHair7WithinMidRightSquareRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair7WithinMidRightSquareRange  :  isCrossHair7InRightEdgeRangeType2 ? isCrossHair7InRightEdgeRangeType2 : isCrossHair7WithinMidRightSquareRange,
                                        ...drawingTypeObj
                                    })

                                    setSelectedDrawingForEditInfoIdObj({
                                        ...selectedDrawingForEditInfoIdObj,
                                        isCrossHair7InRightEdgeRangeType2
                                    })
                                }
                                else if(
                                    //isCrossHair7WithinMidLeftCircleRange
                                    (isCrossHair7WithinMidLeftCircleRange && (isMouseDown===false || isTouchScreenDevice)) || 
                                    (isTouchScreenDevice===false && selectedDrawingForEditInfoIdObj.isCrossHair7WithinMidLeftCircleRange && isMouseDown)
                                ){
                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    setCursorAndDrawingEditIdObj({
                                        cursorType:cursorObj.isCrossHair7WithinMidLeftCircleRange,
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,

                                        isCrossHair7WithinMidLeftCircleRange  :  isCrossHair7InleftEdgeRangeType2 ? isCrossHair7InleftEdgeRangeType2 : isCrossHair7WithinMidLeftCircleRange,
                                        ...drawingTypeObj
                                    })

                                    setSelectedDrawingForEditInfoIdObj({
                                        ...selectedDrawingForEditInfoIdObj,
                                        isCrossHair7InleftEdgeRangeType2
                                    })
                                }
                                else if( 
                                    //isMouseDown===false &&
                                    isCrossHair7WithinMidRightSquareRange === false    &&
                                    isCrossHair7WithinMidLeftCircleRange  === false    &&
                                    isCrossHair7WithinTopLeftSquareRange  === false    &&
                                    isCrossHair7WithinBottomLeftSquareRange === false  &&
                                    (
                                        isCrossHair7InTopEdgeRange ||
                                        isCrossHair7InRightEdgeRange ||
                                        isCrossHair7InBottomEdgeRange ||
                                        isCrossHair7InleftEdgeRange   ||
                                        isCrossHair7InMidEdgeRange
                                    )
                                ){
                                    //take note of this variable
                                    //in order for drawing state & cursorType to change
                                    isAnyOfThePrevDrawingsIndexNotInUse = false

                                    setCursorAndDrawingEditIdObj({
                                        cursorType:"pointer",
                                        isInTLmidRange : true,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,
                                        ...drawingTypeObj
                                    })
                                    //console.log('moveoooooooooooooooooooooooooooooooohelooooooooooooooo')
                                }




                                if(
                                    //isDTendGTdtStart
                                    isMouseDown===false
                                ){

                                    const radian = Math.PI / 180;
                                    // context.arc(centerX, centerY, radius, startAngle, endAngle, antiClockwiseDirection);


                                    //trendline7MidRightXcord
                                    context.lineWidth = 0.5;
                                    context.strokeStyle = "blue";
                                    context.strokeRect(
                                        trendline7MidRightXcord, //x
                                        trendline7MidRightYcord, //y
                                        squareNavigatorWidthHeight, //w
                                        squareNavigatorWidthHeight  //h
                                    );


                                    //trendline7MidLeftXcord
                                    context.beginPath();
                                    context.strokeStyle = "blue";
                                    context.lineWidth = 0.7;
                                    context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                    context.arc(
                                        trendline7MidLeftXcord, //x
                                        trendline7MidLeftYcord, //y
                                        drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                    context.stroke();
                                    context.fill();

                                    //trendline7TopLeftXcord
                                    context.lineWidth = 0.5;
                                    context.strokeStyle = "blue";
                                    context.strokeRect(
                                        trendline7TopLeftXcord, //x
                                        trendline7TopLeftYcord, //y
                                        squareNavigatorWidthHeight, //w
                                        squareNavigatorWidthHeight  //h
                                    );

                                    //trendline7BottomLeftXcord
                                    context.lineWidth = 0.5;
                                    context.strokeStyle = "blue";
                                    context.strokeRect(
                                        trendline7BottomLeftXcord, //x
                                        trendline7BottomLeftYcord, //y
                                        squareNavigatorWidthHeight, //w
                                        squareNavigatorWidthHeight  //h
                                    );

                                }


                                if(//show risk reward value on hover over drawing
                                    isCrossHairWithinRectSquareRange===false
                                ){

                                    if(drawingTypeObj.isRectSquareDrawingSell){
                                        //Draw horizontal baseCurrStrengthValText & centered
                                        //text with 2 attrs, bottom & centered
                                        context.fillStyle = "black";  
                                        context.font = "15px Verdana";
                                        // context.fillText(text, xCoord, yCoord, maxWidth);
                                        context.textAlign = "center";
                                        //context.textBaseline = "center";
                                        context.fillText(`${"1 "} :  ${Math.abs(parseFloat((riskRewardLowerHeight/riskRewardUpperHeight).toFixed(2)))}`, 
                                        sellRiskRewardXcord,//x2 + (w2*0.5),
                                        sellRiskRewardYcord,//y2 + (h2*0.5)
                                        );
                                    }
                                    else{
                                        //Draw horizontal baseCurrStrengthValText & centered
                                        //text with 2 attrs, bottom & centered
                                        context.fillStyle = "black";  
                                        context.font = "15px Verdana";
                                        // context.fillText(text, xCoord, yCoord, maxWidth);
                                        context.textAlign = "center";
                                        //context.textBaseline = "center";
                                        context.fillText(`${"1 "} :  ${Math.abs(parseFloat((riskRewardUpperHeight/riskRewardLowerHeight).toFixed(2)))}`, 
                                        buyRiskRewardXcord,//x1 + (w1*0.5),
                                        buyRiskRewardYcord,//y1 + (h1*0.5)
                                        );
                                    }

                                }

                            }



                            
                            if(//show risk reward value on hover over drawing
                                (isDrawingTypeTrendline7 &&
                                isCrossHairWithinRectSquareRange) ||
                                (isDrawingTypeTrendline7 &&
                                    selectedDrawingForEditInfoIdObj.isSelected &&
                                    selectedDrawingForEditInfoIdObj.id === id )
                            ){

                                if(drawingTypeObj.isRectSquareDrawingSell){
                                    //Draw horizontal baseCurrStrengthValText & centered
                                    //text with 2 attrs, bottom & centered
                                    context.fillStyle = "black";  
                                    context.font = "15px Verdana";
                                    // context.fillText(text, xCoord, yCoord, maxWidth);
                                    context.textAlign = "center";
                                    //context.textBaseline = "center";
                                    context.fillText(`${"1 "} :  ${Math.abs(parseFloat((riskRewardLowerHeight/riskRewardUpperHeight).toFixed(2)))}`, 
                                    sellRiskRewardXcord,//x2 + (w2*0.5),
                                    sellRiskRewardYcord,//y2 + (h2*0.5)
                                    );
                                }
                                else{
                                    //Draw horizontal baseCurrStrengthValText & centered
                                    //text with 2 attrs, bottom & centered
                                    context.fillStyle = "black";  
                                    context.font = "15px Verdana";
                                    // context.fillText(text, xCoord, yCoord, maxWidth);
                                    context.textAlign = "center";
                                    //context.textBaseline = "center";
                                    context.fillText(`${"1 "} :  ${Math.abs(parseFloat((riskRewardUpperHeight/riskRewardLowerHeight).toFixed(2)))}`, 
                                    buyRiskRewardXcord,//x1 + (w1*0.5),
                                    buyRiskRewardYcord,//y1 + (h1*0.5)
                                    );
                                }

                            }
                            /*else{

                            }*/


                            //console.log({drawingType : selectedDrawingForEditInfoIdObj.drawingType})
                            //techiman
                            //console.log({trendlineMidRangePX})
                            if(
                                (isMouseDown===false /*|| true || isTouchScreenDevice*/)
                                &&
                                (
                                    selectedDrawingForEditInfoIdObj.isCrossHair6InRightEdgeRangeType2 ||
                                    selectedDrawingForEditInfoIdObj.isCrossHair6InLeftEdgeRangeType2 ||
                                    selectedDrawingForEditInfoIdObj.isCrossHair6InTopEdgeRangeType2 ||
                                    selectedDrawingForEditInfoIdObj.isCrossHair6InBottomEdgeRangeType2 ||

                                    selectedDrawingForEditInfoIdObj.isCrossHair7InTopEdgeRangeType2 ||
                                    selectedDrawingForEditInfoIdObj.isCrossHair7InRightEdgeRangeType2 ||
                                    selectedDrawingForEditInfoIdObj.isCrossHair7InBottomEdgeRangeType2 ||
                                    selectedDrawingForEditInfoIdObj.isCrossHair7InleftEdgeRangeType2 
                                )
                            ){
                                const obj = selectedDrawingForEditInfoIdObj

                                delete obj['isCrossHair6InRightEdgeRangeType2']
                                delete obj['isCrossHair6InLeftEdgeRangeType2']
                                delete obj['isCrossHair6InTopEdgeRangeType2']
                                delete obj['isCrossHair6InBottomEdgeRangeType2']

                                delete obj['isCrossHair7InTopEdgeRangeType2']
                                delete obj['isCrossHair7InRightEdgeRangeType2']
                                delete obj['isCrossHair7InBottomEdgeRangeType2']
                                delete obj['isCrossHair7InleftEdgeRangeType2']

                                setSelectedDrawingForEditInfoIdObj(obj)

                                //console.log('clicked thro')
                            }





                            if(//remove trendline highlights when clicked far away from the trendline mid
                                (
                                    isEditingDrawing === false &&
                                    selectedDrawingForEditInfoIdObj.isSelected &&
                                    selectedDrawingForEditInfoIdObj.id === id &&
                                    (isMouseDown /*|| isTouchScreenDevice*/)
                                
                                ) 
                                &&
                                (
                                    //(isTouchScreenDevice /*&& crossHairMidStartXcordDrawerInfo.drawingOnDragEditCount > 0*/ /*&& isOnTouchMoveTracker*/) 
                                    //||
                                    (   isDrawingTypeTrendline8===false &&
                                        isDrawingTypeTrendline7===false &&
                                        isDrawingTypeTrendline6===false &&
                                        trendlineMidRangePX > drawingEditCursorOutlierLimit
                                    ) 
                                    ||
                                    (
                                        (isDrawingTypeTrendline6 &&
                                        isCrossHair6InTopEdgeRange    === false &&
                                        isCrossHair6InRightEdgeRange  === false &&
                                        isCrossHair6InBottomEdgeRange === false &&
                                        isCrossHair6InleftEdgeRange   === false &&

                                        isCrossHair6WithinMidTopSquareRange    === false  &&
                                        isCrossHair6WithinMidRightSquareRange  === false  &&
                                        isCrossHair6WithinMidBottomSquareRange === false  &&
                                        isCrossHair6WithinMidLeftSquareRange    === false &&
                                        isCrossHair6WithinTopRightCircleRange  === false  &&
                                        isCrossHair6WithinTopLeftCircleRange    === false &&
                                        isCrossHair6WithinBottomRightCircleRange=== false &&
                                        isCrossHair6WithinBottomLeftCircleRange  === false 

                                        && selectedDrawingForEditInfoIdObj.isCrossHair6InRightEdgeRangeType2===undefined
                                        && selectedDrawingForEditInfoIdObj.isCrossHair6InLeftEdgeRangeType2===undefined
                                        && selectedDrawingForEditInfoIdObj.isCrossHair6InTopEdgeRangeType2===undefined
                                        && selectedDrawingForEditInfoIdObj.isCrossHair6InBottomEdgeRangeType2===undefined
                                        //&& (isCrossHair6InRightEdgeRangeType2 && selectedDrawingForEditInfoIdObj.drawingType === "trendline6")
                                        
                                        /*&&
                                        isCrossHairWithinDrawingSquareRange===false*/ //- only for phone screen Touch Aauth
                                        ) 
                                        /*||
                                        ((isTouchScreenDevice || true)
                                            && isCrossHairWithinCircleRange===false)//for phone toucheScreens*/
                                    )
                                    ||
                                    (
                                        (isDrawingTypeTrendline7 &&
                                        isCrossHair7InTopEdgeRange    === false &&
                                        isCrossHair7InRightEdgeRange  === false &&
                                        isCrossHair7InBottomEdgeRange === false &&
                                        isCrossHair7InleftEdgeRange   === false &&
                                        isCrossHair7InMidEdgeRange    === false &&

                                        isCrossHair7WithinMidRightSquareRange === false   &&
                                        isCrossHair7WithinMidLeftCircleRange  === false   &&
                                        isCrossHair7WithinTopLeftSquareRange   === false  &&
                                        isCrossHair7WithinBottomLeftSquareRange === false 
                                        
                                        && selectedDrawingForEditInfoIdObj.isCrossHair7InRightEdgeRangeType2===undefined
                                        && selectedDrawingForEditInfoIdObj.isCrossHair7InleftEdgeRangeType2===undefined
                                        && selectedDrawingForEditInfoIdObj.isCrossHair7InTopEdgeRangeType2===undefined
                                        && selectedDrawingForEditInfoIdObj.isCrossHair7InBottomEdgeRangeType2===undefined
                                        /*&&
                                        isCrossHairWithinRectSquareRange===false*/ //- only for phone screen Touch Aauth
                                        ) 
                                        /*||
                                        ((isTouchScreenDevice || true)
                                            && isCrossHairWithinRectSquareRange===false)//for phone toucheScreens*/
                                    )
                                    ||
                                    (
                                        (isDrawingTypeTrendline8 &&
                                        isCrossHair8InRoundEdgeRange     === false &&  
                                        isTrendlineEndInSelectionRange   === false &&
                                        isTrendlineStartInSelectionRange === false /*&&
                                        isCrossHairWithinCircleRange===false*/ //- only for phone screen Touch Aauth
                                        ) 
                                        /*||
                                        ((isTouchScreenDevice || true)
                                            && isCrossHairWithinCircleRange===false)//for phone toucheScreens*/
                                    )
                                )
                            ){
                                /*if(isTouchScreenDevice){
                                    setCursorAndDrawingEditIdObj({})
                                    setSelectedDrawingForEditInfoIdObj({})
                                }
                                else{*/
                                    setSelectedDrawingForEditInfoIdObj({})
                                    setCursorAndDrawingEditIdObj({})
                                //}

                                //console.log('hey...1')
                                //console.log('yeioooooooooooooooooooooooo')
                                /*console.log({
                                    trendlineMidRangePX,
                                    drawingEditCursorOutlierLimit
                                })*/
                            }
                            else if(//setIsEditingDrawing
                                isAnyOfThePrevDrawingsIndexNotInUse 
                                &&
                                (
                                    (
                                        (isDrawingTypeTrendline6 &&
                                        isCrossHair6InTopEdgeRange    === false &&
                                        isCrossHair6InRightEdgeRange  === false &&
                                        isCrossHair6InBottomEdgeRange === false &&
                                        isCrossHair6InleftEdgeRange   === false &&
                                        
                                        isCrossHair6WithinMidTopSquareRange    === false  &&
                                        isCrossHair6WithinMidRightSquareRange  === false  &&
                                        isCrossHair6WithinMidBottomSquareRange === false  &&
                                        isCrossHair6WithinMidLeftSquareRange    === false &&
                                        isCrossHair6WithinTopRightCircleRange  === false  &&
                                        isCrossHair6WithinTopLeftCircleRange    === false &&
                                        isCrossHair6WithinBottomRightCircleRange=== false &&
                                        isCrossHair6WithinBottomLeftCircleRange  === false 
                                        
                                        /*&&
                                        isCrossHairWithinDrawingSquareRange===false*///for phone toucheScreens
                                        ) 
                                        /*||
                                        ((isTouchScreenDevice || true)
                                            && isCrossHairWithinDrawingSquareRange===false)//for phone toucheScreens*/
                                    )
                                    ||
                                    (
                                        (isDrawingTypeTrendline8 &&
                                        isCrossHair8InRoundEdgeRange     === false &&  
                                        isTrendlineEndInSelectionRange   === false &&
                                        isTrendlineStartInSelectionRange === false 
                                        
                                        /*&&
                                        isCrossHairWithinCircleRange===false*///for phone toucheScreens
                                        ) 
                                        /*||
                                        ((isTouchScreenDevice || true)
                                            && isCrossHairWithinCircleRange===false)//for phone toucheScreens*/
                                    )
                                    ||
                                    (
                                        (isDrawingTypeTrendline7 &&
                                        isCrossHair7InTopEdgeRange    === false &&
                                        isCrossHair7InRightEdgeRange  === false &&
                                        isCrossHair7InBottomEdgeRange === false &&
                                        isCrossHair7InleftEdgeRange   === false &&
                                        isCrossHair7InMidEdgeRange    === false &&

                                        isCrossHair7WithinMidRightSquareRange === false   &&
                                        isCrossHair7WithinMidLeftCircleRange  === false   &&
                                        isCrossHair7WithinTopLeftSquareRange   === false  &&
                                        isCrossHair7WithinBottomLeftSquareRange === false 
                                        
                                        /*&&
                                        isCrossHairWithinRectSquareRange===false*/ //- only for phone screen Touch Aauth
                                        ) 
                                        /*||
                                        ((isTouchScreenDevice || true)
                                            && isCrossHairWithinRectSquareRange===false)//for phone toucheScreens*/
                                    )
                                )
                                
                            ){
                                //isAnyOfThePrevDrawingsIndexNotInUse = true

                                setCursorAndDrawingEditIdObj({})
                                //console.log('hey...2')
                            }



                            if(//keep the trendline highlights on when selected by id & truthy on loop
                                isDrawingTypeTrendline8===false &&
                                isDrawingTypeTrendline7===false &&
                                isDrawingTypeTrendline6===false &&
                                selectedDrawingForEditInfoIdObj.isSelected &&
                                selectedDrawingForEditInfoIdObj.id === id
                                //&& isMouseDown === false
                            ){
                                //console.log('hello')

                                //console.log("edit TL : ", selectedDrawingForEditInfoIdObj)


                                const radian = Math.PI / 180;
                                // context.arc(centerX, centerY, radius, startAngle, endAngle, antiClockwiseDirection);


                                if(
                                    (selectedDrawingForEditInfoIdObj.isInTLmidRange===undefined || selectedDrawingForEditInfoIdObj.isInTLmidRange===false) 
                                    &&
                                    isMouseDown &&
                                    (isDrawingTypeTrendline1 ||
                                    isDrawingTypeTrendline2)
                                ){//isDTendGTdtStart

                                    if(
                                        (isDTendGTdtStart
                                        && isTrendlineEndInSelectionRange) ||
                                        (isDTendGTdtStart===false
                                            && isTrendlineEndInSelectionRange)
                                    ){
                                        context.beginPath();
                                        context.strokeStyle = "blue";
                                        context.lineWidth = 0.7;
                                        context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                        context.arc(trendlineXstartCord, (canvasChartHeight - trendlineYstartCord), drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                        context.stroke();
                                        context.fill();
                                    }

                                    if( 
                                        (isDTendGTdtStart
                                        && isTrendlineStartInSelectionRange) ||
                                        (isDTendGTdtStart===false
                                            && isTrendlineStartInSelectionRange)
                                    ){
                                        context.beginPath();
                                        context.strokeStyle = "blue";
                                        context.lineWidth = 0.7;
                                        context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                        context.arc(trendlineXendCord, (canvasChartHeight - trendlineYendCord), drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                        context.stroke();
                                        context.fill();
                                    }
                                }
                                else if(
                                    isDrawingTypeTrendline4 
                                ){

                                    const x = (canvasChartWidth * 0.5) - (drawingEditCursorOutlierLimit)
                                    const y = (canvasChartHeight - trendlineYstartCord) - (drawingEditCursorOutlierLimit)
                                    const w = (drawingEditCursorOutlierLimit*2)
                                    const h = (drawingEditCursorOutlierLimit*2)


                                    context.lineWidth = 1;
                                    context.strokeStyle = "blue";
                                    //rect(x,y,w,h)
                                    context.strokeRect(
                                        x, //x
                                        y, //y
                                        w, //w
                                        h  //h
                                    );

                                }
                                else if(
                                    isDrawingTypeTrendline5
                                ){

                                    const x = (trendlineXstartCord) - (drawingEditCursorOutlierLimit)
                                    const y = ((canvasChartHeight * 0.5) - trendlineYstartCord) - (drawingEditCursorOutlierLimit)
                                    const w = (drawingEditCursorOutlierLimit*2)
                                    const h = (drawingEditCursorOutlierLimit*2)

                                    //console.log('hello....')
                                    context.lineWidth = /*isMouseDown && selectedDrawingForEditInfoIdObj.id===id ? 0 :*/ (drawingTypeObj.isReplayLineDrawing || drawingTypeObj.isGlobalLockedVertiLine) ? 1.5 : 1;
                                    context.strokeStyle = drawingTypeObj.isReplayLineDrawing ? 'green' : drawingTypeObj.isGlobalLockedVertiLine ? 'red' : "blue";
                                    //rect(x,y,w,h)
                                    context.strokeRect(
                                        drawingTypeObj.isReplayLineDrawing && isMouseDown && selectedDrawingForEditInfoIdObj.id===id ? -500 : x, //x
                                        drawingTypeObj.isReplayLineDrawing && isMouseDown && selectedDrawingForEditInfoIdObj.id===id ? -500 : y, //y
                                        w, //w
                                        h  //h
                                    );


                                    if(isMouseDown && selectedDrawingForEditInfoIdObj.isReplayLineDrawing){
                                        //do nothing
                                    }
                                    else if(drawingTypeObj.isReplayLineDrawing){
                                        //selectedDrawingForEditInfoIdObj.id===id
                                        //context.setLineDash([5, 5]);
                                        // Draw horizontal Price Line
                                        context.beginPath(); // reset the context state
                                        context.strokeStyle =  'green' 
                                        context.lineWidth =  2 
                                        context.moveTo(trendlineXstartCord + drawingEditCursorOutlierLimit, (canvasChartHeight*0.5) - (canvasChartHeight*0.25));
                                        context.lineTo(trendlineXstartCord + drawingEditCursorOutlierLimit, ((canvasChartHeight*0.5) - (canvasChartHeight*0.25))+(drawingEditCursorOutlierLimit*2)); 
                                        context.lineTo(trendlineXstartCord + (drawingEditCursorOutlierLimit*2.5), ((canvasChartHeight*0.5) - (canvasChartHeight*0.25))+(drawingEditCursorOutlierLimit*2*0.5));
                                        context.lineTo(trendlineXstartCord + drawingEditCursorOutlierLimit, (canvasChartHeight*0.5) - (canvasChartHeight*0.25)); 
                                        context.stroke(); // draws the line
                                        //context.setLineDash([]);

                                    }
                                    
                                }
                                else{
                                    context.beginPath();
                                    context.strokeStyle = "blue";
                                    context.lineWidth = 0.7;
                                    context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                    context.arc(trendlineXstartCord, (canvasChartHeight - trendlineYstartCord), drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                    context.stroke();
                                    context.fill();

                                    if(drawingTypeObj.drawingType !== 'trendline3'){
                                        context.beginPath();
                                        context.strokeStyle = "blue";
                                        context.lineWidth = 0.7;
                                        context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                        context.arc(trendlineXendCord, (canvasChartHeight - trendlineYendCord), drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                        context.stroke();
                                        context.fill();
                                    }
                                }

                            }




                            //Auth to prevent highlights of other drawings when a drawingDragEdit hovers over other drawings
                            if(
                                isDrawingTypeTrendline8===false &&
                                isDrawingTypeTrendline6===false &&
                                isDrawingTypeTrendline7===false &&
                                //isEditingDrawing===false && 
                                crossHairMidStartXcordDrawerInfo.drawingOnDragEditCount===0
                            ){

                                if(
                                    (isDrawingTypeTrendline4===false &&
                                    isDrawingTypeTrendline5===false) &&
                                    isTrendlineEndInSelectionRange &&//the start & ends of tl are Auth 1st b4 the tl mid
                                    isAnyOfThePrevDrawingsIndexNotInUse
                                    && (crossHairOnMoveCordVals.y > (datetimeLabelMarginSize) && 
                                    crossHairOnMoveCordVals.x < (canvasChartWidth - priceLabelMarginSize))
                                    && isDrawingActivated === false
                                    && isPipCheckerActivated === false
                                    && isDrawingTypeTrendline5===false
                                ){
                                    //console.log('trendline end', drawingTypeObj)

                                    isAnyOfThePrevDrawingsIndexNotInUse = false

                                    setCursorAndDrawingEditIdObj({
                                        cursorType:"default",
                                        isInTLmidRange : false,
                                        isDrawingEndCordSelected : true,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,
                                        ...drawingTypeObj
                                    })

                                    const radian = Math.PI / 180;
                                    // context.arc(centerX, centerY, radius, startAngle, endAngle, antiClockwiseDirection);

                                    context.beginPath();
                                    context.strokeStyle = "blue";
                                    context.lineWidth = 0.7;
                                    context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                    context.arc(trendlineXstartCord, (canvasChartHeight - trendlineYstartCord), drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                    context.stroke();
                                    context.fill();

                                    if(drawingTypeObj.drawingType !== 'trendline3'){
                                        context.beginPath();
                                        context.strokeStyle = "blue";
                                        context.lineWidth = 0.7;
                                        context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                        context.arc(trendlineXendCord, (canvasChartHeight - trendlineYendCord), drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                        context.stroke();
                                        context.fill();
                                    }

                                }
                                else if(
                                    (isDrawingTypeTrendline4===false &&
                                    isDrawingTypeTrendline5===false) &&
                                    isTrendlineStartInSelectionRange &&//the start & ends of tl are Auth 1st b4 the tl mid
                                    isAnyOfThePrevDrawingsIndexNotInUse
                                    && (crossHairOnMoveCordVals.y > (datetimeLabelMarginSize) && 
                                    crossHairOnMoveCordVals.x < (canvasChartWidth - priceLabelMarginSize))
                                    && isDrawingActivated === false
                                    && isPipCheckerActivated === false
                                    && isDrawingTypeTrendline5===false
                                ){
                                    //console.log('trendline start', drawingTypeObj)

                                    isAnyOfThePrevDrawingsIndexNotInUse = false

                                    setCursorAndDrawingEditIdObj({
                                        cursorType:"default",
                                        isInTLmidRange : false,
                                        isDrawingStartCordSelected : true,
                                        isDrawingEndCordSelected : false,
                                        isDrawingMidCordSelected : false,
                                        ...drawingTypeObj
                                    })

                                    const radian = Math.PI / 180;
                                    // context.arc(centerX, centerY, radius, startAngle, endAngle, antiClockwiseDirection);

                                    context.beginPath();
                                    context.strokeStyle = "blue";
                                    context.lineWidth = 0.7;
                                    context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                    context.arc(trendlineXstartCord, (canvasChartHeight - trendlineYstartCord), drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                    context.stroke();
                                    context.fill();

                                    if(drawingTypeObj.drawingType !== 'trendline3'){
                                        context.beginPath();
                                        context.strokeStyle = "blue";
                                        context.lineWidth = 0.7;
                                        context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                        context.arc(trendlineXendCord, (canvasChartHeight - trendlineYendCord), drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                        context.stroke();
                                        context.fill();
                                    }

                                }
                                else if(//on tl mid range highlight tl
                                //false &&
                                    (
                                    //(isDrawingTypeTrendline1 && isTrendline1startInSelectionRange)
                                    //||
                                    //(isDrawingTypeTrendline8 && isTrendline8startInSelectionRange)
                                    //||
                                    (isDrawingTypeTrendline5 && isTrendline5startInSelectionRange)
                                    ||
                                    (isDrawingHeightLessThanCursorOutlierLimit===false &&
                                        isCrossHairWithinDrawingSquareRange &&
                                    trendlineMidRangePX <= drawingEditCursorOutlierLimit) 
                                    || 
                                    (
                                        isDrawingHeightLessThanCursorOutlierLimit &&
                                        isShorterTLinMidRangeAuthTruthy
                                    )
                                    )
                                    && isAnyOfThePrevDrawingsIndexNotInUse
                                    && (crossHairOnMoveCordVals.y > (datetimeLabelMarginSize) && 
                                    crossHairOnMoveCordVals.x < (canvasChartWidth - priceLabelMarginSize))
                                    && isDrawingActivated === false
                                    && isPipCheckerActivated === false
                                ){
                                    //console.log('trendline mid', drawingTypeObj)

                                    isAnyOfThePrevDrawingsIndexNotInUse = false
                                    //console.log("trendlineMidRangePX : ", trendlineMidRangePX)

                                    /*
                                    drawingTypeObj
                                    console.log({
                                        drawingType ,//: 'trendline1',
                                        datetimeStart,//   : '2023-08-22 08:00:00',
                                        datetimeEnd,//     : '2023-08-29 20:00:00',//where to start backCountDrawing from
                                        cordYpriceStart,// : 1.09188,
                                        cordYpriceEnd,//   : 1.07877,
                                        candleBoxWidthCount,// : 32,
                                        candleBoxWidthSize,//  : 10.9,
                                        tfStarter,//       : 'h4',
                                        tfEditor,//        : 'h4',
                                        id,// : Date.now()
                                    })
                                    */

                                    //console.log('helooooooooooooooooo----------------------------')

                                    setCursorAndDrawingEditIdObj({
                                        cursorType:"pointer",
                                        isInTLmidRange : true,
                                        isDrawingEndCordSelected : false,
                                        isDrawingStartCordSelected : false,
                                        isDrawingMidCordSelected : false,
                                        ...drawingTypeObj
                                    })

                                    const radian = Math.PI / 180;

                                    if(
                                        isDrawingTypeTrendline4 
                                    ){

                                        const x = (canvasChartWidth * 0.5) - (drawingEditCursorOutlierLimit)
                                        const y = (canvasChartHeight - trendlineYstartCord) - (drawingEditCursorOutlierLimit)
                                        const w = (drawingEditCursorOutlierLimit*2)
                                        const h = (drawingEditCursorOutlierLimit*2)


                                        context.lineWidth = 1;
                                        context.strokeStyle = "blue";
                                        //rect(x,y,w,h)
                                        context.strokeRect(
                                            x, //x
                                            y, //y
                                            w, //w
                                            h  //h
                                        );

                                    }
                                    else if(
                                        isDrawingTypeTrendline5
                                    ){

                                        const x = (trendlineXstartCord) - (drawingEditCursorOutlierLimit)
                                        const y = ((canvasChartHeight * 0.5) - trendlineYstartCord) - (drawingEditCursorOutlierLimit)
                                        const w = (drawingEditCursorOutlierLimit*2)
                                        const h = (drawingEditCursorOutlierLimit*2)


                                        context.lineWidth = /*isMouseDown && selectedDrawingForEditInfoIdObj.id===id ? 0 :*/ (drawingTypeObj.isReplayLineDrawing || drawingTypeObj.isGlobalLockedVertiLine) ? 1.5 : 1;
                                        context.strokeStyle = drawingTypeObj.isReplayLineDrawing ? 'green' : drawingTypeObj.isGlobalLockedVertiLine ? 'red' : "blue";
                                        //rect(x,y,w,h)
                                        context.strokeRect(
                                            x, //x
                                            y, //y
                                            w, //w
                                            h  //h
                                        );


                                        if(isMouseDown && selectedDrawingForEditInfoIdObj.isReplayLineDrawing){
                                            //do nothing
                                        }
                                        else if(drawingTypeObj.isReplayLineDrawing){
                                            //selectedDrawingForEditInfoIdObj.id===id
                                            //context.setLineDash([5, 5]);
                                            // Draw horizontal Price Line
                                            context.beginPath(); // reset the context state
                                            context.strokeStyle =  'green' 
                                            context.lineWidth =  2 
                                            context.moveTo(trendlineXstartCord + drawingEditCursorOutlierLimit, (canvasChartHeight*0.5) - (canvasChartHeight*0.25));
                                            context.lineTo(trendlineXstartCord + drawingEditCursorOutlierLimit, ((canvasChartHeight*0.5) - (canvasChartHeight*0.25))+(drawingEditCursorOutlierLimit*2)); 
                                            context.lineTo(trendlineXstartCord + (drawingEditCursorOutlierLimit*2.5), ((canvasChartHeight*0.5) - (canvasChartHeight*0.25))+(drawingEditCursorOutlierLimit*2*0.5));
                                            context.lineTo(trendlineXstartCord + drawingEditCursorOutlierLimit, (canvasChartHeight*0.5) - (canvasChartHeight*0.25)); 
                                            context.stroke(); // draws the line
                                            //context.setLineDash([]);
                                        
                                        }

                                        
                                    }
                                    else{
                                        // context.arc(centerX, centerY, radius, startAngle, endAngle, antiClockwiseDirection);
                                        //console.log('hello badu')

                                        context.beginPath();
                                        context.strokeStyle = "blue";
                                        context.lineWidth = 0.7;
                                        context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                        context.arc(trendlineXstartCord, (canvasChartHeight - trendlineYstartCord), drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                        context.stroke();
                                        context.fill();

                                        if(drawingTypeObj.drawingType !== 'trendline3'){
                                            context.beginPath();
                                            context.strokeStyle = "blue";
                                            context.lineWidth = 0.7;
                                            context.fillStyle = "rgba(255, 255, 255, 0.1)";
                                            context.arc(trendlineXendCord, (canvasChartHeight - trendlineYendCord), drawingEditCursorOutlierLimit*1, 0 * radian, 360 * radian, false);
                                            context.stroke();
                                            context.fill();
                                        }
                                    }
                                }
                                else if(isAnyOfThePrevDrawingsIndexNotInUse){
                                    //isAnyOfThePrevDrawingsIndexNotInUse = true
                                    setCursorAndDrawingEditIdObj({})
                                }

                            }

                        }
                        }
                        }


                        

                    })
               
                }



            })


            
        }
        

    }, [/*data , dataCandleStartEndIndex,*/ canvasWidth, canvasHeight, reloadAllChartsUseEffectCounter, useEffectReRunCounter]);
    //}, [data, width, height, useEffectReRunCounter]);//anytime (data, width, height) changes useEffect executes or Re-runs

return( 
<div  id={`canvasGrandParentId-${canvasCompoId}`}
style={{
  position:'relative',
  userSelect:'none'
}}
>

    {//essential in App dev state, but comment out for Production
      //essential to clear LStorage to start/test App afresh to avoid LStorage keyName change crashes 
      false &&
      <button
      style={{cursor:'pointer', backgroundColor:'pink'}}
      onClick={()=>{
          localStorage.clear()
          window.document.location.reload()
      }}
      >clear All LStorage states Objs to Allow a fresh Page Reload</button>
    }



    <h3 id={`adCompoParentId-${canvasCompoId}`}
    style={{
        //position:'relative',
        //margin: '30px auto',
        //width:'100%',
        //border:'1px solid black',
        //backgroundColor:'rgba(0, 0, 0, 0.15)',//'rgba(0, 0, 255, 0.3)',
        //height:'50px',//`${isTouchScreenDevice ? '2px' : '20px'}`
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        color:'gray',
        fontWeight:'bold',
        textAlign:'center'
        //borderTop: canvasCompoId===1 ? '1px solid rgba(0,0,0,0.2)' : '',
    }}
    >
        {true ? `(${data.length > 0 ? (chartInfoObj.candleORrsi === 'corr' ? (chartInfoObj.corrInstruName===undefined ? 'USD' : chartInfoObj.corrInstruName) + ' pairs Correlation' : 
        chartInfoObj.candleORrsi === 'rsi' ? 'Currencies index RSI' : chartInfoObj.candleORrsi === 'stoch' ? 'Currencies index Stochastic' :
        chartInfoObj.candleORrsi === 'candle' && chartInfoObj.indexORpair === 'index' ? chartInfoObj.instruName + ' index' : 
        chartInfoObj.instruName + ' pair' )
        : 'Loading...'}) - Chart #${canvasCompoId}` 
        : 'Ad#id - up/down arrow scroll onto'}

        {
          <img src={imageFAKERwhite} alt={`White Image #${canvasCompoId}`} 
          style={{
            height:'2px',
            width:'2px'
          }}
          onLoad={(e)=>{
            //console.log('image Loaded')

            // Get the element by its id
            const element = document.getElementById(`adCompoParentId-${canvasCompoId}`);
            //const element = e.target;

            if (element) {
              // Get the bounding client rect of the element
              const rect = element.getBoundingClientRect();
              // Get the Y position of the element relative to the viewport
              const yPos = rect.top + window.pageYOffset;

              //console.log('Y position of the element:', yPos);

              window.localStorage.setItem(`adCompoParentId-${canvasCompoId}` + forexORcrypto, JSON.stringify(yPos))
              
            } 
          }}
          />
        }
    </h3>


    <div id={`canvasTopBlankCompoParentId-${canvasCompoId}`}
    style={{
        backgroundColor:'rgba(0, 0, 255, 0.2)',
        //position:'relative',
        //margin: '30px auto',
        //width:'100%',
        height:'10px',//`${isTouchScreenDevice ? '2px' : '20px'}`
    }}
    >
    </div>


    
    <div id={`canvasParentId-${canvasCompoId}`}
    style={{
        position:'relative',
        margin: '0px auto',//30px
        //width:'100%',
    }}
    >
      

    <ChartHeader 

    dataLENGTH={data.length}

    setIsDATAloaded={setIsDATAloaded}

    isHideAllDrawings={isHideAllDrawings}
    setIsHideAllDrawings={setIsHideAllDrawings}

    setRsiDATETIMEnumCountObj={setRsiDATETIMEnumCountObj}
    setCorrCalcDATETIME={setCorrCalcDATETIME}
    
    canvasWidth={canvasWidth}
    chartInfoObj={chartInfoObj}
    setChartInfoObj={setChartInfoObj}
    currIndexArray={currIndexArray}
    currPairArray={currPairArray}
    tfArray={tfArray}
    forexORcrypto={forexORcrypto}
    canvasCompoId={canvasCompoId}

    nullifyAllDrawingActivitiesFUNC={nullifyAllDrawingActivitiesFUNC}

    isChartHeaderOptionsSwitchedAtleastOnce={isChartHeaderOptionsSwitchedAtleastOnce}
    setIsChartHeaderOptionsSwitchedAtleastOnce={setIsChartHeaderOptionsSwitchedAtleastOnce}

    chartInRowName={chartInRowName}
    setIsInstruTFloaded={setIsInstruTFloaded}
    setLocalInstruTFuseEffectReRunCounter={setLocalInstruTFuseEffectReRunCounter}
    
    isScreenScrollLocked={isScreenScrollLocked}
    isDontTouchChart={isDontTouchChart}
    setIsShowLockedChartMovementsBtn={setIsShowLockedChartMovementsBtn}
    setIsDontTouchChart={setIsDontTouchChart}
    />



    

    <div id={`canvasMainParentId-${canvasCompoId}`}
    style={{
        position:'relative',
        //display:`${true ? 'block' : 'none'}`
    }}
    >



        {   (isShowPsychoScreenCover && data.length>0)
            &&
            <PsychoCompo
            canvasWidth={canvasWidth}
            isShowPsychoScreenCover={isShowPsychoScreenCover}
            setIsShowPsychoScreenCover={setIsShowPsychoScreenCover}
            tfArray={tfArray}
            setUseEffectReRunCounter={setUseEffectReRunCounter}
            />
        }

        


        {//correlation settings
            (
                //chartInfoObj.candleORrsi !== 'candle' &&
                //rsiCORRdata.rsm.length > 0 &&
                isShowCorrelationScreenCover && 
                data.length>0 //&& rsiORIdata.length>0
            ) 
            
            &&
            
            <HistStrengthMeterCompo
            isRSIcorrListMaximized={isRSIcorrListMaximized}
            setIsRSIcorrListMaximized={setIsRSIcorrListMaximized}
            rsiCORRdata={rsiCORRdata}
            zero_gmt_deviation={zero_gmt_deviation}
            corrCalcDATETIME={corrCalcDATETIME}
            timeZoneDatetimeConverter={timeZoneDatetimeConverter}
            rsiORIdata={rsiORIdata}
            chartInfoObj={chartInfoObj}
            currColorObj={currColorObj}
            setWasChartLockedBeforeOnClickSettingsDisplay={setWasChartLockedBeforeOnClickSettingsDisplay}
            canvasCompoId={canvasCompoId}
            isScreenScrollLocked={isScreenScrollLocked}
            rsiDATETIMEnumCountObj={rsiDATETIMEnumCountObj}
            calcRSIcorrFUNC={calcRSIcorrFUNC}
            rsiDATETIMEobjArrayIDs={rsiDATETIMEobjArrayIDs}
            setCorrCalcDATETIME={setCorrCalcDATETIME}
            setRsiDATETIMEnumCountObj={setRsiDATETIMEnumCountObj}
            datetimeToTimestamp={datetimeToTimestamp}
            setIsShowCorrelationScreenCover={setIsShowCorrelationScreenCover}
            isShowCorrelationScreenCover={isShowCorrelationScreenCover}
            wasChartLockedBeforeOnClickSettingsDisplay={wasChartLockedBeforeOnClickSettingsDisplay}
            isTouchScreenDevice={isTouchScreenDevice}
            canvasWidth={canvasWidth}
            />
        }



       



        {
        //chart movement locked coverScreen
        //unlock chart movements
        //false&&

            <>
                {//cover Sreen with cover zIndex - to avoid chart touch
                    isDontTouchChart &&
                    
                    <div
                    style={{
                        //border:'3px solid red',
                        //backgroundColor:'rgba(0, 0, 0, 0.5)',
                        //width:'100%',
                        //height:'100%',
                        position:'absolute',
                        top:'0px',
                        right:'0px',
                        bottom:'0px',
                        left:'0px',
                        zIndex:'2'
                    }}
                    >

                    </div>
                }


                {//cover Sreen with cover zIndex - to avoid chart touch
                    (isDATAloaded===false || data.length===0)
                    &&//setIsDATAloaded
                    
                    <div
                    style={{
                        //border:'3px solid red',
                        //backgroundColor:'rgba(0, 0, 0, 0.5)',
                        //width:'100%',
                        //height:'100%',
                        position:'absolute',
                        top:'0px',
                        right:'0px',
                        bottom:'0px',
                        left:'0px',
                        zIndex:'2',
                        display:'flex',
                        justifyContent:data.length===0 ? 'center' : 'start',
                        alignItems:data.length===0 ? 'center' : 'top'
                    }}
                    >

                        <div
                        style={{
                            backgroundColor:'rgba(0, 0, 0, 0.5)',
                            color:'white',
                            paddingLeft:'25px', paddingRight:'25px',
                            height:'40px',
                            //width:'70px',
                            //borderRadius:'5px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                        >
                            Loading...
                        </div>

                    </div>
                }


            </>
            
        }



        {
            <div id={`lockButtonParent-${canvasCompoId}`} className={isScreenScrollLocked ? 'unLockBtnClass' : 'lockBtnClass' }
            
            style={{//priceLabelMarginSize
                height:`${priceLabelMarginSize}px`, 
                width:`${priceLabelMarginSize}px`, 
                backgroundColor:'rgba(255, 0,0,0.15)',
                position:'absolute',
                top:`${canvasHeight*0.07}px`,
                right:'0px',
                cursor:'pointer',
                //bottom:'',
                //left:'',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                zIndex:'2'
            }}
            
            onMouseOver={()=>{//background-color:rgba(0,0,255,0.5)
                document.getElementById(`lockButtonParent-${canvasCompoId}`).style.backgroundColor = 'rgba(0,0,255,0.5)' 

                document.getElementById(`lockButton-${canvasCompoId}`).style.color   = 'white' 
                document.getElementById(`unlockButton-${canvasCompoId}`).style.color = 'white' 
            }}

            onMouseOut={()=>{//background-color:rgba(0,0,255,0.5)
                document.getElementById(`lockButtonParent-${canvasCompoId}`).style.backgroundColor = 'rgba(255, 0,0,0.15)'

                document.getElementById(`lockButton-${canvasCompoId}`).style.color   = 'black' 
                document.getElementById(`unlockButton-${canvasCompoId}`).style.color = 'black' 
            }}
            >

                {
                    //display:none
                    //used to hide & take element out of doc completely
                    //whiles having its id event listeneer accessible to avoid error 
                }
                {
                    <div id={`lockButton-${canvasCompoId}`}
                    style={{
                        height:'100%',
                        width:'100%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        display: `${isScreenScrollLocked ? 'none' : 'flex'}`,//instead of block make display:flex if needed
                        //border:'1px solid black'
                    }}
                    >
                        <BsLock  id={`BslockIconId-${canvasCompoId}`} className={isBlinkLockChartIcon && isScreenScrollLocked===false && isTouchScreenDevice ? 'coin' : ''}
                            style={{
                                transform:'scale(1.5)',
                                //border:'1px solid black'
                            }}

                            onMouseOver={()=>{
                                document.getElementById(`BslockIconId-${canvasCompoId}`).style.transform = 'scale(1.7)' 
                            }}
                    
                            onMouseOut={()=>{
                                document.getElementById(`BslockIconId-${canvasCompoId}`).style.transform = 'scale(1.5)' 
                            }}
                        />

                        <MdKeyboardArrowDown  
                            style={{
                                transform:'scale(1.2)',
                                //border:'1px solid black'
                                
                            }}

                            onMouseOver={()=>{
                                document.getElementById(`BslockIconId-${canvasCompoId}`).style.transform = 'scale(1.7)' 
                            }}
                    
                            onMouseOut={()=>{
                                document.getElementById(`BslockIconId-${canvasCompoId}`).style.transform = 'scale(1.5)' 
                            }}
                        />
                    </div>
                    
                }

                {
                    <div id={`unlockButton-${canvasCompoId}`}
                    style={{
                        height:'100%',
                        width:'100%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        display: `${isScreenScrollLocked ? 'flex' : 'none'}` ,//instead of block make display:flex if needed
                        //border:'1px solid black'
                        
                    }}
                    >
                        <BsUnlock  id={`BsUnlockIconId-${canvasCompoId}`} className={isScreenScrollLocked && isTouchScreenDevice ? 'coin' : ''}
                            style={{
                                transform:`${isScreenScrollLocked && isTouchScreenDevice ? 'scale(2)' : 'scale(1.5)'}`,
                                //border:'1px solid black'
                            }}

                            onMouseOver={()=>{
                                document.getElementById(`BsUnlockIconId-${canvasCompoId}`).style.transform = 'scale(1.7)' 
                            }}
                    
                            onMouseOut={()=>{
                                document.getElementById(`BsUnlockIconId-${canvasCompoId}`).style.transform = 'scale(1.5)' 
                            }}
                        />

                        <MdKeyboardArrowDown  className={isScreenScrollLocked && isTouchScreenDevice ? 'coin' : ''}
                            style={{
                                transform:'scale(1.2)',
                                //border:'1px solid black'
                                
                            }}

                            onMouseOver={()=>{
                                document.getElementById(`BsUnlockIconId-${canvasCompoId}`).style.transform = 'scale(1.7)' 
                            }}
                    
                            onMouseOut={()=>{
                                document.getElementById(`BsUnlockIconId-${canvasCompoId}`).style.transform = 'scale(1.5)' 
                            }}
                        />
                    </div>
                }

            </div>
        }


        <div id={`refreshButtonParent-${canvasCompoId}`} className={isScreenScrollLocked ? 'refreshInBtnClass' : 'refreshOutBtnClass'}
        
        style={{//priceLabelMarginSize
            height:`${priceLabelMarginSize}px`, 
            width:`${priceLabelMarginSize}px`, 
            backgroundColor:'rgba(0, 0,255,0.15)',
            position:'absolute',
            bottom:`${/*(canvasHeight*0.01*1.0)+*/priceLabelMarginSize*0.5}px`,
            right:'0px',
            cursor:data.length>0 && isDontTouchChart===false ? 'pointer' : '',
            //bottom:'',
            //left:''
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            zIndex:'2'
        }}
        
        
        onMouseOver={()=>{//background-color:rgba(0,0,255,0.5)
            if(data.length>0 && isDontTouchChart===false){
                document.getElementById(`refreshButtonParent-${canvasCompoId}`).style.backgroundColor = 'rgba(0,0,255,0.5)' 

                document.getElementById(`refreshBtnId-${canvasCompoId}`).style.color   = 'white' 
            }
        }}

        onMouseOut={()=>{//background-color:rgba(0,0,255,0.5)
            if(data.length>0 && isDontTouchChart===false){
                document.getElementById(`refreshButtonParent-${canvasCompoId}`).style.backgroundColor = 'rgba(0, 0,255,0.15)'

                document.getElementById(`refreshBtnId-${canvasCompoId}`).style.color   = 'black' 
            }
        }}

        onClick={()=>{

            



            if(data.length>0 && isDontTouchChart===false){

                //alert('hello')

                if(isChartDATArefreshedAtleastOnce===false){
                  setIsChartDATArefreshedAtleastOnce(true)
                }

                if(isHideAllDrawings){ //to make All drawing Operations re-visible afresh if we had made it invisible - to make new drawings visible
                  setIsHideAllDrawings(false)
                  //setUseEffectReRunCounter(Date.now())
                }

                //if(isYmagnificationActivated){
                    setChartXvelocity(0)
                    setChartYvelocity(0)
                    setYmagnificationPercRatio(0)
                    setXmagnificationPercRatio(0)
                    setIsYmagnificationActivated(false)
                    setIsDrawingActivated(false)

                    setBeforeYmagCandleIndexInfoObj({})
        
                    setDataCandleStartEndIndex({
                        /*dataCandleStartIndex : data.length - Math.floor(canvasWidth / crossHairMidStartXcordDrawerInfo.candleWidthSize),//works when chartWidth===chartElWidth
                        dataCandleEndIndex : data.length */
                        dataCandleStartIndex : data.length - defaultNumOfCandlesToShowOnChart,
                        dataCandleEndIndex   : data.length 
                    })
        
                    setSelectedDrawingForEditInfoIdObj({})
                    setCursorAndDrawingEditIdObj({})
                //}

                
                //---------------------------------------------------------------------------------------------
                //remove all replay verti lines of a given instru_tf from LStorage
                const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                const isDataExist = drawingData !== null

                if(isDataExist){
                    const data = JSON.parse(drawingData)

                    let newData = [...data]
                    
                    newData = newData.filter((el, elIndx)=>{
                        return(el.drawingType!=='trendline5' || (el.drawingType==='trendline5' && el.isReplayLineDrawing===false) )
                    })

                    window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))//*/    

                    //suppose some drawing is making chart malfunction & we want to reset all chart appearance to default
                    //window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify([]))    

                }
                //---------------------------------------------------------------------------------------------


                

                if(isScreenScrollLocked){
                    document.getElementById(`unlockButton-${canvasCompoId}`).click()
                }

                if(isDontTouchChart){
                    setIsDontTouchChart(false)
                }



                window.localStorage.setItem("replayDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))  
                //window.localStorage.setItem("verticalDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))



                //----------------------------------------------------------------------------------------------------------------
                //onRefresh delete data from f.e storage & call for a fresh one from db
                //as most refresh may not work as data stored or updated on the f.e are not properly arranged
                //setLocalInstruTFuseEffectReRunCounter(Date.now())//to fetch/reload fresh data from db afresh, after deleting from globalDataStorageObj
                //----------------------------------------------------------------------------------------------------------------

                //ONLY: reload chart to its alltime default settings - as data.length > 0 && exists
                setUseEffectReRunCounter(Date.now())
                //alert('yeah')
            }
            else{//ONLY: fetch data from db if(data.length > 0 === false)
              if(isChartDATArefreshedAtleastOnce===false){
                setIsChartDATArefreshedAtleastOnce(true)
              }

              //ONLY: fetch data from db if(data.length > 0 === false)
              setLocalInstruTFuseEffectReRunCounter(Date.now())//to fetch/reload fresh data from db afresh, after deleting from globalDataStorageObj
            }

            //alert('hello')
            
        }}
        >
            {
                <SlRefresh id={`refreshBtnId-${canvasCompoId}`}
                style={{
                    transform:'scale(1.4)',
                }}

                onMouseOver={()=>{
                    if(data.length>0 && isDontTouchChart===false){
                    document.getElementById(`refreshBtnId-${canvasCompoId}`).style.transform = 'scale(1.6)' 
                    }
                }}
        
                onMouseOut={()=>{
                    if(data.length>0 && isDontTouchChart===false){
                    document.getElementById(`refreshBtnId-${canvasCompoId}`).style.transform = 'scale(1.4)' 
                    }
                }}
                />
            }
        </div>  




        {
            chartInfoObj.candleORrsi==='stoch' 
            
            &&

            <div

            style={{//stoch ma highlighter
                height:`${priceLabelMarginSize}px`, 
                width:`${priceLabelMarginSize}px`, 
                backgroundColor:'rgba(255, 255,255,0.5)',
                boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
                position:'absolute',
                top:`${(canvasHeight*0.07*0.7)+priceLabelMarginSize}px`,
                right:`${priceLabelMarginSize*1.2}px`,
                cursor:'pointer',
                borderRadius:'5px'
                //bottom:'',
                //left:''
                /*display:'flex',
                justifyContent:'center',
                alignItems:'center',//*/
            }}

            onClick={()=>{
                //console.log('check clicked')
                setIsShowStochMA(!isShowStochMA)
                setReloadAllChartsUseEffectCounter(Date.now())
            }}

            >

                <div
                style={{//
                    //border:'1px solid black',
                    height:'50%',
                    width:'100%',
                    fontWeight:'bold',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}
                >
                    <CgBorderStyleDashed
                    style={{
                      transform:'scale(1.5)',
                      marginTop:'4px'
                    }}
                    />
                </div>

                <div
                style={{
                    //border:'1px solid black',
                    height:'50%',
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}
                >
                    <input type="checkbox" name="" id=""
                    style={{

                    }} 
                    checked={isShowStochMA}
                    onChange={()=>{
                        //console.log('check clicked')
                        //setIsShowStochMA(!isShowStochMA)
                    }}
                    />
                </div>
                

            </div>
        }  



        {
            isLockedChartWARNING &&
            chartInfoObj.candleORrsi==='candle' 
            //&& isLockedChartWARNING
            
            &&

            <div

            style={{//stoch ma highlighter
                height:`${priceLabelMarginSize}px`, 
                //width:`${priceLabelMarginSize}px`, 
                //backgroundColor:'rgba(255, 255,255,0.5)',
                //boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
                position:'absolute',
                top:`${/*(canvasHeight*0.01*0.01)*/0+(priceLabelMarginSize*0.80)}px`,
                left:`${priceLabelMarginSize*1.2}px`,
                //cursor:'pointer',
                borderRadius:'5px',
                //bottom:'',
                //left:''
                display:'flex',
                justifyContent:'center',
                alignItems:'center',//*/
            }}

            onClick={()=>{
                //close this compo when clicked - to allow space for working with chart
            }}

            >

                {
                    //true
                    isLockIcon===false

                    ?

                    <div className="coin"
                    style={{
                        backgroundColor:'rgba(255, 255,255,0.99)',
                        boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
                        //border:'1px solid black',
                        //height:'50%',
                        //width:'100%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        borderRadius:'3px',
                        padding:'7px'//, paddingLeft:'5px'
                    }}

                    onClick={()=>{
                        //Unlock chart when clicked
                        setIsLockedChartWARNING(false)
                        
                        if(isScreenScrollLocked){
                            document.getElementById(`unlockButton-${canvasCompoId}`).click()
                            setWasChartLockedBeforeOnClickSettingsDisplay(true)
                        }
                        else{
                            document.getElementById(`lockButton-${canvasCompoId}`).click()
                            setWasChartLockedBeforeOnClickSettingsDisplay(false)
                        }
                    }}
                    >
                        <BsUnlock
                        style={{
                            //transform:'scale(1.5)'
                            width:'27px',
                            height:'27px'
                        }}
                        />
                    </div>

                    :

                    <div className="coin"
                    style={{
                        backgroundColor:'rgba(255, 255,255,0.99)',
                        boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
                        //border:'1px solid black',
                        //height:'50%',
                        //width:'100%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        borderRadius:'3px',
                        padding:'7px'//, paddingLeft:'5px'
                    }}

                    onClick={()=>{
                        //lock chart when clicked
                        setIsLockedChartWARNING(false)

                        if(isScreenScrollLocked){
                            document.getElementById(`unlockButton-${canvasCompoId}`).click()
                            setWasChartLockedBeforeOnClickSettingsDisplay(true)
                        }
                        else{
                            document.getElementById(`lockButton-${canvasCompoId}`).click()
                            setWasChartLockedBeforeOnClickSettingsDisplay(false)
                        }
                    }}
                    >
                        <BsLock
                        style={{
                            //transform:'scale(1.5)'
                            width:'27px',
                            height:'27px'
                        }}
                        />
                    </div>

                }

                


                <div className="coin_left_right"
                style={{
                    backgroundColor:'rgba(255, 255,255,0.99)',
                    boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
                    //border:'1px solid black',
                    //height:'50%',
                    //width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    borderRadius:'3px',
                    padding:'7px'//, paddingLeft:'5px'
                }}
                >
                    <FaHandPointRight
                    style={{
                        //transform:'scale(1.5)'
                        width:'27px',
                        height:'27px'
                    }}
                    />
                </div>
                

            </div>
        }  



        <div id={`zoomButtonParent-${canvasCompoId}`} className={isScreenScrollLocked ? 'zoomInBtnClass' : 'zoomOutBtnClass'}
        
        style={{//priceLabelMarginSize
            height:`${priceLabelMarginSize}px`, 
            width:`${priceLabelMarginSize}px`, 
            backgroundColor:'rgba(0, 0,255,0.15)',
            position:'absolute',
            top:`${(canvasHeight*0.07*1.5)+priceLabelMarginSize}px`,
            right:'0px',
            cursor:'pointer',
            //bottom:'',
            //left:''
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
        }}
        
        onMouseOver={()=>{//background-color:rgba(0,0,255,0.5)
            document.getElementById(`zoomButtonParent-${canvasCompoId}`).style.backgroundColor = 'rgba(0,0,255,0.5)' 

            document.getElementById(`zoomOutBtnId-${canvasCompoId}`).style.color   = 'white' 
            document.getElementById(`zoomInBtnId-${canvasCompoId}`).style.color = 'white' 
        }}

        onMouseOut={()=>{//background-color:rgba(0,0,255,0.5)
            document.getElementById(`zoomButtonParent-${canvasCompoId}`).style.backgroundColor = 'rgba(0, 0,255,0.15)'

            document.getElementById(`zoomOutBtnId-${canvasCompoId}`).style.color   = 'black' 
            document.getElementById(`zoomInBtnId-${canvasCompoId}`).style.color = 'black' 
        }}
        >


            {
                <div id={`zoomOutBtnId-${canvasCompoId}`} 
                style={{
                    height:'100%',
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    display: `${isYmagnificationActivated===false ? 'flex' : 'none'}` ,//instead of block make display:flex if needed
                    //border:'1px solid black'
                    
                }}

                onClick={()=>{//hey

                    //console.log('zoomOut stuff...')

                    


                    if(data.length>0){

                        if(chartInfoObj.candleORrsi==='candle'){
                          //console.log('zoomed out')

                            setYmagnificationPercRatio(0.3)
                            setIsYmagnificationActivated(true)
                            setBeforeYmagCandleIndexInfoObj({
                                startIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                                endIndex   : dataCandleStartEndIndex.dataCandleEndIndex,
                                candleWidthSize : crossHairMidStartXcordDrawerInfo.candleWidthSize===undefined ? canvasWidth/defaultNumOfCandlesToShowOnChart : crossHairMidStartXcordDrawerInfo.candleWidthSize,
                            })


                            /*console.log({
                                startIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                                endIndex   : dataCandleStartEndIndex.dataCandleEndIndex,
                                candleWidthSize : crossHairMidStartXcordDrawerInfo.candleWidthSize,
                            })*/
                
                            if(isScreenScrollLocked===false){
                                document.getElementById(`lockButton-${canvasCompoId}`).click()
                            }
                
                            setUseEffectReRunCounter(Date.now())

                        }

                        
                    }


                }}
                >
                    <MdZoomOutMap id={`MdZoomOutMapIconId-${canvasCompoId}`} className={isYmagnificationActivated && isTouchScreenDevice && isScreenScrollLocked===false ? 'coin' : ''}
                        style={{
                            transform:'scale(1.5)',
                        }}

                        onMouseOver={()=>{
                            document.getElementById(`MdZoomOutMapIconId-${canvasCompoId}`).style.transform = 'scale(1.7)' 
                        }}
                
                        onMouseOut={()=>{
                            document.getElementById(`MdZoomOutMapIconId-${canvasCompoId}`).style.transform = 'scale(1.5)' 
                        }}
                    />

                </div>
            }


            {
                <div id={`zoomInBtnId-${canvasCompoId}`}
                style={{
                    height:'100%',
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    display: `${isYmagnificationActivated ? 'flex' : 'none'}` ,//instead of block make display:flex if needed
                    //border:'1px solid black'
                    
                }}

                onClick={()=>{//hey

                    //console.log('zoomIn stuff...')


                    
                    //alert('i clashed')
                    
                    if(isYmagnificationActivated){
                        //alert('i am on Ymag!!!')
            
                        fromYmagToDEFAULTchartStructureFUNC()
                    }

                    //alert('hello')
                    if(isScreenScrollLocked){
                        //document.getElementById(`unlockButton-${canvasCompoId}`).click()
                    }
                    
                }}
                >
                    <MdZoomInMap  id={`MdZoomInMapIconId-${canvasCompoId}`}  className={isYmagnificationActivated && isTouchScreenDevice && isScreenScrollLocked===false ? 'coin' : ''}
                        style={{
                            transform:'scale(1.5)',
                        }}

                        onMouseOver={()=>{
                            document.getElementById(`MdZoomInMapIconId-${canvasCompoId}`).style.transform = 'scale(1.7)' 
                        }}
                
                        onMouseOut={()=>{
                            document.getElementById(`MdZoomInMapIconId-${canvasCompoId}`).style.transform = 'scale(1.5)' 
                        }}
                    />

                </div>
            }

        </div>

        {
        ((isYmagnificationActivated===false && crossHairMidStartXcordDrawerInfo.isChartStartPageOutOfView) ||
        (isYmagnificationActivated && ((beforeYmagChartInfoTracker.leftScrollXvelocityLimit - chartXvelocity) < -(canvasWidth*2)))
        ) &&
        <div style={{//priceLabelMarginSize
            height:`${priceLabelMarginSize}px`, 
            width:`${priceLabelMarginSize}px`, 
            //backgroundColor:'rgba(0, 0,255,0.2)',
            position:'absolute',
            bottom:`${canvasHeight*0.07}px`,
            right:`${priceLabelMarginSize}px`, 
            //bottom:'',
            //left:''
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            //zIndex:'2'
        }}>

            <TfiControlForward id={`forwardChartId-${canvasCompoId}`}
                style={{
                    transform:'scale(1.3)',
                    padding:'3px',
                    borderRadius:'5px',
                    //display: `${isScreenScrollLocked ? 'flex' : 'none'}` 
                    boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
                    cursor:'pointer',
                    backgroundColor:'rgba(255, 255, 255,0.9)'
                }}

                onMouseOver={()=>{//background-color:rgba(0,0,255,0.5)
                    document.getElementById(`forwardChartId-${canvasCompoId}`).style.color = 'blue'
                    document.getElementById(`forwardChartId-${canvasCompoId}`).style.transform = 'scale(1.5)' 
                }}
        
                onMouseOut={()=>{//background-color:rgba(0,0,255,0.5)
                    document.getElementById(`forwardChartId-${canvasCompoId}`).style.color   = 'black'
                    document.getElementById(`forwardChartId-${canvasCompoId}`).style.transform = 'scale(1.3)' 
                }}

                
                onClick={()=>{
                    

                    if(isYmagnificationActivated===false){
                        
                        setDataCandleStartEndIndex({
                            dataCandleStartIndex : data.length - (crossHairMidStartXcordDrawerInfo.totalCandleCountOnPage),
                            dataCandleEndIndex : data.length 
                        })
                        
                    }
                    else if(isYmagnificationActivated){

                        const numOfCandlesCouldOccupyChart   = (canvasWidth / crossHairMidStartXcordDrawerInfo.candleWidthSize)
                        let candleNumRatioToDataShiftCount = dataShiftCount / numOfCandlesCouldOccupyChart
                        candleNumRatioToDataShiftCount = numOfCandlesCouldOccupyChart * candleNumRatioToDataShiftCount
                        setChartXvelocity(
                            //-(canvasWidth /*+ (crossHairMidStartXcordDrawerInfo.candleWidthSize * dataShiftCount)*/)
                            //0
                            //Math.abs(-((numOfCandlesCouldOccupyChart - (dataShiftCount + (1 * candleNumRatioToDataShiftCount))) * crossHairMidStartXcordDrawerInfo.candleWidthSize))
                            -canvasWidth
                        )
                        setChartYvelocity(0)


                        //console.log('page forwared!!! : ', -canvasWidth)
                    }

                    setUseEffectReRunCounter(Date.now())
                }}
            />

        </div>
        }



        {data.length>0 &&
        <div style={{//priceLabelMarginSize
            //height:`${chartInfoObj.isdrawingToolOn ? canvasHeight*0.8 : (canvasHeight*0.8)/14}px`,
            width:`${(canvasHeight*0.8)/14}px`, 
            backgroundColor:'rgba(255,255,255,0.50)',
            position:'absolute',
            top:`${chartInfoObj.isdrawingToolOn ? canvasHeight*0.09 : canvasHeight*0.85}px`,
            left:'0px',
            cursor:'pointer',
            //bottom:`${chartInfoObj.isdrawingToolOn===false ? String(canvasHeight*0.1)+'px' : ''}`,
            //bottom:'',
            left:`${((canvasHeight*0.8)/14)*0.3}px`,
        }}
        
        onClick={()=>{
            //console.log('clicked')
            /*if(isScreenScrollLocked===false && isTouchScreenDevice){
                document.getElementById(`lockButton-${canvasCompoId}`).click()
            }*/
        }}
        >

            {chartInfoObj.isdrawingToolOn &&

                //[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13
                /*[//make state for it later
                    'more',//arrow up & down to hide or show certain overflowed drawings by array Id
                    'trendline1_1',//angularTL
                    'trendline1_2',//angularPointerTL
                    'trendline2',//shortHoriTL
                    'trendline3',//centerToEndHoriTL
                    'trendline4',//HoriTL
                    'trendline5',//VertiTL
                    'trendline6_1',//rect-color
                    'trendline6_2',//rect
                    'trendline7_1',//rectSquareSELL
                    'trendline7_2',//rectSquareBUY
                    'trendline8',//circle
                    'pip',//pipChecker
                    'hideDrawings'
                ]*/
                [
                    //true ? 'more' : 'less',
                    //...(true ? [1, 2, 3, 4] : [])
                    (moreORlessToolsVal==='more' ? 'more' : 'less'),
                    ...(moreORlessToolsVal==='less' ? [
                        ...(chartInfoObj.candleORrsi !== 'corr' ? ['hsm'] : []),//'hsm',
                        'replay',
                        ...(chartInfoObj.candleORrsi==='candle' && chartInfoObj.indexORpair === 'pair' ? ['psy'] : []),
                        'unHideAllTL', 
                        'deleteAllTL', 
                        'hideAllTL',
                        //'crossHair',//crossHair
                        //'arrow',//default-cursor
                        ...(chartInfoObj.candleORrsi === 'candle' ? ['text'] : []),//'text',
                    ] : []),
                    ...drawingToolsNamesArray,
                ].map((drawingToolType, drawingToolTypeIndex)=>{


                    //----------------------------------------------------------------
                    const isCandleChart = chartInfoObj.candleORrsi==='candle'
                    
                    const isRSIchart    = chartInfoObj.candleORrsi === 'rsi'
                    const isSTOCHchart    = chartInfoObj.candleORrsi === 'stoch'
                    const isCORRchart  = chartInfoObj.candleORrsi === 'corr'
                    const isINDEXchart    = chartInfoObj.indexORpair === 'index'
                    const isPAIRrsiORstochLineChart = isCandleChart===false && isINDEXchart===false

                    const RSIcurrsTLArray = [
                        'trendline3', 'trendline2', 'pip', 
                        'trendline1_2', 'trendline6_1', 
                        'trendline6_2', 'trendline7_1', 
                        'trendline7_2', 'trendline8',
                    ]
                    
                    
                    /*const RSIcurrsTLObj = {
                        'trendline3'   : 'EUR', 
                        'trendline2'   : 'GBP', 
                        'pip'          : 'AUD', 
                        'trendline1_2' : 'NZD', 
                        'trendline6_1' : 'USD', 
                        'trendline6_2' : 'CAD', 
                        'trendline7_1' : 'CHF', 
                        'trendline7_2' : 'JPY',
                    
                        'trendline8'   : 'ALL',
                        'trendline8'   : 'none'
                    }*/
                    //----------------------------------------------------------------





                    const obj = {//make a state for it later
                        'trendline1_1' : {
                            d1 : "M7.354 21.354l14-14-.707-.707-14 14z",
                            d2 : "M22.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM5.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
                        },//angularTL
                        'trendline1_2' : {
                            d1 : "M7.354 21.354l14-14-.707-.707-14 14z",
                            d2 : "M22.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM5.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z",
                            d3 : "M21 7l-8 3 5 5z"
                        },//angularPointerTL
                        'trendline2' : {
                            d1 : "M7.354 21.354l14-14-.707-.707-14 14z",
                            d2 : "M22.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM5.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
                        },//shortHoriTL
                        'trendline3' : {
                            d1 : "M8.5 15h16.5v-1h-16.5z",
                            d2 : "M6.5 16c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
                        },//centerToEndHoriTL
                        'trendline4' : {
                            d1 : "M4 15h8.5v-1h-8.5zM16.5 15h8.5v-1h-8.5z",
                            d2 : "M14.5 16c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
                        },//HoriTL
                        'trendline5' : {
                            d1 : "M15 12.5v-8.5h-1v8.5zM14 16.5v8.5h1v-8.5z",
                            d2 : "M14.5 16c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
                        },//VertiTL
                        'trendline6_1' : {
                            d1 : "M7.5 6h13v-1h-13z", 
                            d2 : "M7.5 23h13v-1h-13z",
                            d3 : "M5 7.5v13h1v-13z",
                            d4 : "M22 7.5v13h1v-13z", 
                            d5 : "M5.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM22.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM22.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM5.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
                        },//rect1
                        'trendline6_2' : {
                            d1 : "M7.5 6h13v-1h-13z", 
                            d2 : "M7.5 23h13v-1h-13z",
                            d3 : "M5 7.5v13h1v-13z",
                            d4 : "M22 7.5v13h1v-13z", 
                            d5 : "M5.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM22.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM22.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM5.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
                        },//rect2-color
                        'trendline7_1' : {
                            d1 : "M7.5 6h13v-1h-13z",
                            d2 : "M22.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM5.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
                        },//rectSquareSELL
                        'trendline7_2' : {
                            d1 : "M7.354 21.354l14-14-.707-.707-14 14z",
                            d2 : "M22.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM5.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
                        },//rectSquareBUY
                        'trendline8' : {
                            d1 : "M16 14a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z",
                            d2 : "M4.5 14a9.5 9.5 0 0 1 18.7-2.37 2.5 2.5 0 0 0 0 4.74A9.5 9.5 0 0 1 4.5 14Zm19.7 2.5a10.5 10.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5ZM22.5 14a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
                        },//circle
                        'pip' : {
                            d1 : "M7.354 21.354l14-14-.707-.707-14 14z",
                            d2 : "M22.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM5.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
                        },//pipChecker
                    }


                    if(obj[drawingToolType] === undefined){
                        return(
                            <div key={drawingToolTypeIndex} className={'drawingToolClass'} id={`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`}

                            style={{
                                borderTop:`${drawingToolTypeIndex===0 ? '1px solid gray' : ''}`,
                                borderBottom:`${drawingToolsNamesArray.length-1 === drawingToolTypeIndex  ? '' : '1px solid gray'}`,
                                height:`${(canvasHeight*0.8)/14}px`,
                                //textAlign:'center'
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                            }}

                            onMouseOver={()=>{//background-color:rgba(0,0,255,0.5)
                                //console.log('hovered - ', drawingToolTypeIndex)
                                //if(trendlineTypeToDraw==='trendline6' && isRectangleTextInput )
                                if(['more', 'less'].includes(drawingToolType) /*&& isTouchScreenDevice*/){
                                    document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.backgroundColor = 'rgba(0,0,255,0.1)' 
                                    document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.color = 'rgba(0,0,0,1.0)'//'rgba(255,255,255,1.0)' 
                                }
                                else{
                                    document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.backgroundColor = 'rgba(0,0,255,0.5)' 
                                    document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.color = 'rgba(255,255,255,1.0)' 
                                }

                                //if(trendlineTypeToDraw!==drawingToolType){
                                setHoveredDrawingToolName(drawingToolType)
                                //}
                            }}

                            onMouseOut={()=>{//background-color:rgba(0,0,255,0.5)
                                if(['more', 'less'].includes(drawingToolType) /*&& isTouchScreenDevice*/){

                                }
                                else{
                                    document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.backgroundColor = 'rgba(255,255,255,0.0)' 
                                    document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.color = 'rgba(0,0,0,1.0)' 
                                }
                                setHoveredDrawingToolName('')
                            }}

                            
                            onClick={()=>{
                                if(
                                    ['trendline1_1', 'trendline1_2', 'trendline6_1', 'trendline6_2', 'trendline7_1', 'trendline7_2'].includes(drawingToolType)
                                    ||  drawingToolType==='pip'
                                    //drawingToolType==='trendline1_1'
                                ){
                                    setTrendlineTypeToDraw2(drawingToolType)
                                    //console.log(drawingToolType)
                                }
                                else{
                                    setTrendlineTypeToDraw2('')
                                }
                            }}
                            >
                                



                                {//more
                                    drawingToolType==='more' &&

                                    <div className='moreTools'
                                    style={{
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                    }}  

                                    onClick={()=>{
                                        setMoreORlessToolsVal('less')

                                        //------------------------------------------------------------------------------------------------------------
                                        //nullify any drawing activity
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(false)
                                        setTrendlineTypeToDraw('')
                                        setIsRectangleDrawingColored(false)
                                        setIsRectangleTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //------------------------------------------------------------------------------------------------------------

                                        setUseEffectReRunCounter(Date.now())
                                    }}
                                    >
                                        <IoIosArrowUp 
                                        style={{
                                            transform:'scale(1.1)',
                                            //color:`${isTouchScreenDevice ? 'blue' : ""}`
                                        }}
                                        />
                                    </div>
                                }


                                {//less
                                    drawingToolType==='less' &&

                                    <div className='lessTools'
                                    style={{
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                    }}  

                                    onClick={()=>{
                                        setMoreORlessToolsVal('more')

                                        //------------------------------------------------------------------------------------------------------------
                                        //nullify any drawing activity
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(false)
                                        setTrendlineTypeToDraw('')
                                        setIsRectangleDrawingColored(false)
                                        setIsRectangleTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //------------------------------------------------------------------------------------------------------------

                                        setUseEffectReRunCounter(Date.now())
                                    }}
                                    >
                                        <IoIosArrowDown 
                                        style={{
                                            transform:'scale(1.1)',
                                            //color:`${isTouchScreenDevice ? 'blue' : ""}`
                                        }}
                                        />
                                    </div>
                                }


                                {//deleteAllTL
                                    drawingToolType==='deleteAllTL' &&

                                    <div className='deleteAllTLTool'
                                    style={{
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                    }}  

                                    onClick={()=>{
                                        if(isDeleteAllChartDrawings===true){
                                            setIsDeleteAllChartDrawings(false)
                                        }
                                        else{
                                            setIsDeleteAllChartDrawings(true)
                                            setIsMakeAllDrawingsVisible(false)
                                            setIsMakeAllDrawingsInvisible(false)

                                            //------------------------------------------------------------------------------------------------------------
                                            //nullify any drawing activity
                                            setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                            setIsDrawingActivated(false)
                                            setTrendlineTypeToDraw('')
                                            setIsRectangleDrawingColored(false)
                                            setIsRectangleTextInput(false)
                                            setIsPipCheckerActivated(false)
                                            setIsReplayLineDrawing(false)

                                            //------------------------------------------------------------------------------------------------------------

                                            if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                                setIsHideAllDrawings(false)
                                            }

                                            setUseEffectReRunCounter(Date.now())
                                        }
                                        
                                    }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                        viewBox="0 0 28 28" width="28" height="28">
                                            <path 
                                            fill="currentColor" 
                                            fillRule="evenodd" 
                                            d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z">
                                            </path>
                                        </svg>
                                    </div>
                                }


                                {//hideAllTL 
                                    drawingToolType==='hideAllTL' &&

                                    <div className='hideAllTLTool'
                                    style={{
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                    }}  

                                    onClick={()=>{
                                        if(isMakeAllDrawingsInvisible===true){
                                            setIsMakeAllDrawingsInvisible(false)
                                        }
                                        else{
                                            setIsDeleteAllChartDrawings(false)
                                            setIsMakeAllDrawingsVisible(false)
                                            setIsMakeAllDrawingsInvisible(true)
                                            //setIsHideAllDrawings(true)

                                            //------------------------------------------------------------------------------------------------------------
                                            //nullify any drawing activity
                                            setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                            setIsDrawingActivated(false)
                                            setTrendlineTypeToDraw('')
                                            setIsRectangleDrawingColored(false)
                                            setIsRectangleTextInput(false)
                                            setIsPipCheckerActivated(false)
                                            setIsReplayLineDrawing(false)
                                            //------------------------------------------------------------------------------------------------------------
                    
                                            setUseEffectReRunCounter(Date.now())
                                        }
                                    }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                        width="28" height="28">
                                            <path fill="currentColor" 
                                            fillRule="evenodd" 
                                            d="M19.76 6.07l-.7.7a13.4 13.4 0 011.93 2.47c.19.3.33.55.42.72l.03.04-.03.04a15 15 0 01-2.09 2.9c-1.47 1.6-3.6 3.12-6.32 3.12-.98 0-1.88-.2-2.7-.52l-.77.76c1.03.47 2.18.76 3.47.76 3.12 0 5.5-1.75 7.06-3.44a16 16 0 002.38-3.38v-.02h.01L22 10l.45.22.1-.22-.1-.22L22 10l.45-.22-.01-.02a5.1 5.1 0 00-.15-.28 16 16 0 00-2.53-3.41zM6.24 13.93l.7-.7-.27-.29a15 15 0 01-2.08-2.9L4.56 10l.03-.04a15 15 0 012.09-2.9c1.47-1.6 3.6-3.12 6.32-3.12.98 0 1.88.2 2.7.52l.77-.76A8.32 8.32 0 0013 2.94c-3.12 0-5.5 1.75-7.06 3.44a16 16 0 00-2.38 3.38v.02h-.01L4 10l-.45-.22-.1.22.1.22L4 10l-.45.22.01.02a5.5 5.5 0 00.15.28 16 16 0 002.53 3.41zm6.09-.43a3.6 3.6 0 004.24-4.24l-.93.93a2.6 2.6 0 01-2.36 2.36l-.95.95zm-1.97-3.69l-.93.93a3.6 3.6 0 014.24-4.24l-.95.95a2.6 2.6 0 00-2.36 2.36zm11.29 7.84l-.8.79a1.5 1.5 0 000 2.12l.59.59a1.5 1.5 0 002.12 0l1.8-1.8-.71-.7-1.8 1.79a.5.5 0 01-.7 0l-.59-.59a.5.5 0 010-.7l.8-.8-.71-.7zm-5.5 3.5l.35.35-.35-.35.01-.02.02-.02.02-.02a4.68 4.68 0 01.65-.5c.4-.27 1-.59 1.65-.59.66 0 1.28.33 1.73.77.44.45.77 1.07.77 1.73a2.5 2.5 0 01-.77 1.73 2.5 2.5 0 01-1.73.77h-4a.5.5 0 01-.42-.78l1-1.5 1-1.5a.5.5 0 01.07-.07zm.74.67a3.46 3.46 0 01.51-.4c.35-.24.75-.42 1.1-.42.34 0 .72.17 1.02.48.3.3.48.68.48 1.02 0 .34-.17.72-.48 1.02-.3.3-.68.48-1.02.48h-3.07l.49-.72.97-1.46zM21.2 2.5L5.5 18.2l-.7-.7L20.5 1.8l.7.7z">
                                            </path></svg>
                                    </div>
                                }



                                {//unHideAllTL 
                                    drawingToolType==='unHideAllTL' &&

                                    <div className='unHideAllTLTool'
                                    style={{
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                    }}  

                                    onClick={()=>{
                                        if(isMakeAllDrawingsVisible===true){
                                            setIsMakeAllDrawingsVisible(false)
                                        }
                                        else{
                                            setIsDeleteAllChartDrawings(false)
                                            setIsMakeAllDrawingsVisible(true)
                                            setIsMakeAllDrawingsInvisible(false)
                                            //setIsHideAllDrawings(false)

                                            //------------------------------------------------------------------------------------------------------------
                                            //nullify any drawing activity
                                            setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                            setIsDrawingActivated(false)
                                            setTrendlineTypeToDraw('')
                                            setIsRectangleDrawingColored(false)
                                            setIsRectangleTextInput(false)
                                            setIsPipCheckerActivated(false)
                                            setIsReplayLineDrawing(false)
                                            //------------------------------------------------------------------------------------------------------------
                    
                                            setUseEffectReRunCounter(Date.now())
                                        }
                                    }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                        width="28" height="28">
                                            <path fill="currentColor" 
                                            fillRule="evenodd" 
                                            d="M5 10.76l-.41-.72-.03-.04.03-.04a15 15 0 012.09-2.9c1.47-1.6 3.6-3.12 6.32-3.12 2.73 0 4.85 1.53 6.33 3.12a15.01 15.01 0 012.08 2.9l.03.04-.03.04a15 15 0 01-2.09 2.9c-1.47 1.6-3.6 3.12-6.32 3.12-2.73 0-4.85-1.53-6.33-3.12a15 15 0 01-1.66-2.18zm17.45-.98L22 10l.45.22-.01.02a5.04 5.04 0 01-.15.28 16.01 16.01 0 01-2.23 3.1c-1.56 1.69-3.94 3.44-7.06 3.44-3.12 0-5.5-1.75-7.06-3.44a16 16 0 01-2.38-3.38v-.02h-.01L4 10l-.45-.22.01-.02a5.4 5.4 0 01.15-.28 16 16 0 012.23-3.1C7.5 4.69 9.88 2.94 13 2.94c3.12 0 5.5 1.75 7.06 3.44a16.01 16.01 0 012.38 3.38v.02h.01zM22 10l.45-.22.1.22-.1.22L22 10zM3.55 9.78L4 10l-.45.22-.1-.22.1-.22zm6.8.22A2.6 2.6 0 0113 7.44 2.6 2.6 0 0115.65 10 2.6 2.6 0 0113 12.56 2.6 2.6 0 0110.35 10zM13 6.44A3.6 3.6 0 009.35 10 3.6 3.6 0 0013 13.56c2 0 3.65-1.58 3.65-3.56A3.6 3.6 0 0013 6.44zm7.85 12l.8-.8.7.71-.79.8a.5.5 0 000 .7l.59.59c.2.2.5.2.7 0l1.8-1.8.7.71-1.79 1.8a1.5 1.5 0 01-2.12 0l-.59-.59a1.5 1.5 0 010-2.12zM16.5 21.5l-.35-.35a.5.5 0 00-.07.07l-1 1.5-1 1.5a.5.5 0 00.42.78h4a2.5 2.5 0 001.73-.77A2.5 2.5 0 0021 22.5a2.5 2.5 0 00-.77-1.73A2.5 2.5 0 0018.5 20a3.1 3.1 0 00-1.65.58 5.28 5.28 0 00-.69.55v.01h-.01l.35.36zm.39.32l-.97 1.46-.49.72h3.07c.34 0 .72-.17 1.02-.48.3-.3.48-.68.48-1.02 0-.34-.17-.72-.48-1.02-.3-.3-.68-.48-1.02-.48-.35 0-.75.18-1.1.42a4.27 4.27 0 00-.51.4z">
                                            </path></svg>
                                    </div>
                                }


                                {//text 
                                    drawingToolType==='text' &&

                                    <div className='textTool'
                                    style={{
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        color:`${trendlineTypeToDraw==='trendline6'&& isDrawingActivated && isRectangleTextInput ? 'white' : ''}`,
                                        backgroundColor:`${trendlineTypeToDraw==='trendline6'&& isDrawingActivated && isRectangleTextInput &&
                                        hoveredDrawingToolName !== drawingToolType
                                        ? 'rgba(0,0,255,0.5)' : ''}`
                                    }}  

                                    onClick={()=>{
                                        if(trendlineTypeToDraw==='trendline6'&& isDrawingActivated && isRectangleTextInput){
                                            nullifyAllChartDrawingsFUNC()
                                        }
                                        else{
                                            setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                            setIsDrawingActivated(true)
                                            setTrendlineTypeToDraw('trendline6')
                                            setIsRectangleDrawingColored(false)
                                            setIsRectangleTextInput(true)
                                            setIsPipCheckerActivated(false)
                                            setIsReplayLineDrawing(false)

                                            setIsDeleteAllChartDrawings(false)
                                            setIsMakeAllDrawingsVisible(false)
                                            setIsMakeAllDrawingsInvisible(false)
                                            //setIsDisplayChartTextInput(false)
                                            //setIsDisplayChartTextInput(true)
                                            //console.log('trendline6 - Uncolored')

                                            if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                            setIsHideAllDrawings(false)
                                            }

                                            //setUseEffectReRunCounter(Date.now())
                                            if(isScreenScrollLocked===false && isTouchScreenDevice){
                                                document.getElementById(`lockButton-${canvasCompoId}`).click()
                                            }

                                            setUseEffectReRunCounter(Date.now())
                                        }
                                    }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                                            <path fill="currentColor" d="m9.5 5C8.68 5 8 5.67 8 6.5v2h1v-2c0-.27.23-.5.5-.5H14v16h-2v1h5v-1h-2V6h4.5c.28 0 .5.22.5.5v2h1v-2c0-.83-.67-1.5-1.5-1.5h-10z">
                                        </path>
                                        </svg>
                                    </div>
                                }



                                {//csm 
                                    drawingToolType==='hsm' &&

                                    <div className='csmBtnTool'
                                    style={{
                                        //fontWeight:'bold',
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                    }}  

                                    
                                    onClick={()=>{
                                        if(isTouchScreenDevice && isScreenScrollLocked && isShowCorrelationScreenCover===false){
                                            document.getElementById(`unlockButton-${canvasCompoId}`).click()
                                            setWasChartLockedBeforeOnClickSettingsDisplay(true)
                                        }
                                        else if(isTouchScreenDevice && wasChartLockedBeforeOnClickSettingsDisplay && isScreenScrollLocked===false && isShowCorrelationScreenCover){
                                            document.getElementById(`lockButton-${canvasCompoId}`).click()
                                            setWasChartLockedBeforeOnClickSettingsDisplay(false)
                                        }
                                
                                        //-----------------------------------------------------------------------------------------------------------------------------------
                                
                                        if(
                                        //false&&
                                        authDatetimeStrValidityBeforeDrawORedit(replayModeInfo)//(replayLineDrawingArray[replayLineDrawingArray.length-1]).datetimeStart)
                                        && (prevReplayModeInfo==='' || authDatetimeStrValidityBeforeDrawORedit(prevReplayModeInfo))
                                        && replayModeInfo !== prevReplayModeInfo
                                        ){
                                        //console.log('hey Ghana')
                                        //prevReplayDATETIME('')
                                        //console.log({replayModeInfo, prevReplayModeInfo})
                                
                                        let value = replayModeInfo
                                        let numVal = Math.abs((rsiORIdata.length + 1) - (Number(rsiDATETIMEobjArrayIDs[value]) + 1))
                                        numVal = numVal + 1
                                        numVal = numVal < 0 ? 0 : numVal
                                
                                        setCorrCalcDATETIME({
                                            //...corrCalcDATETIME,
                                            datetimeStart : rsiDATETIMEobjArrayIDs[Math.abs((rsiORIdata.length - 1) - (numVal - 1))],//value,
                                            datetimeEnd   : rsiDATETIMEobjArrayIDs[Math.abs((rsiORIdata.length - 1) - (numVal - 1))]//value
                                        })
                                
                                        setRsiDATETIMEnumCountObj({
                                            //...rsiDATETIMEnumCountObj,
                                            datetimeStart : numVal,
                                            datetimeEnd   : numVal
                                        })
                                
                                        //------------------------------------------------------------------------------------
                                        //corr calc
                                        const ARRAYstartIndex = numVal//8//(Number(rsiDATETIMEobjArrayIDs[replayModeInfo]))//Number(numVal-1-1)
                                        const ARRAYendIndex   = numVal//8//(Number(rsiDATETIMEobjArrayIDs[replayModeInfo]))//Number(numVal-1-1)
                                        //console.log({ARRAYstartIndex, ARRAYendIndex, len:rsiORIdata.length})
                                
                                        if(ARRAYstartIndex>=1 && ARRAYendIndex>=1){
                                            calcRSIcorrFUNC(rsiORIdata, ARRAYstartIndex, ARRAYendIndex)
                                        }
                                        //-----------------------------------------------------------------------------------
                                        
                                        setPrevReplayModeInfo(replayModeInfo)
                                        }
                                
                                        
                                
                                        setIsShowCorrelationScreenCover(!isShowCorrelationScreenCover)
                                        //console.log('settings Clicked')



                                        //------------------------------------------------------------------------------------------------------------
                                        //nullify any drawing activity
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(false)
                                        setTrendlineTypeToDraw('')
                                        setIsRectangleDrawingColored(false)
                                        setIsRectangleTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)
                                        //------------------------------------------------------------------------------------------------------------
                                        
                                    }}
                                    >
                                        {'hsm'}
                                    </div>
                                }



                                {//csm 
                                    drawingToolType==='psy' &&

                                    <div className='psychoTool'
                                    style={{
                                        //fontWeight:'bold',
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'
                                    }}  

                                    
                                    onClick={()=>{
                                        
                                        setIsShowPsychoScreenCover(!isShowPsychoScreenCover)


                                        //------------------------------------------------------------------------------------------------------------
                                        //nullify any drawing activity
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(false)
                                        setTrendlineTypeToDraw('')
                                        setIsRectangleDrawingColored(false)
                                        setIsRectangleTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)
                                        //------------------------------------------------------------------------------------------------------------
                                        
                                    }}
                                    >
                                        {'Psy'}
                                    </div>
                                }



                                {//replay 
                                    drawingToolType==='replay' &&

                                    <div className='replayTool'
                                    style={{
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        color:`${trendlineTypeToDraw==='trendline5'&& isDrawingActivated && isReplayLineDrawing ? 'white' : ''}`,
                                        backgroundColor:`${trendlineTypeToDraw==='trendline5'&& isDrawingActivated && isReplayLineDrawing &&
                                        hoveredDrawingToolName !== drawingToolType
                                        ? 'rgba(0,0,255,0.5)' : ''}`
                                    }}  

                                    onClick={()=>{
                                        if(trendlineTypeToDraw==='trendline5'&& isDrawingActivated && isReplayLineDrawing){
                                            nullifyAllChartDrawingsFUNC()
                                        }
                                        else{
                                            setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                            setIsDrawingActivated(true)
                                            setTrendlineTypeToDraw('trendline5')
                                            setIsRectangleDrawingColored(false)
                                            setIsRectangleTextInput(false)
                                            setIsPipCheckerActivated(false)
                                            setIsReplayLineDrawing(true)

                                            setIsDeleteAllChartDrawings(false)
                                            setIsMakeAllDrawingsVisible(false)
                                            setIsMakeAllDrawingsInvisible(false)
                                            //setIsDisplayChartTextInput(false)
                                            //setIsDisplayChartTextInput(true)
                                            //console.log('trendline6 - Uncolored')

                                            if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                            setIsHideAllDrawings(false)
                                            }

                                            if(isScreenScrollLocked===false && isTouchScreenDevice){
                                                document.getElementById(`lockButton-${canvasCompoId}`).click()
                                            }

                                            setUseEffectReRunCounter(Date.now())
                                        }
                                    }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                                            <path fill="none" stroke="currentColor" d="M13.5 20V9l-6 5.5 6 5.5zM21.5 20V9l-6 5.5 6 5.5z"></path>
                                        </svg>
                                    </div>
                                }


                                {//arrow 
                                    drawingToolType==='arrow' &&

                                    <div className='arrowTool'
                                    style={{
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                    }}  

                                    onClick={()=>{//import { LiaHandPointer } from "react-icons/lia";

                                        //------------------------------------------------------------------------------------------------------------
                                        //nullify any drawing activity
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(false)
                                        setTrendlineTypeToDraw('')
                                        setIsRectangleDrawingColored(false)
                                        setIsRectangleTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //------------------------------------------------------------------------------------------------------------

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                          setIsHideAllDrawings(false)
                                        }

                                        //setUseEffectReRunCounter(Date.now())
                                        setUseEffectReRunCounter(Date.now())
                                    }}
                                    >

                                        <LiaHandPointer
                                            style={{transform:'scale(1.3)'}}
                                        />

                                    </div>
                                }


                                {//cross Hair 
                                    drawingToolType==='crossHair' &&

                                    <div className='crossHairTool'
                                    style={{
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                    }}  

                                    onClick={()=>{

                                        //------------------------------------------------------------------------------------------------------------
                                        //nullify any drawing activity
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(false)
                                        setTrendlineTypeToDraw('')
                                        setIsRectangleDrawingColored(false)
                                        setIsRectangleTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //------------------------------------------------------------------------------------------------------------

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                          setIsHideAllDrawings(false)
                                        }

                                        //setUseEffectReRunCounter(Date.now())
                                        setUseEffectReRunCounter(Date.now())
                                    }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                                            <g fill="currentColor"><path d="M18 15h8v-1h-8z"></path><path d="M14 18v8h1v-8zM14 3v8h1v-8zM3 15h8v-1h-8z">
                                            </path></g></svg>
                                    </div>
                                }


                            </div>
                        )
                    }
                    else if(obj[drawingToolType] && drawingToolTypeIndex+1 <= 13){
                        return(
                            <div key={drawingToolTypeIndex} className='drawingToolClass' id={`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`}
                            style={{
                                borderTop:`${drawingToolTypeIndex===0 ? '1px solid gray' : ''}`,
                                borderBottom:`${drawingToolsNamesArray.length === drawingToolTypeIndex  ? '' : '1px solid gray'}`,
                                height:`${(canvasHeight*0.8)/14}px`,
                                //textAlign:'center'
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                position:'relative',
                                backgroundColor:`${//sReplayLineDrawing
                                    (isDrawingActivated && 
                                    (trendlineTypeToDraw===drawingToolType ||
                                        (['trendline1_1', 'trendline1_2', 'trendline6_1', 'trendline6_2', 'trendline7_1', 'trendline7_2'].includes(trendlineTypeToDraw2) &&
                                        drawingToolType===trendlineTypeToDraw2
                                        )
                                    ))
                                    ||  (isPipCheckerActivated && drawingToolType==='pip' && drawingToolType===trendlineTypeToDraw2)
                                    ? 'rgba(0,0,255,0.5)' : 'rgba(255,255,255,0.0)'
                                }`,
                                color:`${
                                    (isDrawingActivated && 
                                    (trendlineTypeToDraw===drawingToolType ||
                                        (['trendline1_1', 'trendline1_2', 'trendline6_1', 'trendline6_2', 'trendline7_1', 'trendline7_2'].includes(trendlineTypeToDraw2) &&
                                        drawingToolType===trendlineTypeToDraw2
                                        )
                                    ))
                                    ||  (isPipCheckerActivated && drawingToolType==='pip' && drawingToolType===trendlineTypeToDraw2)
                                    ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)' 
                                }`,

                                //transform:`${drawingToolType==='trendline2' ? 'rotate(45deg)' : ''}`
                            }}


                            onMouseOver={()=>{

                                if(
                                    isCandleChart ||
                                    (isCandleChart===false && RSIcurrsTLArray.includes(drawingToolType)===false)
                                ){
                                    document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.backgroundColor = 'rgba(0,0,255,0.5)' 
                                    document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.color = 'rgba(255,255,255,1)' 
                                    if(drawingToolType==='trendline6_1'){document.getElementById(`coloredRectMidToolId-${drawingToolTypeIndex}-${canvasCompoId}`).style.backgroundColor = 'rgba(255,255,255,1)'}
                                    
                                    setHoveredDrawingToolName(drawingToolType)
                                }

                            }}


                            onMouseOut={()=>{

                                if(
                                    isCandleChart ||
                                    (isCandleChart===false && RSIcurrsTLArray.includes(drawingToolType)===false)
                                ){

                                    if(
                                        (isDrawingActivated &&
                                        ['trendline1_1', 'trendline1_2', 'trendline6_1', 'trendline6_2', 'trendline7_1', 'trendline7_2'].includes(trendlineTypeToDraw2)
                                        && drawingToolType===trendlineTypeToDraw2) 
                                        ||  (isPipCheckerActivated && drawingToolType==='pip' && drawingToolType===trendlineTypeToDraw2)
                                    ){
                                        document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.backgroundColor = 'rgba(0,0,255,0.5)' 
                                        document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.color = 'rgba(255,255,255,1)' 
                                        if(drawingToolType==='trendline6_1'){document.getElementById(`coloredRectMidToolId-${drawingToolTypeIndex}-${canvasCompoId}`).style.backgroundColor = 'rgba(255,255,255,1)'}
                                    }
                                    else{
                                        document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.backgroundColor = isDrawingActivated && trendlineTypeToDraw===drawingToolType ? 'rgba(0,0,255,0.5)' : 'rgba(255,255,255,0.0)'
                                        document.getElementById(`drawingToolId-${canvasCompoId}-${drawingToolTypeIndex}`).style.color = 'rgba(0,0,0,1)' 
                                        if(drawingToolType==='trendline6_1'){document.getElementById(`coloredRectMidToolId-${drawingToolTypeIndex}-${canvasCompoId}`).style.backgroundColor = 'rgba(0,0,0,1)'}
                                    }

                                }
                                
                                setHoveredDrawingToolName('')
                            }}


                            onClick={(e)=>{

                                if(
                                    isCandleChart ||
                                    (isCandleChart===false && RSIcurrsTLArray.includes(drawingToolType)===false)
                                ){

                                    if(isScreenScrollLocked===false && isTouchScreenDevice){
                                        document.getElementById(`lockButton-${canvasCompoId}`).click()
                                    }

                                }

                                //[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13
                                /*[//make state for it later
                                    'more',//arrow up & down to hide or show certain overflowed drawings by array Id
                                    'trendline1_1',//angularTL
                                    'trendline1_2',//angularPointerTL
                                    'trendline2',//shortHoriTL
                                    'trendline3',//centerToEndHoriTL
                                    'trendline4',//HoriTL
                                    'trendline5',//VertiTL
                                    'trendline6_1',//rect-color
                                    'trendline6_2',//rect
                                    'trendline7_1',//rectSquareSELL
                                    'trendline7_2',//rectSquareBUY
                                    'trendline8',//circle
                                    'pip',//pipChecker
                                    'hideDrawings'
                                ]*/

                                if(
                                    isCandleChart ||
                                    (isCandleChart===false && RSIcurrsTLArray.includes(drawingToolType)===false)
                                ){

                                    if(
                                        ['trendline1_1', 'trendline1_2', 'trendline6_1', 'trendline6_2', 'trendline7_1', 'trendline7_2'].includes(drawingToolType)
                                        ||  drawingToolType==='pip'
                                        //drawingToolType==='trendline1_1'
                                    ){
                                        setTrendlineTypeToDraw2(drawingToolType)
                                        //console.log(drawingToolType)
                                    }
                                    else{
                                        setTrendlineTypeToDraw2('')
                                    }

                                }
                                else{
                                    setTrendlineTypeToDraw2('')
                                }


                                if(isCandleChart && drawingToolType==='trendline1_1'){//Draw Trendline
                                    //alert('hey...')
                                    if(trendlineTypeToDraw==='trendline1' && isDrawingActivated===true){
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(true)
                                        setTrendlineTypeToDraw('trendline1')
                                        setIsTrendlineEndPointy(false)
                                        setIsRectangleTextInput(false)
                                        setIsDisplayChartTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //console.log('trendline1')

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                        setIsHideAllDrawings(false)
                                        }

                                        setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                else if(isCandleChart && drawingToolType==='trendline1_2'){//Draw Pointy Trendline
                                    if(trendlineTypeToDraw==='trendline1' && isDrawingActivated===true && isTrendlineEndPointy===true){
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(true)
                                        setTrendlineTypeToDraw('trendline1')
                                        setIsTrendlineEndPointy(true)
                                        setIsRectangleTextInput(false)
                                        setIsDisplayChartTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //console.log('trendline1')

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                        setIsHideAllDrawings(false)
                                        }

                                        setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                else if(isCandleChart && drawingToolType==='trendline2'){//Draw short hori line
                                    if(trendlineTypeToDraw==='trendline2' && isDrawingActivated===true){
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(true)
                                        setTrendlineTypeToDraw('trendline2')
                                        setIsRectangleTextInput(false)
                                        setIsDisplayChartTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //console.log('trendline2')

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                        setIsHideAllDrawings(false)
                                        }

                                        setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                else if(isCandleChart && drawingToolType==='trendline3'){//Draw horiCenterToEnd line
                                    if(trendlineTypeToDraw==='trendline3' && isDrawingActivated===true){
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(true)
                                        setTrendlineTypeToDraw('trendline3')
                                        setIsRectangleTextInput(false)
                                        setIsDisplayChartTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //console.log('trendline3')

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                        setIsHideAllDrawings(false)
                                        }

                                        setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                else if(drawingToolType==='trendline4'){//Draw Horizontal Line
                                    if(trendlineTypeToDraw==='trendline4' && isDrawingActivated===true){
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(true)
                                        setTrendlineTypeToDraw('trendline4')
                                        setIsRectangleTextInput(false)
                                        setIsDisplayChartTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //console.log('trendline4')

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                        setIsHideAllDrawings(false)
                                        }

                                        setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                else if(drawingToolType==='trendline5'){//Draw Vertical Line
                                    if(trendlineTypeToDraw==='trendline5' && isDrawingActivated===true){
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(true)
                                        setTrendlineTypeToDraw('trendline5')
                                        setIsRectangleTextInput(false)
                                        setIsDisplayChartTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //console.log('trendline5')

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                        setIsHideAllDrawings(false)
                                        }

                                        setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                else if(isCandleChart && drawingToolType==='trendline8'){//Draw Circle
                                    if(trendlineTypeToDraw==='trendline8' && isDrawingActivated===true){
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setIsDrawingActivated(true)
                                        setTrendlineTypeToDraw('trendline8')
                                        setIsRectangleTextInput(false)
                                        setIsDisplayChartTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //console.log('trendline8')

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                        setIsHideAllDrawings(false)
                                        }

                                        setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                else if(isCandleChart && drawingToolType==='trendline6_1'){//Draw Rectangle Colored
                                    if(trendlineTypeToDraw==='trendline6' && isDrawingActivated===true && isRectangleDrawingColored===true){
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(true)
                                        setTrendlineTypeToDraw('trendline6')
                                        setIsRectangleDrawingColored(true)
                                        setIsRectangleTextInput(false)
                                        setIsDisplayChartTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //console.log('trendline6 - colored')

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                        setIsHideAllDrawings(false)
                                        }

                                        setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                else if(isCandleChart && drawingToolType==='trendline6_2'){//Draw Rectangle Uncolored
                                    if(trendlineTypeToDraw==='trendline6' && isDrawingActivated===true && isRectangleDrawingColored===false){
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(true)
                                        setTrendlineTypeToDraw('trendline6')
                                        setIsRectangleDrawingColored(false)
                                        setIsRectangleTextInput(false)
                                        setIsDisplayChartTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //console.log('trendline6 - Uncolored')

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                        setIsHideAllDrawings(false)
                                        }

                                        setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                else if(isCandleChart && drawingToolType==='trendline7_1'){//Draw Rectangle Square SELL
                                    if(trendlineTypeToDraw==='trendline7' && isDrawingActivated===true && isRectangleSquareDrawingSell===true){
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(true)
                                        setTrendlineTypeToDraw('trendline7')
                                        setIsRectangleSquareDrawingSell(true)
                                        setIsRectangleTextInput(false)
                                        setIsDisplayChartTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //console.log('trendline7 - SELL')

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                        setIsHideAllDrawings(false)
                                        }

                                        setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                else if(isCandleChart && drawingToolType==='trendline7_2'){//Draw Rectangle Square BUY
                                    if(trendlineTypeToDraw==='trendline7' && isDrawingActivated===true && isRectangleSquareDrawingSell===false){
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(true)
                                        setTrendlineTypeToDraw('trendline7')
                                        setIsRectangleSquareDrawingSell(false)
                                        setIsRectangleTextInput(false)
                                        setIsDisplayChartTextInput(false)
                                        setIsPipCheckerActivated(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //console.log('trendline7 - BUY')

                                        if(isHideAllDrawings){ //Operation to make All drawing re-visible if we had made it invisible - to make new drawings visible
                                        setIsHideAllDrawings(false)
                                        }

                                        setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                if(isCandleChart && drawingToolType==='pip'){
                                    if(isPipCheckerActivated===true){
                                        setIsPipCheckerActivated(false)
                                        nullifyAllChartDrawingsFUNC()
                                    }
                                    else{
                                        setIsPipCheckerActivated(true)

                                        //------------------------------------------------------------------------------------------------------------
                                        //nullify any drawing activity
                                        setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                                        setIsDrawingActivated(false)
                                        setTrendlineTypeToDraw('')
                                        setIsRectangleDrawingColored(false)
                                        setIsRectangleTextInput(false)
                                        setIsReplayLineDrawing(false)

                                        setIsDeleteAllChartDrawings(false)
                                        setIsMakeAllDrawingsVisible(false)
                                        setIsMakeAllDrawingsInvisible(false)
                                        //------------------------------------------------------------------------------------------------------------
                                        
                                        //setUseEffectReRunCounter(Date.now())
                                    }
                                }
                                
                               
                            }}
                            >
                                {
                                    (
                                        drawingToolType==='trendline6_1' 
                                        && isCandleChart
                                        //|| drawingToolType==='trendline6_2'
                                    ) 
                                    &&
                                    <div id={`coloredRectMidToolId-${drawingToolTypeIndex}-${canvasCompoId}`}
                                    style={{
                                        position:'absolute',
                                        fontSize:'12px',
                                        width:'10px',
                                        height:'10px',
                                        //backgroundColor:'rgba(0, 0, 0, 0.55)',
                                        backgroundColor:`${
                                            (isDrawingActivated && 
                                            (trendlineTypeToDraw===drawingToolType ||
                                                (['trendline1_1', 'trendline1_2', 'trendline6_1', 'trendline6_2', 'trendline7_1', 'trendline7_2'].includes(trendlineTypeToDraw2) &&
                                                drawingToolType===trendlineTypeToDraw2
                                                )
                                            ))
                                            ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,0.55)'
                                        }`,

                                    }}
                                    >
                                        {/*drawingToolType==='trendline6_1' ? 1 : 2*/}
                                    </div>
                                }

                                {

                                drawingToolType==='pip' ?

                                (
                                    isCandleChart ?

                                    <div style={{fontSize:'14px', userSelect:'none'}}>
                                        {'Pip'}
                                    </div>

                                    :

                                    <div id={`AUD-select-${canvasCompoId}-id`}//chartNum + cryptoORforex- for only LStorage
                                    
                                    style={{
                                        fontSize:'14px',
                                        //border:'1px solid black',
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        backgroundColor:`${currsHighlightedArray.includes('AUD') ? 
                                        currColorObj2['AUD']//.replace('rgb','rgba').replace(')',',0.5)') 
                                        : '' }`,
                                        color : currsHighlightedArray.includes('AUD') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
                                    }}
                                    
                                    onMouseOver={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`AUD-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('AUD') ? 'rgba(255,255,255,0.5)' : currColorObj2['AUD']
                                        document.getElementById(`AUD-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('AUD') ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)' 
                                        
                                        
                                        currHighlightHoveredFunc('AUD')
                                      }
                                    }}
        
        
                                    onMouseOut={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`AUD-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('AUD') ? currColorObj2['AUD'] : 'rgba(255,255,255,0.5)'  
                                        document.getElementById(`AUD-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('AUD') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'  
                                        
                                        
                                        currHighlightHoveredFunc('') 
                                      }
                                    }}


                                    onClick={()=>{
                                      if(isPAIRrsiORstochLineChart && chartInfoObj.instruName.includes('AUD')){
                                        switchPairChartTOindex()
                                      }
                                      else if(isPAIRrsiORstochLineChart){}
                                      else{
                                        selectUnselectCurrHighlighterFunc('AUD')
                                      }
                                    }}
                                    
                                    >
                                        {isCORRchart && chartInfoObj.corrInstruName === 'AUD' ? ' - ' : 'AUD'}
                                    </div>
                                )
                                

                                :

                                drawingToolType==='trendline8' ?

                                (
                                    isCandleChart ?

                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
                                        <path stroke={
                                            (isDrawingActivated && 
                                                (trendlineTypeToDraw===drawingToolType ||
                                                    (['trendline1_1', 'trendline1_2', 'trendline6_1', 'trendline6_2', 'trendline7_1', 'trendline7_2'].includes(trendlineTypeToDraw2) &&
                                                    drawingToolType===trendlineTypeToDraw2
                                                    )
                                                ))
                                                ? "white" :
                                            "currentColor"} 
                                        d="M16 14a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"></path><path fill={
                                            (isDrawingActivated && 
                                                (trendlineTypeToDraw===drawingToolType ||
                                                    (['trendline1_1', 'trendline1_2', 'trendline6_1', 'trendline6_2', 'trendline7_1', 'trendline7_2'].includes(trendlineTypeToDraw2) &&
                                                    drawingToolType===trendlineTypeToDraw2
                                                    )
                                                ))
                                                ? "white" :
                                            "currentColor"}  fillRule="evenodd" 
                                        d="M4.5 14a9.5 9.5 0 0 1 18.7-2.37 2.5 2.5 0 0 0 0 4.74A9.5 9.5 0 0 1 4.5 14Zm19.7 2.5a10.5 10.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5ZM22.5 14a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"></path>
                                    </svg>

                                    :

                                    <div id={`ALL-select-${canvasCompoId}-id`}//chartNum + cryptoORforex- for only LStorage
                                    
                                    style={{
                                        fontSize:'14px',
                                        //border:'1px solid black',
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        backgroundColor:currColorObj2[`${currsHighlightedArray.length > 4 ? 'none' : 'ALL'}`],
                                        color:`${currsHighlightedArray.length > 4 ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)'}`
                                    }}
                                    
                                    onMouseOver={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`ALL-select-${canvasCompoId}-id`).style.backgroundColor = currColorObj2[`${currsHighlightedArray.length > 4===false ? 'none' : 'ALL'}`]
                                        document.getElementById(`ALL-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.length > 4===false ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)'
                                        
                                        
                                        currHighlightHoveredFunc(currsHighlightedArray.length > 4 ? 'none' : 'ALL') 
                                      }
                                    }}
        
        
                                    onMouseOut={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`ALL-select-${canvasCompoId}-id`).style.backgroundColor = currColorObj2[`${currsHighlightedArray.length > 4 ? 'none' : 'ALL'}`]
                                        document.getElementById(`ALL-select-${canvasCompoId}-id`).style.color           = `${currsHighlightedArray.length > 4 ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)'}`
                                        
                                        
                                        currHighlightHoveredFunc('')
                                      }
                                    }}


                                    onClick={()=>{
                                      if(isPAIRrsiORstochLineChart /*&& chartInfoObj.instruName.includes('')*/){
                                        //switchPairChartTOindex()
                                      }
                                      else if(isPAIRrsiORstochLineChart){}
                                      else{
                                        selectUnselectCurrHighlighterFunc(currsHighlightedArray.length > 4 ? 'none' : 'ALL')
                                      }
                                    }}
                                    
                                    >
                                        {
                                            currsHighlightedArray.length > 4 ? 'none' : 'ALL'
                                        }
                                    </div>
                                )

                                

                                :

                                drawingToolType==='trendline7_1' ?

                                (
                                    isCandleChart ?

                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" 
                                            d="M4.5 24a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM2 22.5a2.5 2.5 0 0 0 4.95.5H24v-1H6.95a2.5 2.5 0 0 0-4.95.5zM4.5 14a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM2 12.5a2.5 2.5 0 0 0 4.95.5h13.1a2.5 2.5 0 1 0 0-1H6.95a2.5 2.5 0 0 0-4.95.5zM22.5 14a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-18-6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM2 6.5a2.5 2.5 0 0 0 4.95.5H24V6H6.95A2.5 2.5 0 0 0 2 6.5z"></path>
                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" 
                                            d="M22.4 20.06l-1.39-.63-.41.91 1.39.63.41-.91zm-4-1.8l-1.39-.63-.41.91 1.39.63.41-.91zm-4-1.8l-1.4-.63-.4.91 1.39.63.41-.91zm-4-1.8L9 14.03l-.4.91 1.39.63.41-.91z">
                                        </path>
                                    </svg>

                                    :

                                    <div id={`CHF-select-${canvasCompoId}-id`}//chartNum + cryptoORforex- for only LStorage
                                    
                                    style={{
                                        fontSize:'14px',
                                        //border:'1px solid black',
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        backgroundColor:`${currsHighlightedArray.includes('CHF') ? 
                                        currColorObj2['CHF']//.replace('rgb','rgba').replace(')',',0.5)') 
                                        : '' }`,
                                        color : currsHighlightedArray.includes('CHF') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
                                    }}
                                    
                                    onMouseOver={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`CHF-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('CHF') ? 'rgba(255,255,255,0.5)' : currColorObj2['CHF']
                                        document.getElementById(`CHF-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('CHF') ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)' 
                                        
                                      
                                        currHighlightHoveredFunc('CHF')   
                                      }
                                    }}
        
        
                                    onMouseOut={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`CHF-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('CHF') ? currColorObj2['CHF'] : 'rgba(255,255,255,0.5)'  
                                        document.getElementById(`CHF-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('CHF') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)' 
                                        
                                      
                                        currHighlightHoveredFunc('')     
                                      }
                                    }}


                                    onClick={()=>{
                                      if(isPAIRrsiORstochLineChart && chartInfoObj.instruName.includes('CHF')){
                                        switchPairChartTOindex()
                                      }
                                      else if(isPAIRrsiORstochLineChart){}
                                      else{
                                        selectUnselectCurrHighlighterFunc('CHF')
                                      }
                                    }}
                                    
                                    >
                                        {isCORRchart && chartInfoObj.corrInstruName === 'CHF' ? ' - ' : 'CHF'}
                                    </div>
                                )

                                

                                :

                                drawingToolType==='trendline7_2' ?

                                (
                                    isCandleChart ?

                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" 
                                            d="M4.5 5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2 6.5A2.5 2.5 0 0 1 6.95 6H24v1H6.95A2.5 2.5 0 0 1 2 6.5zM4.5 15a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2 16.5a2.5 2.5 0 0 1 4.95-.5h13.1a2.5 2.5 0 1 1 0 1H6.95A2.5 2.5 0 0 1 2 16.5zM22.5 15a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-18 6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2 22.5a2.5 2.5 0 0 1 4.95-.5H24v1H6.95A2.5 2.5 0 0 1 2 22.5z"></path>
                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" 
                                            d="M22.4 8.94l-1.39.63-.41-.91 1.39-.63.41.91zm-4 1.8l-1.39.63-.41-.91 1.39-.63.41.91zm-4 1.8l-1.4.63-.4-.91 1.39-.63.41.91zm-4 1.8l-1.4.63-.4-.91 1.39-.63.41.91z">
                                        </path>
                                    </svg>

                                    :

                                    <div id={`JPY-select-${canvasCompoId}-id`}//chartNum + cryptoORforex- for only LStorage
                                    
                                    style={{
                                        fontSize:'14px',
                                        //border:'1px solid black',
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        backgroundColor:`${/*isCORRchart && chartInfoObj.corrInstruName === 'JPY' ? 'rgba(255,255,255,0.0)' : */
                                            currsHighlightedArray.includes('JPY') ? 
                                        currColorObj2['JPY']//.replace('rgb','rgba').replace(')',',0.5)') 
                                        : '' }`,
                                        color : currsHighlightedArray.includes('JPY') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
                                    }}
                                    
                                    onMouseOver={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`JPY-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('JPY') ? 'rgba(255,255,255,0.5)' : currColorObj2['JPY']
                                        document.getElementById(`JPY-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('JPY') ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)'
                                        
                                        
                                        currHighlightHoveredFunc('JPY')   
                                      }
                                    }}
        
        
                                    onMouseOut={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`JPY-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('JPY') ? currColorObj2['JPY'] : 'rgba(255,255,255,0.5)'  
                                        document.getElementById(`JPY-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('JPY') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)' 
                                        
                                        
                                        currHighlightHoveredFunc('')    
                                      }
                                    }}


                                    onClick={()=>{
                                      if(isPAIRrsiORstochLineChart && chartInfoObj.instruName.includes('JPY')){
                                        switchPairChartTOindex()
                                      }
                                      else if(isPAIRrsiORstochLineChart){}
                                      else{
                                        selectUnselectCurrHighlighterFunc('JPY')
                                      }
                                    }}
                                    
                                    >
                                        {isCORRchart && chartInfoObj.corrInstruName === 'JPY' ? ' - ' : 'JPY'}
                                    </div>
                                )



                                :

                                (isCandleChart===false && drawingToolType==='trendline3') ?

                                (
                                    <div id={`EUR-select-${canvasCompoId}-id`}//chartNum + cryptoORforex- for only LStorage
                                    
                                    style={{
                                        fontSize:'14px',
                                        //border:'1px solid black',
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        backgroundColor:`${currsHighlightedArray.includes('EUR') ? 
                                        currColorObj2['EUR']//.replace('rgb','rgba').replace(')',',0.5)') 
                                        : '' }`,
                                        color : currsHighlightedArray.includes('EUR') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
                                    }}
                                    
                                    onMouseOver={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`EUR-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('EUR') ? 'rgba(255,255,255,0.5)' : currColorObj2['EUR']
                                        document.getElementById(`EUR-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('EUR') ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)' 
                                        
                                        
                                        currHighlightHoveredFunc('EUR') 
                                      }
                                    }}
        
        
                                    onMouseOut={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`EUR-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('EUR') ? currColorObj2['EUR'] : 'rgba(255,255,255,0.5)'  
                                        document.getElementById(`EUR-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('EUR') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)' 
                                        
                                        
                                        currHighlightHoveredFunc('')   
                                      }
                                    }}


                                    onClick={()=>{
                                      if(isPAIRrsiORstochLineChart && chartInfoObj.instruName.includes('EUR')){
                                        switchPairChartTOindex()
                                      }
                                      else if(isPAIRrsiORstochLineChart){}
                                      else{
                                        selectUnselectCurrHighlighterFunc('EUR')
                                      }
                                    }}
                                    
                                    >
                                        {isCORRchart && chartInfoObj.corrInstruName === 'EUR' ? ' - ' : 'EUR'}
                                    </div>
                                )



                                :

                                (isCandleChart===false && drawingToolType==='trendline2') ?

                                (
                                    <div id={`GBP-select-${canvasCompoId}-id`}//chartNum + cryptoORforex- for only LStorage
                                    
                                    style={{
                                        fontSize:'14px',
                                        //border:'1px solid black',
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        backgroundColor:`${currsHighlightedArray.includes('GBP') ? 
                                        currColorObj2['GBP']//.replace('rgb','rgba').replace(')',',0.5)') 
                                        : '' }`,
                                        color : currsHighlightedArray.includes('GBP') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
                                    }}
                                    
                                    onMouseOver={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`GBP-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('GBP') ? 'rgba(255,255,255,0.5)' : currColorObj2['GBP']
                                        document.getElementById(`GBP-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('GBP') ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)' 
                                        
                                        
                                        currHighlightHoveredFunc('GBP') 
                                      }
                                    }}
        
        
                                    onMouseOut={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`GBP-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('GBP') ? currColorObj2['GBP'] : 'rgba(255,255,255,0.5)'  
                                        document.getElementById(`GBP-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('GBP') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'  
                                        
                                        
                                        currHighlightHoveredFunc('')  
                                      }
                                    }}


                                    onClick={()=>{
                                      if(isPAIRrsiORstochLineChart && chartInfoObj.instruName.includes('GBP')){
                                        switchPairChartTOindex()
                                      }
                                      else if(isPAIRrsiORstochLineChart){}
                                      else{
                                        selectUnselectCurrHighlighterFunc('GBP')
                                      }
                                    }}
                                    
                                    >
                                        {isCORRchart && chartInfoObj.corrInstruName === 'GBP' ? ' - ' : 'GBP'}
                                    </div>
                                )


                                :

                                (isCandleChart===false && drawingToolType==='trendline1_2') ?

                                (
                                    <div id={`NZD-select-${canvasCompoId}-id`}//chartNum + cryptoORforex- for only LStorage
                                    
                                    style={{
                                        fontSize:'14px',
                                        //border:'1px solid black',
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        backgroundColor:`${currsHighlightedArray.includes('NZD') ? 
                                        currColorObj2['NZD']//.replace('rgb','rgba').replace(')',',0.5)') 
                                        : '' }`,
                                        color : currsHighlightedArray.includes('NZD') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
                                    }}
                                    
                                    onMouseOver={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`NZD-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('NZD') ? 'rgba(255,255,255,0.5)' : currColorObj2['NZD']
                                        document.getElementById(`NZD-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('NZD') ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)' 
                                        
                                        
                                        currHighlightHoveredFunc('NZD') 
                                      }
                                    }}
        
        
                                    onMouseOut={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`NZD-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('NZD') ? currColorObj2['NZD'] : 'rgba(255,255,255,0.5)'  
                                        document.getElementById(`NZD-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('NZD') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)' 
                                        
                                        
                                        currHighlightHoveredFunc('')   
                                      }
                                    }}


                                    onClick={()=>{
                                      if(isPAIRrsiORstochLineChart && chartInfoObj.instruName.includes('NZD')){
                                        switchPairChartTOindex()
                                      }
                                      else if(isPAIRrsiORstochLineChart){}
                                      else{
                                        selectUnselectCurrHighlighterFunc('NZD')
                                      }
                                    }}
                                    
                                    >
                                        {isCORRchart && chartInfoObj.corrInstruName === 'NZD' ? ' - ' : 'NZD'}
                                    </div>
                                )


                                :

                                (isCandleChart===false && drawingToolType==='trendline6_1') ?

                                (
                                    <div id={`USD-select-${canvasCompoId}-id`}//chartNum + cryptoORforex- for only LStorage
                                    
                                    style={{
                                        fontSize:'14px',
                                        //border:'1px solid black',
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        backgroundColor:`${currsHighlightedArray.includes('USD') ? 
                                        currColorObj2['USD']//.replace('rgb','rgba').replace(')',',0.5)') 
                                        : '' }`,
                                        color : currsHighlightedArray.includes('USD') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
                                    }}
                                    
                                    onMouseOver={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`USD-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('USD') ? 'rgba(255,255,255,0.5)' : currColorObj2['USD']
                                        document.getElementById(`USD-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('USD') ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)'
                                        
                                        
                                        currHighlightHoveredFunc('USD')   
                                      }
                                    }}
        
        
                                    onMouseOut={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`USD-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('USD') ? currColorObj2['USD'] : 'rgba(255,255,255,0.5)'  
                                        document.getElementById(`USD-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('USD') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)' 
                                        
                                        
                                        currHighlightHoveredFunc('')    
                                      }
                                    }}


                                    onClick={()=>{
                                      if(isPAIRrsiORstochLineChart && chartInfoObj.instruName.includes('USD')){
                                        switchPairChartTOindex()
                                      }
                                      else if(isPAIRrsiORstochLineChart){}
                                      else{
                                        selectUnselectCurrHighlighterFunc('USD')
                                      }
                                    }}
                                    
                                    >
                                        {isCORRchart && chartInfoObj.corrInstruName === 'USD' ? ' - ' : 'USD'}
                                    </div>
                                )


                                :

                                (isCandleChart===false && drawingToolType==='trendline6_2') ?

                                (
                                    <div id={`CAD-select-${canvasCompoId}-id`}//chartNum + cryptoORforex- for only LStorage
                                    
                                    style={{
                                        fontSize:'14px',
                                        //border:'1px solid black',
                                        height:'100%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        backgroundColor:`${currsHighlightedArray.includes('CAD') ? 
                                        currColorObj2['CAD']//.replace('rgb','rgba').replace(')',',0.5)') 
                                        : '' }`,
                                        color : currsHighlightedArray.includes('CAD') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
                                    }}
                                    
                                    onMouseOver={()=>{
                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`CAD-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('CAD') ? 'rgba(255,255,255,0.5)' : currColorObj2['CAD']
                                        document.getElementById(`CAD-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('CAD') ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)'
                                        
                                        
                                        currHighlightHoveredFunc('CAD') 
                                      }
                                    }}
        
        
                                    onMouseOut={()=>{

                                      if(isPAIRrsiORstochLineChart===false){
                                        document.getElementById(`CAD-select-${canvasCompoId}-id`).style.backgroundColor = currsHighlightedArray.includes('CAD') ? currColorObj2['CAD'] : 'rgba(255,255,255,0.5)'  
                                        document.getElementById(`CAD-select-${canvasCompoId}-id`).style.color           = currsHighlightedArray.includes('CAD') ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)' 
                                        
                                        
                                        currHighlightHoveredFunc('')  
                                      }
                                    }}


                                    onClick={()=>{

                                        if(isPAIRrsiORstochLineChart && chartInfoObj.instruName.includes('CAD')){
                                          switchPairChartTOindex()
                                        }
                                        else if(isPAIRrsiORstochLineChart){}
                                        else{
                                          selectUnselectCurrHighlighterFunc('CAD')
                                        }
                                    }}
                                    
                                    >
                                        {isCORRchart && chartInfoObj.corrInstruName === 'CAD' ? ' - ' : 'CAD'}
                                    </div>
                                )

                                

                                :
                                    
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 28 28" 
                                    width={drawingToolType==='trendline2' ? "27" : "28"} 
                                    height="28"
                                    //style={{backgroundColor:`${drawingToolType==='trendline6_1' ? `rgba(0, 0, 0, 0.2)` : ""}`}}
                                >   
                                    <g  transform={drawingToolType==='trendline2' ? `rotate(45, ${28/2}, ${28/2})` : ""}//width="28" /2  -  height="28"/2
                                        //style="background-color:green"
                                        //style={{backgroundColor:`${drawingToolType==='trendline6_1' ? `blue` : ""}`}}
                                        //fill="currentColor" 
                                        fill={isDrawingActivated && trendlineTypeToDraw===drawingToolType ? "white" : "currentColor"}
                                        //</svg>/fill={/*hoveredDrawingToolName===drawingToolType ? "white" :*/ "currentColor" } 
                                        fillRule="nonzero"
                                    >
                                        <path d={obj[drawingToolType].d1 === undefined ? "" : obj[drawingToolType].d1}></path>
                                        <path d={obj[drawingToolType].d2 === undefined ? "" : obj[drawingToolType].d2}></path>
                                        <path d={obj[drawingToolType].d3 === undefined ? "" : obj[drawingToolType].d3}></path>
                                        <path d={obj[drawingToolType].d4 === undefined ? "" : obj[drawingToolType].d4}></path>
                                        <path d={obj[drawingToolType].d5 === undefined ? "" : obj[drawingToolType].d5}></path>
                                    </g>
                                </svg>

                                }
                            
                            </div>
                        )
                    }
                    else{

                    }

                })
            }

            

            {
                <div id={`drawToolsAccessElId-${canvasCompoId}`}
                style={{
                    //border:'1px solid black',
                    height:`${(canvasHeight*0.8)/14}px`,
                    textAlign:'center',
                    backgroundColor:'rgba(0,0,255,0.5)',
                    color:'white',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                }}

                onClick={()=>{
                    const chartSettingsObjToSaveInLStorage = {...chartInfoObj, isdrawingToolOn : !chartInfoObj.isdrawingToolOn}
                    setChartInfoObj(chartSettingsObjToSaveInLStorage)

                    window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

                    setMoreORlessToolsVal('more')

                    //------------------------------------------------------------------------------------------------------------
                    //nullify any drawing activity
                    setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
                    setTrendlineTypeToDraw('')
                    setIsRectangleDrawingColored(false)
                    setIsDrawingActivated(false)
                    setIsRectangleTextInput(false)
                    setIsPipCheckerActivated(false)
                    setIsReplayLineDrawing(false)

                    setIsDeleteAllChartDrawings(false)
                    setIsMakeAllDrawingsVisible(false)
                    setIsMakeAllDrawingsInvisible(false)
                    //------------------------------------------------------------------------------------------------------------
                }}

                onMouseOver={()=>{
                    document.getElementById(`drawToolsAccessElId-${canvasCompoId}`).style.transform = chartInfoObj.isdrawingToolOn ? 'scale(1.0)' : 'scale(1.05)' 
                }}
        
                onMouseOut={()=>{
                    document.getElementById(`drawToolsAccessElId-${canvasCompoId}`).style.transform = 'scale(1.0)' 
                }}
                >
                    {//14
                        chartInfoObj.isdrawingToolOn
                        ?
                        <MdOutlineArrowBackIosNew id={`closeToolsIconId-${canvasCompoId}`}
                            style={{
                                transform:'scale(1.4)',
                                //display: `${isScreenScrollLocked ? 'flex' : 'none'}` 
                            }}
                            
                        />

                        :

                        <MdOutlineDraw  id={`drawToolsIconId-${canvasCompoId}`}
                            style={{
                                transform:'scale(1.5)',
                                //display: `${isScreenScrollLocked ? 'flex' : 'none'}` 
                            }}

                            onMouseOver={()=>{
                                document.getElementById(`drawToolsIconId-${canvasCompoId}`).style.transform = 'scale(1.6)' 
                            }}
                    
                            onMouseOut={()=>{
                                document.getElementById(`drawToolsIconId-${canvasCompoId}`).style.transform = 'scale(1.4)' 
                            }}
                        />
                    }
                </div>
            }

        </div>
        }


        {isScreenScrollLocked &&
            <div style={{//priceLabelMarginSize
                //height:`${priceLabelMarginSize*0.75}px`, 
                //width:`${priceLabelMarginSize*4}px`, 
                //backgroundColor:'rgba(0, 0,255,0.2)',
                position:'absolute',
                bottom:`${canvasHeight*(0.06)}px`,
                right:`${priceLabelMarginSize*1.2}px`, 
                //cursor:'pointer',
                //bottom:'',
                //left:''
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                //border:'2px solid green',
                color:'blue',
                fontWeight:'bold',
                //fontSize:'10px',
                fontSize:'13px',
            }}>
                <span //className="coin" 
                style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <BsLock
                    />
                </span> <span className="coin"
                >Chart Locked</span>  
            </div>
        }


        


        {isDontTouchChart &&
            <div style={{//priceLabelMarginSize
                //height:`${priceLabelMarginSize*0.75}px`, 
                //width:`${priceLabelMarginSize*4}px`, 
                //backgroundColor:'rgba(0, 0,255,0.2)',
                position:'absolute',
                bottom:`${canvasHeight*(0.06)}px`,
                right:`${priceLabelMarginSize*1.2}px`, 
                //cursor:'pointer',
                //bottom:'',
                //left:''
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                //border:'2px solid green',
                color:'blue',
                fontWeight:'bold',
                //fontSize:'10px',
            }}>
                <span //className="coin" 
                style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <MdDoNotTouch
                    />
                </span> <span className="coin"
                >Chart Touch Locked</span>  
            </div>
        }


        {isChartReplayOn &&
            <div style={{//priceLabelMarginSize
                //height:`${priceLabelMarginSize*0.75}px`, 
                //width:`${priceLabelMarginSize*4}px`, 
                //backgroundColor:'rgba(0, 0,255,0.2)',
                position:'absolute',
                bottom:`${canvasHeight*( 
                    (isScreenScrollLocked || isDontTouchChart) ? 0.06*1.5 : 0.06)}px`,
                right:`${priceLabelMarginSize*1.2}px`, 
                //cursor:'pointer',
                //bottom:'',
                //left:''
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                //border:'2px solid green',
                color:'blue',
                fontWeight:'bold',
                //fontSize:'10px',
            }}>
                <span //className="coin" 
                style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="25" height="25">
                        <path fill="none" stroke="currentColor" d="M13.5 20V9l-6 5.5 6 5.5zM21.5 20V9l-6 5.5 6 5.5z">
                            </path>
                    </svg>
                </span> <span className="coin" style={{fontSize:'13px',}}
                >Replay on</span>  
            </div>
        }




        {
            //editing should be false to show any
            (
                (selectedDrawingForEditInfoIdObj.isSelected &&
                isMouseDown===false) 
                ||
                (selectedDrawingForEditInfoIdObj.isSelected===undefined ||
                selectedDrawingForEditInfoIdObj.isSelected===false)
            )

            && 

            (isTouchScreenDevice || (isTouchScreenDevice===false && isEditingDrawing===false) )
           
            &&

            (
                (
                    selectedDrawingForEditInfoIdObj.isSelected && selectedDrawingForEditInfoIdObj.id
                    //&& selectedDrawingForEditInfoIdObj.isInTLmidRange
                )
                ||
                isLockedVertiLineSelected
                ||
                (isDeleteAllChartDrawings || isMakeAllDrawingsVisible || isMakeAllDrawingsInvisible) 
                || 
                (
                    selectedDrawingForEditInfoIdObj.isSelected && selectedDrawingForEditInfoIdObj.id && isDisplayChartTextInput
                ) 
                
            ) 
            
            &&
        
        <div style={{//priceLabelMarginSize
            height:`${priceLabelMarginSize*0.75}px`, 
            width:`${priceLabelMarginSize*4}px`, 
            //backgroundColor:'rgba(0, 0,255,0.2)',
            position:'absolute',
            top:`${canvasHeight*0.03}px`,
            right:`${window.innerWidth <= 600 ? priceLabelMarginSize+(priceLabelMarginSize*0.2) : (priceLabelMarginSize+(priceLabelMarginSize*0.2)) * (1+(window.innerWidth/600))}px`, 
            cursor:'pointer',
            //bottom:'',
            //left:''
            display:'flex',
            border:'1px solid gray'
        }}>

            {
            (isDeleteAllChartDrawings || isMakeAllDrawingsVisible || isMakeAllDrawingsInvisible)  
            ?

            <div
            style={{
                width:'100%',
                height:'100%',
                display:'flex',
            }}
            >
                <div
                style={{
                    width:'100%',
                    height:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    fontSize:'12px',
                    fontWeight:'bold',
                    backgroundColor:'rgba(255,255,255,0.8)',
                    backgroundColor:'rgba(0,0,0,0.7)',
                    color:'rgba(255,255,255,1)'
                }}
                >
                    {
                        isMakeAllDrawingsVisible
                        ? 'All Drawings Visible' : 
                        isMakeAllDrawingsInvisible ? 'Hide All drawings' : 'Delete All drawings'
                    }
                </div>

                <div
                style={{
                    width:'30%',
                    height:'100%',
                    //backgroundColor:'rgba(0,0,255,0.1)',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    borderRight:'1px solid gray',
                    borderLeft:'1px solid gray',
                    backgroundColor:'rgba(255,255,255,0.9)'
                }}

                onClick={()=>{

                   if(isMakeAllDrawingsVisible){
                        setIsHideAllDrawings(false)
                        setIsMakeAllDrawingsVisible(false)
                        setUseEffectReRunCounter(Date.now())
                   }
                   else if(isMakeAllDrawingsInvisible){
                        setIsHideAllDrawings(true)
                        setIsMakeAllDrawingsInvisible(false)
                        setUseEffectReRunCounter(Date.now())
                   }
                   else if(isDeleteAllChartDrawings){
                        const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                        const isDataExist = drawingData !== null

                        if(isDataExist){
                            window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify([]))
                            
                            setCursorAndDrawingEditIdObj({})
                            setSelectedDrawingForEditInfoIdObj({})

                            //setUseEffectReRunCounter(Date.now())
                        }
                        else{
                            window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify([]))

                            setCursorAndDrawingEditIdObj({})
                            setSelectedDrawingForEditInfoIdObj({})

                            //setUseEffectReRunCounter(Date.now())
                        }

                        window.localStorage.setItem("replayDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))  
                        window.localStorage.setItem("verticalDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))

                        const newObj = {
                            selected : [],
                            selectedObj : {
                                10 : ['m5', 'm15'], 
                                25 : ['m5', 'm15', 'm30'],  
                                50 : ['m5', 'm15', 'm30', 'h1',],  
                                75 : ['m15', 'm30', 'h1', 'h4'],  
                                100 : ['m30', 'h1', 'h4', 'd1'],  
                                125 : ['m30', 'h1', 'h4','d1'],  
                                150 : [ 'm30', 'h1', 'h4', 'd1'],  
                                175 : ['h1', 'h4', 'd1'],  
                                250 : ['h4', 'd1', 'w1'],  
                                500 : ['d1', 'w1', 'mn'],  
                                750 : ['d1', 'w1', 'mn'],  
                                1000 : ['d1', 'w1', 'mn']
                            }
                        }
                        
                        
                        
                        window.localStorage.setItem(`psychoLevel`, JSON.stringify(newObj))  

                        
                        

                        setIsDeleteAllChartDrawings(false)

                        setUseEffectReRunCounter(Date.now())
                        setReloadAllChartsUseEffectCounter(Date.now())
                    }
                }}
                >
                    {
                        <FiCheck   id={`correctIcon2Id-${canvasCompoId}`}
                            style={{
                                transform:'scale(1.5)',
                                color:'green'
                            }}

                            onMouseOver={()=>{
                                document.getElementById(`correctIcon2Id-${canvasCompoId}`).style.transform = 'scale(1.7)' 
                            }}
                    
                            onMouseOut={()=>{
                                document.getElementById(`correctIcon2Id-${canvasCompoId}`).style.transform = 'scale(1.5)' 
                            }}

                            
                        />
                    }
                </div>

                <div
                style={{
                    width:'30%',
                    height:'100%',
                    //backgroundColor:'rgba(0,0,255,0.1)',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    backgroundColor:'rgba(255,255,255,0.9)'
                }}

                onClick={()=>{
                    setIsDeleteAllChartDrawings(false)
                    setIsMakeAllDrawingsVisible(false)
                    setIsMakeAllDrawingsInvisible(false)
                }}
                >
                    {
                        <GrClose   id={`wrongIcon1Id-${canvasCompoId}`}
                            style={{
                                transform:'scale(1.1)',
                                color:'red'
                            }}

                            onMouseOver={()=>{
                                document.getElementById(`wrongIcon1Id-${canvasCompoId}`).style.transform = 'scale(1.3)' 
                            }}
                    
                            onMouseOut={()=>{
                                document.getElementById(`wrongIcon1Id-${canvasCompoId}`).style.transform = 'scale(1.1)' 
                            }}
                        />
                    }
                </div>
            </div> 

            :


            (selectedDrawingForEditInfoIdObj.isSelected && selectedDrawingForEditInfoIdObj.id && isDisplayChartTextInput) ?

            <div
            style={{
                width:'100%',
                height:'100%',
                display:'flex',
            }}
            >
                <div
                style={{
                    width:'100%',
                    height:'100%',
                    display:'flex',
                }}
                >
                    <input type="text" name="" id={`chartTextInputId-${canvasCompoId}`} placeholder='Enter Text'
                    style={{
                        width:'100%',
                        //height:'100%',
                        border:'0px'
                    }}
                    value={chartTextInputStringTracker} 
                    onChange={(e)=>{
                        setChartTextInputStringTracker(e.target.value)
                    }}
                    />
                </div>

                <div
                style={{
                    width:'30%',
                    height:'100%',
                    //backgroundColor:'rgba(0,0,255,0.1)',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    borderRight:'1px solid gray',
                    borderLeft:'1px solid gray',
                    backgroundColor:'rgba(255,255,255,0.8)'
                }}
                >
                    {
                        <FiCheck id={`correctIcon1Id-${canvasCompoId}`}
                            style={{
                                transform:'scale(1.5)',
                                color:'green'
                            }}

                            onMouseOver={()=>{
                                document.getElementById(`correctIcon1Id-${canvasCompoId}`).style.transform = 'scale(1.7)' 
                            }}
                    
                            onMouseOut={()=>{
                                document.getElementById(`correctIcon1Id-${canvasCompoId}`).style.transform = 'scale(1.5)' 
                            }}


                            onClick={(e)=>{
                                /*console.log({
                                    chartTextInputStringTracker,
                                    id:selectedDrawingForEditInfoIdObj.id
                                })*/
            

                                if(
                                    (
                                        selectedDrawingForEditInfoIdObj.id === (
                                            window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto) !== null &&
                                            JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto))[selectedDrawingForEditInfoIdObj.id] &&
                                            JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto))[selectedDrawingForEditInfoIdObj.id]
                                        )
                                    )
                                ){
                                    //console.log('global delete')
            
                                    //window.localStorage.setItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))
        
                                    const drawingData = localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto)
                                    const isDataExist = drawingData !== null
                    
                                    //now as well map & store the newObj into localStorage
                                    if(isDataExist){
                                        const data = JSON.parse(drawingData)
        
                                        let newData = []
        
                                        for(let obj in data){
                                            newData.push(data[obj])
                                        }
                                        
                                        let isIdExists = false
                                        let isDataExistId = -1
                                        newData = newData.map((el, elInd)=>{
                                            if(el.id===selectedDrawingForEditInfoIdObj.id){
                                                isIdExists = true
                                                isDataExistId = elInd
                                                return {...el, text : chartTextInputStringTracker}
                                            }
                                            else{
                                                return el
                                            }
                                        })
                                        
                                        if(isIdExists && isDataExistId!== -1){
                                            //console.log({newData:newData[0].text})
                                            //setChartTextInputStringTracker(String(newData[0].text))
                                            const obj = {}
                                            obj[selectedDrawingForEditInfoIdObj.id] = newData[isDataExistId]
                                            window.localStorage.setItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto, 
                                            JSON.stringify(obj))
                                        }
                                    }
            
            
                                    setChartTextInputStringTracker('')
                                    setIsDisplayChartTextInput(false)
                                    setReloadAllChartsUseEffectCounter(Date.now())//reloads all charts
            
                                }
                                else{
                                    const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                                    const isDataExist = drawingData !== null
                
                                    //now as well map & store the newObj into localStorage
                                    if(isDataExist){
                                        const data = JSON.parse(drawingData)
                
                                        let newData = [...data]
                                        
                                        
                                        newData = newData.map((el, elInd)=>{
                                            if(el.id===selectedDrawingForEditInfoIdObj.id){
                                                return {...el, text : chartTextInputStringTracker}
                                            }
                                            else{
                                                return el
                                            }
                                        })
                
                                        //alert(newData)
                                        window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))
                
                                        setChartTextInputStringTracker('')
                                        setIsDisplayChartTextInput(false)
                                        setUseEffectReRunCounter(Date.now())
                                    }

                                }
                                
                            }}
                        />
                    }
                </div>

                <div
                style={{
                    width:'30%',
                    height:'100%',
                    //backgroundColor:'rgba(0,0,255,0.1)',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    backgroundColor:'rgba(255,255,255,0.8)'
                }}

                onClick={()=>{
                    setIsDisplayChartTextInput(false)
                    setChartTextInputStringTracker('')
                }}
                >
                    {
                        <GrClose  id={`wrongIcon1Id-${canvasCompoId}`}
                            style={{
                                transform:'scale(1.1)',
                                color:'red'
                            }}

                            onMouseOver={()=>{
                                document.getElementById(`wrongIcon1Id-${canvasCompoId}`).style.transform = 'scale(1.3)' 
                            }}
                    
                            onMouseOut={()=>{
                                document.getElementById(`wrongIcon1Id-${canvasCompoId}`).style.transform = 'scale(1.1)' 
                            }}
                        />
                    }
                </div>
            </div> 

            :

            <div
            style={{
                width:'100%',
                height:'100%',
                display:'flex',
            }}
            >

                {
                ((isLockedVertiLineSelected===false
                && ['trendline2', 'trendline3', 'trendline4', 'trendline5', 'trendline6', 'trendline8'].includes(selectedDrawingForEditInfoIdObj.drawingType))
                ) 
                
                &&

                <div id={`textTLbtn-${canvasCompoId}`}
                style={{
                    width:'100%',
                    height:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    backgroundColor:'rgba(255,255,255, 0.85)',
                    fontWeight:'bold',
                }}

                

                onMouseOver={()=>{//background-color:rgba(0,0,255,0.5)
                    document.getElementById(`textTLbtn-${canvasCompoId}`).style.backgroundColor = 'rgba(0,0,255,0.6)' 
                    document.getElementById(`textTLbtn-${canvasCompoId}`).style.color   = 'white' 
                }}
    
                onMouseOut={()=>{//background-color:rgba(0,0,255,0.5)
                    document.getElementById(`textTLbtn-${canvasCompoId}`).style.backgroundColor = 'rgba(255,255,255, 0.85)'
                    document.getElementById(`textTLbtn-${canvasCompoId}`).style.color   = 'black' 
                }}
                
                onClick={()=>{

                    //setSelectedDrawingForEditInfoIdObj({})//all .isSelected drawings should be nullified before adding a new & fresh drawing
        
                    if(
                        //!isDisplayChartTextInput &&
                        selectedDrawingForEditInfoIdObj.isSelected && selectedDrawingForEditInfoIdObj.id && selectedDrawingForEditInfoIdObj.drawingType  &&
                    (
                        ['trendline2', 'trendline3', 'trendline4', 'trendline5', 'trendline6', 'trendline8'].includes(selectedDrawingForEditInfoIdObj.drawingType) 
                    )
                    ){
                        
        
                        if(
                            (
                                selectedDrawingForEditInfoIdObj.id === (
                                    window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto) !== null &&
                                    JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto))[selectedDrawingForEditInfoIdObj.id] &&
                                    JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto))[selectedDrawingForEditInfoIdObj.id]
                                )
                            )
                        ){
                            //console.log('global delete')
    
                            //window.localStorage.setItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))

                            const drawingData = localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto)
                            const isDataExist = drawingData !== null
            
                            //now as well map & store the newObj into localStorage
                            if(isDataExist){
                                const data = JSON.parse(drawingData)

                                let newData = []

                                for(let obj in data){
                                    newData.push(data[obj])
                                }
                                
                                let isIdExists = false
                                newData = newData.filter((el, elInd)=>{
                                    if(el.id===selectedDrawingForEditInfoIdObj.id){
                                        isIdExists = true
                                    }
                                    return (el.id===selectedDrawingForEditInfoIdObj.id)
                                })
                                
                                if(isIdExists){
                                    //console.log({newData:newData[0].text})
                                    setChartTextInputStringTracker(String(newData[0].text))
                                }
                                else{
                                    setChartTextInputStringTracker('')
                                }
                            }
    
    
                            setReloadAllChartsUseEffectCounter(Date.now())//reloads all charts
    
                        }
                        else{
                            const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                            const isDataExist = drawingData !== null
            
                            //now as well map & store the newObj into localStorage
                            if(isDataExist){
                                const data = JSON.parse(drawingData)
            
                                let newData = [...data]
                                
                                let isIdExists = false
                                newData = newData.filter((el, elInd)=>{
                                    if(el.id===selectedDrawingForEditInfoIdObj.id){
                                        isIdExists = true
                                    }
                                    return (el.id===selectedDrawingForEditInfoIdObj.id)
                                })
                                //console.log({newData:newData[0].text})
                                if(isIdExists){
                                    setChartTextInputStringTracker(String(newData[0].text))
                                }
                                else{
                                    setChartTextInputStringTracker('')
                                }
                            }
                        }

                    }


        
                    setIsDisplayChartTextInput(true)

                    document.getElementById(`textTLbtn-${canvasCompoId}`).style.backgroundColor = 'rgba(255,255,255, 0.85)'
                    document.getElementById(`textTLbtn-${canvasCompoId}`).style.color   = 'black' 
                 
                }}
                >
                    {'Text'}
                </div>
                }








                {
                (['trendline5'].includes(selectedDrawingForEditInfoIdObj.drawingType) 
                || isLockedVertiLineSelected
                )
                &&
                <div id={`lockTLbtnParent-${canvasCompoId}`}
                style={{
                    width:'100%',
                    height:'100%',
                    backgroundColor:'rgba(255,0,0,0.25)',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    borderLeft:'1px solid black',
                    //color:'black'
                }}

                onMouseOver={()=>{
                    document.getElementById(`lockTLbtnParent-${canvasCompoId}`).style.backgroundColor = 'rgba(0,0,255,0.5)' 
    
                    document.getElementById(`lockTLbtn-${canvasCompoId}`).style.color   = 'white' 
                    document.getElementById(`unlockTLbtn-${canvasCompoId}`).style.color = 'white' 
                }}
    
                onMouseOut={()=>{
                    document.getElementById(`lockTLbtnParent-${canvasCompoId}`).style.backgroundColor = 'rgba(255, 0,0,0.25)'
    
                    document.getElementById(`lockTLbtn-${canvasCompoId}`).style.color   = 'black' 
                    document.getElementById(`unlockTLbtn-${canvasCompoId}`).style.color = 'black' 
                }}

                onClick={()=>{
                    


                    //console.log('tl locked')

                    const drawingDataObj = localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto)
                    const isDataExist = drawingDataObj !== null
                    
                    if(isDataExist){
                        
                        const id = /*isLockedVertiLineSelected ? lockedVertiLineSelectedDatetime : */selectedDrawingForEditInfoIdObj.id
                        const dataObj = JSON.parse(drawingDataObj)
                        
                        if(dataObj[id]){//delete

                            const dataToInsert = dataObj[id]

                            //-------------------------------------------------------------------------------
                            //delete dataObj[id]
                            window.localStorage.setItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))

                            //----------------------------------------------------------------------------------
                            //store unlocked tl drawing data in the localstorage
                            const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                            const isDataExist = drawingData !== null

                            if(isDataExist){
                                const data = JSON.parse(drawingData)

                                let newData = [...data]
                                
                                //if(onDrawingMoveInfoTrackerObj.id){
                                    newData = [...newData, {...dataToInsert, isGlobalLockedVertiLine : false, /*isReplayLineDrawing : false*/}]
                                //

                                //alert(newData)
                                window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))

                                setSelectedDrawingForEditInfoIdObj({
                                  ...selectedDrawingForEditInfoIdObj,
                                  isGlobalLockedVertiLine : false, /*isReplayLineDrawing : false*/
                                })
                            }
                            else{
                                window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify([
                                  {...dataToInsert, isGlobalLockedVertiLine : false, /*isReplayLineDrawing : false*/}
                                ]))

                                setSelectedDrawingForEditInfoIdObj({
                                  ...selectedDrawingForEditInfoIdObj,
                                  isGlobalLockedVertiLine : false, /*isReplayLineDrawing : false*/
                                })
                            }
                        }
                        else{

                            //now delete from trendline LStorage - & store in Global Lock
                            const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                            const isDataExist = drawingData !== null

                            //now as well map & store the newObj into localStorage
                            if(isDataExist){
                                const data = JSON.parse(drawingData)

                                let newData = [...data]
                                
                                let removedData = {}

                                newData = newData.filter((el, elInd)=>{
                                    if(el.id===selectedDrawingForEditInfoIdObj.id){
                                        removedData = el
                                    }
                                    return (el.id!==selectedDrawingForEditInfoIdObj.id)
                                })
                                
                                window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))


                                //--------------------------------------------------------------
                                //insert
                                const obj = {}
                                obj[id] = removedData
                                window.localStorage.setItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto, 
                                JSON.stringify({
                                  ...obj
                                }))

                            }

                        }

                        setUseEffectReRunCounter(Date.now())
                        setReloadAllChartsUseEffectCounter(Date.now())//reloads all charts
                    }
                    else{//if LStorage name dont exists
                          //insert as well

                        const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                        const isDataExist = drawingData !== null

                        let removedData = {}
                        //now as well map & store the newObj into localStorage
                        if(isDataExist){
                            const data = JSON.parse(drawingData)

                            let newData = [...data]
                            

                            newData = newData.filter((el, elInd)=>{
                                if(el.id===selectedDrawingForEditInfoIdObj.id){
                                    removedData = el
                                }
                                return (el.id!==selectedDrawingForEditInfoIdObj.id)
                            })
                        }

                        const id = isLockedVertiLineSelected ? lockedVertiLineSelectedDatetime : selectedDrawingForEditInfoIdObj.id
                        const obj = {}
                        obj[id] = removedData

                        window.localStorage.setItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto, 
                        JSON.stringify({
                          ...obj
                        }))


                        setUseEffectReRunCounter(Date.now())
                        setReloadAllChartsUseEffectCounter(Date.now())//reloads all charts
                        
                    }

                }}
                >
                    


                    {

                        <div id={`unlockTLbtn-${canvasCompoId}`} className={selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? 'unlockBtn2Tool' : 'unlockBtnTool'}
                        style={{
                            height:'100%',
                            width:'100%',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            display: `${
                                (selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? localStorage.getItem("replayDatetimeLineLockedObj" + forexORcrypto) : localStorage.getItem("verticalDatetimeLineLockedObj" + forexORcrypto)) && 
                                JSON.parse(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? 
                                    localStorage.getItem("replayDatetimeLineLockedObj" + forexORcrypto) : localStorage.getItem("verticalDatetimeLineLockedObj" + forexORcrypto)
                                    )[isLockedVertiLineSelected ? lockedVertiLineSelectedDatetime : selectedDrawingForEditInfoIdObj.id]
                                 ? 'flex' : 'none'}` ,//instead of block make display:flex if needed
                            //border:'1px solid black'
                            
                        }}
                        >
                            <BsUnlock  
                                style={{
                                    transform:'scale(1.5)',
                                }}
                            />
                        </div>

                    }

                    {

                        <div id={`lockTLbtn-${canvasCompoId}`} className={selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? 'lockBtn2Tool' : 'lockBtnTool'}
                        style={{
                            height:'100%',
                            width:'100%',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            display: `${
                                (selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? localStorage.getItem("replayDatetimeLineLockedObj" + forexORcrypto) : localStorage.getItem("verticalDatetimeLineLockedObj" + forexORcrypto)) && 
                                JSON.parse(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? 
                                    localStorage.getItem("replayDatetimeLineLockedObj" + forexORcrypto) : localStorage.getItem("verticalDatetimeLineLockedObj" + forexORcrypto)
                                    )[isLockedVertiLineSelected ? lockedVertiLineSelectedDatetime : selectedDrawingForEditInfoIdObj.id]
                                 ? 'none' : 'flex'}`,//instead of block make display:flex if needed
                            //border:'1px solid black'
                        }}
                        >
                            <BsLock  
                                style={{
                                    transform:'scale(1.5)',
                                }}
                            />
                        </div>
                    }
                </div>
                
                }


                {
                isLockedVertiLineSelected===false &&

                <div id={`deleteTLbtn-${canvasCompoId}`} className="deleteBtnTool"
                style={{
                    position:'relative',
                    width:'100%',
                    height:'100%',
                    backgroundColor:'rgba(0,0,255,0.1)',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    borderLeft:'1px solid black'
                }}

                onMouseOver={()=>{//background-color:rgba(0,0,255,0.5)
                    document.getElementById(`deleteTLbtn-${canvasCompoId}`).style.backgroundColor = 'rgba(0,0,255,0.5)' 
                    document.getElementById(`deleteTLbtn-${canvasCompoId}`).style.color   = 'white' 
                }}
    
                onMouseOut={()=>{//background-color:rgba(0,0,255,0.5)
                    document.getElementById(`deleteTLbtn-${canvasCompoId}`).style.backgroundColor = 'rgba(0,0,255,0.1)'
                    document.getElementById(`deleteTLbtn-${canvasCompoId}`).style.color   = 'black' 
                }}


                onClick={()=>{
                
                    //console.log('delete clicked............')
                    /*console.log({
                      id : (JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto)))[selectedDrawingForEditInfoIdObj.id].id
                    })*/

                    if(
                        (
                            selectedDrawingForEditInfoIdObj.id === (
                                window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto) !== null &&
                                (JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto)))[selectedDrawingForEditInfoIdObj.id] &&
                                (JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto)))[selectedDrawingForEditInfoIdObj.id].id 
                                ?
                                Number((JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto)))[selectedDrawingForEditInfoIdObj.id].id)
                                : -1
                            )
                        )
                        //true
                    ){
                        //console.log('global delete')

                        window.localStorage.setItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))

                        setCursorAndDrawingEditIdObj({})
                        setSelectedDrawingForEditInfoIdObj({})

                        setReloadAllChartsUseEffectCounter(Date.now())//reloads all charts

                    }
                    else{
                        //console.log('LStorage delete')
                        const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                        const isDataExist = drawingData !== null
        
                        if(isDataExist){
                            const data = JSON.parse(drawingData)
                            
                            const newData = data.filter((elObj, ind)=>{
                                return elObj.id !== selectedDrawingForEditInfoIdObj.id
                            })
                            //alert(newData)
                            window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))
                            
                            setCursorAndDrawingEditIdObj({})
                            setSelectedDrawingForEditInfoIdObj({})
        
                            //console.log('data : ', data, " : ", newData, " : ", selectedDrawingForEditInfoIdObj.id)
        
                            setUseEffectReRunCounter(Date.now())
                        }
                    }
                    
                    
                }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 28 28" width="28" height="28">
                        <path 
                        fill="currentColor" 
                        fillRule="evenodd" 
                        d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z">
                        </path>
                    </svg>
                </div>
                }

            </div>
            }

        </div>
        }

    
    {


    <canvas ref={canvasRef} //width={canvasWidth} height={canvasHeight} //- this not helpful, but when included in style with 'px' added to it
    style={{
        //width:'100%',
        width:`${canvasWidth}px`,//size stated in px in style-prop better than stated  using the exact widthProp={} & heightProp={}
        height:`${canvasHeight}px`,//size stated in px in style-prop better than stated  using the exact widthProp={} & heightProp={}

        borderBottom: '1px solid gray',
        borderTop: '1px solid gray',
        backgroundColor:'white',//to avoid transparent screenshots
        /* center align */
        //display: 'block',
        //width: '100vw',
        /*width: '500px',
        height: '500px',*/
        //margin: '30px auto',
        cursor:'crosshair',
        //display:'none'

        /*position:'absolute',
        top:'0px',
        right:'0px', 
        bottom:'0px',
        left:'0px'*/
    }}


    /*
    -----------------
    -- Mouse events : 
    -----------------
        onClick - left Click
        onContextMenu - right Click
        onDoubleClick - includes onClick
        onMouseDown
        onMouseMove
        onMouseUp
        onMouseOver
        onMouseOut
        onWheel.deltaX/Y

    -------------------------
    -- onScreenTouch events : 
    -------------------------
        onTouchStart
        onTouchMove
        onTouchEnd
        onTouchCancel

    -------------------------
    -- keyboard keys events :
    -------------------------
        onKeyDown
        onKeyPress
        onKeyUp
    */

        

        onContextMenu={(e)=>{//right Click
            if(data.length>0){
            //setIsTouchScreenDevice(false)
            //console.log("onContextMenu - right Click")
            }
        }}

        onDoubleClick={(e)=>{//
            if(data.length>0){
            //setIsTouchScreenDevice(false)
            //console.log("onDoubleClick")
            }
        }}

        onMouseDown={(e)=>{//abrant

            if(data.length>0){

            //setIsTouchScreenDevice(false)
            //console.log("onMouseDown")

            setIsMouseDown(true)

            if(
                //for all trendline(1-8) midMove
                cursorAndDrawingEditIdObj.isInTLmidRange ||
                cursorAndDrawingEditIdObj.isDrawingEndCordSelected ||
                cursorAndDrawingEditIdObj.isDrawingStartCordSelected 
            ){
                setSelectedDrawingForEditInfoIdObj({...cursorAndDrawingEditIdObj, isSelected:true})
                setIsDisplayChartTextInput(false)
            }
            else if(
                //trendline6
                (
                    cursorAndDrawingEditIdObj.drawingType==='trendline6'
                )
                &&
                (
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidTopSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidRightSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidBottomSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidLeftSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinTopRightCircleRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinTopLeftCircleRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinBottomRightCircleRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinBottomLeftCircleRange 
                )
            )
            {
                setSelectedDrawingForEditInfoIdObj({...cursorAndDrawingEditIdObj, isSelected:true, isInTLmidRange:false})   
                setIsDisplayChartTextInput(false)
            }
            else if(
                //trendline7
                (
                    cursorAndDrawingEditIdObj.drawingType==='trendline7'
                )
                &&
                (
                    cursorAndDrawingEditIdObj.isCrossHair7WithinMidRightSquareRange    ||
                    cursorAndDrawingEditIdObj.isCrossHair7WithinMidLeftCircleRange     ||
                    cursorAndDrawingEditIdObj.isCrossHair7WithinTopLeftSquareRange     ||
                    cursorAndDrawingEditIdObj.isCrossHair7WithinBottomLeftSquareRange  
                )
            ){
               setSelectedDrawingForEditInfoIdObj({...cursorAndDrawingEditIdObj, isSelected:true, isInTLmidRange:false})   
               setIsDisplayChartTextInput(false)
            }


            //change cursor onMouseDown
            const canvasEl = document.getElementById(`canvasCompoId-${canvasCompoId}`)
            const canvasOutLiarData = canvasEl.getBoundingClientRect()

            const canvasHeight = canvasEl.height
            const canvasWidth = canvasEl.width

            const outLiarX = Math.ceil(canvasOutLiarData.x)
            const outLiarY = canvasOutLiarData.y

            let Xcoord = e.clientX-outLiarX
            let Ycoord = e.clientY-outLiarY

            //invert chart coords
            //Ycoord = canvasHeight - Ycoord
            //console.log("onMouseDown", Xcoord, Ycoord)

            //--------------------------------------------------------------------------------------------------------------------
            //crossHair YcordPRICE SCALING config:

            //get devicePixelRatio - to scale canvas drawing back to - 1 to 1 ratio if( devicePixelRatio > 1 )
            const devicePixelRatio = window.devicePixelRatio || 1
            const ratioTargetToScaleCanvasChartTo = 1
            const pixelOutlierRatioAwayFrom1to1 = devicePixelRatio - ratioTargetToScaleCanvasChartTo

            //now scale canvas drawing style(w, h) to 1to1 - by taking out the Outlier/invincible drawing portion
            //to start the drawings from a 1to1 visible portion after the outlier had been subtracted
            const canvasChartHeightSCALEoutlierPX = (canvasHeight / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1
            const canvasChartWidthSCALEoutlierPX  = (canvasWidth / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1

            //invert chart coords
            Ycoord = (canvasHeight - Ycoord) - canvasChartHeightSCALEoutlierPX
            //--------------------------------------------------------------------------------------------------------------------

            if(isDrawingActivated){
                setTrendlineCordStart({
                    x:Xcoord, y:Ycoord
                })
            }
            else if(isPipCheckerActivated){
                setPipCheckerCordStart({
                    x:Xcoord, y:Ycoord
                })
            }

            setOnMouseDownVal({Xcoord, Ycoord, chartXvelocity, chartYvelocity, YmagnificationPercRatio, XmagnificationPercRatio,
                dataCandleStartIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                dataCandleEndIndex   : dataCandleStartEndIndex.dataCandleEndIndex
            })

            if (canvasEl && Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX)) {
                canvasEl.style.cursor = 'grabbing'; // You can set it to any valid CSS cursor value
                //pointer - for selecting trendlines
                //grabbing
                //ns-resize
            }
            /*else if (canvasEl && Xcoord > (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX)) {
                //canvasEl.style.cursor = 'grabbing'; 
                setIsYmagnificationActivated(true)
                console.log('Ymag SET')
            }*/

            setUseEffectReRunCounter(Date.now())

            }

        }}
        

        onMouseMove={(e)=>{//

            if(data.length>0){

            //to update screens with both touch & mouse
            //to overcome the following default truthy state
            //setIsTouchScreenDevice('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) 
            setIsTouchScreenDevice(false)

            setCurrHighlightHovered('')
            

            if(
                isEditingDrawing
                && selectedDrawingForEditInfoIdObj.isSelected
                && selectedDrawingForEditInfoIdObj.drawingType === 'trendline5'
                && (selectedDrawingForEditInfoIdObj.isReplayLineDrawing || selectedDrawingForEditInfoIdObj.isGlobalLockedVertiLine)
                && (
                    selectedDrawingForEditInfoIdObj.id === (
                        window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto) !== null &&
                        JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto))[selectedDrawingForEditInfoIdObj.id] &&
                        JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto))[selectedDrawingForEditInfoIdObj.id]
                    )
                )
            ){
                //console.log('badu.......')
                setReloadAllChartsUseEffectCounter(Date.now())
            }


            const canvasEl = document.getElementById(`canvasCompoId-${canvasCompoId}`)
            const canvasOutLiarData = canvasEl.getBoundingClientRect()
            //console.log({x:canvasOutLiarData.x, y:canvasOutLiarData.y})

            //get the canvas HTML element size(w, h)
            const canvasHeight = canvasEl.height
            const canvasWidth  = canvasEl.width

            const outLiarX = Math.ceil(canvasOutLiarData.x)
            const outLiarY = canvasOutLiarData.y
            //console.log({width:window.innerWidth})

            //console.log({canvasWidth, width: document.getElementById(`canvasCompoId-${1}`).style.width})///

            let Xcoord = e.clientX-outLiarX
            let Ycoord = e.clientY-outLiarY

            //invert chart coords
            //Ycoord = canvasHeight - Ycoord //- Math.abs(canvasHeight - Number(document.getElementById(`canvasCompoId-${1}`).style.height.replace('px', '')))
            //Ycoord = Number(document.getElementById(`canvasCompoId-${1}`).style.height.replace('px', '')) - Ycoord


            //--------------------------------------------------------------------------------------------------------------------
            //crossHair YcordPRICE SCALING config:

            //get devicePixelRatio - to scale canvas drawing back to - 1 to 1 ratio if( devicePixelRatio > 1 )
            const devicePixelRatio = window.devicePixelRatio || 1
            const ratioTargetToScaleCanvasChartTo = 1
            const pixelOutlierRatioAwayFrom1to1 = devicePixelRatio - ratioTargetToScaleCanvasChartTo

            //now scale canvas drawing style(w, h) to 1to1 - by taking out the Outlier/invincible drawing portion
            //to start the drawings from a 1to1 visible portion after the outlier had been subtracted
            const canvasChartHeightSCALEoutlierPX = (canvasHeight / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1
            const canvasChartWidthSCALEoutlierPX  = (canvasWidth / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1

            //invert chart coords
            Ycoord = (canvasHeight - Ycoord) - canvasChartHeightSCALEoutlierPX
            //--------------------------------------------------------------------------------------------------------------------
          
            
            //if(isDrawingActivated===false && isPipCheckerActivated===false){
                setCrossHairOnMoveCordVals({
                    x: Xcoord, y: Ycoord
                })
            //}
            //console.log({x: Xcoord, y: Ycoord, x1:e.clientX, y1:e.clientY})


            //adamu
            if(
                isMouseDown && 
                isDrawingActivated===false && 
                isPipCheckerActivated===false &&
                (selectedDrawingForEditInfoIdObj.isSelected === undefined || selectedDrawingForEditInfoIdObj.isSelected === false)
                && isEditingDrawing===false
            ){

                const coordStart = onMouseDownVal
                
                const {dataCandleStartIndex, dataCandleEndIndex} = dataCandleStartEndIndex
                const dataCandleStartEndIndexDiff = dataCandleEndIndex - dataCandleStartIndex//6
                const eachCandleBoxWidthSize = canvasWidth / dataCandleStartEndIndexDiff//83
                //console.log({dataCandleStartIndex, dataCandleEndIndex, dataCandleStartEndIndexDiff, eachCandleBoxWidthSize})


                //horizontal zooming
                if(
                    coordStart.Ycoord < (datetimeLabelMarginSize) && 
                    coordStart.Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) &&
                    isYmagnificationActivated===false
                ){
                    const XcoordDiff = Xcoord - coordStart.Xcoord
                    //console.log('mag - demag me : ', coordStart.Xcoord, " : ", coordStart.Ycoord)
                    //console.log('hey...')

                    const XmagFastMagMultiplier = 1.5 + 0.5//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half
                    const zoomCountInterval = /*coordStart.Ycoord < (datetimeLabelMarginSize) ? -1 :*/ (1) * XmagFastMagMultiplier // Per every wheel activity: mag or de-mag by +1/-1

                    //asuafu - mouse
                    if(XcoordDiff < 0){

                        
                        const newDataCandleStartIndex = dataCandleStartIndex + zoomCountInterval
                        const newDataCandleEndIndex   = dataCandleEndIndex   //- Math.floor(candleXzoomCount)
                        if(
                            newDataCandleStartIndex >= 0 &&
                            (newDataCandleEndIndex - newDataCandleStartIndex) > maxMagnificationCandleCountLimit &&
                            newDataCandleEndIndex <= data.length 
                        ){

                            setDataCandleStartEndIndex({
                            dataCandleStartIndex : newDataCandleStartIndex,
                            dataCandleEndIndex   : newDataCandleEndIndex
                            })

                        }
                        
                    }
                    else if(XcoordDiff > 0){

                        
                        const newDataCandleStartIndex = (dataCandleStartIndex - zoomCountInterval) < 0 ? 0 : dataCandleStartIndex - zoomCountInterval
                        const newDataCandleEndIndex   = (dataCandleStartIndex - zoomCountInterval) < 0 ? dataCandleEndIndex + zoomCountInterval : dataCandleEndIndex 
                        if(
                            newDataCandleStartIndex >= 0 &&
                            (newDataCandleEndIndex - newDataCandleStartIndex) > maxMagnificationCandleCountLimit &&
                            newDataCandleEndIndex <= data.length 
                        ){
                            setDataCandleStartEndIndex({
                                dataCandleStartIndex : newDataCandleStartIndex,
                                dataCandleEndIndex   : newDataCandleEndIndex
                            })


                        }
                        
                    }
                }
                else if(
                    coordStart.Ycoord < (datetimeLabelMarginSize) && 
                    coordStart.Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) &&
                    isYmagnificationActivated //&& false
                ){

                    const XcoordDiff = Xcoord - coordStart.Xcoord
                    //console.log('mag - demag - Ymag : ', coordStart.Xcoord, " : ", coordStart.Ycoord)
                    //console.log('hey............')

                    const XmagFastMagMultiplier = 1.5 + 0.5//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half
                    const zoomCountInterval = (1) * XmagFastMagMultiplier // Per every wheel activity: mag or de-mag by +1/-1
                    const bounceZoomFromZoomLimitRatioSize = zoomCountInterval/100

                    /*const zoomRatio = 0.00000000001
                    candleXzoomCount = Math.floor(candleXzoomCount*zoomRatio)*/
                    //console.log('candleXzoomCount : ', candleXzoomCount/100)
                    //console.log('hello : ', (chartCandleBoxWidthStartPX * (XmagnificationPercRatio-(zoomCountInterval/100))))

                    setXmagnificationPercRatio(
                        //XcoordDiff >= 0 ? XmagnificationPercRatio-(zoomCountInterval/100) : XmagnificationPercRatio+(zoomCountInterval/100)

                        XcoordDiff >= 0 && (chartCandleBoxWidthStartPX * (XmagnificationPercRatio-(zoomCountInterval/100))) < (-chartCandleBoxWidthStartPX) ? 
                        (XmagnificationPercRatio + bounceZoomFromZoomLimitRatioSize) : 
                        (XcoordDiff >= 0 ? XmagnificationPercRatio-(zoomCountInterval/100) : XmagnificationPercRatio+(zoomCountInterval/100))
                    )

                }
                //horizontal scale scrolling
                else if(
                    coordStart.Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) && isYmagnificationActivated===false
                ){
                    const horizontalFastScrollMultiplier = 2.5 + 0.5//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half
                    const XcoordDiff = Xcoord - coordStart.Xcoord
                    const candleXvelocityCount = (XcoordDiff/eachCandleBoxWidthSize) * horizontalFastScrollMultiplier
                    //console.log("XcoordDiff : ", XcoordDiff, candleXvelocityCount)

                    const newDataCandleStartIndex = onMouseDownVal.dataCandleStartIndex - Math.floor(candleXvelocityCount)
                    const newDataCandleEndIndex   = onMouseDownVal.dataCandleEndIndex - Math.floor(candleXvelocityCount)

                    if(
                        newDataCandleEndIndex <= data.length &&
                        newDataCandleStartIndex >= 0
                    ){
                        setDataCandleStartEndIndex({
                            dataCandleStartIndex : newDataCandleStartIndex,
                            dataCandleEndIndex   : newDataCandleEndIndex
                        })

                    }

                    if(newDataCandleEndIndex === data.length-1){
                        //console.log('we are at current chart data of candle id=0, update chart or do something')
                    }
                    
                }
                else if(
                    coordStart.Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) && isYmagnificationActivated
                ){
                    const horizontalFastScrollMultiplier = 1.5 + 0.5//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half
                    const XcoordDiff = (Xcoord - coordStart.Xcoord) * horizontalFastScrollMultiplier
                    const YcoordDiff = (Ycoord - coordStart.Ycoord) * horizontalFastScrollMultiplier 

                    /*
                    beforeYmagChartInfoTracker.isYmagInfoTracked
                    */
                    setChartXvelocity(
                        beforeYmagChartInfoTracker.isYmagInfoTracked 
                        && (
                            (coordStart.chartXvelocity + XcoordDiff) > beforeYmagChartInfoTracker.rightScrollXvelocityLimit ||
                            (coordStart.chartXvelocity + XcoordDiff) < beforeYmagChartInfoTracker.leftScrollXvelocityLimit
                        )
                        ?
                        coordStart.chartXvelocity// + XcoordDiff
                         : (coordStart.chartXvelocity + XcoordDiff)
                    )

                    //if(isYmagnificationActivated){
                        setChartYvelocity(coordStart.chartYvelocity + (YcoordDiff * -1))
                    //}

                    //console.log("onMouseMove", Xcoord, Ycoord,XcoordDiff, YcoordDiff, onMouseDownVal)
                }
                else if(
                    chartInfoObj.candleORrsi==='candle' &&
                    (coordStart.Xcoord > (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX))
                ){
                    //console.log('lets do Ymagnification ')

                    const YmagFastMagMultiplier = 3//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half
                    const XcoordDiff = Xcoord - coordStart.Xcoord
                    const YcoordDiff = Ycoord - coordStart.Ycoord 
                    const YcoordDiffToChartPerc = (YcoordDiff / canvasHeight) * YmagFastMagMultiplier
                    const YmagnificationPercRatioStartVal = coordStart.YmagnificationPercRatio 

                    //setChartXvelocity(coordStart.chartXvelocity + XcoordDiff)
                    if(/*isYmagnificationActivated &&*/ Math.abs(YcoordDiff) > 5+5 && (Math.abs(YcoordDiff) > Math.abs(XcoordDiff))){
                        //console.log('YcoordDiff : ', YcoordDiff)
                        setYmagnificationPercRatio(
                            (YmagnificationPercRatioStartVal + YcoordDiffToChartPerc) < YChartHeightDeMagRatioLimitUptoAbsOfOne ? YChartHeightDeMagRatioLimitUptoAbsOfOne :
                            (YmagnificationPercRatioStartVal + YcoordDiffToChartPerc)
                        )
                        
                        if(isYmagnificationActivated===false){
                            /*setYmagnificationCandleStartEndIndex({
                                dataCandleStartIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                                dataCandleEndIndex :  dataCandleStartEndIndex.dataCandleEndIndex
                            })*/
                            setIsYmagnificationActivated(true)
                            setBeforeYmagCandleIndexInfoObj({
                                startIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                                endIndex   : dataCandleStartEndIndex.dataCandleEndIndex,
                                candleWidthSize : crossHairMidStartXcordDrawerInfo.candleWidthSize,
                                chartXvelocityBefore : Math.abs(dataCandleStartEndIndex.dataCandleEndIndex - dataCandleStartEndIndex.dataCandleStartIndex) * crossHairMidStartXcordDrawerInfo.candleWidthSize
                            })
                        }
                    }
                }

            }
            else{
                //console.log("onMouseMove", Xcoord, Ycoord)

                if(isDrawingActivated){
                    setTrendlineCordEnd({
                        x:Xcoord, y:Ycoord
                    })
                }
                else if(isPipCheckerActivated){//badu
                    setPipCheckerCordEnd({
                        x:Xcoord, y:Ycoord
                    })
                }
            }

            //change cursor onMouseDown
            //datetimeLabelMarginSize
            //kwaku
            if(
                canvasEl &&
                isMouseDown &&
                selectedDrawingForEditInfoIdObj &&
                selectedDrawingForEditInfoIdObj.isSelected &&
                selectedDrawingForEditInfoIdObj.isReplayLineDrawing//if on replay hide mouse cursor
            ){
                canvasEl.style.cursor = 'none'//'text';
            }
            else if(
                canvasEl &&
                selectedDrawingForEditInfoIdObj.isSelected
                && isMouseDown
            ){
                canvasEl.style.cursor = cursorAndDrawingEditIdObj.cursorType//'pointer';
            }
            else if (
                canvasEl && isDrawingActivated===false &&
                Ycoord < (datetimeLabelMarginSize) && 
                Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX)
            ) {
                canvasEl.style.cursor = 'ew-resize'; // You can set it to any valid CSS cursor value
                /*setIsOverPriceLabelMargin(true)
                setIsOutPriceLabelMargin(false)*/
            }
            else if (canvasEl && Xcoord > (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX)) {
                canvasEl.style.cursor = 'ns-resize'; // You can set it to any valid CSS cursor value
                setIsOverPriceLabelMargin(true)
                setIsOutPriceLabelMargin(false)
            }
            else if(canvasEl && Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) && isMouseDown /*&& isMouseDown===false*/) {
              
                if(isLockedVertiLineHovered ){
                    canvasEl.style.cursor = 'pointer';
                }
                else if(isPipCheckerActivated ){
                    canvasEl.style.cursor = 'crosshair';
                }
                else if(isDrawingActivated ){
                    canvasEl.style.cursor = 'crosshair';
                }
                else{
                    canvasEl.style.cursor = 'grabbing';  // You can set it to any valid CSS cursor value
                }
            }
            else if(canvasEl && Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) /*&& isMouseDown===false*/) {
                canvasEl.style.cursor = cursorAndDrawingEditIdObj.cursorType ? cursorAndDrawingEditIdObj.cursorType : 'crosshair'; 
                
                if(isLockedVertiLineHovered ){
                    canvasEl.style.cursor = 'pointer';
                }

                setIsOverPriceLabelMargin(false)
                setIsOutPriceLabelMargin(true)
            }

            setUseEffectReRunCounter(Date.now())
            //console.log('chartXvelocity : ', chartXvelocity)
            //console.log('chartXvelocityPrevVal : ', chartXvelocityPrevVal)

            }

        }}



        onMouseUp={(e)=>{//
 

            if(data.length>0){
            



            //console.log("onMouseUp")
            //setIsTouchScreenDevice(false)
            

            setIsMouseDown(false)

            //change cursor onMouseDown
            const canvasEl = document.getElementById(`canvasCompoId-${canvasCompoId}`)
            const canvasOutLiarData = canvasEl.getBoundingClientRect()

            const canvasHeight = canvasEl.height
            const canvasWidth = canvasEl.width

            const outLiarX = Math.ceil(canvasOutLiarData.x)
            const outLiarY = canvasOutLiarData.y

            let Xcoord = e.clientX-outLiarX
            let Ycoord = e.clientY-outLiarY

            //invert chart coords
            //Ycoord = canvasHeight - Ycoord
            //console.log("onMouseUp", Xcoord, Ycoord)

            //--------------------------------------------------------------------------------------------------------------------
            //crossHair YcordPRICE SCALING config:

            //get devicePixelRatio - to scale canvas drawing back to - 1 to 1 ratio if( devicePixelRatio > 1 )
            const devicePixelRatio = window.devicePixelRatio || 1
            const ratioTargetToScaleCanvasChartTo = 1
            const pixelOutlierRatioAwayFrom1to1 = devicePixelRatio - ratioTargetToScaleCanvasChartTo

            //now scale canvas drawing style(w, h) to 1to1 - by taking out the Outlier/invincible drawing portion
            //to start the drawings from a 1to1 visible portion after the outlier had been subtracted
            const canvasChartHeightSCALEoutlierPX = (canvasHeight / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1
            const canvasChartWidthSCALEoutlierPX  = (canvasWidth / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1

            //invert chart coords
            Ycoord = (canvasHeight - Ycoord) - canvasChartHeightSCALEoutlierPX
            //--------------------------------------------------------------------------------------------------------------------
            

            // Change the cursor style
            if (canvasEl && Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX)) {
                canvasEl.style.cursor = 'crosshair'; // You can set it to any valid CSS cursor value
            }

           

            if(chartXvelocity > chartXvelocityPrevVal){
                setChartXvelocityPrevVal(chartXvelocity)
            }


            /*
            if(cursorAndDrawingEditIdObj.isInTLmidRange){
                //console.log('yesssssssssssssssss........................', cursorAndDrawingEditIdObj)
                setSelectedDrawingForEditInfoIdObj({...cursorAndDrawingEditIdObj, isSelected:true})
            }
            //*/
            if(onDrawingMoveInfoTrackerObj.id){

                //do something with drawing info & empty it
                //setOnDrawingMoveInfoTrackerObj({})
                setIsDrawingDoneOnMouseUp(true)
            }
            else{
                setOnDrawingMoveInfoTrackerObj({})
            }

            setOnMouseDownVal({})
            setIsDrawingActivated(false)
            setTrendlineTypeToDraw('')
            //setIsRectangleTextInput(false)
            setIsPipCheckerActivated(false)
            setIsEditingDrawing(false)

            setIsDeleteAllChartDrawings(false)
            setIsMakeAllDrawingsVisible(false)
            setIsMakeAllDrawingsInvisible(false)

            //setIsLockedVertiLineSelected(false)
            //setLockedVertiLineSelectedDatetime('')

            setTrendlineCordStart({x:-1, y:-1})
            setTrendlineCordEnd({x:-1, y:-1})

            setUseEffectReRunCounter(Date.now())

            }
        }}


        onMouseOver={(e)=>{//

            if(data.length>0){

            //setIsTouchScreenDevice(false)
            //console.log("onMouseOver")
            setIsMouseOut(false)

            

            }
        }}


        onMouseOut={(e)=>{//

            if(data.length>0){
                
            //console.log("onMouseOut")
            //setIsTouchScreenDevice(false)

            setIsOverPriceLabelMargin(false)
            setIsOutPriceLabelMargin(false)

            setIsMouseDown(false)
            setIsMouseOut(true)

            //change cursor onMouseDown
            const canvasEl = document.getElementById(`canvasCompoId-${canvasCompoId}`)
            const canvasOutLiarData = canvasEl.getBoundingClientRect()

            const canvasHeight = canvasEl.height
            const canvasWidth = canvasEl.width

            const outLiarX = Math.ceil(canvasOutLiarData.x)
            const outLiarY = canvasOutLiarData.y

            let Xcoord = e.clientX-outLiarX
            let Ycoord = e.clientY-outLiarY

            //invert chart coords
            //Ycoord = canvasHeight - Ycoord
            //console.log("onMouseOut", Xcoord, Ycoord)

            //--------------------------------------------------------------------------------------------------------------------
            //crossHair YcordPRICE SCALING config:

            //get devicePixelRatio - to scale canvas drawing back to - 1 to 1 ratio if( devicePixelRatio > 1 )
            const devicePixelRatio = window.devicePixelRatio || 1
            const ratioTargetToScaleCanvasChartTo = 1
            const pixelOutlierRatioAwayFrom1to1 = devicePixelRatio - ratioTargetToScaleCanvasChartTo

            //now scale canvas drawing style(w, h) to 1to1 - by taking out the Outlier/invincible drawing portion
            //to start the drawings from a 1to1 visible portion after the outlier had been subtracted
            const canvasChartHeightSCALEoutlierPX = (canvasHeight / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1
            const canvasChartWidthSCALEoutlierPX  = (canvasWidth / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1

            //invert chart coords
            Ycoord = (canvasHeight - Ycoord) - canvasChartHeightSCALEoutlierPX
            //--------------------------------------------------------------------------------------------------------------------


            // Change the cursor style
            if (canvasEl && Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX)) {
                canvasEl.style.cursor = 'crosshair'; // You can set it to any valid CSS cursor value
            }

            

            if(chartXvelocity > chartXvelocityPrevVal){
                setChartXvelocityPrevVal(chartXvelocity)
            }

            setOnMouseDownVal({})
            setCrossHairOnMoveCordVals({x:-50, y:-50})

            //setTrendlineCordStart({x:-1, y:-1})
            //setTrendlineCordEnd({x:-1, y:-1})


            //-------------------------------------------------------------------------------------------------------------------------------------------------------
            //at the end of every onMouseUp :
            //Update LStorage with settings&stateUpdates for both chart_num & instru_tf

            //-------------------------------------------------------------------------------------------------------------------------------------------------------

            setUseEffectReRunCounter(Date.now())

            }

        }}





        onWheel={(e)=>{//

            if(data.length>0){


            
                
            //setIsTouchScreenDevice(false)
            //console.log("onWheel : ", /*e.deltaX,*/ e.deltaY, e.clientX, e.clientY)
            
            const canvasEl          = document.getElementById(`canvasCompoId-${canvasCompoId}`)
            const canvasOutLiarData = canvasEl.getBoundingClientRect()

            const canvasHeight = canvasEl.height
            const canvasWidth  = canvasEl.width

            const outLiarX = Math.ceil(canvasOutLiarData.x)
            const outLiarY = canvasOutLiarData.y

            let Xcoord = e.clientX-outLiarX
            let Ycoord = e.clientY-outLiarY

            //invert chart coords
            //Ycoord = canvasHeight - Ycoord

            //--------------------------------------------------------------------------------------------------------------------
            //crossHair YcordPRICE SCALING config:

            //get devicePixelRatio - to scale canvas drawing back to - 1 to 1 ratio if( devicePixelRatio > 1 )
            const devicePixelRatio = window.devicePixelRatio || 1
            const ratioTargetToScaleCanvasChartTo = 1
            const pixelOutlierRatioAwayFrom1to1 = devicePixelRatio - ratioTargetToScaleCanvasChartTo

            //now scale canvas drawing style(w, h) to 1to1 - by taking out the Outlier/invincible drawing portion
            //to start the drawings from a 1to1 visible portion after the outlier had been subtracted
            const canvasChartHeightSCALEoutlierPX = (canvasHeight / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1
            const canvasChartWidthSCALEoutlierPX  = (canvasWidth / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1

            //invert chart coords
            Ycoord = (canvasHeight - Ycoord) - canvasChartHeightSCALEoutlierPX
            //--------------------------------------------------------------------------------------------------------------------

            //console.log("onMouseDownVal : ", onMouseDownVal, onMouseDownVal.dataCandleStartIndex)
            /*if(onMouseDownVal.dataCandleStartIndex === undefined){
                setOnMouseDownVal({
                    dataCandleStartIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                    dataCandleEndIndex : dataCandleStartEndIndex.dataCandleEndIndex
                })
            }*/
            
            const {dataCandleStartIndex, dataCandleEndIndex} = dataCandleStartEndIndex//onMouseDownVal.dataCandleStartIndex === undefined ? dataCandleStartEndIndex : onMouseDownVal
            const dataCandleStartEndIndexDiff = dataCandleEndIndex - dataCandleStartIndex//6
            const eachCandleBoxWidthSize = canvasWidth / dataCandleStartEndIndexDiff//83
            //console.log({dataCandleStartIndex, dataCandleEndIndex, dataCandleStartEndIndexDiff, eachCandleBoxWidthSize})


            //as mousePad onWheel=(-1 or +1)
            // & mouseWheel=(-100 or +100)
            //normalize them as within the range of (-100 & 100)
            const Ydelta = (e.deltaY === 100 || e.deltaY === -100) ? e.deltaY : Math.floor(e.deltaY * 100)//100 or -100

            //console.log("onWheel : ", Ydelta, Xcoord, Ycoord, XcoordPositionToChartWidthPerc)
            //MouseRoller - e.deltaY = -100(mag) or 100(deMag)
            //MousePad    - e.deltaY !== -100(mag) or 100(deMag) && (< 0 * 100) or (> 0 * 100)

            //horizontal zooming
            if(
                ((Xcoord < canvasWidth  - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) ||
                (Ycoord < (datetimeLabelMarginSize) && 
                Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX)))
                && isYmagnificationActivated===false
            ){
                const XcoordDiff = Ydelta

                const XmagFastMagMultiplier = 12//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half
                const zoomCountInterval     = XmagFastMagMultiplier // Per every wheel activity: mag or de-mag by +1/-1

                //asuafu - wheel
                if(Ydelta < 0){//mag - scaled mag limit could be controlled by the candle_index difference, as least candles to have on chart

                    const newDataCandleStartIndex = dataCandleStartIndex + zoomCountInterval
                    const newDataCandleEndIndex   = dataCandleEndIndex   //- Math.floor(candleXzoomCount)
                    //console.log('zoom ', Math.floor(candleXzoomCount*zoomRatio), newDataCandleStartIndex, newDataCandleEndIndex, data.length)
                    if(
                        newDataCandleStartIndex >= 0 &&
                        (newDataCandleEndIndex - newDataCandleStartIndex) > maxMagnificationCandleCountLimit &&
                        newDataCandleEndIndex <= data.length 
                    ){
                        setDataCandleStartEndIndex({
                            dataCandleStartIndex : newDataCandleStartIndex,
                            dataCandleEndIndex   : newDataCandleEndIndex
                        })

                    }
                    
                    
                }
                else if(Ydelta > 0){//de-mag - scaled de-mag limit controlled by candle_id[ 0 & data.length] as de-mag limit tracker to avoid over de-mag

                    
                    //modify & increase zoomCount when dataCandleStartEndIndexDiff < 5/10
                    //as Ydelta takes a wide value to cause de-mag, as candleWidth had become very big due to small amount of candles on chart
                    //candleXzoomCount = dataCandleStartEndIndexDiff < 10 ? candleXzoomCount + 10 : candleXzoomCount

                    
                    const newDataCandleStartIndex = (dataCandleStartIndex - zoomCountInterval) < 0 ? 0 : dataCandleStartIndex - zoomCountInterval
                    const newDataCandleEndIndex   = (dataCandleStartIndex - zoomCountInterval) < 0 ? dataCandleEndIndex + zoomCountInterval : dataCandleEndIndex 
                    //console.log('yes-------------<50--------------------', /*onMouseDownVal.*/candleXzoomCount*zoomRatio, newDataCandleStartIndex, newDataCandleEndIndex, data.length)
                    if(
                        newDataCandleStartIndex >= 0 &&
                        (newDataCandleEndIndex - newDataCandleStartIndex) > maxMagnificationCandleCountLimit &&
                        newDataCandleEndIndex <= data.length 
                    ){
                        setDataCandleStartEndIndex({
                            dataCandleStartIndex : newDataCandleStartIndex,
                            dataCandleEndIndex   : newDataCandleEndIndex
                        })
                    }
                    

                }

                
            }
            else if(
                ((Xcoord < canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) ||
                (Ycoord < (datetimeLabelMarginSize) && 
                Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX)))
                && isYmagnificationActivated
            ){//unscaled mag & de-mag - with limit controlled by ChartCandleBoxWidthStartPX
                const XcoordDiff = Ydelta
                let candleXzoomCount = XcoordDiff/eachCandleBoxWidthSize
                //console.log(/*"XcoordDiff : ", */{XcoordDiff, candleXzoomCount})
                //console.log("chartCandleBoxWidthStartPX : ", chartCandleBoxWidthStartPX)
                //console.log('hey...........')
                //alert('hey')

                const XmagFastMagMultiplier = 6//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half
                const zoomCountInterval     = XmagFastMagMultiplier // Per every wheel activity: mag or de-mag by +1/-1
                const bounceZoomFromZoomLimitRatioSize = zoomCountInterval/100

                /*const zoomRatio = 0.00000000001
                candleXzoomCount = Math.floor(candleXzoomCount*zoomRatio)*/
                //console.log('candleXzoomCount : ', candleXzoomCount/100)
                //console.log('hello : ', (chartCandleBoxWidthStartPX * (XmagnificationPercRatio-(zoomCountInterval/100))))

                setXmagnificationPercRatio(
                    //XcoordDiff >= 0 ? XmagnificationPercRatio-(zoomCountInterval/100) : XmagnificationPercRatio+(zoomCountInterval/100)

                    XcoordDiff >= 0 && (chartCandleBoxWidthStartPX * (XmagnificationPercRatio-(zoomCountInterval/100))) < (-chartCandleBoxWidthStartPX) ? 
                    (XmagnificationPercRatio + bounceZoomFromZoomLimitRatioSize) : 
                    (XcoordDiff >= 0 ? XmagnificationPercRatio-(zoomCountInterval/100) : XmagnificationPercRatio+(zoomCountInterval/100))
                )
            }
            else if(
                chartInfoObj.candleORrsi==='candle' &&
                (Xcoord > (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) /*&& isYmagnificationActivated*/)
            ){
                const YcoordDiff = Ydelta
                //let candleXzoomCount = XcoordDiff/eachCandleBoxWidthSize
                const YcoordDiffToChartPerc = YcoordDiff / canvasHeight
                //console.log({XcoordDiff, candleXzoomCount})
                //alert('hello')

                const YmagFastMagMultiplier = 9//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half
                const zoomCountInterval = YmagFastMagMultiplier // Per every wheel activity: mag or de-mag by +1/-1

                //console.log('YcoordDiffToChartPerc victor : ', YcoordDiffToChartPerc)
                //console.log('hello : ', YcoordDiff >= 0 ? YmagnificationPercRatio+(zoomCountInterval/100) : YmagnificationPercRatio-(zoomCountInterval/100))

                //------------------------------------------
                setYmagnificationPercRatio(
                    /*(YmagnificationPercRatioStartVal + YcoordDiffToChartPerc) < YChartHeightDeMagRatioLimitUptoAbsOfOne ? YChartHeightDeMagRatioLimitUptoAbsOfOne :
                    (YmagnificationPercRatioStartVal + YcoordDiffToChartPerc)*/
                    /*(YmagnificationPercRatio+(-YcoordDiffToChartPerc)) < YChartHeightDeMagRatioLimitUptoAbsOfOne
                    ? YChartHeightDeMagRatioLimitUptoAbsOfOne :*/ //YmagnificationPercRatio+(-YcoordDiffToChartPerc)
                    /*YcoordDiff < 0 &&*/ YmagnificationPercRatio-(zoomCountInterval/100) < YChartHeightDeMagRatioLimitUptoAbsOfOne 
                    && YmagnificationPercRatio !== YChartHeightDeMagRatioLimitUptoAbsOfOne 
                    ? YChartHeightDeMagRatioLimitUptoAbsOfOne :
                    (YcoordDiff < 0 ? YmagnificationPercRatio+(zoomCountInterval/100) : YmagnificationPercRatio-(zoomCountInterval/100))
                )

                if(isYmagnificationActivated===false){
                    /*setYmagnificationCandleStartEndIndex({
                        dataCandleStartIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                        dataCandleEndIndex :  dataCandleStartEndIndex.dataCandleEndIndex
                    })*/
                    setIsYmagnificationActivated(true)
                    setBeforeYmagCandleIndexInfoObj({
                        startIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                        endIndex   : dataCandleStartEndIndex.dataCandleEndIndex,
                        candleWidthSize : crossHairMidStartXcordDrawerInfo.candleWidthSize,
                        chartXvelocityBefore : Math.abs(dataCandleStartEndIndex.dataCandleEndIndex - dataCandleStartEndIndex.dataCandleStartIndex) * crossHairMidStartXcordDrawerInfo.candleWidthSize
                    })
                }
            }

            //console.log('hey - WHEEL exists...')
            setUseEffectReRunCounter(Date.now())
            
            }

        }}



        
        

        //----------------------------------------------------------------------------------------------------------------------------
        //TouchEvents
        onTouchStart={(e)=>{//

            if(data.length>0){

            //to update screens with both touch & mouse
            //to constantly update the following default truthy state
            //setIsTouchScreenDevice('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) 
            setIsTouchScreenDevice(true)
            setOnlyOneClickDrawerInfoObj({})

            //console.log(
            //"onTouchStart -----------------------", 
            //e.touches[0],
            /*[...e.touches].length,
            e.touches[0].clientX,
            e.touches[0].clientY,*/
            //e
            //)

            //-------------------------------------------------------------------------------------------------------------------------------------------------------
            //-------------------------------------------------------------------------------------------------------------------------------------------------------
            //-------------------------------------------------------------------------------------------------------------------------------------------------------
            setIsMouseDown(true)

            if(
                //for all trendline(1-8) midMove
                cursorAndDrawingEditIdObj.isInTLmidRange ||
                cursorAndDrawingEditIdObj.isDrawingEndCordSelected ||
                cursorAndDrawingEditIdObj.isDrawingStartCordSelected 
            ){
                //console.log('yesssssssssssssssss........................', cursorAndDrawingEditIdObj)
                setSelectedDrawingForEditInfoIdObj({...cursorAndDrawingEditIdObj, isSelected:true})
                setIsDisplayChartTextInput(false)
            }
            else if(
                //trendline6
                (
                    cursorAndDrawingEditIdObj.drawingType==='trendline6'
                )
                &&
                (
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidTopSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidRightSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidBottomSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidLeftSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinTopRightCircleRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinTopLeftCircleRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinBottomRightCircleRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinBottomLeftCircleRange 
                )
            )
            {
                //console.log('yepppppppppppppppppppppp........................', cursorAndDrawingEditIdObj)
                setSelectedDrawingForEditInfoIdObj({...cursorAndDrawingEditIdObj, isSelected:true, isInTLmidRange:false})   
                setIsDisplayChartTextInput(false)
            }
            else if(
                //trendline7
                (
                    cursorAndDrawingEditIdObj.drawingType==='trendline7'
                )
                &&
                (
                    cursorAndDrawingEditIdObj.isCrossHair7WithinMidRightSquareRange    ||
                    cursorAndDrawingEditIdObj.isCrossHair7WithinMidLeftCircleRange     ||
                    cursorAndDrawingEditIdObj.isCrossHair7WithinTopLeftSquareRange     ||
                    cursorAndDrawingEditIdObj.isCrossHair7WithinBottomLeftSquareRange  
                )
            ){
                /*
                isCrossHair7WithinMidRightSquareRange    &&
                isCrossHair7WithinMidLeftCircleRange     &&
                isCrossHair7WithinTopLeftSquareRange     &&
                isCrossHair7WithinBottomLeftSquareRange  &&
                */ 
               //console.log('yepppppppppppppppppppppp........................', cursorAndDrawingEditIdObj)
               setSelectedDrawingForEditInfoIdObj({...cursorAndDrawingEditIdObj, isSelected:true, isInTLmidRange:false})   
               setIsDisplayChartTextInput(false)
            }
            


            //change cursor onMouseDown
            const canvasEl = document.getElementById(`canvasCompoId-${canvasCompoId}`)
            const canvasOutLiarData = canvasEl.getBoundingClientRect()

            const canvasHeight = canvasEl.height
            const canvasWidth = canvasEl.width

            const outLiarX = Math.ceil(canvasOutLiarData.x)
            const outLiarY = canvasOutLiarData.y

            const touchLoc = e.touches[0]
            let Xcoord = touchLoc.clientX-outLiarX
            let Ycoord = touchLoc.clientY-outLiarY

            //invert chart coords
            //Ycoord = canvasHeight - Ycoord
            //console.log("onMouseDown", Xcoord, Ycoord)

            //--------------------------------------------------------------------------------------------------------------------
            //crossHair YcordPRICE SCALING config:

            //get devicePixelRatio - to scale canvas drawing back to - 1 to 1 ratio if( devicePixelRatio > 1 )
            const devicePixelRatio = window.devicePixelRatio || 1
            const ratioTargetToScaleCanvasChartTo = 1
            const pixelOutlierRatioAwayFrom1to1 = devicePixelRatio - ratioTargetToScaleCanvasChartTo

            //now scale canvas drawing style(w, h) to 1to1 - by taking out the Outlier/invincible drawing portion
            //to start the drawings from a 1to1 visible portion after the outlier had been subtracted
            const canvasChartHeightSCALEoutlierPX = (canvasHeight / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1

            //invert chart coords
            Ycoord = (canvasHeight - Ycoord) - canvasChartHeightSCALEoutlierPX
            //--------------------------------------------------------------------------------------------------------------------

            setCrossHairOnMoveCordVals({
                x: Xcoord, y: Ycoord
            })

            if(isDrawingActivated){
                setTrendlineCordStart({
                    x:Xcoord, y:Ycoord
                })
            }
            else if(isPipCheckerActivated){
                setPipCheckerCordStart({
                    x:Xcoord, y:Ycoord
                })
            }

            setOnMouseDownVal({Xcoord, Ycoord, chartXvelocity, chartYvelocity, YmagnificationPercRatio, XmagnificationPercRatio,
                dataCandleStartIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                dataCandleEndIndex   : dataCandleStartEndIndex.dataCandleEndIndex
            })



            setCurrHighlightHovered('')

            

            //-------------------------------------------------------------------------------------------------------------------------------------------------------
            
            setUseEffectReRunCounter(Date.now())

            }
        }}





        onTouchMove={(e)=>{//

            if(data.length>0){

            /*console.log("onTouchMove",
            [...e.changedTouches].length
            )*/

            //setIsTouchScreenDevice(true)
            //isScreenScrollLocked
            setIsOnTouchMove(true)
            //console.log('onTouchMove')




            //-----------------------------------------------------------------------------------------------------------------------
            //touched drawing Auths:
            if(
                //for all trendline(1-8) midMove
                (onTouchMoveCount===0) 
                &&
                (cursorAndDrawingEditIdObj.isInTLmidRange ||
                cursorAndDrawingEditIdObj.isDrawingEndCordSelected ||
                cursorAndDrawingEditIdObj.isDrawingStartCordSelected )
            ){
                //console.log('yesssssssssssssssss........................', cursorAndDrawingEditIdObj)
                setSelectedDrawingForEditInfoIdObj({...cursorAndDrawingEditIdObj, isSelected:true})
                setIsDisplayChartTextInput(false)
            }
            else if(
                //trendline6
                (onTouchMoveCount===0) 
                &&
                (
                    cursorAndDrawingEditIdObj.drawingType==='trendline6'
                )
                &&
                (
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidTopSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidRightSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidBottomSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinMidLeftSquareRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinTopRightCircleRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinTopLeftCircleRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinBottomRightCircleRange ||
                    cursorAndDrawingEditIdObj.isCrossHair6WithinBottomLeftCircleRange 
                )
            )
            {
                //console.log('yepppppppppppppppppppppp........................', cursorAndDrawingEditIdObj)
                setSelectedDrawingForEditInfoIdObj({...cursorAndDrawingEditIdObj, isSelected:true, isInTLmidRange:false})   
                setIsDisplayChartTextInput(false)
            }
            else if(
                //trendline7
                (onTouchMoveCount===0) 
                &&
                (
                    cursorAndDrawingEditIdObj.drawingType==='trendline7'
                )
                &&
                (
                    cursorAndDrawingEditIdObj.isCrossHair7WithinMidRightSquareRange    ||
                    cursorAndDrawingEditIdObj.isCrossHair7WithinMidLeftCircleRange     ||
                    cursorAndDrawingEditIdObj.isCrossHair7WithinTopLeftSquareRange     ||
                    cursorAndDrawingEditIdObj.isCrossHair7WithinBottomLeftSquareRange  
                )
            ){
               setSelectedDrawingForEditInfoIdObj({...cursorAndDrawingEditIdObj, isSelected:true, isInTLmidRange:false})   
               setIsDisplayChartTextInput(false)
            }
            else{
                //console.log('onTouchMove :', onTouchMoveCount)
                setOnTouchMoveCount(onTouchMoveCount+1)
            }
            //-----------------------------------------------------------------------------------------------------------------------



            
            //-------------------------------------------------------------------------------------------------------------------------------------------------------
            const canvasEl = document.getElementById(`canvasCompoId-${canvasCompoId}`)
            const canvasOutLiarData = canvasEl.getBoundingClientRect()

            const canvasHeight = canvasEl.height
            const canvasWidth = canvasEl.width

            const outLiarX = Math.ceil(canvasOutLiarData.x)
            const outLiarY = canvasOutLiarData.y

            const touchLoc = e.touches[0]
            let Xcoord = touchLoc.clientX-outLiarX
            let Ycoord = touchLoc.clientY-outLiarY

            //invert chart coords
            //Ycoord = canvasHeight - Ycoord

            //--------------------------------------------------------------------------------------------------------------------
            //crossHair YcordPRICE SCALING config:

            //get devicePixelRatio - to scale canvas drawing back to - 1 to 1 ratio if( devicePixelRatio > 1 )
            const devicePixelRatio = window.devicePixelRatio || 1
            const ratioTargetToScaleCanvasChartTo = 1
            const pixelOutlierRatioAwayFrom1to1 = devicePixelRatio - ratioTargetToScaleCanvasChartTo

            //now scale canvas drawing style(w, h) to 1to1 - by taking out the Outlier/invincible drawing portion
            //to start the drawings from a 1to1 visible portion after the outlier had been subtracted
            const canvasChartHeightSCALEoutlierPX = (canvasHeight / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1
            const canvasChartWidthSCALEoutlierPX  = (canvasWidth / devicePixelRatio) * pixelOutlierRatioAwayFrom1to1

            //invert chart coords
            Ycoord = (canvasHeight - Ycoord) - canvasChartHeightSCALEoutlierPX
            //--------------------------------------------------------------------------------------------------------------------

            //--------------------------------------------------------------------------------------------------------
            const is2fingerTouch = e.changedTouches.length === 2 ? true : false
            
            if(isOnTouchStart2fingers===false && is2fingerTouch){
                //alert(`changedTouches : ${e.changedTouches[0].clientX}, ${e.changedTouches[1].clientX}, ${e.changedTouches.length}, ${[...e.changedTouches].length}`)
                setIsOnTouchStart2fingers(true)

                setTwofingerTouchXcords({
                    Xcoord1 : e.changedTouches[0].clientX, 
                    Xcoord2 : e.changedTouches[1].clientX
                })
            }
            //-------------------------------------------------------------------------------------------------------

            
            
            //if(isDrawingActivated===false && isPipCheckerActivated===false){
                setCrossHairOnMoveCordVals({
                    x: Xcoord, y: Ycoord
                })
            //}

            const XcoordPositionToChartWidthPerc = Math.floor((Xcoord / canvasWidth) * 100)//20%




            //--------------------------------------------------------------------------------------------------------------------------------------
            //onTouchScreens Alert awareness to - lock & unlock chart - to enjoy a given experience
            //1 - Alert to lock chart - if chart is not locked while editing
            //2 - get out of locked screen Alert - for a given vertical page scroll while chart is still locked  
            //3 - get Alert to lock chart - for a given non-Ymag vertical page scroll while chart is still locked 
            //4 - get Alert to lock chart - for a given Ymag-ed vertical page scroll while chart is still locked 

            if(onMouseDownVal && onMouseDownVal.Ycoord && isLockedChartWARNING===false){//vertical cord scroll Auth

                const coordStart = onMouseDownVal

                //1 - Alert to lock chart - if chart is not locked while editing
                if(isMouseDown && selectedDrawingForEditInfoIdObj.isSelected && isScreenScrollLocked===false){
                    setIsLockIcon(true)
                    setIsLockedChartWARNING(true)

                    setTimeout(()=>{
                        //if(isLockedChartWARNING){
                            setIsLockedChartWARNING(false)
                        //}
                    },3500)
                }
                //2 - get out of locked screen Alert - for a given vertical page scroll while chart is still locked  
                else if(
                    //false &&
                    isScreenScrollLocked &&
                    (
                        isDrawingActivated===false &&
                        isPipCheckerActivated===false &&
                        isReplayLineDrawing===false &&
                        selectedDrawingForEditInfoIdObj.isSelected===undefined
                    ) &&
                    Math.abs(Ycoord - coordStart.Ycoord) > Math.abs(Xcoord - coordStart.Xcoord) &&
                    Math.abs(Ycoord - coordStart.Ycoord) > (canvasEl.offsetHeight * 0.30) &&//0.35 - use real phone to test right scroll size before alert
                    coordStart.Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) && isYmagnificationActivated===false
                ){
                    /*console.log('Hello Victor...', Xcoord - coordStart.Xcoord, '  -  ', Ycoord - coordStart.Ycoord , '  -  ', canvasEl.offsetHeight,
                        isDrawingActivated===false,
                        isPipCheckerActivated===false,
                        isReplayLineDrawing===false,
                        selectedDrawingForEditInfoIdObj.isSelected===undefined, 
                    )*/

                    //alert(`${Ycoord - coordStart.Ycoord}`)

                    setIsLockIcon(false)
                    setIsLockedChartWARNING(true)

                    setTimeout(()=>{
                        //if(isLockedChartWARNING){
                            setIsLockedChartWARNING(false)
                        //}
                    },3500)

                } 
                else if(//3 - get Alert to lock chart - for a given non-Ymag vertical page scroll while chart is still locked 
                    //false &&
                    isScreenScrollLocked===false &&
                    (
                        isDrawingActivated===false &&
                        isPipCheckerActivated===false &&
                        isReplayLineDrawing===false &&
                        selectedDrawingForEditInfoIdObj.isSelected===undefined
                    ) &&
                    Math.abs(Ycoord - coordStart.Ycoord) > Math.abs(Xcoord - coordStart.Xcoord) &&
                    //Math.abs(Ycoord - coordStart.Ycoord) > canvasEl.offsetHeight * 0.1 &&//use real phone to test right scroll size before alert
                    coordStart.Xcoord > (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) && isYmagnificationActivated===false
                ){
                    /*console.log('Hello Victor...', Xcoord - coordStart.Xcoord, '  -  ', Ycoord - coordStart.Ycoord , '  -  ', canvasEl.offsetHeight,
                        isDrawingActivated===false,
                        isPipCheckerActivated===false,
                        isReplayLineDrawing===false,
                        selectedDrawingForEditInfoIdObj.isSelected===undefined, 
                    )*/

                    //alert(`${Ycoord - coordStart.Ycoord}`)

                    setIsLockIcon(true)
                    setIsLockedChartWARNING(true)

                    setTimeout(()=>{
                        //if(isLockedChartWARNING){
                            setIsLockedChartWARNING(false)
                        //}
                    },3500)

                }
                else if(//4 - get Alert to lock chart - for a given Ymag-ed vertical page scroll while chart is still locked 
                    //false &&
                    isScreenScrollLocked===false &&
                    (
                        isDrawingActivated===false &&
                        isPipCheckerActivated===false &&
                        isReplayLineDrawing===false &&
                        selectedDrawingForEditInfoIdObj.isSelected===undefined
                    ) &&
                    Math.abs(Ycoord - coordStart.Ycoord) > Math.abs(Xcoord - coordStart.Xcoord) //&&
                    //Math.abs(Ycoord - coordStart.Ycoord) > canvasEl.offsetHeight * 0.1 &&//use real phone to test right scroll size before alert
                    //coordStart.Xcoord > (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) 
                    && isYmagnificationActivated
                ){
                    /*console.log('Hello Victor...', Xcoord - coordStart.Xcoord, '  -  ', Ycoord - coordStart.Ycoord , '  -  ', canvasEl.offsetHeight,
                        isDrawingActivated===false,
                        isPipCheckerActivated===false,
                        isReplayLineDrawing===false,
                        selectedDrawingForEditInfoIdObj.isSelected===undefined, 
                    )*/

                    //alert(`${Ycoord - coordStart.Ycoord}`)

                    setIsLockIcon(true)
                    setIsLockedChartWARNING(true)

                    setTimeout(()=>{
                        //if(isLockedChartWARNING){
                            setIsLockedChartWARNING(false)
                        //}
                    },3500)

                }
            }
            //--------------------------------------------------------------------------------------------------------------------------------------



           
            if(
                isMouseDown && 
                isDrawingActivated===false && 
                isPipCheckerActivated===false &&
                (selectedDrawingForEditInfoIdObj.isSelected === undefined || selectedDrawingForEditInfoIdObj.isSelected === false)
                && isEditingDrawing===false
            ){
                //console.log('log-1')

                const coordStart = onMouseDownVal
                
                const {dataCandleStartIndex, dataCandleEndIndex} = dataCandleStartEndIndex
                const dataCandleStartEndIndexDiff = dataCandleEndIndex - dataCandleStartIndex//6
                const eachCandleBoxWidthSize = canvasWidth / dataCandleStartEndIndexDiff//83
                //console.log({dataCandleStartIndex, dataCandleEndIndex, dataCandleStartEndIndexDiff, eachCandleBoxWidthSize})


                //horizontal zooming
                if(
                    (
                        (isOnTouchStart2fingers/*is2fingerTouch*/)
                        ||
                        (coordStart.Ycoord < (datetimeLabelMarginSize) && 
                        coordStart.Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX))
                    )
                    &&
                    isYmagnificationActivated===false
                ){
                    //console.log('log-2')
                    
                    let XcoordDiff = Xcoord - coordStart.Xcoord
                    //console.log('mag - demag me : ', coordStart.Xcoord, " : ", coordStart.Ycoord)

                    
                    if(isOnTouchStart2fingers){
                        const Xcoord1  = twofingerTouchXcords.Xcoord1
                        const Xcoord2  = twofingerTouchXcords.Xcoord2

                        const Xcoord11 = e.changedTouches[0].clientX
                        const Xcoord22 = e.changedTouches[1].clientX

                        const firstDiff  = Math.abs(Xcoord2 - Xcoord1)
                        const secondDiff = Math.abs(Xcoord22 - Xcoord11)

                        XcoordDiff = secondDiff >= firstDiff ? -100 : 100

                        //alert(`changedTouches : ${e.changedTouches[0].clientX}, ${e.changedTouches[1].clientX}, ${e.changedTouches.length}, ${[...e.changedTouches].length}`)
                    }

                    //alert('fddf')
                    const XmagFastMagMultiplier = 1.5 + 0.5//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half
                    const zoomCountInterval = /*coordStart.Ycoord < (datetimeLabelMarginSize) ? -1 :*/ (1) * XmagFastMagMultiplier // Per every wheel activity: mag or de-mag by +1/-1


                    //asuafu - touch
                    if(XcoordDiff < 0){

                        
                        const newDataCandleStartIndex = dataCandleStartIndex + zoomCountInterval
                        const newDataCandleEndIndex   = dataCandleEndIndex   //- Math.floor(candleXzoomCount)
                        if(
                            newDataCandleStartIndex >= 0 &&
                            (newDataCandleEndIndex - newDataCandleStartIndex) > maxMagnificationCandleCountLimit &&
                            newDataCandleEndIndex <= data.length 
                        ){
                            setDataCandleStartEndIndex({
                                dataCandleStartIndex : newDataCandleStartIndex,
                                dataCandleEndIndex   : newDataCandleEndIndex
                            })
                        }
                        
                    }
                    else if(XcoordDiff > 0){

                        
                        const newDataCandleStartIndex = (dataCandleStartIndex - zoomCountInterval) < 0 ? 0 : dataCandleStartIndex - zoomCountInterval
                        const newDataCandleEndIndex   = (dataCandleStartIndex - zoomCountInterval) < 0 ? dataCandleEndIndex + zoomCountInterval : dataCandleEndIndex 
                        if(
                            newDataCandleStartIndex >= 0 &&
                            (newDataCandleEndIndex - newDataCandleStartIndex) > maxMagnificationCandleCountLimit &&
                            newDataCandleEndIndex <= data.length 
                        ){
                            setDataCandleStartEndIndex({
                                dataCandleStartIndex : newDataCandleStartIndex,
                                dataCandleEndIndex   : newDataCandleEndIndex
                            })
                        }
                        
                    }
                }
                else if(
                    (
                        (isOnTouchStart2fingers/*is2fingerTouch*/)
                        ||
                        (coordStart.Ycoord < (datetimeLabelMarginSize) && 
                        coordStart.Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX)) 
                    )
                    &&
                    isYmagnificationActivated //&& false
                ){
                    //console.log('log-5')
                    

                    let XcoordDiff = Xcoord - coordStart.Xcoord
                    //console.log('mag - demag - Ymag : ', coordStart.Xcoord, " : ", coordStart.Ycoord)
                    //console.log('hey............')

                    if(isOnTouchStart2fingers){
                        const Xcoord1  = twofingerTouchXcords.Xcoord1
                        const Xcoord2  = twofingerTouchXcords.Xcoord2

                        const Xcoord11 = e.changedTouches[0].clientX
                        const Xcoord22 = e.changedTouches[1].clientX

                        const firstDiff  = Math.abs(Xcoord2 - Xcoord1)
                        const secondDiff = Math.abs(Xcoord22 - Xcoord11)

                        XcoordDiff = secondDiff >= firstDiff ? -100 : 100

                        //alert(`changedTouches : ${e.changedTouches[0].clientX}, ${e.changedTouches[1].clientX}, ${e.changedTouches.length}, ${[...e.changedTouches].length}`)
                    }


                    const XmagFastMagMultiplier = 1.5* 4//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half - //on mobile - horizontal scroll should be 2-times faster than on PC
                    const zoomCountInterval = (1) * XmagFastMagMultiplier // Per every wheel activity: mag or de-mag by +1/-1
                    const bounceZoomFromZoomLimitRatioSize = zoomCountInterval/100

                    /*const zoomRatio = 0.00000000001
                    candleXzoomCount = Math.floor(candleXzoomCount*zoomRatio)*/
                    //console.log('candleXzoomCount : ', candleXzoomCount/100)
                    //console.log('hello : ', (chartCandleBoxWidthStartPX * (XmagnificationPercRatio-(zoomCountInterval/100))))

                    setXmagnificationPercRatio(
                        //XcoordDiff >= 0 ? XmagnificationPercRatio-(zoomCountInterval/100) : XmagnificationPercRatio+(zoomCountInterval/100)

                        XcoordDiff >= 0 && (chartCandleBoxWidthStartPX * (XmagnificationPercRatio-(zoomCountInterval/100))) < (-chartCandleBoxWidthStartPX) ? 
                        (XmagnificationPercRatio + bounceZoomFromZoomLimitRatioSize) : 
                        (XcoordDiff >= 0 ? XmagnificationPercRatio-(zoomCountInterval/100) : XmagnificationPercRatio+(zoomCountInterval/100))
                    )

                }
                //horizontal scale scrolling
                else if(
                    coordStart.Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) && isYmagnificationActivated===false
                ){

                    /*console.log('Hello Victor...', Xcoord - coordStart.Xcoord, '  -  ', Ycoord - coordStart.Ycoord , '  -  ', canvasEl.offsetHeight,
                    
                    isDrawingActivated===false,
                    isPipCheckerActivated===false,
                    isReplayLineDrawing===false,
                    selectedDrawingForEditInfoIdObj.isSelected===undefined, 
                    )*/
                    //console.log('log-6')
                    const horizontalFastScrollMultiplier = 2.5 * 2//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half - //on mobile - horizontal scroll should be 2-times faster than on PC
                    const XcoordDiff = Xcoord - coordStart.Xcoord
                    const candleXvelocityCount = (XcoordDiff/eachCandleBoxWidthSize) * horizontalFastScrollMultiplier
                    //console.log("XcoordDiff : ", XcoordDiff, candleXvelocityCount)

                    const newDataCandleStartIndex = onMouseDownVal.dataCandleStartIndex - Math.floor(candleXvelocityCount)
                    const newDataCandleEndIndex   = onMouseDownVal.dataCandleEndIndex - Math.floor(candleXvelocityCount)

                    if(
                        newDataCandleEndIndex <= data.length &&
                        newDataCandleStartIndex >= 0
                    ){
                        setDataCandleStartEndIndex({
                            dataCandleStartIndex : newDataCandleStartIndex,
                            dataCandleEndIndex   : newDataCandleEndIndex
                        })
                    }

                    if(newDataCandleEndIndex === data.length-1){
                        //console.log('we are at current chart data of candle id=0, update chart or do something')
                    }
                    
                }
                else if(
                    coordStart.Xcoord < (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX) && isYmagnificationActivated
                ){
                    //console.log('log-7')
                    const horizontalFastScrollMultiplier = 2//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half - //on mobile - horizontal scroll should be 2-times faster than on PC
                    const XcoordDiff = (Xcoord - coordStart.Xcoord) * horizontalFastScrollMultiplier
                    const YcoordDiff = (Ycoord - coordStart.Ycoord) * horizontalFastScrollMultiplier 

                    /*
                    beforeYmagChartInfoTracker.isYmagInfoTracked
                    */
                    setChartXvelocity(
                        beforeYmagChartInfoTracker.isYmagInfoTracked 
                        && (
                            (coordStart.chartXvelocity + XcoordDiff) > beforeYmagChartInfoTracker.rightScrollXvelocityLimit ||
                            (coordStart.chartXvelocity + XcoordDiff) < beforeYmagChartInfoTracker.leftScrollXvelocityLimit
                        )
                        ?
                        coordStart.chartXvelocity// + XcoordDiff
                         : (coordStart.chartXvelocity + XcoordDiff)
                    )

                    //if(isYmagnificationActivated){
                        setChartYvelocity(coordStart.chartYvelocity + (YcoordDiff * -1))
                    //}

                    setIsBlinkLockChartIcon(true)
                    //console.log("onMouseMove", Xcoord, Ycoord,XcoordDiff, YcoordDiff, onMouseDownVal)
                }
                else if(
                    chartInfoObj.candleORrsi==='candle' &&
                    (coordStart.Xcoord > (canvasWidth - priceLabelMarginSize - canvasChartWidthSCALEoutlierPX))
                ){
                    //console.log('log-8')
                    //console.log('lets do Ymagnification ')
                    //alert('hello')

                    const YmagFastMagMultiplier = 3 * 2//a horizontal magnification candle range must be a whole number & no halves included - as a candle can't be half - //on mobile - horizontal scroll should be 2-times faster than on PC
                    const XcoordDiff = Xcoord - coordStart.Xcoord
                    const YcoordDiff = Ycoord - coordStart.Ycoord 
                    const YcoordDiffToChartPerc = (YcoordDiff / canvasHeight) * YmagFastMagMultiplier
                    const YmagnificationPercRatioStartVal = coordStart.YmagnificationPercRatio 

                    //setChartXvelocity(coordStart.chartXvelocity + XcoordDiff)
                    if(/*isYmagnificationActivated &&*/ Math.abs(YcoordDiff) > 5+5 && (Math.abs(YcoordDiff) > Math.abs(XcoordDiff))){
                        //console.log('YcoordDiff : ', YcoordDiff)
                        
                        
                        if(isYmagnificationActivated===false && isScreenScrollLocked){
                            /*setYmagnificationCandleStartEndIndex({
                                dataCandleStartIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                                dataCandleEndIndex :  dataCandleStartEndIndex.dataCandleEndIndex
                            })*/
                            setYmagnificationPercRatio(
                                (YmagnificationPercRatioStartVal + YcoordDiffToChartPerc) < YChartHeightDeMagRatioLimitUptoAbsOfOne ? YChartHeightDeMagRatioLimitUptoAbsOfOne :
                                (YmagnificationPercRatioStartVal + YcoordDiffToChartPerc)
                            )

                            setIsYmagnificationActivated(true)
                            setBeforeYmagCandleIndexInfoObj({
                                startIndex : dataCandleStartEndIndex.dataCandleStartIndex,
                                endIndex   : dataCandleStartEndIndex.dataCandleEndIndex,
                                candleWidthSize : crossHairMidStartXcordDrawerInfo.candleWidthSize,
                                chartXvelocityBefore : Math.abs(dataCandleStartEndIndex.dataCandleEndIndex - dataCandleStartEndIndex.dataCandleStartIndex) * crossHairMidStartXcordDrawerInfo.candleWidthSize
                            })
                        }
                        else if(isScreenScrollLocked){
                            setYmagnificationPercRatio(
                                (YmagnificationPercRatioStartVal + YcoordDiffToChartPerc) < YChartHeightDeMagRatioLimitUptoAbsOfOne ? YChartHeightDeMagRatioLimitUptoAbsOfOne :
                                (YmagnificationPercRatioStartVal + YcoordDiffToChartPerc)
                            )
                        }
                        else{
                            setIsBlinkLockChartIcon(true)
                        }
                    }
                }

            }
            else{
                //console.log("onMouseMove", Xcoord, Ycoord)

                if(isDrawingActivated){
                    setTrendlineCordEnd({
                        x:Xcoord, y:Ycoord
                    })
                }
                else if(isPipCheckerActivated){//badu
                    setPipCheckerCordEnd({
                        x:Xcoord, y:Ycoord
                    })
                }
            }


            //-------------------------------------------------------------------------------------------------------------------------------------------------------

             setUseEffectReRunCounter(Date.now())

            }

        }}


        //happens on both phone & PC
        onClick={(e)=>{//left Click

            if(data.length>0){
            //setIsTouchScreenDevice(false)
            //console.log("onClick - left Click")

            //setIsMouseDown(true)
            //console.log('clicked')

            //setUseEffectReRunCounter(Date.now())
            }
        }}



        onTouchEnd={(e)=>{//

            if(data.length>0){



            //console.log("onTouchEnd", //e
            //)


            //-------------------------------------------------------------------------------------------------------------------------------------------------------
            setIsMouseDown(false)

            setIsOnTouchMove(false)

            setOnTouchMoveCount(0)

            setIsOnTouchStart2fingers(false)

            if(
                //isEditingDrawing && 
                selectedDrawingForEditInfoIdObj.isSelected
                && selectedDrawingForEditInfoIdObj.drawingType === 'trendline5'
                && (selectedDrawingForEditInfoIdObj.isReplayLineDrawing || selectedDrawingForEditInfoIdObj.isGlobalLockedVertiLine)
                && (
                    selectedDrawingForEditInfoIdObj.id === (
                        window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto) !== null &&
                        JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto))[selectedDrawingForEditInfoIdObj.id] &&
                        JSON.parse(window.localStorage.getItem(selectedDrawingForEditInfoIdObj.isReplayLineDrawing ? "replayDatetimeLineLockedObj" + forexORcrypto : "verticalDatetimeLineLockedObj" + forexORcrypto))[selectedDrawingForEditInfoIdObj.id]
                    )
                )
            ){
                //console.log('badu.......')
                setReloadAllChartsUseEffectCounter(Date.now())
            }

           

            if(chartXvelocity > chartXvelocityPrevVal){
                setChartXvelocityPrevVal(chartXvelocity)
            }

            if(onDrawingMoveInfoTrackerObj.id){

                //do something with drawing info & empty it
                //setOnDrawingMoveInfoTrackerObj({})
                setIsDrawingDoneOnMouseUp(true)
                setOnlyOneClickDrawerInfoObj({})
            }
            else if(isDrawingActivated && onDrawingMoveInfoTrackerObj.id===undefined){
                //console.log('only one click draw : ', trendlineTypeToDraw)
                //window.localStorage.setItem(`${chartInfoObj.instruName}_onlyOneClickDrawerId` + forexORcrypto, JSON.stringify(crossHairMidStartXcordDrawerInfo.id))
                //setOnlyOneClickDrawerInfoObj(crossHairMidStartXcordDrawerInfo)

                //console.log('runned')

                //----------------------------------------------------------------------------------------------------------------------------------------------
                let startLineXcord = 0
                let startLineYcord = 0
                let endLineXcord   = 0
                let endLineYcord   = 0
                let arcXcord       = 0
                let arcYcord       = 0
                let defaultRectSquareDrawingCandleWidthCountAuthCount = 0
                let defaultRectSquareDrawingHeight = 0
                let rectSquareUpperHeightPrice     = 0
                let rectSquareLowerHeightPrice     = 0

                const TLtypeDrawnByOneClick = onlyOneClickDrawerInfoObj.drawingType
                const candleWidthSize       = onlyOneClickDrawerInfoObj.candleWidthSize
                const minMaxPriceValDiff    = onlyOneClickDrawerInfoObj.minMaxPriceValDiff
                const minPriceVal           = onlyOneClickDrawerInfoObj.minPriceVal
                const YcordTouchVal         = crossHairOnMoveCordVals.y//onlyOneClickDrawerInfoObj.crossHairStartInfos.y
                const XcordTouchVal         = crossHairOnMoveCordVals.x//onlyOneClickDrawerInfoObj.crossHairStartInfos.x

                /*if(
                    //true
                    TLtypeDrawnByOneClick===''
                ){
                    alert(TLtypeDrawnByOneClick)
                }*/


                if(TLtypeDrawnByOneClick === 'trendline7'){
                    defaultRectSquareDrawingHeight = canvasHeight * defaultRectSquareDrawingAtChartHeightRatio

                    defaultRectSquareDrawingCandleWidthCountAuthCount = (onlyOneClickDrawerInfoObj.xCord + (candleWidthSize * defaultRectSquareDrawingCandleWidthCount)) > canvasWidth 
                    ? Math.floor((canvasWidth - onlyOneClickDrawerInfoObj.xCord) / candleWidthSize) : defaultRectSquareDrawingCandleWidthCount
                }


                if(true/*onlyOneClickDrawerInfoObj.drawingOnFirstDragEditCount === 0*/){
                    //console.log('is one click only to be calc')
                    if(
                        TLtypeDrawnByOneClick === 'trendline1' ||
                        TLtypeDrawnByOneClick === 'trendline8' ||
                        TLtypeDrawnByOneClick === 'trendline2' ||
                        TLtypeDrawnByOneClick === 'trendline6'
                    ){
                        startLineXcord = onlyOneClickDrawerInfoObj.xCord
                        startLineYcord = canvasHeight - YcordTouchVal

                        endLineXcord   = onlyOneClickDrawerInfoObj.xCord
                        endLineYcord   = TLtypeDrawnByOneClick === 'trendline2' ? canvasHeight - YcordTouchVal : canvasHeight - YcordTouchVal

                        arcXcord       = onlyOneClickDrawerInfoObj.xCord
                        arcYcord       = canvasHeight - YcordTouchVal
                    }
                    else if(
                        TLtypeDrawnByOneClick === 'trendline3' ||
                        TLtypeDrawnByOneClick === 'trendline4' ||
                        TLtypeDrawnByOneClick === 'trendline5' ||
                        TLtypeDrawnByOneClick === 'trendline7'
                    ){

                        //console.log('wenchi---oooooooooooooooooooooooooooo')
                        startLineXcord = TLtypeDrawnByOneClick === 'trendline4' ? 0 : onlyOneClickDrawerInfoObj.xCord//onlyOneClickDrawerInfoObj.xCord
                        startLineYcord = TLtypeDrawnByOneClick === 'trendline5' ? 0 : canvasHeight - YcordTouchVal

                        endLineXcord   = TLtypeDrawnByOneClick === 'trendline7' ? onlyOneClickDrawerInfoObj.xCord + (candleWidthSize * defaultRectSquareDrawingCandleWidthCountAuthCount) : 
                        (TLtypeDrawnByOneClick === 'trendline3' || TLtypeDrawnByOneClick === 'trendline4') ? canvasWidth : onlyOneClickDrawerInfoObj.xCord
                        endLineYcord   = TLtypeDrawnByOneClick === 'trendline5' ? canvasHeight : canvasHeight - YcordTouchVal

                        arcXcord       = TLtypeDrawnByOneClick === 'trendline4' ? 0 : onlyOneClickDrawerInfoObj.xCord//onlyOneClickDrawerInfoObj.xCord
                        arcYcord       = TLtypeDrawnByOneClick === 'trendline5' ? 0 : canvasHeight - YcordTouchVal
                    }
                }



                //onlyOneClickDrawerInfoObj.crossHairStartInfos
                //console.log({crossHairStartInfos:YcordTouchVal})
                //price reversal from PX //drawingEditCursorOutlierLimit
                let priceStarter = YcordTouchVal
                let priceEnder   = YcordTouchVal

                
                const isStartAndEndDatetimeSame = startLineXcord===endLineXcord
                const YcordDiff = YcordTouchVal - YcordTouchVal
                const isYcordDiffPositiveVal = YcordDiff > 0

                if((TLtypeDrawnByOneClick === 'trendline1' || TLtypeDrawnByOneClick === 'trendline8') && 
                isStartAndEndDatetimeSame && Math.abs(YcordDiff) < candleWidthSize*1
                ){
                    priceEnder   = YcordTouchVal + (isYcordDiffPositiveVal ? candleWidthSize*2 : -(candleWidthSize*2))
                }
                /*console.log({
                    isStartAndEndDatetimeSame,
                    YcordDiff,
                    isYcordDiffPositiveVal
                })*/


                const cordYpriceStart = ((priceStarter + YchartShiftPX + chartYvelocity) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasHeight) + minPriceVal;
                const cordYpriceEnd   = ((priceEnder   + YchartShiftPX + chartYvelocity) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasHeight) + minPriceVal;
                //console.log(cordYpriceStart, " : ", cordYpriceEnd)

                const timestampStart = datetimeToTimestamp(onlyOneClickDrawerInfoObj.datetime);
                const timestampEnd   = datetimeToTimestamp(onlyOneClickDrawerInfoObj.datetime);
                const isEndTimeStampGreater = timestampEnd >= timestampStart
                /*console.log({
                    timestampStart,
                    timestampEnd,
                    isEndTimeStampGreater
                })*/

                let drawingDatetimeStart = isEndTimeStampGreater ? onlyOneClickDrawerInfoObj.datetime : onlyOneClickDrawerInfoObj.datetime
                let drawingDatetimeEnd   = isEndTimeStampGreater ? onlyOneClickDrawerInfoObj.datetime : onlyOneClickDrawerInfoObj.datetime

                let drawingPriceStart = isEndTimeStampGreater ? cordYpriceStart : cordYpriceEnd
                let drawingPriceEnd   = isEndTimeStampGreater ? cordYpriceEnd : cordYpriceStart


                if(
                    TLtypeDrawnByOneClick    === 'trendline4'
                    || TLtypeDrawnByOneClick === 'trendline5'
                    || TLtypeDrawnByOneClick === 'trendline3'
                ){
                    drawingDatetimeStart = onlyOneClickDrawerInfoObj.datetime
                    drawingDatetimeEnd   = onlyOneClickDrawerInfoObj.datetime

                    drawingPriceStart    = cordYpriceEnd
                    drawingPriceEnd      = cordYpriceEnd
                }


                let trendline7DatetimeEnd = onlyOneClickDrawerInfoObj.datetime
                if(TLtypeDrawnByOneClick === 'trendline7'){
                    const datetimeResArray = getNextOrPrevDatetimeByTF(
                        onlyOneClickDrawerInfoObj.datetime,//datetime, 
                        chartInfoObj.tf, 
                        "next",//nextORprevDatetimesToGet, 
                        defaultRectSquareDrawingCandleWidthCountAuthCount,//nextORprevDatetimeToGetCount, 
                        {},//{...dbData[0], open: dbData[0].close, high:dbData[0].close, low:dbData[0].close},//prevDatetimeToGetPriceDataObj, 
                        {},//{...dbData[dbData.length-1], open: dbData[dbData.length-1].close, high:dbData[dbData.length-1].close, low:dbData[dbData.length-1].close}//nextDatetimeToGetPriceDataObj
                    )

                    trendline7DatetimeEnd = datetimeResArray[datetimeResArray.length - 1].datetime

                    const rectSquareUpperHeightPriceYcord = priceEnder + defaultRectSquareDrawingHeight  
                    const rectSquareLowerHeightPriceYcord = priceEnder - defaultRectSquareDrawingHeight

                    rectSquareUpperHeightPrice = ((rectSquareUpperHeightPriceYcord + YchartShiftPX + chartYvelocity) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasHeight) + minPriceVal;
                    rectSquareLowerHeightPrice = ((rectSquareLowerHeightPriceYcord + YchartShiftPX + chartYvelocity) / (1.0 + YmagnificationPercRatio)) * (minMaxPriceValDiff / canvasHeight) + minPriceVal;

                }
                //----------------------------------------------------------------------------------------------------------------------------------------------




                //----------------------------------------------------------------------------------------------------------------------------------------------
                const oneClickTrendlineInfoObj = {//store this in state until mouseUp for localStorage
                    drawingType         : onlyOneClickDrawerInfoObj.drawingType,//'trendline1',
                    datetimeStart       : (TLtypeDrawnByOneClick === 'trendline3' || TLtypeDrawnByOneClick === 'trendline7') ? 
                    onlyOneClickDrawerInfoObj.datetime : drawingDatetimeStart,//'2023-08-22 08:00:00',//drawingDatetimeStart
                    datetimeEnd         : TLtypeDrawnByOneClick === 'trendline7' ? trendline7DatetimeEnd : (
                        //TLtypeDrawnByOneClick === 'trendline3' ||
                        //TLtypeDrawnByOneClick === 'trendline4' ||
                        TLtypeDrawnByOneClick === 'trendline5'
                    ) ? drawingDatetimeStart : drawingDatetimeEnd, //'2023-08-29 20:00:00',//drawingDatetimeEnd
                    cordYpriceStart     : (TLtypeDrawnByOneClick === 'trendline3' || TLtypeDrawnByOneClick === 'trendline7') ? cordYpriceEnd : drawingPriceStart,//1.09188,
                    cordYpriceEnd       : (TLtypeDrawnByOneClick === 'trendline3' || TLtypeDrawnByOneClick === 'trendline7') ? cordYpriceEnd : 
                    TLtypeDrawnByOneClick === 'trendline1' || TLtypeDrawnByOneClick === 'trendline8' || TLtypeDrawnByOneClick === 'trendline6' ? drawingPriceEnd : drawingPriceStart,//1.07877,
                    candleBoxWidthCount : TLtypeDrawnByOneClick === 'trendline7' ? defaultRectSquareDrawingCandleWidthCountAuthCount :
                    Math.round(Math.abs((onlyOneClickDrawerInfoObj.xCord - onlyOneClickDrawerInfoObj.xCord) / candleWidthSize)),//round important
                    candleBoxWidthSize  : candleWidthSize,
                    tfStarter           : chartInfoObj.tf,
                    tfEditor            : chartInfoObj.tf,
                    id                  : onlyOneClickDrawerInfoObj.id,//id:Date.now(),

                    isRectDrawingColored : isRectangleDrawingColored,//eSquareDrawingSell
                    isRectSquareDrawingSell : isRectangleSquareDrawingSell,

                    rectSquareUpperHeightPrice, 
                    rectSquareLowerHeightPrice, 

                    isDrawingForward : isEndTimeStampGreater,

                    isTrendlineEndPointy : TLtypeDrawnByOneClick === 'trendline1' ? isTrendlineEndPointy : false,

                    //isRectangleTextInput
                    isRectangleTextInput : TLtypeDrawnByOneClick === 'trendline6' && isRectangleTextInput ? true : false,
                    text : TLtypeDrawnByOneClick === 'trendline6' && isRectangleTextInput ? "Text" : "",

                    //isReplayLineDrawing
                    isReplayLineDrawing : TLtypeDrawnByOneClick === 'trendline5' && isReplayLineDrawing ? true : false,

                    isGlobalLockedVertiLine : false
                }

                //setOnDrawingMoveInfoTrackerObj(oneClickTrendlineInfoObj)
                //setCursorAndDrawingEditIdObj({...oneClickTrendlineInfoObj, isSelected:true})
                //setOnDrawingMoveInfoTrackerObj(oneClickTrendlineInfoObj)
                //----------------------------------------------------------------------------------------------------------------------------------------------




                //----------------------------------------------------------------------------------------------------------------------------------------------
                //store drawing in LStorage
                if(//store drawing if its candleBoxWidthCount is > a given number
                    oneClickTrendlineInfoObj !== undefined 
                    && 
                    (//TLtypeDrawnByOneClick==='trendline6'&& isDrawingActivated && isRectangleTextInput ?
                        oneClickTrendlineInfoObj.drawingType==="trendline1" ||
                        oneClickTrendlineInfoObj.drawingType==="trendline2" || 
                        (oneClickTrendlineInfoObj.drawingType==="trendline6" && isRectangleTextInput===false)
                        ? oneClickTrendlineInfoObj.candleBoxWidthCount >= 1 : true
                    )
                ){
                    //store tracked drawing data in the localstorage
                    const drawingData = localStorage.getItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto)
                    const isDataExist = drawingData !== null

                    if(isDataExist){
                        const data = JSON.parse(drawingData)

                        let newData = [...data]
                        
                        if(oneClickTrendlineInfoObj.id){
                            
                            //add only if id does not exists
                            //do same Auth for onDraw Storage & Date.now()
                            //or prevent it from running twice

                            if(TLtypeDrawnByOneClick === 'trendline5' && isReplayLineDrawing){//delete any existing replay line before storing a new1
                                newData = newData.filter((el, elIndx)=>{
                                    return(el.drawingType!=='trendline5' || (el.drawingType==='trendline5' && el.isReplayLineDrawing===false) )
                                })

                                window.localStorage.setItem("replayDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))

                            }
                            
                            

                            //if(alreadyExistIdArray.length===0){
                                //console.log('id inserted ', alreadyExistIdArray.length)
                                newData = [...newData, oneClickTrendlineInfoObj]

                                window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify(newData))
                            //}
                            
                            setSelectedDrawingForEditInfoIdObj({...oneClickTrendlineInfoObj, isSelected:true})
                            
                            setOnDrawingMoveInfoTrackerObj({})
                        }

                        

                    }
                    else{
                        window.localStorage.setItem(`${chartInfoObj.instruName}_${chartInfoObj.candleORrsi}_${chartInfoObj.indexORpair}_drawingsDataArray` + forexORcrypto, JSON.stringify([oneClickTrendlineInfoObj]))
                        //keep drawing still selected immediately after drawing to open for any Editing
                        setSelectedDrawingForEditInfoIdObj({...oneClickTrendlineInfoObj, isSelected:true})
                        setOnDrawingMoveInfoTrackerObj({})
                    }

                    //setIsDrawingDoneOnMouseUp(false)
                    //setUseEffectReRunCounter(Date.now())
                }
                else{
                    setSelectedDrawingForEditInfoIdObj({})
                    //setIsDrawingDoneOnMouseUp(false)

                    //setUseEffectReRunCounter(Date.now())
                }
                //*/


                //alert('hey1')

                //setIsOnlyOneClickDrawOnTouchScreen(false)
            }
            else{
                setOnDrawingMoveInfoTrackerObj({})
                setOnlyOneClickDrawerInfoObj({})
            }

            //-------------------------------------
            //setSelectedDrawingForEditInfoIdObj({})
            //setCrossHairMidStartXcordDrawerInfo({})
            //setOnDrawingMoveInfoTrackerObj({})
            //setCursorAndDrawingEditIdObj({})
            //-------------------------------------


            setOnMouseDownVal({})
            setIsDrawingActivated(false)
            //setTrendlineTypeToDraw('')
            //setIsRectangleTextInput(false)
            setIsPipCheckerActivated(false)
            setIsEditingDrawing(false)

            setIsDeleteAllChartDrawings(false)
            setIsMakeAllDrawingsVisible(false)
            setIsMakeAllDrawingsInvisible(false)

            //setIsLockedVertiLineSelected(false)
            //setLockedVertiLineSelectedDatetime('')

            //setTrendlineCordStart({x:-1, y:-1})
            //setTrendlineCordEnd({x:-1, y:-1})

            setIsBlinkLockChartIcon(false)

            setMouseDownCount(0)

            //------------------------------
            setIsOnTouchStart2fingers(false)
            


            //-------------------------------------------------------------------------------------------------------------------------------------------------------
            //at the end of every onTouchEnd :
            //Update LStorage with settings&stateUpdates for both chart_num & instru_tf

            //-------------------------------------------------------------------------------------------------------------------------------------------------------
            
            

            setUseEffectReRunCounter(Date.now())

            }

        }}


    >  
    </canvas>

    }

    </div>




    {
        true 

        &&

        <>

        <div
        style={{
            width: '95%',
            height:'100%',
            //border: '1px solid black',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            //marginRight:'5%',
            fontWeight:'bold',
            fontSize:'13.5px',
            color:'green',
            fontStyle:'italic',
        }}
        >
            <div>
                Live Update
            </div>

            <div className="coin4"
            style={{
                //border:'1px solid black',
                height:'10px', 
                width:'10px',
                borderRadius:'50%',
                backgroundColor:'lightgreen',
                marginLeft:'10px', marginRight:'10px'
            }}
            >{liveUpdateSECcountdown}</div>

            <div
            style={{color:'rgba(0,0,255,0.7)'}}
            >
                {`${liveUpdateSECcountdown}s`}
            </div>
        </div>

        <div 
        style={{
            fontSize:'12px', 
            color:'rgba(0, 0, 0, 0.7)',
            marginLeft:'5px',
            borderBottom:'1px solid rgba(0,0,0,0.2)',
            paddingBottom:'10px',
        }}
        >   Click on lock <span style={{border:'1px solid gray', borderRadius:'5px',
            marginLeft:'4px',
            marginRight:'4px'
            }}>
            <BsLock
            
            />
            </span> , noTouch <span style={{border:'1px solid gray', borderRadius:'5px',
                marginLeft:'4px',
                marginRight:'4px'
            }}> <MdDoNotTouch //BsLock 
                        style={{
                            //cursor:'pointer', 
                            //transform:'scale(1.5)',
                            //color:'blue'
                        }}
                        
                    /> </span> 
                    , zoom <span style={{border:'1px solid gray', borderRadius:'5px',
                marginLeft:'4px',
                marginRight:'4px'
            }}> <MdZoomInMap 
                        style={{
                            //cursor:'pointer', 
                            //transform:'scale(1.5)',
                            //color:'blue'
                        }}
                        
                    /> </span> & reload <span style={{border:'1px solid gray', borderRadius:'5px',
                    marginLeft:'4px',
                    marginRight:'4px'
                }}> <IoIosRefresh 
                            style={{
                                //cursor:'pointer', 
                                //transform:'scale(1.5)',
                                //color:'blue'
                            }}
                            
                        /> </span> icons to: lock , disable , zoom & reload chart movements 
        </div>

        

        </>
    }

    

    </div>




</div>
);
};







function createRandomRGBColor() {
    var red = getRandomInt(0, 257);
    var green = getRandomInt(0, 257);
    var blue = getRandomInt(0, 257);
    return {r: red, g: green, b: blue};
  }
  




function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}



function pythagorasCalculator(//{calcType : 'sin', varToCalc : 'o', adjacent : 5, opposite : 5, angle : 45, hypotenuse : 7.07}
    paramObj//calcType='sin', varToCalc='h', paramObj={calcType : 'sin', varToCalc : 'a', adjacent : 5, opposite : 5, angle : 45, hypotenuse : 7.07}
){

    try{
    /*const adjacent   = 5
    const opposite   = 5
    const angle      = 45
    const hypotenuse = 7.07*/

    if(paramObj.calcType==='sin'){

        if(paramObj.varToCalc==='aa'){//calc for angle
            // Ensure the hypotenuse is greater than or equal to the opposite side
            let angleInDegrees = 0
            if (paramObj.hypotenuse >= paramObj.opposite) {
                // Calculate the angle in radians
                const angleInRadians = Math.asin(paramObj.opposite / paramObj.hypotenuse);

                // Convert radians to degrees
                angleInDegrees = (angleInRadians * 180) / Math.PI;

                //console.log('Angle:', angleInDegrees, 'degrees');
            } 

            return /*Math.round(*/angleInDegrees/*)*/
        }
        else if(paramObj.varToCalc==='o'){//calc for opposite
            // Convert angle to radians (Math.sin() in JavaScript expects radians)
            const angleInRadians = (paramObj.angle * Math.PI) / 180;

            // Calculate the opposite side
            const opposite = paramObj.hypotenuse * Math.sin(angleInRadians);

            //console.log("Opposite:", opposite);
            return opposite
        }
        else if(paramObj.varToCalc==='h'){//calc for hypotenuse
            //convert degrees to radians
            const angleInRadians = paramObj.angle * (Math.PI / 180);

            // Calculate the hypotenuse
            const hypotenuse = paramObj.opposite / Math.sin(angleInRadians);

            //console.log("Hypotenuse:", hypotenuse);
            return hypotenuse
        }
        else{//return 0 if all false
            return 0
        }
    }
    else if(paramObj.calcType==='cos'){
        if(paramObj.varToCalc==='aa'){//calc for angle
            // Ensure the hypotenuse is greater than or equal to the adjacent side
            let angleInDegrees = 0
            if (paramObj.hypotenuse >= paramObj.adjacent) {
                // Calculate the angle in radians
                const angleInRadians = Math.acos(paramObj.adjacent / paramObj.hypotenuse);

                // Convert radians to degrees
                angleInDegrees = (angleInRadians * 180) / Math.PI;

                //console.log('Angle:', angleInDegrees, 'degrees');
            } 

            return angleInDegrees
        }
        else if(paramObj.varToCalc==='a'){//calc adjacent
            // Convert angle to radians
            const angleInRadians = (paramObj.angle * Math.PI) / 180;

            // Calculate the adjacent side
            const adjacent = paramObj.hypotenuse * Math.cos(angleInRadians);

            //console.log("adjacent:", adjacent);
            return adjacent
        }
        else if(paramObj.varToCalc==='h'){//calc hypotenuse
            //convert degrees to radians
            const angleInRadians = paramObj.angle * (Math.PI / 180);

            // Calculate the hypotenuse
            const hypotenuse = paramObj.adjacent / Math.cos(angleInRadians);

            //console.log("Hypotenuse:", hypotenuse);
            return hypotenuse
        }
        else{//return 0 if all false
            return 0
        }
    }
    else if(paramObj.calcType==='tan'){
        if(paramObj.varToCalc==='aa'){//calc for angle
            
            let angleInDegrees = 0
            
            // Calculate the angle in radians
            const angleInRadians = Math.atan(paramObj.opposite / paramObj.adjacent);

            // Convert radians to degrees
            angleInDegrees = (angleInRadians * 180) / Math.PI;

            //console.log('Angle:', angleInDegrees, 'degrees');

            return angleInDegrees
        }
        else if(paramObj.varToCalc==='o'){//calc opposite
            // Convert angle to radians 
            const angleInRadians = (paramObj.angle * Math.PI) / 180;

            // Calculate the opposite side
            const opposite = paramObj.adjacent * Math.tan(angleInRadians);

            //console.log("Opposite:", opposite);
            return opposite
        }
        else if(paramObj.varToCalc==='a'){//calc adjacent
            //convert degrees to radians
            const angleInRadians = paramObj.angle * (Math.PI / 180);

            // Calculate the adjacent
            const adjacent = paramObj.opposite / Math.tan(angleInRadians);

            //console.log("adjacent:", adjacent);
            return adjacent
        }
        else{//return 0 if all false
            return 0
        }
    }
    else{//return 0 if all false
        return 0
    }

    }
    catch(e){
        return 0
    }

   
}

function datetimeToTimestamp(datetime) {

    try{
        //const datetimeString = "2023-12-24 08:45:21";

        // Parse the datetime string
        const parsedDatetime = new Date(datetime);

        // Get the timestamp in milliseconds
        const timestamp = parsedDatetime.getTime();

        // If you want the timestamp in seconds, divide by 1000
        // const timestampInSeconds = Math.floor(timestamp / 1000);

        return timestamp;
    }
    catch(err){
        return "error"
    }
}




function getNextOrPrevDatetimeByTF(
  datetime1, 
  tf, 
  nextORprevDatetime, 
  nextORprevDatetimeToGetCount, 
  prevDatetimeToGetPriceDataObjParam, 
  nextDatetimeToGetPriceDataObjParam, 

  datetime2="2080-01-01 00:00:00",

  isWeekendIncludedForMonthWeekDATETIME = true
){

    /*
    NOTICE:
    all datetime are refined to fit & match each given tf candlestick datetime structure 
    */
  

  try{

      let   datetime    = datetime1

      //console.log({datetime})
      datetime = refineAdjustPrevDatetimeStart({currentTF:tf, prevDatetimeStart:datetime})
      //console.log({datetime})

      //{...dbData[0], open: dbData[0].close, high:dbData[0].close, low:dbData[0].close},//prevDatetimeToGetPriceDataObj, 
      //{...dbData[dbData.length-1], open: dbData[dbData.length-1].close, high:dbData[dbData.length-1].close, low:dbData[dbData.length-1].close}//nextDatetimeToGetPriceDataObj

      const prevDatetimeToGetPriceDataObj = {...prevDatetimeToGetPriceDataObjParam, open: prevDatetimeToGetPriceDataObjParam.close, high:prevDatetimeToGetPriceDataObjParam.close, low:prevDatetimeToGetPriceDataObjParam.close}
      const nextDatetimeToGetPriceDataObj = {...nextDatetimeToGetPriceDataObjParam, open: nextDatetimeToGetPriceDataObjParam.close, high:nextDatetimeToGetPriceDataObjParam.close, low:nextDatetimeToGetPriceDataObjParam.close}

      const tfsMinsCountObj = {
          m1 : 1,
          m5 : 5,
          m15: 15,
          m30: 30,
          h1 : 60,
          h4 : 240,
          d1 : 1440,//if time or time should be of format or reversed into form - "00:00:00" for addition by given interval
          w1 : 10080,//add 7days to every monday to get the next monday datetime of every week
          //mn : 43200//will not use it as all month candles starts on 1st shifted to the next monday if the 1st falls on the weekend
      }

      /*const tfsPrevFridayCount = {
          m1 : "23:59:00",
          m5 : "23:55:00",
          m15: "23:45:00",
          m30: "23:30:00",
          h1 : "23:00:00",
          h4 : "20:00:00",
          d1 : "00:00:00",
          w1 : "00:00:00",
          mn : "00:00:00"
      }*/

      let candleDiffCount = 0

      const milliSecPerSec    = 1000
      const secPerMinute      = 60
      const milliSecPerMinute = secPerMinute * milliSecPerSec//60000

      const dailyMinsCount = tfsMinsCountObj["d1"]
      const dailyMilliSec  = milliSecPerMinute * dailyMinsCount//86400000
      const weeklyMinsCount  = tfsMinsCountObj["w1"]
      const weeklyMilliSec   = milliSecPerMinute * weeklyMinsCount//604800000

      const tfsMinsCount = tfsMinsCountObj[tf]
      const tfMilliSec   = milliSecPerMinute * tfsMinsCount

      let dataArray = []

      //if monthly prevNextDatetimeCount
      if(tf==="mn"){
          //console.log("datetime : ", datetime)

          /*
            NOTICE(important):
            - find out the datetime structure of both 'mn' & 'w1'
            - for any given Forex data Provider you take or extract data from
            - to see if all 'mn' starts from 1st or 2nd/3rd when 1st is either on saturday/sunday
            - & to also see if all 'w1' starts from sunday or monday 

            with ( both 'mn' & 'w1' happening to either be dated/timed on weekend/weekdays )
            yet -: on MT4 - FXCM datetime <=> diff. from trading datetime
            - 'mn' - starts from 1st for every month 
            - 'w1' - starts from sunday(monday-1) as the starting day of the week

            - the rest of the tfs(from 'd1' to 'm1') structure are normal ranging in the week days/hours/minutes from monday to fridays
          */

          let newDatetime = datetime

          for(let i = 0; i < nextORprevDatetimeToGetCount; i++){

              const date = newDatetime.split(" ")[0].split("-")

              let year = date[0]
              let month = date[1]
              let day = date[2]

              if(month === "12" && nextORprevDatetime==="next"){
                  month = "01"
                  year = String(Number(year)+1)
                  day = "01"
              }
              else if(month === "01" && nextORprevDatetime==="prev"){
                  month = "12"
                  year = String(Number(year)-1)
                  day = "01"
              }
              else if(month !== "12" && nextORprevDatetime==="next"){
                  month = String(Number(month)+1).length===1 ? "0" + String(Number(month)+1) : String(Number(month)+1)
                  day   = "01"
              }
              else if(month !== "01" && nextORprevDatetime==="prev"){
                  month = String(Number(month)-1).length===1 ? "0" + String(Number(month)-1) : String(Number(month)-1)
                  day   = "01"
              }

              newDatetime = `${year}-${month}-${day} 00:00:00`

              
              //check if newDatetime is weekend, then push directly to the datetime of monday
              const dayOfWeek = getDayOfWeek(newDatetime);

              if(
                  isWeekendIncludedForMonthWeekDATETIME===false &&
                  dayOfWeek==="Saturday" //+2
              ){
                  newDatetime = `${year}-${month}-${
                      String(Number(day)+2).length===1 ? "0" + String(Number(day)+2) : String(Number(day)+2)
                  } 00:00:00`

                  //console.log("newDatetime : ", newDatetime, " : ", dayOfWeek, " : ", getDayOfWeek(newDatetime))
                  
                  
                  if(datetimeToTimestamp(newDatetime) > datetimeToTimestamp(datetime2)){
                      break
                  }
                  else{
                      nextORprevDatetime==="next" ? dataArray.push(
                      nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newDatetime}
                      ) : dataArray.unshift(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newDatetime}
                      )
                  }
              }
              else if(
                  isWeekendIncludedForMonthWeekDATETIME===false &&
                  dayOfWeek==="Sunday" //+1
              ){
                  newDatetime = `${year}-${month}-${
                      String(Number(day)+1).length===1 ? "0" + String(Number(day)+1) : String(Number(day)+1)
                  } 00:00:00`

                  //console.log("newDatetime : ", newDatetime, " : ", dayOfWeek, " : ", getDayOfWeek(newDatetime))
                  
                  if(datetimeToTimestamp(newDatetime) > datetimeToTimestamp(datetime2)){
                      break
                  }
                  else{
                      nextORprevDatetime==="next" ? dataArray.push(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newDatetime}
                      ) : dataArray.unshift(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newDatetime}
                      )
                  }
              }
              else{
                  //console.log("newDatetime : ", newDatetime, " : ", dayOfWeek)
                  
                  if(datetimeToTimestamp(newDatetime) > datetimeToTimestamp(datetime2)){
                      break
                  }
                  else{
                      nextORprevDatetime==="next" ? dataArray.push(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newDatetime}
                      ) : dataArray.unshift(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newDatetime}
                      )
                  }
              }

              

          }


      }
      else if(tf==="w1"){

          /*
            NOTICE(important):
            - find out the datetime structure of both 'mn' & 'w1'
            - for any given Forex data Provider you take or extract data from
            - to see if all 'mn' starts from 1st or 2nd/3rd when 1st is either on saturday/sunday
            - & to also see if all 'w1' starts from sunday or monday 

            with ( both 'mn' & 'w1' happening to either be dated/timed on weekend/weekdays )
            yet -: on MT4 - FXCM datetime <=> diff. from trading datetime
            - 'mn' - starts from 1st for every month 
            - 'w1' - starts from sunday(monday-1) as the starting day of the week

            - the rest of the tfs(from 'd1' to 'm1') structure are normal ranging in the week days/hours/minutes from monday to fridays
          */

          /*console.log({
              datetime, 
              dayOfWeek: getDayOfWeek(datetime),
              nextORprevDatetimeToGetCount
          })*/

          let weekStaticDatetimeStart = datetime.split(" ")[0] + " " + "00:00:00"
          let weekDatetimeEnd         = weekStaticDatetimeStart
          //console.log("weekStaticDatetimeStart:", weekStaticDatetimeStart);
          
          for(let i = 0; i < nextORprevDatetimeToGetCount; i++){//2000=50years

              const weekCountIndex    = nextORprevDatetime==="next" ? (i+1) : ((i+1)*-1)
              const nextWeekTimeStamp = datetimeToTimestamp(weekStaticDatetimeStart) + (weeklyMilliSec * weekCountIndex)
              const nextWeekDatetime  = timestampToDatetime(nextWeekTimeStamp);
              //-----------------------------------------------------------------------
              //console.log(weekCountIndex)

              if(datetimeToTimestamp(nextWeekDatetime) > datetimeToTimestamp(datetime2)){
                  break
              }
              else{
                  nextORprevDatetime==="next" ? dataArray.push(
                      nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : nextWeekDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : nextWeekDatetime}
                  ) : dataArray.unshift(
                      nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : nextWeekDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : nextWeekDatetime}
                  )
              }
          }


          //newDatetimeEnd 
          //const newDatetimeEnd = refineAdjustPrevDatetimeStart({currentTF, prevDatetimeStart:datetimeEnd})

          //console.log({dataArray})
              
          /*console.log({
              datetimeStart:datetime, 
              datetimeEnd, 
              newDatetimeStart,
              newDatetimeEnd : weekDatetimeEnd,
              candleDiffCount
          }) */         

      }
      else{//if not - monthly prevNextDatetimeCount

          //console.log("datetime : ", datetime, " : ", getDayOfWeek(datetime))

          let timestamp = datetimeToTimestamp(datetime);
          //console.log("Timestamp:", timestamp);

          let weekendIndex = 0
          let isWeekend = false


          
          for(let i = 0; i < nextORprevDatetimeToGetCount; i++){

              //console.log("res : ", (tfMilliSec * (i+1)))
              const new_timestamp = (
                  nextORprevDatetime==="next" ?
                  timestamp + (tfMilliSec * (isWeekend ? (i-weekendIndex) : (i+1))) : 
                  timestamp - (tfMilliSec * (isWeekend ? (i-weekendIndex) : (i+1)))
              )

              let new_datetimeString = timestampToDatetime(new_timestamp);
              //console.log("Datetime:", new_datetimeString);

              const dayOfWeek = getDayOfWeek(new_datetimeString);
              //console.log(new_datetimeString, " : ", dayOfWeek);

              if(dayOfWeek==="Saturday" || dayOfWeek==="Sunday"){
                  weekendIndex = /*nextORprevDatetime==="next" ? i :*/ i
                  isWeekend = true
              }
              else{
                  //weekendIndex = 0
                  //isWeekend = false
              }


              if(
                  nextORprevDatetime==="next" && dayOfWeek==="Saturday" 
              ){
                  //if prev then normalize to saturday "date 00:00:00"
                  const normalizeDatetimeToDay = new_datetimeString.split(" ")[0] + " " + "00:00:00"
                  //console.log(normalizeDatetimeToDay)
                  const newWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) + (dailyMilliSec * 2) + (tfMilliSec * (i-weekendIndex))
                  const newWeekDatetime  = timestampToDatetime(newWeekTimeStamp);
                  //console.log(newWeekDatetime, " : ", getDayOfWeek(newWeekDatetime));

                  if(datetimeToTimestamp(newWeekDatetime) > datetimeToTimestamp(datetime2)){
                      break
                  }
                  else{
                      nextORprevDatetime==="next" ? dataArray.push(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newWeekDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newWeekDatetime}
                      ) : dataArray.unshift(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newWeekDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newWeekDatetime}
                      )
                  }

                  timestamp = newWeekTimeStamp
              }
              else if(
                  nextORprevDatetime==="prev" && dayOfWeek==="Saturday" 
              ){
                  //if prev then normalize to saturday "date 00:00:00"
                  const normalizeDatetimeToDay = new_datetimeString.split(" ")[0] + " " + "00:00:00"//saturday - "date 00:00:00"
                  //console.log(normalizeDatetimeToDay)
                  const newWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) - (tfMilliSec * (i-weekendIndex+1))
                  const newWeekDatetime  = timestampToDatetime(newWeekTimeStamp);
                  //console.log(newWeekDatetime, " : ", getDayOfWeek(newWeekDatetime));

                  if(datetimeToTimestamp(newWeekDatetime) > datetimeToTimestamp(datetime2)){
                      break
                  }
                  else{
                      nextORprevDatetime==="next" ? dataArray.push(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newWeekDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newWeekDatetime}
                      ) : dataArray.unshift(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newWeekDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newWeekDatetime}
                      )
                  }

                  timestamp = newWeekTimeStamp
              }
              else if(
                  nextORprevDatetime==="next" && dayOfWeek==="Sunday" 
              ){
                  //if prev then normalize to saturday "date 00:00:00"
                  const normalizeDatetimeToDay = new_datetimeString.split(" ")[0] + " " + "00:00:00"
                  //console.log(normalizeDatetimeToDay)
                  const newWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) + (dailyMilliSec * 1) + (tfMilliSec * (i-weekendIndex))
                  const newWeekDatetime  = timestampToDatetime(newWeekTimeStamp);
                  //console.log(newWeekDatetime, " : ", getDayOfWeek(newWeekDatetime));

                  if(datetimeToTimestamp(newWeekDatetime) > datetimeToTimestamp(datetime2)){
                      break
                  }
                  else{
                      nextORprevDatetime==="next" ? dataArray.push(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newWeekDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newWeekDatetime}
                      ) : dataArray.unshift(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newWeekDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newWeekDatetime}
                      )
                  }

                  timestamp = newWeekTimeStamp
              }
              else if(
                  nextORprevDatetime==="prev" && dayOfWeek==="Sunday" 
              ){
                  //if prev then normalize to saturday "date 00:00:00"
                  const normalizeDatetimeToDay = new_datetimeString.split(" ")[0] + " " + "00:00:00"//saturday - "date 00:00:00"
                  //console.log(normalizeDatetimeToDay)
                  const newWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) - (dailyMilliSec * 1) - (tfMilliSec * (i-weekendIndex+1))
                  const newWeekDatetime  = timestampToDatetime(newWeekTimeStamp);
                  //console.log(newWeekDatetime, " : ", getDayOfWeek(newWeekDatetime), "its - Sunday");

                  if(datetimeToTimestamp(newWeekDatetime) > datetimeToTimestamp(datetime2)){
                      break
                  }
                  else{
                      nextORprevDatetime==="next" ? dataArray.push(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newWeekDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newWeekDatetime}
                      ) : dataArray.unshift(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : newWeekDatetime} : {...prevDatetimeToGetPriceDataObj, datetime : newWeekDatetime}
                      )
                  }

                  timestamp = newWeekTimeStamp
              }
              else{
                  //console.log(new_datetimeString, " : ", dayOfWeek);

                  if(datetimeToTimestamp(new_datetimeString) > datetimeToTimestamp(datetime2)){
                      break
                  }
                  else{
                      nextORprevDatetime==="next" ? dataArray.push(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : new_datetimeString} : {...prevDatetimeToGetPriceDataObj, datetime : new_datetimeString}
                      ) : dataArray.unshift(
                          nextORprevDatetime==="next" ? {...nextDatetimeToGetPriceDataObj, datetime : new_datetimeString} : {...prevDatetimeToGetPriceDataObj, datetime : new_datetimeString}
                      )
                  }
              }

              


          }
      }

      
      return dataArray

  }
  catch(e){
      const candleDiffCount = 0
      //return [e]
      return []
  }

}






function getDayOfWeek(datetimeString) {
    try{
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const date = new Date(datetimeString);
        const dayOfWeek = daysOfWeek[date.getUTCDay()];
        return dayOfWeek;
    }
    catch(e){
        return "error"
    }
}




function timestampToDatetime(timestamp) {

    try{
        //const timestamp1 = 1703407521000;

        const date = new Date(timestamp);

        // Get the individual components of the date
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Create the formatted datetime string
        const formattedDatetime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return formattedDatetime;
    }
    catch(err){
        return "error"
    }
}



function calcLinePointerXYcord(obj){


    try{

    let pointerRightAngle45degreeDefaultXcordLeft  = obj.pointerXcordEnd
    let pointerRightAngle45degreeDefaultYcordLeft  = obj.pointerYcordPriceEnd
    let pointerRightAngle45degreeDefaultXcordRight = obj.pointerXcordEnd
    let pointerRightAngle45degreeDefaultYcordRight = obj.pointerYcordPriceEnd

    const defaultPointerAngle = 45

    //console.log({obj.angle, obj.pointerYcordPriceEnd, obj.nonPointerYcordPriceStart})

    
    if(//45-90
        obj.isDrawingForward &&
        (obj.pointerYcordPriceEnd <= obj.nonPointerYcordPriceStart) &&
        (obj.angle >= 45)
    ){
        let defaultLeftPointerXcord  = obj.pointerXcordEnd - obj.defaultPointerLength
        let defaultLeftPointerYcord  = obj.pointerYcordPriceEnd
        let defaultRightPointerXcord = obj.pointerXcordEnd
        let defaultRightPointerYcord = obj.pointerYcordPriceEnd + obj.defaultPointerLength

        const pointerRightAngleDefaultAngle     = Math.abs(90 - obj.angle - defaultPointerAngle)//(90 - angle > 45 ? 44 : 0)
        const leftRightPointerHypotenuseLength  = obj.defaultPointerLength
        const pointerOpposite = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})
        const pointerAdjacent = pythagorasCalculator({calcType : 'cos', varToCalc : 'a', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})

        pointerRightAngle45degreeDefaultXcordLeft  = obj.pointerXcordEnd - pointerAdjacent
        pointerRightAngle45degreeDefaultYcordLeft  = obj.pointerYcordPriceEnd + pointerOpposite
        pointerRightAngle45degreeDefaultXcordRight = obj.pointerXcordEnd + (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordLeft)*2.14)
        pointerRightAngle45degreeDefaultYcordRight = obj.pointerYcordPriceEnd + obj.defaultPointerLength - Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordLeft)
    }
    else if(//45-0
        obj.isDrawingForward &&
        (obj.pointerYcordPriceEnd <= obj.nonPointerYcordPriceStart) &&
        (obj.angle <= 45)
    ){
        let defaultLeftPointerXcord  = obj.pointerXcordEnd - obj.defaultPointerLength
        let defaultLeftPointerYcord  = obj.pointerYcordPriceEnd
        let defaultRightPointerXcord = obj.pointerXcordEnd
        let defaultRightPointerYcord = obj.pointerYcordPriceEnd + obj.defaultPointerLength

        const pointerRightAngleDefaultAngle     = Math.abs(90 - obj.angle - defaultPointerAngle)//(90 - angle > 45 ? 44 : 0)
        const leftRightPointerHypotenuseLength  = obj.defaultPointerLength
        const pointerOpposite = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})
        const pointerAdjacent = pythagorasCalculator({calcType : 'cos', varToCalc : 'a', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})

        pointerRightAngle45degreeDefaultXcordLeft  = obj.pointerXcordEnd - pointerAdjacent
        pointerRightAngle45degreeDefaultYcordLeft  = obj.pointerYcordPriceEnd - pointerOpposite
        pointerRightAngle45degreeDefaultXcordRight = obj.pointerXcordEnd - (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordLeft)*2.14)
        pointerRightAngle45degreeDefaultYcordRight = obj.pointerYcordPriceEnd + obj.defaultPointerLength - Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordLeft)
    }
    else if(//0-315
        obj.isDrawingForward &&
        (obj.pointerYcordPriceEnd >= obj.nonPointerYcordPriceStart) &&
        (obj.angle <= 45)
    ){
        let defaultLeftPointerXcord  = obj.pointerXcordEnd - obj.defaultPointerLength
        let defaultLeftPointerYcord  = obj.pointerYcordPriceEnd
        let defaultRightPointerXcord = obj.pointerXcordEnd
        let defaultRightPointerYcord = obj.pointerYcordPriceEnd - obj.defaultPointerLength

        const pointerRightAngleDefaultAngle     = Math.abs(90 - obj.angle - defaultPointerAngle)//(90 - angle > 45 ? 44 : 0)
        const leftRightPointerHypotenuseLength  = obj.defaultPointerLength
        const pointerOpposite = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})
        const pointerAdjacent = pythagorasCalculator({calcType : 'cos', varToCalc : 'a', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})

        pointerRightAngle45degreeDefaultXcordRight = obj.pointerXcordEnd - pointerAdjacent
        pointerRightAngle45degreeDefaultYcordRight = obj.pointerYcordPriceEnd + pointerOpposite
        pointerRightAngle45degreeDefaultXcordLeft  = obj.pointerXcordEnd - (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordRight)*2.14)
        pointerRightAngle45degreeDefaultYcordLeft  = obj.pointerYcordPriceEnd - obj.defaultPointerLength - (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordRight)*0.1)
        
    }
    else if(//315-270
        obj.isDrawingForward &&
        (obj.pointerYcordPriceEnd >= obj.nonPointerYcordPriceStart) &&
        (obj.angle >= 45)
    ){
        let defaultLeftPointerXcord  = obj.pointerXcordEnd - obj.defaultPointerLength
        let defaultLeftPointerYcord  = obj.pointerYcordPriceEnd
        let defaultRightPointerXcord = obj.pointerXcordEnd
        let defaultRightPointerYcord = obj.pointerYcordPriceEnd + obj.defaultPointerLength

        const pointerRightAngleDefaultAngle     = Math.abs(90 - obj.angle - defaultPointerAngle)//(90 - angle > 45 ? 44 : 0)
        const leftRightPointerHypotenuseLength  = obj.defaultPointerLength
        const pointerOpposite = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})
        const pointerAdjacent = pythagorasCalculator({calcType : 'cos', varToCalc : 'a', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})

        pointerRightAngle45degreeDefaultXcordRight = obj.pointerXcordEnd - pointerAdjacent
        pointerRightAngle45degreeDefaultYcordRight = obj.pointerYcordPriceEnd - pointerOpposite
        pointerRightAngle45degreeDefaultXcordLeft  = obj.pointerXcordEnd + (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordRight)*2.14)
        pointerRightAngle45degreeDefaultYcordLeft  = obj.pointerYcordPriceEnd - obj.defaultPointerLength - (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordRight)*0.1)
        
    }
    else if(//135-90
        obj.isDrawingForward===false &&
        (obj.pointerYcordPriceEnd <= obj.nonPointerYcordPriceStart) &&
        (obj.angle >= 45)//&&false
    ){
        let defaultLeftPointerXcord  = obj.pointerXcordEnd + obj.defaultPointerLength
        let defaultLeftPointerYcord  = obj.pointerYcordPriceEnd
        let defaultRightPointerXcord = obj.pointerXcordEnd
        let defaultRightPointerYcord = obj.pointerYcordPriceEnd - obj.defaultPointerLength

        const pointerRightAngleDefaultAngle     = Math.abs(90 - obj.angle - defaultPointerAngle)//(90 - angle > 45 ? 44 : 0)
        const leftRightPointerHypotenuseLength  = obj.defaultPointerLength
        const pointerOpposite = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})
        const pointerAdjacent = pythagorasCalculator({calcType : 'cos', varToCalc : 'a', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})

        pointerRightAngle45degreeDefaultXcordRight = obj.pointerXcordEnd + pointerAdjacent
        pointerRightAngle45degreeDefaultYcordRight = obj.pointerYcordPriceEnd + pointerOpposite
        pointerRightAngle45degreeDefaultXcordLeft  = obj.pointerXcordEnd - (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordRight)*2.14)
        pointerRightAngle45degreeDefaultYcordLeft  = obj.pointerYcordPriceEnd + obj.defaultPointerLength - Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordRight)
    }
    else if(//135-180
        obj.isDrawingForward===false &&
        (obj.pointerYcordPriceEnd <= obj.nonPointerYcordPriceStart) &&
        (obj.angle <= 45)
    ){
        let defaultLeftPointerXcord  = obj.pointerXcordEnd + obj.defaultPointerLength
        let defaultLeftPointerYcord  = obj.pointerYcordPriceEnd
        let defaultRightPointerXcord = obj.pointerXcordEnd
        let defaultRightPointerYcord = obj.pointerYcordPriceEnd - obj.defaultPointerLength

        const pointerRightAngleDefaultAngle     = Math.abs(90 - obj.angle - defaultPointerAngle)//(90 - angle > 45 ? 44 : 0)
        const leftRightPointerHypotenuseLength  = obj.defaultPointerLength
        const pointerOpposite = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})
        const pointerAdjacent = pythagorasCalculator({calcType : 'cos', varToCalc : 'a', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})

        pointerRightAngle45degreeDefaultXcordRight = obj.pointerXcordEnd + pointerAdjacent
        pointerRightAngle45degreeDefaultYcordRight = obj.pointerYcordPriceEnd - pointerOpposite
        pointerRightAngle45degreeDefaultXcordLeft  = obj.pointerXcordEnd + (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordRight)*2.14)
        pointerRightAngle45degreeDefaultYcordLeft  = obj.pointerYcordPriceEnd + obj.defaultPointerLength - Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordRight)
    }
    else if(//225-180
        obj.isDrawingForward===false &&
        (obj.pointerYcordPriceEnd >= obj.nonPointerYcordPriceStart) &&
        (obj.angle <= 45)
    ){
        let defaultLeftPointerXcord  = obj.pointerXcordEnd + obj.defaultPointerLength
        let defaultLeftPointerYcord  = obj.pointerYcordPriceEnd
        let defaultRightPointerXcord = obj.pointerXcordEnd
        let defaultRightPointerYcord = obj.pointerYcordPriceEnd - obj.defaultPointerLength

        const pointerRightAngleDefaultAngle     = Math.abs(90 - obj.angle - defaultPointerAngle)//(90 - angle > 45 ? 44 : 0)
        const leftRightPointerHypotenuseLength  = obj.defaultPointerLength
        const pointerOpposite = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})
        const pointerAdjacent = pythagorasCalculator({calcType : 'cos', varToCalc : 'a', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})

        pointerRightAngle45degreeDefaultXcordLeft  = obj.pointerXcordEnd + pointerAdjacent
        pointerRightAngle45degreeDefaultYcordLeft  = obj.pointerYcordPriceEnd + pointerOpposite
        pointerRightAngle45degreeDefaultXcordRight = obj.pointerXcordEnd + (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordLeft)*2.14)
        pointerRightAngle45degreeDefaultYcordRight = obj.pointerYcordPriceEnd - obj.defaultPointerLength - (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordLeft)*0.1)
        
    }
    else if(//225-270
        obj.isDrawingForward===false &&
        (obj.pointerYcordPriceEnd >= obj.nonPointerYcordPriceStart) &&
        (obj.angle >= 45)
    ){
        let defaultLeftPointerXcord  = obj.pointerXcordEnd + obj.defaultPointerLength
        let defaultLeftPointerYcord  = obj.pointerYcordPriceEnd
        let defaultRightPointerXcord = obj.pointerXcordEnd
        let defaultRightPointerYcord = obj.pointerYcordPriceEnd - obj.defaultPointerLength

        const pointerRightAngleDefaultAngle     = Math.abs(90 - obj.angle - defaultPointerAngle)//(90 - angle > 45 ? 44 : 0)
        const leftRightPointerHypotenuseLength  = obj.defaultPointerLength
        const pointerOpposite = pythagorasCalculator({calcType : 'sin', varToCalc : 'o', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})
        const pointerAdjacent = pythagorasCalculator({calcType : 'cos', varToCalc : 'a', hypotenuse:leftRightPointerHypotenuseLength, angle:pointerRightAngleDefaultAngle})

        pointerRightAngle45degreeDefaultXcordLeft  = obj.pointerXcordEnd + pointerAdjacent
        pointerRightAngle45degreeDefaultYcordLeft  = obj.pointerYcordPriceEnd - pointerOpposite
        pointerRightAngle45degreeDefaultXcordRight = obj.pointerXcordEnd - (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordLeft)*2.14)
        pointerRightAngle45degreeDefaultYcordRight = obj.pointerYcordPriceEnd - obj.defaultPointerLength - (Math.abs(defaultLeftPointerXcord-pointerRightAngle45degreeDefaultXcordLeft)*0.1)
        
    }

    /*console.log({
        //obj.isDrawingForward : drawingTypeObj.isDrawingForward
        //opposite,
        //adjacent,
        //angle,
        //hypotenuse,
        leftRightPointerHypotenuseLength,
        pointerRightAngleDefaultAngle,
        pointerAdjacent,
        pointerOpposite,
        pointerRightAngle45degreeDefaultXcordLeft,
        pointerRightAngle45degreeDefaultYcordLeft
        //newOpposite,
        //newAdjacent
    })*/

    return({
        pointerRightAngle45degreeDefaultXcordLeft,
        pointerRightAngle45degreeDefaultYcordLeft,
        pointerRightAngle45degreeDefaultXcordRight,
        pointerRightAngle45degreeDefaultYcordRight 
    })

    }
    catch(e){
        return {}
    }

}


function authDatetimeStrValidityBeforeDrawORedit(datetime){


    try{

    const datetimeStr = datetime
    const dayOfWeek = getDayOfWeek(datetimeStr);
    //console.log({datetimeStr})

    const isAdayOfWeek               = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].includes(dayOfWeek)
    const isDatetimeStrHasWhiteSpace = datetimeStr.includes(" ")
    const isDatetimeStrHasHyphen     = datetimeStr.includes("-")
    const isDatetimeStrHasColon      = datetimeStr.includes(":")

    const datetimeStrSplit = datetimeStr.split(" ")//[ '2024-01-03', '00:00:00' ]
    const date             = datetimeStrSplit[0]//'2024-01-03'
    const time             = datetimeStrSplit[1]//'00:00:00'
    const dateSplit        = date.split("-")//[ '2024', '01', '03' ]
    const timeSplit        = time.split(":")//[ '00', '00', '00' ]
    
    const year  = dateSplit[0]//'2024'
    const month = dateSplit[1]//'01'
    const day   = dateSplit[2]//'03'

    const hour   = timeSplit[0]//'00'
    const minute = timeSplit[1]//'00'
    const second = timeSplit[2]//'00'

    const isDateSplitLen3 = dateSplit.length === 3
    const isTimeSplitLen3 = timeSplit.length === 3

    const isYearLen4  = year.length  === 4//'1970'
    const isMonthLen2 = month.length === 2//'02'
    const isDayLen2   = day.length   === 2//'01'

    const isHourLen2   = hour.length   === 2//'00'
    const isMinuteLen2 = minute.length === 2//'00'
    const isSecondLen2 = second.length === 2//'00'

    const yearNumValue   = Number(year)//1970-9999
    const monthNumValue  = Number(month)//1-12
    const dayNumValue    = Number(day)//1-31

    const hourNumValue   = Number(hour)//0-23
    const minuteNumValue = Number(minute)//0-59
    const secondNumValue = Number(second)//0-59


    const isYearNumValueValid   = yearNumValue  >= 1900//1900-9999
    const isMonthNumValueValid  = monthNumValue >= 1 && monthNumValue <= 12//1-12
    const isDayNumValueValid    = dayNumValue   >= 1 && dayNumValue   <= 31//1-31

    const isHourNumValueValid   = hourNumValue   >= 0 && hourNumValue   <= 23//0-23
    const isMinuteNumValueValid = minuteNumValue >= 0 && minuteNumValue <= 59//0-59
    const isSecondNumValueValid = secondNumValue >= 0 && secondNumValue <= 59//0-59

    const isAllAuthTruthy = (//12 Auths
        isAdayOfWeek &&
        isDatetimeStrHasWhiteSpace &&
        isDatetimeStrHasHyphen &&
        isDatetimeStrHasColon  &&

        isDateSplitLen3 &&
        isTimeSplitLen3 &&

        isYearLen4  &&
        isMonthLen2 &&
        isDayLen2   &&

        isHourLen2   &&
        isMinuteLen2 &&
        isSecondLen2 &&

        isYearNumValueValid   &&
        isMonthNumValueValid  &&
        isDayNumValueValid    &&

        isHourNumValueValid   &&
        isMinuteNumValueValid &&
        isSecondNumValueValid 
    )


    return(isAllAuthTruthy)

    /*return {
        isAdayOfWeek,
        isDatetimeStrHasWhiteSpace,
        isDatetimeStrHasHyphen,
        isDatetimeStrHasColon,

        isYearNumValueValid,isMonthNumValueValid,isDayNumValueValid,

        isHourNumValueValid,isMinuteNumValueValid,isSecondNumValueValid,

        datetimeStrSplit,
        date,
        time,
        dateSplit,
        timeSplit,
        isDateSplitLen3,
        isTimeSplitLen3,

        year,month,day,

        hour,minute,second,

        isAllAuthTruthy
    }
    return true*/
    }
    catch(e) {
        return false
    }
}



function getDatetimeDiffCount(obj, isWeekendIncludedForMonthWeekDATETIME = true){

  /*
  NOTICE:
  all datetime & count are calculated to fit each given tf candlestick datetime structure & trendline drawing steps count
  */

  const tfsMinsCountObj = {
      m1 : 1,
      m5 : 5,
      m15: 15,
      m30: 30,
      h1 : 60,
      h4 : 240,
      d1 : 1440,//if time or time should be of format or reversed into form - "00:00:00" for addition by given interval
      w1 : 10080,//add 7days to every monday to get the next monday datetime of every week
      //mn : 43200//will not use it as all month candles starts on 1st shifted to the next monday if the 1st falls on the weekend
  }

  const milliSecPerSec    = 1000
  const secPerMinute      = 60
  const milliSecPerMinute = secPerMinute * milliSecPerSec//60000

  const dailyMinsCount   = tfsMinsCountObj["d1"]
  const dailyMilliSec    = milliSecPerMinute * dailyMinsCount//86400000
  const weeklyMinsCount  = tfsMinsCountObj["w1"]
  const weeklyMilliSec   = milliSecPerMinute * weeklyMinsCount//604800000

  try{

      const starttime = obj.datetimeStart
      const endtime   = obj.datetimeEnd
      const tf        = obj.tf
      //const tfEditor  = obj.tfEditor
      //console.log(obj)

      const tfsMinsCount = tfsMinsCountObj[tf]
      const tfMilliSec   = milliSecPerMinute * tfsMinsCount

      let   datetimeStart = datetimeToTimestamp(starttime) <= datetimeToTimestamp(endtime) ? starttime : endtime//datetimeStart
      const datetimeEnd   = datetimeToTimestamp(endtime)   >= datetimeToTimestamp(starttime) ? endtime : starttime//datetimeEnd
      
      //const datetimeTimestampMilliSecDiff = Math.abs(datetimeToTimestamp(datetimeEnd) - datetimeToTimestamp(datetimeStart))

      //const dateStartDayOfWeek = getDayOfWeek(datetimeStart)
      //const dateEndDayOfWeek   = getDayOfWeek(datetimeEnd)
      //const tfCount = datetimeTimestampMilliSecDiff / tfMilliSec //strictly >=1


      //-------------------------------------------------------------------------------
      const currentTF = tf
      //const prevTF    = tfEditor//"m1"//tfEditor
      //const prevDatetimeStart = "2024-12-11 09:47:00"//datetimeStart

      //console.log({datetimeStart})
      const newDatetimeStart = refineAdjustPrevDatetimeStart({currentTF, prevDatetimeStart:datetimeStart})
      datetimeStart = newDatetimeStart !== 'error' ? newDatetimeStart : 'error'


      if(datetimeStart!=='error'){

          //console.log({newDatetimeStart})
          datetimeStart = newDatetimeStart//.datetime


          //------------------------------------------------------------------------------------------------
          let candleDiffCount = 0
          if(currentTF==="mn"){

              /*
              NOTICE(important):
              - find out the datetime structure of both 'mn' & 'w1'
              - for any given Forex data Provider you take or extract data from
              - to see if all 'mn' starts from 1st or 2nd/3rd when 1st is either on saturday/sunday
              - & to also see if all 'w1' starts from sunday or monday 

              with ( both 'mn' & 'w1' happening to either be dated/timed on weekend/weekdays )
              yet -: on MT4 - FXCM datetime <=> diff. from trading datetime
              - 'mn' - starts from 1st for every month 
              - 'w1' - starts from sunday(monday-1) as the starting day of the week

              - the rest of the tfs(from 'd1' to 'm1') structure are normal ranging in the week days/hours/minutes from monday to fridays
              */

              //console.log("datetime : ", datetime)

              let newMonthDatetime = datetimeStart
              let monthDatetimeEnd = newMonthDatetime

              for(let i = 0; i < 500; i++){//500=40years

                  const date = newMonthDatetime.split(" ")[0].split("-")

                  let year  = date[0]
                  let month = date[1]
                  let day   = date[2]

                  if(month === "12"){
                      month = "01"
                      year = String(Number(year)+1)
                      day = "01"
                  }
                  else if(month !== "12"){
                      month = String(Number(month)+1).length===1 ? "0" + String(Number(month)+1) : String(Number(month)+1)
                      day   = "01"
                  }

                  newMonthDatetime = `${year}-${month}-${day} 00:00:00`

                  
                  //check if newMonthDatetime is weekend, then push directly to the datetime of monday
                  const dayOfWeek = getDayOfWeek(newMonthDatetime);

                  if(
                      isWeekendIncludedForMonthWeekDATETIME===false &&
                      dayOfWeek==="Saturday" //+2
                  ){
                      newMonthDatetime = `${year}-${month}-${
                          String(Number(day)+2).length===1 ? "0" + String(Number(day)+2) : String(Number(day)+2)
                      } 00:00:00`

                      //console.log("newMonthDatetime : ", newMonthDatetime, " : ", dayOfWeek, " : ", getDayOfWeek(newMonthDatetime))
                      
                      if(datetimeToTimestamp(newMonthDatetime) <= datetimeToTimestamp(datetimeEnd)){
                          candleDiffCount = candleDiffCount + 1
                          monthDatetimeEnd = newMonthDatetime
                      }
                      else if(datetimeToTimestamp(newMonthDatetime) > datetimeToTimestamp(datetimeEnd)){break}
                  }
                  else if(
                      isWeekendIncludedForMonthWeekDATETIME===false &&
                      dayOfWeek==="Sunday" //+1
                  ){
                      newMonthDatetime = `${year}-${month}-${
                          String(Number(day)+1).length===1 ? "0" + String(Number(day)+1) : String(Number(day)+1)
                      } 00:00:00`

                      //console.log("newMonthDatetime : ", newMonthDatetime, " : ", dayOfWeek, " : ", getDayOfWeek(newMonthDatetime))
                      
                      if(datetimeToTimestamp(newMonthDatetime) <= datetimeToTimestamp(datetimeEnd)){
                          candleDiffCount = candleDiffCount + 1
                          monthDatetimeEnd = newMonthDatetime
                      }
                      else if(datetimeToTimestamp(newMonthDatetime) > datetimeToTimestamp(datetimeEnd)){break}
                  }
                  else{
                      //console.log("newMonthDatetime : ", newMonthDatetime, " : ", dayOfWeek)
                      
                      if(datetimeToTimestamp(newMonthDatetime) <= datetimeToTimestamp(datetimeEnd)){
                          candleDiffCount = candleDiffCount + 1
                          monthDatetimeEnd = newMonthDatetime
                      }
                      else if(datetimeToTimestamp(newMonthDatetime) > datetimeToTimestamp(datetimeEnd)){break}
                  }

              }

              /*console.log({
                  datetimeStart, 
                  datetimeEnd, 
                  newDatetimeStart,
                  newDatetimeEnd : monthDatetimeEnd,
                  candleDiffCount
              })*/

              return({
                  datetimeStart : newDatetimeStart,
                  datetimeEnd   : monthDatetimeEnd,
                  count         : candleDiffCount
              })

          }
          else if(currentTF==='w1'){//datetimeStart

             /*
              NOTICE(important):
              - find out the datetime structure of both 'mn' & 'w1'
              - for any given Forex data Provider you take or extract data from
              - to see if all 'mn' starts from 1st or 2nd/3rd when 1st is either on saturday/sunday
              - & to also see if all 'w1' starts from sunday or monday 

              with ( both 'mn' & 'w1' happening to either be dated/timed on weekend/weekdays )
              yet -: on MT4 - FXCM datetime <=> diff. from trading datetime
              - 'mn' - starts from 1st for every month 
              - 'w1' - starts from sunday(monday-1) as the starting day of the week

              - the rest of the tfs(from 'd1' to 'm1') structure are normal ranging in the week days/hours/minutes from monday to fridays
              */

              //console.log("datetimeStart : ", datetimeStart, " : ", getDayOfWeek(datetimeStart))

              let weekStaticDatetimeStart = datetimeStart.split(" ")[0] + " " + "00:00:00"
              let weekDatetimeEnd         = weekStaticDatetimeStart
              //console.log("weekStaticDatetimeStart:", weekStaticDatetimeStart);
              
              for(let i = 0; i < 2000; i++){//2000=50years

                  const weekCountIndex    = (i+1) 
                  const nextWeekTimeStamp = datetimeToTimestamp(weekStaticDatetimeStart) + (weeklyMilliSec * weekCountIndex)
                  const nextWeekDatetime  = timestampToDatetime(nextWeekTimeStamp);
                  //-----------------------------------------------------------------------

                  
                  if(datetimeToTimestamp(nextWeekDatetime) <= datetimeToTimestamp(datetimeEnd)){
                      candleDiffCount = candleDiffCount + 1
                      weekDatetimeEnd = nextWeekDatetime
                  }
                  else if(datetimeToTimestamp(nextWeekDatetime) > datetimeToTimestamp(datetimeEnd)){break}
                  
                  /*console.log({
                      nextWeekDatetime,
                      dayOfWeek : getDayOfWeek(nextWeekDatetime)
                  })*/
              }


              //newDatetimeEnd 
              //const newDatetimeEnd = refineAdjustPrevDatetimeStart({currentTF, prevDatetimeStart:datetimeEnd})
                  
              /*console.log({
                  datetimeStart, 
                  datetimeEnd, 
                  newDatetimeStart,
                  newDatetimeEnd : weekDatetimeEnd,
                  candleDiffCount
              })*/

              return({
                  datetimeStart : weekStaticDatetimeStart,//newDatetimeStart,
                  datetimeEnd   : weekDatetimeEnd,
                  count         : candleDiffCount
              })
              

          }
          else if(
              ['d1', 'h4', 'h1', 'm30', 'm15', 'm5', 'm1'].includes(currentTF)
          ){//counted by the denominator of 'd1' millisec

              /*
              NOTICE(important):
              - find out the datetime structure of both 'mn' & 'w1'
              - for any given Forex data Provider you take or extract data from
              - to see if all 'mn' starts from 1st or 2nd/3rd when 1st is either on saturday/sunday
              - & to also see if all 'w1' starts from sunday or monday 

              with ( both 'mn' & 'w1' happening to either be dated/timed on weekend/weekdays )
              yet -: on MT4 - FXCM datetime <=> diff. from trading datetime
              - 'mn' - starts from 1st for every month 
              - 'w1' - starts from sunday(monday-1) as the starting day of the week

              - the rest of the tfs(from 'd1' to 'm1') structure are normal ranging in the week days/hours/minutes from monday to fridays
              */


              //if prev then normalize to saturday "date 00:00:00"
              const normalizeDatetimeToDay = datetimeStart.split(" ")[0] + " " + "00:00:00"
              //console.log(normalizeDatetimeToDay)
              const nextDayTimeStamp     = datetimeToTimestamp(normalizeDatetimeToDay) + dailyMilliSec 
              const nextDayDatetimeStart = timestampToDatetime(nextDayTimeStamp);

              //let isDatetimeEndWithinORequalEndOfSameDay = false

              if(//datetimeEnd : within or equal to the end of the same day
                  datetimeToTimestamp(datetimeEnd) <= datetimeToTimestamp(nextDayDatetimeStart)  
              ){
                  const timestartTimestampDiff = Math.abs(datetimeToTimestamp(datetimeEnd) - datetimeToTimestamp(datetimeStart))
                  const timestartTimestampDiffTFcount = timestartTimestampDiff / (tfsMinsCountObj[currentTF] * milliSecPerMinute)
                  
                  //newDatetimeEnd 
                  const newDatetimeEnd = refineAdjustPrevDatetimeStart({currentTF, prevDatetimeStart:datetimeEnd})
                  
                  /*console.log({
                      res : 'less than next day',
                      datetimeStart, 
                      datetimeEnd, 
                      newDatetimeStart,
                      newDatetimeEnd,
                      nextDayDatetimeStart,
                      timestartTimestampDiff,
                      timestartTimestampDiffTFcount
                  })*/

                  return({
                      datetimeStart : newDatetimeStart,
                      datetimeEnd   : newDatetimeEnd,
                      count         : timestartTimestampDiffTFcount
                  })

                  //isDatetimeEndWithinORequalEndOfSameDay = true
              }
              else{//datetimeEnd : beyond the end of a given day
                  const timestartTimestampDiff = Math.abs(datetimeToTimestamp(nextDayDatetimeStart) - datetimeToTimestamp(datetimeStart))
                  //const timestartTimestampDiffTFcount = timestartTimestampDiff / (tfsMinsCountObj[currentTF] * milliSecPerMinute)
                  //don't divide the diff by the tf millisec until: we get all the (startDiff + midDiff + endDiff) 

                  //continue to add daily millisec to find our first friday, if the current datetimeStart is not friday
                  //if addition of daily millisec does not reach friday or > datetimeEnd, 
                  //then get the last day addition result datetime & find diff btn its timeStamp & that of the datetimeEnd
                  //to get timeendTimestampDiffTFcount
                  //round division result to get a whole number, as to be able to select chart drawing Ends by mid crossHair
                  //console.log({timestartTimestampDiff: timestartTimestampDiff / (tfsMinsCountObj[currentTF] * milliSecPerMinute)})

                  const countDatetimeStart  = nextDayDatetimeStart
                  const countStartDayOfWeek = getDayOfWeek(countDatetimeStart)

                  let loopDatetimeEndPrev  = countDatetimeStart
                  let loopDatetimeEndCurr  = countDatetimeStart
                  let loopMillisecCount    = timestartTimestampDiff//0
                  let weekdaysCount        = 0

                  //let isLastCountFridayJump      = false
                  let isDatetimeAdditionExceeded = false
                  let isDatetimeAdditionEqualDatetimeEnd = false


                  for(let i = 0; i < 2600; i++){//2600===56years or m1 count data

                      if(datetimeToTimestamp(loopDatetimeEndCurr) === datetimeToTimestamp(datetimeEnd)){
                          isDatetimeAdditionEqualDatetimeEnd = true
                          //console.log('datetimeEnd equals prevAdd')
                          break
                      }
                      else if(datetimeToTimestamp(loopDatetimeEndCurr) > datetimeToTimestamp(datetimeEnd)){
                          isDatetimeAdditionExceeded = true
                          //console.log('datetimeEnd exceeded')
                          break
                      }
                      else{

                          if(//true
                             getDayOfWeek(loopDatetimeEndCurr)==='Friday'
                          ){//add weekly-wise
                              //const weeklyMilliSec   = milliSecPerMinute * weeklyMinsCount//604800000

                              //-----------------------------------------------------------------------
                              //if prev then normalize to saturday "date 00:00:00"
                              const normalizeDatetimeToDay = loopDatetimeEndCurr.split(" ")[0] + " " + "00:00:00"
                              //console.log(normalizeDatetimeToDay)
                              const nextWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) + weeklyMilliSec// (weeklyMilliSec + dailyMilliSec) 
                              const nextWeekDatetime  = timestampToDatetime(nextWeekTimeStamp);
                              //-----------------------------------------------------------------------

                              //-----------------------------------------------------------------------
                              //Auth the timeStamp of nextDayDatetime does not exceed the datetimeEnd, before adding week count, else don't or pass
                              //per every weekend jump subtract or track & subtract later those 2 weekdays
                              if(datetimeToTimestamp(nextWeekDatetime) <= datetimeToTimestamp(datetimeEnd)){
                                  loopMillisecCount += weeklyMilliSec
                                  weekdaysCount     += 2
                              }
                              //------------------------------------------------------------------------

                              loopDatetimeEndPrev = loopDatetimeEndCurr
                              loopDatetimeEndCurr = nextWeekDatetime
                              //isLastCountFridayJump = true

                              //console.log('is Friday', nextWeekDatetime, getDayOfWeek(nextWeekDatetime))

                              /*console.log({
                                  isFriday:true,
                                  dayOfWeek : getDayOfWeek(nextWeekDatetime),
                                  nextWeekDatetime
                              })*/

                              //break
                          }
                          else if(//true
                             getDayOfWeek(loopDatetimeEndCurr)==="Saturday"
                          ){//add weekly-wise
                              //const weeklyMilliSec   = milliSecPerMinute * weeklyMinsCount//604800000
                              

                              //-----------------------------------------------------------------------
                              //if prev then normalize to saturday "date 00:00:00"
                              const normalizeDatetimeToDay = loopDatetimeEndCurr.split(" ")[0] + " " + "00:00:00"
                              //console.log(normalizeDatetimeToDay)
                              const nextWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) + (weeklyMilliSec - dailyMilliSec)// (weeklyMilliSec + dailyMilliSec) 
                              const nextWeekDatetime  = timestampToDatetime(nextWeekTimeStamp);
                              //-----------------------------------------------------------------------

                              //-----------------------------------------------------------------------
                              //Auth the timeStamp of nextDayDatetime does not exceed the datetimeEnd, before adding week count, else don't or pass
                              //per every weekend jump subtract or track & subtract later those 2 weekdays
                              if(datetimeToTimestamp(nextWeekDatetime) <= datetimeToTimestamp(datetimeEnd)){
                                  loopMillisecCount += (weeklyMilliSec - dailyMilliSec)
                                  weekdaysCount     += 2
                              }
                              //------------------------------------------------------------------------

                              loopDatetimeEndPrev = loopDatetimeEndCurr
                              loopDatetimeEndCurr = nextWeekDatetime
                              //isLastCountFridayJump = true

                              //console.log('is Saturday', nextWeekDatetime, getDayOfWeek(nextWeekDatetime))

                              /*console.log({
                                  isFriday:true,
                                  dayOfWeek : getDayOfWeek(nextWeekDatetime),
                                  nextWeekDatetime
                              })*/

                              //break
                          }
                          else if(//true
                             getDayOfWeek(loopDatetimeEndCurr)==="Sunday"
                          ){//add weekly-wise
                              //const weeklyMilliSec   = milliSecPerMinute * weeklyMinsCount//604800000

                              //-----------------------------------------------------------------------
                              //if prev then normalize to saturday "date 00:00:00"
                              const normalizeDatetimeToDay = loopDatetimeEndCurr.split(" ")[0] + " " + "00:00:00"
                              //console.log(normalizeDatetimeToDay)
                              const nextWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) + (weeklyMilliSec - (dailyMilliSec*2))// (weeklyMilliSec + dailyMilliSec) 
                              const nextWeekDatetime  = timestampToDatetime(nextWeekTimeStamp);
                              //-----------------------------------------------------------------------

                              //-----------------------------------------------------------------------
                              //Auth the timeStamp of nextDayDatetime does not exceed the datetimeEnd, before adding week count, else don't or pass
                              //per every weekend jump subtract or track & subtract later those 2 weekdays
                              if(datetimeToTimestamp(nextWeekDatetime) <= datetimeToTimestamp(datetimeEnd)){
                                  loopMillisecCount += (weeklyMilliSec - (dailyMilliSec*2))
                                  weekdaysCount     += 2
                              }
                              //------------------------------------------------------------------------

                              loopDatetimeEndPrev = loopDatetimeEndCurr
                              loopDatetimeEndCurr = nextWeekDatetime

                              //console.log('is Sunday', nextWeekDatetime, getDayOfWeek(nextWeekDatetime))
                              //isLastCountFridayJump = true

                              /*console.log({
                                  isFriday:true,
                                  dayOfWeek : getDayOfWeek(nextWeekDatetime),
                                  nextWeekDatetime
                              })*/

                              //break
                          }
                          else{//add daily-wise
                              //const dailyMilliSec    = milliSecPerMinute * dailyMinsCount//86400000

                              //-----------------------------------------------------------------------
                              //if prev then normalize to saturday "date 00:00:00"
                              const normalizeDatetimeToDay = loopDatetimeEndCurr.split(" ")[0] + " " + "00:00:00"
                              //console.log(normalizeDatetimeToDay)
                              const nextDayTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) + (dailyMilliSec) 
                              const nextDayDatetime  = timestampToDatetime(nextDayTimeStamp);
                              //-----------------------------------------------------------------------

                              //-----------------------------------------------------------------------
                              //Auth the timeStamp of nextDayDatetime does not exceed the datetimeEnd, before adding day count, else don't or pass
                              if(datetimeToTimestamp(nextDayDatetime) <= datetimeToTimestamp(datetimeEnd)){
                                  loopMillisecCount += dailyMilliSec
                              }
                              //------------------------------------------------------------------------

                              loopDatetimeEndPrev   = loopDatetimeEndCurr
                              loopDatetimeEndCurr   = nextDayDatetime
                              //isLastCountFridayJump = false

                              /*console.log({
                                  isFriday:false,
                                  dayOfWeek : getDayOfWeek(nextDayDatetime)
                              })*/
                          }

                          //break
                      }
                  }
                  //console.log({loopMillisecCount})

                  
                  if(isDatetimeAdditionEqualDatetimeEnd){//done && summarize all adons & divide & return as total count

                      //gather all start-mid-end counts & divide by tf millisec
                      loopMillisecCount                 = loopMillisecCount - (weekdaysCount*dailyMilliSec)
                      let timestartTimestampDiffTFcount = loopMillisecCount / (tfsMinsCountObj[currentTF] * milliSecPerMinute)
                      timestartTimestampDiffTFcount     = Math.round(timestartTimestampDiffTFcount)

                      //newDatetimeEnd 
                      const newDatetimeEnd = refineAdjustPrevDatetimeStart({currentTF, prevDatetimeStart:datetimeEnd})


                      /*console.log({
                          datetimeStart,
                          datetimeEnd,
                          newDatetimeStart,
                          newDatetimeEnd,
                          datetimeLastCountButOne : loopDatetimeEndPrev,
                          datetimeLastCount       : loopDatetimeEndCurr,
                          datetimeEnd,
                          weekdaysCount,
                          loopMillisecCount,
                          timestartTimestampDiffTFcount,
                      })*/


                      return({
                          datetimeStart : newDatetimeStart,
                          datetimeEnd   : newDatetimeEnd,
                          count         : timestartTimestampDiffTFcount
                      })
                  }
                  else if(
                      isDatetimeAdditionExceeded
                  ){//do final adons in same week or over weekend but less than next end
                      //per every weekend jump subtract or track & subtract later those 2 weekdays
                      //console.log('datetime exceeded')

                      //later subtract looped weekdays
                      const timeendTimestampDiff = Math.abs(datetimeToTimestamp(datetimeEnd) - datetimeToTimestamp(loopDatetimeEndPrev))
                      
                      //Loop to get the total no. of weekdays without exceeding the datetimeEnd 
                      //break loop when nextday exceed the datetimeEnd
                      for(let i = 0; i < 31; i++){//31===4weeks

                          //-----------------------------------------------------------------------
                          //if prev then normalize to saturday "date 00:00:00"
                          const normalizeDatetimeToDay = loopDatetimeEndPrev.split(" ")[0] + " " + "00:00:00"
                          //console.log(normalizeDatetimeToDay)
                          const nextDayTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) + (dailyMilliSec * (i/*+1*/)) 
                          const nextDayDatetime  = timestampToDatetime(nextDayTimeStamp);
                          const dayOfWeek = getDayOfWeek(nextDayDatetime)
                          //-----------------------------------------------------------------------

                          if(datetimeToTimestamp(nextDayDatetime) > datetimeToTimestamp(datetimeEnd)){
                              //console.log({i})
                              break
                          }
                          else if(
                              dayOfWeek==='Sunday' ||
                              dayOfWeek==='Saturday'
                          ){
                              weekdaysCount += 1
                          }
                      }

                      //gather all start-mid-end counts & divide by tf millisec
                      loopMillisecCount = loopMillisecCount + (timeendTimestampDiff - (weekdaysCount*dailyMilliSec))
                      let timestartTimestampDiffTFcount = loopMillisecCount / (tfsMinsCountObj[currentTF] * milliSecPerMinute)
                      timestartTimestampDiffTFcount = Math.floor(timestartTimestampDiffTFcount)

                      //newDatetimeEnd 
                      const newDatetimeEnd = refineAdjustPrevDatetimeStart({currentTF, prevDatetimeStart:datetimeEnd})
                      

                      /*console.log({
                          datetimeStart,
                          datetimeEnd,
                          newDatetimeStart,
                          newDatetimeEnd,
                          datetimeLastCountButOne : loopDatetimeEndPrev,
                          datetimeLastCount       : loopDatetimeEndCurr,
                          datetimeEnd,
                          timeendTimestampDiff,
                          weekdaysCount,
                          loopMillisecCount,
                          timestartTimestampDiffTFcount,
                      })*/

                      return({
                          datetimeStart : newDatetimeStart,
                          datetimeEnd   : newDatetimeEnd,
                          count         : timestartTimestampDiffTFcount
                      })
  
                  }


                  
              }

              

          }

      }



      /*return {
          datetimeStart,
          datetimeEnd,

          datetimeTimestampMilliSecDiff,
          tfCount,

          dateStartDayOfWeek,
          dateEndDayOfWeek,

          dailyMilliSec,
          weeklyMilliSec
      }*/
  }
  catch(e){
      //console.log(e)
      return "error"//or 0
  }
}






function refineAdjustPrevDatetimeStart(obj, isWeekendIncludedForMonthWeekDATETIME = true){
  /*
  NOTICE:
  all datetime are refined to fit each given tf candlestick datetime structure 
  */


  try{

      const tfsMinsCountObj = {
          m1 : 1,
          m5 : 5,
          m15: 15,
          m30: 30,
          h1 : 60,
          h4 : 240,
          d1 : 1440,//if time or time should be of format or reversed into form - "00:00:00" for addition by given interval
          w1 : 10080,//add 7days to every monday to get the next monday datetime of every week
          mn : 43200//will not use it as all month candles starts on 1st shifted to the next monday if the 1st falls on the weekend
      }

      const milliSecPerSec    = 1000
      const secPerMinute      = 60
      const milliSecPerMinute = secPerMinute * milliSecPerSec//60000

      const dailyMinsCount = tfsMinsCountObj["d1"]
      const dailyMilliSec  = milliSecPerMinute * dailyMinsCount


      const currentTF               = obj.currentTF
      //const prevTF                  = obj.prevTF
      let   staticPrevDatetimeStart = obj.prevDatetimeStart
      let   prevDatetimeStart       = obj.prevDatetimeStart

      //const isCurrentTFgreaterThanPrevTF = tfsMinsCountObj[currentTF] > tfsMinsCountObj[prevTF]//expansion= ltf - htf
      //const isCurrentTFequalToPrevTF     = tfsMinsCountObj[currentTF] === tfsMinsCountObj[prevTF]//same tf = no-expansion-effect
      //const isHTFexpansion               = isCurrentTFgreaterThanPrevTF

      let   dayOfWeek = getDayOfWeek(prevDatetimeStart)

      //--------------------------------------------------------------------------
      //checkout datetime from weekends if so
      if(
          (dayOfWeek==="Saturday" ||
          dayOfWeek==="Sunday")
      ){
          const objRes = getToNextMondayDatetimeAwayFromWeekend({
              datetime : prevDatetimeStart, 
              //nextMondayORprevFridayDatetime : isHTFexpansion ? "prev" : "next"
          })

          prevDatetimeStart = objRes.datetime
          dayOfWeek         = objRes.dayOfWeek
      }
      //----------------------------------------------------------------------------


      //----------------------------------------------------------------------------
      //normalize sec
      const datetimeSplitArray1 = prevDatetimeStart.split(" ")
      const date1               = datetimeSplitArray1[0]
      const time1               = datetimeSplitArray1[1]
      const timeSplit1          = time1.split(":")
      const hour1               = timeSplit1[0]
      const minute1             = timeSplit1[1]
      const second1             = timeSplit1[2]

      if(second1 !== "00"){
          //console.log('sec normed : ', prevDatetimeStart)
          const normalizedSecond = "00"
          prevDatetimeStart = `${date1}` + " " + `${hour1}:${minute1}:${normalizedSecond}`;
          dayOfWeek         = getDayOfWeek(prevDatetimeStart)
          //console.log('sec normed : ', prevDatetimeStart)
      }
      //---------------------------------------------------------------------------




      if(
          true
          //isCurrentTFgreaterThanPrevTF//ltf-hft
      ){//if isCurrentTFgreaterThanPrevTF then make some refinement or adjustments to htf datetime to appear&fit as ltf datetime


          if(currentTF==="mn"){
              /*
              NOTICE(important):
              - find out the datetime structure of both 'mn' & 'w1'
              - for any given Forex data Provider you take or extract data from
              - to see if all 'mn' starts from 1st or 2nd/3rd when 1st is either on saturday/sunday
              - & to also see if all 'w1' starts from sunday or monday 

              with ( both 'mn' & 'w1' happening to either be dated/timed on weekend/weekdays )
              yet -: on MT4 - FXCM datetime <=> diff. from trading datetime
              - 'mn' - starts from 1st for every month 
              - 'w1' - starts from sunday(monday-1) as the starting day of the week

              - the rest of the tfs(from 'd1' to 'm1') structure are normal ranging in the week days/hours/minutes from monday to fridays
              */
              

              const datetimeSplitArray = staticPrevDatetimeStart.split(" ")//'2024-01-11 09:45:00'
              const date               = datetimeSplitArray[0]       //'2024-01-11'
              const time               = datetimeSplitArray[1]       //'09:45:00'
              const dateSplit          = date.split("-")             //'2024-01-11'
              const year               = dateSplit[0]                //'2024'
              const month              = dateSplit[1]                //'01'


              const adjustedMonthDatetime = `${year}-${month}-${'01'} 00:00:00`//'2024-01-01 00:00:00'
              const MonthDayOfWeek        = getDayOfWeek(adjustedMonthDatetime)
              //console.log(adjustedMonthDatetime)

              if(
                  isWeekendIncludedForMonthWeekDATETIME===false &&
                  (MonthDayOfWeek==="Saturday" || MonthDayOfWeek==="Sunday")
              ){
                  //---------------------------------------------------------------------
                  const objResOfNextMondayDatetimeOfMonth = getToNextMondayDatetimeAwayFromWeekend({
                      datetime : adjustedMonthDatetime, 
                      //nextMondayORprevFridayDatetime : "next"//next monday
                  })
      
                  const datetime = objResOfNextMondayDatetimeOfMonth.datetime
                  //dayOfWeek         = objResOfNextMondayDatetimeOfMonth.dayOfWeek

                  //return {tf:currentTF, datetime}
                  return datetime
                  //--------------------------------------------------------------------*/
              }
              else{
                  //return {tf:currentTF, datetime : adjustedMonthDatetime}
                  return adjustedMonthDatetime
              }

          }
          else if(currentTF==="w1"){//ltf-hft

              /*
              NOTICE(important):
              - find out the datetime structure of both 'mn' & 'w1'
              - for any given Forex data Provider you take or extract data from
              - to see if all 'mn' starts from 1st or 2nd/3rd when 1st is either on saturday/sunday
              - & to also see if all 'w1' starts from sunday or monday 

              with ( both 'mn' & 'w1' happening to either be dated/timed on weekend/weekdays )
              yet -: on MT4 - FXCM datetime <=> diff. from trading datetime
              - 'mn' - starts from 1st for every month 
              - 'w1' - starts from sunday(monday-1) as the starting day of the week

              - the rest of the tfs(from 'd1' to 'm1') structure are normal ranging in the week days/hours/minutes from monday to fridays
              */


              const weekdayBackToSundayORmondayCountObj = isWeekendIncludedForMonthWeekDATETIME ? {
                  Monday    : 1, 
                  Tuesday   : 2, 
                  Wednesday : 3, 
                  Thursday  : 4, 
                  Friday    : 5, 
                  Saturday  : 6, 
                  Sunday    : 0
              } : {
                  Monday    : 0, 
                  Tuesday   : 1, 
                  Wednesday : 2, 
                  Thursday  : 3, 
                  Friday    : 4, 
                  Saturday  : 5, 
                  Sunday    : 6
              }
  
              const dayOfWeekCountBack = weekdayBackToSundayORmondayCountObj[dayOfWeek]
              
              //if prev then normalize to saturday "date 00:00:00"
              const normalizeDatetimeToDay = prevDatetimeStart.split(" ")[0] + " " + "00:00:00"
              //console.log(normalizeDatetimeToDay)
              const newWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) - (dailyMilliSec * dayOfWeekCountBack) 
              const newWeekDatetime  = timestampToDatetime(newWeekTimeStamp);
              //console.log(newWeekDatetime, " : ", getDayOfWeek(newWeekDatetime));
  
              //console.log({dayOfWeek, dayOfWeekCountBack, newWeekDatetime, dayOfWeekNew : getDayOfWeek(newWeekDatetime)})
  
              //return {tf:currentTF, datetime : newWeekDatetime}
              return newWeekDatetime
          }
          else if(currentTF==="d1"){//ltf-hft
              const normalizeDatetimeToDay = prevDatetimeStart.split(" ")[0] + " " + "00:00:00"
              //console.log({currentTF, prevDatetimeStart})
              //return {tf:currentTF, datetime : normalizeDatetimeToDay}
              return normalizeDatetimeToDay
          }
          else if(currentTF==="h4"){//ltf-hft

              const h4_moduloArray = ["00", "04", "08", "12", "16", "20"]
              
              const datetimeSplitArray = prevDatetimeStart.split(" ")
              const date               = datetimeSplitArray[0]
              const time               = datetimeSplitArray[1]
              const timeSplit          = time.split(":")
              const hour               = timeSplit[0]
              const minute             = timeSplit[1]

              const newHour = getNextHigherTime({time : hour, timeArray : h4_moduloArray})

              const normalizeDatetime = date + " " + newHour + ":00:00"
              
              //console.log({currentTF, prevDatetimeStart})
              //return {tf:currentTF, datetime : normalizeDatetime}
              return normalizeDatetime
          }
          else if(currentTF==="h1"){//ltf-hft
              
              const h1_moduloArray = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", 
              "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
              
              const datetimeSplitArray = prevDatetimeStart.split(" ")
              const date               = datetimeSplitArray[0]
              const time               = datetimeSplitArray[1]
              const timeSplit          = time.split(":")
              const hour               = timeSplit[0]
              const minute             = timeSplit[1]

              const newHour = getNextHigherTime({time : hour, timeArray : h1_moduloArray})

              const normalizeDatetime = date + " " + newHour + ":00:00"
              
              //console.log({currentTF, prevDatetimeStart})
              //return {tf:currentTF, datetime : normalizeDatetime}
              return normalizeDatetime
          }
          else if(currentTF==="m30"){//ltf-hft
              
              const m30_moduloArray = ["00", "30"]
              
              const datetimeSplitArray = prevDatetimeStart.split(" ")
              const date               = datetimeSplitArray[0]
              const time               = datetimeSplitArray[1]
              const timeSplit          = time.split(":")
              const hour               = timeSplit[0]
              const minute             = timeSplit[1]

              const newMinute = getNextHigherTime({time : minute, timeArray : m30_moduloArray})

              const normalizeDatetime = date + " " + hour + ":" + newMinute + ":00"
              
              //console.log({currentTF, prevDatetimeStart})
              //return {tf:currentTF, datetime : normalizeDatetime}
              return normalizeDatetime
          }
          else if(currentTF==="m15"){//ltf-hft
              
              const m15_moduloArray = ["00", "15", "30", "45"]
              
              const datetimeSplitArray = prevDatetimeStart.split(" ")
              const date               = datetimeSplitArray[0]
              const time               = datetimeSplitArray[1]
              const timeSplit          = time.split(":")
              const hour               = timeSplit[0]
              const minute             = timeSplit[1]

              const newMinute = getNextHigherTime({time : minute, timeArray : m15_moduloArray})

              const normalizeDatetime = date + " " + hour + ":" + newMinute + ":00"
              
              //console.log({currentTF, prevDatetimeStart})
              //return {tf:currentTF, datetime : normalizeDatetime}
              return normalizeDatetime
          }
          else if(currentTF==="m5"){//ltf-hft
              
              const m5_moduloArray = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"]
              
              const datetimeSplitArray = prevDatetimeStart.split(" ")
              const date               = datetimeSplitArray[0]
              const time               = datetimeSplitArray[1]
              const timeSplit          = time.split(":")
              const hour               = timeSplit[0]
              const minute             = timeSplit[1]

              const newMinute = getNextHigherTime({time : minute, timeArray : m5_moduloArray})

              const normalizeDatetime = date + " " + hour + ":" + newMinute + ":00"
              
              //console.log({currentTF, prevDatetimeStart})
              //return {tf:currentTF, datetime : normalizeDatetime}
              return normalizeDatetime
          }
          else if(currentTF==="m1"){//ltf-hft  --  feasible to all
              
              //console.log({currentTF, prevDatetimeStart})
              //return {tf:currentTF, datetime : prevDatetimeStart}
              return prevDatetimeStart
          }


          
      }



  }
  catch(e){
      //console.log(e)
      return 'error'
  }
}





function getNextHigherTime(obj){


    try{
        const time          = obj.time
        const timeArray     = obj.timeArray
        const fistIndexTime = timeArray[0]
        const lastIndexTime = timeArray[timeArray.length - 1]

        let newTime = ""


        if(time===fistIndexTime){//auth 1st index
            newTime = fistIndexTime
        }
        else if(
            Number(time)>=Number(lastIndexTime)
        ){//auth last index & beyond
            newTime = lastIndexTime
        }
        else{//auth all mid indexes
            for(let i = 0; i < timeArray.length-1; i++){

                const startTime = Number(timeArray[i])
                const endTime   = Number(timeArray[i+1])
                const midTime   = Number(time)

                if(
                    ((midTime > startTime) && (midTime < endTime))
                    || (midTime === startTime)
                ){

                    newTime = timeArray[i]

                    break
                }


            }
        }


        return newTime
    }
    catch(e){
        return "error"
    }

}




function getToNextMondayDatetimeAwayFromWeekend(obj){

    const tfsMinsCountObj = {
        m1 : 1,
        m5 : 5,
        m15: 15,
        m30: 30,
        h1 : 60,
        h4 : 240,
        d1 : 1440,//if time or time should be of format or reversed into form - "00:00:00" for addition by given interval
        w1 : 10080,//add 7days to every monday to get the next monday datetime of every week
        //mn : 43200//will not use it as all month candles starts on 1st shifted to the next monday if the 1st falls on the weekend
    }

    try{

        const milliSecPerSec    = 1000
        const secPerMinute      = 60
        const milliSecPerMinute = secPerMinute * milliSecPerSec//60000

        const dailyMinsCount = tfsMinsCountObj["d1"]
        const dailyMilliSec  = milliSecPerMinute * dailyMinsCount

        
        const datetime                       = obj.datetime
        //const nextMondayORprevFridayDatetime = obj.nextMondayORprevFridayDatetime
        const dayOfWeek                      = getDayOfWeek(datetime)
        //console.log({dayOfWeek})



        if(
            dayOfWeek!=="Saturday" &&
            dayOfWeek!=="Sunday"
        ){
            //console.log('1')
            return {datetime, dayOfWeek : getDayOfWeek(datetime)}
        }
        else if(
            /*nextMondayORprevFridayDatetime==="next" && */dayOfWeek==="Saturday" 
        ){
            //if prev then normalize to saturday "date 00:00:00"
            const normalizeDatetimeToDay = datetime.split(" ")[0] + " " + "00:00:00"
            //console.log(normalizeDatetimeToDay)
            const newWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) + (dailyMilliSec * 2) //+ (tfMilliSec * (i-weekendIndex))
            const newWeekDatetime  = timestampToDatetime(newWeekTimeStamp);
            //console.log(newWeekDatetime, " : ", getDayOfWeek(newWeekDatetime));

            //console.log('sat-next')
            return {datetime : newWeekDatetime, dayOfWeek : getDayOfWeek(newWeekDatetime)}
        }
        /*else if(
            nextMondayORprevFridayDatetime==="prev" && dayOfWeek==="Saturday" 
        ){
            //if prev then normalize to saturday "date 00:00:00"
            const normalizeDatetimeToDay = datetime.split(" ")[0] + " " + "00:00:00"//saturday - "date 00:00:00"
            //console.log(normalizeDatetimeToDay)
            const newWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) - milliSecPerMinute//- (tfMilliSec * (i-weekendIndex+1))
            const newWeekDatetime  = timestampToDatetime(newWeekTimeStamp);
            //console.log(newWeekDatetime, " : ", getDayOfWeek(newWeekDatetime));

            //console.log('sat-prev')
            return {datetime : newWeekDatetime, dayOfWeek : getDayOfWeek(newWeekDatetime)}
        }*/
        else if(
            /*nextMondayORprevFridayDatetime==="next" && */dayOfWeek==="Sunday" 
        ){
            //if prev then normalize to saturday "date 00:00:00"
            const normalizeDatetimeToDay = datetime.split(" ")[0] + " " + "00:00:00"
            //console.log(normalizeDatetimeToDay)
            const newWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) + (dailyMilliSec * 1) //+ (tfMilliSec * (i-weekendIndex))
            const newWeekDatetime  = timestampToDatetime(newWeekTimeStamp);
            //console.log(newWeekDatetime, " : ", getDayOfWeek(newWeekDatetime));

            //console.log('sun-next')
            return {datetime : newWeekDatetime, dayOfWeek : getDayOfWeek(newWeekDatetime)}
        }
        /*else if(
            nextMondayORprevFridayDatetime==="prev" && dayOfWeek==="Sunday" 
        ){
            //if prev then normalize to saturday "date 00:00:00"
            const normalizeDatetimeToDay = datetime.split(" ")[0] + " " + "00:00:00"//saturday - "date 00:00:00"
            //console.log(normalizeDatetimeToDay)
            const newWeekTimeStamp = datetimeToTimestamp(normalizeDatetimeToDay) - (dailyMilliSec * 1) - milliSecPerMinute//- (tfMilliSec * (i-weekendIndex+1))
            const newWeekDatetime  = timestampToDatetime(newWeekTimeStamp);
            //console.log(newWeekDatetime, " : ", getDayOfWeek(newWeekDatetime), "its - Sunday");

            //console.log('sun-prev')
            return {datetime : newWeekDatetime, dayOfWeek : getDayOfWeek(newWeekDatetime)}
        }*/
        

    }
    catch(e){
        //console.log(e)
        return 'error'
    }

}







function calcRSI_all(data, period){


    try{

        //const period = 14


        /*const data = [
            // forex data provided
            //...forexDATAarray()
            ...(finalDBdataObj['currs_h1'].data)
        ];*/

        
        
        /*
        [
        {
            "datetime": "2023-11-02 06:20:00",
            "EUR": [60.555341933568165,60.5757943297036,60.442904296669525,60.513186720814765,13],
            "GBP": [-193.1889030472101,-193.1800506384007,-193.31426300934712,-193.23952518825791,0],
            "AUD": [-6.323424353775735,-6.2086247142449436,-6.385146132140607,-6.355740408737798,18],
            "NZD": [101.0927835996365,101.20123759358509,101.03532371220028,101.07191385540642,30],
            "USD": [8.389081925339745,8.413833373758278,8.290286933267911,8.390347641927578,38],
            "CAD": [43.971002549629965,44.004847618370455,43.90899470358006,43.965005205708856,35],
            "CHF": [421.97153249699153,422.0031506406322,421.8832321151646,421.93542271567713,29],
            "JPY": [-436.46741510418013,-436.1608749853851,-436.5106458374137,-436.2806105425391,100]
        },
        ]
        */
        
        let result = [];

        //let gains  = [];
        //let losses = [];

        const gains = {
            EUR: [],
            GBP: [],
            AUD: [],
            NZD: [],
            USD: [],
            CAD: [],
            CHF: [],
            JPY: []
        }

        const losses = {
            EUR: [],
            GBP: [],
            AUD: [],
            NZD: [],
            USD: [],
            CAD: [],
            CHF: [],
            JPY: []
        }

        const currs = ["EUR", "GBP","AUD", "NZD", "USD", "CAD", "CHF", "JPY"]

        

        for (let i = 1; i < data.length; i++) {

            const datetime = data[i].datetime
            const currsResObj = {datetime}

            for(let curr of currs){

                //let change = data[i].close - data[i - 1].close;
                //let change = data[i][curr] - data[i - 1][curr];
                const change = data[i][curr][3] - data[i - 1][curr][3];//[OHLC%]=[01234]

                if(change > 0){//gain(> 0)
                    gains[curr].push(change);
                    losses[curr].push(0);
                }
                else{//loss(<= 0)
                    gains[curr].push(0);
                    losses[curr].push(-change);
                }


                if(i >= period){//later check divergence

                    // .reducer gets the addiditive sum of an array starting summation from , 0
                    // if looking for sum(array) func use array.reducer()
                    //[1, 2, 3, 4, 5].reduce((sumTracker, val) => sumTracker + val, 0) = 15
                    //[1, 2, 3, 4, 5].reduce((sumTracker, val) => sumTracker + val, 2) = 2+15 = 17
                    let avgGain = gains[curr].slice(i - period, i).reduce((sumTracker, val) => sumTracker + val, 0) / period; 
                    let avgLoss = losses[curr].slice(i - period, i).reduce((sumTracker, val) => sumTracker + val, 0) / period;
                    let RS = avgGain / avgLoss;
                    let RSI = 100 - (100 / (1 + RS));
                    //result.push(RSI);
                    currsResObj[curr] = [RSI, RSI/*data[i][curr]*/]
                } 
                else{
                    //result.push(null); // Not enough data for RSI calculation
                }

            }

            if(i >= period){
                result.push(currsResObj);
            }


        }

        
        //const period = 14;
        //const rsiValues = calculateRSI(data, period);
        //console.log(rsiValues);
        //console.log(result[0]);
        return(result)


        //store DBobj in json
        //const json_wrte_res2 = write_json_file('./RSIcalcResArray.json', result)
        //console.log('isSuccess : ', json_wrte_res2)

        
    }
    catch(e){
        return({error:'error'})
        //console.log('error')
    }

}







function CORRcalcFUNC(dataArray, currPairs, periodNUM, corrInstruName){

    try{

        /*
        const corrOBJdata = [
            {
                AUD :  2.7014207947325097,
                CAD :  29.855996420618933,
                CHF :  432.82072009797844,
                EUR :  68.2067809793888,
                GBP :  -173.87236894390645,
                JPY :  -449.5724103494694,
                NZD :  108.66923395971332,
                USD :  -18.8093729590562,
                datetime :  "2024-08-20 13:00:00"
            }
        ]
        */

        /* endRES :
        [
            {
                datetime: '2024-08-19 08:20:00',

                EUR: [0,0], GBP: [0,0], AUD: [0,0], NZD: [0,0], USD: [0,0], CAD: [0,0], CHF: [0,0], JPY: [0,0],//for testing purpose & delete after use

                EURGBP: [ 3.0832571338200268, 0.9383348573235993 ],
                EURAUD: [ 8.201362231574578, 0.8359727553685087 ],
                EURNZD: [ 28.69780608916568, 0.4260438782166865 ],
                EURUSD: [ 1.245809735346029, 0.9750838052930795 ],
                EURCAD: [ 2.6380419569551634, 0.9472391608608969 ],
                EURCHF: [ 16.672146080612606, 0.6665570783877478 ],
                EURJPY: [ 97.58551014717469, -0.9517102029434936 ],
                GBPAUD: [ 5.785001852357169, 0.8842999629528566 ],
                GBPNZD: [ 28.41677414673883, 0.43166451706522335 ],
            },
        ]
        */


        const FULLdataARRAY          = dataArray
        const currPairArray = currPairs
        const period        = periodNUM

        let resARRAY = []

        for(let i=0; i<(FULLdataARRAY.length + 1) - periodNUM; i++){

            const dataSLICE = FULLdataARRAY.slice(i, i + period)
            //console.log(dataSLICE, dataSLICE.length)

            /*const calcCORRresultObj = calcCORR({
            data : dataSLICE,
            currPairArray
            })

            resARRAY.push(calcCORRresultObj)*/
            let datetime = dataSLICE[dataSLICE.length - 1].datetime
            datetime = datetime===undefined ? '' : datetime


            const currs = ['EUR', 'GBP', 'AUD', 'NZD', 'USD', 'CAD', 'CHF', 'JPY']

            let PARSEDcorrDataARRAY = []

            for (let i = 0; i < dataSLICE.length; i++) {
                
                const newObj = {}
                for(let curr of currs){
                    /*const open = dataSLICE[i][curr][0]
                    const high = dataSLICE[i][curr][1]
                    const low  = dataSLICE[i][curr][2]*/
                    const close = dataSLICE[i][curr][3]
                    //const currPerf = (close - open) / open
                    newObj[curr] = close
                }
                PARSEDcorrDataARRAY.push(newObj)
            }

            //console.log(PARSEDcorrDataARRAY)



            //------------------------------------------------------f.e------------------------------------------------------------
            
            const data = PARSEDcorrDataARRAY
            //console.log(data)

            const currTotalPERFobjCount = {
                EUR: 0, GBP: 0, AUD: 0, NZD: 0, USD: 0, CAD: 0, CHF: 0, JPY: 0
            } 

            const currCorrDataArrayObj = {
                EUR: [0], GBP: [0], AUD: [0], NZD: [0], USD: [0], CAD: [0], CHF: [0], JPY: [0]//[0] - good for operation to avoid NAN res
            } 

            for(let i=0; i<data.length; i++){
                const currPerfObj = data[i]
                //console.log({currPerfObj})
                /*
                currPerfObj : {
                    datetime: '2023-10-06 18:00:00',
                    EUR: 56.3728225936063,
                    GBP: -192.5066510642918,
                    AUD: -14.227336618548087,
                    NZD: 111.16757569401048,
                    USD: 4.623402495816192,
                    CAD: 49.925743770483614,
                    CHF: 414.4255726399931,
                    JPY: -429.7811295110698 
                }
                */

                if(i>0){
                    for(let curr in currTotalPERFobjCount){
                        //console.log(curr)

                        const dataTYPE = typeof(data[i][curr])//'object'

                        const currentPerf = dataTYPE === 'object' ? data[i][curr][0]   : data[i][curr]
                        const prevPerf    = dataTYPE === 'object' ? data[i-1][curr][0] : data[i-1][curr]

                        const perfDIFF = currentPerf - prevPerf

                        const calcRES = currTotalPERFobjCount[curr] + perfDIFF

                        currTotalPERFobjCount[curr] = calcRES

                        //currCorrDataArrayObj[curr].push(currentPerf)//perfDIFF
                        currCorrDataArrayObj[curr].push(calcRES)

                        //break
                    }

                    //console.log({currTotalPERFobjCount})

                    //break
                }



                
            }

            //console.log({currTotalPERFobjCount, len:data.length})
            /*
            {
            currTotalPERFobjCount: {
                EUR: -6.46324423252284,
                GBP: 18.53159273555977,
                AUD: 22.273911551915404,
                NZD: 24.468080118412516,
                USD: -25.931054672556186,
                CAD: -23.194961223546414,
                CHF: -23.440758994044202,
                JPY: 3.3689681970434293
            },
            len: 485
            }
            */// calc csm of currs & store in obj

            //console.log({currCorrDataArrayObj})
            /*
            {
            currCorrDataArrayObj: {
                EUR: [ 64.83118738093157 ],
                GBP: [ 65.36439638354484 ],
                AUD: [ 54.28615460085835 ],
                NZD: [ 68.57889068373203 ],
                USD: [ 41.534858875755205 ],
                CAD: [ 57.36655681847387 ],
                CHF: [ 71.34846942830522 ],
                JPY: [ 19.020690864842024 ]
            }
            }
            *///is all length loop of curr truthy - with loop of currpairs calc corr & store in obj


            //---------------------------------------corr-------------------------------------------
            //corr
            const baseCURR = 'USD'
            const baseCURRlength = currCorrDataArrayObj[baseCURR].length
            let truthyARRAY = []
            
            for(let curr in currTotalPERFobjCount){
                truthyARRAY.push(currCorrDataArrayObj[curr].length===baseCURRlength)
            }

            //console.log({baseCURRlength, truthyARRAY})

            const testingDATA = {/*EUR: [0,0], GBP: [0,0], AUD: [0,0], NZD: [0,0], USD: [0,0], CAD: [0,0], CHF: [0,0], JPY: [0,0]*/}//DELETE AFTER TEST
            const corrRESobj = {datetime, ...testingDATA}

            if(
                truthyARRAY.includes(false)===false
            ){
                for(let currpair of currPairArray){
                    const base  = currpair.slice(0,3)
                    const quote = currpair.slice(3)

                    const baseARRAY  = currCorrDataArrayObj[base]
                    const quoteARRAY = currCorrDataArrayObj[quote]

                    const corrRES = calculateCorrelation(baseARRAY, quoteARRAY)

                    //console.log({currpair, base, quote, baseARRAY, quoteARRAY, corrRES})

                    //corrRESobj[currpair] = corrRES//-0.6446227541574069

                    let final_corrRES = corrRES

                    if(true){
                        final_corrRES = corrRES + 1//nullify all neg to 0 (0 - 2)
                        final_corrRES = (100 * final_corrRES) / 2
                    }

                    //corrRESobj[currpair] = [100 - final_corrRES, corrRES]//corrRES
                    corrRESobj[currpair] = [100 - final_corrRES, 100 - final_corrRES]//corrRES

                    //break
                }
            }
            //--------------------------------------------------------------------------

            //console.log({corrRESobj})
            /*
            {
                datetime: '2024-08-19 08:20:00',

                EUR: [0,0], GBP: [0,0], AUD: [0,0], NZD: [0,0], USD: [0,0], CAD: [0,0], CHF: [0,0], JPY: [0,0],//for testing purpose & delete after use

                EURGBP: [ 3.0832571338200268, 0.9383348573235993 ],
                EURAUD: [ 8.201362231574578, 0.8359727553685087 ],
                EURNZD: [ 28.69780608916568, 0.4260438782166865 ],
                EURUSD: [ 1.245809735346029, 0.9750838052930795 ],
                EURCAD: [ 2.6380419569551634, 0.9472391608608969 ],
                EURCHF: [ 16.672146080612606, 0.6665570783877478 ],
                EURJPY: [ 97.58551014717469, -0.9517102029434936 ],
                GBPAUD: [ 5.785001852357169, 0.8842999629528566 ],
                GBPNZD: [ 28.41677414673883, 0.43166451706522335 ],
            }
            */
            //------------------------------------------------------------------------------------------------------------------
            


            //corrInstruName = 'USD'
            //getCURRpairCURRS(corrRESobj, corrInstruName)



            //push individual corr length calced into resARRAY
            resARRAY.push(getCURRpairCURRS(corrRESobj, corrInstruName))

            //break

        }




        //console.log(resARRAY)
        /*
        [
            {
                datetime: '2024-08-19 08:20:00',

                EUR: [0,0], GBP: [0,0], AUD: [0,0], NZD: [0,0], USD: [0,0], CAD: [0,0], CHF: [0,0], JPY: [0,0],//for testing purpose & delete after use

                EURGBP: [ 3.0832571338200268, 0.9383348573235993 ],
                EURAUD: [ 8.201362231574578, 0.8359727553685087 ],
                EURNZD: [ 28.69780608916568, 0.4260438782166865 ],
                EURUSD: [ 1.245809735346029, 0.9750838052930795 ],
                EURCAD: [ 2.6380419569551634, 0.9472391608608969 ],
                EURCHF: [ 16.672146080612606, 0.6665570783877478 ],
                EURJPY: [ 97.58551014717469, -0.9517102029434936 ],
                GBPAUD: [ 5.785001852357169, 0.8842999629528566 ],
                GBPNZD: [ 28.41677414673883, 0.43166451706522335 ],
            },
        ]
        */

        return(resARRAY)

    }
    catch(e){
        //console.log({e})
        return []
    }


}





function getCURRpairCURRS(dataObj, corrInstruName){

  
    try{
  
      const resObj = {datetime : dataObj.datetime}
      resObj[corrInstruName] = [0 - 50, -1 - 1]
  
      for(let currPAIR in dataObj){
        //console.log(currPAIR)//EURUSD
        if(currPAIR.includes(corrInstruName)){
          //console.log(currPAIR, currPAIR.indexOf(corrInstruName))
          const base  = currPAIR.slice(0,3)
          const quote = currPAIR.slice(3)
  
          if(base !== corrInstruName){
            resObj[base] = dataObj[currPAIR]
          }
          else if(quote  !== corrInstruName){
            resObj[quote] = dataObj[currPAIR]
          }
        }
      }
  
      /*
      {
        datetime: '2024-08-19 08:20:00',
        USD: [ -50, -2 ],
        EUR: [ 1.245809735346029, 0.9750838052930795 ],
        GBP: [ 1.7897877378028966, 0.964204245243942 ],
        AUD: [ 6.2594665708896, 0.8748106685822079 ],
        NZD: [ 27.636551374802536, 0.4472689725039492 ],
        CAD: [ 1.0027612827598915, 0.9799447743448022 ],
        CHF: [ 22.15954070484426, 0.5568091859031148 ],
        JPY: [ 98.32458842236032, -0.9664917684472065 ]
      }
      */
  
      return resObj
    }
    catch(e){
      return {datetime : '', EUR: [0,0], GBP: [0,0], AUD: [0,0], NZD: [0,0], USD: [0,0], CAD: [0,0], CHF: [0,0], JPY: [0,0]};
    }
  
  
  }
  






function calcCORR(obj){


    try{

        const data = obj.data
        const currPairArray = obj.currPairArray
       //console.log(data)

        const currTotalPERFobjCount = {
            EUR: 0, GBP: 0, AUD: 0, NZD: 0, USD: 0, CAD: 0, CHF: 0, JPY: 0
        } 

        const currCorrDataArrayObj = {
            EUR: [0], GBP: [0], AUD: [0], NZD: [0], USD: [0], CAD: [0], CHF: [0], JPY: [0]//[0] - good for operation to avoid NAN res
        } 

        for(let i=0; i<data.length; i++){
            const currPerfObj = data[i]
            //console.log({currPerfObj})
            /*
            currPerfObj : {
                datetime: '2023-10-06 18:00:00',
                EUR: [ 64.83118738093157, 56.3728225936063 ],
                GBP: [ 65.36439638354484, -192.5066510642918 ],
                AUD: [ 54.28615460085835, -14.227336618548087 ],
                NZD: [ 68.57889068373203, 111.16757569401048 ],
                USD: [ 41.534858875755205, 4.623402495816192 ],
                CAD: [ 57.36655681847387, 49.925743770483614 ],
                CHF: [ 71.34846942830522, 414.4255726399931 ],
                JPY: [ 19.020690864842024, -429.7811295110698 ]
            }
            */

            if(i>0){
                for(let curr in currTotalPERFobjCount){
                    //console.log(curr)

                    const dataTYPE = typeof(data[i][curr])//'object'

                    const currentPerf = dataTYPE === 'object' ? data[i][curr][0]   : data[i][curr]
                    const prevPerf    = dataTYPE === 'object' ? data[i-1][curr][0] : data[i-1][curr]

                    const perfDIFF = currentPerf - prevPerf

                    const calcRES = currTotalPERFobjCount[curr] + perfDIFF

                    currTotalPERFobjCount[curr] = calcRES

                    //currCorrDataArrayObj[curr].push(currentPerf)//perfDIFF
                    currCorrDataArrayObj[curr].push(calcRES)

                    //break
                }

                //console.log({currTotalPERFobjCount})

                //break
            }



            
        }

        //console.log({currTotalPERFobjCount, len:data.length})
        /*
        {
        currTotalPERFobjCount: {
            EUR: -6.46324423252284,
            GBP: 18.53159273555977,
            AUD: 22.273911551915404,
            NZD: 24.468080118412516,
            USD: -25.931054672556186,
            CAD: -23.194961223546414,
            CHF: -23.440758994044202,
            JPY: 3.3689681970434293
        },
        len: 485
        }
        */// calc csm of currs & store in obj

        //console.log({currCorrDataArrayObj})
        /*
        {
        currCorrDataArrayObj: {
            EUR: [ 64.83118738093157 ],
            GBP: [ 65.36439638354484 ],
            AUD: [ 54.28615460085835 ],
            NZD: [ 68.57889068373203 ],
            USD: [ 41.534858875755205 ],
            CAD: [ 57.36655681847387 ],
            CHF: [ 71.34846942830522 ],
            JPY: [ 19.020690864842024 ]
        }
        }
        *///is all length loop of curr truthy - with loop of currpairs calc corr & store in obj


        //----------------------------------------------------------------------------------
        //rsm
        const currPERFscaled = calcCURRperf(currTotalPERFobjCount)
        /*
        {
            EUR: { scaled: 20, unscaled: -11.098854981508005 },
            GBP: { scaled: 84, unscaled: 36.685781742706034 },
            AUD: { scaled: 63, unscaled: 21.101034014810054 },
            NZD: { scaled: 100, unscaled: 48.31884247428135 },
            USD: { scaled: 15, unscaled: -14.776797912956809 },
            CAD: { scaled: 17, unscaled: -13.619712574528585 },
            CHF: { scaled: 41, unscaled: 4.862377314156795 },
            JPY: { scaled: 0, unscaled: -25.9394921125592 }
        }
        */

        let csmRESarray = []
        for(let key in currPERFscaled){
            const obj = {}
            obj['name']   = key
            obj['scaled']  = currPERFscaled[key].scaled
            obj['unscaled'] = currPERFscaled[key].unscaled
            csmRESarray.push(obj)
        }
        let csmRESarraySORTED = bubbleSort(csmRESarray)

        let newArray = []
        csmRESarraySORTED.forEach((el, elInd)=>{
            newArray.push(csmRESarraySORTED[(csmRESarraySORTED.length-1) - elInd])
        })
        csmRESarraySORTED = newArray
        //-----------------------------------------------------------------------------------
        


        //---------------------------------------corr-------------------------------------------
        //corr
        const baseCURR = 'USD'
        const baseCURRlength = currCorrDataArrayObj[baseCURR].length
        let truthyARRAY = []
        
        for(let curr in currTotalPERFobjCount){
            truthyARRAY.push(currCorrDataArrayObj[curr].length===baseCURRlength)
        }

        //console.log({baseCURRlength, truthyARRAY})

        const corrRESobj = {}

        if(
            truthyARRAY.includes(false)===false
        ){
            for(let currpair of currPairArray){
                const base  = currpair.slice(0,3)
                const quote = currpair.slice(3)

                const baseARRAY  = currCorrDataArrayObj[base]
                const quoteARRAY = currCorrDataArrayObj[quote]

                const corrRES = calculateCorrelation(baseARRAY, quoteARRAY)

                //console.log({currpair, base, quote, baseARRAY, quoteARRAY, corrRES})

                corrRESobj[currpair] = corrRES

                //break
            }
        }
        //--------------------------------------------------------------------------

        //console.log({corrRESobj})
        
        const calcCURRperfScaled = calcCURRperf(corrRESobj)

        let corrRESarray = []
        for(let key in calcCURRperfScaled){
            const obj = {}
            obj['name']  = key
            obj['scaled']  = Number(100 - calcCURRperfScaled[key].scaled)
            obj['unscaled'] = Number(calcCURRperfScaled[key].unscaled)
            //console.log(corrRESobj[key])
            corrRESarray.push(obj)
        }
        const corrRESarraySORTED = bubbleSort(corrRESarray)

        const finalRESobj = {//rsm
            corr : corrRESarraySORTED,
            rsm  : csmRESarraySORTED,
        }
        //console.log({finalRESobj})

        return(finalRESobj)//*/

    }
    catch(e){
        return({error:'error'})
        //console.log('error')
    }

}





function calcSTOCH_all(dataParam, dynamicPERIOD) {

    try{

        /*
            To calculate the Stochastic Oscillator based on the provided data and settings, you can follow these steps:

            - Calculate the %K value for each data point.
            - Calculate the simple moving average (SMA) of the %K values for the %Dperiod.
            - Calculate the %D value based on the SMA calculated in step 2.
        */
    

        /*
        [
        {
            "datetime": "2023-11-02 06:20:00",
            "EUR": [60.555341933568165,60.5757943297036,60.442904296669525,60.513186720814765,13],
            "GBP": [-193.1889030472101,-193.1800506384007,-193.31426300934712,-193.23952518825791,0],
            "AUD": [-6.323424353775735,-6.2086247142449436,-6.385146132140607,-6.355740408737798,18],
            "NZD": [101.0927835996365,101.20123759358509,101.03532371220028,101.07191385540642,30],
            "USD": [8.389081925339745,8.413833373758278,8.290286933267911,8.390347641927578,38],
            "CAD": [43.971002549629965,44.004847618370455,43.90899470358006,43.965005205708856,35],
            "CHF": [421.97153249699153,422.0031506406322,421.8832321151646,421.93542271567713,29],
            "JPY": [-436.46741510418013,-436.1608749853851,-436.5106458374137,-436.2806105425391,100]
        },
        ]
        */

        const data = dataParam


        /*
        default:
            const Kperiod = 5; 5/5 = 1
            const Dperiod = 3; 3/5 = 0.6
            const slowing = 3; 3/5 = 0.6
        */

        const KperiodCONSTperiodRATIO = 1
        const DperiodCONSTperiodRATIO = 0.6
        const slowingCONSTperiodRATIO = 0.6

        /*console.log({
            Kperiod : Math.round(dynamicPERIOD * KperiodCONSTperiodRATIO),
            Dperiod : Math.round(dynamicPERIOD * DperiodCONSTperiodRATIO),
            slowing : Math.round(dynamicPERIOD * slowingCONSTperiodRATIO)
        })*/


        const Kperiod = Math.round(dynamicPERIOD * KperiodCONSTperiodRATIO)
        const Dperiod = Math.round(dynamicPERIOD * DperiodCONSTperiodRATIO)
        const slowing = Math.round(dynamicPERIOD * slowingCONSTperiodRATIO)


        const Dvalues = [];
        const Kvalues = [];
        const result  = [];

        //----------------------------------------------------------------------
        const currs = ["EUR", "GBP","AUD", "NZD", "USD", "CAD", "CHF", "JPY"]
        //---------------------------------------------------------------------
        
        // Calculate %K values
        //"EUR": [60.555341933568165,60.5757943297036,60.442904296669525,60.513186720814765,13]
        //[OHLC%]=[01234]
        //open=0
        //high=1
        //low=2
        //close=3
        //perf=4
        for (let i = 0; i < data.length; i++) {
            /*const K = ((data[i].close - Math.min(...data.slice(i - (Kperiod - 1), i + 1).map(item => item.low))) /
                        (Math.max(...data.slice(i - (Kperiod - 1), i + 1).map(item => item.high)) - 
                        Math.min(...data.slice(i - (Kperiod - 1), i + 1).map(item => item.low)))) * 100;*/
            /*
            const K = ((data[i][3] - Math.min(...data.slice(i - (Kperiod - 1), i + 1).map(item => item[2]))) /
                            (Math.max(...data.slice(i - (Kperiod - 1), i + 1).map(item => item[1])) - 
                            Math.min(...data.slice(i - (Kperiod - 1), i + 1).map(item => item[2])))) * 100;
            */
            
            const datetime = data[i].datetime 
            const currObj = {datetime}
            for(let curr of currs){
                const K = ((data[i][curr][3] - Math.min(...data.slice(i - (Kperiod - 1), i + 1).map(item => item[curr][2]))) /
                            (Math.max(...data.slice(i - (Kperiod - 1), i + 1).map(item => item[curr][1])) - 
                            Math.min(...data.slice(i - (Kperiod - 1), i + 1).map(item => item[curr][2])))) * 100;
                
                currObj[curr] = K
            }

            Kvalues.push(currObj);
        }
        //console.log(Kvalues[Kvalues.length-1])
        /*
        Kvalues = [
            {
                datetime: '2023-11-03 23:00:00',
                EUR: 10.686416934731781,
                GBP: 61.892186030314555,
                AUD: 72.98211068451002,
                NZD: 76.96960958910657,
                USD: 54.92707865062677,
                CAD: 87.70933212687409,
                CHF: 8.237604413458943,
                JPY: 60.48223092351465
            },
        ]
        */
        
        // Calculate %D values (SMA of %K values) - Moving Average Calculation
        for (let i = Kperiod - 1; i < Kvalues.length; i++) {

            const datetime = Kvalues[i].datetime
            const currObj = {datetime}
            const isBothAnumberTruthyArray = []
            for(let curr of currs){

                const sum = Kvalues.slice(i - (Dperiod - 1), i + 1).reduce((sumTracker, val) => sumTracker + val[curr], 0);
                const SMA = sum / Dperiod;
                //Dvalues.push(SMA);

                //EUR: [ 64.83118738093157, 56.3728225936063 ],
                //const datetime = data[i].datetime
                const K = Kvalues[i][curr]
                const D = SMA

                currObj[curr] = [K, D]

                const isBothAnumber = (K >= 0 || K <= 0) && (D >= 0 || D <= 0)
                isBothAnumberTruthyArray.push(isBothAnumber)
            }
            //console.log(currObj)
            

            if(isBothAnumberTruthyArray.includes(false)===false){
                /*result.push({ 
                    datetime,
                    EUR : [K, D],
                    GBP: [ 0, 0 ],
                    AUD: [ 0, 0 ],
                    NZD: [ 0, 0 ],
                    USD: [ 0, 0 ],
                    CAD: [ 0, 0 ],
                    CHF: [ 0, 0 ],
                    JPY: [ 0, 0 ]
                })*/
                result.push(currObj)
            }

        

        }
        
        //console.log(result[result.length-1])
        /*
        {
            datetime: '2023-11-03 23:00:00',
            EUR: [ 10.686416934731781, 26.408790609762278 ],
            GBP: [ 61.892186030314555, 68.691865227581 ],
            AUD: [ 72.98211068451002, 79.55245809719936 ],
            NZD: [ 76.96960958910657, 73.7653128991032 ],
            USD: [ 54.92707865062677, 39.67746244762224 ],
            CAD: [ 87.70933212687409, 76.94203841565987 ],
            CHF: [ 8.237604413458943, 23.04100588702445 ],
            JPY: [ 60.48223092351465, 33.441256822895575 ]
        }
        */

        return result;

    }
    catch(e){
        return({error:'error'})
        //console.log('error')
    }

}



function calcCURRperf(obj){

    try{


        const currPerfObj = obj

        let val_arr = []
        let min_val;
        let max_val;

        for(let curr_key in currPerfObj) {
            //console.log(key, ':', currPerfObj[key])
            val_arr.push(currPerfObj[curr_key])
        }

        min_val = Math.min(...val_arr)
        max_val = Math.max(...val_arr)

        //console.log('val_arr : ', val_arr)
        //console.log('min_val : ', min_val)
        //console.log('max_val : ', max_val)

        const abs_min_val = Math.abs(min_val)//to have 0.0 for min
        //console.log('abs_min_val : ', abs_min_val)


        for(let curr_key in currPerfObj) {

            const curr_val = currPerfObj[curr_key]

            const curr_scaled_perf = Math.round( ( (curr_val + abs_min_val) / (max_val + abs_min_val) ) * 100 )
            //console.log(curr_key, " : ", curr_scaled_perf)
            const curr_perf_obj = {
            scaled : curr_scaled_perf,
            unscaled : curr_val
            }
            //console.log(curr_key, " : ", curr_perf_obj)

            //currPerfObj[curr_key] = curr_perf_obj
            currPerfObj[curr_key] = curr_perf_obj
        }


        //console.log(currPerfObj)
        /*
        {
            EUR: { scaled: 39, unscaled: -6.46324423252284 },
            GBP: { scaled: 88, unscaled: 18.53159273555977 },
            AUD: { scaled: 96, unscaled: 22.273911551915404 },
            NZD: { scaled: 100, unscaled: 24.468080118412516 },
            USD: { scaled: 0, unscaled: -25.931054672556186 },
            CAD: { scaled: 5, unscaled: -23.194961223546414 },
            CHF: { scaled: 5, unscaled: -23.440758994044202 },
            JPY: { scaled: 58, unscaled: 3.3689681970434293 }
            }
        */

        return(
            currPerfObj
        )
    

    }
    catch(e){
        return({error:'error'})
        //console.log('error')
    }



}







function bubbleSort(arr) {

    try{
        const n = arr.length;

        // Outer loop for each pass
        for (let i = 0; i < n - 1; i++) {
            // Inner loop for comparison and swapping
            for (let j = 0; j < n - i - 1; j++) {
                // Swap if the current element is greater than the next element
                if (arr[j].unscaled > arr[j + 1].unscaled) {
                    // Swap arr[j] and arr[j + 1]
                    [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
                }
            }
        }
        return arr;
    }
    catch(e){
        return({error:'error'})
        //console.log('error')
    }
}




function calculateCorrelation(array1, array2) {

    try{
        /*if (array1.length !== array2.length) {
            throw new Error('Arrays must be of the same length');
        }*/

        const n = array1.length;

        // Calculating mean of the arrays
        const mean1 = array1.reduce((acc, val) => acc + val, 0) / n;
        const mean2 = array2.reduce((acc, val) => acc + val, 0) / n;

        // Calculating covariance and the standard deviations
        let covariance = 0;
        let sd1 = 0;
        let sd2 = 0;

        for (let i = 0; i < n; i++) {
            covariance += (array1[i] - mean1) * (array2[i] - mean2);
            sd1 += (array1[i] - mean1) ** 2;
            sd2 += (array2[i] - mean2) ** 2;
        }

        // Standard deviations
        sd1 = Math.sqrt(sd1 / n);
        sd2 = Math.sqrt(sd2 / n);

        // Pearson correlation coefficient
        const correlation = covariance / (n * sd1 * sd2);

        return correlation;
    }
    catch(e){
        return({error:'error'})
        //console.log('error')
    }
}



function isWeekend(datetime) {
    const date = new Date(datetime);
    const day = date.getDay();
    return day === 0 || day === 6; // 0 is Sunday, 6 is Saturday
}

function isMonday(datetime) {
    const date = new Date(datetime);
    const day = date.getDay();
    return day === 1 // 1 is Monday
}






function psychoLEVELcalc(obj){

    try{

        const psychoLEVELs = obj.psychoLEVELs
        const instrNAME    = obj.instrNAME
        const minPRICE     = obj.minPRICE
        const maxPRICE     = obj.maxPRICE


        const isJPY        = instrNAME.includes('JPY') ? true : false
        const pairUNIT     = isJPY ? 100 : 10000

        //normalize price decimal place
        const defaultUNIT = pairUNIT//10000
        const defaultUNITprice = 1/defaultUNIT//0.0001
        //console.log({isJPY})

        let calcRESarray = []//{}
        let calcRESobj   = {}


        for(let psychoVAL of psychoLEVELs){
            //console.log(psychoVAL, 1/10000)

            const minPRICEround = Math.round(minPRICE * pairUNIT) / pairUNIT//round num in whole number form, then brought back into decimal

            let psychoBaseUNIT = minPRICEround
            if(isJPY){
                //console.log('hello world...')
                let psychoBaseUNITstr = String(psychoBaseUNIT)//'106'

                //if price includes no decimal, then add decimal('.0') at end
                psychoBaseUNITstr = psychoBaseUNITstr.includes('.') ? psychoBaseUNITstr : (psychoBaseUNITstr + '.0')
                //console.log({psychoBaseUNITstr})

                //before perform psychoVALslice - to generate a psychoBaseUNIT - to in loopwise add psychoLEVELS 
                //make sure that the unit-length after the decimal is normalized to the unit decimal length of defaultUNITprice 0.0001
                //where we add 0's if less, or we slice off if length greater
                const normalizePRICEunitLENstr = normalizePRICEunitLEN(defaultUNITprice, psychoBaseUNITstr)
                psychoBaseUNITstr = typeof(normalizePRICEunitLENstr)==='string' && 
                normalizePRICEunitLENstr.length > 0 && normalizePRICEunitLENstr.includes('.') ? normalizePRICEunitLENstr : psychoBaseUNITstr


                psychoBaseUNITstr = String(Math.round(psychoBaseUNITstr * pairUNIT))

                const psychoVALsliceATstart = psychoBaseUNITstr.slice(0, psychoBaseUNITstr.length - String(psychoVAL).length)
                const psychoVALsliceATend = psychoBaseUNITstr.slice(psychoBaseUNITstr.length - String(psychoVAL).length)
                psychoBaseUNIT = Number(psychoVALsliceATstart + String(psychoVAL)) / pairUNIT//1.06

                /*console.log({
                    psychoVAL,
                    minPRICEround,
                    psychoVALsliceATstart,
                    psychoVALsliceATend,
                    psychoBaseUNIT
                })*/
            }
            else{
                let psychoBaseUNITstr = String(psychoBaseUNIT)//'1.0601' - 100

                //if price includes no decimal, then add decimal('.0') at end
                psychoBaseUNITstr = psychoBaseUNITstr.includes('.') ? psychoBaseUNITstr : (psychoBaseUNITstr + '.0')

                //before perform psychoVALslice - to generate a psychoBaseUNIT - to in loopwise add psychoLEVELS 
                //make sure that the unit-length after the decimal is normalized to the unit decimal length of defaultUNITprice 0.0001
                //where we add 0's if less, or we slice off if length greater
                const normalizePRICEunitLENstr = normalizePRICEunitLEN(defaultUNITprice, psychoBaseUNITstr)
                psychoBaseUNITstr = typeof(normalizePRICEunitLENstr)==='string' && 
                normalizePRICEunitLENstr.length > 0 && normalizePRICEunitLENstr.includes('.') ? normalizePRICEunitLENstr : psychoBaseUNITstr


                const psychoVALsliceATstart = psychoBaseUNITstr.slice(0, psychoBaseUNITstr.length - String(psychoVAL).length)
                const psychoVALsliceATend = psychoBaseUNITstr.slice(psychoBaseUNITstr.length - String(psychoVAL).length)
                psychoBaseUNIT = Number(psychoVALsliceATstart + String(psychoVAL))//1.06

                /*console.log({
                    psychoVAL,
                    minPRICEround,
                    psychoVALsliceATstart,
                    psychoVALsliceATend,
                    psychoBaseUNIT
                })*/
            }

            

            if(typeof(psychoBaseUNIT)==='number'){
                
                let psychoPRICEcount = psychoBaseUNIT - ((psychoVAL/pairUNIT) * 5)

                for(let i = 0; i < 1000; i++){//1000

                    if(psychoPRICEcount > (maxPRICE + ((psychoVAL/pairUNIT) * 5))){
                        //console.log('hello... - broken : ', i)
                        break
                    }
                    //console.log('hello... - UNbroken : ', i)


                    if(i===0){
                        const outlier = psychoPRICEcount 
                        if(
                            calcRESarray.includes(Math.round(outlier * pairUNIT) / pairUNIT, psychoBaseUNIT)===false
                        ){
                            calcRESarray.push(Math.round(outlier * pairUNIT) / pairUNIT, psychoBaseUNIT)
                        }
                    }

                    psychoPRICEcount += psychoVAL/pairUNIT
                    if(
                        calcRESarray.includes(Math.round(psychoPRICEcount * pairUNIT) / pairUNIT)===false
                    ){
                        calcRESarray.push(Math.round(psychoPRICEcount * pairUNIT) / pairUNIT)
                    }
                }
            }
            
            //console.log(calcRESarray)

            //break
            
        }


        return calcRESarray

    }
    catch (e) {
        //console.log({e})
        return []
    }



}







/*
const defaultUNITprice  = 0.0001
const psychoBaseUNITstr = '1.061'


console.log(normalizePRICEunitLEN(defaultUNITprice, psychoBaseUNITstr))
*/

function normalizePRICEunitLEN(
    defaultUNITpricePARAM, 
    psychoBaseUNITstrPARAM
){

    try{

        const defaultUNITprice                = defaultUNITpricePARAM
        const defaultUNITpriceSTR             = String(defaultUNITprice)//'0.0001'
        const defaultUNITpriceDECIMALindex    = defaultUNITpriceSTR.indexOf('.')//1
        const defaultUNITpriceLEN             = defaultUNITpriceSTR.length// '0.0001' = 6
        const defaultUNITpriceLENafterDECIMAL = (defaultUNITpriceLEN - 1) - defaultUNITpriceDECIMALindex//4


        const psychoBaseUNITstr    = psychoBaseUNITstrPARAM
        const priceDECIMALindex    = psychoBaseUNITstr.indexOf('.')//1
        const priceLEN             = psychoBaseUNITstr.length// '1.061' = 5
        const priceLENafterDECIMAL = (priceLEN - 1) - priceDECIMALindex//3


        const priceDIFF = defaultUNITpriceLENafterDECIMAL - priceLENafterDECIMAL//1

        let newPRICEstr = psychoBaseUNITstr
        if(priceDIFF > 0){//+1
            for(let i=0; i<priceDIFF; i++){
                //console.log(i)
                newPRICEstr += '0'
            }
        }
        else if(priceDIFF < 0){//-1
            //pop or slice end out
            newPRICEstr = newPRICEstr.slice(0, newPRICEstr.length + priceDIFF)
        }



        /*console.log({
            defaultUNITprice,
            defaultUNITpriceSTR,
            defaultUNITpriceDECIMALindex,
            defaultUNITpriceLEN,
            defaultUNITpriceLENafterDECIMAL,

            psychoBaseUNITstr,
            priceDECIMALindex,
            priceLEN,
            priceLENafterDECIMAL,

            newPRICEstr
        })*/

        return newPRICEstr

    }
    catch(e){
        return ''
    }

}






export default Chart;



import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { FiPlus, FiMinus } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { SlRefresh } from "react-icons/sl";


const HistStrengthMeterCompo = ({
    isRSIcorrListMaximized,
    setIsRSIcorrListMaximized,
    rsiCORRdata,
    zero_gmt_deviation,
    corrCalcDATETIME,
    timeZoneDatetimeConverter,
    rsiORIdata,
    chartInfoObj,
    currColorObj,
    setWasChartLockedBeforeOnClickSettingsDisplay,
    canvasCompoId,
    isScreenScrollLocked,
    rsiDATETIMEnumCountObj,
    calcRSIcorrFUNC,
    rsiDATETIMEobjArrayIDs,
    setCorrCalcDATETIME,
    setRsiDATETIMEnumCountObj,
    datetimeToTimestamp,
    setIsShowCorrelationScreenCover,
    isShowCorrelationScreenCover,
    wasChartLockedBeforeOnClickSettingsDisplay,
    isTouchScreenDevice,
    canvasWidth,

}) => {




return (
<div
style={{
    //border:'3px solid red',
    backgroundColor:'rgba(0, 0, 0, 0.7)',
    //width:'100%',
    //height:'100%',
    position:'absolute',
    top:'0px',
    //right:`${'50%'}`,//canvasWidth
    bottom:`${isRSIcorrListMaximized ? '0px' : ''}`,
    left:'0px',
    zIndex:'3',
    color:'white',
    padding:'5px',
    paddingLeft:`${canvasWidth>500 ? '20px' : '5px'}`
}}
>

    <div
    style={{
        //border:'1px solid white',
        height:'5%',
        //width:'50%',
        //display:'flex',
    }}
    >


        <div
        style={{
            marginBottom:'5px',
            //border:'1px solid white',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center'
        }}
        >

            <div
            style={{
                //fontSize:'12px',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                color:'gold',
                fontWeight:'bold',
                //border:'1px solid white',
            }}
            >
                Historical Strength Meter & Corr. 
            </div>
            
            <div
            style={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
            }}
            >

                <SlRefresh
                style={{
                    backgroundColor:'white',
                    borderRadius:'50%',
                    padding:'4px',
                    color:'black',
                    cursor:'pointer',
                    marginRight:'7px'
                }}

                onClick={()=>{

                    const value = 1

                    setRsiDATETIMEnumCountObj({
                        datetimeStart : value,
                        datetimeEnd : value
                    })

                    setCorrCalcDATETIME({
                        datetimeStart : rsiDATETIMEobjArrayIDs[String(
                            (rsiORIdata.length-1) - (Number(value) - 1)
                        )],
                        datetimeEnd : rsiDATETIMEobjArrayIDs[String(
                            (rsiORIdata.length-1) - (Number(value) - 1)
                        )]
                    })

                    //------------------------------------------------------------------------------------
                    //corr calc
                    const ARRAYstartIndex = 1
                    const ARRAYendIndex   = 1
                    //console.log({ARRAYstartIndex, ARRAYendIndex})

                    if(ARRAYstartIndex>=1 && ARRAYendIndex>=1){
                        calcRSIcorrFUNC(rsiORIdata, ARRAYstartIndex, ARRAYendIndex)
                    }
                    //-----------------------------------------------------------------------------------
                    
                }}
                />

                <GrClose
                style={{
                    backgroundColor:'white',
                    borderRadius:'50%',
                    padding:'5px',
                    color:'black',
                    cursor:'pointer',
                    marginRight:'5px'
                }}

                onClick={()=>{
                    if(isTouchScreenDevice && wasChartLockedBeforeOnClickSettingsDisplay && isScreenScrollLocked===false && isShowCorrelationScreenCover){
                        document.getElementById(`lockButton-${canvasCompoId}`).click()
                        setWasChartLockedBeforeOnClickSettingsDisplay(false)
                    }

                    setIsShowCorrelationScreenCover(false)
                }}
                />
            </div>
            
        </div>


    </div>


    <div
    style={{
        //border:'1px solid red',
        height:'7%',
        //width:'50%',
        //display:'flex',
        userSelect:'none',
        marginTop:'2%'
    }}
    >
        {/*<div
        style={{
            color:'lightgreen',
            fontWeight:'bold'
        }}
        >
            Calculate RSI Strength meter & Correlation :
        </div>*/}

        <div
        style={{
            //userSelect:'none'
        }}
        >
            From : <span>
            <select //id="pet-select" 
            value={
                timeZoneDatetimeConverter(
                    corrCalcDATETIME.datetimeStart, 
                    localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                    zero_gmt_deviation
                )
            }
            onChange={(e)=>{
                /*
                const [corrCalcDATETIME, setCorrCalcDATETIME] = useState({
                    datetimeStart:'', datetimeEnd:'' 
                })

                //console.log({rsiDATETIMEobjArrayIDsObj})
                /*
                {rsiDATETIMEobjArrayIDs
                    '2023-10-06 18:00:00' : 0,
                    '2023-10-06 19:00:00' : 1,
                    '2023-10-06 20:00:00' : 2,
                    '2023-10-06 21:00:00' : 3,

                    0 : '2023-10-06 18:00:00',
                    1 : '2023-10-06 19:00:00',
                    2 : '2023-10-06 20:00:00',
                    3 : '2023-10-06 21:00:00',
                }
                */ 
                const value = datetimeToTimestamp(e.target.value) > datetimeToTimestamp(corrCalcDATETIME.datetimeEnd) ? 
                corrCalcDATETIME.datetimeEnd : e.target.value


                setCorrCalcDATETIME({
                    ...corrCalcDATETIME,
                    datetimeStart : value
                })

                const countNumVal = Math.abs((rsiORIdata.length + 1) - (Number(rsiDATETIMEobjArrayIDs[value]) + 1))

                setRsiDATETIMEnumCountObj({
                    ...rsiDATETIMEnumCountObj,
                    datetimeStart : countNumVal
                })


                //------------------------------------------------------------------------------------
                //corr calc
                const ARRAYstartIndex = Number(countNumVal)
                const ARRAYendIndex   = Number(rsiDATETIMEnumCountObj.datetimeEnd)
                //console.log({ARRAYstartIndex, ARRAYendIndex})

                if(ARRAYstartIndex>=1 && ARRAYendIndex>=1){
                    calcRSIcorrFUNC(rsiORIdata, ARRAYstartIndex, ARRAYendIndex)
                }
                //-----------------------------------------------------------------------------------*/
            }}
            style={{
                cursor:'pointer', 
                marginLeft:'2px',
                fontSize:'12px'
            }}
            >
                {//
                    //['2024-12-11 12:14', '2024-12-11 12:50']
                    rsiORIdata.map((chartTypeOptionName, ind)=>{
                        const datetimeStr = chartTypeOptionName.datetime
                        //const datetimeStr = rsiORIdata[(rsiORIdata.length-1) - ind].datetime
                        return(
                            <option value={datetimeStr} key={ind} style={{cursor:'pointer'}}>{
                                datetimeStr
                            }</option>
                        )
                    })
                }
                
            </select>
            </span> : <span>

                <FiMinus
                style={{
                    cursor:'pointer',
                    backgroundColor:'white',
                    color:'black',
                    marginLeft:'4px',
                    marginRight:'4px',
                    //marginTop:'4px'
                }}

                onClick={(e)=>{

                    const value = (Number(rsiDATETIMEnumCountObj.datetimeStart) > Number(rsiDATETIMEnumCountObj.datetimeEnd)) &&
                    (Number(rsiDATETIMEnumCountObj.datetimeStart) - 1) > 0 ? 
                    (Number(rsiDATETIMEnumCountObj.datetimeStart) - 1) : rsiDATETIMEnumCountObj.datetimeStart

                    setRsiDATETIMEnumCountObj({
                        ...rsiDATETIMEnumCountObj,
                        datetimeStart : value
                    })

                    setCorrCalcDATETIME({
                        ...corrCalcDATETIME,
                        datetimeStart : rsiDATETIMEobjArrayIDs[String(
                            (rsiORIdata.length-1) - (Number(value) - 1)
                        )]
                    })

                    //------------------------------------------------------------------------------------
                    //corr calc
                    const ARRAYstartIndex = Number(value)
                    const ARRAYendIndex   = Number(rsiDATETIMEnumCountObj.datetimeEnd)
                    //console.log({ARRAYstartIndex, ARRAYendIndex})

                    if(ARRAYstartIndex>=1 && ARRAYendIndex>=1){
                        calcRSIcorrFUNC(rsiORIdata, ARRAYstartIndex, ARRAYendIndex)
                    }
                    //-----------------------------------------------------------------------------------
                }}
                />

                <input type="number" value={rsiDATETIMEnumCountObj.datetimeStart} min="1" max={rsiORIdata.length} step="1"
                name="" 
                onChange={(e)=>{
                    if(
                        (Number(e.target.value) >= Number(rsiDATETIMEnumCountObj.datetimeEnd)) 
                    ){
                        setRsiDATETIMEnumCountObj({
                            ...rsiDATETIMEnumCountObj,
                            datetimeStart : e.target.value
                        })

                        setCorrCalcDATETIME({
                            ...corrCalcDATETIME,
                            datetimeStart : rsiDATETIMEobjArrayIDs[String(
                                (rsiORIdata.length-1) - (Number(e.target.value) - 1)
                            )]
                        })

                        //------------------------------------------------------------------------------------
                        //corr calc
                        const ARRAYstartIndex = Number(e.target.value)
                        const ARRAYendIndex   = Number(rsiDATETIMEnumCountObj.datetimeEnd)
                        //console.log({ARRAYstartIndex, ARRAYendIndex})

                        if(ARRAYstartIndex>=1 && ARRAYendIndex>=1){
                            calcRSIcorrFUNC(rsiORIdata, ARRAYstartIndex, ARRAYendIndex)
                        }
                        //-----------------------------------------------------------------------------------
                        
                    }
                }}
                />

                <FiPlus
                style={{
                    cursor:'pointer',
                    backgroundColor:'white',
                    color:'black',
                    marginLeft:'4px',
                    marginRight:'4px'
                }}

                onClick={(e)=>{
                    //console.log('+add')

                    const value = (Number(rsiDATETIMEnumCountObj.datetimeStart) + 1) < rsiORIdata.length ? 
                    (Number(rsiDATETIMEnumCountObj.datetimeStart) + 1) : rsiDATETIMEnumCountObj.datetimeStart

                    setRsiDATETIMEnumCountObj({
                        ...rsiDATETIMEnumCountObj,
                        datetimeStart : value
                    })

                    setCorrCalcDATETIME({
                        ...corrCalcDATETIME,
                        datetimeStart : rsiDATETIMEobjArrayIDs[String(
                            (rsiORIdata.length-1) - (Number(value) - 1)
                        )]
                    })



                    //------------------------------------------------------------------------------------
                    //corr calc
                    const ARRAYstartIndex = Number(value)
                    const ARRAYendIndex   = Number(rsiDATETIMEnumCountObj.datetimeEnd)
                    //console.log({ARRAYstartIndex, ARRAYendIndex})

                    if(ARRAYstartIndex>=1 && ARRAYendIndex>=1){
                        calcRSIcorrFUNC(rsiORIdata, ARRAYstartIndex, ARRAYendIndex)
                    }
                    //-----------------------------------------------------------------------------------

                }}
                />
            </span>

        </div>

        <div
        style={{
            
        }}
        >
            To <span style={{marginLeft:'0px'}}>:</span>  <span>
            <select //id="pet-select" 
            value={//'Current Time'
                corrCalcDATETIME.datetimeEnd === rsiORIdata[rsiORIdata.length-1].datetime 
                ? 'Current Time' 
                : 
                timeZoneDatetimeConverter(
                    corrCalcDATETIME.datetimeEnd, 
                    localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                    zero_gmt_deviation
                )
            }
            onChange={(e)=>{
                /*
                const [corrCalcDATETIME, setCorrCalcDATETIME] = useState({
                    datetimeStart:'', datetimeEnd:'' 
                })

                //console.log({rsiDATETIMEobjArrayIDsObj})
                /*
                {
                    '2023-10-06 18:00:00' : 0,
                    '2023-10-06 19:00:00' : 1,
                    '2023-10-06 20:00:00' : 2,
                    '2023-10-06 21:00:00' : 3,

                    0 : '2023-10-06 18:00:00',
                    1 : '2023-10-06 19:00:00',
                    2 : '2023-10-06 20:00:00',
                    3 : '2023-10-06 21:00:00',
                }
                */ 

                const value = datetimeToTimestamp(e.target.value) < datetimeToTimestamp(corrCalcDATETIME.datetimeStart) ? 
                corrCalcDATETIME.datetimeStart : e.target.value

                setCorrCalcDATETIME({
                    ...corrCalcDATETIME,
                    datetimeEnd : value === rsiORIdata[rsiORIdata.length-1].datetime ? 'Current Time' : value
                })

                const countNumVal = Math.abs((rsiORIdata.length + 1) - (Number(rsiDATETIMEobjArrayIDs[
                    value !== 'Current Time' ? value : rsiORIdata[rsiORIdata.length-1].datetime
                ]) + 1))

                setRsiDATETIMEnumCountObj({
                    ...rsiDATETIMEnumCountObj,
                    datetimeEnd : countNumVal
                })

                //------------------------------------------------------------------------------------
                //corr calc
                const ARRAYstartIndex = Number(rsiDATETIMEnumCountObj.datetimeStart) 
                const ARRAYendIndex   = Number(countNumVal)
                //console.log({ARRAYstartIndex, ARRAYendIndex})

                if(ARRAYstartIndex>=1 && ARRAYendIndex>=1){
                    calcRSIcorrFUNC(rsiORIdata, ARRAYstartIndex, ARRAYendIndex)
                }
                //-----------------------------------------------------------------------------------*/
            }}
            style={{
                cursor:'pointer', marginLeft:'2px',
                fontSize:'12px'
            }}
            >
                {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                    //['2024-12-11 12:14', '2024-12-11 12:50']
                    [...rsiORIdata, {datetime:'Current Time'}].map((chartTypeOptionName, ind)=>{
                        let datetimeStr = ''
                        //if(ind===0){
                            datetimeStr = chartTypeOptionName.datetime
                        /*}
                        else{
                            datetimeStr = rsiORIdata[(rsiORIdata.length-1) - (ind-1)].datetime
                        }*/
                        return(
                            <option value={datetimeStr} key={ind} style={{cursor:'pointer'}}>{
                                datetimeStr
                            }</option>
                        )
                    })
                }
                
            </select> : <span>

            <FiMinus
            style={{
                cursor:'pointer',
                backgroundColor:'white',
                color:'black',
                marginLeft:'4px',
                marginRight:'4px'
            }}

            onClick={(e)=>{

                const value = (Number(rsiDATETIMEnumCountObj.datetimeEnd) - 1) > 0 ? 
                (Number(rsiDATETIMEnumCountObj.datetimeEnd) - 1) : rsiDATETIMEnumCountObj.datetimeEnd

                setRsiDATETIMEnumCountObj({
                    ...rsiDATETIMEnumCountObj,
                    datetimeEnd : value
                })

                setCorrCalcDATETIME({
                    ...corrCalcDATETIME,
                    datetimeEnd : rsiDATETIMEobjArrayIDs[String(
                        (rsiORIdata.length-1) - (Number(value) - 1)
                    )]
                })

                //------------------------------------------------------------------------------------
                //corr calc
                const ARRAYstartIndex = Number(rsiDATETIMEnumCountObj.datetimeStart)
                const ARRAYendIndex   = Number(value) 
                //console.log({ARRAYstartIndex, ARRAYendIndex})

                if(ARRAYstartIndex>=1 && ARRAYendIndex>=1){
                    calcRSIcorrFUNC(rsiORIdata, ARRAYstartIndex, ARRAYendIndex)
                }
                //-----------------------------------------------------------------------------------
            }}
            />

            <input type="number" value={rsiDATETIMEnumCountObj.datetimeEnd} min="1" max={rsiORIdata.length-1} step="1"
            name="" 

            onChange={(e)=>{
                if(
                    (Number(e.target.value) <= Number(rsiDATETIMEnumCountObj.datetimeStart))
                ){
                    setRsiDATETIMEnumCountObj({
                        ...rsiDATETIMEnumCountObj,
                        datetimeEnd : e.target.value
                    })

                    setCorrCalcDATETIME({
                        ...corrCalcDATETIME,
                        datetimeEnd : rsiDATETIMEobjArrayIDs[String(
                            (rsiORIdata.length-1) - (Number(e.target.value) - 1)
                        )]
                    })

                    //------------------------------------------------------------------------------------
                    //corr calc
                    const ARRAYstartIndex = Number(rsiDATETIMEnumCountObj.datetimeStart)
                    const ARRAYendIndex   = Number(e.target.value) 
                    //console.log({ARRAYstartIndex, ARRAYendIndex})

                    if(ARRAYstartIndex>=1 && ARRAYendIndex>=1){
                        calcRSIcorrFUNC(rsiORIdata, ARRAYstartIndex, ARRAYendIndex)
                    }
                    //-----------------------------------------------------------------------------------
                }
            }}
            />

            <FiPlus
            style={{
                cursor:'pointer',
                backgroundColor:'white',
                color:'black',
                marginLeft:'4px',
                marginRight:'4px'
            }}

            onClick={(e)=>{

                const value = (Number(rsiDATETIMEnumCountObj.datetimeEnd) < Number(rsiDATETIMEnumCountObj.datetimeStart)) &&
                (Number(rsiDATETIMEnumCountObj.datetimeEnd) + 1) < rsiORIdata.length ? 
                (Number(rsiDATETIMEnumCountObj.datetimeEnd) + 1) : rsiDATETIMEnumCountObj.datetimeEnd

                setRsiDATETIMEnumCountObj({
                    ...rsiDATETIMEnumCountObj,
                    datetimeEnd : value
                })

                setCorrCalcDATETIME({
                    ...corrCalcDATETIME,
                    datetimeEnd : rsiDATETIMEobjArrayIDs[String(
                        (rsiORIdata.length-1) - (Number(value) - 1)
                    )]
                })

                //------------------------------------------------------------------------------------
                //corr calc
                const ARRAYstartIndex = Number(rsiDATETIMEnumCountObj.datetimeStart)
                const ARRAYendIndex   = Number(value) 
                //console.log({ARRAYstartIndex, ARRAYendIndex})

                if(ARRAYstartIndex>=1 && ARRAYendIndex>=1){
                    calcRSIcorrFUNC(rsiORIdata, ARRAYstartIndex, ARRAYendIndex)
                }
                //-----------------------------------------------------------------------------------
            }}
            />

            </span> - <span
            style={{fontSize:'12px', backgroundColor:'gold', color:'black', padding:'2px',
            fontWeight:'bold'}}
            >
                {

                    /*authDatetimeStrValidityBeforeDrawORedit(corrCalcDATETIME.datetimeStart) && authDatetimeStrValidityBeforeDrawORedit(corrCalcDATETIME.datetimeEnd)
                    ? (getDatetimeDiffCount({
                        datetimeStart : corrCalcDATETIME.datetimeStart,
                        datetimeEnd   : corrCalcDATETIME.datetimeEnd,
                        tf            : chartInfoObj.tf
                    })).count+1 : ' - '*/
                    Math.abs(Number(rsiDATETIMEnumCountObj.datetimeStart) - Number(rsiDATETIMEnumCountObj.datetimeEnd)) + 1
                    //'500'
                }
            </span>
            </span> 
        </div>

        
        {/*<div
        style={{
            //border:'1px solid white',
            //height:'4%',
            //width:'50%',
            marginTop:'7px',
            display:'flex',
        }}

        onClick={()=>{
            //rsiORIdata
            //setRsiORIdata
        }}
        >
            <button
            style={{
                border:'0px',
                width:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                cursor:'pointer',
                backgroundColor:'lightgreen',
                fontWeight:'bold',
                padding:'2px'
            }}

            onClick={()=>{
                if(isRSIcorrListMaximized===false){
                    setIsRSIcorrListMaximized(true)
                }
            }}
            >
                {true ? 'Calculate' : 'Calculated'}

                {//import { ImCheckmark } from "react-icons/im";
                    true
                    ?
                    <FaCalculator className='coin'
                    style={{
                        marginLeft:'4px'
                    }}
                    />
                    :
                    <ImCheckmark className='coin'
                    style={{
                        marginLeft:'4px'
                    }}
                    />
                }

            </button>

        </div>*/}

    </div>


    {//false &&
    isRSIcorrListMaximized &&
        <div className="pageDropDownScroolBar2"
        style={{
            //border:'1px solid white',
            height:'80%',
            marginTop:'5%',
            //width:'50%',
            //display:'flex',
            overflowY:'scroll'
        }}

        onTouchStart={()=>{
            //console.log('am touch')

            if(isScreenScrollLocked){
                document.getElementById(`unlockButton-${canvasCompoId}`).click()
                setWasChartLockedBeforeOnClickSettingsDisplay(true)
            }
        }}
        >

            {

            <div>
                <div
                style={{
                    fontWeight:'bold',
                    color:'gold'
                }}
                >
                    {`${
                        chartInfoObj.candleORrsi==='candle' ? 'Currency' : 
                        chartInfoObj.candleORrsi === 'rsi' ? 'RSI' : 
                        chartInfoObj.candleORrsi === 'stoch' ? 'Stochastic' : ''
                    } Strength Meter :`} 
                </div>

                <div
                style={{
                    color:'gold'
                }}
                >
                    from <span
                    style={{
                        fontSize:'12px', 
                        fontWeight:'bold',
                        backgroundColor:'rgba(255, 255, 255, 0.7)',
                        color:'black',
                        borderRadius:'3px',
                        padding:'2px'
                    }}
                    >{
                        timeZoneDatetimeConverter(
                            corrCalcDATETIME.datetimeStart, 
                            localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                            zero_gmt_deviation
                        )
                    }</span> to <span
                    style={{
                        fontSize:'12px', 
                        fontWeight:'bold',
                        backgroundColor:'rgba(255, 255, 255, 0.7)',
                        color:'black',
                        borderRadius:'3px',
                        padding:'2px'
                    }}
                    >{
                        corrCalcDATETIME.datetimeEnd === rsiORIdata[rsiORIdata.length-1].datetime ? 'Current Time' 
                        : 
                        timeZoneDatetimeConverter(
                            corrCalcDATETIME.datetimeEnd, 
                            localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                            zero_gmt_deviation
                        )
                    }</span>
                </div>

                <div
                style={{
                    marginTop:'10px'
                }}
                >
                    {//Array(10).fill(1) - [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
                    //rsiCORRdata
                        //Array(currIndexArray.length).fill(1).map((el, elInd)=>{
                        rsiCORRdata.rsm.map((el, elInd)=>{

                            //{ name: 'JPY', value: 0, value2: -25.9394921125592 }
                            const currName = el.name
                            let currValue = el.unscaled
                            let currPerfValue = el.scaled
                            currValue = Number(String(currValue).slice(0, currValue<0 ? 5 : 4))


                            return(
                                <div key={elInd}
                                style={{
                                    display:'flex',
                                    height:'5%'
                                }}
                                >
                                    {
                                        // `${elInd+1} - 1colors -  ${currName} | ${currValue} | ${currPerfValue}%`
                                    }

                                    {false &&<div
                                    style={{
                                        height:'5%',
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'
                                    }}
                                    >
                                        {elInd+1}
                                    </div>}


                                    {false&&<div
                                    style={{
                                        height:'5%',
                                        width:'100%',
                                        display:'flex',
                                        //justifyContent:'center',
                                        alignItems:'center',
                                        //borderBottom:'1px solid white'
                                    }}
                                    >
                                        {
                                        //'1colors'
                                        }

                                        <div
                                        style={{
                                            height:'15px',
                                            width:'15px',
                                            backgroundColor:`${currColorObj[currName]}`
                                        }}
                                        ></div>
                                    </div>}

                                    <div
                                    style={{
                                        height:'5%',
                                        width:'100%',
                                        display:'flex',
                                        //justifyContent:'center',
                                        //backgroundColor:'white',
                                        //color:`${currColorObj[currName]}`,
                                        alignItems:'center',
                                        borderBottom:'1px solid rgba(255,255,255,0.3)',
                                        borderTop:`${elInd===0 ? '1px solid rgba(255,255,255,0.3)' : ''}`
                                    }}
                                    >
                                        {currName}
                                    </div>

                                    <div
                                    style={{
                                        height:'5%',
                                        width:'100%',
                                        display:'flex',
                                        //justifyContent:'center',
                                        //backgroundColor:'white',
                                        //color:`${currColorObj[currName]}`,
                                        alignItems:'center',
                                        borderBottom:'1px solid rgba(255,255,255,0.3)',
                                        borderTop:`${elInd===0 ? '1px solid rgba(255,255,255,0.3)' : ''}`
                                    }}
                                    >
                                        {currValue}
                                    </div>

                                    <div
                                    style={{
                                        height:'5%',
                                        width:'100%',
                                        display:'flex',
                                        //justifyContent:'center',
                                        //backgroundColor:'white',
                                        //color:`${currColorObj[currName]}`,
                                        alignItems:'center',
                                        borderBottom:'1px solid rgba(255,255,255,0.3)',
                                        borderTop:`${elInd===0 ? '1px solid rgba(255,255,255,0.3)' : ''}`
                                    }}
                                    >
                                        {`${currPerfValue}%`}
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>

            }


            {

            <div>
                <div
                style={{
                    marginTop:'20px',
                    fontWeight:'bold',
                    //backgroundColor:'white',
                    color:'gold',
                    /*padding:'2px',
                    borderRadius:'2px'*/
                }}
                >
                    {`Correlated ${ 
                        chartInfoObj.candleORrsi==='candle' ? 'Currency' : 
                        chartInfoObj.candleORrsi === 'rsi' ? 'RSI' : 
                        chartInfoObj.candleORrsi === 'stoch' ? 'Stochastic' : ''
                    } Pairs :`}
                </div>

                <div
                style={{
                    color:'gold'
                }}
                >
                    from <span
                    style={{
                        fontSize:'12px', 
                        fontWeight:'bold',
                        backgroundColor:'rgba(255, 255, 255, 0.7)',
                        color:'black',
                        borderRadius:'3px',
                        padding:'2px'
                    }}
                    >{
                        timeZoneDatetimeConverter(
                            corrCalcDATETIME.datetimeStart, 
                            localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                            zero_gmt_deviation
                        )
                    }</span> to <span
                    style={{
                        fontSize:'12px', 
                        fontWeight:'bold',
                        backgroundColor:'rgba(255, 255, 255, 0.7)',
                        color:'black',
                        borderRadius:'3px',
                        padding:'2px'
                    }}
                    >{
                        corrCalcDATETIME.datetimeEnd === rsiORIdata[rsiORIdata.length-1].datetime ? 'Current Time' 
                        :
                        timeZoneDatetimeConverter(
                            corrCalcDATETIME.datetimeEnd, 
                            localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                            zero_gmt_deviation
                        )
                    }</span>
                </div>

                <div
                style={{
                    marginTop:'10px'
                }}
                >
                    {
                        rsiCORRdata.corr.map((el, elInd)=>{

                            //{ name: 'AUDCAD', value: -0.6417767832641605 }
                            const currpairName = el.name
                            let currpairValue = el.unscaled
                            let currpairPerfValue = el.scaled
                            currpairValue = Number(String(currpairValue).slice(0, currpairValue<0 ? 5 : 4))


                            return(
                                <div key={elInd}
                                style={{
                                    display:'flex',
                                    height:'5%'
                                }}
                                >
                                    {
                                        //`${elInd+1} - 2colors -  ${currpairName} | ${currpairValue} | ${currpairPerfValue}%`
                                    }

                                <div
                                    style={{
                                        height:'5%',
                                        width:'100%',
                                        display:'flex',
                                        //justifyContent:'center',
                                        //backgroundColor:'white',
                                        //color:`${currColorObj[currpairName]}`,
                                        alignItems:'center',
                                        borderBottom:'1px solid rgba(255,255,255,0.3)',
                                        borderTop:`${elInd===0 ? '1px solid rgba(255,255,255,0.3)' : ''}`
                                    }}
                                    >
                                        {currpairName}
                                    </div>

                                    <div
                                    style={{
                                        height:'5%',
                                        width:'100%',
                                        display:'flex',
                                        //justifyContent:'center',
                                        //backgroundColor:'white',
                                        //color:`${currColorObj[currpairName]}`,
                                        alignItems:'center',
                                        borderBottom:'1px solid rgba(255,255,255,0.3)',
                                        borderTop:`${elInd===0 ? '1px solid rgba(255,255,255,0.3)' : ''}`
                                    }}
                                    >
                                        {currpairValue}
                                    </div>

                                    <div
                                    style={{
                                        height:'5%',
                                        width:'100%',
                                        display:'flex',
                                        //justifyContent:'center',
                                        //backgroundColor:'white',
                                        //color:`${currColorObj[currpairName]}`,
                                        alignItems:'center',
                                        borderBottom:'1px solid rgba(255,255,255,0.3)',
                                        borderTop:`${elInd===0 ? '1px solid rgba(255,255,255,0.3)' : ''}`
                                    }}
                                    >
                                        {`${currpairPerfValue}%`}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            
            }
        </div>
    }


    <div
    style={{
        //border:'1px solid white',
        height:'4%',
        //width:'50%',
        display:'flex',
    }}

    onClick={()=>{
        setIsRSIcorrListMaximized(!isRSIcorrListMaximized)
    }}
    >
        <button
        style={{
            width:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            cursor:'pointer',
            backgroundColor:`${isRSIcorrListMaximized ? '' : 'gold'}`,
            border:`${isRSIcorrListMaximized ? '' : '0px'}`,
            marginTop:`${isRSIcorrListMaximized ? '' : '4px'}`
        }}
        >
            {isRSIcorrListMaximized ? 'minimize' : 'maximize'}

            {
                isRSIcorrListMaximized 
                ?
                <BiSolidUpArrow
                style={{
                    marginLeft:'4px'
                }}
                />
                :
                <BiSolidDownArrow
                style={{
                    marginLeft:'4px'
                }}
                />
            }

        </button>

    </div>

</div>

)
}

export default HistStrengthMeterCompo


import { IoPlayBackSharp } from "react-icons/io5";

import TimeframeClockCompo from './TimeframeClockCompo'


const PlayerHeader = ({

    setPlayBackMSGstr,
    getPlayBACKdataFromDB,
    isPlayBackBtnClicked,
    setIsPlayBackBtnClicked,


    forexORcrypto,
    csmDATAtypeIDstr,

    timeframeClockBtnClicked,
    setTimeframeClockBtnClicked,
    switchOnAndInsertTFbyOrder,
    defaultTfArray,
    tfArray,
    extra_tf, 
    setTfArray,
    setIsAllTFsSelected,
}) => {


  const chartNum = 2


return (
<div className="" 
style={{
    backgroundColor:`rgba(255,255,255,0.0)`,
    width: `90%`,
    height: '10.6%',
    //border:'1px solid black',
    margin:'0px auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: '3'
}} 

onClick={()=>{
}}
>
    
    
    <TimeframeClockCompo
    timeframeClockBtnClicked={timeframeClockBtnClicked}
    setTimeframeClockBtnClicked={setTimeframeClockBtnClicked}
    setIsAllTFsSelected={setIsAllTFsSelected}
    forexORcrypto={forexORcrypto}
    setTfArray={setTfArray}
    tfArray={tfArray}
    csmDATAtypeIDstr={csmDATAtypeIDstr}
    switchOnAndInsertTFbyOrder={switchOnAndInsertTFbyOrder}
    extra_tf={extra_tf}
    defaultTfArray={defaultTfArray}
    />


    {
      <div
      style={{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor:'pointer',
        whiteSpace: 'nowrap',
        boxShadow:`${/*currIconBtnClicked === true ? `rgba(0, 0, 211, 0.9) 0px 7px 29px 0px` : */`rgba(0, 0, 211, 0.4) 0px 7px 29px 0px`}`,
        position: 'relative',
        zIndex: '11',
        borderRadius: '15px',
        height:'3vh',
        paddingLeft: '15px', paddingRight: '15px',
        //marginLeft:'30%',
        marginRight:'0%',
      }}
  
      onClick={()=>{
        setIsPlayBackBtnClicked(!isPlayBackBtnClicked)
  
        setPlayBackMSGstr('Loading...')
  
        setTimeout(()=>{
          getPlayBACKdataFromDB(chartNum)
        }, 1)
  
        setTimeframeClockBtnClicked(false)
      }}
      >
        <IoPlayBackSharp
          style={{
            color:'rgba(0,0,255,0.5)',
            transform:'scale(1.5)'
          }}
        />
        <span style={{
          fontSize:'0.7em',
          color: 'gray',
          marginLeft:'11px'
        }}>play-back</span>
      </div>
    }




</div>
)
}

export default PlayerHeader

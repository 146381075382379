

import { MdKeyboardArrowDown } from "react-icons/md";
import { MdDoNotTouch } from "react-icons/md";
import { MdTouchApp } from "react-icons/md";



const ChartHeader = ({

    dataLENGTH,

    setIsDATAloaded,
    
    isHideAllDrawings, setIsHideAllDrawings,

    setRsiDATETIMEnumCountObj, 
    setCorrCalcDATETIME,

    canvasWidth,
    chartInfoObj,
    setChartInfoObj,
    currIndexArray,
    currPairArray,
    tfArray,
    forexORcrypto,
    canvasCompoId,

    chartInRowName,
    setIsInstruTFloaded,
    setLocalInstruTFuseEffectReRunCounter,

    nullifyAllDrawingActivitiesFUNC,
    
    isScreenScrollLocked,
    isDontTouchChart,
    setIsShowLockedChartMovementsBtn,
    setIsDontTouchChart,

    isChartHeaderOptionsSwitchedAtleastOnce, setIsChartHeaderOptionsSwitchedAtleastOnce
}) => {




return (
<div
style={{
    height:'28px',
    display:'flex',
    justifyContent:'space-between',
}}

onClick={()=>{
    //console.log('unSelect All Drawings')
    nullifyAllDrawingActivitiesFUNC()
}}
>
    


    <div
    style={{
        display:'flex',
        //width:'100%'
    }}
    >




    {//candle/rsi/stoch
        <select //id="pet-select" 
        value={
            //currPairCurrIndexTypeVal
            chartInfoObj.candleORrsi//indexORpair//candleORrsiORstoch
        } 
        onChange={(e)=>{//if able to change or pass the change test
            //console.log('there is a change')
            if(
                //e.target.value==='pair'
                true
            ){

                //'USD'/'BTC' remain static for LStorage naming sake for rsi/stoch tfs
                //as rsi/stoch comprise of multi currINDEX data but not specific curr
                //therefore: to avoid dynamic LStorage name/index to access sake, we choose a static CURRname for rsi/stoch index chart
                const multiCURRindexSTATICinstruName = forexORcrypto==='forex' ? 'USD' : 'BTC'

                //setChartTypeOptionVal('candle')
                const chartSettingsObjToSaveInLStorage = {
                    ...chartInfoObj,

                    candleORrsi : e.target.value,

                    instruName : e.target.value!=='candle' ? multiCURRindexSTATICinstruName 
                    : chartInfoObj.prevIndexORpair==='pair' ? chartInfoObj.prevCandlePairInstruAndTF.instruName :
                    chartInfoObj.prevIndexORpair==='index' ? chartInfoObj.prevCandleIndexInstruAndTF.instruName : chartInfoObj.instruName,

                    tf : chartInfoObj.tf,

                    prevIndexORpair : chartInfoObj.indexORpair,

                    indexORpair : e.target.value!=='candle' ? 'index' : chartInfoObj.prevIndexORpair,


                    prevCandlePairInstruAndTF  : e.target.value!=='candle' && chartInfoObj.indexORpair==='pair' ? {
                        instruName:chartInfoObj.instruName, 
                        tf:chartInfoObj.tf
                    } : chartInfoObj.prevCandlePairInstruAndTF,
    
                    prevCandleIndexInstruAndTF : e.target.value!=='candle' && chartInfoObj.indexORpair==='index' ? {
                        instruName:chartInfoObj.instruName,    
                        tf:chartInfoObj.tf
                    } : chartInfoObj.prevCandleIndexInstruAndTF,
                    
                    
                }
                setChartInfoObj(chartSettingsObjToSaveInLStorage)

                window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

                //----------------------------------------------------------------------------------------------------------------------------------------
                //to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
                //call setIsChartHeaderOptionsSwitchedAtleastOnce(true) first before loading db fetch request by setLocalInstruTFuseEffectReRunCounter(Date.now())
                //to avoid truthy delay before the useEffect reRunner is triggered for a db fetch request
                if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                    setIsChartHeaderOptionsSwitchedAtleastOnce(true)
                }


                //calc Corr afresh when Auth of corrCalcDATETIME.datetimeStart===''
                setCorrCalcDATETIME({
                    datetimeStart:'', datetimeEnd:'' 
                })

                setRsiDATETIMEnumCountObj({
                    datetimeStart :1,
                    datetimeEnd   :1
                })


                if(isHideAllDrawings){//Operation to make All drawing re-visible when we make a new switch to it - or might have forgotten to switch it on
                    setIsHideAllDrawings(false)
                    //setUseEffectReRunCounter(Date.now())
                }



                setIsInstruTFloaded(false)
                setLocalInstruTFuseEffectReRunCounter(Date.now())

                setIsDATAloaded(false)
            }

        }}
        style={{cursor:'pointer', marginLeft:'2px'}}
        >
            {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                ['candle', 'rsi', 'stoch', 'corr'].map((currPairCurrIndexTypeName, ind)=>{
                    return(
                        <option value={currPairCurrIndexTypeName} key={ind} style={{cursor:'pointer'}}>{
                            /*currPairCurrIndexTypeName==='stoch' ? 'corr.' :*/ currPairCurrIndexTypeName
                        }</option>
                    )
                })
            }
            
        </select>
    }








    {//pair/index
        //chartInfoObj.candleORrsi==='candle' 
        //&&
        chartInfoObj.candleORrsi!=='corr'
        &&
        <select //id="pet-select" 
        disabled={chartInfoObj.candleORrsi!=='candle'}
        value={
            //currPairCurrIndexTypeVal
            chartInfoObj.indexORpair
        } 
        onChange={(e)=>{
            //console.log('there is a change')
            if(
                //e.target.value==='pair'
                true
            ){
                
                const chartSettingsObjToSaveInLStorage = {
                    ...chartInfoObj,

                    indexORpair : e.target.value ,

                    instruName : e.target.value==='pair' ? chartInfoObj.prevCandlePairInstruAndTF.instruName :
                    e.target.value==='index' ? chartInfoObj.prevCandleIndexInstruAndTF.instruName : chartInfoObj.instruName,

                    tf           : chartInfoObj.tf
                }
                setChartInfoObj(chartSettingsObjToSaveInLStorage)

                window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

                //----------------------------------------------------------------------------------------------------------------------------------------
                //to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
                //call setIsChartHeaderOptionsSwitchedAtleastOnce(true) first before loading db fetch request by setLocalInstruTFuseEffectReRunCounter(Date.now())
                //to avoid truthy delay before the useEffect reRunner is triggered for a db fetch request
                if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                    setIsChartHeaderOptionsSwitchedAtleastOnce(true)
                }

                //calc Corr afresh when Auth of corrCalcDATETIME.datetimeStart===''
                setCorrCalcDATETIME({
                    datetimeStart:'', datetimeEnd:'' 
                })

                setRsiDATETIMEnumCountObj({
                    datetimeStart :1,
                    datetimeEnd   :1
                })

                if(isHideAllDrawings){//Operation to make All drawing re-visible when we make a new switch to it - or might have forgotten to switch it on
                    setIsHideAllDrawings(false)
                    //setUseEffectReRunCounter(Date.now())
                }



                setIsInstruTFloaded(false)
                setLocalInstruTFuseEffectReRunCounter(Date.now())

                setIsDATAloaded(false)
            }

        }}
        style={{cursor:'pointer', marginLeft:'2px'}}
        >
            {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                ['pair', 'index', /*'index(all)'*/].map((currPairCurrIndexTypeName, ind)=>{
                    return(
                        <option value={currPairCurrIndexTypeName} key={ind} style={{cursor:'pointer'}}>{
                            currPairCurrIndexTypeName
                        }</option>
                    )
                })
            }
            
        </select>
    }




    
    
    {//All/Some instru - pairType
        (
            chartInfoObj.indexORpair==='pair'
        ) 
        
        &&

        <select //id="pet-select" 
        value={
            //currPairCurrIndexName
            chartInfoObj.pairType
        } 
        onChange={(e)=>{
            
            const chartSettingsObjToSaveInLStorage = {
                ...chartInfoObj,
                pairType : e.target.value,
                

                instruName : e.target.value === 'ALL' || chartInfoObj.instruName.includes(e.target.value) ? chartInfoObj.instruName : 
                (currPairArray.filter((pair, pairIndex)=>{
                    return(
                        pair.includes(e.target.value)
                    )
                }))[0],
            }

            setChartInfoObj(chartSettingsObjToSaveInLStorage)

            window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

            //----------------------------------------------------------------------------------------------------------------------------------------
            //to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
            //call setIsChartHeaderOptionsSwitchedAtleastOnce(true) first before loading db fetch request by setLocalInstruTFuseEffectReRunCounter(Date.now())
            //to avoid truthy delay before the useEffect reRunner is triggered for a db fetch request
            if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                setIsChartHeaderOptionsSwitchedAtleastOnce(true)
            }
            
            //calc Corr afresh when Auth of corrCalcDATETIME.datetimeStart===''
            setCorrCalcDATETIME({
                datetimeStart:'', datetimeEnd:'' 
            })

            setRsiDATETIMEnumCountObj({
                datetimeStart :1,
                datetimeEnd   :1
            })

            if(isHideAllDrawings){//Operation to make All drawing re-visible when we make a new switch to it - or might have forgotten to switch it on
                setIsHideAllDrawings(false)
                //setUseEffectReRunCounter(Date.now())
            }



            setIsInstruTFloaded(false)
            setLocalInstruTFuseEffectReRunCounter(Date.now())

            setIsDATAloaded(false)
        }}
        style={{cursor:'pointer', marginLeft:'2px'}}
        >
            {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                ['ALL', ...currIndexArray].map((currPairCurrIndexNameEL, ind)=>{
                    return(
                        <option value={currPairCurrIndexNameEL} key={ind} style={{cursor:'pointer'}}>{
                            currPairCurrIndexNameEL
                        }</option>
                    )
                })
            }
            
        </select>
    }






    {//instru
        (
            chartInfoObj.candleORrsi==='candle'
            || (chartInfoObj.candleORrsi!=='candle' && chartInfoObj.indexORpair==='pair')
        ) 
        
        &&

        <select //id="pet-select" 
        value={
            //currPairCurrIndexName
            chartInfoObj.instruName
        } 
        onChange={(e)=>{
            
            const chartSettingsObjToSaveInLStorage = {
                ...chartInfoObj,

                instruName : e.target.value,

                tf : chartInfoObj.tf,

                prevCandlePairInstruAndTF  : chartInfoObj.indexORpair==='pair' ? {
                    instruName:e.target.value, 
                    tf:chartInfoObj.tf
                } : chartInfoObj.prevCandlePairInstruAndTF,

                prevCandleIndexInstruAndTF : chartInfoObj.indexORpair==='index' ? {
                    instruName:e.target.value,    
                    tf:chartInfoObj.tf
                } : chartInfoObj.prevCandleIndexInstruAndTF,
            }

            setChartInfoObj(chartSettingsObjToSaveInLStorage)

            window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

            //----------------------------------------------------------------------------------------------------------------------------------------
            //to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
            //call setIsChartHeaderOptionsSwitchedAtleastOnce(true) first before loading db fetch request by setLocalInstruTFuseEffectReRunCounter(Date.now())
            //to avoid truthy delay before the useEffect reRunner is triggered for a db fetch request
            if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                setIsChartHeaderOptionsSwitchedAtleastOnce(true)
            }
            
            //calc Corr afresh when Auth of corrCalcDATETIME.datetimeStart===''
            setCorrCalcDATETIME({
                datetimeStart:'', datetimeEnd:'' 
            })

            setRsiDATETIMEnumCountObj({
                datetimeStart :1,
                datetimeEnd   :1
            })

            if(isHideAllDrawings){//Operation to make All drawing re-visible when we make a new switch to it - or might have forgotten to switch it on
                setIsHideAllDrawings(false)
                //setUseEffectReRunCounter(Date.now())
            }



            setIsInstruTFloaded(false)
            setLocalInstruTFuseEffectReRunCounter(Date.now())

            setIsDATAloaded(false)
        }}
        style={{cursor:'pointer', marginLeft:'2px'}}
        >
            {//chartInfoObj.pairType==='ALL'
                (chartInfoObj.indexORpair==='index' ? currIndexArray : 
                    currPairArray.filter((pair, pairIndex)=>{
                        return(
                            chartInfoObj.pairType==='ALL' ||  
                            pair.includes(chartInfoObj.pairType)
                        )
                    })
                ).map((currPairCurrIndexNameEL, ind)=>{
                    return(
                        <option value={currPairCurrIndexNameEL} key={ind} style={{cursor:'pointer'}}>{
                            currPairCurrIndexNameEL
                        }</option>
                    )
                })
            }
            
        </select>
    }


    {//COOR instru
        (
            chartInfoObj.candleORrsi==='corr' && chartInfoObj.indexORpair==='index'
        ) 
        
        &&

        <select //id="pet-select" 
        value={
            //currPairCurrIndexName
            //chartInfoObj.instruName
            chartInfoObj.corrInstruName===undefined ? 'USD' : chartInfoObj.corrInstruName
        } 
        onChange={(e)=>{
            
            const chartSettingsObjToSaveInLStorage = {
                ...chartInfoObj,

                corrInstruName : e.target.value,

                tf : chartInfoObj.tf,
            }

            setChartInfoObj(chartSettingsObjToSaveInLStorage)

            window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

            //----------------------------------------------------------------------------------------------------------------------------------------
            //to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
            //call setIsChartHeaderOptionsSwitchedAtleastOnce(true) first before loading db fetch request by setLocalInstruTFuseEffectReRunCounter(Date.now())
            //to avoid truthy delay before the useEffect reRunner is triggered for a db fetch request
            if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                setIsChartHeaderOptionsSwitchedAtleastOnce(true)
            }
            
            //calc Corr afresh when Auth of corrCalcDATETIME.datetimeStart===''
            setCorrCalcDATETIME({
                datetimeStart:'', datetimeEnd:'' 
            })

            setRsiDATETIMEnumCountObj({
                datetimeStart :1,
                datetimeEnd   :1
            })

            if(isHideAllDrawings){//Operation to make All drawing re-visible when we make a new switch to it - or might have forgotten to switch it on
                setIsHideAllDrawings(false)
                //setUseEffectReRunCounter(Date.now())
            }



            setIsInstruTFloaded(false)
            setLocalInstruTFuseEffectReRunCounter(Date.now())

            setIsDATAloaded(false)
        }}
        style={{cursor:'pointer', marginLeft:'2px'}}
        >
            {//chartInfoObj.pairType==='ALL'
                (chartInfoObj.indexORpair==='index' ? currIndexArray : 
                    currPairArray.filter((pair, pairIndex)=>{
                        return(
                            chartInfoObj.pairType==='ALL' ||  
                            pair.includes(chartInfoObj.pairType)
                        )
                    })
                ).map((currPairCurrIndexNameEL, ind)=>{
                    return(
                        <option value={currPairCurrIndexNameEL} key={ind} style={{cursor:'pointer'}}>{
                            '(' + currPairCurrIndexNameEL + ') - pairs'
                        }</option>
                    )
                })
            }
            
        </select>
    }



    {//COOR period
        (
            chartInfoObj.candleORrsi!=='candle' && chartInfoObj.indexORpair==='index'
        ) 
        
        &&

        <select //id="pet-select" 
        value={
            //chartInfoObj.corrPeriod===undefined ? 14 : chartInfoObj.corrPeriod//14
            
            chartInfoObj.candleORrsi==='rsi' && chartInfoObj.rsiPeriod===undefined ? 14 : 
            chartInfoObj.candleORrsi==='stoch' && chartInfoObj.stochPeriod===undefined ? 10 :
            chartInfoObj.candleORrsi==='corr' && chartInfoObj.corrPeriod===undefined ? 14 : 
            chartInfoObj.candleORrsi==='rsi' ? Number(chartInfoObj.rsiPeriod) : 
            chartInfoObj.candleORrsi==='stoch' ? Number(chartInfoObj.stochPeriod) : 
            chartInfoObj.candleORrsi==='corr' && Number(chartInfoObj.corrPeriod) //: 5
            
        } 
        onChange={(e)=>{

            //console.log({dataLENGTH})
            const minCorrPERIOD = 5
            const parsedPERIOD  = dataLENGTH > Number(e.target.value) ? Number(e.target.value) : minCorrPERIOD
            
            const chartSettingsObjToSaveInLStorage = {//['candle', 'rsi', 'stoch', 'corr']
                ...chartInfoObj,

                //corrPeriod : Number(e.target.value),
                /*
                rsiPeriod : 14,

                stochPeriod : 5,

                corrPeriod : 14,
                */
                rsiPeriod   : chartInfoObj.candleORrsi==='rsi' ? parsedPERIOD : chartInfoObj.rsiPeriod,

                stochPeriod : chartInfoObj.candleORrsi==='stoch' ? parsedPERIOD : chartInfoObj.stochPeriod,

                corrPeriod  : chartInfoObj.candleORrsi==='corr' ? parsedPERIOD : chartInfoObj.corrPeriod,


                tf : chartInfoObj.tf,
            }

            setChartInfoObj(chartSettingsObjToSaveInLStorage)

            window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

            //----------------------------------------------------------------------------------------------------------------------------------------
            //to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
            //call setIsChartHeaderOptionsSwitchedAtleastOnce(true) first before loading db fetch request by setLocalInstruTFuseEffectReRunCounter(Date.now())
            //to avoid truthy delay before the useEffect reRunner is triggered for a db fetch request
            if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                setIsChartHeaderOptionsSwitchedAtleastOnce(true)
            }
            
            //calc Corr afresh when Auth of corrCalcDATETIME.datetimeStart===''
            setCorrCalcDATETIME({
                datetimeStart:'', datetimeEnd:'' 
            })

            setRsiDATETIMEnumCountObj({
                datetimeStart :1,
                datetimeEnd   :1
            })

            if(isHideAllDrawings){//Operation to make All drawing re-visible when we make a new switch to it - or might have forgotten to switch it on
                setIsHideAllDrawings(false)
                //setUseEffectReRunCounter(Date.now())
            }



            setIsInstruTFloaded(false)
            setLocalInstruTFuseEffectReRunCounter(Date.now())

            setIsDATAloaded(false)
        }}
        style={{cursor:'pointer', marginLeft:'2px'}}
        >
            {//chartInfoObj.pairType==='ALL'
                /*([
                    5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27
                ])*/
                Array(
                    chartInfoObj.candleORrsi==='rsi' ? 20-4 : 
                    chartInfoObj.candleORrsi==='stoch' ? 50-4 : 
                    chartInfoObj.candleORrsi==='corr' && 99-4
                ).fill(1).map((currPairCurrIndexNameEL, ind)=>{
                    return(
                        <option value={ind+5} key={ind} style={{cursor:'pointer'}}>{
                            '(' + String(ind+5) + ') - period'
                        }</option>
                    )
                })
            }
            
        </select>
    }





    {//tf
        <select //id="pet-select" 
        value={
            //tf
            //chartInfoObj.lineTF
            chartInfoObj.tf
        } 
        onChange={(e)=>{
            //if(isShowChart){
            //setTf(e.target.value)

            const obj = {...chartInfoObj}
            if(chartInfoObj.indexORpair==='pair'){
                obj.pairPrevTF[chartInfoObj.instruName] = e.target.value
            }
            else if(chartInfoObj.indexORpair==='index'){
                obj.indexPrevTF[chartInfoObj.instruName] = e.target.value
            }

            const chartSettingsObjToSaveInLStorage = {
                ...chartInfoObj,

                tf : e.target.value,
            }
            setChartInfoObj(chartSettingsObjToSaveInLStorage)

            window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

            //----------------------------------------------------------------------------------------------------------------------------------------
            //to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
            //call setIsChartHeaderOptionsSwitchedAtleastOnce(true) first before loading db fetch request by setLocalInstruTFuseEffectReRunCounter(Date.now())
            //to avoid truthy delay before the useEffect reRunner is triggered for a db fetch request
            if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                setIsChartHeaderOptionsSwitchedAtleastOnce(true)
            }
            
            //calc Corr afresh when Auth of corrCalcDATETIME.datetimeStart===''
            setCorrCalcDATETIME({
                datetimeStart:'', datetimeEnd:'' 
            })

            setRsiDATETIMEnumCountObj({
                datetimeStart :1,
                datetimeEnd   :1
            })

            if(isHideAllDrawings){//Operation to make All drawing re-visible when we make a new switch to it - or might have forgotten to switch it on
                setIsHideAllDrawings(false)
                //setUseEffectReRunCounter(Date.now())
            }


            
            setIsInstruTFloaded(false)
            setLocalInstruTFuseEffectReRunCounter(Date.now())

            setIsDATAloaded(false)
            
        }}
        style={{cursor:'pointer', marginLeft:'2px'}}
        >
            {
                tfArray.map((tfEL, ind)=>{
                    return(
                        <option value={tfEL} key={ind} style={{cursor:'pointer'}}>{
                            tfEL
                        }</option>
                    )
                })
            }
            
        </select>
    }







    </div>


    <div
    style={{
        display:'flex',
        justifyContent:'end',
        width:'100%',
        //border:'1px solid black'
    }}
    >



        {//UnToucheable Chart
            <div className={isDontTouchChart ? 'touchableChartBtnTool' : 'unTouchableChartBtnTool'}
            style={{
                position:'relative',
                width:`${canvasWidth < 600 ? '100%' : '80px'}`,
                border:'1px solid black',
                marginLeft:'2px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                cursor:'pointer',
                zIndex:'5'
            }}

            onClick={()=>{
                
                setIsShowLockedChartMovementsBtn(!isDontTouchChart)
                setIsDontTouchChart(!isDontTouchChart)

                if(isScreenScrollLocked){
                    document.getElementById(`unlockButton-${canvasCompoId}`).click()
                }
                //console.log('settings Clicked')
            }}
            >
                {
                isDontTouchChart===false && 
                    <MdDoNotTouch//BsLock 
                        style={{
                            //cursor:'pointer', 
                            //transform:'scale(1.5)',
                            //color:'blue',
                            marginLeft:'4px'
                        }}
                        
                    />
                }


                {isDontTouchChart&& 
                    <MdTouchApp//BsUnlock 
                    className='coin'
                        style={{
                            //cursor:'pointer', 
                            //transform:'scale(1.5)',
                            color:'blue',
                            marginLeft:'4px'
                        }}
                    
                />}

                {
                    <MdKeyboardArrowDown  
                    style={{
                        transform:'scale(1.0)',
                        //border:'1px solid black'
                        
                    }}

                    
                />
                }


            </div>
        }






        


      



    </div>


</div>


)
}

export default ChartHeader

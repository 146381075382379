

import './App.css';

import {TESTINGcopyAndPasteCompo, HTMLcopyAndPastePages, ForexHome, TFCurrsHistogram, CurrTFsHistogram, CandlestickChart} from './Pages/export_Pages'



function App() { 



  return ( 
  <div className="App"> 

    {/*true && <TESTINGcopyAndPasteCompo />*/}

    {true && <ForexHome/>}

    {/*true && <HTMLcopyAndPastePages/>*/}
     
 
    {/*false && <Router>
      
      <Routes>

        <Route path='/' element={
          <ForexHome/>
        }/>

        <Route path='/charts' element={//ChartSignals
          <CandlestickChart />
        }/>

        <Route path='/privacy' element={
          <PrivacyPolicy/>
        }/>

        <Route path='/page/:page_type' element={
          <ForexHome/>
        }/>

      </Routes>
    
    </Router>*/}


    



  </div>
  );

}

export default App;
